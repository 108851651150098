export const AUTHORIZATION = { headers: { 'x-api-key': 'dummy' } };
export const TEST_BASEURL_PRD = 'http://localhost:8080';
export const TEST_BASEURL_QA = 'https://jsonplaceholder.typicode.com/';
export const TEST_BASEURL_DEV = 'https://jsonplaceholder.typicode.com/';
// export const FIT_BASEURL_DEV = "https://aic-qa-hcss.lgthinq.com:47040/"; <= 요청에러 낼 수 있습니다. 500번
export const THRESHOLD_AUTH_REFRESH = 0.2; // Percent

const LOCALHOST = 'http://10.0.0.19:8080'; // 승훈님 (win-netgear-5g)

const INTERNAL_BASEURL_PRD = 'https://admin.subscriptionadmin.lge.com';
const INTERNAL_BASEURL_QA = 'https://qa-admin.subscriptionadmin.lge.com';
const INTERNAL_BASEURL_DEV = 'https://qa2-admin.subscriptionadmin.lge.com';
const EXTERNAL_BASEURL_PRD = 'https://subscriptionadmin.lge.com';
const EXTERNAL_BASEURL_QA = 'https://qa-subscriptionadmin.lge.com';
const EXTERNAL_BASEURL_DEV = 'https://qa2-subscriptionadmin.lge.com';

export const DEFAULT_SERVERTYPE = 'dev'; //prd, qa, dev

export const URLS = {
  // TEST: "thinqcare/api/v1.0/home" <= 요청에러 낼 수 있습니다. 500번
  TEST: 'users',
};

export const getUrl = (endStr, serverType) => {
  let base = '';
  if (process.env.REACT_APP_MODE === 'LG') {
    if (serverType === 'localhost') {
      base = LOCALHOST;
    } else if (serverType === 'qa') {
      base = INTERNAL_BASEURL_QA;
    } else if (serverType === 'qa2') {
      base = INTERNAL_BASEURL_DEV;
    } else {
      base = INTERNAL_BASEURL_PRD;
    }
  } else {
    if (serverType === 'localhost') {
      base = LOCALHOST;
    } else if (serverType === 'qa') {
      base = EXTERNAL_BASEURL_QA;
    } else if (serverType === 'qa2') {
      base = EXTERNAL_BASEURL_DEV;
    } else {
      base = EXTERNAL_BASEURL_PRD;
    }
  }
  return base + endStr;
};
