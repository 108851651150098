import { useState, useCallback } from 'react';

/**
 * @category useHooks
 * @namespace useCheckbox
 * @param {string} initialValue RadioButton 공통 컴포넌트 사용 시 지정해주는 초기 value.
 * @returns {value, onCheckedSingle, onCheckedAll}
 * @description RadioButton 공통 컴포넌트에서 사용하는 value, onClickItem 출력.
 */

const useCheckbox = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const onClickItem = useCallback((id) => {
    setValue(id);
  }, []);

  return [value, onClickItem];
};

export default useCheckbox;
