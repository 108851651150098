import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import css from './AlarmFailList.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import TableCheckbox from '@components/Table/TableCheckbox';
import { SelectBox, Button, TabMenu, TableV8 } from '@components/index';

const columnHelper = createColumnHelper();

const cn = classNames.bind(css);

const SELECT_MESSAGE_DATA = [
  { id: 0, title: '알림톡' },
  { id: 1, title: 'second' },
  { id: 2, title: 'third' },
];

const SELECT_FAIL_CODE_DATA = [
  { id: 0, title: 'All' },
  { id: 1, title: 'second' },
  { id: 2, title: 'third' },
];

const SELECT_FAIL_DETAIL_DATA = [
  { id: 0, title: 'All' },
  { id: 1, title: 'second' },
  { id: 2, title: 'third' },
];

const tabData = [
  { id: 'tab1', title: '전체', content: '' },
  { id: 'tab2', title: '알림 톡', content: '' },
  { id: 'tab3', title: 'LMS', content: '' },
  { id: 'tab4', title: 'E-mail', content: '' },
];

const tableData = [
  {
    Code: 'mesg_02',
    알림구분: '당월 미결제',
    수신자구분: '제휴사_대리주부',
    수신자명: '김**',
    수신자번호: '010-XXXX-XXXX',
    메세지유형: '알림톡',
    실패코드: '403',
    실패상세: '권한 없음',
    발송구분: '신규',
  },
  {
    Code: 'mesg_02',
    알림구분: '당월 미결제',
    수신자구분: '제휴사_대리주부',
    수신자명: '김**',
    수신자번호: '010-XXXX-XXXX',
    메세지유형: '알림톡',
    실패코드: '403',
    실패상세: '권한 없음',
    발송구분: '신규',
  },
];

const AlarmFailList = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const columnData = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('Code', {
        id: 'Code',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>Code</span>,
      }),
      columnHelper.accessor('알림구분', {
        id: '알림구분',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림구분</span>,
      }),
      columnHelper.accessor('수신자구분', {
        id: '수신자구분',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신자구분</span>,
      }),
      columnHelper.accessor('수신자명', {
        id: '수신자명',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신자명</span>,
      }),
      columnHelper.accessor('수신자번호', {
        id: '수신자번호',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신자번호</span>,
      }),
      columnHelper.accessor('메세지유형', {
        id: '메세지유형',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>메세지유형</span>,
      }),
      columnHelper.accessor('실패코드', {
        id: '실패코드',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>실패코드</span>,
      }),
      columnHelper.accessor('실패상세', {
        id: '실패상세',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>실패상세</span>,
      }),
      columnHelper.accessor('발송구분', {
        id: '발송구분',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송구분</span>,
      }),
    ],
    []
  );
  return (
    <>
      <strong className={css.popup_title}>발송 알림 실패 리스트</strong>

      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={css.flex}>
          <div className={css.innerBox}>
            <div className={cn('innerBox', 'marginL4')}>
              <strong className={css.title}>메세지 유형</strong>
              <div className={cn('selectbox', 'type01', 'sizeS')}>
                <SelectBox optionList={SELECT_MESSAGE_DATA} fullWidth={true} />
              </div>
            </div>
            <div className={cn('innerBox', 'marginL4')}>
              <strong className={css.title}>발송 구분</strong>
              <div className={cn('selectbox', 'type01', 'sizeS')}>
                <SelectBox
                  optionList={SELECT_FAIL_CODE_DATA}
                  fullWidth={true}
                />
              </div>
            </div>
            <div className={cn('innerBox', 'marginL4')}>
              <strong className={css.title}>실패 상세</strong>
              <div className={cn('selectbox', 'type01', 'sizeS')}>
                <SelectBox
                  optionList={SELECT_FAIL_DETAIL_DATA}
                  fullWidth={true}
                />
              </div>
            </div>
            <div className={css.innerBox}>
              <Button className={cn('btn', 'btn01', 'type03')}>검색</Button>
            </div>
          </div>
        </div>
      </div>

      <div className={cn('tabMenu', 'tabMenu03', 'mt32')}>
        <TabMenu
          tabData={tabData}
          tabMenu={'tabMenu03'}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isTabMarinBottom={false}
        />
      </div>
      <div className={css.tableContainer}>
        <TableV8
          columns={columnData}
          data={tableData}
          hasCsvDownload={false}
          hasPageSize={false}
        />
      </div>
    </>
  );
};

export default AlarmFailList;
