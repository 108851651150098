import { useController } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './HFSelectBox.module.scss';

const cn = classNames.bind(css);

/**
 * @category components
 * @namespace HFSelectBox
 * @param {Object} control useForm의 return 값으로 useContorller에 등록하기 위한 객체
 * @param {string} name handleSubmit에서 사용되는 고유 key 값
 * @param {bool} required 값 선택 필수 유무
 * @param {bool} disabled disabled
 * @param {bool} hasScroll scroll 존재 유무
 * @param {string} type style type
 * @param {Object} options { guide: string?, list: { id: string, title: string }[] } 형태의 옵션리스트
 * @description React-hook-form 사용 시 사용되는 SelectBox
 */
const HFSelectBox = ({
  control,
  name,
  required,
  disabled,
  hasScroll = false,
  options,
  type = 'type01',
  selectApprovalStatus = false,
  ...rest
}) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isShow, setIsShow] = useState(false);
  const { field } = useController({
    name,
    defaultValue: !options.guide ? options?.list[0]?.id : null,
    control,
    rules: {
      required,
      disabled,
    },
  });

  const label = useMemo(() => {
    if (!options?.guide) {
      return currentIndex === -1
        ? options?.list[0]?.title
        : options?.list[currentIndex]?.title;
    } else {
      return currentIndex === -1
        ? options.guide
        : options?.list[currentIndex]?.title;
    }
  }, [currentIndex, options.guide, options.list]);

  const onSelectboxClick = useCallback(() => {
    setIsShow((prev) => !prev);
  }, []);

  const onOptionClick = useCallback(
    ({ event, item }) => {
      field.onChange(item.id);
    },
    [field]
  );

  useEffect(() => {
    if (selectApprovalStatus) {
      field.onChange(selectApprovalStatus);
      setCurrentIndex(2);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (options.list && field.value) {
      const index = options.list?.findIndex(({ id }) => id === field.value);
      setCurrentIndex(index);
    }
  }, [field.value, options.list]);

  return (
    <div>
      <div
        // FIXME: type01을 prop 혹은 className으로 받는 방법 찾기
        className={cn('selectbox', type, isShow ? 'active' : null, {
          disabled,
        })}
        onClick={onSelectboxClick}
        {...rest}
      >
        <div className={cn('label')}>
          <div>{label}</div>
        </div>
        <ul
          className={cn('optionList', isShow && 'showOption', {
            hasScroll,
            disabled,
          })}
        >
          {!disabled &&
            options?.list.map((item, index) => (
              <li
                key={`${item?.id}-${index}`}
                className={cn('optionItem', { hasScroll })}
                onClick={(event) => onOptionClick({ event, item })}
              >
                {item.title}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default HFSelectBox;
