import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import css from './CouponAlarm.module.scss';
import classNames from 'classnames/bind';
import AlarmRequestList from './AlarmRequestList/AlarmRequestList';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAlert } from '@feature/common/commonSlice';
import { createColumnHelper } from '@tanstack/react-table';
import { Calendar, Button, TextButton, PopUp, TableV8 } from '@components';
import AlarmListTable from '../module/AlarmListTable';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import CouponAlarmSearchArea from './modules/CouponAlarmSearchArea';
import { getRetrieveCouponTalkdreamResultListAPI } from 'redux/action/couponAction';
import { dateToStr, convertDateToString2 } from '@utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from 'template/Loading/Loading';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { useSearchParams } from 'react-router-dom';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import { DISABLED_DATE_TYPE, MILLISECONDS_PER_DAY } from '@data/Const';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();
const DATE_NOW = Date.now();

const targetSearchOptions = {
  guide: '',
  list: [
    { id: 'ALL', title: '전체' },
    { id: 'CUSTOMER', title: '고객' },
    { id: 'LGE', title: 'LGE' },
    // { id: 'VENDOR', title: '기타' },
  ],
};

const alarmSearchOptions = {
  guide: '',
  list: [
    { id: 'CAT01', title: '고객 쿠폰 발행 완료' },
    { id: 'CAT02', title: '고객 폐기 알림(한 달전)' },
    { id: 'CAT03', title: '고객 폐기 알림(15일전)' },
    { id: 'CAT04', title: '고객 폐기 알림(1일전)' },
    { id: 'CAT05', title: '제휴사 직원 난수 추가요청' },
  ],
};

const tableData = [
  {
    messageTarget: 'CUSTOMER',
    requestDatetime: '2024-01-11',
    vendorKey: 'VENDOR.00005',
    failedCount: 20,
    vendorName: '더반찬',
    category: 'COUPON_ISSUE',
    totalCount: 43,
    messageTargetDesc: '고객',
    categoryDesc: '쿠폰발행완료',
    content:
      '[LG전자 제휴서비스 쿠폰 도착 안내]\n \n 안녕하세요. #{항목1} 고객님, LG전자입니다.\n 제휴서비스 쿠폰이 도착했습니다.\n 해당 쿠폰은 #{항목5} 앱에서 등록 후 사용이 가능합니다. \n \n [쿠폰 안내]\n ● 쿠폰번호 : #{항목7}\n ● 쿠폰기간 : #{항목8} ~ #{항목9}\n ● 서비스명 : #{항목2}\n \n ※ 이 메시지는 고객님의 동의에 의해 지급된 쿠폰 안내 메시지입니다.\n ※ 유효기간 내 사용하지 않은 쿠폰은 소멸됩니다.\n ※ 사용쿠폰은 결제일 다음 날 지급됩니다.\n ※ 쿠폰은 현금으로 교환이 되지 않습니다.\n ※ 더 자세한 내용은 구독 상세 페이지를 참고해 주세요.\n \n LG전자 : https://www.lge.co.kr\n \n 쿠폰 문의 : LG전자 고객센터 1544-7777 (유료)\n 서비스 이용 문의: #{항목5} 고객센터 #{항목6} (유료)',
  },
  {
    messageTarget: 'CUSTOMER',
    requestDatetime: '2024-01-23',
    vendorKey: 'VENDOR.00004',
    failedCount: 10,
    vendorName: '대리주부',
    category: 'test',
    totalCount: 43,
    messageTargetDesc: '고객',
    categoryDesc: '쿠폰발행완료',
    content:
      '[LG전자 제휴서비스 쿠폰 도착 안내]\n \n 안녕하세요. #{항목1} 고객님, LG전자입니다.\n 제휴서비스 쿠폰이 도착했습니다.\n 해당 쿠폰은 #{항목5} 앱에서 등록 후 사용이 가능합니다. \n \n [쿠폰 안내]\n ● 쿠폰번호 : #{항목7}\n ● 쿠폰기간 : #{항목8} ~ #{항목9}\n ● 서비스명 : #{항목2}\n \n ※ 이 메시지는 고객님의 동의에 의해 지급된 쿠폰 안내 메시지입니다.\n ※ 유효기간 내 사용하지 않은 쿠폰은 소멸됩니다.\n ※ 사용쿠폰은 결제일 다음 날 지급됩니다.\n ※ 쿠폰은 현금으로 교환이 되지 않습니다.\n ※ 더 자세한 내용은 구독 상세 페이지를 참고해 주세요.\n \n LG전자 : https://www.lge.co.kr\n \n 쿠폰 문의 : LG전자 고객센터 1544-7777 (유료)\n 서비스 이용 문의: #{항목5} 고객센터 #{항목6} (유료)',
  },
];

const TODAY = new Date();

const AlarmModifiedInterface = {
  receiverName: '',
  contractNum: '',
  phoneNo0: '010',
  phoneNo1: '',
  phoneNo2: '',
  modifiedPhoneNo0: '',
  modifiedPhoneNo1: '',
  modifiedPhoneNo2: '',
};

const RADIO_INPUT_VALUES = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTH: 'THREE_MONTH',
};

const CouponAlarm = ({ hasWritePermission }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);
  const vendorList = useSelector((state) => state.common.vendorList);
  const couponIssueTargetList = useSelector(
    (state) => state.coupon.retrieveCouponTalkdreamResultList
  );

  const [isOpenMessagePopup, setIsOpenMessagePopup] = useState(false);
  const [currentMessageContents, setCurrentMessageContents] = useState(null);

  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenChangeInfo, setIsOpenChangeInfo] = useState(false);
  const [tableOriginalData, setTableOriginalData] = useState({
    category: '',
    requestDatetime: '',
    vendorKey: '',
  });

  const couponAlarmSearchRef = useRef({
    getCategorySelectWatch: () => {},
  });

  const couponAlarmCategoryValue =
    couponAlarmSearchRef.current.getCategorySelectWatch();

  const handleMessagePopup = useCallback((contents) => {
    setIsOpenMessagePopup(true);
    setCurrentMessageContents(contents);
  }, []);

  const vendorSearchOptions = useMemo(() => {
    let options = {
      guide: '제휴사명을 선택해주세요.',
      list: [],
    };
    if (vendorList.length > 0) {
      options.list = [
        ...options.list,
        ...vendorList
          .filter((item) => item.vendorKey !== 'VENDOR.00001')
          .map((item) => {
            return {
              id: item.vendorKey,
              title: item.companyName,
            };
          }),
      ];
    }
    return options;
  }, [vendorList]);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const vendorKey = searchParams.get('vendorKey');
  const messageTarget = searchParams.get('messageTarget');
  const category = searchParams.get('category');

  const searchForms = useForm();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: AlarmModifiedInterface,
    mode: 'onChange',
  });

  // const fromDateValue = watch('fromDate');
  // const toDateValue = watch('toDate', TODAY);

  // const radioDateWatch = useWatch({
  //   control,
  //   name: 'radioDate',
  //   defaultValue: TODAY,
  // });

  // const [filtered, setFiltered] = useState(couponIssueTargetList);
  // const TABLE_DATA_LENGTH = useMemo(
  //   () => filtered && filtered.length,
  //   [filtered]
  // );

  const columns = useMemo(
    () => [
      columnHelper.accessor('messageTarget', {
        id: 'messageTarget',
        header: () => <span>수신</span>,
        cell: (info) => {
          switch (info.getValue()) {
            case 'CUSTOMER':
              return <span>고객</span>;
            case 'VENDOR':
              return <span>기타</span>;
            case 'LGE':
              return <span>LGE</span>;
            default:
              return info.getValue();
          }
        },
        size: 60,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
        size: 80,
      }),
      columnHelper.accessor('categoryDesc', {
        id: 'categoryDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림 구분</span>,
        size: 100,
      }),
      columnHelper.accessor('content', {
        id: 'content',
        cell: (info) => {
          return (
            <div
              className={css.ellipsis}
              onClick={() => handleMessagePopup(info.getValue())}
            >
              {info.getValue()}
            </div>
          );
        },
        header: () => <span>알림톡 내용</span>,
      }),
      columnHelper.accessor('totalCount', {
        id: 'totalCount',
        cell: (info) => {
          const {
            failedCount,
            totalCount,
            waitingCount,
            requestDatetime,
            category,
            vendorKey,
          } = info?.row?.original || {};
          return (
            <TextButton
              styleType={'btn_txt04'}
              onClick={() => {
                setTableOriginalData((prevState) => ({
                  ...prevState,
                  requestDatetime: requestDatetime,
                  category: category,
                  vendorKey: vendorKey,
                }));
                setIsOpenDetail(true);
              }}
            >
              <span className={cn('color-default')}>총 {totalCount}건 / </span>
              {waitingCount > 0 && (
                <span className={cn('color-pink')}>
                  {waitingCount}건 대기 /{' '}
                </span>
              )}
              <span className={cn('color-pink')}>{failedCount}건 실패</span>
            </TextButton>
          );
        },
        header: () => <span>최종 결과</span>,
        size: 100,
      }),
    ],
    [handleMessagePopup]
  );

  const [startDate, setStartDate] = useState(TODAY);
  const [endDate, setEndDate] = useState(TODAY);

  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);

  const handleStartDate = (date) => {
    if (date <= endDate) {
      setStartDate(date);
    } else {
      dispatch(
        openModalAlert({
          message: '시작 날짜는 종료 날짜보다 이전이어야 합니다.',
        })
      );
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      setEndDate(date);
    } else {
      dispatch(
        openModalAlert({
          message: '종료 날짜는 시작 날짜보다 이후이어야 합니다.',
        })
      );
    }
  };

  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '100');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    const tempParams = {
      // fromDate: convertDateToString2(fromDateValue),
      fromDate: convertDateToString2(startDate),
      toDate: convertDateToString2(endDate),
      messageTarget: messageTarget ?? '',
      vendorKey: vendorKey ?? '',
      category: couponAlarmCategoryValue,
      limit: limit ?? '100',
      page: page ?? '1',
    };
    dispatch(getRetrieveCouponTalkdreamResultListAPI(tempParams));
  }, [
    dispatch,
    startDate,
    endDate,
    messageTarget,
    vendorKey,
    limit,
    page,
    couponAlarmCategoryValue,
  ]);

  const onSearchClick = useCallback(
    (data) => {
      const {
        targetSearchCondition,
        vendorSearchCondition,
        alarmSearchCondition,
      } = data;

      const tempParams = {
        // fromDate: convertDateToString2(fromDateValue),
        fromDate: convertDateToString2(startDate),
        toDate: convertDateToString2(endDate),
        messageTarget:
          targetSearchCondition === 'ALL' ? '' : targetSearchCondition,
        vendorKey: vendorSearchCondition ?? '',
        category: alarmSearchCondition,
        limit: limit ?? '100',
        page: page ?? '1',
      };

      dispatch(getRetrieveCouponTalkdreamResultListAPI(tempParams));
    },
    [startDate, endDate, limit, page, dispatch]
  );

  // FIXME: 테이블 리스트에 성공 된 건수의 합계 표시하는게 맞는지 확인 후 적용
  const totalSuccessedLength = useMemo(() => {
    return (
      couponIssueTargetList &&
      couponIssueTargetList
        .map((item) => item.totalCount - item.failedCount)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0)
    );
  }, [couponIssueTargetList]);

  // useEffect(() => {
  //   if (radioDateWatch !== undefined) {
  //     switch (radioDateWatch) {
  //       case RADIO_INPUT_VALUES.TODAY:
  //         setValue('fromDate', new Date(DATE_NOW));
  //         break;
  //       case RADIO_INPUT_VALUES.WEEK:
  //         setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 7));
  //         break;
  //       case RADIO_INPUT_VALUES.ONE_MONTH:
  //         setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30));
  //         break;
  //       case RADIO_INPUT_VALUES.THREE_MONTH:
  //         setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 90));
  //         break;
  //       default:
  //         break;
  //     }
  //     setValue('toDate', new Date(DATE_NOW));
  //   }
  // }, [radioDateWatch, setValue]);

  return (
    <>
      <h2 className={css.pageTitle}>쿠폰 발행 알림</h2>
      {/* <div className={cn('dateBox')}>
        <HFCalendar
          name={'fromDate'}
          control={control}
          disabledDateTypeList={[
            DISABLED_DATE_TYPE.HOLIDAY,
            DISABLED_DATE_TYPE.WEEKEND,
          ]}
        />
        <span className={cn('dash')}>-</span>
        <HFCalendar
          name={'toDate'}
          control={control}
          disabledDateTypeList={[
            DISABLED_DATE_TYPE.HOLIDAY,
            DISABLED_DATE_TYPE.WEEKEND,
          ]}
        />
        <div className={css.radioBox}>
          <HFRadioButton
            name={'radioDate'}
            register={register}
            id={`alarm-today`}
            value={RADIO_INPUT_VALUES.TODAY}
          >
            오늘
          </HFRadioButton>
          <HFRadioButton
            name={'radioDate'}
            register={register}
            id={`alarm-week`}
            value={RADIO_INPUT_VALUES.WEEK}
          >
            일주일
          </HFRadioButton>
          <HFRadioButton
            name={'radioDate'}
            register={register}
            id={`alarm-oneMonth`}
            value={RADIO_INPUT_VALUES.ONE_MONTH}
          >
            1개월
          </HFRadioButton>
          <HFRadioButton
            name={'radioDate'}
            register={register}
            id={`alarm-threeMonth`}
            value={RADIO_INPUT_VALUES.THREE_MONTH}
          >
            3개월
          </HFRadioButton>
        </div>
      </div> */}
      <div className={cn('innerBox', 'date')}>
        <Calendar
          className={cn('calendar')}
          hasTitle={false}
          hasPeriodInput={true}
          periodInputType={'Type1'}
          startDate={startDate}
          setStartDate={setStartDate}
          handleStartDate={handleStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleEndDate={handleEndDate}
          minDate={minDate}
          defaultDateRange={'oneMonthAgo'}
        />
      </div>
      <AlarmListTable startDate={startDate} endDate={endDate} />
      <FormProvider {...searchForms}>
        <CouponAlarmSearchArea
          ref={couponAlarmSearchRef}
          targetSearchOptions={targetSearchOptions}
          vendorSearchOptions={vendorSearchOptions}
          alarmSearchOptions={alarmSearchOptions}
          onSearchClick={onSearchClick}
          totalLength={totalSuccessedLength}
        />
      </FormProvider>
      {/* FIXME: test 버튼 푸시 x */}
      {/* <div className={css.mt20}>
        <Button onClick={() => setIsOpenDetail(true)}>
          알림 발송 결과 상세 test
        </Button>
      </div> */}
      <TableV8ManualPage
        columns={columns}
        data={couponIssueTargetList}
        // data={tableData}
        page={page}
        limit={limit ?? undefined}
        hasPageSize={false}
        hasCsvDownload={false}
        handlePageCallback={handlePageCallback}
      />
      <PopUp
        title={'알림 발송 결과 상세'}
        isOpenPopUp={isOpenDetail}
        onRequestClose={() => setIsOpenDetail(false)}
        closeButton={true}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                subType={'type03'}
                onClick={() => setIsOpenDetail(false)}
                fullWidth
              >
                닫기
              </Button>
            </div>
          </div>,
        ]}
      >
        <AlarmRequestList resultDetailListParam={tableOriginalData} />
      </PopUp>
      <PopUp isOpenPopUp={loading} closeButton={false}>
        <Loading />
      </PopUp>
      <PopUp
        title={'알림톡 확인'}
        isOpenPopUp={isOpenMessagePopup}
        onRequestClose={() => setIsOpenMessagePopup(false)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='stay'
                subType={'type02'}
                onClick={() => {
                  setIsOpenMessagePopup(false);
                }}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={css.messagePreview}>{currentMessageContents}</div>
      </PopUp>
    </>
  );
};

export default CouponAlarm;
