import { useState, memo, useContext, useEffect } from 'react';
import css from './SelectBox.module.scss';
import classNames from 'classnames/bind';
import { searchContext } from '@components/Search/SearchContainer';
import { SEARCH_CONTAINER_QUERY_TYPE } from '@data/Const';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace SelectBox
 * @param {any} optionList SelectBox 실제 데이터 리스트.
 * @param {string} currentIndex SelectBox 의 현재 index.
 * @param {string} currentLabel SelectBox 의 현재 값.
 * @param {function} handleSelectIndex SelectBox 값 선택 함수.
 * @param {boolean} fullWidth SelectBox 넓이 100% 여부.
 * @param {boolean} disabled SelectBox 비활성화 여부.
 * @param {string} type DXC 디자인을 적용하기 위한 타입
 * @param {string} id for문 key에 사용하는 id
 * @param {string} titleKey for문에서 값을 추출하기 위한 key
 * @description 공통적으로 사용하는 SelectBox 컴포넌트.
 */
const SelectBox = ({
  title,
  id,
  titleKey,
  optionList,
  currentIndex,
  currentLabel,
  handleSelectIndex,
  fullWidth,
  disabled,
  searchConditionIndex,
  type,
  className,
  ...rest
}) => {
  const [isShow, setIsShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(optionList[currentIndex]);
  const context = useContext(searchContext);

  const handleShowEvent = () => {
    !disabled && setIsShow(!isShow);
  };

  const handleItemClick = (event, item) => {
    setSelectedItem(item);
    handleSelectIndex && handleSelectIndex(event);
    item.fn && item.fn();
  };

  useEffect(() => {
    if (context) {
      const { searchQuery, setSearchQuery } = context;

      switch (searchQuery[searchConditionIndex].type) {
        case SEARCH_CONTAINER_QUERY_TYPE.SELECT:
          setSearchQuery &&
            setSearchQuery((prev) => {
              prev[searchConditionIndex].value = selectedItem.id;
              return prev;
            });
          return;
        case SEARCH_CONTAINER_QUERY_TYPE.KEYWORD:
          setSearchQuery &&
            setSearchQuery((prev) => {
              prev[searchConditionIndex].targetKey =
                optionList[currentIndex].id;
              return prev;
            });
          return;
        default:
          return;
      }
    }
  }, [context, selectedItem, optionList, searchConditionIndex, currentIndex]);

  return (
    <div className={cn(css.container, className)}>
      {title ? <span className={css.title}>{title}</span> : null}
      <div
        className={cn('selectbox', type, isShow ? 'active' : null, {
          fullWidth,
          disabled,
        })}
        onClick={handleShowEvent}
        {...rest}
      >
        {type === 'type02' && <span className={cn('ico', 'ico_user')}></span>}
        <div className={css.label}>
          <div>{currentLabel}</div>
          {/* <div>{optionList[currentIndex]?.title}</div> */}
        </div>
        <ul className={cn('optionList', isShow && 'showOption')}>
          {!disabled &&
            optionList.map((item) => (
              <li
                key={item?.[id]}
                className={css.optionItem}
                onClick={(event) => handleItemClick(event, item)}
              >
                {item?.[titleKey]}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

SelectBox.defaultProps = {
  /** SelectBox 선택된 값 */
  currentIndex: 0,
  /** SelectBox 실제 데이터 리스트 */
  optionList: [],
  /** SelectBox 타입 지정 (type01, type02, type03, type04) */
  type: 'type01',
  /** SelectBox 넓이 100% */
  fullWidth: false,
  /** SelectBox 비활성화 활성화 토글 */
  disabled: false,
  id: 'id',
  titleKey: 'title',
};

export default memo(SelectBox);
