import React from 'react';
import classNames from 'classnames/bind';
import css from './AccountingManageSearchArea.module.scss';
import { Button } from '@components/index';
import HFInput from '@components/_ReactHookForm/HFInput';
import { useFormContext } from 'react-hook-form';

const cn = classNames.bind(css);

const AccountingManageSearchArea = ({ onSearchClick }) => {
  const { register, control, handleSubmit } = useFormContext();

  return (
    <div className={css.searchContainer}>
      <div className={css.inputWrap}>
        <form className={css.form}>
          <HFInput
            register={register}
            name={'keyword'}
            type={'text'}
            className={cn('input')}
            placeholder={'상품 아이디를 입력해주세요.'}
          />
          <Button
            styleType={'btn01'}
            subType={'type03'}
            className={cn('btn')}
            onClick={handleSubmit(onSearchClick)}
          >
            조정 항목 추가
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AccountingManageSearchArea;
