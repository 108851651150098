import classNames from 'classnames/bind';
import css from './HFRadioButton.module.scss';

const cn = classNames.bind(css);

const HFRadioButton = ({
  name,
  register,
  required,
  disabled,
  id,
  className,
  children,
  defaultValue,
  ...rest
}) => {
  return (
    <div className={cn('radioButton')}>
      <input
        id={id}
        type='radio'
        defaultChecked={defaultValue === rest.value}
        {...register(name, { required, disabled })}
        {...rest}
      />
      <label htmlFor={id} className={css.label}>
        <span>{children}</span>
      </label>
    </div>
  );
};

export default HFRadioButton;
