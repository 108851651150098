import {
  ROUTER_PATH,
  BASE_URL,
  PRODUCT_SUB_PATH,
  DELIVERY_SUB_PATH,
  COUPON_SUB_PATH,
  REFUND_SUB_PATH,
  ACCOUNTING_SUB_PATH,
} from '@router/routerPath';

export const TOTAL_PAGE_MAP = {
  상품관리: [
    { title: '상품등록', path: BASE_URL + PRODUCT_SUB_PATH.REGISTER },
    { title: '상품조회', path: BASE_URL + PRODUCT_SUB_PATH.LIST },
  ],

  배송관리: [
    { title: '주문 배송 처리', path: BASE_URL + DELIVERY_SUB_PATH.ORDER },
    { title: '알림 등록/결과 관리', path: BASE_URL + DELIVERY_SUB_PATH.RESULT },
  ],

  쿠폰관리: [
    { title: '쿠폰 생성 등록', path: BASE_URL + COUPON_SUB_PATH.REGISTER },
    {
      title: '쿠폰 발행',
      path: BASE_URL + COUPON_SUB_PATH.MANAGEMENT + '?page=1&limit=100',
    },
    {
      title:
        process.env.REACT_APP_MODE === 'LG'
          ? '쿠폰 사용 완료 승인'
          : '쿠폰 사용 완료 요청',
      path: BASE_URL + COUPON_SUB_PATH.RESULT,
    },
    {
      title: '쿠폰 현황 리스트',
      path: BASE_URL + COUPON_SUB_PATH.LIST + '?page=1&limit=100',
    },
    { title: '쿠폰 발행 알림', path: BASE_URL + COUPON_SUB_PATH.ALARM },
  ],

  환불관리: [
    {
      title: '기간 내 미사용 쿠폰 환불',
      path: BASE_URL + REFUND_SUB_PATH.UNUSED,
    },
    { title: '고객 요청 환불', path: BASE_URL + REFUND_SUB_PATH.CUSTOMER },
    { title: '환불 알림', path: BASE_URL + REFUND_SUB_PATH.ALARM },
    {
      title: '제휴사 / 고객 환불 조회',
      path: BASE_URL + REFUND_SUB_PATH.RETRIEVE,
    },
  ],

  정산관리: [
    {
      title: '업체별 매출 조정 및 확정',
      path: BASE_URL + ACCOUNTING_SUB_PATH.MANAGEMENT,
    },
    {
      title: '업체별 거래액 송부 및 세금계산서',
      path: BASE_URL + ACCOUNTING_SUB_PATH.TAXBILL,
    },
    {
      title: '업체별 현황 데이터 조회',
      path: BASE_URL + ACCOUNTING_SUB_PATH.LIST,
    },
  ],
  권한관리: [
    {
      title: '계정 관리 (제휴사)',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab1' } },
    },
    {
      title: '계정 관리 (LGE)',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab2' } },
    },
    {
      title: '계정 권한 관리',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab3' } },
    },
  ],
};

//tab 이름
export const TAB_MAP = {
  '상품 등록': {
    title: '상품관리',
    subTitle: '상품등록',
    path: BASE_URL + PRODUCT_SUB_PATH.REGISTER,
  },
  '상품 조회': {
    title: '상품관리',
    subTitle: '상품조회',
    path: BASE_URL + PRODUCT_SUB_PATH.LIST,
  },
  '쿠폰 생성/등록': {
    title: '쿠폰관리',
    subTitle: '쿠폰 생성 등록',
    path: BASE_URL + COUPON_SUB_PATH.REGISTER,
  },
  '쿠폰 발행': {
    title: '쿠폰관리',
    subTitle: '쿠폰 발행',
    path: BASE_URL + COUPON_SUB_PATH.MANAGEMENT + '?page=1&limit=100',
  },
  '쿠폰 사용 완료 승인': {
    title: '쿠폰관리',
    subTitle:
      process.env.REACT_APP_MODE === 'LG'
        ? '쿠폰 사용 완료 승인'
        : '쿠폰 사용 완료 요청',
    path: BASE_URL + COUPON_SUB_PATH.RESULT,
  },
  '쿠폰 현황 리스트': {
    title: '쿠폰관리',
    subTitle: '쿠폰 현황 리스트',
    path: BASE_URL + COUPON_SUB_PATH.LIST + '?page=1&limit=100',
  },
  '쿠폰발행 알림': {
    title: '쿠폰관리',
    subTitle: '쿠폰발행 알림',
    path: BASE_URL + COUPON_SUB_PATH.ALARM,
  },
  '주문배송 처리': {
    title: '배송관리',
    subTitle: '주문 배송 처리',
    path: BASE_URL + DELIVERY_SUB_PATH.ORDER,
  },
  '알림등록/결과관리': {
    title: '배송관리',
    subTitle: '알림 등록/결과 관리',
    path: BASE_URL + DELIVERY_SUB_PATH.RESULT,
  },
  '기간 내 미사용 쿠폰 환불': {
    title: '환불관리',
    subTitle: '기간 내 미사용 쿠폰 환불',
    path: BASE_URL + REFUND_SUB_PATH.UNUSED,
  },
  '고객 요청 환불': {
    title: '환불관리',
    subTitle: '고객 요청 환불',
    path: BASE_URL + REFUND_SUB_PATH.CUSTOMER,
  },

  //Todo: 제휴사 / 고객 환불 조회으로 변경
  '제휴사 / 고객 환불 조회': {
    title: '환불관리',
    subTitle: '제휴사 / 고객 환불 조회',
    path: BASE_URL + REFUND_SUB_PATH.RETRIEVE,
  },
  '환불 알림': {
    title: '환불관리',
    subTitle: '환불 알림',
    path: BASE_URL + REFUND_SUB_PATH.ALARM,
  },
  //'Todo: 업체별 매출 확인으로 변경'
  '업체별 매출 확인': {
    title: '정산관리',
    subTitle: '업체별 매출 확인',
    path: BASE_URL + ACCOUNTING_SUB_PATH.MANAGEMENT,
  },
  //'Todo: 백엔드 연결'
  '업체별 매출 승인 및 확정': {
    title: '정산관리',
    subTitle: '업체별 매출 승인 및 확정',
    path: BASE_URL + ACCOUNTING_SUB_PATH.APPROVAL,
  },
  '업체별 거래액 송부 및 세금계산서': {
    title: '정산관리',
    subTitle: '업체별 거래액 송부 및 세금계산서',
    path: BASE_URL + ACCOUNTING_SUB_PATH.TAXBILL,
  },
  //개발제외 시나리오 0.90
  '업체별 정산내역 조회': {
    title: '정산관리',
    subTitle: '업체별 정산내역 조회',
    path: BASE_URL + ACCOUNTING_SUB_PATH.LIST,
  },
};

export const AUTHORITY_TAB = {
  권한관리: [
    {
      title: '계정 관리 (제휴사)',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab1' } },
    },
    {
      title: '계정 관리 (LGE)',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab2' } },
    },
    {
      title: '사업자 정보 관리',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab3' } },
    },
    {
      title: '계정 권한 관리',
      path: ROUTER_PATH.AUTHORITY,
      state: { state: { tabIndex: 'tab4' } },
    },
  ],
};

export const PATH_AUTHORITY_MAP = {
  [PRODUCT_SUB_PATH.REGISTER]: '상품 등록',
  [PRODUCT_SUB_PATH.LIST]: '상품 조회',
  [DELIVERY_SUB_PATH.ORDER]: '주문배송 처리',
  [DELIVERY_SUB_PATH.RESULT]: '알림등록/결과관리',
  [COUPON_SUB_PATH.REGISTER]: '쿠폰 생성/등록',
  [COUPON_SUB_PATH.MANAGEMENT]: '쿠폰 발행',
  [COUPON_SUB_PATH.RESULT]: '쿠폰 사용 완료 승인',
  [COUPON_SUB_PATH.LIST]: '쿠폰 현황 리스트',
  [COUPON_SUB_PATH.ALARM]: '쿠폰발행 알림',
  [REFUND_SUB_PATH.UNUSED]: '기간 내 미사용 쿠폰 환불',
  [REFUND_SUB_PATH.CUSTOMER]: '고객 요청 환불',
  [REFUND_SUB_PATH.VENDOR]: '제휴사 / 고객 환불 조회',
  [REFUND_SUB_PATH.ALARM]: '환불 알림',
  [ACCOUNTING_SUB_PATH.MANAGEMENT]: '업체별 매출 확인',
  [ACCOUNTING_SUB_PATH.APPROVAL]: '업체별 매출 승인 및 확정',
  [ACCOUNTING_SUB_PATH.TAXBILL]: '업체별 거래액 송부 및 세금계산서',
  [ACCOUNTING_SUB_PATH.LIST]: '업체별 정산내역 조회',
};

export const LEVEL_AUTHORITY_MAP = {
  LEVEL_1: {
    권한관리: [
      {
        title: '계정 관리 (제휴사)',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab1' } },
      },
      {
        title: '계정 관리 (LGE)',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab2' } },
      },
      {
        title: '계정 권한 관리',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab3' } },
      },
    ],
  },
  LEVEL_2: {
    권한관리: [
      {
        title: '계정 관리 (제휴사)',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab1' } },
      },
      {
        title: '계정 권한 관리',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab3' } },
      },
    ],
  },
  LEVEL_3_MASTER: {
    권한관리: [
      {
        title: '계정 관리 (제휴사)',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab1' } },
      },
    ],
  },
  DEVELOP: {
    권한관리: [
      {
        title: '계정 관리 (제휴사)',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab1' } },
      },
      {
        title: '계정 관리 (LGE)',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab2' } },
      },
      {
        title: '계정 권한 관리',
        path: ROUTER_PATH.AUTHORITY,
        state: { state: { tabIndex: 'tab3' } },
      },
    ],
  },
};

export const AUTH_ACCESS_MAP = [
  '상품관리',
  '쿠폰관리',
  '배송관리',
  '정산관리',
  '환불관리',
];
export const AUTH_SUB_ACCESS_MAP = {
  상품관리: ['상품 등록', '상품 조회'],
  쿠폰관리: [
    '쿠폰 생성/등록',
    '쿠폰 발행',
    '쿠폰 사용 완료 승인',
    '쿠폰 현황 리스트',
    '쿠폰발행 알림',
  ],
  배송관리: ['주문배송 처리', '알림등록/결과관리'],
  환불관리: [
    '기간 내 미사용 쿠폰 환불',
    '고객 요청 환불',
    '제휴사 / 고객 환불 조회',
    '환불 알림',
  ],
  정산관리: [
    '업체별 매출 확인',
    '업체별 매출 승인 및 확정',
    '업체별 거래액 송부 및 세금계산서',
    '업체별 정산내역 조회',
  ],
};
