import { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingTaxBill.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PopUpAlert, Button, SelectBox } from '@components/index';
import AccountingTaxBillStepFirst from './AccountingTaxBillStep/AccountingTaxBillStepFirst';
import AccountingTaxBillStepSecond from './AccountingTaxBillStep/AccountingTaxBillStepSecond';
import { useDispatch, useSelector } from 'react-redux';
import { useSelect } from '@hooks/index';
import { getExternalProcessDashboardAPI } from 'redux/action/settlementAction';
import { FormProvider, useForm } from 'react-hook-form';

const cn = classNames.bind(css);

const AccountingTaxBill = () => {
  const vendorList = useSelector((state) => state.common.vendorList);
  const externalProcessDashboard = useSelector(
    (state) => state.settlement.externalProcessDashboard
  );
  const [step, setStep] = useState('first');
  const [isOpenNextPopUp, setIsOpenNextPopUp] = useState(false);
  const [isOpenPrevPopUp, setIsOpenPrevPopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const searchForms = useForm();
  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, []);

  const selectVendorList = useMemo(() => {
    return vendorList.map((vendor) => ({
      id: vendor.companyCode,
      title: vendor.companyName,
      vendorKey: vendor.vendorKey,
    }));
  }, [vendorList]);

  const [selectedCompanyIndex, setSelectedCompanyIndex, selectedCompanyLabel] =
    useSelect({
      optionList: selectVendorList,
      initialIndex: 0,
      initialLabel: '업체를 선택해주세요',
    });

  useEffect(() => {
    console.log('location', location);
    console.log('params', params);
  }, []);

  const onClickNext = useCallback(() => {
    setIsOpenNextPopUp(!isOpenNextPopUp);
  }, []);

  const onClickPrev = useCallback(() => {
    if (step === 'second') {
      setIsOpenPrevPopUp(!isOpenPrevPopUp);
    } else {
      handleNavigate('/accounting/management');
    }
  }, [step]);

  const onClickNextConfirm = useCallback(() => {
    if (step === 'first') {
      setStep('second');
    } else {
      handleNavigate('/accounting/taxbill');
    }
    setIsOpenNextPopUp(false);
  }, [step]);

  const onClickPrevConfirm = useCallback(() => {
    if (step === 'second') {
      setStep('first');
    }
    setIsOpenPrevPopUp(false);
  }, [step]);

  const nextGuideText =
    '다음 단계로 이동 하시겠습니까? 마감 확정 페이지로 이동합니다.';

  useEffect(() => {
    //no params
    getExternalProcessDashboardAPI(dispatch, {});
  }, []);

  return (
    <>
      <h2 className={cn('pageTitle')}>업체별 거래액 송부 및 세금계산서</h2>

      <div className={cn('statusDashBoard')}>
        <div className={cn('adjusmentStatus')}>
          <dl>
            <dt>
              유상<span className={cn('type')}>GEVS</span>
            </dt>
            <dd>
              <ul>
                <li>
                  <span className={cn('type')}>거래액 정산 완료</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.gevsCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type03')}></i>
                  </strong>
                </li>
                <li>
                  <span className={cn('type')}>거래액 정산 중</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.gevsNotCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type01')}></i>
                  </strong>
                </li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt>
              유상<span className={cn('type')}>GERP</span>
            </dt>
            <dd>
              <ul>
                <li>
                  <span className={cn('type')}>세금계산서 발행완료</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.gerpCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type03')}></i>
                  </strong>
                </li>
                <li>
                  <span className={cn('type')}>세금계산서 발행대기</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.gerpNotCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type01')}></i>
                  </strong>
                </li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt>무상</dt>
            <dd>
              <ul>
                <li>
                  <span className={cn('type')}>세금계산서 요청 완료</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.taxBillRequestCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type03')}></i>
                  </strong>
                </li>
                <li>
                  <span className={cn('type')}>세금계산서 미요청</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.taxBillRequestNotCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type01')}></i>
                  </strong>
                </li>
                <li>
                  <span className={cn('type')}>세금계산서 수신 완료</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.taxBillResponseCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type03')}></i>
                  </strong>
                </li>
                <li>
                  <span className={cn('type')}>세금계산서 수신 미완료</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.taxBillResponseNotCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type01')}></i>
                  </strong>
                </li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt>
              무상<span className={cn('type')}>APMS</span>
            </dt>
            <dd>
              <ul>
                <li>
                  <span className={cn('type')}>거래액 정산 완료</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.apmsCompleteCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type03')}></i>
                  </strong>
                </li>
                <li>
                  <span className={cn('type')}>거래액 정산 대기</span>
                  <strong>
                    <span className={cn('num')}>
                      {externalProcessDashboard.apmsCompleteNotCompleteCnt}
                    </span>
                    <i className={cn('ico_act', 'type01')}></i>
                  </strong>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>

      <div className={cn('stepsFlow', 'type03', 'mt32')}>
        <div className={cn('steps', 'on')}>업체별 매출 조정 및 확정</div>
        <div className={cn('steps', 'on')}>
          업체별 거래액 송부 및 세금계산서
        </div>
      </div>

      {step === 'first' ? (
        <FormProvider {...searchForms}>
          <AccountingTaxBillStepFirst />
        </FormProvider>
      ) : (
        <AccountingTaxBillStepSecond />
      )}
      <div className={cn('stepsNav')}>
        {step !== 'first' && (
          <Button
            onClick={onClickPrev}
            type='button'
            className={cn('btn', 'btn01', 'type01')}
          >
            <i className={cn('ico_prev')}></i>이전 단계로 이동
          </Button>
        )}
        {step !== 'second' && (
          <Button
            type='button'
            className={cn('btn', 'btn01', 'type01')}
            onClick={onClickNext}
          >
            다음 단계로 이동<i className={cn('ico_next')}></i>
          </Button>
        )}
      </div>
      {/* 이전단계로 이동 popUp */}
      <PopUpAlert
        isOpenPopUp={isOpenPrevPopUp}
        onRequestClose={() => setIsOpenPrevPopUp(false)}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={onClickPrevConfirm}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        <div className={cn('nextGuideText')}>
          매출 조정 단계로 이동 하시겠습니까?
          <br /> 입력사항은 모두 지워집니다.
        </div>
      </PopUpAlert>

      {/* 다음단계로 이동 popUp */}
      <PopUpAlert
        isOpenPopUp={isOpenNextPopUp}
        onRequestClose={() => setIsOpenNextPopUp(false)}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={onClickNextConfirm}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        <div className={cn('nextGuideText')}>{nextGuideText}</div>
      </PopUpAlert>
    </>
  );
};

export default AccountingTaxBill;
