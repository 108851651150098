import { memo, useCallback, useEffect, useMemo } from 'react';
import css from './DetailBoxSmall.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_SUB_PATH, REFUND_SUB_PATH } from '@router/routerPath';
import { useDispatch, useSelector } from 'react-redux';
import {
  MERCHANDISE_SEARCH_CATEGORY,
  MERCHANDISE_SEARCH_TYPE,
  MILLISECONDS_PER_DAY,
} from '@data/Const';
import { createQueryString } from 'utils/utils';
import { getDashboardVendorRefundInfo } from 'redux/action/dashBoardAction';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace DetailBoxSmall
 * @param {string} title DetailBoxSmall의 타이틀.
 * @description Home 화면에서 사용하는 DetailBoxSmall 컴포넌트 (상품 관리, 환불 관리, 정산 관리).
 */

// TODO: 실제 데이터 연동했을 때 구조 변경 필요 (231121)
const DetailBoxSmall = ({ title }) => {
  const dispatch = useDispatch();
  const vendorKey = useSelector((state) => state.user.userData.vendorKey);
  const dashboardVendorMerchandiseInfo = useSelector(
    (state) => state.dashBoard.dashboardVendorMerchandiseInfo
  );
  const selectedVendorKey = useSelector(
    (state) => state.dashBoard.selectedVendorKey
  );
  const dashboardVendorRefundInfo = useSelector(
    (state) => state.dashBoard.dashboardVendorRefundInfo
  );

  useEffect(() => {
    getDashboardVendorRefundInfo(dispatch, {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' ? selectedVendorKey : vendorKey,
    });
  }, [vendorKey, selectedVendorKey]);

  const companyName = useSelector((state) => state.user.userData.companyName);
  const selectedCompanyName = useSelector(
    (state) => state.dashBoard.selectedCompanyName
  );

  // 상품 관리
  const DATE_NOW = Date.now();

  const criteriaSearchOptions = {
    guide: '',
    list: [
      { id: MERCHANDISE_SEARCH_CATEGORY.CR, title: '상품 등록일' },
      { id: MERCHANDISE_SEARCH_CATEGORY.SS, title: '판매 시작일' },
      { id: MERCHANDISE_SEARCH_CATEGORY.SE, title: '판매 종료일' },
      { id: MERCHANDISE_SEARCH_CATEGORY.AC, title: '승인 완료일' },
    ],
  };

  const detailedSearchOptions = {
    guide: '',
    list: [
      { id: MERCHANDISE_SEARCH_TYPE.FOCFLAG, title: '정산' },
      { id: MERCHANDISE_SEARCH_TYPE.VNAME, title: '제휴사명' },
      { id: MERCHANDISE_SEARCH_TYPE.MNAME, title: '상품명' },
    ],
  };

  const BASE_PARAMS = Object.freeze({
    vendorKey: null,
    searchCate: criteriaSearchOptions.list[0].id,
    searchPeriodType: 'MANUAL',
    preDefinedType: '',
    fromDate: new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30),
    toDate: new Date(DATE_NOW),
    searchType: detailedSearchOptions.list[0].id,
    detailCondition: '',
    salesStatus: '',
    approvalStatus: '',
  });

  const merchandisePath = (approvalStatus, salesStatus) => {
    return `/${PRODUCT_SUB_PATH.LIST}?${createQueryString({
      salesStatus: salesStatus,
      searchCategory: MERCHANDISE_SEARCH_CATEGORY.CR,
      approvalStatus: approvalStatus,
      fromDate: BASE_PARAMS.fromDate,
      toDate: BASE_PARAMS.toDate,
      searchType: MERCHANDISE_SEARCH_TYPE.VNAME,
      detailCondition:
        process.env.REACT_APP_MODE === 'LG' ? selectedCompanyName : companyName,
      page: '1',
      limit: '10',
    })}`;
  };

  const navigate = useNavigate();
  const boxType = useMemo(() => {
    let type = '';
    if (title === '상품 관리') {
      type = 'product';
    } else if (title === '환불 관리') {
      type = 'refund';
    } else {
      type = 'calculate';
    }
    return type;
  }, [title]);

  const boxType1 = useMemo(() => {
    if (title === '상품 관리') {
      return (
        <div>
          <div className={css.flex}>
            <dl>
              <dt>판매 중</dt>
              <dd>
                <strong
                  className={css.num}
                  onClick={() =>
                    handleNavigate(merchandisePath('APPROVED', 'USED'))
                  }
                >
                  {dashboardVendorMerchandiseInfo.activatedCnt}
                </strong>
                건
              </dd>
            </dl>
            <dl>
              <dt>승인 요청</dt>
              <dd>
                <strong
                  className={css.num}
                  onClick={() =>
                    handleNavigate(merchandisePath('UNDER_APPROVAL', ''))
                  }
                >
                  {dashboardVendorMerchandiseInfo.underApprovalCnt}
                </strong>
                건
              </dd>
            </dl>
          </div>
          <div className={css.subTitle}>
            <span className={css.type_color_red}>
              승인된 상품코드 중<br /> 판매중인 건 수
            </span>
            <span className={css.type_color_red}>
              제휴사별 등록한 상품 중<br /> LGE 승인 요청 건 수
            </span>
          </div>
        </div>
      );
    } else if (title === '환불 관리') {
      return (
        <dl>
          <dt>장기 미사용 쿠폰 건 수</dt>
          <dd>
            <strong
              className={css.num}
              onClick={() =>
                handleNavigate(
                  `/${REFUND_SUB_PATH.UNUSED}?${createQueryString({
                    vendorkey: selectedVendorKey,
                  })}`
                )
              }
            >
              {dashboardVendorRefundInfo?.unusedCouponRefundCnt}
            </strong>
            건
          </dd>
        </dl>
      );
    } else {
    }
  }, [dashboardVendorMerchandiseInfo, title]);

  const handleNavigate = useCallback((path, state) => {
    if (state) {
      navigate(path, { state: state });
    } else if (path) {
      navigate(path);
    }
  }, []);

  return (
    <div
      className={cn(
        css.detailBoxSmall,
        title !== '상품 관리' ? css.disabledDetailBox : null
      )}
    >
      <strong className={css.title_01}>
        {title}
        <p>{(title === '환불 관리' || title === '정산 관리') && `(개발중)`}</p>
      </strong>
      {boxType1}
      {boxType === 'refund' && (
        <>
          <dl>
            <dt>고객 요청 건 수</dt>
            <dd>
              <span className={css.mr10}>쿠폰</span>
              <strong
                className={css.num}
                onClick={() =>
                  handleNavigate(
                    `/${REFUND_SUB_PATH.CUSTOMER}?${createQueryString({
                      vendorkey: selectedVendorKey,
                      category: 'COUPON_REFUND_TARGET',
                    })}`
                  )
                }
              >
                {dashboardVendorRefundInfo?.couponCustomerRefundCnt}
              </strong>
              건
            </dd>
            <dd>
              <span className={css.mr10}>배송</span>
              <strong
                className={css.num}
                onClick={() =>
                  handleNavigate(
                    `/${REFUND_SUB_PATH.CUSTOMER}?${createQueryString({
                      vendorkey: selectedVendorKey,
                      category: 'DELIVERY_REFUND_TARGET',
                    })}`
                  )
                }
              >
                {dashboardVendorRefundInfo?.deliveryCustomerRefundCnt}
              </strong>
              건
            </dd>
          </dl>
        </>
      )}
      {boxType === 'calculate' && (
        <div className={css.mt32}>
          <div className={cn('ico_act', 'type01')}></div>당월 정산 마감 2일
          전입니다.
        </div>
      )}
    </div>
  );
};

DetailBoxSmall.defaultProps = {};

export default memo(DetailBoxSmall);
