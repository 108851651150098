import { createPortal } from 'react-dom';

const Portal = ({ children, index }) => {
  const modalRoot = document.getElementById(
    index ? `portal-popup-${index}` : 'portal-popup'
  );
  return createPortal(children, modalRoot);
};

export default Portal;
