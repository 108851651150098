import { TAxios } from 'redux/function/TAxios';
import { openModalAlert } from '@feature/common/commonSlice';
import { ROUTER_PATH } from '@router/routerPath';
import {
  merchandiseDashboard,
  dashboardMainPanel,
  couponRegisterTargetDashboard,
  dashboardCouponInfo,
  dashboardMerchandiseInfo,
  dashboardVendorMerchandiseInfo,
  dashboardVendorCouponInfo,
  dashboardDeliveryInfo,
  dashboardVendorDeliveryInfo,
  dashboardRefundInfo,
  dashboardVendorRefundInfo,
} from '@feature/dashBoard/dashBoardSlice';

/**
 * @category Action
 * @namespace getMerchandiseDashboard
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @property {string} params.authority - 사용자 Level
 * @property {string} params.vendorKey - 사용자 제휴사 key
 */

const getMerchandiseDashboard = (dispatch, params) => {
  const URL = '/api/1.0/app/merchandise/retrieveMerchandiseDashboard';

  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs?.dashboardInfo;
    if (dashboardInfo) {
      dispatch(merchandiseDashboard(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('retrieveMerchandiseDashboard onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

const getDashboardMainPanelInfo = (dispatch) => {
  const URL = '/api/1.0/app/dashboard/getDashboardMainPanelInfo';

  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs?.list;
    if (dashboardInfo) {
      dispatch(dashboardMainPanel(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('getDashboardMainPanelInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getDashboardCouponInfo = (dispatch) => {
  const URL = '/api/1.0/app/dashboard/getDashboardCouponInfo';

  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs;
    if (dashboardInfo) {
      dispatch(dashboardCouponInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('dashboardCouponInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getDashboardVendorCouponInfo = (dispatch, params) => {
  const URL = '/api/1.0/app/dashboard/getDashboardVendorCouponInfo';
  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs.vendorCouponInfo[0];
    if (dashboardInfo) {
      dispatch(dashboardVendorCouponInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('getDashboardVendorCouponInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

const getDashboardMerchandiseInfo = (dispatch) => {
  const URL = '/api/1.0/app/dashboard/getDashboardMerchandiseInfo';
  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs;
    if (dashboardInfo) {
      dispatch(dashboardMerchandiseInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('getDashboardMerchandiseInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getDashboardVendorMerchandiseInfo = (dispatch, params) => {
  const URL = '/api/1.0/app/dashboard/getDashboardVendorMerchandiseInfo';
  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs.vendorMerchandiseInfo[0];
    if (dashboardInfo) {
      dispatch(dashboardVendorMerchandiseInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('tDashboardVendorMerchandiseInfo  onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

const getDashboardRefundInfo = (dispatch) => {
  const URL = '/api/1.0/app/dashboard/getDashboardRefundInfo';
  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs;
    if (dashboardInfo) {
      dispatch(dashboardRefundInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('getDashboardMerchandiseInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getCouponRegisterTargetDashboard = (dispatch, params) => {
  const URL = '/api/1.0/app/coupon/retrieveCouponRegisterTargetDashboard';

  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs?.dashboardInfo;
    if (dashboardInfo) {
      dispatch(couponRegisterTargetDashboard(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('retrieveCouponRegisterTargetDashboard onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

const getDashboardDeliveryInfo = (dispatch) => {
  const URL = '/api/1.0/app/dashboard/getDashboardDeliveryInfo';

  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs;
    if (dashboardInfo) {
      dispatch(dashboardDeliveryInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('dashboardDeliveryInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getDashboardVendorDeliveryInfo = (dispatch, param) => {
  const URL = '/api/1.0/app/dashboard/getDashboardVendorDeliveryInfo';

  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs?.vendorDeliveryInfo;
    if (Array.isArray(dashboardInfo) && dashboardInfo.length > 0) {
      const [ret] = dashboardInfo;
      dispatch(dashboardVendorDeliveryInfo(ret));
    }
  };
  const onFail = (response) => {
    console.log('getDashboardVendorDeliveryInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, param, onSuccess, onFail, false);
};

const getDashboardVendorRefundInfo = (dispatch, params) => {
  const URL = '/api/1.0/app/dashboard/getDashboardVendorRefundInfo';
  const onSuccess = (response) => {
    const dashboardInfo = response?.data?.rs?.vendorDeliveryInfo;
    if (dashboardInfo) {
      dispatch(dashboardVendorRefundInfo(dashboardInfo));
    }
  };
  const onFail = (response) => {
    console.log('getDashboardVendorRefundInfo onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

export {
  getMerchandiseDashboard,
  getDashboardMainPanelInfo,
  getDashboardCouponInfo,
  getDashboardVendorCouponInfo,
  getDashboardMerchandiseInfo,
  getDashboardRefundInfo,
  getDashboardVendorMerchandiseInfo,
  getDashboardDeliveryInfo,
  getDashboardVendorRefundInfo,
  getCouponRegisterTargetDashboard,
  getDashboardVendorDeliveryInfo,
};
