import { TAxios } from 'redux/function/TAxios';
import { setUserData } from '@feature/user/userSlice';
import {
  getVendorAuthorityList,
  getInternalAuthorityList,
} from './AuthorityAction';
import { ROUTER_PATH } from '@router/routerPath';
import { MakeTwoDigitDate } from '@utils';
import store from '@store/index';
import { setSessionExpiredPopup } from '@feature/common/commonSlice';
import { openModalAlert } from '@feature/common/commonSlice';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    // 웹 폼 데이터를 인코딩한 형식, HTML 폼을 통해 전송되는 데이터를 나타낸다.
  },
};

// 로그인 관리자 승인 여부 판별하기 위한 Api 함수(LG)
// const getCheckApprovalStatusLg = (handleNavigate, params) => {
//   const URL = `/api/1.0/app/auth/retrieveInternalUserList`;
//   const onSuccess = (response) => {
//     console.log('LGuser test', response);
//     // FIXME: 테스트 위해 임시로 분기 반대로 처리
//     if (response.data.rs.list[0].approvalStatus !== 'APPROVED') {
//       dispatch(openModalAlert({message:'로그인 성공'));
//       handleNavigate(ROUTER_PATH.HOME);
//     } else dispatch(openModalAlert({message:'관리자 승인 후 사용 가능 합니다.'));
//   };
//   const onFail = (response) => {
//     console.log('onFail', response);
//     dispatch(openModalAlert({message:'updateUserAuthority failure'));
//   };
//   TAxios(
//     undefined,
//     undefined,
//     'get',
//     URL,
//     params,
//     onSuccess,
//     onFail,
//     false
//   );
// };

// const getCheckApprovalStatusVendor = (handleNavigate, params) => {
//   const URL = `/api/1.0/app/auth/retrieveVendorUserList`;
//   const onSuccess = (response) => {
//     // FIXME: 테스트 위해 임시로 분기 반대로 처리
//     if (response.data.rs.list[0].approvalStatus === 'APPROVED') {
//       dispatch(openModalAlert({message:'로그인 성공'));
//       handleNavigate(ROUTER_PATH.HOME);
//     } else dispatch(openModalAlert({message:'관리자 승인 후 사용 가능 합니다.'));
//   };
//   const onFail = (response) => {
//     console.log('onFail', response);
//     dispatch(openModalAlert({message:'updateUserAuthority failure'));
//   };
//   TAxios(
//     undefined,
//     undefined,
//     'get',
//     URL,
//     params,
//     onSuccess,
//     onFail,
//     false
//   );
// };

const postLogin = (dispatch, handleNavigate, params, setIsOpenFail) => {
  const URL = '/api/1.0/app/login/doLogin';

  const onSuccess = (response) => {
    if (process.env.REACT_APP_MODE === 'LG') {
      if (response.data.rs === null) {
        dispatch(
          openModalAlert({ message: '아이디 / 패스워드가 일치 하지 않습니다.' })
        );
      } else if (
        response.data?.rs?.authority === 'LEVEL_1' ||
        response.data?.rs?.authority === 'LEVEL_2'
      ) {
        const TODAY = new Date();
        const year = TODAY.getFullYear();
        const month = MakeTwoDigitDate(TODAY.getMonth() + 1);
        const date = MakeTwoDigitDate(TODAY.getDate() + 1);
        const toDate = `${year}${month}${date}`;

        const authParams = {
          fromDate: '20230801',
          toDate,
          approvalStatus: '',
          searchType: 'USER_ID',
          detailCondition: response.data?.rs?.userId,
          limit: '100',
          page: '1',
        };
        const myAuth = true;
        dispatch(setUserData(response.data.rs));
        getInternalAuthorityList(
          dispatch,
          authParams,
          myAuth,
          handleNavigate,
          setIsOpenFail
        );
        // // 로그인 관리자 승인 여부 판별하기 위한 Api 함수
        // getCheckApprovalStatusLg(handleNavigate, authParams);
      } else {
        dispatch(openModalAlert({ message: 'LEVEL 1 or 2 가 아닙니다.' }));
      }
    } else {
      if (response.data.rs === null) {
        dispatch(
          openModalAlert({ message: '아이디 / 패스워드가 일치 하지 않습니다.' })
        );
      } else if (response.data?.rs?.authority === 'LEVEL_3') {
        const TODAY = new Date();
        const year = TODAY.getFullYear();
        const month = MakeTwoDigitDate(TODAY.getMonth() + 1);
        const date = MakeTwoDigitDate(TODAY.getDate() + 1);
        const toDate = `${year}${month}${date}`;

        const authParams = {
          fromDate: '20230801',
          toDate,
          approvalStatus: '',
          searchType: 'USER_ID ',
          detailCondition: response.data?.rs?.userId,
          limit: '100',
          page: '1',
        };

        const myAuth = true;
        dispatch(setUserData(response.data.rs));
        getVendorAuthorityList(
          dispatch,
          authParams,
          myAuth,
          handleNavigate,
          setIsOpenFail
        );
        // getUserAuthorityList(dispatch, authParams);
        // 로그인 관리자 승인 여부 판별하기 위한 Api 함수
        // getCheckApprovalStatusVendor(handleNavigate, authParams);
      } else {
        openModalAlert({ message: 'LEVEL 3이 아닙니다.' });
      }
    }
  };
  const onFail = (response) => {
    console.log('login onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

const postLogout = (handleNavigate) => {
  const URL = '/api/1.0/app/login/doLogout';

  const onSuccess = () => {
    handleNavigate(ROUTER_PATH.ROOT);
  };
  const onFail = (response) => {
    console.log('login onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    {},
    onSuccess,
    onFail,
    true,
    header
  );
};

const postLogoutBySessionExpired = () => {
  const URL = '/api/1.0/app/login/doLogout';

  const onSuccess = () => {
    window.location.href = window.location.origin;
    return store.dispatch(setSessionExpiredPopup(false));
  };
  const onFail = (response) => {
    console.log('postLogoutBySessionExpired onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    {},
    onSuccess,
    onFail,
    true,
    header
  );
};

const postCreateInternalUser = (dispatch, params, handleCancel) => {
  const URL = '/api/1.0/app/user/createInternalUser';

  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      dispatch(openModalAlert({ message: '계정 신청이 완료 되었습니다.' }));
      handleCancel();
    } else dispatch(openModalAlert({ message: '정보를 확인해주세요.' }));
  };
  const onFail = (response) => {
    console.log('login onFail ', response);
    dispatch(openModalAlert({ message: '계정 신청 실패.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

export {
  postLogin,
  postLogout,
  postLogoutBySessionExpired,
  postCreateInternalUser,
};
