import { memo, useMemo } from 'react';
import css from './NoProduct.module.scss';
import classNames from 'classnames/bind';
import { Button } from '@components/';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { PRODUCT_SUB_PATH } from '@router/routerPath';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace DetailBoxLarge
 * @param {string} type DetailBoxLarge의 타입 (delivery | coupon)
 * @description Home 화면에서 사용하는 DetailBoxLarge 컴포넌트 (배송 관리, 쿠폰 관리).
 */

const NoProduct = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback((path) => {
    navigate(path);
    //eslint-disable-next-line
  }, []);

  return (
    <div className={css['main_nodata']}>
      <div>
        <strong>판매된 상품을 확인하신 후 정상적인 이용이 가능합니다.</strong>
      </div>
      <div>
        <strong>(등록 상품 승인 필요)</strong>
      </div>
      <div className={css['btn_container4']}>
        <Button
          styleType={'btn01'}
          subType={'type02'}
          onClick={() => {
            handleNavigate(`/${PRODUCT_SUB_PATH.LIST}`);
          }}
        >
          상품 조회로 이동
        </Button>
      </div>
    </div>
  );
};

NoProduct.defaultProps = {
  type: 'coupon',
};

export default memo(NoProduct);
