import React, { useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import css from './Loading.module.scss';
import classNames from 'classnames/bind';
import loading from '@assets/img/loading.svg';

import { writeLocalStorage } from 'utils/utils';

const Loading = ({ onRequestClose }) => {
  const cn = classNames.bind(css);

  // const closeOnBoardingPopUp = () => {
  //   writeLocalStorage('firstEntry', false);
  //   onRequestClose();
  // };

  return (
    <div className={css.layerPopup}>
      <div className={css.popup}>
        <strong className={css.popup_title_center}>잠시만 기다려주세요.</strong>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10vh',
          }}
        >
          <img src={loading} alt='loading...' />
        </div>
      </div>
    </div>
  );
};
export default memo(Loading);
