import { memo, useEffect, useState } from 'react';
import css from './PartnerList.module.scss';
import classNames from 'classnames/bind';
import img_slogan from '@assets/img/img_slogan.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { register } from 'swiper/element/bundle';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedCompanyName,
  setSelectedVendorKey,
} from '@feature/dashBoard/dashBoardSlice';
register();

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace PartnerList
 * @description Home 화면에서 사용하는 PartnerList 컴포넌트.
 */

// swiper 커스텀 버튼(이전)
const CustomPrevButton = ({ disabled }) => {
  return (
    <button
      className={cn('customPrevButton', { disabled })}
      disabled={disabled}
    ></button>
  );
};

// swiper 커스텀 버튼(다음)
const CustomNextButton = ({ disabled }) => {
  return (
    <button
      className={cn('customNextButton', { disabled })}
      disabled={disabled}
    ></button>
  );
};

const PartnerList = ({ slideList, selectedIndex, setSelectedIndex }) => {
  const dispatch = useDispatch();
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsPrevButtonDisabled(swiper.isBeginning);
    setIsNextButtonDisabled(swiper.isEnd);
  };

  return (
    <div className={css.partner_list}>
      <div className={css.guideContainer}>
        <h3>제휴사 별 미처리 건수</h3>
        {/* <span>(숫자부분을 클릭해주세요)</span> */}
      </div>
      <Swiper
        className={css.swiper}
        modules={[Navigation]}
        slidesPerView={'auto'}
        spaceBetween={20}
        navigation={{
          prevEl: `.${css.customPrevButton}`,
          nextEl: `.${css.customNextButton}`,
        }}
        breakpoints={{
          1700: {
            slidesPerView: 3,
          },
        }}
        // onSwiper={(swiper) => console.log('swiper', swiper)}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
        <div className={cn('swiper_wrapper')}>
          {slideList.map((slide, index) => (
            <SwiperSlide
              key={slide.title}
              className={cn(
                'swiper-slide',
                selectedIndex === index ? 'active' : ''
              )}
              onClick={() => {
                setSelectedIndex(index);
                dispatch(setSelectedCompanyName(slide.companyName));
                dispatch(setSelectedVendorKey(slide.vendorKey));
              }}
            >
              <dl>
                <dt>{slide.title}</dt>
                <dd>{slide.confirm}</dd>
                <dd>{slide.status}</dd>
              </dl>
            </SwiperSlide>
          ))}
        </div>
        <CustomPrevButton disabled={isPrevButtonDisabled} />
        <CustomNextButton disabled={isNextButtonDisabled} />
      </Swiper>
    </div>
  );
};

PartnerList.defaultProps = {
  slideList: [],
};

export default memo(PartnerList);
