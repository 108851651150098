import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import css from './ProductList.module.scss';
import classNames from 'classnames/bind';
import img_flow_product_check from '@assets/img/img_flow_product_check.png';
import ProductListDashBoard from '../modules/ProductListDashBoard';
import ProductListSearch from './modules/ProductListSearch';
import ProductListTableV8 from './modules/ProductListTableV8';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getProductList } from 'redux/action/productAction';
import {
  dateToStr,
  createQueryString,
  addHyphenToDate,
  convertDateToString2,
} from '@utils';

import {
  MERCHANDISE_SEARCH_CATEGORY,
  MERCHANDISE_SEARCH_TYPE,
  MILLISECONDS_PER_DAY,
  SERVICE_START_DATE,
} from '@data/Const';

const cn = classNames.bind(css);
const DATE_NOW = Date.now();

const criteriaSearchOptions = {
  guide: '',
  list: [
    { id: MERCHANDISE_SEARCH_CATEGORY.CR, title: '상품 등록일' },
    // { id: MERCHANDISE_SEARCH_CATEGORY.SS, title: '판매 시작일' },
    // { id: MERCHANDISE_SEARCH_CATEGORY.SE, title: '판매 종료일' },
    { id: MERCHANDISE_SEARCH_CATEGORY.AC, title: '승인 완료일' },
  ],
};

const detailedSearchOptions = {
  guide: '',
  list: [
    { id: MERCHANDISE_SEARCH_TYPE.FOCFLAG, title: '정산' },
    { id: MERCHANDISE_SEARCH_TYPE.VNAME, title: '제휴사명' },
    { id: MERCHANDISE_SEARCH_TYPE.MNAME, title: '상품명' },
  ],
};

const BASE_PARAMS = Object.freeze({
  vendorKey: null,
  searchCategory: criteriaSearchOptions.list[0].id,
  searchPeriodType: 'MANUAL',
  preDefinedType: '',
  fromDate: new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30),
  toDate: new Date(DATE_NOW),
  searchType: detailedSearchOptions.list[0].id,
  detailCondition: '',
  salesStatus: '',
  approvalStatus: '',
  category: '',
});

const ProductList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const didMountRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const authority = useSelector(
    ({
      user: {
        userData: { authority },
      },
    }) => authority
  );
  const vendorKey = useSelector(
    ({
      user: {
        userData: { vendorKey },
      },
    }) => vendorKey
  );
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);

  const searchForms = useForm({
    defaultValues: {
      searchCategory: searchParams.get('searchCategory'),
      toDate: searchParams.get('toDate')
        ? new Date(addHyphenToDate(searchParams.get('toDate')))
        : new Date(),
      fromDate: new Date(
        addHyphenToDate(searchParams.get('fromDate') ?? SERVICE_START_DATE)
      ),
      searchType: searchParams.get('searchType'),
      detailCondition: searchParams.get('detailCondition'),
    },
  });
  const { watch, getValues, setValue } = searchForms;
  const [searchCategoryWatch, toDateWatch, fromDateWatch] = watch([
    'searchCategory',
    'toDate',
    'fromDate',
  ]);
  const salesStatus = searchParams.get('salesStatus');
  const approvalStatus = searchParams.get('approvalStatus');
  const category = searchParams.get('category');

  const onFetchDataOnEntry = useCallback(
    (customParams) => {
      const { searchCategory, toDate, fromDate, searchType, detailCondition } =
        getValues();
      const params = {
        ...BASE_PARAMS,
        searchCategory,
        toDate,
        fromDate,
        searchType,
        detailCondition,
        vendorKey,
        authority,
        salesStatus: salesStatus ?? '',
        approvalStatus: approvalStatus ?? '',
        category: category ?? '',
        ...customParams,
      };

      getProductList(dispatch, params);
    },
    [getValues, vendorKey, authority, dispatch, salesStatus, approvalStatus]
  );

  const onSearchClick = useCallback(
    (data) => {
      const { searchCategory, toDate, fromDate, searchType, detailCondition } =
        data;

      const params = {
        ...BASE_PARAMS,
        fromDate:
          fromDate instanceof Date
            ? dateToStr(fromDate).split(' ')[0]
            : fromDate,
        toDate:
          toDate instanceof Date ? dateToStr(toDate).split(' ')[0] : toDate,
        searchCategory:
          searchCategory ??
          getValues('searchCategory') ??
          BASE_PARAMS.searchCategory,
        searchType:
          searchType ?? getValues('searchType') ?? BASE_PARAMS.searchType,
        detailCondition: detailCondition ?? '',
        salesStatus: '',
        approvalStatus: '',
        category: '',
        vendorKey,
        authority,
      };

      setSearchParams(params); // FIXME: authority를 뺄지말지 정하기 | 뒤로가기 때문에 replace를 사용해야할 수 있음
      getProductList(dispatch, params);
    },
    [getValues, setSearchParams, vendorKey, authority, dispatch]
  );

  /**
   * FIXME:
   * URL을 치고 들어왔을 때
   * GNB로 들어왔을 때
   * 새로고침 했을 때
   *
   * 위 세 가지에 대해서 대응할 것
   */

  useEffect(() => {
    onFetchDataOnEntry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      didMountRef.current &&
      searchCategoryWatch &&
      toDateWatch &&
      fromDateWatch
    ) {
      setValue('detailCondition', '');
      onSearchClick({
        fromDate: fromDateWatch,
        toDate: toDateWatch,
        searchCategory: searchCategoryWatch,
      });
    }

    didMountRef.current = true;
    // eslint-disable-next-line
  }, [searchCategoryWatch, toDateWatch, fromDateWatch]);

  useEffect(() => {
    if (!location.search && vendorKey) {
      const params = {
        ...BASE_PARAMS,
        fromDate: SERVICE_START_DATE,
        toDate: dateToStr(new Date()),
        vendorKey,
        authority,
      };

      navigate(`?${createQueryString(params)}`, { replace: true });
      onFetchDataOnEntry(params);
    }
    if (location.state) {
      handleSearchButtonTrigger(location.state);
    }
    // eslint-disable-next-line
  }, [location, vendorKey, authority]);

  const handleSearchButtonTrigger = useCallback((customParams) => {
    const { searchCategory, toDate, fromDate, searchType, detailCondition } =
      getValues();
    const params = {
      ...BASE_PARAMS,
      searchCategory,
      toDate,
      fromDate,
      searchType,
      detailCondition,
      vendorKey,
      authority,
      salesStatus: salesStatus ?? '',
      approvalStatus: approvalStatus ?? '',
      category: '',
      ...customParams,
    };

    getProductList(dispatch, params);
    // onSearchClick();
  }, []);

  return (
    <>
      <h2 className={cn('pageTitle')}>상품 조회</h2>
      <ProductListDashBoard
        handleSearchButtonTrigger={handleSearchButtonTrigger}
      />
      <div className={cn('flowChart', 'mt15')}>
        <h3 className={cn('title')}>상품 조회 Flow</h3>
        <div className={cn('img')}>
          <img src={img_flow_product_check} alt='상품 조회 Flow 이미지' />
        </div>
      </div>
      <FormProvider {...searchForms}>
        <ProductListSearch
          onSearchClick={onSearchClick}
          criteriaSearchOptions={criteriaSearchOptions}
          detailedSearchOptions={detailedSearchOptions}
          isOpenPopUp={isOpenPopUp}
        />
      </FormProvider>
      <ProductListTableV8
        isOpenPopUp={isOpenPopUp}
        setIsOpenPopUp={setIsOpenPopUp}
      />
    </>
  );
};

export default ProductList;
