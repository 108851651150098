import { INPUT_TYPE } from '@data/Const';
import css from './HFInput.module.scss';
import classNames from 'classnames/bind';
import { forwardRef } from 'react';

const cn = classNames.bind(css);

/**
 * @category components
 * @namespace HFInput
 * @param {function} register useForm의 return 값으로 Input 등록하기 위한 함수
 * @param {string} name handleSubmit에서 사용되는 고유 key 값
 * @param {RegExp} pattern 유효성 검사를 위한 정규 표현식
 * @param {bool} required 값 선택 필수 유무
 * @param {bool} disabled disabled
 * @param {string} type type
 * @param {string} className className
 * @param {React.ref} ref ref가 추가로 필요한 경우 사용
 * @description React-hook-form 사용 시 사용되는 Input
 */
const HFInput = (
  { register, name, pattern, required, disabled, type, className, ...rest },
  ref
) => {
  let returnJSX;

  if (type === INPUT_TYPE.FILE) {
    const { ref: refForFileInput, ...restForFileInput } = register(name, {
      required,
      disabled,
    });

    returnJSX = (
      <input
        className={cn(className)}
        type={type}
        {...restForFileInput}
        {...rest}
        ref={(element) => {
          refForFileInput(element);
          ref.current = element;
        }}
      />
    );
  } else {
    returnJSX = (
      <input
        type={type}
        className={cn(
          'input',
          {
            required,
            disabled,
          },
          className
        )}
        {...register(name, { pattern, required, disabled })}
        {...rest}
      />
    );
  }

  return returnJSX;
};

export default forwardRef(HFInput);
