import React from 'react';
import classNames from 'classnames/bind';
import css from './AccountingListSearchArea.module.scss';
import { useFormContext } from 'react-hook-form';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { Button } from '@components/index';

const cn = classNames.bind(css);

const AccountingListSearchArea = ({
  vendorSearchOptions,
  periodSearchOptions,
  isLevel3,
  onSearchClick,
}) => {
  const { control, handleSubmit } = useFormContext();
  return (
    <div>
      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={cn('flex')}>
          <div className={cn('innerBox')}>
            <strong className={cn('title')}>정산 기간</strong>
            <div className={cn('selectbox', 'type01')}>
              <HFSelectBox
                control={control}
                name={'periodSearchOptions'}
                options={periodSearchOptions}
              />
            </div>
          </div>
          {!isLevel3 ? (
            <div className={cn('innerBox')}>
              <strong className={cn('title')}>업체명</strong>
              <div className={cn('selectbox', 'type01')}>
                <HFSelectBox
                  control={control}
                  name={'vendorSearchOptions'}
                  options={vendorSearchOptions}
                />
              </div>
            </div>
          ) : null}
          <div className={cn('innerBox')}>
            <div className={cn('ml10')}>
              <Button
                styleType={'btn01'}
                subType={'type03'}
                className={cn('btn')}
                onClick={handleSubmit(onSearchClick)}
              >
                검색
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountingListSearchArea;
