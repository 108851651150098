import { Button } from '@components/index';
import css from './DeliverySearchArea.module.scss';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames/bind';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import HFInput from '@components/_ReactHookForm/HFInput';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';
import { DISABLED_DATE_TYPE, MILLISECONDS_PER_DAY } from '@data/Const';
import { useFormContext, useWatch } from 'react-hook-form';

const cn = classNames.bind(css);

const RADIO_INPUT_VALUES = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  ONE_MONTH: 'ONE_MONTH',
};

const DATE_NOW = Date.now();

const DeliverySearchArea = forwardRef(
  ({ deliveryStatusOptions, deliveryCategoryOptions, onSearchClick }, ref) => {
    const { register, watch, control, handleSubmit, setValue } =
      useFormContext();

    useImperativeHandle(ref, () => ({
      getStatusSelectWatch: () => watch('deliveryStatusCondition'),
      getFromDateWatch: () => watch('fromDate'),
      getToDateWatch: () => watch('toDate'),
    }));

    const radioDateWatch = useWatch({
      control,
      name: 'radioDate',
      defaultValue: RADIO_INPUT_VALUES.TODAY,
    });

    useEffect(() => {
      if (radioDateWatch !== undefined) {
        switch (radioDateWatch) {
          case RADIO_INPUT_VALUES.TODAY:
            setValue('fromDate', new Date(DATE_NOW));
            break;
          case RADIO_INPUT_VALUES.WEEK:
            setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 7));
            break;
          case RADIO_INPUT_VALUES.ONE_MONTH:
            setValue(
              'fromDate',
              new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30)
            );
            break;
          default:
            break;
        }
        setValue('toDate', new Date(DATE_NOW));
      }
    }, [radioDateWatch, setValue]);

    return (
      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={cn('flex')}>
          <div className={cn('innerBox', 'date')}>
            <strong className={cn('title')}>조회 기간</strong>
            <div className={cn('dateBox')}>
              <HFCalendar
                name={'fromDate'}
                control={control}
                disabledDateTypeList={[
                  DISABLED_DATE_TYPE.HOLIDAY,
                  DISABLED_DATE_TYPE.WEEKEND,
                ]}
              />
              <span className={cn('dash')}>-</span>
              <HFCalendar
                name={'toDate'}
                control={control}
                disabledDateTypeList={[
                  DISABLED_DATE_TYPE.HOLIDAY,
                  DISABLED_DATE_TYPE.WEEKEND,
                ]}
              />
              <div className={css.radioBox}>
                <HFRadioButton
                  name={'radioDate'}
                  register={register}
                  id={`alarm-today`}
                  value={RADIO_INPUT_VALUES.TODAY}
                  defaultValue={radioDateWatch}
                >
                  오늘
                </HFRadioButton>
                <HFRadioButton
                  name={'radioDate'}
                  register={register}
                  id={`alarm-week`}
                  value={RADIO_INPUT_VALUES.WEEK}
                >
                  일주일
                </HFRadioButton>
                <HFRadioButton
                  name={'radioDate'}
                  register={register}
                  id={`alarm-oneMonth`}
                  value={RADIO_INPUT_VALUES.ONE_MONTH}
                >
                  1개월
                </HFRadioButton>
              </div>
            </div>
          </div>
        </div>
        <div className={cn('flex')}>
          <div className={cn('innerBox')}>
            <strong className={cn('title')}>배송상태</strong>
            <div className={cn('selectbox', 'type01', 'sizeS')}>
              <HFSelectBox
                control={control}
                name={'deliveryStatusCondition'}
                options={deliveryStatusOptions}
              />
            </div>
          </div>
          <div className={cn('innerBox')}>
            <strong className={cn('title')}>정산상태</strong>
            <div className={cn('selectbox', 'type01', 'sizeS')}>
              <HFSelectBox
                control={control}
                name={'deliveryCategoryCondition'}
                options={deliveryCategoryOptions}
              />
            </div>
          </div>
          <div className={cn('innerBox', 'option_detail')}>
            <div className={cn('inputWrap')}>
              <HFInput
                register={register}
                name={'keyword'}
                type={'text'}
                placeholder={'운송장 번호'}
              />
              <Button subType={'type03'} onClick={handleSubmit(onSearchClick)}>
                검색하기
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default DeliverySearchArea;
