import useInput from '@hooks/useInput';
import { Input, Button } from '@components';
import classNames from 'classnames/bind';
import css from './SingleSelectSearch.module.scss';

const cn = classNames.bind(css);

/**
 * @param {string} className - 컨테이너 컴포넌트에 커스텀으로 추가할 스타일클래스
 * @param {string} placeholder - 검색어 입력창의 가이드 메세지(default: '검색어를 입력해주세요')
 * @param {array} targetData - 검색 대상이 되는 데이터
 * @param {string} searchKey - 검색 대상이 되는 데이터의 키 값
 * @param {function} setFilteredData - 검색 결과를 저장하는 함수
 */
const SingleSelectSearch = ({
  className,
  placeholder,
  targetData,
  searchKey,
  setFilteredData,
}) => {
  const [searchText, setSearchText, onChangeSearchText] = useInput('');

  const onSearchClick = (event) => {
    event.preventDefault();
    if (!searchKey || !searchText) {
      setFilteredData(targetData);
      return;
    }

    const id = searchKey;
    const searchPattern = new RegExp(searchText, 'i');

    const filteredData = targetData.filter((datum) =>
      searchPattern.test(datum[id])
    );

    setFilteredData(filteredData);
    setSearchText('');
  };

  return (
    <div className={cn('container', className)}>
      <form className={css.form}>
        <Input
          placeholder={placeholder}
          type='text'
          name={'messageSearch'}
          value={searchText}
          onChange={onChangeSearchText}
          fullWidth
        />
        <div className={css.buttonContainer}>
          <Button subType={'type03'} onClick={onSearchClick} fullWidth>
            검색
          </Button>
        </div>
      </form>
    </div>
  );
};

SingleSelectSearch.defaultProps = {
  placeholder: '검색어를 입력해주세요',
  fullWidth: true,
};

export default SingleSelectSearch;
