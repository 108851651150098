import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, SelectBox, TableV8 } from '@components/index';
import { useSelect } from '@hooks/index';
import TableCheckbox from '@components/Table/TableCheckbox';
import SingleSelectSearch from '@components/Search/SingleSelectSearch';
import classNames from 'classnames/bind';
import css from './Inbox.module.scss';
import {
  getRetrieveMessageList,
  postDeleteMessage,
  postUpdateReadFlag,
  getMessageCount,
} from 'redux/action/mailAction';
import { useDispatch, useSelector } from 'react-redux';
import { dateToStr } from 'utils/utils';
import resetIcon from '@assets/img/reset.png';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const optionList = [
  { id: 'categoryDesc', title: '구분' },
  { id: 'sendUserId', title: '아이디' },
];

const Inbox = () => {
  const dispatch = useDispatch();
  const tableV8Ref = useRef();
  const userId = useSelector((state) => state.user.userData.userId);
  const mailList = useSelector((state) => state.mail.list);
  const totalCount = useSelector((state) => state.mail.totalCount);
  const [isMessageDetail, setIsMessageDetail] = useState(false);
  const [filteredData, setFilteredData] = useState(mailList);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [selectedKeyArray, setSelectedKeyArray] = useState([]);
  const [pageElements, setPageElements] = useState({
    pageSize: '1000000',
    pageCount: '1',
  });

  const [mailDetail, setMailDetail] = useState({
    categoryDesc: '',
    sendUserId: '',
    receivedDatetime: '',
    content: '',
    messageKey: '',
  });

  //todo: pramas 수정
  const listParams = useMemo(() => {
    const result = {
      userId: userId,
      fromDate: '20231201',
      toDate: dateToStr(new Date()),
      delFlag: 'N',
      searchType: '',
      detailCondition: '',
      limit: '100',
      page: pageElements.pageCount,
    };
    return result;
  }, [userId]);

  const countParams = useMemo(() => {
    const result = {
      userId: userId,
      delFlag: 'N',
    };
    return result;
  }, [userId]);

  useEffect(() => {
    getRetrieveMessageList(dispatch, listParams);
  }, [listParams]);

  useEffect(() => {
    resetMessageCount(dispatch, countParams);
  }, [countParams]);

  useEffect(() => {
    setIsMessageDetail(false);
  }, []);

  const onChangeSelectedKeys = useCallback((keys) => {
    setSelectedKeys(keys);
  }, []);

  const onChangePageElements = useCallback((element) => {
    setPageElements(element);
  }, []);

  useEffect(() => {
    setFilteredData(mailList);
  }, [mailList]);

  //FIXME:  `['${keysAsString}']`, 동작확인 필요, JSON.stringify(selectedKeys) 가 정상동작일 것으로 생각됨
  useEffect(() => {
    if (selectedKeys) {
      const keysAsString = selectedKeys
        .map((item) => `'${item.messageKey}'`)
        .join(', ');
      setSelectedKeyArray(`[${keysAsString}]`);
    }
  }, [selectedKeys]);

  useEffect(() => {
    if (isMessageDetail) {
      const keysAsString = mailDetail?.messageKey;
      const params = {
        userId: userId,
        updateTargetList: `['${keysAsString}']`,
      };
      postUpdateReadFlag(dispatch, params, listParams, false);
    }
  }, [userId, listParams, mailDetail, isMessageDetail]);

  const [currentSelectedIndex, setCurrentSelectedIndex, currentLabel] =
    useSelect({
      optionList,
      initialIndex: 0,
      initialLabel: optionList[0].title,
    });
  const searchKey = useMemo(
    () => optionList[currentSelectedIndex]?.id,
    [currentSelectedIndex]
  );

  const onClickMessageDetail = useCallback((info) => {
    setMailDetail({
      categoryDesc: info.categoryDesc,
      sendUserId: info.sendUserId,
      receivedDatetime: info.receivedDatetime,
      content: info.content,
      messageKey: info.messageKey,
    });
    setIsMessageDetail(true);
  }, []);

  const onClickDelete = useCallback(() => {
    const params = {
      userId: userId,
      deleteTargetList: selectedKeyArray,
    };

    postDeleteMessage(dispatch, params, listParams);
    handleSelected();
  }, [userId, selectedKeyArray, listParams]);

  const onClickRead = useCallback(() => {
    const params = {
      userId: userId,
      updateTargetList: selectedKeyArray,
    };
    handleSelected();

    postUpdateReadFlag(dispatch, params, listParams, true);
  }, [userId, selectedKeyArray, listParams]);

  const resetMessageCount = useCallback(() => {
    getMessageCount(dispatch, countParams);
  }, [countParams]);

  const resetMessage = useCallback(() => {
    resetMessageCount();
    getRetrieveMessageList(dispatch, listParams);
  }, [countParams]);

  const handleSelected = () => {
    if (tableV8Ref.current) {
      tableV8Ref.current.clearSelectedRows();
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        header: ({ table }) => (
          <TableCheckbox
            className={css.headerInput}
            id={'message-select-header'}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <TableCheckbox
            className={css.headerInput}
            id={'message-select' + row.id}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      }),
      columnHelper.accessor('manageCategoryDesc', {
        header: () => <span>구분</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('groupName', {
        header: () => <span>그룹(상호명)</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('sendUserId', {
        header: () => <span>보낸 사람</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('content', {
        header: () => <span>내용</span>,
        cell: (info) => (
          <span
            className={css.cursor}
            onClick={() => onClickMessageDetail(info.row.original)}
          >
            {info.getValue()}
          </span>
        ),
        size: 700,
      }),
      columnHelper.accessor('receivedDatetime', {
        header: () => <span>받은 날짜</span>,
        cell: (info) => <span>{info.getValue()}</span>,
        size: 200,
      }),
    ],
    []
  );

  return (
    <>
      {isMessageDetail ? (
        <div className={css.detailContainer}>
          <hr className={css.hrBoldBlack} />
          <div className={css.detail}>
            <strong className={css.detailTitle}>구분</strong>
            <span>{mailDetail.categoryDesc}</span>
          </div>
          <div className={css.detail}>
            <strong className={css.detailTitle}>보낸사람</strong>
            <span>{mailDetail.sendUserId}</span>
          </div>
          <div className={css.detail}>
            <strong className={css.detailTitle}>받은 날짜</strong>
            <span>{mailDetail.receivedDatetime}</span>
          </div>
          <hr />
          <div className={css.detailContent}>
            <p>{mailDetail.content}</p>
          </div>
          <hr />
          <div className={css.buttonContainer}>
            <div className={css.buttonWidth}>
              <Button fullWidth onClick={() => setIsMessageDetail(false)}>
                확인
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={cn('searchOptionArea')}>
            <div className={cn('flex')}>
              <div className={cn('sizeS')}>
                <SelectBox
                  optionList={optionList}
                  currentIndex={currentSelectedIndex}
                  currentLabel={currentLabel}
                  handleSelectIndex={setCurrentSelectedIndex}
                  fullWidth={true}
                />
              </div>
              <SingleSelectSearch
                targetData={mailList}
                searchKey={searchKey}
                setFilteredData={setFilteredData}
                className={cn('ml20', 'fullWidth')}
              />
            </div>
          </div>
          <TableV8
            ref={tableV8Ref}
            data={filteredData}
            columns={columns}
            hasCsvDownload={false}
            getRowselected={onChangeSelectedKeys}
            getPageElements={onChangePageElements}
            rightControls={[
              <div className={cn('tableButtonContainer')}>
                <Button
                  onClick={onClickDelete}
                  styleType={'btn03'}
                  subType={'type04'}
                >
                  삭제
                </Button>
                <Button
                  onClick={onClickRead}
                  styleType={'btn03'}
                  subType={'type01'}
                >
                  읽음
                </Button>
              </div>,
            ]}
            //  TODO: pagination 반영후 반영 예정
            // leftControls={[
            //   <div className={css.leftControls}>
            //     <span>
            //       {`${totalCount?.totalCnt ?? 0}/ `}
            //       <span className={css.fontBlue}>{`${
            //         totalCount?.unreadCnt ?? 0
            //       } `}</span>
            //       미확인
            //     </span>
            //     <img
            //       onClick={resetMessage}
            //       src={resetIcon}
            //       alt=''
            //       className={css.resetIcon}
            //     ></img>
            //   </div>,
            // ]}
          />
        </>
      )}
    </>
  );
};

export default Inbox;
