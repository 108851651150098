import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deliveryStatusDashboard: {
    deliveryDelayedCnt: 0,
    deliveryNewOrderCnt: 0,
    deliveryCompletedCnt: 0,
    deliveryPackageUnderPreparingCnt: 0,
    deliveryPackagePreparedCnt: 0,
    deliveryInTransitCnt: 0,
  },
  deliveryStatusList: {
    additionalInfo: { totalCount: 10 },
    list: [],
  },
  deliveryInfoDetails: [],
  retrieveDeliveryTalkdreamResultList: [],
  retrieveDeliveryTalkdreamResultDetailList: [],
  retrieveDeliveryTalkdreamResultDashboard: [
    {
      kakaoDeliveryStartedFailedCnt: 0, // 알림톡 배송안내 실패
      kakaoDeliveryStartedSuccessedCnt: 0, // 알림톡 배송안내 성공
      lmsDeliveryStartedFailedCnt: 0, // LMS 배송안내 실패
      lmsDeliveryStartedSuccessedCnt: 0, // LMS 배송안내 성공
      lmsDeliveryStartedWaitingCnt: 0, // LMS 배송안내 대기중
      lmsDeliveryNewOrderCreatedFailedCnt: 0, // LMS 배송요청 실패
      lmsDeliveryNewOrderCreatedSuccessedCnt: 0, // LMS 배송요청 성공
      lmsDeliveryNewOrderCreatedWaitingCnt: 0, // LMS 배송요청 대기중
      lmsDeliveryPrepareCompletedD1FailedCnt: 0, // LMS 송장번호 입력 지연 실패
      lmsDeliveryPrepareCompletedD1SuccessedCnt: 0, // LMS 송장번호 입력 지연 성공
      lmsDeliveryPrepareCompletedD1WaitingCnt: 0, // LMS 송장번호 입력 지연 대기중
      lmsDeliveryUnderDeliverdD7FailedCnt: 0, // LMS 배송지연(제휴사) 실패
      lmsDeliveryUnderDeliverdD7SuccessedCnt: 0, // LMS 배송지연(제휴사) 실패
      lmsDeliveryUnderDeliverdD7WaitingCnt: 0, // LMS 배송지연(제휴사) 실패
      lmsDeliveryUnderPreparedD1FailedCnt: 0, // LMS 상품 준비완료 요청 실패
      lmsDeliveryUnderPreparedD1SuccessedCnt: 0, // LMS 상품 준비완료 요청 성공
      lmsDeliveryUnderPreparedD1WaitingCnt: 0, // LMS 상품 준비완료 요청 대기중
    },
  ],
  deliveryCompanyList: [],
};
const delivery = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    getDeliveryStatusDashboard: (state, action) => {
      state.deliveryStatusDashboard = action.payload;
    },
    getDeliveryStatusList: (state, action) => {
      state.deliveryStatusList = action.payload;
    },
    getDeliveryStatusListDetails: (state, action) => {
      state.deliveryInfoDetails = action.payload;
    },
    getRetrieveDreamTalkdreamResultList: (state, action) => {
      state.retrieveDeliveryTalkdreamResultList = action.payload;
    },
    getRetrieveDreamTalkdreamResultDetailList: (state, action) => {
      state.retrieveDeliveryTalkdreamResultDetailList = action.payload;
    },
    getRetrieveDeliveryTalkdreamResultDashboard: (state, action) => {
      state.retrieveDeliveryTalkdreamResultDashboard = action.payload;
    },
    getDeliveryCompanyList: (state, action) => {
      state.deliveryCompanyList = action.payload;
    },
  },
});

export const {
  getDeliveryStatusDashboard,
  getDeliveryStatusList,
  getDeliveryStatusListDetails,
  getRetrieveDreamTalkdreamResultList,
  getRetrieveDreamTalkdreamResultDetailList,
  getRetrieveDeliveryTalkdreamResultDashboard,
  getDeliveryCompanyList,
} = delivery.actions;
export default delivery.reducer;
