import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useMemo } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingManageStepSecond.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, PopUp, PopUpAlert, SelectBox } from '@components/index';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import TableCheckbox from '@components/Table/TableCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAlert } from '@feature/common/commonSlice';
import { FormProvider, useForm } from 'react-hook-form';
import AccountingManageSearchArea from '../module/AccountingManageSearchArea';
import { useSelect } from '@hooks/index';
import {
  getSettlementTargetStatusInfoAPI,
  getEntireAdjustmentListAPI,
  getAdjustmentResultAPI,
  postInsertAdjustmentAPI,
  postDeleteAdjustmentAPI,
  getSettlementApprovalStatusAPI,
} from 'redux/action/settlementAction';
import { convertDateToString3 } from '@utils';
import { getSettlementTargetMerchandiseAPI } from 'redux/action/productAction';
import { setSettlementTargetMerchandise } from '@feature/settlement/settlementSlice';
import HFInput from '@components/_ReactHookForm/HFInput';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const AccountingManageStepSecond = () => {
  const dispatch = useDispatch();
  const searchForms = useForm();
  const {
    register: adjustmentDesc,
    watch,
    reset,
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  });
  const vendorList = useSelector((state) => state.common.vendorList);
  const entireAdjustmentList = useSelector(
    (state) => state.settlement.entireAdjustmentList
  );
  const adjustmentResult = useSelector(
    (state) => state.settlement.adjustmentResult
  );
  const targetMerchandise = useSelector(
    (state) => state.settlement.targetMerchandise
  );
  const settlementApprovalStatus = useSelector(
    (state) => state.settlement.settlementApprovalStatus
  );

  const [selectedDeleteTarget, setSelectedDeleteTarget] = useState(null);
  const [isOpenSavePopUp, setIsOpenSavePopUp] = useState(false);
  const [isOpenAddPopUp, setIsOpenAddPopUp] = useState(false);

  //+- 버튼
  const [plusMinusBtn, setPlusMinusBtn] = useState([]);
  // const [adjustmentDescText, setAdjustmentDescText] = useState([]);

  useEffect(() => {
    if (
      targetMerchandise.length > 0 &&
      plusMinusBtn.length < targetMerchandise.length
    ) {
      plusMinusBtn.push('ADD');
      setPlusMinusBtn([...plusMinusBtn]);
    }
  }, [targetMerchandise, plusMinusBtn]);

  const selectVendorList = useMemo(() => {
    const totalSelecteOption = [
      { id: 'NULL', title: '업체를 선택해주세요', vendorKey: '' },
    ];
    vendorList.forEach((vendor) => {
      totalSelecteOption.push({
        id: vendor.companyCode,
        title: vendor.companyName,
        vendorKey: vendor.vendorKey,
      });
    });
    return totalSelecteOption;
  }, [vendorList]);

  const [selectedCompanyIndex, setSelectedCompanyIndex, selecteCompanyLabel] =
    useSelect({
      optionList: selectVendorList,
      initialIndex: 0,
      initialLabel: '업체를 선택해주세요',
    });

  const onClickSave = useCallback(() => {
    setIsOpenSavePopUp(!isOpenSavePopUp);
  }, [isOpenSavePopUp]);

  const onClickAdd = useCallback(() => {
    setIsOpenAddPopUp(!isOpenAddPopUp);
  }, [isOpenAddPopUp]);

  const onClickDelete = useCallback(() => {
    if (selectedDeleteTarget?.deleteList?.length > 0) {
      const entireAdjustmentLisParams = {
        vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
        settlementMonth: convertDateToString3(new Date()),
      };
      postDeleteAdjustmentAPI(
        dispatch,
        selectedDeleteTarget,
        entireAdjustmentLisParams
      );
      dispatch(openModalAlert({ message: '선택한 항목이 삭제됩니다.' }));
      // getEntireAdjustmentListAPI(dispatch, entireAdjustmentLisParams);
    } else {
      dispatch(openModalAlert({ message: '선택한 항목이 없습니다.' }));
    }
  }, [selectedDeleteTarget, selectVendorList, selectedCompanyIndex]);

  const confirmSavePopUp = useCallback(() => {
    setIsOpenSavePopUp(false);
  }, []);

  const onChangeSelectedKeys = useCallback((rows) => {
    const deleteTarget = rows.map((row) => {
      return {
        data: {
          adjustmentId: row.adjustmentId,
          settlementMonth: row.settlementMonth,
          vendorKey: row.vendorKey,
          promotionType: row.promotionType,
        },
      };
    });
    setSelectedDeleteTarget({ deleteList: deleteTarget });
  }, []);

  const onSearchClick = useCallback((data) => {
    const { keyword } = data;
    const params = {
      merchandiseId: keyword,
    };
    getSettlementTargetMerchandiseAPI(dispatch, params);
  }, []);
  const handlePlusMinus = useCallback(
    (type, index) => {
      if (type === 'ADD') {
        plusMinusBtn.splice(index, 1, 'ADD');
        setPlusMinusBtn([...plusMinusBtn]);
      } else {
        plusMinusBtn.splice(index, 1, 'EXCLUDE');
        setPlusMinusBtn([...plusMinusBtn]);
      }
    },
    [plusMinusBtn]
  );

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-settlement-adjust-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-settlement-adjust-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('category', {
        id: 'category',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>구분</span>,
      }),
      columnHelper.accessor('promotionType', {
        id: 'promotionType',
        cell: (info) => (
          <span>{info.getValue() === 'FOC' ? '무상' : '유상'}</span>
        ),
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('sellPrice', {
        id: '매출sellPrice금액',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출 금액(공급가액)</span>,
      }),
      columnHelper.accessor('adjustmentType', {
        id: 'adjustmentType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정</span>,
      }),
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출 반영 월</span>,
      }),
      columnHelper.accessor('adjustmentDesc', {
        id: 'adjustmentDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정 내용</span>,
      }),
    ],
    []
  );

  const merchandiseInfoColumns = useMemo(
    () => [
      columnHelper.accessor('category', {
        id: 'category',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>구분</span>,
      }),
      columnHelper.accessor('promotionType', {
        id: 'promotionType',
        cell: (info) => (
          <span>{info.getValue() === 'FOC' ? '무상' : '유상'}</span>
        ),
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('sellPrice', {
        id: '매출sellPrice금액',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출 금액(공급가액)</span>,
      }),
      columnHelper.accessor('adjustmentType', {
        id: 'adjustmentType',
        cell: (info) => {
          return (
            <span>
              <button
                className={cn(
                  plusMinusBtn[info.row.index] === 'ADD'
                    ? 'btn_adj_plus'
                    : 'btn_adj_plus_disabled'
                )}
                style={{ marginRight: '10px' }}
                onClick={() => handlePlusMinus('ADD', info.row.index)}
              ></button>
              <button
                className={cn(
                  plusMinusBtn[info.row.index] === 'EXCLUDE'
                    ? 'btn_adj_minus'
                    : 'btn_adj_minus_disabled'
                )}
                onClick={() => handlePlusMinus('EXCLUDE', info.row.index)}
              ></button>
            </span>
          );
        },
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출 반영 월</span>,
      }),
      columnHelper.accessor('adjustmentDesc', {
        id: 'adjustmentDesc',
        cell: (info) => (
          <span>
            <HFInput
              className={'type02'}
              type={'text'}
              name={`adjustmentDesc-${info.row.id}`}
              register={adjustmentDesc}
              placeholder={'조정 사유'}
            />
          </span>
        ),
        header: () => <span>조정 내용</span>,
      }),
    ],
    [plusMinusBtn, targetMerchandise]
  );

  const currentMonth = useMemo(() => {
    const currentDate = new Date();
    return currentDate.getMonth() + 1;
  }, []);

  const handleAdjustSave = useCallback(() => {
    const deepCloneArray = JSON.parse(JSON.stringify(targetMerchandise));
    const adjustmentTextArray = Object.values(watch());
    const target = deepCloneArray.map((item, index) => {
      item.adjustmentType = plusMinusBtn[index];
      item.adjustmentDesc = adjustmentTextArray[index];
      item.adjustmentCnt = 1;
      return item;
    });
    const params = { adjustmentList: [] };
    target.forEach((item) => {
      params.adjustmentList.push({ data: item });
    });
    const entireAdjustmentLisParams = {
      vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
      settlementMonth: convertDateToString3(new Date()),
    };

    postInsertAdjustmentAPI(dispatch, params, entireAdjustmentLisParams);
    setIsOpenAddPopUp(false);
  }, [targetMerchandise, plusMinusBtn, selectVendorList, selectedCompanyIndex]);

  const isAccessibleAdjustBtn = useMemo(() => {
    let ret = true;
    if (
      settlementApprovalStatus?.adminApprovalStatus === 'Y' ||
      settlementApprovalStatus?.level3ApprovalStatus === 'Y'
    ) {
      ret = false;
    }
    return ret;
  }, [settlementApprovalStatus]);

  useEffect(() => {
    dispatch(setSettlementTargetMerchandise('reset'));
    setPlusMinusBtn([]);
    reset();
    const entireAdjustmentLisParams = {
      vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
      settlementMonth: convertDateToString3(new Date()),
    };
    getEntireAdjustmentListAPI(dispatch, entireAdjustmentLisParams);
  }, [isOpenAddPopUp, selectVendorList, selectedCompanyIndex]);

  useEffect(() => {
    const params = {
      vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
    };
    const entireAdjustmentLisParams = {
      vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
      settlementMonth: convertDateToString3(new Date()),
    };
    getSettlementTargetStatusInfoAPI(dispatch, params); //상단 대시보드
    getEntireAdjustmentListAPI(dispatch, entireAdjustmentLisParams);
    getAdjustmentResultAPI(dispatch, params);
    getSettlementApprovalStatusAPI(dispatch, params);
  }, [selectedCompanyIndex, selectVendorList]);

  return (
    <div>
      <div className={cn('selectBoxContainer')}>
        <SelectBox
          optionList={selectVendorList}
          currentIndex={selectedCompanyIndex}
          handleSelectIndex={setSelectedCompanyIndex}
          currentLabel={selecteCompanyLabel}
          fullWidth
        />
      </div>
      {selecteCompanyLabel !== '업체를 선택해주세요' && (
        <div>
          <div className={cn('stepGuideText')}>
            2-1. 1번 영역에서 매출이 틀린 부분이 있으면 아래{' '}
            <b>[추가] [삭제]</b> 버튼을 통해 수정해 주세요.(필수 아님)
          </div>

          <div className={cn('table_header')}>
            <div className={cn('box_left')}>
              <strong>매출 조정</strong>
            </div>
            <div className={cn('box_right')}>
              {/* TODO: 제휴사 N && LG N 일때만 가능 retrieveSettlementApprovalStatus */}
              {isAccessibleAdjustBtn ? (
                <>
                  <Button
                    type='button'
                    className={cn('btn')}
                    styleType='btn03'
                    onClick={onClickAdd}
                  >
                    추가
                  </Button>
                  <Button
                    type='button'
                    className={cn('btn')}
                    styleType='btn03'
                    subType='type04'
                    onClick={onClickDelete}
                  >
                    삭제
                  </Button>
                </>
              ) : (
                <div>{`[3-2] 매출 대상 승인을 취소해야 매출조정이 가능합니다.`}</div>
              )}
            </div>
          </div>

          {entireAdjustmentList.length > 0 ? (
            <div className={cn('dataTable01')}>
              <TableV8ManualPage
                columns={columns}
                data={entireAdjustmentList}
                hasCsvDownload={false}
                // page={page}
                // limit={limit}
                // totalLength={couponOverallStatusTotalCount}
                getRowselected={onChangeSelectedKeys}
              />
            </div>
          ) : (
            <div>매출 조정 대상이 없습니다.</div>
          )}

          <div className={cn('stepGuideText')}>
            2-2. 조정 결과가 맞으시면 <b>[업체별 매출 승인및 확정]</b> 버튼으로
            이동해주세요.
          </div>

          <div className={cn('calc_table_wrapper')}>
            <div className={cn('calc_table_wrap')}>
              <div className={cn('calc_table', 'double')}>
                <div className={cn('title')}>
                  <div className={cn('flex')}>
                    <strong className='type'>조정 결과</strong>
                  </div>
                </div>
                <div className={cn('item')}>
                  <dl className={cn('total', 'mt10')}>
                    <dt>전체 {adjustmentResult.entireCnt} 건</dt>
                  </dl>
                  <dl className={cn('detail')}>
                    <dt>- 쿠폰</dt>
                    <dd>
                      + 조정({adjustmentResult.couponAddCnt}건){' '}
                      <span className={cn('color-default')}>
                        - 조정({adjustmentResult.couponExcludeCnt}건)
                      </span>
                      <div className={cn('addValue')}>
                        + {adjustmentResult.couponAddTotal}원 ,-{' '}
                        {adjustmentResult.couponExcludeTotal}원
                      </div>
                    </dd>
                  </dl>
                  <dl className={cn('detail')}>
                    <dt>- 배송</dt>
                    <dd>
                      + 조정({adjustmentResult.deliveryAddCnt}건){' '}
                      <span className={cn('color-default')}>
                        - 조정({adjustmentResult.deliveryExcludeCnt}건)
                      </span>
                      <div className={cn('addValue')}>
                        + {adjustmentResult.deliveryAddTotal}원 ,-{' '}
                        {adjustmentResult.deliveryExcludeTotal}원
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div className={cn('calc_table', 'single')}>
                <div className={cn('item')}>
                  <dl className={cn('total', 'mt10')}>
                    <dt>추가 금액</dt>
                    <dd>{adjustmentResult.entireAddTotal} 원</dd>
                  </dl>
                  <dl className={cn('total', 'mt10')}>
                    <dt>제외 금액</dt>
                    <dd>{adjustmentResult.entireExcludeTotal} 원</dd>
                  </dl>
                </div>
                <div className={cn('amount', 'font-w700')}>
                  <dl>
                    <dt>전체 조정 금액({currentMonth}월 반영)</dt>
                    <dd>{adjustmentResult.adjustmentTotal} 원</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <PopUpAlert
        isOpenPopUp={isOpenSavePopUp}
        onRequestClose={() => setIsOpenSavePopUp(false)}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={confirmSavePopUp}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        매출조정 항목을 저장합니다.
      </PopUpAlert>

      <PopUp
        isOpenPopUp={isOpenAddPopUp}
        onRequestClose={() => setIsOpenAddPopUp(false)}
        isWideSize
      >
        <strong className={cn('popup_title')}>매출 조정</strong>
        <FormProvider {...searchForms}>
          <AccountingManageSearchArea onSearchClick={onSearchClick} />
        </FormProvider>
        <TableV8ManualPage
          columns={merchandiseInfoColumns}
          data={targetMerchandise}
          hasCsvDownload={false}
          disabledFooter
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
        >
          <Button styleType='btn03' subType='type01' onClick={handleAdjustSave}>
            추가 항목 저장
          </Button>
        </div>
      </PopUp>
    </div>
  );
};

export default AccountingManageStepSecond;
