import useInput from '@hooks/useInput';
import { Input, Button } from '@components';
import classNames from 'classnames/bind';
import css from './APISearchInput.module.scss';

const cn = classNames.bind(css);

/** search UI만 존재 : 프론트에서 filter가 아닌 Api 통신을 통한 필터링
 * @param {string} className - 컨테이너 컴포넌트에 커스텀으로 추가할 스타일클래스
 * @param {string} placeholder - 검색어 입력창의 가이드 메세지(default: '검색어를 입력해주세요')
 * @param {function} action - input에 쓰일 action 함수
 * @param {function} onClickFunc - 검색 버튼을 클릭했을때 실행되는 함수
 */
const APISearchInput = ({ className, placeholder, action, onClickFunc }) => {
  const [searchText, setSearchText, onChangeSearchText] = useInput(
    '',
    '',
    action && action
  );

  const onSearchClick = (event) => {
    event.preventDefault();
    onClickFunc();
    setSearchText('');
  };

  return (
    <div className={cn('container', className)}>
      <form className={css.form}>
        <Input
          placeholder={placeholder}
          type='text'
          name={'detailCondition'}
          value={searchText}
          onChange={onChangeSearchText}
          fullWidth
        />
        <div className={css.buttonContainer}>
          <Button subType={'type03'} onClick={onSearchClick} fullWidth>
            검색
          </Button>
        </div>
      </form>
    </div>
  );
};

APISearchInput.defaultProps = {
  placeholder: '검색어를 입력해주세요',
  fullWidth: true,
};

export default APISearchInput;
