import { memo, useMemo } from 'react';
import css from './Button.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Button
 * @param {any} children Button 의 자식 요소.
 * @param {string} styleType Button 의 타입 (btn01, btn02, btn03).
 * @param {string} subType Button 의 크기 (type01, type02, type03).
 * @param {string} deliveryStatus Button btn03타입 의 배송 상태 (type01, type02, type03, type04).
 * @param {boolean} fullWidth Button 넓이 100% 여부.
 * @param {boolean} disabled Button 비활성화 여부.
 * @description 공통적으로 사용하는 Button 컴포넌트.
 */
const Button = ({
  children,
  styleType,
  subType,
  deliveryStatus,
  fullWidth,
  disabled,
  className,
  ...rest
}) => {
  const buttonIcon = useMemo(() => {
    let iconType = '';
    if (subType === 'type02') {
      iconType = 'ico_excel';
    } else if (subType === 'type03') {
      iconType = 'ico_upload';
    } else if (subType === 'type04') {
      iconType = 'ico_download';
    }
    return iconType;
  }, [subType]);

  return (
    <button
      className={cn(
        'button',
        styleType,
        subType,
        { fullWidth, disabled },
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {styleType === 'btn02' && <i className={cn(buttonIcon)}></i>}
      {styleType === 'btn03' && deliveryStatus && (
        <i className={cn('ico_act', deliveryStatus)}></i>
      )}
      {children}
    </button>
  );
};

Button.defaultProps = {
  /** Button 타입 지정 (btn01, btn02, btn03, btn04) */
  styleType: 'btn01',
  /** Button 서브 타입(색상, 크기등) 지정 (type01, type02, type03, type04) */
  subType: 'type01',
  /** Button btn03 배송상태 타입 (type01, type02, type03, type04) */
  deliveryStatus: null,
  /** Button 넓이 100% */
  fullWidth: false,
  /** Button 비활성화 활성화 토글 */
  disabled: false,
};

export default memo(Button);
