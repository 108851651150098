import { TAxios } from 'redux/function/TAxios';
import {
  getNotice,
  getPopUpNotice,
  updateNotice,
  deleteNotice,
  setConmments,
  updateConmments,
  deleteComments,
  setNoticeReadCount,
} from '@feature/notice/noticeSlice';
import { replaceImgUploadUrl } from '@utils';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const defaultParams = {
  fromDate: '20231101',
  toDate: new Date(),
  searchType: 'SUBJECT',
  detailCondition: '',
};

/**
 * @category Action
 * @namespace getNoticeList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 *   fromDate: "20230801",
 *   toDate: "20230831",
 *   noticeStatus: "ONB",
 *   searchType: "SUBJECT",
 *   detailCondition: "런드리고"
 * };
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.noticeStatus - 게시 상태 ("ONB" for 게시중, "TBB" for 게시예정, "DEL" for 삭제)
 * @property {string} params.searchType - 검색 유형 ("SUBJECT" or "WRITER")
 * @property {string} params.detailCondition - 상세 조건 (코드네임으로 변경될 수 있음)
 */

const getNoticeList = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/retrieveNoticeList`;
  const onSuccess = (response) => {
    const currentDate = new Date();
    const data = [];
    let noticeObj = {};
    let popupNoticeObj = {};

    const filterDelFlag = response.data.rs.list.filter(
      (item) => item.delFlag === 'N'
    );
    const totalList = filterDelFlag;

    totalList?.forEach((item) => {
      const createDatetime = new Date(item.createDatetime);
      if (createDatetime < currentDate) {
        data.push(item);
      }
    });

    const isTodayBetweenDates = (startDateStr, endDateStr) => {
      const today = new Date();
      const todayDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      return (
        new Date(todayDate.setHours(12, 0, 0, 0)) >=
          new Date(startDate.setHours(0, 0, 0, 0)) &&
        new Date(todayDate.setHours(12, 0, 0, 0)) <=
          new Date(endDate.setHours(23, 59, 59, 0))
      );
    };
    data.forEach((item) => {
      if (!noticeObj[item.noticeKey]) {
        noticeObj[item.noticeKey] = {};
        noticeObj[item.noticeKey] = item;
        noticeObj[item.noticeKey].content = replaceImgUploadUrl(
          'S3ToCloudfront',
          item.content
        );

        if (
          noticeObj[item.noticeKey].popupFlag === 'Y' &&
          isTodayBetweenDates(
            noticeObj[item.noticeKey].popupFromDate,
            noticeObj[item.noticeKey].popupToDate
          )
        ) {
          popupNoticeObj[item.noticeKey] = {};
          popupNoticeObj[item.noticeKey] = item;
          popupNoticeObj[item.noticeKey].content = replaceImgUploadUrl(
            'S3ToCloudfront',
            item.content
          );
        }
      }
    });

    const sortedPopup =
      Object.keys(popupNoticeObj).length > 0 &&
      Object.values(popupNoticeObj)
        .sort(
          (a, b) => new Date(b?.createDatetime) - new Date(a?.createDatetime)
        )
        .splice(0, 1);

    dispatch(getNotice(noticeObj));
    dispatch(getPopUpNotice(sortedPopup ?? []));
  };
  const onFail = (response) => {
    console.log('get Notice List onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};
/**
 * @category Action
 * @namespace updateNoticeItem
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 *   noticeKey: "NOTICE.1699836521000000003"
 * };
 * @property {string} params.noticeKey - 게시물 고유번호(key)
 */

const updateNoticeItem = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/retrieveNoticeDetail`;
  const onSuccess = (response) => {
    const payload = response.data.rs.detailInfo;
    dispatch(updateNotice(payload));
    getNoticeList(dispatch, defaultParams);
  };
  const onFail = (response) => {
    console.log('get Notice List onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getNoticeComment
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 *   noticeKey: "NOTICE.1699836521000000003"
 * };
 * @property {string} params.noticeKey - 게시물 고유번호(key)
 */

const getNoticeComment = (dispatch, params, subAction) => {
  const URL = `/api/1.0/app/notice/retrieveNoticeDetail`;
  const onSuccess = (response) => {
    if (subAction?.key === 'update') {
      dispatch(updateConmments(response.data.rs));
    } else if (subAction?.key === 'delete') {
      dispatch(deleteComments(response.data.rs));
    } else {
      dispatch(setConmments(response.data.rs));
    }
  };
  const onFail = (response) => {
    console.log('get Notice Detail error', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace postSaveNotice
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    companyRegNo: "561-87-00957",
    noticeTarget: "LGE, 703-88-01843, 251-86-02274",
    subject: "공지사항 제목입니다람쥐.",
    noticeFixedFlag: "Y",
    postType: "IMMEDIATE",
    postReservedDate: "2023-11-05",
    postReservedTime: "14:23:41",
    memo: "공지사항 메모입니다.",
    content: "<html>.....</html>",
    userId: "testUserId"
 * };
 * @property {string} params.companyRegNo - 사업자 번호
 * @property {string} params.noticeTarget - 공개 대상
 * @property {string} params.subject - 제목
 * @property {string} params.noticeFixedFlag - 상단 고정 여부
 * @property {string} params.postType - 게시 타입(즉시,예약)
 * @property {string} params.postReservedDate - 예약 날짜
 * @property {string} params.postReservedTime - 예약 시간
 * @property {string} params.content - 내용
 * @property {string} params.userId - useId
 */

const postSaveNotice = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/saveNotice`;
  const onSuccess = () => {
    getNoticeList(dispatch, defaultParams);
  };
  const onFail = (response) => {
    console.log('post save Notice error ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateNotice
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    noticeKey: "NOTICE.1698112055000000000",
    companyRegNo: "561-87-00957",
    noticeTarget: "LGE, 703-88-01843, 251-86-02274",
    subject: "공지사항 제목입니다.",
    noticeFixedFlag: "Y",
    postType: "IMMEDIATE / RESERVED",
    postReservedDate: "2023-11-05",
    postReservedTime: "14:23:41",
    memo: "공지사항 메모입니다.",
    content: "<html>.....</html>",
    userId: "testUserId"
}
 * @property {string} params.noticeKey - 게시물 고유번호(key)
 * @property {string} params.companyRegNo - 사업자 번호
 * @property {string} params.noticeTarget 공개 대상
 * @property {string} params.subject - 제목
 * @property {string} params.postType - 게시 타입(즉시,예약)
 * @property {string} params.noticeFixedFlag - 상단 고정 여부
 * @property {string} params.postReservedDate - 예약 날짜
 * @property {string} params.postReservedTime - 예약 시간
 * @property {string} params.memo - 메모
 * @property {string} params.content - 내용
 * @property {string} params.userId - useId
 */

const postUpdateNotice = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/updateNotice`;
  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      updateNoticeItem(dispatch, { noticeKey: params.noticeKey }, 'update');
    }
  };
  const onFail = (response) => {
    console.log('post update Notice error', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postDeleteNotice
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 *   noticeKey: "NOTICE.1700021491000000062",
 *   userId: "testUserId"
 * };
 * @property {string} params.noticeKey - 게시물 고유번호(key)
 * @property {string} params.userId - useId
 */

const postDeleteNotice = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/deleteNotice`;
  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      const payload = params.noticeKey;
      dispatch(deleteNotice(payload));
    }
  };
  const onFail = (response) => {
    console.log('post delete Notice error', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postSaveNoticeComment
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 *  noticeKey: "NOTICE.1698112055000000000",
 *  content: "공지사항 댓글.............",
 *  secretFlag: "Y",
 *  userId: "testUserId"
}
 * @property {string} params.noticeKey - 게시물 고유번호(key)
 * @property {string} params.content - 댓글 내용
 * @property {secretFlag} params.content - 비밀글인지 여부
 * @property {string} params.userId - userId
 */

const postSaveNoticeComment = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/saveNoticeComment`;
  const onSuccess = () => {
    getNoticeComment(
      dispatch,
      { noticeKey: params.noticeKey },
      { key: 'update' }
    );
  };
  const onFail = (response) => {
    console.log('post save Comment error', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postDeleteComment
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 *  noticeKey: "NOTICE.1700021491000000062"
 *  commentKey: "COMMENT.1698112055000000000",
 *  userId: "testUserId"
}
 * @property {string} params.commentKey - 댓글 고유번호(key)
 * @property {string} params.userId - userId
 */

const postDeleteComment = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/deleteNoticeComment`;
  const onSuccess = (response) => {
    getNoticeComment(
      dispatch,
      {
        noticeKey: params.noticeKey,
      },
      { key: 'delete', state: params }
    );
  };
  const onFail = (response) => {
    console.log('post delete Comment error', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postIncreaseNoticeReadCount
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "noticeKey": "NOTICE.1701988055000000001",
  "userId": "thhan"
}
 * @property {string} params.noticeKey - 공지사항 고유번호(key)
 * @property {string} params.userId - userId
 */

const postIncreaseNoticeReadCount = (dispatch, params) => {
  const URL = `/api/1.0/app/notice/increaseReadCount`;
  const onSuccess = (response) => {
    dispatch(setNoticeReadCount(params.noticeKey));
  };
  const onFail = (response) => {
    console.log('post Increase Notice ReadCount error', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

export {
  getNoticeList,
  getNoticeComment,
  postSaveNotice,
  postUpdateNotice,
  postDeleteNotice,
  postSaveNoticeComment,
  postDeleteComment,
  postIncreaseNoticeReadCount,
};
