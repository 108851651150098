import { TAxios } from 'redux/function/TAxios';
import {
  getModelSuffix,
  setProduct,
  getProductDeatil,
  getDashboardData,
} from '@feature/product/productSlice';
import { dateToStr } from '@utils';
import { openModalAlert } from '@feature/common/commonSlice';
import { setSettlementTargetMerchandise } from '@feature/settlement/settlementSlice';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded', // 요청의 Content-Type 설정
  },
};

/**
 * @category Action
 * @namespace getModelSuffixList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
 * "searchType": "FOC / MODEL / DESC",
 * "detailCondition": "세탁세제"
}

 * @property {string} params.searchType - 검색 조건
 * @property {string} params.detailCondition - 검색 명
 */

const getModelSuffixList = (dispatch, params) => {
  const URL = `/api/1.0/app/common/retrieveModelSuffixList`;

  const onSuccess = (response) => {
    dispatch(getModelSuffix(response.data.rs.list));
  };
  const onFail = (response) => {
    console.log('get Model Suffix onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getProductList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "authority": "LEVEL_1 / LEVEL_2 / LEVEL_3",
  "vendorKey": "VENDOR.00004",
  "searchCategory": "상품등록일(CR) / 판매시작일(SS) / 판매종료일(SE) / 승인완료일(AC)",
  "searchPeriodType": "기간별선택(PREDEF) / 일자별선택(MANUAL)",
  "preDefinedType": "오늘(TODAY) / 최근일주일(WEEK) / 최근한달(MONTH) / 6개월(HALFYEAR) / 1년(YEAR)",
  "fromDate": "20230801",
  "toDate": "20231231",
  "searchType": "상품ID(ID) / 상품명(NAME) / Model.Suffix(MODEL)",
  "detailCondition": "런드리고"
}

 * @property {string} params.authority - 권한 level
 * @property {string} params.vendorKey 
 * @property {string} params.searchCategory 
 * @property {string} params.searchPeriodType 
 * @property {string} params.preDefinedType
 * @property {Date} params.fromDate
 * @property {Date} params.toDate
 * @property {string} params.searchType
 * @property {string} params.detailCondition
 */

const getProductList = (dispatch, params) => {
  const URL = `/api/1.0/app/merchandise/retrieveMerchandiseList`;

  const copiedParams = { ...params };

  ['toDate', 'fromDate'].forEach((key) => {
    if (copiedParams.hasOwnProperty(key)) {
      const date = copiedParams[key];

      if (date instanceof Date) {
        copiedParams[key] = dateToStr(date).split(' ')[0];
      }
    }
  });

  const onSuccess = (response) => {
    let result = response.data.rs?.list;
    if (
      params.vendorKey !== 'VENDOR.00001' &&
      params.vendorKey !== 'VENDOR.00002'
    )
      result = response.data.rs?.list.filter(
        (item) => item.vendorKey === params.vendorKey
      );

    dispatch(setProduct(result));
  };
  const onFail = (response) => {
    console.log('get Product List onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    copiedParams,
    onSuccess,
    onFail,
    false
  );
};

const getProductDetail = (dispatch, params) => {
  const URL = `/api/1.0/app/merchandise/retrieveMerchandiseDetail`;
  const onSuccess = (response) => {
    dispatch(getProductDeatil(response.data.rs.list));
  };
  const onFail = (response) => {
    console.log('get Product Detail onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace postSaveProductTempNewAndSaved
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "merchandiseKey": "HAWA.1698112055000000001",
  "modelSuffix": "ADJ-SC01J.AKOR",
  "focFlag": "FOC / NFOC",
  "category": "COUPON",
  "merchandiseDesc": "공청기 대리주부 청소",
  "validDateFrom": "2023-12-05",
  "validDateTo": "2023-12-31",
  "vendorKey": "VENDOR.00004",
  "description": "비고란...",
  "sellPrice": "10000",
  "netPrice": "7000",
  "profitMargin": "3000",
  "userId": "testUserId"
}

 * @property {string} params.merchandiseKey 
 * @property {string} params.modelSuffix 
 * @property {string} params.focFlag 
 * @property {string} params.category 
 * @property {string} params.merchandiseDesc 
 * @property {string} params.validDateFrom
 * @property {Date} params.validDateTo
 * @property {Date} params.vendorKey
 * @property {string} params.description
 * @property {string} params.sellPrice
 * @property {string} params.netPrice
 * @property {string} params.profitMargin
 * @property {string} params.userId
 */

// 상품등록 - 임시저장 or 수정임시저장(SAVED)
const postSaveProductTempNewAndSaved = (
  dispatch,
  params,
  setIsTempSaved,
  setMerchandiseKey,
  setIsApproval
) => {
  const URL = `/api/1.0/app/merchandise/doSaveMerchandise`;
  const onSuccess = (response) => {
    if (response.data.message === '기간 중복 데이터 존재') {
      dispatch(openModalAlert({ message: '기간중복 데이터가 존재합니다.' }));
      setIsTempSaved && setIsTempSaved(false);
    } else {
      dispatch(openModalAlert({ message: '저장되었습니다.' }));
      setIsTempSaved && setIsTempSaved(true);
      setIsApproval && setIsApproval(true);
      setMerchandiseKey && setMerchandiseKey(response.data.rs.merchandiseKey);
    }
  };
  const onFail = (response) => {
    console.log('post Save Product TempNewAndSaved Product onFail', response);
    dispatch(openModalAlert({ message: '저장이 실패했습니다.' }));
    setIsTempSaved && setIsTempSaved(false);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postSaveProductTemp
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "modelSuffix": "RDJF-SC01J.AKOR",
  "focFlag": "FOC / NFOC",
  "category": "COUPON",
  "merchandiseDesc": "냉장고 대리주부 냉장고정리",
  "validDateFrom": "2023-12-05",
  "validDateTo": "2023-12-31",
  "vendorKey": "VENDOR.00004",
  "description": "비고란...",
  "sellPrice": "판매가(10000)",
  "netPrice": "공급가(7000)",
  "profitMargin": "마진가(3000)",
  "userId": "testUserId"
}

 * @property {string} params.modelSuffix 
 * @property {string} params.focFlag 
 * @property {string} params.category 
 * @property {string} params.merchandiseDesc 
 * @property {string} params.validDateFrom
 * @property {Date} params.validDateTo
 * @property {Date} params.vendorKey
 * @property {string} params.description
 * @property {string} params.sellPrice
 * @property {string} params.netPrice
 * @property {string} params.profitMargin
 * @property {string} params.userId
 */

// 상품등록 - 임시저장- 수정임시저장(APPROVED or REJECT)
const postSaveProductTemp = (dispatch, params, setIsTempSaved) => {
  const URL = `/api/1.0/app/merchandise/doSaveMerchandise`;
  const onSuccess = (response) => {
    if (response.data.message === '기간 중복 데이터 존재') {
      dispatch(openModalAlert({ message: '기간중복 데이터가 존재합니다.' }));
      setIsTempSaved && setIsTempSaved(false);
    } else {
      dispatch(openModalAlert({ message: '저장되었습니다.' }));
      setIsTempSaved && setIsTempSaved(true);
    }
  };
  const onFail = (response) => {
    console.log('postSaveProductTemp Product onFail', response);
    dispatch(openModalAlert({ message: '저장이 실패했습니다.' }));
    setIsTempSaved && setIsTempSaved(false);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postSaveTempProduct
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "merchandiseKey": "HAWA.1702458830000000000",
  "userId": "testUserId"
}
 * @property {string} params.merchandiseKey
 * @property {string} params.userId
 */

// 상품등록 - 승인요청
const postRequestProductApproval = (dispatch, params, setIsApproval) => {
  const URL = `/api/1.0/app/merchandise/doRequestApproval`;
  const onSuccess = (response) => {
    setIsApproval && setIsApproval(false);
    dispatch(openModalAlert({ message: '승인요청 되었습니다.' }));
  };
  const onFail = (response) => {
    console.log('post Request Product Approval onFail', response);
    setIsApproval && setIsApproval(true);
    dispatch(openModalAlert({ message: '승인요청에 실패하였습니다.' }));
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postDoApprovalOrRejected
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "merchandiseKey": "HAWA.1702458830000000000",
  "approvalStatus": "승인(APPROVED) / 반려(REJECTED)",
  "approvalComment": "승인 및 반려 시 추가 Comment...",
  "modelSuffix": "RDJF-SC01J.AKOR",
  "validDateFrom": "2023-12-05",
  "validDateTo": "2023-12-31",
  "description": "상품혜택 등...",
  "sellPrice": "판매가(10000)",
  "netPrice": "공급가(7000)",
  "profitMargin": "마진가(3000)",
  "userId": "testUserId"
}
 * @property {string} params.merchandiseKey
 * @property {string} params.approvalStatus
 * @property {string} params.approvalComment
 * @property {string} params.modelSuffix
 * @property {string} params.validDateFrom
 * @property {string} params.validDateTo
 * @property {string} params.description
 * @property {string} params.sellPrice
 * @property {string} params.netPrice
 * @property {string} params.profitMargin
 * @property {string} params.userId
 */

// 상품조회 - LEVEL_3 - 반려/승인
const postDoApprovalOrRejected = (
  dispatch,
  params,
  decision,
  setDecisionCompleted
) => {
  const URL = `/api/1.0/app/merchandise/doApproveMerchandiseRequest`;
  const onSuccess = (response) => {
    if (decision === 'REJECTED') {
      setDecisionCompleted && setDecisionCompleted(true);
      dispatch(openModalAlert({ message: '반려처리 되었습니다.' }));
    } else {
      dispatch(openModalAlert({ message: '승인처리 되었습니다.' }));
      setDecisionCompleted && setDecisionCompleted(true);
    }
  };
  const onFail = (response) => {
    console.log('post Request Do Approval onFail', response);
    dispatch(openModalAlert({ message: '요청에 실패하였습니다.' }));
    setDecisionCompleted && setDecisionCompleted(false);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

// 상품조회 대쉬보드 데이터
const getRetrieveMerchandiseDashboard = (dispatch, params) => {
  const URL = `/api/1.0/app/merchandise/retrieveMerchandiseDashboard`;
  const onSuccess = (response) => {
    dispatch(getDashboardData(response?.data.rs.dashboardInfo));
  };
  const onFail = (response) => {
    console.log('retrieveMerchandiseDashboard fail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

// 정산, 매출조정을 위해 상품아이디로 데이터 끌어오는 API
const getSettlementTargetMerchandiseAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/merchandise/retrieveSettlementTargetMerchandise`;
  const onSuccess = (response) => {
    //정산 slice reducer
    dispatch(setSettlementTargetMerchandise(response?.data.rs.merchandiseInfo));
  };
  const onFail = (response) => {
    console.log('retrieveSettlementTargetMerchandise fail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

export {
  getProductList,
  getProductDetail,
  getModelSuffixList,
  postSaveProductTempNewAndSaved,
  postSaveProductTemp,
  postRequestProductApproval,
  postDoApprovalOrRejected,
  getRetrieveMerchandiseDashboard,
  getSettlementTargetMerchandiseAPI,
};
