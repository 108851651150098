/**
 * FIXME:
 * 임시로 추가한 상태
 * 객체명 및 내용 적절하게 추가/수정하여 사용
 */

// [POST] /api/1.0/app/auth/approveUserAccount : 계정 관리
export const PERMISSIONS = Object.freeze({
  APPROVED: 'APPROVED',
  UNDER_APPROVAL: 'UNDER_APPROVAL',
  REJECTED: 'REJECTED',
});

// [POST] /api/1.0/app/auth/approveUserAccount : 계정 관리
export const APPROVAL_TARGET = Object.freeze({
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  ACCOUNT: 'ACCOUNT',
});

export const MERCHANDISE_SEARCH_CATEGORY = Object.freeze({
  CR: 'CR', // 상품등록일
  SS: 'SS', // 판매시작일
  SE: 'SE', // 판매종료일
  AC: 'AC', // 승인완료일
});

export const MERCHANDISE_SEARCH_TYPE = Object.freeze({
  FOCFLAG: 'FOCFLAG',
  MNAME: 'MNAME',
  VNAME: 'VNAME',
});

// [GET] /api/1.0/app/notice/retrieveMessageList 받은메세지 리스트
export const MESSAGE_SEARCH_TYPE = Object.freeze({
  ATEGORY: 'ATEGORY',
  GROUP: 'GROUP',
  SENDER: 'SENDER',
  CONTENT: 'CONTENT',
});

// [GET] /api/1.0/app/notice/retrieveNoticeList 공지사항 리스트
export const NOTICE_SEARCH_TYPE = Object.freeze({
  SUBJECT: 'SUBJECT',
  WRITER: 'WRITER',
});

// [GET] /api/1.0/app/common/retrieveModelSuffixList : Model.Suffix 리스트
export const MODEL_SUFFIX_SEARCH_TYPE = Object.freeze({
  FOC: 'FOC',
  MODEL: 'MODEL',
  DESC: 'DESC',
});

// [GET] /api/1.0/app/common/retrieveVendorList : 제휴사리스트(LGE 미포함)
export const VENDOR_LIST_SEARCH_TYPE = Object.freeze({
  NAME: 'NAME',
  REGNO: 'REGNO',
});

// [GET] /api/1.0/app/auth/retrieveInternalUserList : 계정관리(LGE) - 사용자 리스트
// [GET] /api/1.0/app/auth/retrieveVendorMasterList : 사업자정보관리 - 제휴사 리스트
export const SEARCH_TYPE = Object.freeze({
  COMPANY_NAME: 'COMPANY_NAME',
  USER_ID: 'USER_ID',
  USER_NAME: 'USER_NAME',
});

// SearchContainer 사용 시 initialValue에 대한 타입
export const SEARCH_CONTAINER_QUERY_TYPE = Object.freeze({
  DATE: 'DATE',
  SELECT: 'SELECT',
  KEYWORD: 'KEYWORD',
});

export const INPUT_TYPE = Object.freeze({
  TEXT: 'text',
  FILE: 'file',
});

export const DISABLED_DATE_TYPE = Object.freeze({
  WEEKEND: 'weekend',
  HOLIDAY: 'holiday',
});

// 환불관리 - 쿠폰
export const MODIFIED_COUPON_Type = Object.freeze({
  C004: 'C004(사용확정)',
  C006: 'C006(쿠폰폐기)',
});

// 환불관리 - 배송
export const MODIFIED_SHIPPING_Type = Object.freeze({
  D005: 'D005(배송완료 확정)',
  D006: 'D006(배송폐기)',
});

export const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export const SERVICE_START_DATE = '20230801';
