import { useState, memo, useEffect, useCallback } from 'react';
import css from './NoticeRegister.module.scss';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import {
  Button,
  Input,
  Checkbox,
  VerticalTable,
  VerticalTableContainer,
  VerticalTableLabel,
  TextArea,
  Calendar,
} from '@components/index';
import { useInput } from '@hooks';
import { setNoticeForm } from '@feature/notice/noticeSlice';
import { postSaveNotice } from 'redux/action/noticeAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  calculateOffsetBusinessDate,
  convertDateToString2,
  isHoliday,
  isWeekDay,
  transTimeToText,
  replaceImgUploadUrl,
} from 'utils/utils';
import QuillEditor from '@components/QuillEditor/QuillEditor';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const DISABLED_DATE_TYPE = {
  WEEKEND: 'weekend',
  HOLIDAY: 'holiday',
};

const DISABLED_DATE_TYPE_LIST = ['weekend', 'holiday'];
const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const OFFSET = 3;

/**
 * @category components
 * @namespace NoticeRegister
 * @description 공지 등록.
 */

const NoticeRegister = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const formList = useSelector((state) => state.notice.form);
  const content = useSelector((state) => state.notice.form.content);
  const userData = useSelector((state) => state.user.userData);
  const holidaysOfKorea = useSelector(
    ({ holidays: { holidaysOfKorea } }) => holidaysOfKorea
  );

  // Todo: companyCode 는 어디서 ??
  const companyCode = 'LGE';

  const [startDate, setStartDate] = useState(TODAY);
  const [endDate, setEndDate] = useState(TODAY);
  const [maxEndDate, setMaxEndDate] = useState(TODAY);
  //---------------------------------------------------------
  const [subject, setSubject, onChangeSubject] = useInput(
    '',
    false,
    setNoticeForm
  );

  const handlePopupSelect = useCallback(() => {
    setPopupFlag(true);
  }, []);

  const [popupContent, setPopupContent, onChangePopupContent] = useInput(
    '',
    false,
    setNoticeForm,
    handlePopupSelect
  );
  //---------------------------------------------------------

  //noticeFixedFlag "Y" / "N"
  const [noticeFixedFlag, setNoticeFixedFlag] = useState(false);
  //popupFlag "Y" / "N"
  const [popupFlag, setPopupFlag] = useState(false);

  // 공개 범위
  const handleFixedFlag = () => {
    const fixedFlag = !noticeFixedFlag;
    setNoticeFixedFlag(fixedFlag);

    const payLoad = {
      key: 'noticeFixedFlag',
      value: fixedFlag ? 'Y' : 'N',
    };
    dispatch(setNoticeForm(payLoad));
  };

  // 기본값 세팅
  useEffect(() => {
    const payLoad = [
      {
        key: 'popupFromDate',
        value: convertDateToString2(startDate),
      },
      {
        key: 'popupToDate',
        value: convertDateToString2(endDate),
      },
      {
        key: 'companyCode',
        value: companyCode,
      },
      {
        key: 'noticeFixedFlag',
        value: noticeFixedFlag ? 'Y' : 'N',
      },
      {
        key: 'userId',
        value: userData.userId,
      },
      {
        key: 'popupFlag',
        value: popupFlag ? 'Y' : 'N',
      },
    ];
    payLoad.map((item) => {
      dispatch(setNoticeForm(item));
    });
  }, [companyCode, userData]);

  // 폼 저장cloudfrontToS3
  const handleSave = useCallback(
    (e) => {
      if (validateForm()) {
        const cloudfrontToS3 = Object.assign({}, formList, {
          content: replaceImgUploadUrl('cloudfrontToS3', formList.content),
        });
        postSaveNotice(dispatch, cloudfrontToS3);
        dispatch(openModalAlert({ message: '저장되었습니다.' }));
        onRequestClose();

        // dispatch(resetForm());
      } else {
        e.preventDefault();
        dispatch(openModalAlert({ message: '제목,내용은 필수입니다.' }));
      }
    },
    [formList]
  );
  // 필수 입력 필드 체크
  const validateForm = () => {
    if (!subject || !content) {
      return false;
    }
    return true;
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onRequestClose();
  };

  const isBusinessDay = useCallback(
    (date) => {
      if (!DISABLED_DATE_TYPE_LIST.length) return true;

      for (const type of DISABLED_DATE_TYPE_LIST) {
        switch (type) {
          case DISABLED_DATE_TYPE.WEEKEND:
            if (!isWeekDay(date)) return false;
            break;
          case DISABLED_DATE_TYPE.HOLIDAY:
            if (isHoliday({ holidays: holidaysOfKorea, date })) return false;
            break;
          default:
            break;
        }
      }
      return true;
    },
    [holidaysOfKorea]
  );

  // 날짜 선택 이벤트 핸들러
  const handleStartDate = (date) => {
    setStartDate(date);
    handlePopupSelect();

    const AFTER_OFFSET_DATE = calculateOffsetBusinessDate({
      isBusinessDayValidFn: isBusinessDay,
      date,
      offset: OFFSET,
      operator: 'plus',
    });

    setEndDate(AFTER_OFFSET_DATE);
    setMaxEndDate(AFTER_OFFSET_DATE);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    handlePopupSelect();
  };

  const handlePopupFlag = useCallback(() => {
    const flag = !popupFlag;
    setPopupFlag(flag);

    const payLoad = {
      key: 'popupFlag',
      value: flag ? 'Y' : 'N',
    };
    dispatch(setNoticeForm(payLoad));
    //eslint-disable-next-line
  }, [popupFlag]);

  useEffect(() => {
    const AFTER_OFFSET_DATE = calculateOffsetBusinessDate({
      isBusinessDayValidFn: isBusinessDay,
      date: TODAY,
      offset: OFFSET,
      operator: 'plus',
    });

    setMaxEndDate(AFTER_OFFSET_DATE);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const payLoad = [
      {
        key: 'popupFromDate',
        value: format(startDate, 'yyyy-MM-dd'),
      },
      {
        key: 'popupToDate',
        value: format(endDate, 'yyyy-MM-dd'),
      },
      {
        key: 'popupFromTime',
        value: transTimeToText(startDate.getHours(), startDate.getMinutes()),
      },
      {
        key: 'popupToTime',
        value: transTimeToText(endDate.getHours(), endDate.getMinutes()),
      },
    ];
    payLoad.forEach((payload) => {
      dispatch(setNoticeForm(payload));
    });
  }, [startDate, endDate]);

  useEffect(() => {
    const payLoad = {
      key: 'popupFlag',
      value: popupFlag ? 'Y' : 'N',
    };
    popupFlag && dispatch(setNoticeForm(payLoad));
  }, [popupFlag]);

  return (
    <form>
      <strong className={css.popup_title}>공지사항 등록</strong>
      <VerticalTableContainer
        button={[
          <div className={css.buttonContainer}>
            <Button
              key='cancel'
              onClick={(e) => handleCancel(e)}
              className={cn('btn01', 'type03')}
            >
              취소
            </Button>
            <Button
              key='save'
              onClick={(e) => handleSave(e)}
              className={cn('btn01', 'type02')}
              type='submit'
            >
              저장
            </Button>
          </div>,
        ]}
      >
        <VerticalTable title={'게시 정보'}>
          <div className={css.contentBox}>
            <VerticalTableLabel size='auto'>
              <strong>등록</strong>
            </VerticalTableLabel>

            <div className={css.content}>
              <Input
                type='text'
                size='auto'
                readOnly='true'
                value={companyCode}
              />
            </div>
          </div>
        </VerticalTable>
        <VerticalTable
          multiTitle={['제목']}
          isMultiRow={true}
          multiItem={[
            <Input
              type='text'
              value={subject}
              name={'subject'}
              onChange={onChangeSubject}
              placeholder={'제목을 입력하세요'}
              required={true}
              hasByte={true}
              maxByte={100}
            />,
            <Checkbox
              id={'fixTop'}
              onClick={() => handleFixedFlag()}
              checked={noticeFixedFlag}
              className={css.checkbox}
              readOnly
            >
              상단고정
            </Checkbox>,
          ]}
        ></VerticalTable>
        <VerticalTable title={['팝업 설정']}>
          <div className={css.popupContainer}>
            <Checkbox
              id='popupFlag'
              onClick={() => {
                handlePopupFlag();
              }}
              checked={popupFlag}
              readOnly
              className={css.checkbox}
            >
              출력
            </Checkbox>
            <div className={css.calendarContainer}>
              <Calendar
                hasTitle={false}
                hasPeriodInput={false}
                startDate={startDate}
                handleStartDate={handleStartDate}
                setStartDate={setStartDate}
                endDate={endDate}
                handleEndDate={handleEndDate}
                setEndDate={setEndDate}
                disabledDateTypeList={DISABLED_DATE_TYPE_LIST}
                minDate={startDate}
                maxDate={null}
                maxEndDate={maxEndDate}
              />
            </div>
          </div>
        </VerticalTable>
        <VerticalTable title={['팝업 내용']}>
          <TextArea
            type='text'
            value={popupContent}
            name={'popupContent'}
            onChange={onChangePopupContent}
            placeholder={'팝업 내용을 입력하세요'}
            hasByte={true}
            maxByte={400}
          />
        </VerticalTable>
        <VerticalTable title={'텍스트 에디터'}>
          <QuillEditor name={'content'} action={setNoticeForm} />
        </VerticalTable>
        <div className={css.fontLeft}>
          <VerticalTable title={['발행(수정)자']}>
            <div className={css.marginTop}>{userData.userId}</div>
          </VerticalTable>
        </div>
        <div className={css.fontLeft}>
          <VerticalTable title={['최근 수정일시']}>
            <div className={css.marginTop}>
              {convertDateToString2(new Date())}
            </div>
          </VerticalTable>
        </div>
        <div className={css.btn_container}></div>
      </VerticalTableContainer>
    </form>
  );
};

export default memo(NoticeRegister);
