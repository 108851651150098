import React, { memo, useMemo, useEffect, useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SelectUser from './modules/SelectUser/SelectUser';
import QuickMenu from './modules/QuickMenu/QuickMenu';
import Gnb from './modules/Gnb/Gnb';
import Logo from './modules/Logo/Logo';
import SelectBox from '@components/SelectBox/SelectBox';
import css from './DashboardLayout.module.scss';
import { getRetrieveVendorList } from 'redux/action/commonAction';
import { postLogoutBySessionExpired } from 'redux/action/loginAction';
import { PopUpAlert, Button } from '@components/index';

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const isOpenSessionExpired = useSelector(
    (state) => state.common.isSessionExpiredPopup
  );

  useEffect(() => {
    getRetrieveVendorList(dispatch);
  }, [userData, dispatch]);

  const userId = userData.userId;

  return (
    <div className={css.wrap}>
      {/* Header */}
      <div className={css.header}>
        <Logo />
        <Gnb />
        <SelectUser userId={userId} />
      </div>

      {/* Main Contents */}
      <div className={css.main}>
        <div className={css.contents}>
          <Outlet />
        </div>
      </div>

      {/* Side Quick Menu */}
      <QuickMenu />

      {/* Footer */}
      <footer className={css.footer}>
        Copyright © LG Electronics. All Rights Reserved.
      </footer>
      <PopUpAlert
        isOpenPopUp={isOpenSessionExpired}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={() => postLogoutBySessionExpired()}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        오랜 시간 동안 활동 없어 세션이 만료되었습니다. <br />
        다시 로그인하십시오.
      </PopUpAlert>
    </div>
  );
};

export default memo(DashboardLayout);
