import TableCheckbox from '@components/Table/TableCheckbox';
import { PopUp, TableV8 } from '@components/index';
import { createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import css from './ProductListTableV8.module.scss';
import ProductListModifyPopup from './ProductListModifyPopup';

const columnHelper = createColumnHelper();

const ProductListTableV8 = ({ isOpenPopUp, setIsOpenPopUp }) => {
  const productData = useSelector((state) => state.product.contents);
  const [prevProductInfo, setPrevProductInfo] = useState();

  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };

  const handleModifyPopup = (product) => {
    handlePopUpClick();
    setPrevProductInfo(product);
  };

  const columns = [
    columnHelper.accessor('no', {
      id: 'no',
      header: () => <span>No</span>,
      cell: ({ row }) => <span>{row.index + 1}</span>,
    }),
    columnHelper.accessor('focFlagDesc', {
      id: 'focFlagDesc',
      header: () => <span>정산</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor('vendorName', {
      id: 'vendorName',
      header: () => <span>제휴사명</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor('modelSuffix', {
      id: 'modelSuffix',
      header: () => <span>model.Suffix</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor('merchandiseDesc', {
      id: 'merchandiseDesc',
      header: () => <span>상품명</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor('merchandiseId', {
      id: 'merchandiseId',
      header: () => <span>상품 ID</span>,
      cell: (info) => (
        <span
          className={css.underLine}
          onClick={() => handleModifyPopup(info.row.original)}
        >
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('recentModifiedDate', {
      id: 'recentModifiedDate',
      header: () => <span>최근 수정일</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    // columnHelper.accessor('validDateFrom', {
    //   id: 'validDateFrom',
    //   header: () => <span>판매 시작일</span>,
    //   cell: ({ getValue }) => <span>{getValue()}</span>,
    // }),
    // columnHelper.accessor('validDateTo', {
    //   id: 'validDateTo',
    //   header: () => <span>판매 종료일</span>,
    //   cell: ({ getValue }) => <span>{getValue()}</span>,
    // }),
    columnHelper.accessor('sellPrice', {
      id: 'sellPrice',
      header: () => <span>판매가</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor('netPrice', {
      id: 'netPrice',
      header: () => <span>공급가</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
    columnHelper.accessor('approvalStatusDesc', {
      id: 'approvalStatusDesc',
      header: () => <span>상품등록</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),

    columnHelper.accessor('approvalDatetime', {
      id: 'approvalDatetime',
      header: () => <span>승인 완료일</span>,
      cell: ({ getValue }) => <span>{getValue()}</span>,
    }),
  ];

  return (
    <>
      <TableV8 hasCsvDownload={false} data={productData} columns={columns} />

      <PopUp
        isWideSize
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
      >
        <ProductListModifyPopup
          prevProductInfo={prevProductInfo}
          handleModifyPopup={handlePopUpClick}
        />
      </PopUp>
    </>
  );
};

export default ProductListTableV8;
