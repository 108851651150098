import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './CouponUsed.module.scss';
import {
  PopUp,
  Table,
  CsvDownload,
  CsvUpload,
  RadioButton,
  Input,
} from '@components/index';
import { useRadio, useInput } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CouponUsedVendor from './module/CouponUsedVendor';
import CouponUsedLG from './module/CouponUsedLG';

const cn = classNames.bind(css);

const TABLE_MOCK_DATA = [
  {
    some1: 'GC0001',
    some2: '',
    some3: '',
    some4: '',
    some5: '',
    some6: '',
    some7: 'Y',
  },
  {
    some1: 'GC0001',
    some2: '',
    some3: '',
    some4: '',
    some5: '',
    some6: '',
    some7: 'Y',
  },
];

const FAIL_MOCK_DATA = [
  {
    some1: '2021-11-11',
    some2: 'cssg11232',
    some3: '2022-11-01',
    some4: '무상',
    some5: 'Ac0001',
    some6: '런드리고 포인트 쿠폰',
    some7: '-',
    some8: '난수번호 소진',
  },
];

const CouponUsed = ({ handleClickStepNext }) => {
  const couponListStore = useSelector((state) => state.coupon.couponList); //TODO: mockdata 수정 필요
  const [currentCouponItem, setCurrentCouponItem] = useState([]);

  const [selectMerchandiseId, handleClickMerchandiseId] = useRadio(null);
  const [firstNumber, setFirstNumber, onChangeFirstNumber] = useInput('');
  const [secondNumber, setSecondNumber, onChangeSecondNumber] = useInput('');
  const [thirdNumber, setThirdNumber, onChangeThirdNumber] = useInput('');
  const [lastNumber, setLastNumber, onChangeLastNumber] = useInput('');
  const [couponList, setCouponList] = useState([]);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);

  const handlePostRadio = useCallback(
    (title) => {
      handleClickMerchandiseId(title);
      if (title) {
        setCurrentCouponItem(
          couponList.filter((item) => item.merchandiseId === title)
        );
      }
    },
    [couponList]
  );
  const columnData = useMemo(() => {
    return [
      {
        accessor: 'some1',
        Header: '상품 아이디',
      },
      {
        accessor: 'some2',
        Header: '난수번호',
      },
      {
        accessor: 'some3',
        Header: '계약라인번호(CCMS)',
      },
      {
        accessor: 'some4',
        Header: '발행 완료 유무(N/Y)',
      },
      {
        accessor: 'some5',
        Header: '발행 일자',
      },
      {
        accessor: 'some6',
        Header: '계약일',
      },
      {
        accessor: 'some7',
        Header: '완납여부',
      },
    ];
  }, []);

  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };

  const isAllInputComplete = useCallback(() => {
    return (
      firstNumber.length >= 4 &&
      secondNumber.length >= 4 &&
      thirdNumber.length >= 4 &&
      lastNumber.length >= 4
    );
  }, [firstNumber, secondNumber, thirdNumber, lastNumber]);

  const handleAddCoupon = useCallback(() => {
    if (isAllInputComplete()) {
      const newCoupon = `${firstNumber}-${secondNumber}-${thirdNumber}-${lastNumber}`;
      setCouponList((prevList) => [...prevList, newCoupon]);
      // 쿠폰 추가 후 입력 값 초기화
      setFirstNumber('');
      setSecondNumber('');
      setThirdNumber('');
      setLastNumber('');
    }
  }, [firstNumber, secondNumber, thirdNumber, lastNumber, isAllInputComplete]);

  const handleUploadData = (newData) => {
    setCouponList((prevList) => [...prevList, ...newData]);
  };
  const handleDeleteCoupon = useCallback((index) => {
    setCouponList((prevList) => prevList.filter((_, i) => i !== index));
  }, []);

  useEffect(() => {
    if (couponListStore.length > 0) {
      setCouponList(couponListStore);
    }
  }, [couponListStore]);

  const couponTableRow = useMemo(() => {
    let result = [];
    if (Array.isArray(couponList) && couponList.length > 0) {
      result = couponList.map((item) => {
        return (
          <tr key={item.merchandiseId}>
            <td>
              <div className={cn('input_radio', 'type02')}>
                <RadioButton
                  key={item.merchandiseId}
                  id={item.merchandiseId}
                  value={selectMerchandiseId}
                  checked={selectMerchandiseId === item.merchandiseId}
                  onChange={() => handlePostRadio(item.merchandiseId)}
                ></RadioButton>
              </div>
            </td>
            <td>{item.focFlagDesc}</td>
            <td>{item.merchandiseId}</td>
            <td>{item.merchandiseDesc}</td>
            <td>{item.salesStatusDesc}</td>
            <td>{item.validDateFrom}</td>
            <td>{item.validDateTo}</td>
            <td>{item.sellPrice}</td>
          </tr>
        );
      });
    }

    return result;
  }, [couponList, selectMerchandiseId]);

  const couponDelBtn = useMemo(() => {
    let result = null;
    //TODO: 선택된 상품아이디에 할당된 난수 번호 중 사용 완료처리할 리스트가 나와야함
    if (currentCouponItem.length > 0) {
      result = currentCouponItem.map((coupon, index) => (
        <div className={css.item} key={index}>
          {coupon.merchandiseId + '난수번호들'}
          <button
            type='button'
            className={css.delete}
            onClick={() => handleDeleteCoupon(index)}
          >
            삭제
          </button>
        </div>
      ));
    }
    return result;
  }, [currentCouponItem]);

  return process.env.REACT_APP_MODE === 'LG' ? (
    <CouponUsedLG />
  ) : (
    <CouponUsedVendor />
  );
};

export default CouponUsed;
