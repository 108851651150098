import { SEARCH_CONTAINER_QUERY_TYPE } from '@data/Const';
import {
  createContext,
  useState,
  Children,
  isValidElement,
  cloneElement,
  useEffect,
} from 'react';
import {
  isDateInRange,
  matchesCondition,
  hasSearchText,
} from './utils/searchValidationFunctions';

export const searchContext = createContext();

const SearchContainer = ({
  initialValue,
  targetData,
  setFilteredData,
  children,
}) => {
  const [searchQuery, setSearchQuery] = useState(initialValue);

  const onSearchClick = () => {
    const filteredData = targetData.filter((datum) => {
      for (const query of searchQuery) {
        const { type } = query;

        if (type) {
          if (type === SEARCH_CONTAINER_QUERY_TYPE.DATE) {
            const { startDate, endDate, targetKey } = query;

            if (
              !isDateInRange({
                startDate,
                endDate,
                targetDate: new Date(datum[targetKey]),
              })
            )
              return false;
          } else if (type === SEARCH_CONTAINER_QUERY_TYPE.SELECT) {
            const { value, targetKey } = query;

            if (
              !matchesCondition({
                targetCondition: datum[targetKey],
                searchCondition: value,
              }) &&
              value !== null
            )
              return false;
          } else if (type === SEARCH_CONTAINER_QUERY_TYPE.KEYWORD) {
            const { value, targetKey } = query;

            if (
              !hasSearchText({
                targetValue: datum[targetKey],
                searchText: value,
              })
            )
              return false;
          }
        }
      }

      return true;
    });

    setFilteredData(filteredData);
  };

  useEffect(() => {
    setSearchQuery(initialValue);
  }, [initialValue]);

  return (
    <searchContext.Provider value={{ searchQuery, setSearchQuery }}>
      {Children.map(children, (child, index) => {
        // FIXME: 각 컴포넌트 (search, selectbox, calendar) 앞에 라벨이 달릴 수 있음
        if (isValidElement(child))
          return Children.count(children) - 1 !== index
            ? cloneElement(child, { searchConditionIndex: index })
            : cloneElement(child, {
                searchConditionIndex: index,
                onSearchClick,
              });

        return child;
      })}
    </searchContext.Provider>
  );
};

export default SearchContainer;
