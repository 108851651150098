import React, { useEffect } from 'react';
import css from './PasswordChange.module.scss';
import classNames from 'classnames/bind';
import { Input } from '@components/index';
import { Button } from '@components/index';
import { useInput } from '@hooks/index';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPasswordVerificationAndChange,
  postSkipPasswordUpdate,
} from 'redux/action/userAction';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const PasswordChange = ({ handlePasswordChangePopUpClose }) => {
  const userInfo = useSelector((state) => state.user.userData);
  const [currentPassword, setCurrentPassword, onChangeCurrentPassword] =
    useInput('');
  const [newPassword, setNewPassword, onChangeNewPassword] = useInput('');
  const [confirmPassword, setConfirmPassword, onChangeConfirmPassword] =
    useInput('');
  const userId = userInfo.userId;
  const dispatch = useDispatch();

  const handleCancel = () => {
    handlePasswordChangePopUpClose();
  };

  const resetPasswordFields = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const handleSave = () => {
    const changePasswordParams = {
      userId: userId,
      password: newPassword,
    };

    if (!currentPassword || !newPassword || !confirmPassword) {
      dispatch(openModalAlert({ message: '정보를 입력해주세요' }));
    } else if (newPassword !== confirmPassword) {
      dispatch(
        openModalAlert({
          message: '새비밀번호와 비밀번호 확인이 일치 하지 않습니다.',
        })
      );
    } else if (newPassword === userId) {
      dispatch(
        openModalAlert({
          message:
            '사용 불가능한 패스워드 입니다.(아이디와 동일 하지 않게 입력해주세요.)',
        })
      );
    } else if (
      confirmPassword.length < 8 ||
      confirmPassword.length > 16 ||
      newPassword.length < 8 ||
      newPassword.length > 16
    ) {
      dispatch(
        openModalAlert({ message: '글자수를 확인 해주세요. (8 ~ 16자)' })
      );
    } else {
      const checkUserParams = {
        userId: userId,
        password: currentPassword,
      };

      getPasswordVerificationAndChange(
        dispatch,
        checkUserParams,
        changePasswordParams,
        handlePasswordChangePopUpClose,
        resetPasswordFields
      );
    }
  };

  const handleChangePasswordNext = () => {
    handlePasswordChangePopUpClose();
    const params = {
      userId: userId,
    };
    postSkipPasswordUpdate(dispatch, params);
  };

  return (
    <div id='wrap' className={cn('has-no-header', 'registerWrap')}>
      <div id='container'>
        <div className={css.registerContainer}>
          <h2 className={css.pageTitle2}>비밀번호 재설정</h2>
          <div className={css.register_input}>
            <div className={css.mb10}>
              비밀번호를 변경 해 주세요. <br />
              {userInfo.authority === 'LEVEL_1'
                ? '*유효기간 1개월'
                : '*유효기간 2개월'}
            </div>
            <div className={css.inputWrap}>
              <div className={css.input_text}>
                <Input
                  type='password'
                  id='currentPassword'
                  placeholder='기존 비밀 번호 입력'
                  value={currentPassword}
                  onChange={onChangeCurrentPassword}
                />
              </div>
            </div>
            <div className={css.inputWrap}>
              <div className={css.input_text}>
                <Input
                  type='password'
                  id='newPassword'
                  placeholder='신규 비밀번호를 입력해 주세요'
                  value={newPassword}
                  onChange={onChangeNewPassword}
                />
              </div>
            </div>
            <div className={css.inputWrap}>
              <div className={css.input_text}>
                <Input
                  type='password'
                  id='confirmPassword'
                  placeholder='비밀번호를 다시 입력해 주세요'
                  value={confirmPassword}
                  onChange={onChangeConfirmPassword}
                />
              </div>
            </div>
          </div>
          <div className={css.btn_container4}>
            <Button
              onClick={handleCancel}
              className={cn('btn', 'btn01', 'type03')}
            >
              취소
            </Button>
            <Button
              onClick={handleSave}
              className={cn('btn', 'btn01', 'type02')}
            >
              저장
            </Button>
            <Button
              onClick={handleChangePasswordNext}
              className={cn('btn', 'btn01', 'type01')}
            >
              다음에 변경
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChange;
