import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingManage.module.scss';
import AccountingManageStepFirst from './AccountingManageStep/AccountingManageStepFirst';
import AccountingManageStepSecond from './AccountingManageStep/AccountingManageStepSecond';
import { PopUpAlert, Button, PopUp, SelectBox } from '@components/index';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSelect } from '@hooks/index';
import AccountingManageStepFlow from './module/AccountingManageStepFlow';
import { ACCOUNTING_SUB_PATH } from '@router/routerPath';
import { useEffect } from 'react';

const cn = classNames.bind(css);

const AccountingManage = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState('first');
  const [isOpenNextPopUp, setIsOpenNextPopUp] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = useCallback((path) => {
    navigate(path);
    // eslint-disable-next-line
  }, []);

  const onClickNext = useCallback(() => {
    setIsOpenNextPopUp(!isOpenNextPopUp);
  }, []);

  const onClickPrev = useCallback(() => {
    setStep('first');
  }, [step]);

  const onClickNextConfirm = useCallback(() => {
    if (step === 'first') {
      setStep('second');
    }
    setIsOpenNextPopUp(false);
  }, [step]);

  const nextGuideText = `당월 매출 정산 내역을 확인 하셨습니까?
매출조정 단계로 이동합니다.`;

  return (
    <>
      <AccountingManageStepFlow />
      {step === 'first' ? (
        <AccountingManageStepFirst />
      ) : (
        <AccountingManageStepSecond />
      )}
      <div className={cn('stepsNav')}>
        {step !== 'first' && (
          <Button
            onClick={onClickPrev}
            type='button'
            className={cn('btn', 'btn01', 'type01')}
          >
            <i className={cn('ico_prev')}></i>이전 단계로 이동
          </Button>
        )}

        {step === 'first' ? (
          <Button
            type='button'
            className={cn('btn', 'btn01', 'type01')}
            onClick={onClickNext}
          >
            다음 단계로 이동<i className={cn('ico_next')}></i>
          </Button>
        ) : (
          <Button
            type='button'
            className={cn('btn', 'btn01', 'type01')}
            onClick={() => handleNavigate('/' + ACCOUNTING_SUB_PATH.APPROVAL)}
          >
            업체별 매출 확인 완료 <i className={cn('ico_next')}></i>
          </Button>
        )}
      </div>

      {/* 다음단계로 이동 popUp */}
      <PopUpAlert
        isOpenPopUp={isOpenNextPopUp}
        onRequestClose={() => setIsOpenNextPopUp(false)}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={onClickNextConfirm}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        <div className={cn('nextGuideText')}>{nextGuideText}</div>
      </PopUpAlert>
    </>
  );
};

export default AccountingManage;
