import { convertDateToString2 } from '@utils';

export const isDateInRange = ({ targetDate, startDate, endDate }) => {
  if (!targetDate || !startDate || !endDate) return true;

  return (
    convertDateToString2(startDate) <= convertDateToString2(targetDate) &&
    convertDateToString2(endDate) >= convertDateToString2(targetDate)
  );
};

export const matchesCondition = ({ targetCondition, searchCondition }) =>
  targetCondition === searchCondition || searchCondition === '';

export const hasSearchText = ({ targetValue, searchText }) =>
  targetValue.includes(searchText);
