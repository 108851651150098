import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from '../CouponUsed.module.scss';
import {
  PopUp,
  Table,
  CsvDownload,
  CsvUpload,
  RadioButton,
  Input,
  SelectBox,
  TableV8,
  Button,
  PopUpAlert,
  VerticalTable,
  VerticalTableContainer,
} from '@components/index';
import { useRadio, useInput, useSelect } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import flow_coupon_manage from '@assets/img/img_flow_coupon_manage.png';
import { createColumnHelper } from '@tanstack/react-table';
import TableCheckbox from '@components/Table/TableCheckbox';
import {
  getCouponApprovalStatusDashboardAPI,
  getCouponUseApprovalStatusList,
  postApproveCouponUseComplete,
  // getCouponUseApprovalStatusList,
} from 'redux/action/couponAction';
import HFInput from '@components/_ReactHookForm/HFInput';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { useRef } from 'react';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();
const FAIL_MOCK_DATA = [
  {
    some1: '2021-11-11',
    some2: 'cssg11232',
    some3: '2022-11-01',
    some4: '무상',
    some5: 'Ac0001',
    some6: '런드리고 포인트 쿠폰',
    some7: '-',
    some8: '난수번호 소진',
  },
];

const SUBMIT_TYPE = {
  STATUS_APPROVED: 'STATUS_APPROVED',
  STATUS_REJECTED: 'STATUS_REJECTED',
};

const CouponUsedLG = () => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { rejectReason: '' },
  });
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const targetVendorKey = searchParams.get('vendorKey');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');

  const dispatch = useDispatch();
  const couponUseApprovalStatus = useSelector(
    (state) => state.coupon.couponUseApprovalStatus.list
  );

  const couponUseApprovalStatusTotalCount = useSelector(
    (state) => state.coupon.couponUseApprovalStatus.additionalInfo.totalCount
  );
  const couponApprovalStatusDashboard = useSelector(
    (state) => state.coupon.couponApprovalStatusDashboard
  );
  // const willCompleteTargetCouponList = useSelector(
  //   (state) => state.coupon.willCompleteTargetCouponList.list
  // );
  // const willCompleteTargetCouponTotalCount = useSelector(
  //   (state) => state.coupon.willCompleteTargetCouponList.additionalInfo.totalCount
  // );
  const userData = useSelector((state) => state.user.userData);
  const vendorList = useSelector((state) => state.common.vendorList);

  const [isOpenReject, setIsOpenReject] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [selectedCouponkeys, setSelectedCouponkeys] = useState([]);
  const selectBoxOptionList = useMemo(() => {
    let optionList = [{ id: 0, title: '전체', vendorKey: 'VENDOR.00000' }];
    if (vendorList.length > 0) {
      optionList = [
        ...optionList,
        ...vendorList.map((item, index) => {
          return {
            id: index + 1,
            title: item.companyName,
            vendorKey: item.vendorKey,
          };
        }),
      ];
    }
    return optionList;
  }, [vendorList]);
  const [
    selectedStatusIndex,
    setSelectedStatusIndex,
    selectedStatusLabel,
    setManualSelectedItem,
  ] = useSelect({
    optionList: selectBoxOptionList,
    initialIndex: 0,
    initialLabel: selectBoxOptionList[0].title,
  });

  const selectedVendorItems = useMemo(() => {
    const target = couponUseApprovalStatus;
    let filteredItems = [];
    if (target) {
      const vendorKey = selectBoxOptionList[selectedStatusIndex].vendorKey;
      if (vendorKey && vendorKey !== 'VENDOR.00000') {
        filteredItems = target.filter((item) => item.vendorKey === vendorKey);
      } else filteredItems = target;

      filteredItems = filteredItems.map((filteredItem, index) => {
        return Object.assign({}, filteredItem, {
          no: filteredItems.length - index,
        });
      });
    }
    return filteredItems;
  }, [couponUseApprovalStatus, selectedStatusIndex, selectBoxOptionList]);

  const columnData = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('no', {
        id: 'no',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>No</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사</span>,
      }),
      columnHelper.accessor('couponUseRequestDate', {
        id: 'couponUseRequestDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>요청일</span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품 아이디</span>,
      }),
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('validDateFrom', {
        id: 'validDateFrom',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            쿠폰시작유효기간
            <br />
            {`(CNP_START_DATE)`}
          </span>
        ),
      }),
      columnHelper.accessor('validDateTo', {
        id: 'validDateTo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            쿠폰종료유효기간
            <br />
            {`(CNP_END_DATE)`}
          </span>
        ),
      }),
      columnHelper.accessor('couponUseApprovalStatusDesc', {
        id: 'couponUseApprovalStatusDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상태</span>,
      }),
      columnHelper.accessor('couponUseApprovalComment', {
        id: 'couponUseApprovalComment',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>비고</span>,
      }),
    ],
    []
  );

  const onCouponUsedSubmit = useCallback(
    ({ data, type }) => {
      const { rejectReason } = data;

      switch (type) {
        case SUBMIT_TYPE.STATUS_REJECTED:
          const rejectParams = {
            approvalStatus: 'REJECTED',
            updateTargetList: JSON.stringify(selectedCouponkeys),
            rejectComment: rejectReason,
            userId: userData.userId,
          };
          postApproveCouponUseComplete(
            dispatch,
            rejectParams,
            setIsOpenReject,
            setIsOpenConfirm
          );
          const tempGetListParams = {
            vendorKey: selectBoxOptionList[selectedStatusIndex].vendorKey,
            authority: userData.authority,
            limit: '100',
            page: '1',
          };
          getCouponUseApprovalStatusList(dispatch, tempGetListParams);
          break;
        case SUBMIT_TYPE.STATUS_APPROVED:
          const approvalParams = {
            approvalStatus: 'APPROVED',
            updateTargetList: JSON.stringify(selectedCouponkeys),
            rejectComment: '',
            userId: userData.userId,
          };
          postApproveCouponUseComplete(
            dispatch,
            approvalParams,
            setIsOpenReject,
            setIsOpenConfirm
          );

          const tempGetListParamsApproved = {
            vendorKey: selectBoxOptionList[selectedStatusIndex].vendorKey,
            authority: userData.authority,
            limit: '100',
            page: '1',
          };
          getCouponUseApprovalStatusList(dispatch, tempGetListParamsApproved);
          break;
        default:
          break;
      }
    },
    [
      userData.userId,
      userData.authority,
      selectBoxOptionList,
      selectedStatusIndex,
      selectedCouponkeys,
      dispatch,
    ]
  );

  const selectedRowCallback = useCallback((rows) => {
    const couponKeys = rows.map((row) => {
      return row.couponKey;
    });
    // couponKeyRef.current = couponKeys;
    setSelectedCouponkeys(couponKeys);
  }, []);

  const listCallbackBySearchParams = useCallback(
    (params) => {
      if (params?.vendorKey) {
        const targetIndex = selectBoxOptionList.findIndex(
          (el) => el.vendorKey === params.vendorKey
        );
        selectBoxOptionList[targetIndex] &&
          setManualSelectedItem(selectBoxOptionList[targetIndex]);
        getCouponUseApprovalStatusList(
          dispatch,
          Object.assign({}, params, {
            vendorKey: selectBoxOptionList[selectedStatusIndex].vendorKey,
            authority:
              selectedStatusIndex === 0 ? userData.authority : 'LEVEL_3',
          })
        );
      } else {
        getCouponUseApprovalStatusList(dispatch, params);
      }
    },
    [selectBoxOptionList, selectedStatusIndex]
  );

  useEffect(() => {
    const prams = {
      vendorKey: selectBoxOptionList[selectedStatusIndex].vendorKey
        ? selectBoxOptionList[selectedStatusIndex].vendorKey
        : targetVendorKey,
      authority: selectedStatusIndex === 0 ? userData.authority : 'LEVEL_3',
      limit: limit ?? '100',
      page: page ?? '1',
    };
    listCallbackBySearchParams(prams);
    getCouponApprovalStatusDashboardAPI(dispatch);
  }, [listCallbackBySearchParams, page, limit]);

  // useEffect(() => {
  //   const params = {
  //     vendorKey: targetVendorKey ?? '',
  //     authority: userData.authority,
  //     limit: limit ?? '10',
  //     page: page ?? '1',
  //   };
  //   listCallbackBySearchParams(params);
  // }, []);

  // handlePagecallback Table이 여러개인 화면에 경우 handlePagecallback 함수를 여러개 만들어서 키( ex) page1 / limit1)를 다르게 셋팅해야 의도한대로 동작함
  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '100');
    setSearchParams(searchParams);
  }, []);

  return (
    <>
      <h2 className={css.pageTitle}>사용 완료 승인</h2>
      <div className={css.status}>
        <dl>
          <dt>사용 완료 미확정 제휴사</dt>
          <dd>
            <div className={css.value}>
              <span className={css.num}>
                {couponApprovalStatusDashboard.couponUseUnderApprovalVendorCnt}
              </span>
              건
            </div>
            {/* <p className={css.standard}>* 접속 월 기준</p> */}
          </dd>
          <div className={cn('desc')}>(미확정 제휴사 수)</div>
        </dl>
        <dl>
          <dt>사용 확정 승인 제휴사</dt>
          <dd>
            <div className={css.value}>
              <span className={css.num}>
                {couponApprovalStatusDashboard.couponUseApprovedVendorCnt}
              </span>
              건
              <button type='button' className={cn('co_list_btn')}>
                업체 목록
                <div
                  className={cn('co_list')}
                  style={{
                    display:
                      couponApprovalStatusDashboard.couponUseApprovedVendorName
                        ? ''
                        : 'none',
                  }}
                >
                  {couponApprovalStatusDashboard.couponUseApprovedVendorName}
                </div>
              </button>
            </div>
          </dd>
          <div className={cn('desc')}>
            (쿠폰 상품을 가진 제휴사 중 접속월 사용 확정 승인이력이 있는 업체
            수)
          </div>
        </dl>
        <dl>
          <dt>사용 확정 요청 반려</dt>
          <dd>
            <div className={css.value}>
              <span className={css.num}>
                {couponApprovalStatusDashboard.couponUseRejectedVendorCnt}
              </span>
              건
              <button type='button' className={cn('co_list_btn')}>
                업체 목록
                <div
                  className={cn('co_list')}
                  style={{
                    display:
                      couponApprovalStatusDashboard.couponUseRejectedVendorName
                        ? ''
                        : 'none',
                  }}
                >
                  {couponApprovalStatusDashboard.couponUseRejectedVendorName}
                </div>
              </button>
            </div>
          </dd>
          <div className={cn('desc')}>(반려 이력이 있는 제휴사 수)</div>
        </dl>
      </div>
      <div className={cn('flowChart')}>
        <h3 className={cn('title')}>쿠폰 발행 Flow</h3>
        <div className={cn('img')}>
          <img src={flow_coupon_manage} alt='상품 조회 Flow 이미지' />
        </div>
      </div>
      <div className={cn('stepsFlow', 'mt32')}>
        <div className={cn('steps', 'on')}>
          쿠폰 생성 등록<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 발행<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 사용 등록<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 사용 확정 승인<span className={css.badge}>진행중</span>
        </div>
      </div>

      <div className={cn('stepGuideText')}>
        ① 제휴사에서 사용 확정 승인 요청 된 쿠폰을 확인해주세요
      </div>
      <TableV8ManualPage
        columns={columnData}
        data={selectedVendorItems}
        hasCsvDownload={false}
        getRowselected={selectedRowCallback}
        page={page}
        limit={limit ?? undefined}
        handlePageCallback={handlePageCallback}
        hasPageSize={false}
        totalLength={couponUseApprovalStatusTotalCount}
        rightControls={
          <div className={css.innerBox}>
            <div className={cn('selectbox', 'type01', 'sizeS')}>
              <SelectBox
                optionList={selectBoxOptionList}
                currentIndex={selectedStatusIndex}
                handleSelectIndex={setSelectedStatusIndex}
                currentLabel={selectedStatusLabel}
                fullWidth={true}
              />
            </div>
          </div>
        }
      />

      <div className={cn('note', 'mt10')}>
        ※ 쿠폰종료유효기간 만료되었 거나 쿠폰 생성이력(N)없거나 정산 이력(Y)이
        있는 난수번호는 확정 선택 시 반려됩니다.
      </div>
      <div className={cn('stepsNav')}>
        <div className={cn('note', 'mb10')}>
          * 쿠폰 종료 유효기간 만료는 접속일자 기준, 제외 항목 중 1개만
          해당되어도 선택 취소 됨
        </div>
        <div className={css.btn_container6}>
          <div className={css.btn}>
            <Button
              styleType={'btn01'}
              subType={'type01'}
              onClick={() => setIsOpenReject(true)}
              fullWidth
              disabled={selectedCouponkeys?.length !== 1}
            >
              반려
            </Button>
          </div>
          <div className={css.btn}>
            <Button
              styleType={'btn01'}
              subType={'type02'}
              onClick={() => setIsOpenConfirm(true)}
              fullWidth
              disabled={selectedCouponkeys?.length === 0}
            >
              확정
            </Button>
          </div>
        </div>
      </div>
      <PopUpAlert
        title={'사용 완료 요청 반려'}
        isOpenPopUp={isOpenReject}
        button={[
          <div className={css.btn_container6}>
            <div className={css.btn}>
              <Button
                key='alertConfirm'
                subType={'type03'}
                onClick={() => setIsOpenReject(false)}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='alertConfirm'
                subType={'type02'}
                onClick={handleSubmit((data) =>
                  onCouponUsedSubmit({
                    data,
                    type: SUBMIT_TYPE.STATUS_REJECTED,
                  })
                )}
                fullWidth
              >
                반려
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={cn('mt15', 'mb10')}>반려 사유를 입력해주세요</div>
        <HFInput
          type={'text'}
          name={'rejectReason'}
          register={register}
          placeholder={'10자 이내로 입력 해주세요.'}
        />
      </PopUpAlert>
      <PopUpAlert
        title={'확정 쿠폰 확인'}
        isOpenPopUp={isOpenConfirm}
        button={[
          <div className={css.btn_container6}>
            <div className={css.btn}>
              <Button
                key='rejectConfirm'
                subType={'type03'}
                onClick={() => setIsOpenConfirm(false)}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='alertConfirm'
                subType={'type02'}
                onClick={handleSubmit((data) =>
                  onCouponUsedSubmit({
                    data,
                    type: SUBMIT_TYPE.STATUS_APPROVED,
                  })
                )}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={cn('mt10')}>
          총 {selectedCouponkeys?.length ?? 0}건의 난수번호가 사용 확정됩니다.
          <br />
          사용완료 처리 하시겠습니까?
        </div>
      </PopUpAlert>
    </>
  );
};

export default CouponUsedLG;
