import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  holidaysOfKorea: {
    '': [],
  },
};

const holidays = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    setHolidayList: (state, action) => {
      state.holidaysOfKorea = action.payload;
    },
  },
});

export const { setHolidayList } = holidays.actions;
export default holidays.reducer;
