import React, { useState, useRef, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import css from './CalendarTimer.module.scss';
import { ko } from 'date-fns/esm/locale';
import ico_calendar from '@assets/img/ico_calendar.svg';
import ico_time from '@assets/img/ico_time.svg';
import { memo } from 'react';
import '../Calendar/Calendar.css';
import * as Utils from '@utils';
import classNames from 'classnames/bind';
import { setNoticeForm } from '@feature/notice/noticeSlice';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

/**
 * @category Components
 * @namespace Calendar
 * @param {date} startDate 시작일
 * @param {boolean} hasTimeSetter TimeSetter 존재 여부
 * @param {date} minDate 최소 날짜
 * @param {date} maxDate 최대 날짜
 */

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const CalendarTimer = ({
  startDate,
  handleStartDate,
  setStartDate,
  hasTimeSetter,
  minDate,
  maxDate,
  disabled,
}) => {
  const dispatch = useDispatch();
  const startDateRef = useRef(null);
  const startTimeRef = useRef(null);
  const minTime = new Date();

  // 현재 시간에서 30분 추가
  minTime.setMinutes(minTime.getMinutes());

  const startDateFocus = useCallback(() => {
    // DatePicker에 handleFocus 메소드가 내장되어있습니다.
    // 아이콘 클릭 시 DatePicker에 포커스 줘서 달력 UI Open 시킴.
    startDateRef.current.handleFocus();
  }, []);

  const startTimeFocus = useCallback(() => {
    startTimeRef.current.handleFocus();
  }, []);

  useEffect(() => {
    if (startDate <= minTime) {
      setStartDate(minTime);
    }
  }, []);

  useEffect(() => {
    const payLoad = [
      {
        key: 'postReservedDate',
        value: format(startDate, 'yyyy-MM-dd'),
      },
      {
        key: 'postReservedTime',
        value: Utils.transTimeToText(
          startDate.getHours(),
          startDate.getMinutes()
        ),
      },
    ];
    payLoad.forEach((payload) => {
      dispatch(setNoticeForm(payload));
    });
  }, [startDate]);

  return (
    <div className={css.container}>
      <DatePicker
        selected={startDate}
        onChange={handleStartDate}
        dateFormat='yyyy-MM-dd'
        className={css.datePicker}
        ref={startDateRef}
        minDate={minDate}
        maxDate={maxDate}
        showIcon
        icon={
          <i className={css.calIcon}>
            <img
              onClick={() => {
                startDateFocus();
              }}
              src={ico_calendar}
              alt={'calendar Icon'}
            />
          </i>
        }
        locale={ko}
        showYearDropdown
        scrollableMonthYearDropdown
      />
      {hasTimeSetter && (
        <div className={css.timeSetter}>
          <DatePicker
            selected={startDate}
            onChange={handleStartDate}
            dateFormat='hh:mm'
            className={css.datePicker}
            ref={startTimeRef}
            minDate={minDate}
            maxDate={maxDate}
            showIcon
            icon={
              <i className={css.calIcon}>
                <img
                  onClick={() => {
                    startTimeFocus();
                  }}
                  src={ico_time}
                  alt={'time Icon'}
                />
              </i>
            }
            locale={ko}
            showTimeSelect
            showTimeSelectOnly
          />
        </div>
      )}
    </div>
  );
};

CalendarTimer.defaultProps = {
  startDate: new Date(),
  hasTimeSetter: true,
  minDate: new Date(),
};

export default memo(CalendarTimer);
