import { memo, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import css from './NoticeDetail.module.scss';
import {
  Button,
  TextArea,
  VerticalTable,
  VerticalTableContainer,
  PopUpAlert,
} from '@components/index';
import { useState } from 'react';
import { useInput } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  postSaveNoticeComment,
  getNoticeComment,
  postDeleteComment,
} from 'redux/action/noticeAction';
import { setNoticeComment } from '@feature/notice/noticeSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace ProductDetail
 * @description 공지사항 상세
 */

const NoticeDetail = ({
  onRequestClose,
  openChildPopUp,
  currentNotice,
  isHomePopUp,
}) => {
  const myAuthority = useSelector((state) => state.user.userData.authority);
  const userId = useSelector((state) => state.user.userData.userId);
  const dispatch = useDispatch();

  // 삭제 팝업
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };

  // 댓글기능
  const [content, setContent, onChangeContent] = useInput(
    '',
    false,
    setNoticeComment
  );
  const conmmentsList = useSelector((state) => state.notice.conmmentsList);

  const targetComment = useMemo(() => {
    let resultArr = [];
    if (conmmentsList?.[currentNotice?.noticeKey]) {
      resultArr = conmmentsList[currentNotice.noticeKey];
    }
    return resultArr;
  }, [conmmentsList, currentNotice.noticeKey]);

  // 수정,저장,삭제 기능
  const handleModify = () => {
    onRequestClose();
    openChildPopUp();
  };

  const handleSaveComment = useCallback(() => {
    const params = {
      noticeKey: currentNotice.noticeKey,
      secretFlag: 'N',
      userId: userId,
      content: content,
    };
    postSaveNoticeComment(dispatch, params);
    setContent('');
  }, [content, currentNotice, userId]);

  const handleDeleteComment = useCallback(
    (commentKey, userId, noticeKey) => {
      setIsOpenPopUp(false);
      postDeleteComment(dispatch, {
        userId: userId,
        commentKey: commentKey,
        noticeKey: noticeKey,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentNotice?.noticeKey && currentNotice?.commentCount > 0) {
      getNoticeComment(dispatch, {
        noticeKey: currentNotice.noticeKey,
      });
    }
  }, [dispatch, currentNotice]);

  return (
    <>
      <strong className={css.popup_title}>공지사항 상세</strong>
      {currentNotice && (
        <VerticalTableContainer>
          <div className={css.noticeMetaDataBody}>
            <div>
              <div>
                <span className={css.popup_title}>
                  <strong>{currentNotice.subject}</strong>
                </span>
              </div>
            </div>
            <div className={css.noticeMetaSubData}>
              <div>
                <div>
                  <span>{`작성자: `}</span>
                  {currentNotice.updateUserId
                    ? currentNotice.updateUserId
                    : currentNotice.createUserId}
                </div>
                <div>
                  <span>{`게시시간: `}</span>
                  {currentNotice.createDatetime}
                </div>
              </div>
              {!isHomePopUp && (
                <div className={css.buttonContainer}>
                  <Button key='save' onClick={handleModify}>
                    수정
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              minHeight: '380px',
              padding: '16px',
              borderRadius: '8px',
              border: '1px solid #ddd',
              background: '#fff',
              fontSize: '16px',
              color: '#222',
            }}
            dangerouslySetInnerHTML={{
              __html: currentNotice.content,
            }}
          />
          {currentNotice.popupContent && (
            <VerticalTable title={'팝업 내용'}>
              <TextArea
                value={currentNotice.popupContent}
                hasByte={true}
                maxByte='400'
                disabled
              />
            </VerticalTable>
          )}
        </VerticalTableContainer>
      )}

      {!isHomePopUp && (
        <>
          <div className={css.reply_container}>
            <div className={css.reply_wrapper}>
              {Array.isArray(targetComment) &&
                targetComment.length > 0 &&
                targetComment.map((reply, idx) => {
                  return (
                    <div className={css.reply_wrap} key={idx}>
                      <div className={css.info}>
                        <div className={css.name}>{reply.createUserId}</div>
                        <div className={css.date}>{reply.createDatetime}</div>
                        {(reply.createUserId === userId ||
                          myAuthority === 'LEVEL_1' ||
                          myAuthority === 'LEVEL_2') && (
                          <button
                            onClick={handlePopUpClick}
                            type='button'
                            className={css.delete}
                          >
                            삭제
                          </button>
                        )}
                        <PopUpAlert
                          title='작성한 댓글을 삭제 하시겠습니까?'
                          isOpenPopUp={isOpenPopUp}
                          onRequestClose={() => setIsOpenPopUp(false)}
                          button={[
                            <Button
                              key='firstButton'
                              onClick={() => setIsOpenPopUp(false)}
                            >
                              취소
                            </Button>,
                            <Button
                              key='secondButton'
                              onClick={() =>
                                handleDeleteComment(
                                  reply.commentKey,
                                  reply.createUserId,
                                  reply.noticeKey
                                )
                              }
                            >
                              확인
                            </Button>,
                          ]}
                        ></PopUpAlert>
                      </div>
                      <div className={css.cont}>{reply.content}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={css.reply_write}>
            <div className={css.input_textarea}>
              <TextArea
                value={content}
                name='content'
                id=''
                rows='1'
                placeholder='댓글을 등록하세요.'
                onChange={onChangeContent}
              ></TextArea>
            </div>
            <div className={css.apply}>
              <button
                onClick={() => handleSaveComment()}
                type='button'
                className={cn('btn', 'btn03', 'type01')}
              >
                등록하기
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(NoticeDetail);
