import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './RefundRetrieve.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import RefundRetrieveSearchArea from './module/RefundRetrieveSearchArea';
import { FormProvider, useForm } from 'react-hook-form';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { getRefundCompletedList } from 'redux/action/refundAction';
import { convertDateToString2 } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const categorySearchOptions = {
  guide: '',
  list: [
    { id: '쿠폰', title: '쿠폰' },
    { id: '배송', title: '배송' },
  ],
};

const RefundRetrieve = () => {
  const dispatch = useDispatch();
  const searchForms = useForm();
  const refundCompletedList = useSelector(
    (state) => state.refund.refundCompletedList
  );

  // 초기 세팅값
  useEffect(() => {
    getRefundCompletedList(dispatch, {
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
      refundCategory: '',
      refundStatus: '',
      serviceType: '',
      vendorKey: '',
      limit: '100',
      page: '1',
    });
  }, []);

  // 검색 버튼 or 대시보드 클릭시 변경 되는 값 세팅
  // Todo: APi params에 맞게 변경
  const listParams = useCallback((fromDate, toDate, categorySearchOptions) => {
    const result = {
      fromDate: convertDateToString2(fromDate),
      toDate: convertDateToString2(toDate),
      refundCategory: '',
      refundStatus: '',
      serviceType: categorySearchOptions === '쿠폰' ? 'C' : 'D',
      vendorKey: '',
      limit: '100',
      page: '1',
    };
    return result;
  }, []);

  const onSearchClick = useCallback(
    (data) => {
      const { fromDate, toDate, categorySearchOptions } = data;
      getRefundCompletedList(
        dispatch,
        listParams(fromDate, toDate, categorySearchOptions)
      );
    },
    [listParams]
  );

  // Todo: APi return 값에 맞게 coulms 변경
  const columns = useMemo(
    () => [
      columnHelper.accessor('No', {
        id: 'No',
        cell: (info) => parseInt(info.row.id) + 1,
        header: () => <span>No</span>,
      }),
      columnHelper.accessor('serviceTypeDesc', {
        id: 'serviceTypeDesc',
        cell: (info) => info.getValue(),
        header: () => <span>정산구분</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => info.getValue(),
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => info.getValue(),
        header: () => <span>발송계획번호</span>,
      }),
      columnHelper.accessor('paymentDate', {
        id: 'paymentDate',
        cell: (info) => info.getValue(),
        header: () => <span>결제 일</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => info.getValue(),
        header: () => <span>구독회차</span>,
      }),
      columnHelper.accessor('promotionType', {
        id: 'promotionType',
        cell: (info) => info.getValue(),
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => info.getValue(),
        header: () => <span>제휴사명</span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => info.getValue(),
        header: () => <span>상품 아이디</span>,
      }),
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => info.getValue(),
        header: () => <span>상품명</span>,
      }),
      {
        id: 'refundCategory',
        header: () => {
          return '환불 상태';
        },
        cell: ({ row }) => {
          return `${row.original.refundStatus} ${row.original.refundCategoryDesc}`;
        },
      },
    ],
    []
  );

  return (
    <>
      <h2 className={cn('pageTitle')}>환불 현황</h2>
      <FormProvider {...searchForms}>
        <RefundRetrieveSearchArea
          categorySearchOptions={categorySearchOptions}
          onSearchClick={onSearchClick}
        />
      </FormProvider>
      <TableV8ManualPage
        columns={columns}
        data={refundCompletedList}
        csvTitle='내보내기'
      />
    </>
  );
};

export default RefundRetrieve;
