import { memo } from 'react';
import css from './Checkbox.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Checkbox
 * @param {string} id Checkbox 의 고유 id.
 * @param {boolean} noLabel Checkbox 라벨 존재 여부. (padding 값 조정)
 * @param {string} children Checkbox 의 자식 요소.
 * @description 공통적으로 사용하는 Checkbox 컴포넌트.
 */

const Checkbox = ({ id, noLabel, children, className, ...rest }) => {
  return (
    <div className={cn('checkbox', { noLabel }, className)}>
      <input id={id} type='checkbox' {...rest} />
      <label htmlFor={id} className={css.label}>
        <span>{children}</span>
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  /** Checkbox 라벨 여부 */
  noLabel: false,
};

export default memo(Checkbox);
