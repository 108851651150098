import classNames from 'classnames/bind';
import React, {
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import css from './DeliveryResult.module.scss';
import {
  Button,
  Calendar,
  PopUp,
  TableV8,
  TextButton,
  VerticalTable,
  VerticalTableContainer,
} from '@components';
import { openModalAlert } from '@feature/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import DeliveryAlarmListTable from './modules/DeliveryAlarmListTable';
import {
  alarmMessage,
  alarmSearchOptions,
  mockData,
  targetSearchOptions,
} from './Constants';
import { dateToStr } from '@utils';
import { getRetrieveDeliveryTalkdreamResultListAPI } from 'redux/action/deliveryAction';
import { matchesCondition } from '@components/Search/utils/searchValidationFunctions';
import DeliveryAlarmSearchArea from './modules/DeliveryAlarmSearchArea';
import { createColumnHelper } from '@tanstack/react-table';
import AlarmRequestList from '@views/DeliveryManagement/DeliveryResult/AlarmRequestList/AlarmRequestList';
import HFInput from '@components/_ReactHookForm/HFInput';
import { handlePreventRegex, convertDateToString2 } from '@utils';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { useSearchParams } from 'react-router-dom';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const TODAY = new Date();

const AlarmModifiedInterface = {
  receiverName: '',
  contractNum: '',
  phoneNo0: '010',
  phoneNo1: '',
  phoneNo2: '',
  modifiedPhoneNo0: '',
  modifiedPhoneNo1: '',
  modifiedPhoneNo2: '',
};

const tableData = [
  {
    messageTarget: 'CUSTOMER',
    requestDatetime: '2024-01-11',
    vendorKey: 'VENDOR.00005',
    failedCount: 20,
    vendorName: '더반찬',
    category: 'COUPON_ISSUE',
    totalCount: 43,
    messageTargetDesc: '고객',
    categoryDesc: '쿠폰발행완료',
    content:
      '[LG전자 제휴서비스 쿠폰 도착 안내]\n \n 안녕하세요. #{항목1} 고객님, LG전자입니다.\n 제휴서비스 쿠폰이 도착했습니다.\n 해당 쿠폰은 #{항목5} 앱에서 등록 후 사용이 가능합니다. \n \n [쿠폰 안내]\n ● 쿠폰번호 : #{항목7}\n ● 쿠폰기간 : #{항목8} ~ #{항목9}\n ● 서비스명 : #{항목2}\n \n ※ 이 메시지는 고객님의 동의에 의해 지급된 쿠폰 안내 메시지입니다.\n ※ 유효기간 내 사용하지 않은 쿠폰은 소멸됩니다.\n ※ 사용쿠폰은 결제일 다음 날 지급됩니다.\n ※ 쿠폰은 현금으로 교환이 되지 않습니다.\n ※ 더 자세한 내용은 구독 상세 페이지를 참고해 주세요.\n \n LG전자 : https://www.lge.co.kr\n \n 쿠폰 문의 : LG전자 고객센터 1544-7777 (유료)\n 서비스 이용 문의: #{항목5} 고객센터 #{항목6} (유료)',
  },
  {
    messageTarget: 'CUSTOMER',
    requestDatetime: '2024-01-23',
    vendorKey: 'VENDOR.00004',
    failedCount: 10,
    vendorName: '대리주부',
    category: 'test',
    totalCount: 43,
    messageTargetDesc: '고객',
    categoryDesc: '쿠폰발행완료',
    content:
      '[LG전자 제휴서비스 쿠폰 도착 안내]\n \n 안녕하세요. #{항목1} 고객님, LG전자입니다.\n 제휴서비스 쿠폰이 도착했습니다.\n 해당 쿠폰은 #{항목5} 앱에서 등록 후 사용이 가능합니다. \n \n [쿠폰 안내]\n ● 쿠폰번호 : #{항목7}\n ● 쿠폰기간 : #{항목8} ~ #{항목9}\n ● 서비스명 : #{항목2}\n \n ※ 이 메시지는 고객님의 동의에 의해 지급된 쿠폰 안내 메시지입니다.\n ※ 유효기간 내 사용하지 않은 쿠폰은 소멸됩니다.\n ※ 사용쿠폰은 결제일 다음 날 지급됩니다.\n ※ 쿠폰은 현금으로 교환이 되지 않습니다.\n ※ 더 자세한 내용은 구독 상세 페이지를 참고해 주세요.\n \n LG전자 : https://www.lge.co.kr\n \n 쿠폰 문의 : LG전자 고객센터 1544-7777 (유료)\n 서비스 이용 문의: #{항목5} 고객센터 #{항목6} (유료)',
  },
];

const DeliveryResult = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const vendorKey = searchParams.get('vendorKey');
  const messageTarget = searchParams.get('messageTarget');
  const category = searchParams.get('category');

  const searchForms = useForm();
  const { register, handleSubmit } = useForm({
    defaultValues: AlarmModifiedInterface,
    mode: 'onChange',
  });

  const vendorList = useSelector((state) => state.common.vendorList);
  const deliveryIssueTargetList = useSelector(
    (state) => state.delivery.getRetrieveDreamTalkdreamResultList
  );

  const deliveryAlarmSearchRef = useRef({
    getCategorySelectWatch: () => {},
  });

  const deliveryAlarmCategoryValue =
    deliveryAlarmSearchRef.current.getCategorySelectWatch();

  const [isOpenMessagePopup, setIsOpenMessagePopup] = useState(false);
  const [currentMessageContents, setCurrentMessageContents] = useState(null);

  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenChangeInfo, setIsOpenChangeInfo] = useState(false);
  const [tableOriginalData, setTableOriginalData] = useState({
    category: '',
    requestDatetime: '',
    vendorKey: '',
  });

  const [startDate, setStartDate] = useState(TODAY);
  const [endDate, setEndDate] = useState(TODAY);

  const minDate = useMemo(() => {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);
    return minDate;
  }, []);

  const handleStartDate = useCallback(
    (date) => {
      if (date <= endDate) {
        setStartDate(date);
      } else {
        dispatch(
          openModalAlert({
            message: '시작 날짜는 종료 날짜보다 이전이어야 합니다.',
          })
        );
      }
    },
    [endDate]
  );

  const handleEndDate = useCallback(
    (date) => {
      if (date >= startDate) {
        setEndDate(date);
      } else {
        dispatch(
          openModalAlert({
            message: '종료 날짜는 시작 날짜보다 이후이어야 합니다.',
          })
        );
      }
    },
    [startDate]
  );

  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '100');
    setSearchParams(searchParams);
  }, []);

  const handleMessagePopup = useCallback((contents) => {
    setIsOpenMessagePopup(true);
    setCurrentMessageContents(contents);
  }, []);

  useEffect(() => {
    const tempParams = {
      fromDate: convertDateToString2(startDate),
      toDate: convertDateToString2(endDate),
      messageTarget: messageTarget ?? '',
      vendorKey: vendorKey ?? '',
      category: deliveryAlarmCategoryValue,
      limit: limit ?? '100',
      page: page ?? '1',
    };
    dispatch(getRetrieveDeliveryTalkdreamResultListAPI(tempParams));
  }, [
    dispatch,
    startDate,
    endDate,
    messageTarget,
    vendorKey,
    limit,
    page,
    deliveryAlarmCategoryValue,
  ]);

  const onSearchClick = useCallback(
    (data) => {
      const {
        targetSearchCondition,
        vendorSearchCondition,
        alarmSearchCondition,
      } = data;

      const tempParams = {
        fromDate: convertDateToString2(startDate),
        toDate: convertDateToString2(endDate),
        messageTarget:
          targetSearchCondition === 'ALL' ? '' : targetSearchCondition,
        vendorKey: vendorSearchCondition ?? '',
        category: alarmSearchCondition,
        limit: limit ?? '100',
        page: page ?? '1',
      };

      dispatch(getRetrieveDeliveryTalkdreamResultListAPI(tempParams));
    },
    [startDate, endDate, limit, page, dispatch]
  );

  const onAccountSubmit = useCallback((data) => {
    const { receiverName, contractNum, phoneNo0, phoneNo1, phoneNo2 } = data;

    console.log('### data: ', data);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('messageTarget', {
        id: 'messageTarget',
        header: () => <span>수신</span>,
        cell: (info) => {
          switch (info.getValue()) {
            case 'CUSTOMER':
              return <span>고객</span>;
            case 'VENDOR':
              return <span>기타</span>;
            case 'LGE':
              return <span>LGE</span>;
            default:
              return info.getValue();
          }
        },
        size: 60,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('categoryDesc', {
        id: 'categoryDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림 구분</span>,
      }),
      columnHelper.accessor('messageType', {
        id: 'messageType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>메세지 유형</span>,
      }),
      columnHelper.accessor('제목', {
        id: '제목',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제목</span>,
        size: 300,
      }),
      columnHelper.accessor('content', {
        id: 'content',
        cell: (info) => {
          return (
            // <div className={css.txtL}>
            //   {info.renderValue() && alarmMessage(info.renderValue())}
            // </div>
            <div
              className={css.ellipsis}
              onClick={() => handleMessagePopup(info.getValue())}
            >
              {info.getValue()}
            </div>
          );
        },
        header: () => <span style={{ width: '300px' }}>알림톡 내용</span>,
        size: 600,
      }),
      columnHelper.accessor('알림톡 발송 결과', {
        id: '알림톡 발송 결과',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림톡 발송 결과</span>,
      }),
      columnHelper.accessor('LMS 발송 결과', {
        id: 'LMS 발송 결과',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>LMS 발송 결과</span>,
      }),
      columnHelper.accessor('totalCount', {
        id: 'totalCount',
        cell: (info) => {
          const {
            failedCount,
            totalCount,
            waitingCount,
            requestDatetime,
            category,
            vendorKey,
          } = info?.row?.original || {};
          return (
            <TextButton
              styleType={'btn_txt04'}
              onClick={() => {
                setTableOriginalData((prevState) => ({
                  ...prevState,
                  requestDatetime: requestDatetime,
                  category: category,
                  vendorKey: vendorKey,
                }));
                setIsOpenDetail(true);
              }}
            >
              <span className={cn('color-default')}>총 {totalCount}건 / </span>
              {waitingCount > 0 && (
                <span className={cn('color-pink')}>
                  {waitingCount}건 대기 /{' '}
                </span>
              )}
              <span className={cn('color-pink')}>{failedCount}건 실패</span>
            </TextButton>
          );
        },
        header: () => <span>최종 결과</span>,
        size: 100,
      }),
    ],
    [handleMessagePopup]
  );

  const handleOpenChangeInfo = useCallback(() => {
    setIsOpenChangeInfo(!isOpenChangeInfo);
  }, [isOpenChangeInfo]);

  const totalSuccessedLength = useMemo(() => {
    return (
      deliveryIssueTargetList &&
      deliveryIssueTargetList
        .map((item) => item.totalCount - item.failedCount)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0)
    );
  }, [deliveryIssueTargetList]);

  const vendorSearchOptions = useMemo(() => {
    let options = {
      guide: '제휴사명을 선택해주세요.',
      list: [],
    };
    if (vendorList.length > 0) {
      options.list = [
        ...options.list,
        ...vendorList
          .filter((item) => item.vendorKey !== 'VENDOR.00001')
          .map((item) => {
            return {
              id: item.vendorKey,
              title: item.companyName,
            };
          }),
      ];
    }
    return options;
  }, [vendorList]);

  return (
    <div>
      <h3 className={cn('pageTitle')}>{'배송 알림'}</h3>
      <div className={css.searchOptionArea}>
        <div className={css.innerBox}>
          <span className={css.innerTitle}>{'조회 기간'}</span>
          <Calendar
            className={cn('calendar')}
            hasTitle={false}
            hasPeriodInput={true}
            periodInputType={'Type1'}
            startDate={startDate}
            setStartDate={setStartDate}
            handleStartDate={handleStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            handleEndDate={handleEndDate}
            minDate={minDate}
            defaultDateRange={'oneMonthAgo'}
          />
        </div>
      </div>
      <DeliveryAlarmListTable startDate={startDate} endDate={endDate} />
      <FormProvider {...searchForms}>
        <DeliveryAlarmSearchArea
          ref={deliveryAlarmSearchRef}
          targetSearchOptions={targetSearchOptions}
          vendorSearchOptions={vendorSearchOptions}
          alarmSearchOptions={alarmSearchOptions}
          onSearchClick={onSearchClick}
          totalLength={totalSuccessedLength}
        />
      </FormProvider>
      <TableV8ManualPage
        columns={columns}
        data={deliveryIssueTargetList}
        // data={tableData}
        page={page}
        limit={limit ?? undefined}
        hasPageSize={false}
        hasCsvDownload={false}
        handlePageCallback={handlePageCallback}
      />
      <PopUp
        title={'알림 발송 결과 상세'}
        isOpenPopUp={isOpenDetail}
        onRequestClose={() => setIsOpenDetail(false)}
        closeButton={true}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                subType={'type03'}
                onClick={() => setIsOpenDetail(false)}
                fullWidth
              >
                닫기
              </Button>
            </div>
          </div>,
        ]}
      >
        <AlarmRequestList resultDetailListParam={tableOriginalData} />
      </PopUp>
      <PopUp
        title={'알림 정보 변경'}
        description={
          ' 알림 대상이 고객일 경우 개인 정보는 변경되지 않고 해당 알림 재발송 시 1회 적용됩니다.'
        }
        // TODO: 테스트 중
        isOpenPopUp={isOpenChangeInfo}
        // isOpenPopUp={true}
        onRequestClose={handleOpenChangeInfo}
        closeButton
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key={'cancelInfo'}
                  subType={'type03'}
                  onClick={handleOpenChangeInfo}
                  fullWidth
                >
                  닫기
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key={'saveInfo'}
                  subType={'type02'}
                  // onClick={handleSaveChangeInfo}
                  onClick={handleSubmit(onAccountSubmit)}
                  fullWidth
                >
                  저장
                </Button>
              </div>
            </div>,
          ]}
          isCenter
        >
          <VerticalTable title={'수신자 명'}>
            <HFInput
              type={'text'}
              name={'receiverName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'계약번호'}>
            <HFInput
              type={'text'}
              name={'contractNum'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'현재 휴대폰 번호'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <Fragment key={index}>
                  <HFInput
                    type={'text'}
                    name={`phoneNo${index}`}
                    register={register}
                    readOnly
                  />
                  {index < 2 && <span>-</span>}
                </Fragment>
              ))}
            </div>
          </VerticalTable>
          <VerticalTable title={'변경 휴대폰 번호'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <Fragment key={index}>
                  <HFInput
                    type={'text'}
                    name={`modifiedPhoneNo${index}`}
                    register={register}
                    maxLength={index === 0 ? 3 : 4}
                    onKeyDown={(e) => handlePreventRegex(e, /^[0-9]*$/)}
                  />
                  {index < 2 && <span>-</span>}
                </Fragment>
              ))}
            </div>
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUp
        title={'알림톡 확인'}
        isOpenPopUp={isOpenMessagePopup}
        onRequestClose={() => setIsOpenMessagePopup(false)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='stay'
                subType={'type02'}
                onClick={() => {
                  setIsOpenMessagePopup(false);
                }}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={css.messagePreview}>{currentMessageContents}</div>
      </PopUp>
    </div>
  );
};

export default DeliveryResult;
