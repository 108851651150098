import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // 상품관리
  contents: {},
  modelSuffixList: {},
  // form: {
  //   authority: '',
  //   vendorKey: '',
  //   searchCategory: 'CR',
  //   searchPeriodType: 'MANUAL',
  //   preDefinedType: '',
  //   fromDate: formatDateTime(new Date(DATE_NOW)).split(' ')[0],
  //   toDate: formatDateTime(
  //     new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30)
  //   ).split(' ')[0],
  //   searchType: 'FOCFLAG',
  //   detailCondition: '',
  // },
  modelSuffixSearchCondition: {
    searchType: '',
    detailCondition: '',
  },

  // 상품 상세정보
  detailContent: {},
  dashboardData: {},
};

const product = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getModelSuffix: (state, action) => {
      state.modelSuffixList = action.payload;
    },
    setProduct: (state, action) => {
      state.contents = action.payload;
    },
    getProductDeatil: (state, action) => {
      state.detailContent = action.payload;
    },
    // setProductListForm: (state, action) => {
    //   state.form = { ...state.form, ...action.payload };
    // },
    // resetProductListDetailCondition: (state) => {
    //   state.form.detailCondition = '';
    // },
    setModelSuffixSearchCondition: (state, action) => {
      const key = action.payload.key;
      state.modelSuffixSearchCondition[key] = action.payload.value;
    },
    resetModelSuffixSearchCondition: (state) => {
      state.modelSuffixSearchCondition.detailCondition = '';
    },
    getDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});

export const {
  setProduct,
  getProductDeatil,
  getModelSuffix,
  // setProductListForm,
  // resetProductListDetailCondition,
  setModelSuffixSearchCondition,
  resetModelSuffixSearchCondition,
  getDashboardData,
} = product.actions;

export default product.reducer;
