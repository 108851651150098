import css from './HFTextarea.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(css);

/**
 * @category components
 * @namespace HFTextarea
 * @param {function} register useForm의 return 값으로 Textarea를 등록하기 위한 함수
 * @param {string} name handleSubmit에서 사용되는 고유 key 값
 * @param {bool} required 값 선택 필수 유무
 * @param {bool} disabled disabled
 * @param {number} maxLength maxLength
 * @description React-hook-form 사용 시 사용되는 Textarea
 */
const HFTextarea = ({
  name,
  register,
  required,
  disabled,
  maxLength,
  ...rest
}) => {
  return (
    <textarea
      className={cn('textArea')}
      {...register(name, { required, disabled, maxLength })}
      {...rest}
    />
  );
};

export default HFTextarea;
