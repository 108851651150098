import { TabMenu, AuthorityTable } from '@components/index';
import VendorAccountTable from 'template/Authority/VendorAccountTable';
import LGAccountTable from 'template/Authority/LGAccountTable';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import css from './AuthorityManage.module.scss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getVendorAuthorityList,
  getInternalAuthorityList,
  getNewVendorList,
  getVendorMasterList,
} from 'redux/action/AuthorityAction';
import { MakeTwoDigitDate } from '@utils';
import ManagementCompanyInfo from 'template/Authority/ManagementCompanyInfo';
/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const AuthorityManage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('tab1');

  const queryParams = new URLSearchParams(location.search);
  const approvalStatus = queryParams.get('approvalStatusAccount');

  const selectApprovalStatus = approvalStatus
    ? { id: approvalStatus, title: '승인대기' }
    : undefined;

  const tabData = [
    {
      id: 'tab1',
      title: '계정 관리(제휴사)',
      content: (
        <VendorAccountTable selectApprovalStatus={selectApprovalStatus} />
      ),
    },
    {
      id: 'tab2',
      title: '계정 관리 (LGE)',
      content: <LGAccountTable selectApprovalStatus={selectApprovalStatus} />,
    },
    {
      id: 'tab3',
      title: '사업자 정보 관리',
      content: (
        <ManagementCompanyInfo selectApprovalStatus={selectApprovalStatus} />
      ),
    },
    { id: 'tab4', title: '계정 권한 관리', content: <AuthorityTable /> },
  ];

  useEffect(() => {
    // tabIndex: 기존 tabIndex
    if (location?.state?.tabIndex) {
      setActiveTab(location?.state?.tabIndex);
      // tab: 대시보드 - navigate를 통해 넘겨져 오는 tabIndex값
    } else if (location?.state?.tab) {
      setActiveTab(location?.state?.tab);
    }
  }, [location]);

  useEffect(() => {
    const TODAY = new Date();
    const year = TODAY.getFullYear();
    const month = MakeTwoDigitDate(TODAY.getMonth() + 1);
    const date = MakeTwoDigitDate(TODAY.getDate() + 1);
    const toDate = `${year}${month}${date}`;
    const authorityParams = {
      fromDate: '20230801',
      toDate,
      approvalStatus: approvalStatus ?? '',
      searchType: '',
      detailCondition: '',
      // FIXME: limit, page가 mandatory라서 임의의 값을 넣어줘야 함 => 수정이 필요할 수 있음
      limit: '100',
      page: '1',
    };
    getNewVendorList(dispatch);
    getVendorAuthorityList(dispatch, authorityParams);
    getInternalAuthorityList(dispatch, authorityParams);
    getVendorMasterList(dispatch, authorityParams);
  }, [approvalStatus]);

  return (
    <>
      <h2 className={css.pageTitle}>권한관리</h2>
      <div className={cn('tabMenu', 'tabMenu01', 'type01', 'mt20')}>
        <div className={css.list}>
          <TabMenu
            tabData={tabData}
            type02={true}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
      </div>
    </>
  );
};

export default AuthorityManage;
