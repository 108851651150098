import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import css from './CouponAlarmList.module.scss';
import pusblished from 'assets/img/img_flow_coupon_pusblished_notification.svg';
import AlarmListTable from '../module/AlarmListTable';
import { ROUTER_PATH } from '@router/routerPath';
import {
  BreadCrumbs,
  TabMenu,
  Calendar,
  SelectBox,
  RadioButton,
  Button,
  TableV8,
  PopUp,
} from '@components/index';
import { useRadio } from '@hooks';
import { createColumnHelper } from '@tanstack/react-table';
import TableCheckbox from '@components/Table/TableCheckbox';
import AlarmFailList from './module/AlarmFailList';

const columnHelper = createColumnHelper();

const cn = classNames.bind(css);

const BREADCRUMBS = [
  { title: 'Home', path: ROUTER_PATH.HOME },
  { title: '쿠폰관리' },
  { title: '쿠폰 생성 등록' },
];

const tabData = [
  { id: 'tab1', title: '전체', content: '' },
  { id: 'tab2', title: '쿠폰 발행 요청', content: '' },
  { id: 'tab3', title: '쿠폰 발행 완료', content: '' },
  { id: 'tab4', title: '폐기 알림(한 달전)', content: '' },
  { id: 'tab5', title: '폐기 알림(15일전)', content: '' },
  { id: 'tab6', title: '폐기 알림(1일전)', content: '' },
  { id: 'tab7', title: '난수 번호 추가', content: '' },
  { id: 'tab8', title: '계약 취소 안내', content: '' },
];

const SELECT_COMPANY_DATA = [
  { id: 0, title: 'All' },
  { id: 1, title: 'second' },
  { id: 2, title: 'third' },
];

const SELECT_MESSAGE_DATA = [
  { id: 0, title: '알림톡' },
  { id: 1, title: 'second' },
  { id: 2, title: 'third' },
];

const SELECT_SHIPPING_TYPE_DATA = [
  { id: 0, title: 'All' },
  { id: 1, title: 'second' },
  { id: 2, title: 'third' },
];

const RADIO_MOCK_DATA = [
  { id: 1, title: '전체' },
  { id: 2, title: '성공' },
  { id: 3, title: '실패' },
];

const tableData = [
  {
    Code: 'mesg_02',
    알림구분: '당월 미결제',
    메세지유형: '알림톡',
    수신자구분: '제휴사_대리주부',
    정산: '무상',
    메시지: '내용',
    총발송수: '100',
    발송성공: '99',
    발송실패: '1',
    발송일시: '2023-08-01 13:00',
    발송상태: '발송완료',
    발송구분: '신규',
    알림등록일: '2023-07-29',
  },
  {
    Code: 'mesg_02',
    알림구분: '당월 미결제',
    메세지유형: '알림톡',
    수신자구분: '제휴사_대리주부',
    정산: '무상',
    메시지: '내용',
    총발송수: '100',
    발송성공: '99',
    발송실패: '1',
    발송일시: '2023-08-01 13:00',
    발송상태: '발송완료',
    발송구분: '신규',
    알림등록일: '2023-07-29',
  },
];

const CouponAlarmList = () => {
  const [activeTab, setActiveTab] = useState('tab1');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectRadioValue, handleClickRadio] = useRadio('전체');
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);

  const columnData = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('Code', {
        id: 'Code',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>Code</span>,
      }),
      columnHelper.accessor('알림구분', {
        id: '알림구분',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림구분</span>,
      }),
      columnHelper.accessor('메세지유형', {
        id: '메세지유형',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>메세지유형</span>,
      }),
      columnHelper.accessor('수신자구분', {
        id: '수신자구분',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신자구분</span>,
      }),
      columnHelper.accessor('정산', {
        id: '정산',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('정산', {
        id: '정산',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('메시지', {
        id: '메시지',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>메시지</span>,
      }),
      columnHelper.accessor('총발송수', {
        id: '총발송수',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>총발송수</span>,
      }),
      columnHelper.accessor('발송성공', {
        id: '발송성공',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송성공</span>,
      }),
      columnHelper.accessor('발송실패', {
        id: '발송실패',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송실패</span>,
      }),
      columnHelper.accessor('발송일시', {
        id: '발송일시',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송일시</span>,
      }),
      columnHelper.accessor('발송상태', {
        id: '발송상태',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송상태</span>,
      }),
      columnHelper.accessor('발송구분', {
        id: '발송구분',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송구분</span>,
      }),
      columnHelper.accessor('알림등록일', {
        id: '알림등록일',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림등록일</span>,
      }),
    ],
    []
  );

  // 날짜 선택 이벤트 핸들러
  const handleStartDate = (date) => {
    if (date <= endDate) {
      setStartDate(date);
    } else {
      alert('시작 날짜는 종료 날짜보다 이전이어야 합니다.');
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      setEndDate(date);
    } else {
      alert('종료 날짜는 시작 날짜보다 이후이어야 합니다.');
    }
  };

  const handleSecond = () => {
    alert('do someting2');
  };

  return (
    <>
      <BreadCrumbs menuList={BREADCRUMBS} />
      <h2 className={css.pageTitle}>쿠폰 생성 등록</h2>
      <div className={css.flowChart}>
        <h3 className={css.title}>쿠폰 알림 결과/후 처리 단계 Flow</h3>
        <div className={css.img}>
          <img src={pusblished} alt='' />
        </div>
      </div>
      <AlarmListTable />
      {/* TabList 공통으로 쓰는거면 AlarmListTable로 이동해야함*/}
      <div className={cn('tabMenu', 'tabMenu01', 'type02', 'mt32')}>
        <TabMenu
          tabData={tabData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={css.flex}>
          <div className={css.innerBox}>
            <Calendar
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
            />
          </div>
          <div className={css.innerBox}>
            <strong className={css.title}>제휴사</strong>
            <div className={cn('selectbox', 'type01', 'sizeS')}>
              <SelectBox optionList={SELECT_COMPANY_DATA} fullWidth={true} />
            </div>
          </div>
        </div>
        <div className={css.flex}>
          <div className={css.innerBox}>
            <strong className={css.title}>발송결과</strong>
            {RADIO_MOCK_DATA &&
              RADIO_MOCK_DATA?.map((item) => (
                <RadioButton
                  key={item.id}
                  id={item.id}
                  value={selectRadioValue}
                  checked={selectRadioValue === item.title}
                  onClick={() => handleClickRadio(item.title)}
                  disabled={item.disabled}
                >
                  {item.title}
                </RadioButton>
              ))}
            <div className={cn('innerBox', 'marginL4')}>
              <strong className={css.title}>메세지 유형</strong>
              <div className={cn('selectbox', 'type01', 'sizeS')}>
                <SelectBox optionList={SELECT_MESSAGE_DATA} fullWidth={true} />
              </div>
            </div>
            <div className={cn('innerBox', 'marginL4')}>
              <strong className={css.title}>발송 구분</strong>
              <div className={cn('selectbox', 'type01', 'sizeS')}>
                <SelectBox
                  optionList={SELECT_SHIPPING_TYPE_DATA}
                  fullWidth={true}
                />
              </div>
            </div>
            <div className={css.innerBox}>
              <Button className={cn('btn', 'btn01', 'type03')}>검색</Button>
            </div>
          </div>
        </div>
      </div>
      {/* TabList끝 */}

      <TableV8
        columns={columnData}
        data={tableData}
        hasCsvDownload={false}
        rightControls={[
          <div className={css.box_right}>
            <Button type='button' className={cn('btn', 'btn03', 'type02')}>
              실패 재 발송
            </Button>
            <Button
              onClick={() => setIsOpenPopUp(true)}
              type='button'
              className={cn('btn', 'btn03', 'type01')}
            >
              실패 리스트 전체 보기
            </Button>
          </div>,
        ]}
      />

      <PopUp
        size='mideum'
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
        button={[
          <div className={css.btn_container4}>
            <Button
              className={cn('btn', 'btn01', 'type03')}
              key='firstButton'
              onClick={() => setIsOpenPopUp(false)}
            >
              닫기
            </Button>
            <Button
              className={cn('btn', 'btn01', 'type02')}
              key='secondButton'
              onClick={handleSecond}
            >
              재발송
            </Button>
          </div>,
        ]}
        isWideSize
      >
        <AlarmFailList />
      </PopUp>
    </>
  );
};

export default CouponAlarmList;
