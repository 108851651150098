import { memo, useCallback, useMemo } from 'react';
import css from './DetailBoxLarge.module.scss';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COUPON_SUB_PATH, DELIVERY_SUB_PATH } from '@router/routerPath';
import { convertDateToString2 } from 'utils/utils';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const PAGE_LIMIT = `page=1&limit=100`;

/**
 * @category components
 * @namespace DetailBoxLarge
 * @param {string} type DetailBoxLarge의 타입 (delivery | coupon)
 * @description Home 화면에서 사용하는 DetailBoxLarge 컴포넌트 (배송 관리, 쿠폰 관리).
 */

const formatNumber = (num) => {
  // 숫자를 반올림하여 다음 100의 배수로 만든다.
  if (num >= 1000) {
    const roundedNum = Math.round(num / 100) * 100;

    // 숫자를 문자열로 변환하고 마지막 자리 수를 제거.
    const formattedNum = (roundedNum / 100).toFixed(1);
    return formattedNum + '백';
  } else return num;
};

const DELIVERY_WAITING = 0;
const DELIVERY_PROGRESSING = 0;
const DELIVERY_AFTER_SEVEN = 0;

const DELIVERY_TOTAL_WAITING =
  DELIVERY_WAITING + DELIVERY_PROGRESSING + DELIVERY_AFTER_SEVEN;

// TODO: 실제 데이터(배송/쿠폰 별) 연동했을 때 구조 변경 필요 (231121)
const DetailBoxLarge = ({ type }) => {
  const authority = useSelector((state) => state.user.userData.authority);
  const vendorKey = useSelector((state) => state.user.userData.vendorKey);
  const selectedVendorKey = useSelector(
    (state) => state.dashBoard.selectedVendorKey
  );
  const navigate = useNavigate();
  const dashboardVendorCouponInfo = useSelector(
    (state) => state.dashBoard.dashboardVendorCouponInfo
  );
  const dashboardVendorDeliveryInfo = useSelector(
    (state) => state.dashBoard.dashboardVendorDeliveryInfo
  );
  const isLEVEL_2 = authority === 'LEVEL_2';
  const isLEVEL_3 = authority === 'LEVEL_3';
  const handleNavigate = useCallback((path, state) => {
    if (state) {
      navigate(path, { state: state });
    } else {
      navigate(path);
    }
  }, []);

  // 쿠폰 관리

  const dailyCouponIssuanceTotalCount = useMemo(() => {
    return (
      dashboardVendorCouponInfo.couponFocCnt +
      dashboardVendorCouponInfo.couponNfocCnt
    );
  }, [dashboardVendorCouponInfo]);

  const couponPendingTotalCount = useMemo(() => {
    return (
      dashboardVendorCouponInfo.couponCreationRequiredCnt +
      dashboardVendorCouponInfo.couponUseCompleteRequestedCnt +
      dashboardVendorCouponInfo.couponTalkdreamSendRequiredCnt
    );
  }, [dashboardVendorCouponInfo]);

  // 배송 관리

  const deliveryPendingTotalCount = useMemo(() => {
    return (
      dashboardVendorDeliveryInfo.statusD001Cnt +
      dashboardVendorDeliveryInfo.statusD002Cnt +
      dashboardVendorDeliveryInfo.statusD003Cnt +
      dashboardVendorDeliveryInfo.statusD004Cnt
    );
  }, [dashboardVendorDeliveryInfo]);

  return (
    <div className={cn(css.detailBoxLarge)}>
      <strong className={css.title_01}>
        {type === 'delivery' ? '배송 관리' : '쿠폰 관리'}
      </strong>
      <div className={css.status}>
        <strong className={css.title_02}>
          {type === 'delivery' ? '일자별 주문' : '일자별 쿠폰 발행'}
        </strong>

        <span
          className={css.new}
          onClick={() =>
            type === 'delivery'
              ? handleNavigate(
                  `/${
                    DELIVERY_SUB_PATH.ORDER
                  }?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&category=CAT03&fromDate=${convertDateToString2(
                    new Date()
                  )}&toDate=${convertDateToString2(new Date())}`
                )
              : handleNavigate(`/${COUPON_SUB_PATH.LIST}`)
          }
        >
          신규 계약
          <em className={cn('color-pink')}>
            {type === 'delivery' ? (
              <span>{dashboardVendorDeliveryInfo.newOrderCnt}</span>
            ) : (
              <span>{dashboardVendorCouponInfo.csmsNewContractCnt ?? 0}</span>
            )}
          </em>
          건
        </span>
        <div className={css.detail}>
          <strong className={css.case}>
            <span className={css.num}>
              {type === 'delivery' ? (
                0
              ) : (
                <span
                // onClick={() => {
                //   handleNavigate(`/${COUPON_SUB_PATH.LIST}`);
                // }}
                >
                  {formatNumber(dailyCouponIssuanceTotalCount ?? 0)}
                </span>
              )}
            </span>
            건
          </strong>
          <ul className={css.value}>
            {type === 'delivery' ? (
              <>
                {/*FIXME: 배송 현황 화면에서 필터링 필요한지 확인 필요 */}
                <li>
                  무상 주문
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () => {
                            handleNavigate(
                              `/${
                                DELIVERY_SUB_PATH.ORDER
                              }?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&category=CAT01&fromDate=${convertDateToString2(
                                new Date()
                              )}&toDate=${convertDateToString2(new Date())}`
                            );
                          }
                        : null
                    }
                  >
                    ({dashboardVendorDeliveryInfo.focOrderCnt ?? 0})
                  </span>
                </li>
                <li>
                  유상 주문
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () => {
                            handleNavigate(
                              `/${
                                DELIVERY_SUB_PATH.ORDER
                              }?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&category=CAT02&fromDate=${convertDateToString2(
                                new Date()
                              )}&toDate=${convertDateToString2(new Date())}`
                            );
                          }
                        : null
                    }
                  >
                    ({dashboardVendorDeliveryInfo.nfocOrderCnt ?? 0})
                  </span>
                </li>
              </>
            ) : (
              <>
                <li>
                  무상 주문
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () => {
                            handleNavigate(
                              `/${
                                COUPON_SUB_PATH.LIST
                              }?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&searchType=FOC_STATUS&detailCondition=FOC&fromDate=${convertDateToString2(
                                new Date()
                              )}&toDate=${convertDateToString2(new Date())}`
                            );
                          }
                        : null
                    }
                  >
                    ({dashboardVendorCouponInfo.couponFocCnt ?? 0})
                  </span>
                </li>
                <li>
                  유상 주문
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () => {
                            handleNavigate(
                              `/${
                                COUPON_SUB_PATH.LIST
                              }?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&searchType=FOC_STATUS&detailCondition=NFOC&fromDate=${convertDateToString2(
                                new Date()
                              )}&toDate=${convertDateToString2(new Date())}`
                            );
                          }
                        : null
                    }
                  >
                    ({dashboardVendorCouponInfo.couponNfocCnt ?? 0})
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className={css.status}>
        <strong className={css.title_02}>
          {type === 'delivery' ? '배송 처리 대기' : '쿠폰 처리 대기'}
        </strong>
        <div className={css.detail}>
          <strong className={css.case}>
            <span
              className={css.num}
              onClick={
                process.env.REACT_APP_MODE === 'LG'
                  ? () => {
                      handleNavigate(`/${DELIVERY_SUB_PATH.ORDER}`);
                    }
                  : undefined
              }
            >
              {type === 'delivery' ? (
                formatNumber(deliveryPendingTotalCount ?? 0)
              ) : (
                <span
                  onClick={
                    process.env.REACT_APP_MODE === 'LG'
                      ? () => {
                          handleNavigate(`/${COUPON_SUB_PATH.LIST}`);
                        }
                      : undefined
                  }
                >
                  {formatNumber(couponPendingTotalCount ?? 0)}
                </span>
              )}
            </span>
            건
          </strong>
          <ul className={css.value}>
            <li>
              <span className={cn('ico_act', 'type01')}></span>
              {type === 'delivery' ? (
                <>
                  신규주문
                  <span
                    className={css.num}
                    onClick={() => {
                      if (process.env.REACT_APP_MODE === 'LG') {
                        handleNavigate(
                          `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&deliveryStatus=D001`
                        );
                      } else if (process.env.REACT_APP_MODE !== 'LG') {
                        handleNavigate(
                          `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${vendorKey}&deliveryStatus=D001`
                        );
                      }
                    }}
                  >
                    ({dashboardVendorDeliveryInfo.statusD001Cnt ?? 0})
                  </span>
                </>
              ) : (
                <>
                  쿠폰 생성 필요
                  <span
                    className={css.num}
                    onClick={() => {
                      if (!isLEVEL_3) {
                        handleNavigate(
                          `/${COUPON_SUB_PATH.REGISTER}?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}`
                        );
                      } else {
                        handleNavigate(
                          `/${COUPON_SUB_PATH.REGISTER}?${PAGE_LIMIT}&vendorKey=${vendorKey}`
                        );
                      }
                    }}
                  >
                    ({dashboardVendorCouponInfo.couponCreationRequiredCnt ?? 0})
                  </span>
                </>
              )}
            </li>
            <li>
              <span className={cn('ico_act', 'type02')}></span>
              {type === 'delivery' ? (
                <>
                  상품 준비중
                  <span
                    className={css.num}
                    onClick={() => {
                      if (!isLEVEL_3) {
                        handleNavigate(
                          `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}&deliveryStatus=D002`
                        );
                      } else {
                        handleNavigate(
                          `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${vendorKey}&deliveryStatus=D002`
                        );
                      }
                    }}
                  >
                    ({dashboardVendorDeliveryInfo.statusD001Cnt ?? 0})
                  </span>
                </>
              ) : (
                <>
                  확정 요청 건
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () => {
                            handleNavigate(
                              `/${COUPON_SUB_PATH.RESULT}?${PAGE_LIMIT}&vendorKey=${selectedVendorKey}`
                            );
                          }
                        : null
                    }
                  >
                    (
                    {dashboardVendorCouponInfo.couponUseCompleteRequestedCnt ??
                      0}
                    )
                  </span>
                </>
              )}
            </li>
            <li>
              <span className={cn('ico_act', 'type03')}></span>
              {type === 'delivery' ? (
                <>
                  상품 준비완료
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={() => {
                      if (!isLEVEL_3) {
                        handleNavigate(
                          `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${vendorKey}&deliveryStatus=D003`
                        );
                      }
                    }}
                  >
                    ({dashboardVendorDeliveryInfo.statusD003Cnt ?? 0})
                  </span>
                </>
              ) : (
                <>
                  발행 알림 대기
                  <span
                    className={!isLEVEL_3 && css.num}
                    onClick={() => {
                      if (!isLEVEL_3) {
                        handleNavigate(
                          `/${COUPON_SUB_PATH.MANAGEMENT}?page=1&limit=100&vendorKey=${selectedVendorKey}`
                        );
                      }
                    }}
                  >
                    (
                    {dashboardVendorCouponInfo.couponTalkdreamSendRequiredCnt ??
                      0}
                    )
                  </span>
                </>
              )}
            </li>
            {type === 'delivery' && (
              <li>
                <span className={cn('ico_act', 'type04')}></span>
                배송중
                <span
                  className={!isLEVEL_3 && css.num}
                  onClick={() => {
                    if (!isLEVEL_3) {
                      handleNavigate(
                        `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${vendorKey}&deliveryStatus=D004`
                      );
                    }
                  }}
                >
                  ({dashboardVendorDeliveryInfo.statusD004Cnt ?? 0})
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

DetailBoxLarge.defaultProps = {
  type: 'coupon',
};

export default memo(DetailBoxLarge);
