import { memo, useMemo, useState, useCallback } from 'react';
import css from './QuickMenu.module.scss';
import classNames from 'classnames/bind';
import logo from '@assets/img/logo.svg';
import ico_top from '@assets/img/ico_top.svg';
import ico_home from '@assets/img/ico_home.svg';
import ico_notice from '@assets/img/ico_notice.svg';
import ico_user2 from '@assets/img/ico_user2.svg';
import ico_more from '@assets/img/ico_more.svg';
import ico_minus from '@assets/img/ico_minus.svg';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH, BASE_URL, USER_SUB_PATH } from '@router/routerPath';
import { PopUp } from '@components/';
import { NoticeList } from 'template/Notice/NoticeList';
import NoticeDetail from 'template/Notice/NoticeDetail';
import NoticeEdit from 'template/Notice/NoticeEdit';
import NoticeRegister from 'template/Notice/NoticeRegister';
import {
  setNoticeDetailPopup,
  setNoticeRegisterPopup,
  setNoticeEditPopup,
  setNoticeListPopup,
} from '@feature/common/commonSlice';
import { resetForm } from '@feature/notice/noticeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNotice } from '@feature/notice/noticeSlice';
import { postIncreaseNoticeReadCount } from 'redux/action/noticeAction';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace QuickMenu
 * @description DashboardLayout에서 사용하는 QuickMenu 컴포넌트.
 */

const QuickMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quickMenuOpen, setQuickMenuOpen] = useState(false);
  const data = useSelector((state) => state.notice.contents);
  const currentNotice = useSelector((state) => state.notice.currentNotice);
  const userId = useSelector((state) => state.user.userData.userId);

  const tableData = useMemo(() => {
    let resultArr = [];
    if (Object.keys(data).length > 0) {
      const filteredDelflag = Object.values(data).filter(
        (data) => data.delFlag === 'N'
      );
      const dataArr = Object.values(filteredDelflag);

      let fixedElements = [];

      for (let i = dataArr.length - 1; i >= 0; i--) {
        if (dataArr[i].noticeFixedFlag === 'Y') {
          fixedElements = fixedElements.concat(dataArr.splice(i, 1)); // 조건에 맞는 요소를 배열에서 제거하고 fixedElements에 추가
        }
      }
      const topFixedElements = fixedElements
        .sort((a, b) => {
          const dateA = new Date(a.createDatetime);
          const dateB = new Date(b.createDatetime);
          return dateB - dateA;
        })
        .splice(0, 3);

      const notTopFixed = [...dataArr, ...fixedElements];

      const sortedRestArr = notTopFixed.sort((a, b) => {
        const dateA = new Date(a.createDatetime);
        const dateB = new Date(b.createDatetime);
        return dateB - dateA;
      });

      const mergedArr = [...topFixedElements, ...sortedRestArr];
      resultArr = mergedArr.map((item, index) => {
        return Object.assign({}, item, {
          no: mergedArr.length - index,
          isTopFixed: topFixedElements.includes(item),
        });
      });
    }
    return resultArr;
  }, [data]);

  const isNoticeDetailPopup = useSelector(
    (state) => state.common.popup.isNoticeDetailPopup
  );
  const isNoticeEditPopup = useSelector(
    (state) => state.common.popup.isNoticeEditPopup
  );

  const isNoticeRegisterPopup = useSelector(
    (state) => state.common.popup.isNoticeRegisterPopup
  );
  const isNoticeListPopup = useSelector(
    (state) => state.common.popup.isNoticeListPopup
  );
  const handleDetailPopUpClick = useCallback(() => {
    dispatch(setNoticeDetailPopup(!isNoticeDetailPopup));
    //eslint-disable-next-line
  }, [isNoticeDetailPopup]);

  const handleRegisterPopUpClose = useCallback(() => {
    dispatch(setNoticeRegisterPopup(false));
    dispatch(resetForm());
    //eslint-disable-next-line
  }, []);

  const handleRegisterPopUpClick = useCallback(() => {
    dispatch(setNoticeRegisterPopup(!isNoticeRegisterPopup));
    //eslint-disable-next-line
  }, [isNoticeRegisterPopup]);

  const handleListPopUpClick = useCallback(() => {
    dispatch(setNoticeListPopup(!isNoticeListPopup));
    //eslint-disable-next-line
  }, [isNoticeListPopup]);

  const handleEditPopUpClick = useCallback(() => {
    dispatch(setNoticeEditPopup(!isNoticeEditPopup));
    //eslint-disable-next-line
  }, [isNoticeEditPopup]);

  const handleCurrentNotice = useCallback((target) => {
    if (target.createUserId !== userId) {
      postIncreaseNoticeReadCount(dispatch, {
        noticeKey: target.noticeKey,
        userId: userId,
      });
    }

    dispatch(setCurrentNotice(target));
  }, []);

  const handleQuickMenu = useCallback(() => {
    setQuickMenuOpen(!quickMenuOpen);
  }, [quickMenuOpen]);
  const handleNavigate = useCallback((path, state) => {
    navigate(path, state);
  }, []);

  const goToTop = useCallback(() => {
    document.getElementById('root').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const quickMenu = useMemo(() => {
    const quickMenuMap = [
      { title: 'TOP', img: ico_top, fn: goToTop },
      {
        title: '홈',
        img: ico_home,
        fn: () => handleNavigate(ROUTER_PATH.HOME),
      },
      {
        title: '공지',
        img: ico_notice,
        fn: () => handleListPopUpClick(),
      },
      {
        title: '내정보',
        img: ico_user2,
        fn: () => handleNavigate(BASE_URL + USER_SUB_PATH.MYINFO),
      },
    ];

    const list = quickMenuMap.map((item, index) => {
      return (
        <li
          key={`quickMenu-${index}`}
          className={quickMenuOpen ? css.activeQmenu : css.disabledQmenu}
        >
          <div
            className={css.q_menu}
            onClick={() => item.fn()}
            style={{
              backgroundImage: `url(${item.img})`,
            }}
          >
            {item.title}
          </div>
        </li>
      );
    });

    list.push(
      <li key={'plusMinus-quick-menu'} onClick={() => handleQuickMenu()}>
        <div
          className={css.qMenuOnOff}
          style={{
            backgroundImage: `url(${quickMenuOpen ? ico_minus : ico_more})`,
          }}
        ></div>
      </li>
    );

    return list;
  }, [handleQuickMenu, quickMenuOpen, handleListPopUpClick, handleNavigate]);

  return (
    <>
      <div className={css.quickMenu}>
        {/* <ul className={quickMenuOpen ? css.quickMenuList : css.disabledList}> */}
        <ul className={cn(quickMenuOpen ? 'quickMenuList' : 'disabledList')}>
          {quickMenu.map((item) => {
            return item;
          })}
        </ul>
        <div className={css.goLGEWrap}>
          <div
            className={css.goLGE}
            style={{ backgroundImage: `url(${logo})` }}
            onClick={() => window.open('https://www.lge.co.kr', '_blank')}
          >
            LGE
          </div>
        </div>
      </div>
      <PopUp
        isOpenPopUp={isNoticeListPopup}
        onRequestClose={() => handleListPopUpClick()}
        flexStart={true}
        title={'공지사항 조회'}
        isWideSize={true}
      >
        <NoticeList
          tableData={tableData}
          handleDetailPopUpClick={handleDetailPopUpClick}
          handleCurrentNotice={handleCurrentNotice}
          handleRegisterPopUpClick={handleRegisterPopUpClick}
        />
      </PopUp>
      <PopUp
        isOpenPopUp={isNoticeDetailPopup}
        onRequestClose={handleDetailPopUpClick}
        flexStart={true}
        isWideSize={true}
      >
        <NoticeDetail
          openChildPopUp={handleEditPopUpClick}
          onRequestClose={handleDetailPopUpClick}
          currentNotice={currentNotice}
        />
      </PopUp>

      <PopUp
        isOpenPopUp={isNoticeEditPopup}
        onRequestClose={handleEditPopUpClick}
        isWideSize={true}
      >
        <NoticeEdit
          onRequestClose={handleEditPopUpClick}
          currentNotice={currentNotice}
        />
      </PopUp>
      <PopUp
        isOpenPopUp={isNoticeRegisterPopup}
        onRequestClose={handleRegisterPopUpClose}
        isWideSize={true}
      >
        <NoticeRegister onRequestClose={handleRegisterPopUpClose} />
      </PopUp>
    </>
  );
};

QuickMenu.defaultProps = {};

export default memo(QuickMenu);
