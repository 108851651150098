import { memo, useRef, useCallback, useMemo, useEffect, useState } from 'react';
import { ko } from 'date-fns/esm/locale';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import ico_calendar from '@assets/img/ico_calendar.svg';
import css from './Calendar.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.css';
import { timeToISO8601DateStr, isWeekDay, isHoliday } from '@utils';
import { useSelector } from 'react-redux';

const cn = classNames.bind(css);
const DISABLED_DATE_TYPE = {
  WEEKEND: 'weekend',
  HOLIDAY: 'holiday',
};
const periodArr = [
  { label: '오늘', key: 'today' },
  { label: '3일', key: 'threeDaysAgo' },
  { label: '7일', key: 'oneWeekAgo' },
];

// const CustomInput = forwardRef(({ value, onClick }, ref) => (
//   <button onClick={onClick} ref={ref}>
//     {value}
//     <img src={ico_calendar} alt="Calendar Icon" className={css.calendarIcon} />
//   </button>
// ));

/**
 * @category Components
 * @namespace Calendar
 * @param {boolean} hasTitle - date-picker 왼쪽에 '조회 기간' 텍스트 출력 유무
 * @param {boolean} hasPeriodInput - periodInput이 있는지
 * @param {string} periodInputType - periodInput의 Type -Type1:오늘/일주일/1개월/3개월 Type2:오늘/3일/일주일
 * @param {string} className - calendar container에 스타일 추가할 수 있는 className
 * @param {Date} startDate - date-picker의 시작 날짜
 * @param {function} handleStartDate - date-picker 시작 날짜의 onChange callback
 * @param {function} setStartDate - PeriodInput에서 시작 날짜를 설정하는 함수
 * @param {Date} endDate - date-picker의 종료 날짜
 * @param {function} handleEndDate - date-picker 종료 날짜의 onChange callback
 * @param {function} setEndDate - PeriodInput에서 종료 날짜를 설정하는 함수
 * @param {array} disabledDateTypeList - 달력에서 선택할 수 없는 날짜들을 지정하기 위한 배열 (값: 'weekend' | 'holiday')
 * @param {Date} minDate - date-picker의 최소 날짜
 * @param {Date} maxDate - date-picker의 최대 날짜
 * @param {Date} maxEndDate - 두 번쨰 date-picker에서 선택할 수 있는 최대 날짜
 * @param {string} defaultDateRange - 기간별 라디오 버튼(hasPeriodInput)의 기본값 설정을 위함
 */

const Calendar = ({
  hasTitle,
  hasPeriodInput,
  periodInputType,
  className,
  startDate,
  handleStartDate,
  setStartDate,
  endDate,
  handleEndDate,
  setEndDate,
  disabledDateTypeList,
  minDate,
  maxDate,
  maxEndDate,
  defaultDateRange,
  disabled,
}) => {
  const holidaysOfKorea = useSelector(
    ({ holidays: { holidaysOfKorea } }) => holidaysOfKorea
  );
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const onStartDateChange = (date) => {
    handleStartDate(date);
  };

  const onEndDateChange = (date) => {
    handleEndDate(date);
  };

  const handleTodayClick = () => {
    setSelectedDateRange('today');
    const today = new Date();
    setStartDate(today);
    setEndDate(today);
  };

  const handleThreeDaysAgo = () => {
    setSelectedDateRange('threeDaysAgo');
    const threeDaysAgo = new Date();
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    setStartDate(threeDaysAgo);
    setEndDate(new Date());
  };

  const handleOneWeekAgoClick = () => {
    setSelectedDateRange('oneWeekAgo');
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(oneWeekAgo);
    setEndDate(new Date());
  };

  const handleOneMonthAgoClick = () => {
    setSelectedDateRange('oneMonthAgo');
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setStartDate(oneMonthAgo);
    setEndDate(new Date());
  };

  const handleThreeMonthAgoClick = () => {
    setSelectedDateRange('threeMonthsAgo');
    const threeMonthAgo = new Date();
    threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);
    setStartDate(threeMonthAgo);
    setEndDate(new Date());
  };

  const startDateFocus = useCallback(() => {
    // DatePicker에 handleFocus 메소드가 내장되어있습니다.
    // 아이콘 클릭 시 DatePicker에 포커스 줘서 달력 UI Open 시킴.
    startDateRef.current.handleFocus();
  }, []);

  const endDateFocus = useCallback(() => {
    endDateRef.current.handleFocus();
  }, []);

  const isSelectableDate = useCallback(
    (date) => {
      if (!disabledDateTypeList.length) return true;

      for (const type of disabledDateTypeList) {
        switch (type) {
          case DISABLED_DATE_TYPE.WEEKEND:
            if (!isWeekDay(date)) return false;
            break;
          case DISABLED_DATE_TYPE.HOLIDAY:
            if (isHoliday({ holidays: holidaysOfKorea, date })) return false;
            break;
          default:
            break;
        }
      }
      return true;
    },
    [disabledDateTypeList, holidaysOfKorea]
  );

  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange);

  useEffect(() => {
    if (hasPeriodInput && periodInputType === 'Type1') {
      switch (selectedDateRange) {
        case 'today':
          handleTodayClick();
          break;
        case 'oneWeekAgo':
          handleOneWeekAgoClick();
          break;
        case 'oneMonthAgo':
          handleOneMonthAgoClick();
          break;
        case 'threeMonthsAgo':
          handleThreeMonthAgoClick();
          break;
        default:
          break;
      }
    } else if (
      hasPeriodInput &&
      (periodInputType === 'Type2' || periodInputType === 'Type4')
    ) {
      switch (selectedDateRange) {
        case 'today':
          handleTodayClick();
          break;
        case 'threeDaysAgo':
          handleThreeDaysAgo();
          break;
        case 'oneWeekAgo':
          handleOneWeekAgoClick();
          break;
        default:
          break;
      }
    } else if (hasPeriodInput && periodInputType === 'Type3') {
      switch (selectedDateRange) {
        case 'today':
          handleTodayClick();
          break;
        case 'oneWeekAgo':
          handleOneWeekAgoClick();
          break;
        case 'oneMonthAgo':
          handleOneMonthAgoClick();
          break;
        default:
          break;
      }
    }
  }, [selectedDateRange]);

  const highlightedArr = useMemo(() => {
    // timestamp로 생성한 Date 객체 변수에 할당
    let firstDate = new Date(timeToISO8601DateStr(startDate));
    let start = new Date(timeToISO8601DateStr(startDate));
    let end = new Date(timeToISO8601DateStr(endDate));
    const highlighted = [firstDate];

    // timestamp로 크기비교
    while (start.getTime() < end.getTime()) {
      // setDate하면서 start에 1일 추가
      const date = new Date(start.setDate(start.getDate() + 1));
      if (isSelectableDate(date)) highlighted.push(date);
    }

    return highlighted;
  }, [startDate, endDate, isSelectableDate]);

  useEffect(() => {
    periodInputType === 'Type4' && setSelectedDateRange('today');
  }, [periodInputType]);

  return (
    <>
      {/* Calendar1 */}
      <div className={cn(css.container, className)}>
        {hasTitle && <span className={css.title}>조회 기간</span>}
        {hasPeriodInput && periodInputType === 'Type4' && (
          <ul className={css.period}>
            {periodArr.map((item, index) => (
              <li
                key={`period_${index}`}
                className={classNames(
                  selectedDateRange === item.key && css.selected
                )}
                onClick={() => setSelectedDateRange(item.key)}
              >
                <button>{item.label}</button>
              </li>
            ))}
          </ul>
        )}
        <DatePicker
          highlightDates={highlightedArr}
          selected={startDate}
          onChange={onStartDateChange}
          dateFormat='yyyy-MM-dd'
          className={css.datePicker}
          ref={startDateRef}
          showIcon
          icon={
            <i
              className={classNames(
                css.calIcon,
                periodInputType === 'Type4' && css.type4
              )}
            >
              <img
                onClick={startDateFocus}
                src={ico_calendar}
                alt={'calendar Icon'}
              />
            </i>
          }
          locale={ko}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown
          scrollableMonthYearDropdown
          filterDate={isSelectableDate}
          disabled={disabled}
        />
        <span
          className={classNames(
            css.dash,
            periodInputType === 'Type4' && css.type4
          )}
        >
          -
        </span>
        <DatePicker
          highlightDates={highlightedArr}
          selected={endDate}
          onChange={onEndDateChange}
          dateFormat='yyyy-MM-dd'
          className={css.datePicker}
          ref={endDateRef}
          showIcon
          icon={
            <i
              className={classNames(
                css.calIcon,
                periodInputType === 'Type4' && css.type4
              )}
            >
              <img
                onClick={endDateFocus}
                src={ico_calendar}
                alt={'calendar Icon'}
              />
            </i>
          }
          locale={ko}
          minDate={minDate}
          maxDate={maxEndDate ?? maxDate}
          showYearDropdown
          scrollableMonthYearDropdown
          filterDate={isSelectableDate}
          disabled={disabled}
        />

        {hasPeriodInput && periodInputType === 'Type1' && (
          <div className={css.input_wrap}>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('today')}
                defaultChecked={selectedDateRange === 'today'}
                id='radio1'
              />
              <label htmlFor='radio1'>
                <span>오늘</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('oneWeekAgo')}
                defaultChecked={selectedDateRange === 'oneWeekAgo'}
                id='radio2'
              />
              <label htmlFor='radio2'>
                <span>일주일</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('oneMonthAgo')}
                defaultChecked={selectedDateRange === 'oneMonthAgo'}
                id='radio3'
              />
              <label htmlFor='radio3'>
                <span>1개월</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('threeMonthsAgo')}
                defaultChecked={selectedDateRange === 'threeMonthsAgo'}
                id='radio4'
              />
              <label htmlFor='radio4'>
                <span>3개월</span>
              </label>
            </div>
          </div>
        )}
        {hasPeriodInput && periodInputType === 'Type2' && (
          <div className={css.input_wrap}>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('today')}
                defaultChecked={selectedDateRange === 'today'}
                id='radio1'
              />
              <label htmlFor='radio1'>
                <span>오늘</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('threeDaysAgo')}
                defaultChecked={selectedDateRange === 'threeDaysAgo'}
                id='radio2'
              />
              <label htmlFor='radio2'>
                <span>3일</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('oneWeekAgo')}
                defaultChecked={selectedDateRange === 'oneWeekAgo'}
                id='radio3'
              />
              <label htmlFor='radio3'>
                <span>일주일</span>
              </label>
            </div>
          </div>
        )}
        {hasPeriodInput && periodInputType === 'Type3' && (
          <div className={css.input_wrap}>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('today')}
                defaultChecked={selectedDateRange === 'today'}
                id='radio1'
              />
              <label htmlFor='radio1'>
                <span>오늘</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('oneWeekAgo')}
                defaultChecked={selectedDateRange === 'oneWeekAgo'}
                id='radio2'
              />
              <label htmlFor='radio2'>
                <span>일주일</span>
              </label>
            </div>
            <div className={css.input_radio}>
              <input
                type='radio'
                name='dateRange'
                onClick={() => setSelectedDateRange('oneMonthAgo')}
                defaultChecked={selectedDateRange === 'oneMonthAgo'}
                id='radio3'
              />
              <label htmlFor='radio3'>
                <span>1개월</span>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Calendar.defaultProps = {
  hasTitle: true,
  startDate: new Date(),
  endDate: new Date(),
  hasPeriodInput: true,
  periodInputType: 'Type1',
  disabledDateTypeList: [],
  minDate: undefined,
  maxDate: new Date(),
  defaultDateRange: 'today',
  disabled: false,
};

export default memo(Calendar);
