import { memo, useCallback } from 'react';
import css from './BreadCrumbs.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace BreadCrumbs
 * @param {Array} menuList BreadCrumbs 의 아이템 리스트. (화면에서 title과 path 가진 배열 지정 해서 사용
 * @description 공통적으로 사용하는 BreadCrumbs 컴포넌트.
 */
const BreadCrumbs = ({ menuList }) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, []);

  return (
    <div className={css.breadcrumbs}>
      <ul>
        {menuList &&
          menuList.map((menu) => (
            <li key={menu.title} onClick={() => handleNavigate(menu.path)}>
              {menu.title}
            </li>
          ))}
      </ul>
    </div>
  );
};

BreadCrumbs.defaultProps = {
  menuList: [],
};

export default memo(BreadCrumbs);
