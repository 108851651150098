import React from 'react';
import classNames from 'classnames/bind';
import css from './SendingResultSearchArea.module.scss';
import { Button } from '@components/index';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { useFormContext } from 'react-hook-form';

const cn = classNames.bind(css);

const SendingResultSearchArea = ({
  vendorSearchOptions,
  receiverSearchOptions,
  alarmTypeSearchOptions,
  onSearchClick,
}) => {
  const { watch, control, handleSubmit } = useFormContext();
  const targetWatch = watch('receiverSearchOptions');

  return (
    <>
      <div className={cn('selectbox', 'type01', 'sizeS')}>
        <HFSelectBox
          control={control}
          name={'receiverSearchOptions'}
          options={receiverSearchOptions}
        />
      </div>
      <div className={cn('selectbox', 'type01', 'sizeS', 'ml10', 'mr20')}>
        <HFSelectBox
          control={control}
          name={'vendorSearchOptions'}
          options={vendorSearchOptions}
          disabled={targetWatch !== 'VENDOR'}
        />
      </div>
      <strong className={cn('title', 'ml20')}>알림구분</strong>
      <div className={cn('selectbox', 'type01', 'sizeS', 'mr20')}>
        <HFSelectBox
          control={control}
          name={'alarmTypeSearchOptions'}
          options={alarmTypeSearchOptions}
        />
      </div>
      <Button
        styleType={'btn01'}
        subType={'type03'}
        className={cn('btn', 'ml40')}
        onClick={handleSubmit(onSearchClick)}
      >
        검색
      </Button>
    </>
  );
};

export default SendingResultSearchArea;
