import React, { useCallback, useEffect, useRef } from 'react';
import Portal from './Portal';
import css from './PortalPopup.module.scss';
import classNames from 'classnames';

const cn = classNames.bind(css);

const SIZES = {
  '1440x770': 'big',
  '660x330': 'small',
};

/**
 * @category components
 * @namespace PortalPopup
 * @param {Number} portalIndex
 * @param {String} size default = SIZES['660x330']
 * @param {Function} setClose
 * @param {Array} buttonContents default = []
 * @param {Component} children
 * @param {Boolean} disabled default = false
 * @description Create a popup and apply the button component's onClick event
 */

const PortalPopup = ({
  portalIndex,
  size = SIZES['660x330'],
  setClose,
  buttonContents = [],
  children,
  disabled = false,
}) => {
  const popupRef = useRef(null);

  const onClickClose = useCallback(() => {
    setClose && setClose(false);
  }, [setClose]);

  const onClickButton = useCallback(
    (index) => {
      return buttonContents[index].isDefault
        ? onClickClose()
        : buttonContents[index].onClick() && buttonContents[index].onClick();
    },
    [onClickClose, buttonContents]
  );

  // TODO: 로딩 팝업 바깥 영역 클릭 시 닫히는 현상 방지 위해 주석 처리
  // useEffect(() => {
  //   if (!disabled) {
  //     const handler = (event) => {
  //       if (popupRef.current && !popupRef.current.contains(event.target)) {
  //         onClickClose();
  //       }
  //     };
  //     document.addEventListener('mousedown', handler);

  //     return () => {
  //       document.removeEventListener('mousedown', handler);
  //     };
  //   }
  // }, [disabled]);

  return (
    <Portal index={portalIndex}>
      <div className={css.bgBlack}>
        <div ref={popupRef} className={classNames(css.wrapper, css[size])}>
          <div className={css.contentLayer}>{children}</div>
          <div className={css.buttonLayer}>
            {buttonContents.length > 0 ? (
              buttonContents.map((buttonItem, buttonIndex) => (
                <button
                  key={`buttonContent_${buttonIndex}`}
                  className={classNames(
                    css.button,
                    css[buttonItem.isDefault && 'default']
                  )}
                  onClick={() => onClickButton(buttonIndex)}
                >
                  {buttonItem.label}
                </button>
              ))
            ) : (
              <button className={cn('button')} onClick={onClickClose}>
                {'확인'}
              </button>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default PortalPopup;
export { SIZES };
