import css from './Sent.module.scss';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames/bind';
import {
  Calendar,
  MultiSelectSearch,
  SelectBox,
  TableV8,
} from '@components/index';
import { useSelect } from '@hooks/index';
import { useSelector } from 'react-redux';
import { dateToStr } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { getRetrieveMessageList } from 'redux/action/mailAction';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const catagoryOptionList = [
  { id: 'first_catagory', title: '상품관리' },
  { id: 'second_catagory', title: '쿠폰관리' },
  { id: 'thired_catagory', title: '배송관리' },
  { id: 'fourth_catagory', title: '환불관리' },
  { id: 'fifth_catagory', title: '정산관리' },
];
const Sent = () => {
  const dispatch = useDispatch();
  const vendorList = useSelector((state) => state.common.vendorList);
  const userId = useSelector((state) => state.user.userData.userId);
  const mailList = useSelector((state) => state.mail.autoSendList);

  const [pageElements, setPageElements] = useState({
    pageSize: '100',
    pageCount: '1',
  });

  const groupOptionList = [...vendorList];

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //todo: pramas 수정
  const listParams = useMemo(() => {
    const result = {
      userId: userId,
      fromDate: '20231201',
      toDate: dateToStr(new Date()),
      delFlag: 'N',
      searchType: '',
      detailCondition: '',
      systemMessageFlag: 'Y',
      limit: '100',
      page: pageElements.pageCount,
    };
    return result;
  }, [userId]);

  useEffect(() => {
    getRetrieveMessageList(dispatch, listParams, 'autoSend');
  }, []);

  const onChangePageElements = useCallback((element) => {
    setPageElements(element);
  }, []);

  const [filteredData, setFilteredData] = useState(mailList);

  useEffect(() => {
    setFilteredData(mailList);
  }, [mailList]);

  const handleStartDate = (date) => {
    if (date <= endDate) {
      setStartDate(date);
    } else {
      alert('시작 날짜는 종료 날짜보다 이전이어야 합니다.');
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      setEndDate(date);
    } else {
      alert('종료 날짜는 시작 날짜보다 이후이어야 합니다.');
    }
  };

  const [
    currentSelectedCatagory,
    setCurrentSelectedCatagory,
    currentCatagoryLabel,
  ] = useSelect({
    optionList: catagoryOptionList,
    initialIndex: 0,
    initialLabel: catagoryOptionList[0].title,
  });

  const [currentSelectedGroup, setCurrentSelectedGroup, currentGroupLabel] =
    useSelect({
      optionList: groupOptionList,
      initialIndex: 0,
      initialLabel: 'LGE',
      titleKey: 'companyName',
    });

  const updateFilteredData = (newFilteredData) => {
    setFilteredData(newFilteredData);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('manageCategoryDesc', {
        header: () => <span>구분</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('groupName', {
        header: () => <span>그룹(상호명)</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('sendUserId', {
        header: () => <span>보낸 사람</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('content', {
        header: () => <span>내용</span>,
        cell: (info) => <span className={css.cursor}>{info.getValue()}</span>,
        size: 700,
      }),
      columnHelper.accessor('receivedDatetime', {
        header: () => <span>받은 날짜</span>,
        cell: (info) => <span>{info.getValue()}</span>,
        size: 200,
      }),
    ],
    []
  );

  return (
    <>
      <div className={cn('searchOptionArea')}>
        <div className={css.calendarContainer}>
          <Calendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            hasPeriodInput={false}
          />
        </div>
        <div className={cn('flex')}>
          <span className={css.title}>그룹(상호명)</span>
          <div className={cn('sizeS')}>
            <SelectBox
              optionList={groupOptionList}
              currentIndex={currentSelectedGroup}
              currentLabel={currentGroupLabel}
              handleSelectIndex={setCurrentSelectedGroup}
              titleKey={'companyName'}
              id={'companyCode'}
              fullWidth={true}
            />
          </div>
          <span className={css.title}>구분</span>
          <div className={cn('sizeS')}>
            <SelectBox
              optionList={catagoryOptionList}
              currentIndex={currentSelectedCatagory}
              currentLabel={currentCatagoryLabel}
              handleSelectIndex={setCurrentSelectedCatagory}
              fullWidth={true}
            />
          </div>
          <div className={css.searchContainer}>
            <MultiSelectSearch
              searchKeys={['sendUserId', 'receiveUserId']}
              targetDateKey={'receivedDatetime'}
              data={mailList}
              setFilteredData={setFilteredData}
              startDate={startDate}
              endDate={endDate}
              updateFilteredData={updateFilteredData}
              placeholder={'보낸사람,ID'}
              selectList={{
                groupName: currentGroupLabel,
                categoryDesc: currentCatagoryLabel,
              }}
            />
          </div>
        </div>
      </div>
      <h2 className={css.title}>자동 발송 메시지 조회 결과</h2>
      <TableV8
        data={filteredData}
        columns={columns}
        hasCsvDownload={false}
        getPageElements={onChangePageElements}
      />
    </>
  );
};

export default Sent;
