import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingManageStepFirst.module.scss';
import { SelectBox, TabMenu, SlidingButton, PopUp } from '@components/index';
import { useSelect } from '@hooks/index';
import { useDispatch, useSelector } from 'react-redux';
import { convertToKoreanDate } from 'utils/utils';
import { useEffect } from 'react';
import {
  getSettlementTargetStatusInfoAPI,
  getSettlementDashboardAPI,
  getSettlementDetailsAPI,
} from 'redux/action/settlementAction';
import { createColumnHelper } from '@tanstack/react-table';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const AccountingManageStepFirst = () => {
  const dispatch = useDispatch();
  const vendorList = useSelector((state) => state.common.vendorList);
  const settlementDetails = useSelector(
    (state) => state.settlement.settlementDetails
  );
  const settlementDashboard = useSelector(
    (state) => state.settlement.settlementDashboard
  );

  const [popUpVendorName, setPopUpVendorName] = useState(false);
  const [isOpenTotalHistoryPopUp, setIsOpenTotalHistoryPopUp] = useState(false);
  const currentPeriod = convertToKoreanDate(new Date());

  const selectVendorList = useMemo(() => {
    const totalSelecteOption = [{ id: 'TOTAL', title: '전체', vendorKey: '' }];
    vendorList.forEach((vendor) => {
      totalSelecteOption.push({
        id: vendor.companyCode,
        title: vendor.companyName,
        vendorKey: vendor.vendorKey,
      });
    });
    return totalSelecteOption;
  }, [vendorList]);

  const [selectedCompanyIndex, setSelectedCompanyIndex, selecteCompanyLabel] =
    useSelect({
      optionList: selectVendorList,
      initialIndex: 0,
      initialLabel: '전체',
    });

  const settlementCard = useMemo(() => {
    const keyArray = Object.keys(settlementDashboard);
    let resultArray = [];
    keyArray.forEach((key) => {
      resultArray.push({ [key]: settlementDashboard[key] });
    });
    return resultArray;
  }, [settlementDashboard]);

  const onClickTotalHistory = useCallback((vendorName) => {
    setPopUpVendorName(vendorName);
    setIsOpenTotalHistoryPopUp(true);
  }, []);

  const onCloseTotalHistory = useCallback((Type) => {
    setIsOpenTotalHistoryPopUp(false);
  }, []);

  const popUpTableData = useMemo(() => {
    const resultArray = [];
    const [targetData] = settlementDetails.filter(
      (item) => Object.keys(item)[0] === popUpVendorName
    );
    if (targetData?.[popUpVendorName]) {
      const { settlementAdjustmentDetailsList, settlementOriginalDetailsList } =
        targetData[popUpVendorName];
      // settlementAdjustmentDetailsList 는 매출조정 데이터임
      if (Array.isArray(settlementAdjustmentDetailsList)) {
        settlementAdjustmentDetailsList.forEach((item) => {
          resultArray.push(item);
        });
      }
      if (Array.isArray(settlementOriginalDetailsList)) {
        settlementOriginalDetailsList.forEach((item) => {
          resultArray.push(item);
        });
      }
    }
    return resultArray;
  }, [popUpVendorName, settlementDetails]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정구분</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품ID</span>,
      }),
      columnHelper.accessor('serviceModelDesc', {
        id: 'serviceModelDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품</span>,
      }),
      columnHelper.accessor('promotionTypeDesc', {
        id: 'promotionTypeDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('category', {
        id: 'category',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>구분</span>,
      }),
      columnHelper.accessor('sellPrice', {
        id: 'sellPrice',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>판매가</span>,
      }),
      columnHelper.accessor('netPrice', {
        id: 'netPrice',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>공급가</span>,
      }),
      columnHelper.accessor('profitMargin', {
        id: 'profitMargin',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>마진</span>,
      }),
    ],
    []
  );

  useEffect(() => {
    const params = {
      vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
    };
    getSettlementTargetStatusInfoAPI(dispatch, params); //상단 대시보드
    getSettlementDashboardAPI(dispatch, params);
    getSettlementDetailsAPI(dispatch, params); //전체 거래내역보기
  }, [selectedCompanyIndex, selectVendorList]);

  return (
    <>
      <div className={cn('stepGuideText')}>
        1. 업체 별 유상 매출을 확인해 주세요. 금액이 맞지 않는 경우 매출
        조정에서 변경해주세요.
      </div>
      <div className={cn('selectbox', 'type01', 'sizeM', 'mt10')}>
        <SelectBox
          optionList={selectVendorList}
          currentIndex={selectedCompanyIndex}
          handleSelectIndex={setSelectedCompanyIndex}
          currentLabel={selecteCompanyLabel}
          fullWidth
        />
      </div>
      {settlementCard.length > 0 &&
        settlementCard.map((item) => {
          const vendorName = Object.keys(item)[0];
          return (
            <div className={cn('calc_table_wrapper')}>
              <div className={cn('titleArea')}>
                <div className={cn('title')}>
                  <strong>{vendorName}</strong>
                  <button
                    type='button'
                    className={cn('btn', 'btn03', 'type01')}
                    onClick={() => onClickTotalHistory(vendorName)}
                  >
                    전체 거래 내역 보기
                  </button>
                </div>
              </div>
              <div className={cn('calc_table_wrap')}>
                <div className={cn('calc_table')}>
                  <div className={cn('title')}>
                    <div className={cn('flex')}>
                      <div className={cn('flex')}>
                        <div
                          className={cn('type01', 'sizeS')}
                          style={{ fontWeight: 'bold' }}
                        >
                          {currentPeriod}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={cn('item')}>
                    <dl className={cn('total')}>
                      <dt>전체 정산 건수</dt>
                      <dd>
                        {item[vendorName].settlementTotalByVendor
                          .totalSettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    <dl className={cn('detail')}>
                      <dt>- 쿠폰 당월 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByVendor
                          .couponSettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    <dl className={cn('detail')}>
                      <dt>- 배송 당월 매출</dt>

                      <dd>
                        {item[vendorName].settlementTotalByVendor
                          .deliverySettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    {/* <div className={cn('note', 'txtR')}>
                      + 조정 8건 / - 조정 2건
                    </div> */}
                  </div>
                  <div className={cn('amount')}>
                    <dl>
                      <dt>총 예상 금액</dt>
                      <dd>
                        {item[vendorName].settlementTotalByVendor
                          .expectedTotalAmount ?? 0}{' '}
                        원
                      </dd>
                    </dl>
                  </div>
                </div>
                <span className={cn('equal')}></span>
                <div className={cn('calc_table')}>
                  <div className={cn('title')}>
                    <div className={cn('flex')}>
                      <strong className={cn('type')}>유상</strong>
                      {/* <SlidingButton data={slideNfocMockdata} /> */}
                    </div>
                  </div>
                  <div className={cn('item')}>
                    <dl className={cn('total')}>
                      <dt>전체 유상 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByNFOCMerchandise
                          .totalSettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    <dl className={cn('detail')}>
                      <dt>- 쿠폰 당월 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByNFOCMerchandise
                          .couponSettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    <dl className={cn('detail')}>
                      <dt>- 배송 당월 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByNFOCMerchandise
                          .deliverysettlementcnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                  </div>
                  <div className={cn('amount')}>
                    <dl>
                      <dt>총 예상 금액(공급가액)</dt>
                      <dd>
                        {item[vendorName].settlementTotalByNFOCMerchandise
                          .expectedTotalAmount ?? 0}{' '}
                        원
                      </dd>
                    </dl>
                  </div>
                </div>
                <span className={cn('plus')}></span>
                <div className={cn('calc_table')}>
                  <div className={cn('title')}>
                    <div className={cn('flex')}>
                      <strong className={cn('type')}>무상</strong>
                      {/* <SlidingButton data={slideFocMockdata} /> */}
                    </div>
                  </div>
                  <div className={cn('item')}>
                    <dl className={cn('total')}>
                      <dt>전체 무상 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByFOCMerchandise
                          .totalSettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    <dl className={cn('detail')}>
                      <dt>- 쿠폰 당월 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByFOCMerchandise
                          .couponSettlementCnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                    <dl className={cn('detail')}>
                      <dt>- 배송 당월 매출</dt>
                      <dd>
                        {item[vendorName].settlementTotalByFOCMerchandise
                          .deliverysettlementcnt ?? 0}{' '}
                        건
                      </dd>
                    </dl>
                  </div>
                  <div className={cn('amount')}>
                    <dl>
                      <dt>총 예상 금액(공급가액)</dt>
                      <dd>
                        {item[vendorName].settlementTotalByFOCMerchandise
                          .expectedTotalAmount ?? 0}{' '}
                        원
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* 전체 거래 내역 보기 popUp */}
      <PopUp
        isOpenPopUp={isOpenTotalHistoryPopUp}
        onRequestClose={() => onCloseTotalHistory()}
      >
        <strong className={css.popup_title}>전체 거래 내역</strong>

        <TableV8ManualPage
          columns={columns}
          data={popUpTableData}
          csvTitle='다운로드'
          // page={page}
          // limit={limit}
          // totalLength={couponOverallStatusTotalCount}
        />
      </PopUp>
    </>
  );
};

export default AccountingManageStepFirst;
