import React, { Fragment, memo } from 'react';
import Modal from 'react-modal';
import css from './PopUpAlert.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category Components
 * @namespace PopUPAlert
 * @param {string} title PopUPAlert title
 * @param {boolean} isOpenPopUp PopUPAlert open/close 여부
 * @callback onRequestCloseCallback PopUPAlert 닫힐때 실행되는 함수
 * @param {ReactNode[]} button PopUPAlert 의 버튼 리스트
 * @param {boolean} flexStart PopUPAlert 길이가 화면 크기보다 길어지면 flexStart를 적용해줘야 짤림 없이 동작함
 * @param {boolean} closeButton PopUp의 우측 상단 X버튼 존재 여부
 */

// Modal.setAppElement('#custom-portal');

const PopUPAlert = ({
  isOpenPopUp,
  onRequestClose,
  title,
  children,
  button,
  flexStart,
  closeButton,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: flexStart ? 'flex-Start' : 'center',
      justifyContent: 'center',
      maxHeight: '100%',
      padding: '60px 0',
      overflowX: 'hidden',
      overflowY: 'auto',
      zIndex: 100,
    },
  };

  return (
    // FIXME: Portal test 후 불필요한 구조면 삭제 예정
    // <div className={css.container}>
    //   {isOpenPopUp && (
    //     <div className={css.dimmed} onClick={onRequestClose}></div>
    //   )}
    //   <div className={css.layerPopup}>
    <>
      {isOpenPopUp && (
        <Modal
          className={cn('alert')}
          isOpen={isOpenPopUp}
          onRequestClose={onRequestClose}
          style={customStyles}
        >
          {title && <strong className={css.alert_title}>{title}</strong>}
          {closeButton && (
            <div onClick={onRequestClose} className={css.popup_close} />
          )}
          <div className={css.alert_body}>{children}</div>
          <div className={css.buttonContianer}>
            {button &&
              button.map((item, idx) => <Fragment key={idx}>{item}</Fragment>)}
          </div>
        </Modal>
      )}
    </>
    //   </div>
    // </div>
  );
};

PopUPAlert.defaultProps = {
  title: false,
  flexStart: false,
  closeButton: false,
};

export default memo(PopUPAlert);
