import React, {
  useCallback,
  useEffect,
  memo,
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { CsvDownload, Button } from '@components';
import classNames from 'classnames/bind';
import css from './TableV8.module.scss';

const cn = classNames.bind(css);

const useTablePagination = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });

  const initializePageIndex = useCallback(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, []);

  const handleChangePageIndex = useCallback(
    (pageIndex) => {
      setPagination((prev) => {
        return Object.assign({}, prev, pageIndex);
      });
    },
    [pagination]
  );

  const handleChangePageSize = useCallback((pageSize) => {
    setPagination((prev) => ({ ...prev, pageSize }));
  }, []);

  return {
    pagination,
    setPagination,
    initializePageIndex,
    onChangePageIndex: handleChangePageIndex,
    onChangePageSize: handleChangePageSize,
  };
};

/**
 * @category components
 * @namespace TableV8
 * @param {TData[]} data table에 실제 뿌려지는 데이터
 * @param {ColumnDef<TData>[]} columns table을 구성하는 각 열(column)의 header 및 데이터를 추가, 설정하는 배열
 * @param {boolean} hasExportBtn '내보내기' 버튼의 활성화 여부
 * @param {boolean} hasCsvDownload 'CSV 파일 저장' 버튼의 활성화 여부
 * @param {boolean} hasPageSize 한 페이지에서 보여지는 데이터의 수를 선택하는 selectbox 활성화 여부
 * @param {ReactNode[]} rightControls table pagination 오른쪽에 버튼이나 selectBox, search(들)을 한줄로 나타내기 위해 추가할 수 있는 배열
 * @param {ReactNode[]} leftControls table pagination 바로 오른쪽에 버튼이나 기타 요소들을 추가하기 위한 배열
 * @param {function} getRowselected table에서 selected된 값을 가져오기 위함
 * @param {function} getPageElements table에서 pagenation에 필요한 현재페이지, limit를 가져오기 위함
 * @description React Table V8 https://tanstack.com/table/v8/docs/api/core/table
 */
const TableV8ManualPage = (
  {
    data,
    columns,
    hasExportBtn,
    hasCsvDownload,
    hasPageSize,
    rightControls,
    leftControls,
    csvTitle,
    getRowselected,
    getPageElements,
    disabledFooter = false,
    noMargin = false,
    page,
    limit,
    handlePageCallback,
    totalLength,
  },
  ref
) => {
  const didMountRef = useRef(false);
  const [rowSelection, setRowSelection] = React.useState({});
  // const [ramainPage, setRamainPage] = React.useState(0);
  const {
    pagination,
    setPagination,
    initializePageIndex,
    onChangePageIndex,
    onChangePageSize,
  } = useTablePagination();
  // const pageSizeOptions = [10, 20, 30, 40, 50, 100, 200];
  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      pagination,
    },
    enableRowSelection: true,
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: setPagination,
    manualpagination: true,
    debugTable: true,
  });

  const pageButtons = useCallback(() => {
    const tData = {
      pageIndex: Number(pagination.pageIndex),
      pageSize: Number(pagination.pageSize),
    };
    const pageGroupSize = 5;
    const currentPageGroupIndex = Math.floor(tData.pageIndex / pageGroupSize);

    const remain =
      Math.ceil(Number(totalLength) / Number(limit)) -
      pageGroupSize * currentPageGroupIndex;

    return Array.from({ length: Math.min(pageGroupSize, remain) }, (_, i) => {
      const pageNumber = currentPageGroupIndex * pageGroupSize + i;
      return (
        <li key={i} className={pageNumber === tData.pageIndex ? css.on : ''}>
          <button
            type='button'
            onClick={() => onChangePageIndex({ pageIndex: pageNumber })}
            disabled={tData.pageIndex === pageNumber}
          >
            {pageNumber + 1}
          </button>
        </li>
      );
    });
  }, [limit, totalLength, onChangePageIndex, pagination, page]);

  const tableRowAll = useCallback(() => {
    return Object.values(table.getRowModel().rowsById).map((row) => {
      return row.original;
    });
  }, [table.getRowModel()]);

  const tableRowselected = useCallback(() => {
    const selectedArr = Object.values(table.getSelectedRowModel().rowsById).map(
      (row) => {
        console.log('###row', row.getAllCells());

        return row.original;
      }
    );
    if (typeof getRowselected === `function`) {
      getRowselected(selectedArr);
    }
    return selectedArr;
  }, [table.getSelectedRowModel()]);

  useEffect(() => {
    if (typeof getRowselected === 'function' && !hasCsvDownload) {
      tableRowselected();
    }
  }, [tableRowselected, getRowselected]);

  useEffect(() => {
    if (didMountRef.current && typeof handlePageCallback === 'function') {
      handlePageCallback(pagination);
    }
    didMountRef.current = true;
  }, [pagination]);

  useEffect(() => {
    const currentPage = pagination.pageIndex + 1;
    const maxPage = Math.ceil(Number(totalLength) / Number(limit));
    if (currentPage > maxPage) {
      if (pagination.pageIndex !== 0) {
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      }
    }
  }, [pagination, setPagination, totalLength, limit, page]);

  return (
    <>
      <div className={css.tableContainer}>
        <div className={classNames(css.table_header, noMargin && css.noMargin)}>
          {/* {hasPageSize && (
            <div className={css.box_left}>
              <div className={css.total_count}>
                <span>{data?.length ?? 0} </span>
                Items
              </div>
              <span>
                <select
                  className={cn('selectbox', 'type05')}
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    onChangePageSize(Number(e.target.value));
                  }}
                >
                  {pageSizeOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </span>
              {leftControls && <div>{leftControls}</div>}
            </div>
          )} */}
          <div className={css.box_right}>
            {rightControls && <div>{rightControls}</div>}
          </div>
        </div>
        <table
          className={classNames(css.checkBoxTable, noMargin && css.noMargin)}
        >
          <thead className={classNames(css.thead, noMargin && css.noMargin)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={css.tableList}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ width: header.column.getSize() }}
                    className={css.table_th}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={css.tbody_tr}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={css.tbody_td}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {!disabledFooter ? (
          <div className={css.table_footer_test}>
            <div className={css.pagination}>
              <button
                className={cn('nav', 'first')}
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                처음
              </button>
              <button
                className={cn('nav', 'prev')}
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                이전
              </button>
              <ol className={css.order}>{pageButtons()}</ol>
              <button
                className={cn('nav', 'next')}
                onClick={() => table.nextPage()}
                disabled={
                  pagination.pageIndex >=
                  Math.ceil(Number(totalLength) / Number(limit)) - 1
                }
              >
                다음
              </button>
              <button
                className={cn('nav', 'last')}
                // onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                onClick={() =>
                  onChangePageIndex({
                    pageIndex: Math.ceil(
                      Number(totalLength) / Number(limit) - 1
                    ),
                  })
                }
                disabled={
                  pagination.pageIndex >=
                  Math.ceil(Number(totalLength) / Number(limit)) - 1
                }
              >
                마지막
              </button>
              <div className={css.pageIndex}>
                {`${pagination.pageIndex + 1} of ${Math.ceil(
                  Number(totalLength) / Number(limit)
                )}`}
              </div>
            </div>
            {hasExportBtn && (
              <Button styleType={'btn02'} subType={'type04'}>
                내보내기
              </Button>
            )}
          </div>
        ) : null}
        {hasCsvDownload && (
          <div className={css.table_footer}>
            <button type='button' className={css.excelPosition}>
              <CsvDownload
                className={css.ico_download}
                data={tableRowAll()}
                selectedRows={tableRowselected()}
                filterKey='no'
                title={csvTitle}
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

TableV8ManualPage.defaultProps = {
  hasExportBtn: false,
  hasCsvDownload: true,
  hasPageSize: true,
  page: 1,
  limit: 100,
  totalLength: 10,
  data: [],
};

export default TableV8ManualPage;
