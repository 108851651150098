import Home from '@Home/Home';
import NotFound from '@NotFound/NotFound';

//DeliveryManagement
import DeliveryOrder from '@views/DeliveryManagement/DeliveryOrder/DeliveryOrder';
import DeliveryResult from '@views/DeliveryManagement/DeliveryResult/DeliveryResult';

//CouponManagement
import CouponManage from '@views/CouponManagement/CouponManage/CouponManage';
import CouponRegister from '@views/CouponManagement/CouponRegister/CouponRegisterStep';
import CouponAlarm from '@views/CouponManagement/CouponAlarm/CouponAlarm';
import CouponList from '@views/CouponManagement/CouponList/CouponList';
import CouponAlarmList from '@views/CouponManagement/CouponAlarmList/CouponAlarmList';
import CouponUsed from '@views/CouponManagement/CouponUsed/CouponUsed'; //신규추가
// depth 4

//RefundManagement
import RefundUnused from '@views/RefundManagement/RefundUnused/RefundUnused';
import RefundCustomer from '@views/RefundManagement/RefundCustomer/RefundCustomer';
import RefundAlarm from '@views/RefundManagement/RefundAlarm/RefundAlarm';
import RefundRetrieve from '@views/RefundManagement/RefundRetrieve/RefundRetrieve';

//AccountingManagement
import AccountingTaxBill from '@views/AccountingManagement/AccountingTaxBill/AccountingTaxBill';
import AccountingList from '@views/AccountingManagement/AccountingList/AccountingList';
import AccountingManage from '@views/AccountingManagement/AccountingManage/AccountingManage';
import AccountingManageStepThird from '@views/AccountingManagement/AccountingManage/AccountingManageStep/AccountingManageStepThird';
import ComponentTest from '@views/ComponentTest/ComponentTest';
import TemplatePageTest from '@views/TemplatePageTest/TemplatePageTest';

//Register
import Register from '@views/User/Register';
import Login from '@views/User/Login/Login';
import Find from '@views/User/Find/Find';
import AuthorityManage from '@views/AuthorityManagement/AuthorityManage';
import MyInfo from '@views/User/MyInfo/MyInfo';

//Product
import ProductList from '@views/ProductManagement/ProductList/ProductList';
import ProductReg from '@views/ProductManagement/ProductReg/ProductReg';

//sub routers
import {
  ROUTER_PATH,
  BASE_URL,
  PRODUCT_SUB_PATH,
  DELIVERY_SUB_PATH,
  COUPON_SUB_PATH,
  REFUND_SUB_PATH,
  ACCOUNTING_SUB_PATH,
  USER_SUB_PATH,
} from '@router/routerPath';

// layout
import DashboardLayout from 'layout/DashboardLayout';
import ProtectedRoute from '@components/ProtectedRoute/ProtectedRoute';
import RefundVendor from '@views/RefundManagement/RefundVendor/RefundVendor';

// mail
import Mail from '@views/User/Mail/Mail';

const router = [
  {
    path: ROUTER_PATH.ROOT,
    element: <Login />,
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      { path: ROUTER_PATH.TEST, element: <Home /> },
      { path: ROUTER_PATH.HOME, element: <Home /> },
      {
        // 상품관리
        path: BASE_URL,
        children: [
          {
            path: PRODUCT_SUB_PATH.LIST,
            element: <ProductList />,
          },
          {
            path: PRODUCT_SUB_PATH.REGISTER,
            element: <ProductReg />,
          },
        ],
      },
      {
        //쿠폰관리
        path: BASE_URL,
        children: [
          {
            path: COUPON_SUB_PATH.REGISTER,
            element: <CouponRegister />,
          },
          {
            path: COUPON_SUB_PATH.MANAGEMENT,
            element: <CouponManage />,
          },
          {
            path: COUPON_SUB_PATH.LIST,
            element: <CouponList />,
          },
          {
            path: COUPON_SUB_PATH.ALARM,
            element: <CouponAlarm />,
          },
          {
            path: COUPON_SUB_PATH.RESULT,
            // element: <CouponAlarmList />,
            element: <CouponUsed />, //신규
          },
        ],
      },
      {
        // 권한 관리
        path: ROUTER_PATH.AUTHORITY,
        element: <AuthorityManage />,
      },
      // 메일함
      {
        path: ROUTER_PATH.MAIL,
        element: <Mail />,
      },
      {
        // 배송관리
        path: BASE_URL,
        children: [
          {
            path: DELIVERY_SUB_PATH.ORDER,
            element: <DeliveryOrder />,
          },
          {
            path: DELIVERY_SUB_PATH.RESULT,
            element: <DeliveryResult />,
          },
        ],
      },
      {
        // 환불관리
        path: BASE_URL,
        children: [
          {
            path: REFUND_SUB_PATH.UNUSED,
            element: <RefundUnused />,
          },
          {
            path: REFUND_SUB_PATH.CUSTOMER,
            element: <RefundCustomer />,
          },
          {
            path: REFUND_SUB_PATH.ALARM,
            element: <RefundAlarm />,
          },
          {
            path: REFUND_SUB_PATH.RETRIEVE,
            element: <RefundRetrieve />,
          },
        ],
      },
      {
        //정산관리
        path: BASE_URL,
        children: [
          {
            path: ACCOUNTING_SUB_PATH.MANAGEMENT,
            element: <AccountingManage />,
          },
          {
            path: ACCOUNTING_SUB_PATH.APPROVAL,
            element: <AccountingManageStepThird />,
          },
          {
            path: ACCOUNTING_SUB_PATH.TAXBILL,
            element: <AccountingTaxBill />,
          },
          {
            path: ACCOUNTING_SUB_PATH.LIST,
            element: <AccountingList />,
          },
        ],
      },
    ],
  },
  // {
  //   //컴포넌트 테스트 페이지
  //   path: ROUTER_PATH.COMPONENT,
  //   children: [
  //     {
  //       path: '',
  //       element: <ComponentTest />,
  //     },
  //   ],
  // },
  // {
  //   //템플릿 테스트 페이지
  //   path: ROUTER_PATH.TEMPLATE,
  //   children: [
  //     {
  //       path: '',
  //       element: <TemplatePageTest />,
  //     },
  //   ],
  // },

  {
    //(회원가입 / 아이디 비밀번호 찾기 / 정보 수정 / 내 정보) 페이지
    path: BASE_URL,
    children: [
      {
        path: USER_SUB_PATH.REGISTER,
        element: <Register />,
      },
      {
        path: USER_SUB_PATH.FIND,
        element: <Find />,
      },
      {
        path: USER_SUB_PATH.MYINFO,
        element: (
          <ProtectedRoute>
            <MyInfo />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    /* 상단에 위치하는 라우트들의 규칙을 모두확인, 일치하는 라우터가 없는경우 처리 */
    path: '*',
    element: <NotFound />,
  },
];

export default router;
