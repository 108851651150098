import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { startJob } from '@utils';

/**
 * @category useHooks
 * @namespace useInput
 * @param {string} initialValue Input 공통 컴포넌트 사용 시 지정해주는 초기 value.
 * @param {boolean} handleChecked 회원가입: Input 유효성 검사시 결과값을 반환하게 되는데, input값이 바뀌게 되면 이 결과값을 리셋 하기 위한 값
 * @param {function} action 전역 상태 수정을 위한 action 함수.
 * @param {function} onChangefunc Input의 값이 바꼈을때 부가적으로 수행해야하는 함수
 * @returns {value, setValue, onChange}
 * @description Input 공통 컴포넌트에서 사용하는 value, setValue, onChange 출력.
 */

const useInput = (initialValue, handleChecked, action, onChangefunc) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback((e) => {
    //input target name = 수정할 상태의 key
    console.log('onChange', e);
    const payLoad = {
      key: e.target?.name,
      value: e.target?.value,
      checked: e.target?.checked ? 'Y' : 'N',
      id: e.target?.id ?? null,
    };
    console.log('payLoad', payLoad);
    if (action) {
      dispatch(action(payLoad));
    }
    setValue(e.target.value);
    handleChecked && handleChecked(false);

    onChangefunc && onChangefunc();
  }, []);

  return [value, setValue, onChange];
};

export default useInput;
