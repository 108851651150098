import React, { useEffect, useMemo, useCallback } from 'react';
import { useRoutes } from 'react-router-dom';
import router from '@router/router';
import { useSelector, useDispatch } from 'react-redux';
import { PATH_AUTHORITY_MAP } from '@data/listMap';
import { isProduction } from '@utils';
import { getHolidays } from 'redux/action/holidaysAction';
import { getAccessControlList } from 'redux/action/AuthorityAction';
import { setAccessiblePageList } from '@feature/user/userSlice';

const App = () => {
  const dispatch = useDispatch();
  const myLevel = useSelector((state) => state.user?.userData?.authority);
  const accessOriginSetting = useSelector(
    (state) => state.authority.accessOriginSetting
  );

  const myAccessList = useMemo(() => {
    let list = {};
    if (accessOriginSetting.length > 0) {
      const myLevelFlag =
        myLevel === 'LEVEL_1'
          ? 'level1Flag'
          : myLevel === 'LEVEL_2'
            ? 'level2Flag'
            : myLevel === 'LEVEL_3'
              ? 'level3Flag'
              : 'firstLogin';
      accessOriginSetting.forEach((item) => {
        list[item.depth2Desc] = {};
        list[item.depth2Desc] = item[myLevelFlag];
      });
    }
    return list;
  }, [myLevel, accessOriginSetting]);

  const generateRoutesBasedOnPermissions = (routesData, myAccessList) => {
    return routesData.reduce((accumulator, route) => {
      let { path, element, children } = route;

      let shouldAddRoute = true;

      const authorityInfo = PATH_AUTHORITY_MAP[path];

      if (authorityInfo) {
        const hasPermission = myAccessList[authorityInfo] === 'Y';

        const currentDate = new Date().getDate();
        const hasExeption = currentDate >= 26 || currentDate <= 5;
        const exeptionTarget = [
          '업체별 매출 확인',
          '업체별 매출 승인 및 확정',
          '업체별 거래액 송부 및 세금계산서',
        ];

        if (!hasPermission) {
          shouldAddRoute = false;
        } else if (!hasExeption && exeptionTarget.includes(authorityInfo)) {
          //26부터 말일 , 1일부터 5일까지만 접근 가능
          shouldAddRoute = false;
        } else {
          element = React.cloneElement(element, {
            ...element.props,
          });
        }
      }

      if (shouldAddRoute) {
        const routeObject = { path, element };

        if (children && children.length > 0) {
          routeObject.children = generateRoutesBasedOnPermissions(
            children,
            myAccessList
          );
        }

        accumulator.push(routeObject);
      }

      return accumulator;
    }, []);
  };

  useEffect(() => {
    if (Object.keys(myAccessList).length > 0) {
      dispatch(setAccessiblePageList(myAccessList));
    }
    // eslint-disable-next-line
  }, [myAccessList]);

  const productionRouter = useMemo(() => {
    if (Object.keys(myAccessList).length > 0) {
      return generateRoutesBasedOnPermissions(router, myAccessList);
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [myAccessList]);

  const prdRoutes = useRoutes(productionRouter);
  const devRoutes = useRoutes(router);

  useEffect(() => {
    const params = {
      solYear: new Date().getFullYear(),
    };

    getHolidays(dispatch, params);
    getAccessControlList(dispatch);
  }, []);

  const preventContextMenu = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    //브라우저 우클릭 방지 코드
    document.addEventListener('contextmenu', preventContextMenu);
    return () => {
      document.removeEventListener('contextmenu', preventContextMenu);
    };
  }, [preventContextMenu]);

  // return isProduction ? prdRoutes : devRoutes; //devRoutes 전체라우터
  return prdRoutes;
};

export default App;
