import React, { useCallback, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingManageStepThird.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, PopUpAlert, SelectBox } from '@components/index';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import AccountingManageStepFlow from '../module/AccountingManageStepFlow';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSettlementTargetStatusInfoAPI,
  getSettlementTotalAPI,
  getSettlementApprovalStatusAPI,
  postUpdateSettlementApprovalStatusAPI,
} from 'redux/action/settlementAction';
import { useSelect } from '@hooks/index';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const AccountingManageStepThird = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authority = useSelector((state) => state.user.userData.authority);

  const [isOpenNextPopUp, setIsOpenNextPopUp] = useState(false);
  const adminMockState = '승인';
  const vandorMockState = '미승인';

  const vendorList = useSelector((state) => state.common.vendorList);
  const userVendorKey = useSelector((state) => state.user.userData.vendorKey);
  const settlementTotal = useSelector(
    (state) => state.settlement.settlementTotal
  );

  const settlementApprovalStatus = useSelector(
    (state) => state.settlement.settlementApprovalStatus
  );

  const selectVendorList = useMemo(() => {
    const totalSelecteOption = [
      { id: 'NULL', title: '업체를 선택해주세요', vendorKey: '' },
    ];
    vendorList.forEach((vendor) => {
      totalSelecteOption.push({
        id: vendor.companyCode,
        title: vendor.companyName,
        vendorKey: vendor.vendorKey,
      });
    });
    return totalSelecteOption;
  }, [vendorList]);

  const [selectedCompanyIndex, setSelectedCompanyIndex, selecteCompanyLabel] =
    useSelect({
      optionList: selectVendorList,
      initialIndex: 0,
      initialLabel:
        process.env.REACT_APP_MODE === 'LG' ? '업체를 선택해주세요' : 'NULL',
    });
  const handleNavigate = useCallback((path) => {
    navigate(path);
    // eslint-disable-next-line
  }, []);

  const onClickNextConfirm = useCallback(() => {
    handleNavigate('/accounting/taxbill');
  }, []);

  const ApprovalCompleted = useCallback(() => {
    setIsOpenNextPopUp(!isOpenNextPopUp);
  }, []);

  const columnsFOC = useMemo(
    () => [
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정구분</span>,
      }),
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산월</span>,
      }),
      columnHelper.accessor('promotionTypeDesc', {
        id: 'promotionTypeDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('sellCnt', {
        id: 'sellCnt',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출건수</span>,
      }),
      columnHelper.accessor('netTotal', {
        id: 'netTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>공급가액</span>,
      }),
      columnHelper.accessor('salesApproveLevel3', {
        id: 'salesApproveLevel3',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사 승인</span>,
      }),
      columnHelper.accessor('salesApproveAdmin', {
        id: 'salesApproveAdmin',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>LGE 승인</span>,
      }),
    ],
    []
  );
  const columnsNFOC = useMemo(
    () => [
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정구분</span>,
      }),
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산월</span>,
      }),
      columnHelper.accessor('promotionTypeDesc', {
        id: 'promotionTypeDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('sellCnt', {
        id: 'sellCnt',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출건수</span>,
      }),
      columnHelper.accessor('sellTotal', {
        id: 'sellTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>판매총액</span>,
      }),
      columnHelper.accessor('marginTotal', {
        id: 'marginTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>마진총액</span>,
      }),
      columnHelper.accessor('netTotal', {
        id: 'netTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>공급가액</span>,
      }),
      columnHelper.accessor('salesApproveLevel3', {
        id: 'salesApproveLevel3',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사 확정 승인</span>,
      }),
      columnHelper.accessor('salesApproveAdmin', {
        id: 'salesApproveAdmin',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>LGE 확정 승인</span>,
      }),
    ],
    []
  );

  const handleSettlemtApprovalStatus = useCallback(
    (level, changeStatus) => {
      const vendorKey = selectVendorList[selectedCompanyIndex].vendorKey;
      const params = {
        vendorKey:
          process.env.REACT_APP_MODE === 'LG' ? vendorKey : userVendorKey,
        authority: level,
        changeStatus: changeStatus,
      };
      postUpdateSettlementApprovalStatusAPI(dispatch, params);
    },
    [selectVendorList, selectedCompanyIndex, userVendorKey]
  );
  useEffect(() => {
    const params = {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG'
          ? selectVendorList[selectedCompanyIndex].vendorKey
          : userVendorKey,
    };
    if (selectedCompanyIndex !== undefined && selectVendorList) {
      getSettlementTargetStatusInfoAPI(dispatch, params); //상단 대시보드
      getSettlementTotalAPI(dispatch, params);
      getSettlementApprovalStatusAPI(dispatch, params); //승인 상태
    }
  }, [selectedCompanyIndex, selectVendorList, userVendorKey]);

  // const entireAdjustmentLisParams = {
  //   vendorKey: selectVendorList[selectedCompanyIndex].vendorKey,
  //   settlementMonth: convertDateToString3(new Date()),
  // };

  console.log('###settlementTotal', settlementTotal);

  return (
    <>
      <AccountingManageStepFlow />
      <div className={cn('stepGuideText')}>
        3-1. 업체별 매출을 모두 확인해 주시고 이상이 없으면 제휴사와 LG전자 모두
        확인 해주세요.
      </div>
      {process.env.REACT_APP_MODE === 'LG' && (
        <div className={cn('selectBoxContainer')}>
          <SelectBox
            optionList={selectVendorList}
            currentIndex={selectedCompanyIndex}
            handleSelectIndex={setSelectedCompanyIndex}
            currentLabel={selecteCompanyLabel}
            fullWidth
          />
        </div>
      )}
      {selecteCompanyLabel !== '업체를 선택해주세요' && (
        <>
          <div className={cn('title')} style={{ marginTop: '32px' }}>
            <strong>유상</strong>
          </div>
          <TableV8ManualPage
            columns={columnsNFOC}
            data={settlementTotal?.SettlementNFOCTotal}
            csvTitle='내보내기'
          />
          <div className={cn('title')}>
            <strong>무상</strong>
          </div>
          <TableV8ManualPage
            columns={columnsFOC}
            data={settlementTotal?.SettlementFOCTotal}
            csvTitle='내보내기'
          />
          <div className={cn('stepGuideText')}>
            3-2. 이상이 없으면 승인해주세요.
          </div>
          <div className={cn('table_header')}>
            <div className={cn('box_left')}>
              <strong>현재 승인 상태</strong>
            </div>
            <div className={cn('box_right')}>
              {(authority === 'LEVEL_1' || authority === 'LEVEL_3') && (
                <>
                  <Button
                    styleType='btn03'
                    subType='type02'
                    onClick={() =>
                      handleSettlemtApprovalStatus('LEVEL_3', 'APPROVAL')
                    }
                  >
                    제휴사 승인
                  </Button>
                  <Button
                    styleType='btn03'
                    subType='type01'
                    onClick={() =>
                      handleSettlemtApprovalStatus('LEVEL_3', 'CANCEL')
                    }
                  >
                    제휴사 승인 취소
                  </Button>
                </>
              )}
              {(authority === 'LEVEL_1' || authority === 'LEVEL_2') && (
                <>
                  <Button
                    styleType='btn03'
                    subType='type02'
                    onClick={() =>
                      handleSettlemtApprovalStatus('LEVEL_1', 'APPROVAL')
                    }
                  >
                    LG전자 승인
                  </Button>
                  <Button
                    styleType='btn03'
                    subType='type01'
                    onClick={() =>
                      handleSettlemtApprovalStatus('LEVEL_1', 'CANCEL')
                    }
                  >
                    LG전자 승인 취소
                  </Button>
                </>
              )}
            </div>
          </div>
          <ul className={cn('applyStatus')}>
            <li>
              제휴사{' '}
              <strong
                className={cn(
                  settlementApprovalStatus?.level3ApprovalStatus === 'Y'
                    ? 'color-primary'
                    : 'color-red'
                )}
              >
                {settlementApprovalStatus?.level3ApprovalStatus === 'Y'
                  ? '승인'
                  : '미승인'}
              </strong>
            </li>
            <li>
              LG전자{' '}
              <strong
                className={cn(
                  settlementApprovalStatus?.adminApprovalStatus === 'Y'
                    ? 'color-primary'
                    : 'color-red'
                )}
              >
                {settlementApprovalStatus?.adminApprovalStatus === 'Y'
                  ? '승인'
                  : '미승인'}
              </strong>
            </li>
          </ul>
          <div className={cn('stepGuideText')}>
            3-3. 제휴사와 LG전자 모두 승인이 완료되었으면 다음 버튼으로 이동
            해주세요.
          </div>
          {process.env.REACT_APP_MODE === 'LG' && (
            <div className={cn('stepsNav')}>
              <Button
                type='button'
                className={cn('btn', 'btn01', 'type01')}
                onClick={ApprovalCompleted}
                disabled={
                  !(
                    settlementApprovalStatus?.adminApprovalStatus === 'Y' &&
                    settlementApprovalStatus?.level3ApprovalStatus === 'Y'
                  )
                }
              >
                승인 및 확정완료 <i className={cn('ico_next')}></i>
              </Button>
            </div>
          )}
        </>
      )}

      {/* 다음단계로 이동 popUp */}
      <PopUpAlert
        isOpenPopUp={isOpenNextPopUp}
        onRequestClose={() => setIsOpenNextPopUp(false)}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={onClickNextConfirm}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        <div className={cn('nextGuideText')}>
          다음 단계로 이동 시 거래액 확정 후
          <br /> 세금계산서 절차가 진행됩니다.
        </div>
      </PopUpAlert>
    </>
  );
};

export default AccountingManageStepThird;
