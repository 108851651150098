import { useState, useEffect, useMemo } from 'react';
import css from './BusinessCheck.module.scss';
import classNames from 'classnames/bind';
import {
  Button,
  Input,
  VerticalTable,
  VerticalTableContainer,
  PopUpAlert,
  SelectBox,
  CalendarSingle,
} from '@components/index';
import { useInput, useSelect } from '@hooks';
import {
  postResisterBusiness,
  setNewVendor,
} from '@feature/signUp/signUpSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckVendorExist } from 'redux/action/registerAction';
import { getRetrieveVendorList } from 'redux/action/commonAction';
import { dateToStr } from '@utils';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace BusinessCheck
 * @description 회원가입/가입 조회 페이지.
 */

const BusinessCheck = ({ handleClickStepNext }) => {
  const [companyNumber, setCompanyNumber, onChangeCompanyNumber] = useInput('');
  const [companyPerson, setCompanyPerson, onChangeCompanyPerson] = useInput('');
  const [companyBizName, setCompanyBizName, onChangeCompanyBizName] =
    useInput('');

  const [startDate, setStartDate] = useState(new Date());

  const vendorList = useSelector((state) => state.common.vendorList);

  const selectVendorList = useMemo(() => {
    let list = [];

    vendorList.map((vendor) => {
      return list.push({
        id: vendor.companyCode,
        title: vendor.companyName,
        vendorKey: vendor.vendorKey,
      });
    });

    return list;
  }, [vendorList]);

  // SelectBox 동작 -> useSelect hook으로 분리
  const [selectedVendorindex, onSelectVendor, currentLabel] = useSelect({
    optionList: selectVendorList,
    initialIndex: null,
    initialLabel: '제휴사명 선택',
  });

  const selectedVendorInfo = { ...selectVendorList[selectedVendorindex] };

  const [activeSaveBtn, setActiveSaveBtn] = useState(false);

  const dispatch = useDispatch();
  const companyDataObj = useSelector((state) => state.signUp.businessRegister);

  useEffect(() => {
    getRetrieveVendorList(dispatch);
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setNewVendor({
        companyCode: '',
        companyName: '',
        vendorKey: '',
      })
    );
  }, [dispatch]);

  const handleCheckBusiness = () => {
    if (companyNumber && companyPerson && companyBizName) {
      dispatch(
        postResisterBusiness({
          companyRegNo: companyNumber,
          companyOpenDate: dateToStr(startDate),
          companyCeoName: companyPerson,
          companyBizName: companyBizName,
        })
      );
    } else {
      dispatch(
        openModalAlert({ message: '필수 입력 정보를 모두 입력해주세요.' })
      );
    }
  };

  useEffect(() => {
    if (
      companyBizName !== companyDataObj.companyBizName ||
      companyNumber !== companyDataObj.companyRegNo ||
      companyPerson !== companyDataObj.companyCeoName ||
      dateToStr(startDate) !== companyDataObj.companyOpenDate
    ) {
      setActiveSaveBtn(false);
    }
  }, [companyDataObj, companyBizName, companyNumber, companyPerson, startDate]);

  useEffect(() => {
    if (companyDataObj) {
      if (companyDataObj && companyDataObj?.valid === '02') {
        dispatch(openModalAlert({ message: '확인할 수 없습니다.' }));
      } else if (companyDataObj && companyDataObj?.valid === '01') {
        dispatch(openModalAlert({ message: '정상적인 업체 입니다.' }));
        setActiveSaveBtn(true);
      }
    }
  }, [companyDataObj]);

  const handleSave = (e) => {
    const params = {
      companyRegNo: companyNumber.replace(
        /^(\d{3})(\d{2})(\d{5})$/,
        `$1-$2-$3`
      ),
    };
    Object.keys(selectedVendorInfo).length !== 0
      ? getCheckVendorExist(
          dispatch,
          params,
          handleClickStepNext,
          selectedVendorInfo
        )
      : dispatch(openModalAlert({ message: '제휴사명을 선택해주세요.' }));
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  return (
    <>
      <div className={cn('register_input2')}>
        <VerticalTableContainer
          button={[
            <div className={cn('btn_container4')}>
              <div className={css.btn}>
                <Button
                  key='save'
                  onClick={handleSave}
                  disabled={!activeSaveBtn}
                  fullWidth
                >
                  저장
                </Button>
              </div>
            </div>,
          ]}
          isCenter
        >
          <VerticalTable title={'제휴사명'} required>
            <div className={cn('selectbox', 'sizeM')}>
              <SelectBox
                optionList={selectVendorList}
                currentIndex={selectedVendorindex}
                currentLabel={currentLabel}
                handleSelectIndex={onSelectVendor}
                fullWidth
              />
            </div>
            <div className={cn('note')}>
              해당되는 제휴사가 없는 경우 [신규]를 선택해주세요.
            </div>
          </VerticalTable>
          <VerticalTable title={'사업자 등록 번호'} required>
            <Input
              type='text'
              value={companyNumber.replace(/[^0-9]/g, '')}
              name={companyNumber}
              maxLength={10}
              onChange={onChangeCompanyNumber}
              required
              placeholder={'사업자 등록 번호를 입력해 주세요. (-제외)'}
              pattern='^[0-9]+$'
            />
          </VerticalTable>
          <VerticalTable
            isMultiRow={true}
            multiTitle={['상호명', '대표자명']}
            multiItem={[
              <Input
                type='text'
                value={companyBizName}
                name={companyBizName}
                onChange={onChangeCompanyBizName}
                required
                placeholder={'상호명을 입력해주세요.'}
              />,
              <Input
                type='text'
                value={companyPerson}
                name={companyPerson}
                onChange={onChangeCompanyPerson}
                required
                placeholder={'대표자명을 입력해주세요.'}
              />,
            ]}
            required
            hasSlash
          ></VerticalTable>
          <VerticalTable title={'개업 일자'} required>
            <CalendarSingle
              startDate={startDate}
              handleStartDate={handleStartDate}
              setStartDate={setStartDate}
              minDate={null}
            />
          </VerticalTable>
          <VerticalTable>
            <div className={css.flexEnd}>
              <Button
                onClick={handleCheckBusiness}
                styleType={'btn04'}
                subType={'type01'}
              >
                조회
              </Button>
            </div>
          </VerticalTable>
        </VerticalTableContainer>
      </div>
    </>
  );
};
export default BusinessCheck;
