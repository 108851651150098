import React, { useState, useEffect } from 'react';
import css from './RegisterStep.module.scss';
import classNames from 'classnames/bind';

import StepProgressTab from './StepProgressTab/StepProgressTab';
import BusinessCheck from './BusinessCheck/BusinessCheck';
import Terms from './Terms/Terms';
import SignUp from './SignUp/SignUp';
import { history } from '@utils';
import { Link } from 'react-router-dom';
import { ROUTER_PATH } from '@router/routerPath';

const cn = classNames.bind(css);

const RegisterStep = () => {
  const [stepIndex, setStepIndex] = useState(0);

  const handleClickStepNext = () => {
    setStepIndex((prev) => prev + 1);
  };

  const handleClickStepPrev = () => {
    setStepIndex((prev) => (prev !== 0 ? prev - 1 : 0));
  };

  const handleBackButton = () => {
    if (stepIndex !== 0) {
      history.push(ROUTER_PATH.REGISTER);
      handleClickStepPrev();
    } else return;
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.action === 'POP') {
        handleBackButton();
      }
    });

    return () => {
      unlisten();
    };
  }, [stepIndex]);

  return (
    <>
      <div className={cn('registerContainer', 'type02')}>
        <div className={cn('register_header')}>
          <Link to={ROUTER_PATH.HOME}>
            <span className={cn('copyright')}>구독 제휴사 Admin</span>
          </Link>
        </div>
        <div className={cn('register_contents')}>
          <h2 className={cn('pageTitle')}>회원 가입</h2>
          <StepProgressTab stepIndex={stepIndex} />
          {stepIndex === 0 && (
            <BusinessCheck handleClickStepNext={handleClickStepNext} />
          )}
          {stepIndex === 1 && (
            <Terms
              handleClickStepNext={handleClickStepNext}
              handleClickStepPrev={handleClickStepPrev}
            />
          )}
          {stepIndex === 2 && (
            <SignUp handleClickStepPrev={handleClickStepPrev} />
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterStep;
