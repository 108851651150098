import React, { useState, useRef, useEffect, memo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import css from './QuillEditor.module.scss';
import axios from 'axios';
import { backendMode } from '@utils';
import { getUrl } from 'redux/function/TAxiosConfig';
import { useDispatch } from 'react-redux';
import { replaceImgUploadUrl } from '@utils';

Quill.register('modules/imageResize', ImageResize);

const toolbarOptions = [
  ['link', 'image'],
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
];
const modules = {
  toolbar: {
    container: toolbarOptions,
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
  },
};
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
  'color',
  'background',
];

const QuillEditor = ({ action, prevValue }) => {
  const [quillValue, setQuillValue] = useState(prevValue ?? '');
  const dispatch = useDispatch();
  const quillRef = useRef(null);

  const handleQuillChange = (content, delta, source, editor) => {
    setQuillValue(content);
    dispatch(action({ key: 'content', value: content }));
  };

  const uploadCallback = async (file) => {
    const fileName = file.name;
    const upLoadHeader = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const serverType = backendMode();
    const baseUri = '/api/1.0/app/attachment/uploadTemperaryImage';
    const url = getUrl(baseUri, serverType);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const formData = new FormData();
          formData.append('files', file);
          const res = await axios.post(url, formData, upLoadHeader);

          if (res && res.data && res.data.rs && res.data.rs.detailInfo) {
            const imageUrl = res.data.rs.detailInfo[fileName];

            resolve({ url: imageUrl });
          } else {
            reject(new Error('Invalid response format'));
          }
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImage = () => {
    // 이미지 핸들 로직
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const range = quillRef.current.getEditor().getSelection(true);

      try {
        const { url } = await uploadCallback(file);
        const convertCloudFront = replaceImgUploadUrl('S3ToCloudFront', url);
        // 정상적으로 업로드 됐다면 로딩 placeholder 삭제
        quillRef.current.getEditor().deleteText(range.index, 1);
        // 받아온 url을 이미지 태그에 삽입
        quillRef.current
          .getEditor()
          .insertEmbed(range.index, 'image', convertCloudFront);

        // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
        quillRef.current.getEditor().setSelection(range.index + 1);
      } catch (e) {
        quillRef.current.getEditor().deleteText(range.index, 1);
      }
    };
  };
  useEffect(() => {
    if (quillRef.current) {
      const toolbar = quillRef.current.getEditor().getModule('toolbar');
      toolbar.addHandler('image', handleImage);
    }
  }, []);

  return (
    <>
      <div className={css.container}>
        <ReactQuill
          style={{ height: '230px' }}
          ref={quillRef}
          theme='snow'
          modules={modules}
          formats={formats}
          value={quillValue || ''}
          onChange={handleQuillChange}
        />
      </div>
    </>
  );
};

export default React.memo(QuillEditor);
