import React, { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames/bind';
import css from './CouponList.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import TableCheckbox from '@components/Table/TableCheckbox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCouponOverallStatusDashboardAPI,
  getCouponOverallStatusList,
} from 'redux/action/couponAction';
import { FormProvider, useForm } from 'react-hook-form';
import CouponListSearchArea from './modules/CouponListSearchArea';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { convertDateToString2 } from '@utils';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const keywordSearchOptions = {
  guide: '',
  list: [
    { id: '', title: '전체' },
    { id: 'MERCHANDISE_ID', title: '상품명' },
    { id: 'CONTRACT_LINE_NO', title: '계약번호' },
    { id: 'FOC_FLAG', title: '정산' },
    { id: 'ISSUE_DATE', title: '발행일' },
    { id: 'COUPON_STATUS', title: '쿠폰상태' },
    { id: 'VENDOR_NAME', title: '업체명' },
  ],
};

const CouponList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchForms = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedVendorKey = searchParams.get('vendorkey');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const searchType = searchParams.get('searchType');
  const detailCondition = searchParams.get('detailCondition');
  const fromDate = searchParams.get('fromDate');
  const toDate = searchParams.get('toDate');
  const couponStatusList = searchParams.get('couponStatusList');

  const couponOverallStatusList = useSelector(
    (state) => state.coupon?.couponOverallStatusList?.list
  );
  const vendorKey = useSelector((state) => state.user.userData.vendorKey);
  const couponOverallStatusTotalCount = useSelector(
    (state) => state.coupon.couponOverallStatusList?.additionalInfo?.totalCount
  );
  // const [filteredTotalCount, setFilteredTotalCount] = useState(
  //   couponOverallStatusTotalCount
  // );
  const couponOverallStatusDashboard = useSelector(
    (state) => state.coupon.couponOverallStatusDashboard
  );

  //   {
  //     "contractStartDate": "2023-09-07",
  //     "contractEndDate": "2043-09-06",
  //     "customerCode": "1140420169",
  //     "couponNo": "1234-1234-1234-1232",
  //     "vendorName": "대리주부",
  //     "contractStatusDesc": "정상",
  //     "merchandiseId": "ACA0002",
  //     "sendDataToCsmsFlag": "Y",
  //     "modelSuffix": "ADJF-SC01J.AKOR",
  //     "couponStatus": "C002",
  //     "contractGroupNo": "20001098",
  // },

  //     "serviceTimes": "1",
  //     "promotionTypeDesc": "무상",
  //     "useDate": "",
  //     "couponStatusDesc": "쿠폰발행",
  //     "deliveryReqDate": "2023-10-11",
  //     "issueDate": "2024-02-07",
  //     "validDateFrom": "2024-01-12",
  //     "validDateTo": "2029-01-31",
  //     "deliveryPlanNo": "100032333",
  //     "contractLineNo": "20229462",
  //     "serviceModelDesc": "대리주부 청소서비스 할인 쿠폰(3개월 무료)"
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('serviceModelDesc', {
        id: 'serviceModelDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송계획번호</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>회차</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사명</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('couponStatusDesc', {
        id: 'couponStatusDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상태</span>,
      }),
      columnHelper.accessor('validDateFrom', {
        id: 'validDateFrom',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰시작유효기간</span>,
      }),
      columnHelper.accessor('validDateTo', {
        id: 'validDateTo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰종료유효기간</span>,
      }),
      columnHelper.accessor('deliveryReqDate', {
        id: 'deliveryReqDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송예정일</span>,
      }),
      columnHelper.accessor('useDate', {
        id: 'useDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰사용일자</span>,
      }),
    ],
    [couponOverallStatusTotalCount]
  );

  const handlePageCallback = useCallback(
    (pagination) => {
      searchParams.set('page', pagination.pageIndex + 1 ?? '1');
      searchParams.set('limit', pagination.pageSize ?? '100');
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  const callOverallStatusListApi = useCallback((tempParams) => {
    getCouponOverallStatusList(dispatch, tempParams);
  }, []);

  const onSearchClick = useCallback(
    (data) => {
      const {
        keywordSearchCondition,
        keyword,
        subKeywordSearchOptions,
        toDate,
        fromDate,
      } = data;

      const keyArray = [];
      for (let key of searchParams.keys()) {
        keyArray.push(key);
      }
      keyArray.forEach((key) => {
        searchParams.delete(key);
      });

      const hasSubSelectBoxArray = [
        'MERCHANDISE_ID',
        'FOC_FLAG',
        'COUPON_STATUS',
        'VENDOR_NAME',
      ];

      const isSubSelectBox = hasSubSelectBoxArray.includes(
        keywordSearchCondition
      );
      let detailCondition = '';
      let searchType = '';
      if (!searchParams.get('page')) {
        searchParams.set('page', '1');
      }
      if (!searchParams.get('limit')) {
        searchParams.set('limit', '100');
      }

      if (isSubSelectBox) {
        searchParams.set('searchType', keywordSearchCondition ?? '');
        searchParams.set('detailCondition', subKeywordSearchOptions);
        searchType = keywordSearchCondition;
        detailCondition = subKeywordSearchOptions;
      } else {
        searchParams.set('searchType', keywordSearchCondition ?? '');
        searchParams.set('detailCondition', keyword);
        searchType = keywordSearchCondition;
        detailCondition = keyword;
      }

      setSearchParams(searchParams);
      // setFilteredTotalCount(couponOverallStatusList?.length ?? 0);

      // const tempParams = {
      //   searchType: searchType ?? '',
      //   detailCondition: detailCondition ?? '',
      //   vendorKey: subKeywordSearchOptions?.includes('VENDER')
      //     ? subKeywordSearchOptions
      //     : '',
      //   fromDate: convertDateToString2(fromDate),
      //   toDate: toDate
      //     ? convertDateToString2(new Date(toDate))
      //     : convertDateToString2(new Date()),
      //   limit: limit ?? '10',
      //   page: page ?? '1',
      // };

      // callOverallStatusListApi(tempParams);
    },
    [limit, page, searchParams, callOverallStatusListApi]
  );

  useEffect(() => {
    const tempParams = {
      searchType: searchType ?? '',
      detailCondition: detailCondition ?? '',
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' && selectedVendorKey
          ? selectedVendorKey
          : process.env.REACT_APP_MODE === 'LG'
            ? ''
            : vendorKey,
      fromDate: fromDate ?? '2023-01-01',
      toDate: toDate
        ? convertDateToString2(new Date(toDate))
        : convertDateToString2(new Date()),
      couponStatusList: couponStatusList ?? '',
      limit: limit ?? '100',
      page: page ?? '1',
    };
    callOverallStatusListApi(tempParams);
    getCouponOverallStatusDashboardAPI(dispatch, {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' && selectedVendorKey
          ? selectedVendorKey
          : process.env.REACT_APP_MODE === 'LG'
            ? ''
            : vendorKey,
    });
  }, [
    page,
    limit,
    fromDate,
    toDate,
    selectedVendorKey,
    searchType,
    detailCondition,
    couponStatusList,
    callOverallStatusListApi,
  ]);

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, []);

  const handleSearchParams = useCallback(
    (param) => {
      for (let key in param) {
        searchParams.set(key, param[key]);
      }
      const tempParams = {
        searchType: param.searchType ?? '',
        detailCondition: param.detailCondition ?? '',
        fromDate: param.fromDate ?? '2023-01-01',
        toDate: param.toDate
          ? convertDateToString2(new Date(toDate))
          : convertDateToString2(new Date()),
        couponStatusList: param.couponStatusList ?? '',
        vendorKey:
          process.env.REACT_APP_MODE === 'LG' && selectedVendorKey
            ? selectedVendorKey
            : process.env.REACT_APP_MODE === 'LG'
              ? ''
              : vendorKey,
        limit: limit ?? '100',
        page: page ?? '1',
      };

      setSearchParams(searchParams);
      callOverallStatusListApi(tempParams);
    },
    [limit, page, selectedVendorKey, vendorKey]
  );

  const today = new Date();

  return (
    <>
      <h2 className={css.pageTitle}>쿠폰 현황 리스트</h2>

      <div className={css.statusDashBoard}>
        <div className={css.status}>
          <dl>
            <dt>당월 사용완료 확정 쿠폰</dt>
            <dd>
              <div className={css.value}>
                <span
                  className={cn(css.num, css.pointer_cursor)}
                  onClick={() =>
                    handleSearchParams({
                      searchType: 'COUPON_STATUS',
                      detailCondition: 'C004',
                      page: '1',
                      limit: '100',
                      couponStatusList: '',
                      fromDate: convertDateToString2(
                        new Date(today.getFullYear(), today.getMonth(), 1)
                      ),
                      todate: convertDateToString2(new Date()),
                    })
                  }
                >
                  {couponOverallStatusDashboard.couponUseApprovedCnt}
                </span>
                건
              </div>

              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
            <div className={cn('desc')}>
              (당월 사용완료 확정된 난수 번호 수)
            </div>
          </dl>
          <dl>
            <dt>현재 사용 등록 쿠폰</dt>
            <dd>
              <div className={css.value}>
                <span
                  className={cn(css.num, css.pointer_cursor)}
                  onClick={() =>
                    handleSearchParams({
                      searchType: '',
                      detailCondition: '',
                      fromDate: '2023-01-01',
                      todate: convertDateToString2(new Date()),
                      couponStatusList: JSON.stringify(['C003', 'C004']),
                      page: '1',
                    })
                  }
                >
                  {couponOverallStatusDashboard.couponUsedCnt}
                </span>
                건
              </div>
              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
            <div className={cn('desc')}>(누적 된 사용완료 쿠폰 수 )</div>
          </dl>
          <dl>
            <dt>현재 발행 완료된 쿠폰</dt>
            <dd>
              <div className={css.value}>
                <span
                  className={cn(css.num, css.pointer_cursor)}
                  onClick={() =>
                    handleSearchParams({
                      searchType: '',
                      detailCondition: '',
                      fromDate: '2023-01-01',
                      todate: convertDateToString2(new Date()),
                      couponStatusList: JSON.stringify([
                        'C002',
                        'C003',
                        'C004',
                      ]),
                      page: '1',
                    })
                  }
                >
                  {couponOverallStatusDashboard.couponIssuedCnt}
                </span>
                건
              </div>
              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
            <div className={cn('desc')}>(누적 된 발행 쿠폰 수 )</div>
          </dl>
        </div>
      </div>

      <FormProvider {...searchForms}>
        <CouponListSearchArea
          keywordSearchOptions={keywordSearchOptions}
          onSearchClick={onSearchClick}
        />
      </FormProvider>

      <TableV8ManualPage
        columns={columns}
        data={couponOverallStatusList ?? []}
        page={page}
        hasPageSize={false}
        limit={limit ?? undefined}
        totalLength={couponOverallStatusTotalCount}
        handlePageCallback={handlePageCallback}
      />
    </>
  );
};

export default CouponList;
