import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import css from './DeliveryAlarmListTable.module.scss';
import { getRetrieveDeliveryTalkdreamResultDashboardAPI } from 'redux/action/deliveryAction';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateToString2 } from '@utils';

const cn = classNames.bind(css);

const DeliveryAlarmListTable = ({ startDate, endDate }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const tempParams = {
      fromDate: convertDateToString2(startDate),
      toDate: convertDateToString2(endDate),
    };

    getRetrieveDeliveryTalkdreamResultDashboardAPI(dispatch, tempParams);
  }, [dispatch, startDate, endDate]);

  const [initialDashboardData] = useSelector(
    (state) => state.delivery.retrieveDeliveryTalkdreamResultDashboard
  );

  const dashboardData = useMemo(() => {
    if (initialDashboardData) {
      return { ...initialDashboardData, noDataCnt: 0 };
    } else return;
  }, [initialDashboardData]);

  const dashboardDataByType = useMemo(() => {
    const data = [
      {
        type: '배송 안내',
        messageType: '알림톡',
        data: [
          'kakaoDeliveryStartedSuccessedCnt',
          'kakaoDeliveryStartedFailedCnt',
          'lmsDeliveryStartedSuccessedCnt',
          'lmsDeliveryStartedFailedCnt',
          'lmsDeliveryStartedWaitingCnt',
        ],
      },
      {
        type: '배송요청',
        messageType: 'LMS/ 메세지',
        data: [
          'noDataCnt',
          'noDataCnt',
          'lmsDeliveryNewOrderCreatedSuccessedCnt',
          'lmsDeliveryNewOrderCreatedFailedCnt',
          'lmsDeliveryNewOrderCreatedWaitingCnt',
        ],
      },
      {
        type: '상품 준비 완료 요청',
        messageType: 'LMS/ 메세지',
        data: [
          'noDataCnt',
          'noDataCnt',
          'lmsDeliveryUnderPreparedD1SuccessedCnt',
          'lmsDeliveryUnderPreparedD1FailedCnt',
          'lmsDeliveryUnderPreparedD1WaitingCnt',
        ],
      },
      {
        type: '송장 번호 입력 지연',
        messageType: 'LMS/ 메세지',
        data: [
          'noDataCnt',
          'noDataCnt',
          'lmsDeliveryPrepareCompletedD1SuccessedCnt',
          'lmsDeliveryPrepareCompletedD1FailedCnt',
          'lmsDeliveryPrepareCompletedD1WaitingCnt',
        ],
      },
      {
        type: '배송 지연(제휴사)',
        messageType: 'LMS/ 메세지',
        data: [
          'noDataCnt',
          'noDataCnt',
          'lmsDeliveryUnderDeliverdD7SuccessedCnt',
          'lmsDeliveryUnderDeliverdD7FailedCnt',
          'lmsDeliveryUnderDeliverdD7WaitingCnt',
        ],
      },
    ];
    return data;
  }, []);

  const convertZeroToDash = (data) => {
    return data === 0 ? '-' : data;
  };

  return (
    <div className={cn('dataTable01', 'type04')}>
      <table>
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th rowSpan='2'>{'알림 구분'}</th>
            <th rowSpan='2'>{'메세지 유형'}</th>
            <th colSpan='4' className={css.bg_type02}>
              {'전체'}
            </th>
            <th colSpan='2' className={css.bg_type03}>
              {'알림톡'}
            </th>
            <th colSpan='3' className={css.bg_type04}>
              {'LMS'}
            </th>
          </tr>
          <tr>
            <th className={css.bg_type01}>{'발송건수'}</th>
            <th className={css.bg_type01}>{'성공'}</th>
            <th className={css.bg_type01}>{'실패'}</th>
            <th className={css.bg_type01}>{'대기중'}</th>
            <th className={css.bg_type01}>{'성공'}</th>
            <th className={css.bg_type01}>{'실패'}</th>
            <th className={css.bg_type01}>{'성공'}</th>
            <th className={css.bg_type01}>{'실패'}</th>
            <th className={css.bg_type01}>{'대기중'}</th>
          </tr>
        </thead>
        <tbody>
          {dashboardDataByType.map((item, index) => {
            const totalCount =
              dashboardData[item.data[0]] +
              dashboardData[item.data[1]] +
              dashboardData[item.data[2]] +
              dashboardData[item.data[3]] +
              dashboardData[item.data[4]];
            return (
              <tr key={index}>
                <td>{item.type}</td>
                <td>{item.messageType}</td>
                <td>{convertZeroToDash(totalCount)}</td>
                <td>
                  {convertZeroToDash(
                    dashboardData[item.data[0]] + dashboardData[item.data[2]]
                  )}
                </td>
                <td>
                  {convertZeroToDash(
                    dashboardData[item.data[1]] + dashboardData[item.data[3]]
                  )}
                </td>
                <td>{convertZeroToDash(dashboardData[item.data[4]])}</td>
                <td>{convertZeroToDash(dashboardData[item.data[0]])}</td>
                <td>{convertZeroToDash(dashboardData[item.data[1]])}</td>
                <td>{convertZeroToDash(dashboardData[item.data[2]])}</td>
                <td>{convertZeroToDash(dashboardData[item.data[3]])}</td>
                <td>{convertZeroToDash(dashboardData[item.data[4]])}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DeliveryAlarmListTable;
