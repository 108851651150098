import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import css from './Home.module.scss';
import { readLocalStorage, writeLocalStorage, dateToStr } from 'utils/utils';
import { PopUp, PopUpAlert, Button } from '@components/index';
import PartnerList from './modules/PartnerList/PartnerList';
import DetailBoxLarge from './modules/DetailBoxLarge/DetailBoxLarge';
import DetailBoxSmall from './modules/DetailBoxSmall/DetailBoxSmall';
import NoProduct from './modules/NoProduct/NoProduct';
import Notice from './modules/Notice/Notice';
import Terms from '@views/User/RegisterStep/Terms/Terms';
import HomePopUp from 'template/HomePopup/HomePopUp';
import PasswordChange from '@views/User/PasswordChange/PasswordChange';
import { TabMenu } from '@components/index';
import classNames from 'classnames/bind';
import TotalSummary from './modules/TotalSummary/TotalSummary';

// Swiper
import 'swiper/scss';
import { useMemo } from 'react';
import { getMerchandiseDashboard } from 'redux/action/dashBoardAction';
import LGDashBoardDetailSlide from './modules/LGDashBoardDetailSlide/LGDashBoardDetailSlide';
import {
  resetSelectedCompanyName,
  resetSelectedVendorKey,
} from '@feature/dashBoard/dashBoardSlice';

import {
  getDashboardVendorCouponInfo,
  getDashboardVendorMerchandiseInfo,
  getDashboardVendorDeliveryInfo,
} from 'redux/action/dashBoardAction';

const cn = classNames.bind(css);

const tabData = [
  { id: 'tab1', title: '전체', content: '' },
  { id: 'tab2', title: '제휴사', content: '' },
];

const Home = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState(generateMockData(10));
  const navigate = useNavigate(); // 라우터 이동, 라우터 이동시 원하는 데이터 전송가능, 히스토리 관리
  const location = useLocation(); // pathName 비롯한 searchParams 정보
  const params = useParams(); // searchParams get Params 구조분해 할당해서 상태로 사용 가능
  const userData = useSelector((state) => state.user.userData);
  const vendorList = useSelector((state) => state.common.vendorList);
  const vendorMerchandiseInfo = useSelector(
    (state) => state.dashBoard.dashboardVendorMerchandiseInfo
  );

  const [isOpenHomePopUp, setIsOpenHomePopUp] = useState(false);
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [IsPopupReady, setIsPopupReady] = useState(false);
  const popupList = useSelector((state) => state.notice.popupContents);
  const [activeDetailSlideTab, setActiveDetailSlideTab] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [isOpenPasswordChangePopUp, setIsOpenPasswordChangePopUp] =
    useState(false);

  const handlePasswordChangePopUpClose = () => {
    setIsOpenPasswordChangePopUp(false);
  };

  const selectedVendorKey = useSelector(
    (state) => state.dashBoard.selectedVendorKey
  );
  const vendorKey = useSelector((state) => state.user.userData.vendorKey);
  const dashboardVendorCouponInfo = useSelector(
    (state) => state.dashBoard.dashboardVendorCouponInfo
  );

  useEffect(() => {
    getDashboardVendorCouponInfo(dispatch, {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' ? selectedVendorKey : vendorKey,
    });
    getDashboardVendorMerchandiseInfo(dispatch, {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' ? selectedVendorKey : vendorKey,
    });
    getDashboardVendorDeliveryInfo(dispatch, {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' ? selectedVendorKey : vendorKey,
    });
  }, [selectedVendorKey, vendorKey]);

  useEffect(() => {
    setActiveTab(location?.state?.tabIndex ?? 'tab1');
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (userData.passwordChangeRequired === 'true') {
      setIsOpenPasswordChangePopUp(true);
    }
  }, [userData]);

  const dashboardCouponInfo = useSelector(
    (state) => state.dashBoard.dashboardCouponInfo
  );

  const dashboardMerchandiseInfo = useSelector(
    (state) => state.dashBoard.dashboardMerchandiseInfo
  );

  const partnerListCount = useMemo(() => {
    let vendorObj = {};
    if (vendorList.length > 0) {
      vendorList.forEach((vendor) => {
        if (!vendorObj[vendor.companyName]) {
          vendorObj[vendor.companyName] = {};
        }
      });
    }
    //TODO: 환불 정산 배송 대시보드 API 나오면 아래 반영해야함
    dashboardCouponInfo.totalUnhandledInfo?.forEach((coupon) => {
      if (vendorObj[coupon.companyName]) {
        vendorObj[coupon.companyName].unhandledCouponCount = coupon.cnt;
      }
    });
    dashboardMerchandiseInfo.merchandiseUnderApprovalInfo?.forEach(
      (merchandise) => {
        if (vendorObj[merchandise.companyName]) {
          vendorObj[merchandise.companyName].unhandledMerchandiseCount =
            merchandise.cnt;
        }
      }
    );
    if (Object.keys(vendorObj).length > 0) {
      Object.keys(vendorObj).forEach((key) => {
        vendorObj[key].totalCnt = Object.values(vendorObj[key]).reduce(
          (acc, cur) => acc + cur,
          0
        );
      });
    }

    return vendorObj;
  }, [vendorList, dashboardCouponInfo, dashboardMerchandiseInfo]);

  // TODO: 약관동의 Api 추가되면 의존성 배열에 상태 추가
  // useEffect(() => {
  //   setIsOpenTerms(true);
  // }, []);

  const handleNavigate = useCallback((path) => {
    navigate(path);
    // eslint-disable-next-line
  }, []);

  // 엑셀 다운로드 링크
  function generateMockData(count) {
    const mockData = [];
    for (let i = 1; i <= count; i++) {
      mockData.push({
        Name: `User ${i}`,
        Email: `user${i}@example.com`,
        Age: Math.floor(Math.random() * 50) + 20,
      });
    }
    return mockData;
  }

  const handleDowload = async () => {
    const filename = 'mock_data';
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    if (data.length > 0) {
      const header = Object.keys(data[0]);
      worksheet.columns = header.map((header) => ({
        // 제목
        header,
        // 이 키를 이용하여 데이터를 올바른 열에 넣음
        key: header,
        // 간격
        width: 20,
      }));
      data.forEach((item) => {
        //addRow: 새로운 행을 추가하고 데이터를 해당 행에 매핑
        worksheet.addRow(item);
      });
    }

    //Blob으로 변환하고 파일로 다운로드
    const blob = await workbook.xlsx.writeBuffer();
    const file = new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(file, filename);
  };

  useEffect(() => {
    const toDay = new Date();
    const todayStr = dateToStr(toDay);
    const popupStorage = readLocalStorage('popupStorage');
    setIsPopupReady(false);
    if (popupList && Object.keys(popupList)?.length > 0) {
      if (popupStorage === null) {
        const popupItems = { [todayStr]: popupList };
        writeLocalStorage('popupStorage', popupItems);
        setIsPopupReady(true);
      } else {
        let willUpdateItem = {};
        willUpdateItem[todayStr] = {};

        Object.keys(popupStorage).forEach((key) => {
          if (key !== todayStr) {
            delete popupStorage[key];
            popupStorage[todayStr] = {};
            popupStorage[todayStr][popupList.noticeKey] = {};
            popupStorage[todayStr][popupList.noticeKey] = popupList;
            writeLocalStorage('popupStorage', popupStorage);
            setIsPopupReady(true);
          } else {
            for (const noticeKey in popupStorage[todayStr]) {
              if (noticeKey === popupList.noticeKey) {
                const notPopupToday =
                  popupStorage[todayStr][noticeKey].notPopupToday;
                const updateItem = Object.assign(
                  {},
                  popupStorage[todayStr][noticeKey],
                  popupList
                );
                const mergeItem = Object.assign({}, updateItem, {
                  notPopupToday: notPopupToday,
                });
                delete popupStorage[todayStr][noticeKey];
                popupStorage[todayStr][noticeKey] = mergeItem;

                writeLocalStorage('popupStorage', popupStorage);
                if (!notPopupToday) {
                  setIsPopupReady(true);
                }
              } else {
                delete popupStorage[todayStr][noticeKey];
                popupStorage[todayStr][popupList.noticeKey] = popupList;
                writeLocalStorage('popupStorage', popupStorage);
                setIsPopupReady(true);
              }
            }
          }
        });
      }
    }
  }, [popupList]);

  const openHomePopUp = useCallback(() => {
    setIsOpenHomePopUp(true);
  }, []);
  const closeHomePopUp = useCallback(() => {
    setIsOpenHomePopUp(false);
  }, []);

  const targetPopup = useMemo(() => {
    let ret = false;
    if (IsPopupReady) {
      const toDay = new Date();
      const todayStr = dateToStr(toDay);
      const popupStorage = readLocalStorage('popupStorage');
      if (!popupStorage[todayStr][popupList.noticeKey]?.notPopupToday)
        ret = popupStorage[todayStr][popupList.noticeKey];
      if (typeof ret !== 'object') {
        setIsPopupReady(false);
      } else {
        openHomePopUp();
      }
    }
    return ret;
  }, [IsPopupReady, popupList]);

  const partnerList = useMemo(() => {
    let resultArr = [];
    if (vendorList?.length > 0) {
      const filteredVendorList = vendorList.filter(
        (item) => item.companyCode !== 'LGE' && item.companyCode !== 'NEW'
      );
      resultArr = filteredVendorList.map((item) => {
        return Object.assign({}, item, {
          title: item.companyName,
          // Todo: 임시 주석 처리 원복 예정
          confirm: `미처리(${partnerListCount[item.companyName].totalCnt})`,
          // status: partnerListCount[item.companyName].totalCnt,
        });
      });
    }
    return resultArr;
  }, [vendorList, partnerListCount]);

  const TOTALSUMMARY = [
    {
      id: 0,
      title: '상품관리',
      num: 1,
      supportStatus: true,
      key: 'merchandiseUnderApprovalCnt',
    },
    {
      id: 1,
      title: '쿠폰관리',
      num: 2,
      supportStatus: true,
      key: 'couponManagementCnt',
    },
    {
      id: 2,
      title: '배송관리',
      num: 3,
      supportStatus: true,
      key: 'deliveryManagementCnt',
    },
    {
      id: 3,
      title: '정산관리',
      num: '-',
      supportStatus: false,
      key: 'settlementManagementCnt',
    },
    {
      id: 4,
      title: '환불관리',
      num: '-',
      supportStatus: false,
      key: 'refundManagementCnt',
    },
  ];

  useEffect(() => {
    const params = {
      authority: userData.authority,
      vendorKey: userData.vendorKey,
    };

    getMerchandiseDashboard(dispatch, params);
  }, [userData, selectedIndex]);

  const activeDetailSlideTabCallback = useCallback((index) => {
    setActiveDetailSlideTab(index);
  }, []);

  useEffect(() => {
    dispatch(resetSelectedCompanyName());
    dispatch(resetSelectedVendorKey());
  }, []);

  return (
    <div className={css.container}>
      <div className={css.date_nav}>
        {/* 처리대기 상태요약 영역 */}
        {/* 제휴사 리스트 영역  */}
        {process.env.REACT_APP_MODE === 'VENDOR' && (
          <>
            {vendorMerchandiseInfo.activatedCnt > 0 ? (
              // 컴포넌트화 필요할지도

              <div className={css.main_dashboard}>
                <div className={css.flex}>
                  <DetailBoxLarge type={'delivery'} />
                  <DetailBoxLarge type={'coupon'} />
                </div>
                <div className={css.flex}>
                  <DetailBoxSmall title={'상품 관리'} />
                  <DetailBoxSmall title={'환불 관리'} />
                  <DetailBoxSmall title={'정산 관리'} />
                </div>
              </div>
            ) : (
              <NoProduct />
            )}
          </>
        )}
        {process.env.REACT_APP_MODE === 'LG' && (
          <>
            <div className={cn('tabMenu', 'tabMenu01', 'type01', 'mt20')}>
              <div className={css.list}>
                <TabMenu
                  tabData={tabData}
                  type02={true}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
              <div className={css.cont_area}>
                <div className={css.cont} style={{ display: 'inline-block' }}>
                  {activeTab === 'tab1' ? (
                    <div>
                      <TotalSummary
                        slideList={TOTALSUMMARY}
                        activeDetailSlideTabCallback={
                          activeDetailSlideTabCallback
                        }
                      />
                      <LGDashBoardDetailSlide
                        activeDetailSlideTab={activeDetailSlideTab}
                      />
                      {/* <h3 className={css.areaTitle}>배송 관리</h3>
                      <div className={css.shippingManage}>
                        {TEST_SWIPER.map((rowItem, rowIndex) => {
                          return (
                            <div className={css.row}>
                              <div className={css.titleArea}>
                                <dl>
                                  <dt>{rowItem.title}</dt>
                                  {rowItem.subTitle && (
                                    <span className={css.type_color_red}>
                                      {rowItem.subTitle}
                                    </span>
                                  )}
                                  <dd>
                                    <span className={css.num}>
                                      {rowItem.total}
                                    </span>
                                    건
                                  </dd>
                                </dl>
                              </div>
                              <Swiper
                                className={cn('swiper-container')}
                                slidesPerView={'auto'}
                                spaceBetween={20}
                                breakpoints={{
                                  1700: {
                                    slidesPerView: 3,
                                  },
                                }}
                                onSwiper={(swiper) =>
                                  console.log('swiper', swiper)
                                }
                              >
                                {rowItem.slideItem.map((slide, index) => {
                                  return (
                                    <SwiperSlide
                                      key={slide.itemTitle + index}
                                      className={cn('swiper-slide')}
                                    >
                                      <div className={css.link}>
                                        <dl>
                                          <dt>{slide.itemTitle}</dt>
                                          <dd className={css.value}>
                                            <span className={css.num}>
                                              {slide.num}
                                            </span>
                                            건
                                          </dd>
                                          {slide.badge && (
                                            <dd className={css.notConnected}>
                                              {slide.badge}
                                            </dd>
                                          )}
                                          {slide.d_day && (
                                            <dd className={css.d_day}>
                                              {slide.d_day}
                                              <span>{slide.d_day_count}</span>
                                            </dd>
                                          )}
                                        </dl>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </div>
                          );
                        })}
                      </div> */}
                    </div>
                  ) : (
                    // 컴포넌트화 필요할지도
                    <div>
                      {/* <StatusSummary /> */}
                      <PartnerList
                        slideList={partnerList}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                      />
                      <div className={css.main_dashboard}>
                        <div className={css.flex}>
                          <DetailBoxLarge type={'delivery'} />
                          <DetailBoxLarge />
                        </div>
                        <div className={css.flex}>
                          {/* 제휴사 별 데이터 필요 */}
                          <DetailBoxSmall title={'상품 관리'} />
                          <DetailBoxSmall title={'환불 관리'} />
                          <DetailBoxSmall title={'정산 관리'} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <Notice />
      </div>

      {/* Notice popUP */}
      <PopUpAlert
        onRequestClose={() => setIsOpenHomePopUp(false)}
        closeButton
        isOpenPopUp={isOpenHomePopUp}
        button={[
          <Button key='firstButton' onClick={closeHomePopUp}>
            확인
          </Button>,
        ]}
      >
        <HomePopUp targetPopup={targetPopup} openHomePopUp={openHomePopUp} />
      </PopUpAlert>
      {/* passwordChange popUP */}
      <PopUp isOpenPopUp={isOpenPasswordChangePopUp} closeButton={false}>
        <PasswordChange
          handlePasswordChangePopUpClose={handlePasswordChangePopUpClose}
        />
      </PopUp>

      {/* TODO: 약관 변경 되었을 때 이미 회원 가입한 유저에게 보여주기 (미정) */}
      <PopUp
        title='약관동의 팝업(약관이 변경 되었을 때)'
        isOpenPopUp={isOpenTerms}
        closeButton={false}
      >
        <Terms setIsOpenTerms={setIsOpenTerms} isPopUpMode />
      </PopUp>
    </div>
  );
};

export default Home;
