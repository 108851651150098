import css from './HFCheckbox.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace HFCheckbox
 * @param {string} name
 * @description React-hook-form 사용 시 사용되는 HFCheckbox
 */
const HFCheckbox = ({
  name,
  register,
  required,
  disabled,
  id,
  className,
  children,
  ...rest
}) => {
  return (
    <div className={cn('checkbox', className)}>
      <input
        id={id}
        type='checkbox'
        {...register(name, { required, disabled })}
        {...rest}
      />
      <label htmlFor={id} className={css.label}>
        <span>{children}</span>
      </label>
    </div>
  );
};

export default HFCheckbox;
