import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import css from './TotalSummary.module.scss';
import classNames from 'classnames/bind';
import img_slogan from '@assets/img/img_slogan.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { register } from 'swiper/element/bundle';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '@router/routerPath';
import { useDispatch, useSelector } from 'react-redux';
import { MakeTwoDigitDate } from '@utils';
import { getDashboardMainPanelInfo } from 'redux/action/dashBoardAction';

register();

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace PartnerList
 * @description Home 화면에서 사용하는 PartnerList 컴포넌트.
 */

// swiper 커스텀 버튼(이전)
const CustomPrevButton = ({ disabled }) => {
  return (
    <button
      className={cn('customPrevButton', { disabled })}
      disabled={disabled}
    ></button>
  );
};

// swiper 커스텀 버튼(다음)
const CustomNextButton = ({ disabled }) => {
  return (
    <button
      className={cn('customNextButton', { disabled })}
      disabled={disabled}
    ></button>
  );
};

const TotalSummary = ({ slideList, activeDetailSlideTabCallback }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardMainPanel = useSelector(
    (state) => state.dashBoard.dashboardMainPanel
  );
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setIsPrevButtonDisabled(swiper.isBeginning);
    setIsNextButtonDisabled(swiper.isEnd);
  };
  const handleNavigate = useCallback((path, state) => {
    if (state) {
      navigate(path, { state: state });
    } else {
      navigate(path);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_MODE === 'LG') {
      getDashboardMainPanelInfo(dispatch);
    }
  }, []);

  return (
    <div className={css.partner_list}>
      <div className={css.guideContainer}>
        <h3>미처리 건수</h3>
        <span>(숫자부분을 클릭해주세요)</span>
      </div>
      <div className={css.confirmRequest}>
        <dl>
          <dt>권한 승인 요청</dt>
          <dd>
            {/* <i className={css.ico_act_type01}></i> */}
            <div>
              <span>계정관리 제휴사 </span>
              <span
                className={css.num}
                onClick={() => {
                  handleNavigate(
                    `${ROUTER_PATH.AUTHORITY}?approvalStatusAccount=UNDER_APPROVAL`,
                    {
                      tab: 'tab1',
                    }
                  );
                }}
              >
                {dashboardMainPanel.vendorAccountApprovalRequestedCnt}
              </span>
              건
            </div>
            <div>
              <span>계정관리 LG </span>
              <span
                className={css.num}
                onClick={() => {
                  handleNavigate(
                    `${ROUTER_PATH.AUTHORITY}?approvalStatusAccount=UNDER_APPROVAL`,
                    {
                      tab: 'tab2',
                    }
                  );
                }}
              >
                {dashboardMainPanel.internalAccountApprovalRequestedCnt}
              </span>
              건
            </div>
            <div>
              <span>계정관리 사업자 </span>
              <span
                className={css.num}
                onClick={() => {
                  handleNavigate(
                    `${ROUTER_PATH.AUTHORITY}?approvalStatusAccount=UNDER_APPROVAL`,
                    {
                      tab: 'tab3',
                    }
                  );
                }}
              >
                {dashboardMainPanel.vendorMasterApprovalRequestedCnt}
              </span>
              건
            </div>
          </dd>
        </dl>
      </div>
      <Swiper
        className={css.swiper}
        modules={[Navigation]}
        slidesPerView={'auto'}
        spaceBetween={20}
        navigation={{
          prevEl: `.${css.customPrevButton}`,
          nextEl: `.${css.customNextButton}`,
        }}
        breakpoints={{
          1700: {
            slidesPerView: 3,
          },
        }}
        // onSwiper={(swiper) => console.log('swiper', swiper)}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
        <div className={cn('swiper_wrapper')}>
          {slideList.map((slide, index) => {
            return (
              <SwiperSlide
                key={slide.title}
                className={cn(
                  'swiper-slide',
                  selectedIndex === index && slide.supportStatus
                    ? 'active'
                    : !slide.supportStatus
                      ? 'disabled'
                      : ''
                )}
              >
                <button
                  className={css.card}
                  onClick={() => {
                    if (slide.supportStatus) {
                      handleNavigate(slide.path);
                      setSelectedIndex(index);
                      activeDetailSlideTabCallback(index);
                    } else {
                      return null;
                    }
                  }}
                >
                  <dl>
                    <dt>{slide.title}</dt>
                    <dd>
                      <span className={css.num}>
                        {dashboardMainPanel[slide.key]}
                      </span>
                      건
                    </dd>
                    {!slide.supportStatus && `(개발중)`}
                  </dl>
                </button>
              </SwiperSlide>
            );
          })}
        </div>
        <CustomPrevButton disabled={isPrevButtonDisabled} />
        <CustomNextButton disabled={isNextButtonDisabled} />
      </Swiper>
    </div>
  );
};

TotalSummary.defaultProps = {
  slideList: [],
};

export default memo(TotalSummary);
