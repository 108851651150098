import { memo, useState, useCallback, useMemo } from 'react';
import css from './Gnb.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import {
  LEVEL_AUTHORITY_MAP,
  TAB_MAP,
  TOTAL_PAGE_MAP,
  AUTHORITY_TAB,
} from '@data/listMap';
import { useSelector } from 'react-redux';
import { isProduction } from '@utils';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Gnb
 * @description DashboardLayout에서 사용하는 Gnb 컴포넌트.
 */

const Gnb = () => {
  const navigate = useNavigate();
  const accessiblePageList = useSelector(
    (state) => state.user.accessiblePagelist
  );
  const [hoveredItem, setHoveredItem] = useState(null);

  const tab = useMemo(() => {
    let permissionedPage = {};
    if (Object.keys(accessiblePageList).length > 0) {
      const addPageToRet = (key) => {
        if (TAB_MAP[key]) {
          if (!permissionedPage[TAB_MAP[key].title]) {
            permissionedPage[TAB_MAP[key].title] = [];
          }
          permissionedPage[TAB_MAP[key].title].push({
            title: TAB_MAP[key].subTitle,
            path: TAB_MAP[key].path,
            state: TAB_MAP[key]?.state ?? { state: null },
          });
        }
      };
      for (let key in accessiblePageList) {
        if (accessiblePageList[key] === 'Y') {
          addPageToRet(key);
        }
      }
      if (process.env.REACT_APP_MODE === 'LG') {
        permissionedPage = { ...permissionedPage, ...AUTHORITY_TAB };
      }
    }
    // return isProduction ? permissionedPage : TOTAL_PAGE_MAP;
    return permissionedPage;
  }, [accessiblePageList]);

  const handleNavigate = useCallback((path, state) => {
    navigate(path, state);
  }, []);

  const handleMouseOver = useCallback((mainTabIndex) => {
    setHoveredItem(mainTabIndex);
  }, []);

  const DefaultSubTab = () => {
    return (
      <ul className={css.depth2_wrap}>
        <li className={css.default}>invisible</li>
      </ul>
    );
  };

  const SelectedSubTab = ({ mainTab, handleNavigate }) => {
    return (
      <ul className={css.depth2_wrap}>
        {tab &&
          tab[mainTab].map((subTab, subIndex) => {
            return (
              <li
                key={`subList-${subIndex}`}
                className={css.depth2}
                onClick={() => handleNavigate(subTab.path, subTab.state)}
              >
                <div>{subTab.title}</div>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div className={css.gnb}>
      <ul className={css.space}>
        {Object.keys(tab).map((mainTab, mainTabIndex) => {
          return (
            <li key={`topList-${mainTabIndex}`} className={css.depth1}>
              <div
                className={css.topMenuTitle}
                onMouseOver={() => handleMouseOver(mainTabIndex)}
              >
                {mainTab}
              </div>
              {hoveredItem === null ? (
                <DefaultSubTab />
              ) : hoveredItem === mainTabIndex ? (
                <SelectedSubTab
                  mainTab={mainTab}
                  handleNavigate={handleNavigate}
                />
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default memo(Gnb);
