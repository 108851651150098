import React from 'react';
import css from './RegisterContainer.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(css);

/**
 * @category components
 * @namespace RegisterContainer
 * @param {any} children RegisterContainer 자식 요소.
 * @description Register/Login, Find 페이지에서 사용하는 RegisterContainer 컴포넌트.
 */

const RegisterContainer = ({ children }) => {
  return (
    <div id={css.wrap} className={cn('has-no-header', 'registerWrap')}>
      <div id={css.container}>{children}</div>
    </div>
  );
};

export default RegisterContainer;
