import React, { useCallback, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import css from './RefundCustomer.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import RefundCustomerSearchArea from './module/RefundCustomerSearchArea';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import TableCheckbox from '@components/Table/TableCheckbox';
import { Button, SelectBox } from '@components/index';
import { MODIFIED_COUPON_Type, MODIFIED_SHIPPING_Type } from '@data/Const';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomerRefundDashBoardCount,
  getCustomerRefundList,
  postUpdateCustomerCouponStatus,
} from 'redux/action/refundAction';
import { convertDateToString2 } from 'utils/utils';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { useSearchParams } from 'react-router-dom';
import { openModalAlert } from '@feature/common/commonSlice';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const RefundCustomer = () => {
  const dispatch = useDispatch();
  const searchForms = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = useSelector((state) => state.user.userData.userId);
  const refundCustomerList = useSelector((state) => state.refund.contents);
  const dashboardList = useSelector(
    (state) => state.refund.customerRefundDashboard
  );
  const [selectedKeys, setSelectedKeys] = useState(0);
  const { watch } = searchForms;
  const [fromDate, toDate] = watch(['fromDate', 'toDate']);
  const { handleSubmit: handleSubmit, control } = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  const defaultCategory =
    'REFUND_TARGET / COUPON_REFUND_TARGET / DELIVERY_REFUND_TARGET / DELIVERY_REFUND_APPROVED / COUPON_REFUND_APPROVED';

  const selectedVendorKey = searchParams.get('vendorkey');
  const selectedCategory = searchParams.get('category');

  const onChangeSelectedKeys = useCallback((keys) => {
    setSelectedKeys(keys);
  }, []);

  // 화면 진입시 기본값 세팅값
  useEffect(() => {
    getCustomerRefundDashBoardCount(dispatch, {
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
      vendorKey: '',
      category: defaultCategory,
    });
  }, []);

  useEffect(() => {
    getCustomerRefundList(dispatch, {
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
      vendorKey: selectedVendorKey ? selectedVendorKey : '',
      category: selectedCategory ? selectedCategory : 'REFUND_TARGET',
      limit: '100',
      page: '1',
    });
  }, []);

  // 검색 버튼 or 대시보드 클릭시 변경 되는 값 세팅
  const countParams = useMemo(() => {
    const result = {
      fromDate: convertDateToString2(fromDate),
      toDate: convertDateToString2(toDate),
      vendorKey: '',
      category: defaultCategory,
    };
    return result;
  }, [fromDate, toDate]);

  const listParams = useCallback(
    (category) => {
      const result = {
        fromDate: convertDateToString2(fromDate),
        toDate: convertDateToString2(toDate),
        category: category ?? 'REFUND_TARGET',
        vendorKey: selectedVendorKey ? selectedVendorKey : '',
        limit: '100',
        page: '1',
      };
      return result;
    },
    [fromDate, toDate]
  );

  const changeListOptions = useCallback((Type) => {
    let options = {
      guide: '변경할 값을 선택해주세요',
      list: [],
    };

    if (Type === 'C') {
      options.list = Object.entries(MODIFIED_COUPON_Type).map(
        ([id, title]) => ({
          id,
          title,
        })
      );
    } else {
      options.list = Object.entries(MODIFIED_SHIPPING_Type).map(
        ([id, title]) => ({
          id,
          title,
        })
      );
    }

    return options;
  }, []);

  // 대시보드 클릭시 실행되는 함수
  const onClickDashBoardCount = useCallback(
    (category) => {
      getCustomerRefundList(dispatch, listParams(category));
    },
    [fromDate, toDate]
  );

  // 검색버튼 클릭시 실행되는 함수
  const onSearchClick = useCallback(() => {
    getCustomerRefundList(dispatch, listParams());
    getCustomerRefundDashBoardCount(dispatch, countParams);
  }, [listParams]);

  const updateCustomerCouponStatus = useCallback(
    (data) => {
      if (selectedKeys.length === 0) {
        dispatch(openModalAlert({ message: '변경 할 항목을 선택해주세요.' }));
      } else {
        const resultArray = [];
        for (const key in data) {
          if (data[key] !== undefined) {
            const newItem = {
              deliveryPlanNo: key.toString(),
              targetStatus: data[key],
            };
            resultArray.push(newItem);
          }
        }

        const resultJSONString = JSON.stringify(resultArray);

        const updateStatusParams = {
          userId: userId,
          updateTargetList: resultJSONString,
        };
        postUpdateCustomerCouponStatus(dispatch, updateStatusParams);
      }
    },
    [selectedKeys]
  );

  const renderSelectBox = useCallback(
    (row, Type) => {
      if (row.original.changeStatus === 'CHANGE_COMPLETED') {
        return null;
      } else {
        return (
          <HFSelectBox
            control={control}
            name={row.original.deliveryPlanNo}
            options={
              Type === 'C' ? changeListOptions('C') : changeListOptions('D')
            }
            type={'type04'}
            disabled={!row.getIsSelected()}
          />
        );
      }
    },
    [control, changeListOptions]
  );

  const columns = useMemo(
    () => [
      {
        id: 'select',
        // header: ({ table }) => {
        //   return (
        //     <TableCheckbox
        //       className={css.headerInput}
        //       id={'LG-auth-table-select-header'}
        //       checked={table.getIsAllRowsSelected()}
        //       indeterminate={table.getIsSomeRowsSelected()}
        //       onChange={table.getToggleAllRowsSelectedHandler()}
        //     />
        //   );
        // },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약 라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송 예정번호</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>회차</span>,
      }),
      columnHelper.accessor('serviceModelDesc', {
        id: 'serviceModelDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>회사명</span>,
      }),
      {
        id: 'statusCode',
        header: () => {
          return '배송 / 쿠폰코드';
        },
        cell: ({ row }) => {
          if (row.original.serviceType === 'C') {
            return row.original.couponStatus;
          } else {
            return row.original.deliveryStatus;
          }
        },
      },

      {
        id: 'changeStatusCode',
        header: () => {
          return '변경 배송/쿠폰코드';
        },
        cell: ({ row }) => {
          return renderSelectBox(row, row.original.serviceType);
        },
      },
    ],
    [renderSelectBox]
  );
  return (
    <>
      <h2 className={cn('pageTitle')}>고객 요청 환불</h2>
      <div className={cn('statusDashBoard')}>
        <div className={cn('status')}>
          <dl>
            <dt>
              환불 검토 중
              <p className={cn('note', 'color-red')}>환불 요청 건</p>
            </dt>
            <dd>
              <div className={cn('value')}>
                <span
                  className={cn('num', 'pointer')}
                  onClick={() => onClickDashBoardCount('REFUND_TARGET')}
                >
                  {dashboardList?.refundOngoingCount ?? 0}
                </span>
                건
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              배송 환불 승인
              <p className={cn('note', 'color-red')}>환불 승인 처리된 건</p>
            </dt>
            <dd>
              <div className={cn('value')}>
                <span
                  className={cn('num', 'pointer')}
                  onClick={() =>
                    onClickDashBoardCount('DELIVERY_REFUND_APPROVED')
                  }
                >
                  {dashboardList?.refundCompletedDeliveryCount ?? 0}
                </span>
                건
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              쿠폰 환불 승인
              <p className={cn('note', 'color-red')}>환불처리 완료 건</p>
            </dt>
            <dd>
              <div className={cn('value')}>
                <span
                  className={cn('num', 'pointer')}
                  onClick={() =>
                    onClickDashBoardCount('COUPON_REFUND_APPROVED')
                  }
                >
                  {dashboardList?.refundCompletedCouponCount ?? 0}
                </span>
                건
              </div>
            </dd>
          </dl>
        </div>
      </div>

      <FormProvider {...searchForms}>
        <RefundCustomerSearchArea onSearchClick={onSearchClick} />
      </FormProvider>

      <TableV8ManualPage
        columns={columns}
        data={refundCustomerList}
        getRowselected={onChangeSelectedKeys}
        // page={1}
        // limit={5}
        hasCsvDownload={false}
        rightControls={
          <Button
            styleType={'btn03'}
            subType={'type01'}
            className={cn('mt10')}
            onClick={handleSubmit(updateCustomerCouponStatus)}
          >
            변경/저장하기
          </Button>
        }
      />
    </>
  );
};

export default RefundCustomer;
