import { TAxios } from 'redux/function/TAxios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDeliveryStatusDashboard,
  getDeliveryStatusList,
  getDeliveryStatusListDetails,
  getRetrieveDreamTalkdreamResultList,
  getRetrieveDreamTalkdreamResultDetailList,
  getRetrieveDeliveryTalkdreamResultDashboard,
  getDeliveryCompanyList,
} from '@feature/delivery/deliverySlice';
import { openModalAlert } from '@feature/common/commonSlice';
import axios from 'axios';
import { getUrl } from 'redux/function/TAxiosConfig';
import { backendMode, wait, createQueryString } from '@utils';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

/**
 * @category Action
 * @namespace getRetrieveDeliveryStatusDashboard
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 주문배송처리 / 배송현황 리스트(대시보드)
const getRetrieveDeliveryStatusDashboard = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/retrieveDeliveryStatusDashboard';

  const onSuccess = (response) => {
    const dashBoard = response.data?.rs?.dashboardInfo;
    dispatch(getDeliveryStatusDashboard(dashBoard));
  };
  const onFail = (response) => {
    console.log('retrieveDeliveryStatusDashboard onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getRetrieveDeliveryStatusList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 주문배송처리 / 배송현황 리스트

const getRetrieveDeliveryStatusListAPI = createAsyncThunk(
  'delivery/getRetrieveDeliveryStatusListAPI',
  async (params, thunkAPI) => {
    const serverType = backendMode();
    const path = '/api/1.0/app/delivery/retrieveDeliveryStatusList';

    let URL = getUrl(path, serverType);
    const urlparams = createQueryString(params);
    URL += `?${urlparams}`;
    return axios
      .get(URL, header)
      .then(async (response) => {
        const detailList = response.data?.rs;
        thunkAPI.dispatch(getDeliveryStatusList(detailList));
      })
      .catch((err) => console.log(err));
  }
);

/**
 * @category Action
 * @namespace getDeliveryInfoDetailsAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 주문배송처리 / 계약 및 배송현황 상세조회

const getDeliveryInfoDetailsAPI = createAsyncThunk(
  'delivery/getDeliveryInfoDetailsAPI',
  async (params, thunkAPI) => {
    const serverType = backendMode();
    const path = '/api/1.0/app/delivery/getDeliveryInfoDetails';

    let URL = getUrl(path, serverType);
    const urlparams = createQueryString(params);
    URL += `?${urlparams}`;
    return axios
      .get(URL, header)
      .then(async (response) => {
        const detailList = response.data?.rs ?? [];
        thunkAPI.dispatch(getDeliveryStatusListDetails(detailList));
      })
      .catch((err) => console.log(err));
  }
);

/**
 * @category Action
 * @namespace postUpdateDeliveryStatus
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / status 변경
const postUpdateDeliveryStatus = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/updateDeliveryStatus';
  const onSuccess = (response) => {
    console.log('delevery / updateDeliveryStatus', response);
  };
  const onFail = (response) => {
    console.log('delevery / updateDeliveryStatus onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateDeliveryStatusReverse
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / status 변경 (역방향)
const postUpdateDeliveryStatusReverse = (
  dispatch,
  params,
  setIsOpenChangeRequestComment
) => {
  const URL = '/api/1.0/app/delivery/updateDeliveryStatusReverse';
  const onSuccess = (response) => {
    console.log('delevery / updateDeliveryStatusReverse', response);
    // FIXME: 파라미터 정보 불일치 에러 발생.. 나중에 수정해야 함.
    dispatch(openModalAlert({ message: '승인 요청에 성공했습니다.' }));
    setIsOpenChangeRequestComment(false);
  };
  const onFail = (response) => {
    console.log('delevery / updateDeliveryStatusReverse onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateDeliveryTrackingInfo
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / 운송장정보 업데이트
const postUpdateDeliveryTrackingInfo = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/updateDeliveryTrackingInfo';
  const onSuccess = (response) => {
    console.log('delevery / updateDeliveryTrackingInfo', response);
  };
  const onFail = (response) => {
    console.log('delevery / updateDeliveryTrackingInfo onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateDeliveryTrackingStatus
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / 배송현황 업데이트(스마트택배 API)
const postUpdateDeliveryTrackingStatus = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/updateDeliveryTrackingStatus';
  const onSuccess = (response) => {
    console.log('delevery / postUpdateDeliveryTrackingStatus', response);
  };
  const onFail = (response) => {
    console.log(
      'delevery / postUpdateDeliveryTrackingStatus onFail ',
      response
    );
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateDeliveryStatusResend
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / 재배송처리
const postUpdateDeliveryStatusResend = (
  dispatch,
  params,
  setIsOpenChangeRequestComment
) => {
  const URL = '/api/1.0/app/delivery/updateDeliveryStatusResend';
  const onSuccess = (response) => {
    if (response?.data?.rs?.statusUpdateResult?.result === 'SUCCESSED') {
      dispatch(openModalAlert({ message: '재배송처리에 성공했습니다.' }));
      setIsOpenChangeRequestComment(false);
    } else dispatch(openModalAlert({ message: '재배송처리에 실패했습니다.' }));
    console.log('delevery / updateDeliveryStatusResend', response);
  };
  const onFail = (response) => {
    console.log('delevery / updateDeliveryStatusResend onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateDeliveryStatusDispose
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / 배송폐기처리
const postUpdateDeliveryStatusDispose = (
  dispatch,
  params,
  setIsOpenChangeRequestComment
) => {
  const URL = '/api/1.0/app/delivery/updateDeliveryStatusResend';
  const onSuccess = (response) => {
    if (response?.data?.rs?.statusUpdateResult?.result === 'SUCCESSED') {
      dispatch(openModalAlert({ message: '배송폐기처리에 성공했습니다.' }));
      setIsOpenChangeRequestComment(false);
    } else
      dispatch(openModalAlert({ message: '배송폐기처리에 실패했습니다.' }));
    console.log('delevery / updateDeliveryStatusResend', response);
  };
  const onFail = (response) => {
    console.log('delevery / updateDeliveryStatusResend onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postDoApprovalDeliveryStatusChangeRequest
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 주문배송처리 / 배송상태 변경 건 승인
const postDoApprovalDeliveryStatusChangeRequest = (
  dispatch,
  params,
  setIsOpenChangeRequestComment
) => {
  const URL = '/api/1.0/app/delivery/doApprovalDeliveryStatusChangeRequest';
  const onSuccess = (response) => {
    console.log('delevery / doApprovalDeliveryStatusChangeRequest', response);
    setIsOpenChangeRequestComment(false);
  };
  const onFail = (response) => {
    console.log(
      'delevery / doApprovalDeliveryStatusChangeRequest onFail ',
      response
    );
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getRetrieveDeliveryTalkdreamResultList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 배송알림 / 알림 결과 리스트
const getRetrieveDeliveryTalkdreamResultListAPI = createAsyncThunk(
  'delivery/getRetrieveDeliveryTalkdreamResultListAPI',
  async (params, thunkAPI) => {
    const serverType = backendMode();
    const path = '/api/1.0/app/delivery/retrieveDeliveryTalkdreamResultList';
    let URL = getUrl(path, serverType);
    const urlparams = createQueryString(params);
    URL += `?${urlparams}`;
    return axios
      .get(URL, header)
      .then(async (response) => {
        const targetList = response.data?.rs?.list ?? [];
        thunkAPI.dispatch(getRetrieveDreamTalkdreamResultList(targetList));
      })
      .catch((err) => console.log(err));
  }
);

/**
 * @category Action
 * @namespace getRetrieveDeliveryTalkdreamResultDetailListAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 배송알림 / 알림 결과 리스트
const getRetrieveDeliveryTalkdreamResultDetailListAPI = createAsyncThunk(
  'delivery/getRetrieveDeliveryTalkdreamResultDetailListAPI',
  async (params, thunkAPI) => {
    const serverType = backendMode();
    const path =
      '/api/1.0/app/delivery/retrieveDeliveryTalkdreamResultDetailList';
    let URL = getUrl(path, serverType);
    const urlparams = createQueryString(params);
    URL += `?${urlparams}`;
    return axios
      .get(URL, header)
      .then(async (response) => {
        const targetList = response.data?.rs?.list ?? [];
        thunkAPI.dispatch(
          getRetrieveDreamTalkdreamResultDetailList(targetList)
        );
      })
      .catch((err) => console.log(err));
  }
);

/**
 * @category Action
 * @namespace getRetrieveDeliveryTalkdreamResultDashboardAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 배송알림 - 알림 결과 리스트(대시보드)
const getRetrieveDeliveryTalkdreamResultDashboardAPI = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/retrieveDeliveryTalkdreamResultDashboard';
  const onSuccess = (response) => {
    const targetList = response.data?.rs?.list ?? [];
    dispatch(getRetrieveDeliveryTalkdreamResultDashboard(targetList));
  };
  const onFail = (response) => {
    console.log('retrieveDeliveryTalkdreamResultDashboard onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace postResendSingleDeliveryMessage
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 알림 발송 결과 상세 / 단건 수정 발송
const postResendSingleDeliveryMessage = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/resendSingleDeliveryMessage';
  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      dispatch(openModalAlert({ message: '단건 수정 발송에 성공했습니다.' }));
    } else if (response.data.rs.result === 'FAILED') {
      dispatch(
        openModalAlert({
          message: '단건 재발송에 실패했습니다. 정보를 확인 해주세요.',
        })
      );
    } else
      dispatch(
        openModalAlert({
          message: '단건 재발송에 실패했습니다. 정보를 확인 해주세요.',
        })
      );
  };
  const onFail = (response) => {
    console.log('couponList / resendSingleDeliveryMessage onFail ', response);
    dispatch(
      openModalAlert({
        message: '단건 수정 발송에 실패했습니다. 정보를 확인 해주세요.',
      })
    );
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postResendDeliveryMessage
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 */

// 알림 발송 결과 상세 / 단건 수정 발송
const postResendDeliveryMessage = (dispatch, params) => {
  const URL = '/api/1.0/app/delivery/resendDeliveryMessage';
  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      dispatch(openModalAlert({ message: '대량 수정 발송에 성공했습니다.' }));
    } else if (response.data.rs.result === 'FAILED') {
      dispatch(
        openModalAlert({
          message: '대량 재발송에 실패했습니다. 정보를 확인 해주세요.',
        })
      );
    } else
      dispatch(
        openModalAlert({
          message: '대량 재발송에 실패했습니다. 정보를 확인 해주세요.',
        })
      );
  };
  const onFail = (response) => {
    console.log('couponList / resendDeliveryMessage onFail ', response);
    dispatch(
      openModalAlert({
        message: '대량 수정 발송에 실패했습니다. 정보를 확인 해주세요.',
      })
    );
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getRetrieveDeliveryCompanyList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 주문배송처리 / 택배사 리스트
const getRetrieveDeliveryCompanyList = (dispatch, params) => {
  const URL = '/api/1.0/app/common/retrieveDeliveryCompanyList';

  const onSuccess = (response) => {
    console.log('delievery / retrieveDeliveryCompanyList', response);
    if (response.data?.rs?.list.length > 0) {
      dispatch(getDeliveryCompanyList(response.data?.rs?.list));
    }
  };
  const onFail = (response) => {
    console.log('retrieveDeliveryCompanyList onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

export {
  getRetrieveDeliveryStatusDashboard,
  getRetrieveDeliveryStatusListAPI,
  getDeliveryInfoDetailsAPI,
  postUpdateDeliveryStatus,
  postUpdateDeliveryStatusReverse,
  postUpdateDeliveryTrackingInfo,
  postUpdateDeliveryTrackingStatus,
  postUpdateDeliveryStatusResend,
  postUpdateDeliveryStatusDispose,
  postDoApprovalDeliveryStatusChangeRequest,
  getRetrieveDeliveryTalkdreamResultListAPI,
  getRetrieveDeliveryTalkdreamResultDetailListAPI,
  getRetrieveDeliveryTalkdreamResultDashboardAPI,
  postResendSingleDeliveryMessage,
  postResendDeliveryMessage,
  getRetrieveDeliveryCompanyList,
};
