import { TAxios } from 'redux/function/TAxios';
import {
  getCustomerRefund,
  getCustomerRefundDashBoard,
  getVendorRefundStatusList,
  getVendorRefundStatusDashboard,
  getRefundCompleted,
  getRefundTalkedreamResult,
  getRefundTalkdreamResultDetail,
  getRefundTalkdreamResultDashboard,
} from '@feature/refund/refundSlice';
import { openModalAlert } from '@feature/common/commonSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { backendMode, createQueryString } from '@utils';
import { getUrl } from 'redux/function/TAxiosConfig';
import axios from 'axios';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

/**
 * @category Action
 * @namespace getCustomerRefundList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "fromDate": "2023-07-01",
  "toDate": "2024-02-24",
  "vendorKey": "",
  "category": "REFUND_TARGET / COUPON_REFUND_TARGET / DELIVERY_REFUND_TARGET / DELIVERY_REFUND_APPROVED / COUPON_REFUND_APPROVED",
  "limit": "10",
  "page": "1"
}
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.category - 대시보드 클릭시 filter를 해주기 위한 분류 값
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇 페이지인지 설정
 */

const getCustomerRefundList = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveCustomerRefundStatusList`;
  const onSuccess = (response) => {
    dispatch(getCustomerRefund(response.data.rs.list));
  };
  const onFail = (response) => {
    console.log('get Customer Message onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getCustomerRefundDashBoardCount
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "fromDate": "2023-07-01",
    "toDate": "2024-02-24",
    "vendorKey": "VENDOR.00004",
    "category": "REFUND_TARGET / COUPON_REFUND_TARGET / DELIVERY_REFUND_TARGET / DELIVERY_REFUND_APPROVED / COUPON_REFUND_APPROVED"
}
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.category 
 */

const getCustomerRefundDashBoardCount = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveCustomerRefundStatusDashboard`;
  const onSuccess = (response) => {
    dispatch(getCustomerRefundDashBoard(response.data.rs.dashboardInfo));
  };
  const onFail = (response) => {
    console.log('get Customer DashBoard Message onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace postUpdateCustomerCouponStatus
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "updateTargetList": "[{'deliveryPlanNo':'105796918', 'targetStatus':'C006'}, {'deliveryPlanNo':'104522129', 'targetStatus':'D006'}]",
  "userId": "admin"
}
 * @property {string} params.userId - userId
 * @property {string} params.updateTargetList - 업데이트가 필요한 대상 리스트: 1) deliveryPlanNo(고유 key: 배송예정넘버),
 * 2) targetStatus(바꿀 상태 값)
 * C004: 'C004(사용확정)',
   C006: 'C006(쿠폰폐기)',
   D001: 'D001(신규주문)',
   D005: 'D005(배송완료 확정)',
   D006: 'D006(배송폐기)',
 */

const postUpdateCustomerCouponStatus = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/updateCustomerCouponStatus`;
  const onSuccess = (response) => {
    const updateTargetListArray = JSON.parse(params.updateTargetList);
    const hasNullTargetStatus = updateTargetListArray.some(
      (item) => item.targetStatus === null
    );
    if (hasNullTargetStatus) {
      dispatch(openModalAlert({ message: '변경할 값을 선택 해 주세요.' }));
    } else {
      dispatch(openModalAlert({ message: '변경이 완료 되었습니다.' }));
      console.log(
        'post Update Customer CouponStatus Message onSuccess',
        response
      );
    }
  };
  const onFail = (response) => {
    console.log('post Update Customer CouponStatus Message onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getVendorRefundStatusList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "vendorKey": "VENDOR.00004",
    "fromDate": "2023-07-01",
    "toDate": "2024-02-24",
    "changeStatus": "CHANGE_TARGET, CHANGE_COMPLETED",
    "limit": "10",
    "page": "1"
  }
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.changeStatus - 변경할 상태 (예: "CHANGE_TARGET")
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇 페이지인지 설정
 */

const getVendorRefundStatusListAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveVendorRefundStatusList`;
  const onSuccess = (response) => {
    console.log('###hello', response);
    dispatch(getVendorRefundStatusList(response.data.rs));
  };
  const onFail = (response) => {
    console.log('get Customer Message onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getVendorRefundStatusDashboardAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "vendorKey": "VENDOR.00004",
    "fromDate": "2023-07-01",
    "toDate": "2024-02-24",
    "limit": "10",
    "page": "1"
  }
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇페이지인지 설정
 */

const getVendorRefundStatusDashboardAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveVendorRefundStatusDashboard`;
  const onSuccess = (response) => {
    dispatch(getVendorRefundStatusDashboard(response.data.rs.dashboardInfo));
  };
  const onFail = (response) => {
    console.log('get Customer DashBoard Message onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace postVendorCouponStatusAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "vendorKey": "VENDOR.00004",
    "fromCouponStatus": fromCoupon.status,
    "toCouponStatus": toCoupon.status,
    "limit": "10",
    "page": "1"
  }
 * @property {string} params.updateTargetList - 업데이트 대상 (예: "['1084534']" JSON.stringify(updateTargetList) updateTargetList 일반 배열)
 * @property {string} params.fromCouponStatus - 기존 쿠폰상태 (예: "C002")
 * @property {string} params.toCouponStatus - 변경할 쿠폰상태 (예: "C006")
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇페이지인지 설정
 */

const postVendorCouponStatusAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/updateVendorCouponStatus`;
  const onSuccess = (response) => {
    dispatch(getCustomerRefundDashBoard(response.data.rs.list));
  };
  const onFail = (response) => {
    console.log('get updateVendorCouponStatus Message onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getRefundCompletedList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "fromDate": "2023-07-01",
    "toDate": "2024-02-24",
    "refundCategory": "UNUSED_COUPON_REFUND / CUSTOMER_REFUND",
    "refundStatus": "C004 / C006 / D005 / D006",
    "vendorKey": "VENDOR.00004",
    "limit": "10",
    "page": "1"
  }
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.refundCategory - 대시보드 클릭시 filter를 해주기 위한 분류 값
 * @property {string} params.refundStatus - 대시보드 클릭시 filter를 해주기 위한 분류 값
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇 페이지인지 설정
 */

const getRefundCompletedList = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveRefundCompletedList`;
  const onSuccess = (response) => {
    dispatch(getRefundCompleted(response.data.rs.list));
  };
  const onFail = (response) => {
    console.log('get retrieveRefundCompletedList Message onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getRefundCompletedList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "fromDate": "2023-01-01",
    "toDate": "2024-03-08",
    "messageTarget": "CUSTOMER / VENDOR / LGE",
    "vendorKey": "VENDOR.00002",
    "category": "CAT01(기간내미처리환불지연) / CAT02(고객요청환불지연)",
    "limit": "10",
    "page": "1"
}
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 * @property {string} params.messageTarget - 메세지 대상 (고객,제휴사,LGE)
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.category - 알림 구분 (기간내미처리환불지연/고객요청환불지연)
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇 페이지인지 설정
 */

const getRefundTalkedreamResultList = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveRefundTalkdreamResultList`;
  const onSuccess = (response) => {
    dispatch(getRefundTalkedreamResult(response.data.rs.list));
  };
  const onFail = (response) => {
    console.log(
      'get retrieveRefundTalkdreamResultList Message onFail',
      response
    );
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getRefundCompletedList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "requestDatetime": "2023-12-21 11:32:11",
    "vendorKey": "VENDOR.00002",
    "category": "REFUND_VENDOR_DELAYED+1 / REFUND_CUSTOMER_DELAYED+1",
    "sendMethod": "KAKAO / LMS",
    "sendResult": "FAILED",
    "limit": "10",
    "page": "1"
}
 * @property {string} params.requestDatetime - 요청 날짜
 * @property {string} params.vendorKey - 제휴사 키 (예: "VENDOR.00004")
 * @property {string} params.category - 알림 구분 (REFUND_VENDOR_DELAYED/REFUND_CUSTOMER_DELAYED)
 * @property {string} params.sendMethod - 알림 전송 구분 (KAKAO/LMS)
 * @property {string} params.sendMethod - 알림 전송 결과(FAILED)
 * @property {string} params.limit - 한 페이지에서 보여줄 아이템 갯수
 * @property {string} params.page - 현재 페이지가 몇 페이지인지 설정
 */

const getRefundTalkdreamResultDetailList = createAsyncThunk(
  'refund/getRefundTalkdreamResultDetailList',
  async (params, thunkAPI) => {
    const serverType = backendMode();
    const path = `/api/1.0/app/refund/retrieveRefundTalkdreamResultDetailList`;
    let URL = getUrl(path, serverType);
    const urlparams = createQueryString(params);
    URL += `?${urlparams}`;
    return axios
      .get(URL, header)
      .then(async (res) => {
        const list = res.data?.rs?.list;
        thunkAPI.dispatch(getRefundTalkdreamResultDetail(list));
      })
      .catch((err) => console.error(err));
  }
);

/**
 * @category Action
 * @namespace getRefundTalkdreamResultDashboard
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
    "fromDate": "2023-01-01",
    "toDate": "2024-03-08",
}
 * @property {string} params.fromDate - 조회 시작날짜 (예: "20230801")
 * @property {string} params.toDate - 조회 종료날짜 (예: "20230831")
 */

const getRefundTalkdreamResultDashboardAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/refund/retrieveRefundTalkdreamResultDashboard`;
  const onSuccess = (response) => {
    dispatch(getRefundTalkdreamResultDashboard(response.data.rs.list[0]));
  };
  const onFail = (response) => {
    console.log(
      'get retrieveRefundTalkdreamResultDashboard Message onFail',
      response
    );
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

export {
  getCustomerRefundList,
  getCustomerRefundDashBoardCount,
  postUpdateCustomerCouponStatus,
  getVendorRefundStatusListAPI,
  getVendorRefundStatusDashboardAPI,
  postVendorCouponStatusAPI,
  getRefundCompletedList,
  getRefundTalkedreamResultList,
  getRefundTalkdreamResultDetailList,
  getRefundTalkdreamResultDashboardAPI,
};
