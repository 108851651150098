import React, { useCallback, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Input,
  VerticalTable,
  VerticalTableContainer,
} from '@components';
import classNames from 'classnames/bind';
import css from './NotFound.module.scss';

const cn = classNames.bind(css);

const NotFound = () => {
  const navigate = useNavigate(); // 라우터이동
  const location = useLocation();
  const params = useParams();

  const handleGoHome = useCallback(() => {
    navigate('/home');
  }, []);

  useEffect(() => {
    console.log('params', params);
    console.log('location', location);
  }, [params]);
  return (
    <div className={cn('notFoundContainer')}>
      <div>
        <h2>
          <strong>접근할 수 없는 경로입니다.</strong>
        </h2>
      </div>
      <div>
        <h2>
          <strong>
            업체별 매출 확인, 업체별 매출 승인 및 확정, 업체별 거래액 송부 및
            세금계산서 페이지는{' '}
            <span className={cn('highLight')}>26일부터 말일</span> 그리고{' '}
            <span className={cn('highLight')}>1일 부터 5일</span> 까지 접근
            가능합니다.
          </strong>
          <br />
        </h2>
      </div>
      <div className={cn('btnContainer')}>
        <Button className={cn('btn', 'btn01', 'type03')} onClick={handleGoHome}>
          홈으로 돌아가기
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
