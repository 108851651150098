import React, { useState, useMemo, useEffect, useCallback } from 'react';
import css from './NoticeList.module.scss';
import classNames from 'classnames/bind';
import { Calendar, Search, SelectBox, TableV8 } from '@components/index';
import { useDispatch, useSelector } from 'react-redux';
import { useSelect } from '@hooks';
import { createColumnHelper } from '@tanstack/react-table';
import { openModalAlert } from '@feature/common/commonSlice';
import { FormProvider, useForm } from 'react-hook-form';
import NoticeListSearchArea from './modules/NoticeListSearchArea';
import {
  hasSearchText,
  isDateInRange,
} from '@components/Search/utils/searchValidationFunctions';

const columnHelper = createColumnHelper();

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const selectSearchOptions = {
  guide: '',
  list: [
    { id: 'subject', title: '제목' },
    { id: 'content', title: '내용' },
  ],
};

export const NoticeList = ({
  tableData,
  handleDetailPopUpClick,
  handleRegisterPopUpClick,
  handleCurrentNotice,
}) => {
  const dispatch = useDispatch();
  const searchForms = useForm();

  const myAuthority = useSelector((state) => state.user.userData.authority);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filtered, setFiltered] = useState(tableData);

  const onSearchClick = useCallback(
    (data) => {
      const { selectSearchCondition, keyword } = data;

      const filteredList = tableData.filter((table) => {
        const { createDatetime } = table;

        return (
          isDateInRange({
            targetDate: new Date(createDatetime),
            startDate,
            endDate,
          }) &&
          hasSearchText({
            targetValue: table[selectSearchCondition?.id],
            searchText: keyword,
          })
        );
      });
      setFiltered(filteredList);
    },
    [startDate, endDate, tableData]
  );

  // 날짜 선택 이벤트 핸들러
  const handleStartDate = (date) => {
    if (date <= endDate) {
      setStartDate(date);
    } else {
      dispatch(
        openModalAlert({
          message: '시작 날짜는 종료 날짜보다 이전이어야 합니다.',
        })
      );
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      setEndDate(date);
    } else {
      dispatch(
        openModalAlert({
          message: '종료 날짜는 시작 날짜보다 이후이어야 합니다.',
        })
      );
    }
  };

  const columnData = useMemo(() => {
    return [
      // columnHelper.accessor('no', {
      //   id: 'no',
      //   cell: (info) => info.getValue(),
      //   header: () => <span>No</span>,
      // }),
      columnHelper.accessor('subject', {
        id: 'subject',
        cell: (info) => {
          return (
            <>
              {info.row.original.isTopFixed ? (
                <strong>{info.getValue()}</strong>
              ) : (
                info.getValue()
              )}
            </>
          );
        },
        header: () => <span>제목</span>,
      }),
      columnHelper.accessor('content', {
        id: 'content',
        cell: (info) => (
          <>
            <button
              onClick={() => {
                handleDetailPopUpClick();
                handleCurrentNotice(info.row.original);
              }}
              className={cn('btn_txt04', 'content')}
              dangerouslySetInnerHTML={{
                __html:
                  info
                    .getValue()
                    .replace(/<img[^>]*>(.*?)<\/img>/g, '')
                    .match(/<p>(.*?)<\/p>/)?.[0] ??
                  info.getValue().replace(/<img[^>]*>(.*?)<\/img>/g, ''),
              }}
            ></button>
            {info.row.original.commentCount > 0 && (
              <span className={css.ico_reply}>
                {info.row.original.commentCount}
              </span>
            )}
          </>
        ),
        header: () => <span>내용</span>,
        size: 300,
      }),
      columnHelper.accessor('createDatetime', {
        id: 'createDatetime',
        cell: (info) => info.getValue().split(' ')[0],
        header: () => <span>게시일</span>,
      }),
      // columnHelper.accessor('readCount', {
      //   id: 'readCount',
      //   cell: (info) => info.getValue(),
      //   header: () => <span>조회</span>,
      // }),
    ];
  }, [handleDetailPopUpClick, handleCurrentNotice]);

  useEffect(() => {
    setFiltered(tableData);
  }, [tableData]);

  return (
    <>
      <div className={cn('searchOptionArea', 'row')}>
        <div className={css.flex}>
          <div className={cn('innerBox', 'date')}>
            <Calendar
              hasPeriodInput={false}
              startDate={startDate}
              endDate={endDate}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
            />
          </div>
        </div>
        <FormProvider {...searchForms}>
          <NoticeListSearchArea
            selectSearchOptions={selectSearchOptions}
            onSearchClick={onSearchClick}
          />
        </FormProvider>
      </div>
      <TableV8
        columns={columnData}
        data={filtered}
        hasCsvDownload={false}
        rightControls={
          (myAuthority === 'LEVEL_1' || myAuthority === 'LEVEL_2') && (
            <div className={css.box_right}>
              <button
                onClick={handleRegisterPopUpClick}
                type='button'
                className={cn('btn', 'btn03', 'type02')}
              >
                작성하기
              </button>
            </div>
          )
        }
      />
    </>
  );
};
