export const TERMS_LIST = {
  term01: `제휴사 Admin 서비스 이용약관
  
    제1조 목적
    본 이용약관은 LG전자 주식회사(이하 “회사”)에서 제공하는 제휴사 Admin 서비스와 관련하여 제공되는 정보 및 제반 서비스(이하 “서비스”)를 이용하는 이용자와 “회사” 간의 권리·의무 및 책임, 기타 필요한 사항을 규정함을 목적으로 합니다.
    제2조 정의
    본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
    ① “서비스”라 함은 구현되는 단말기(PC, 휴대폰 등 각종 유무선 장치를 포함)의 종류와 상관없이 "회사”에 의해 제공되는 제휴사 Admin 관련 제반 서비스를 의미합니다.
    ② “이용자”라 함은 “회사”의 “서비스” 사용에 동의하여 이 약관에 따라 “회사”와 이용계약을 체결하고 “회사”가 제공하는 “서비스”를 이용하는 자사 및 제휴사 직원을 말합니다.
    ③ “아이디(ID)”라 함은 “이용자”의 식별과 “서비스” 이용을 위하여 “이용자”가 정하고 “회사”가 승인하는 “아이디(ID)”를 의미합니다.
    제3조 약관의 게시와 개정
    ① “회사”는 이 약관의 내용을 “이용자”가 쉽게 알 수 있도록 서비스 내에 게시합니다.
    ② “회사”는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편주소 등) 등을 “이용자”가 알 수 있도록 서비스 내에 게시합니다.
    ③ “회사”는 “약관의규제에관한법률”, “정보통신망이용촉진및정보보호등에관한법률”(이하 “정보통신망법”)등 관련법령을 위반하지 않는 범위에서 이 약관을 개정할 수 있으며, 이 경우에는 적용일자 및 개정사유, 변경되는 내용을 명시하여 현행 약관과 함께 1항의 방식에 따라 그 적용일자 7일 전(‘이용자”에게 불리하거나 중대한 사항의 변경은 30일 전)부터 적용일자 전일까지 공지합니다.
    ④ 제③항에 따라 개정된 약관은 “이용자”가 명시적인 반대의사를 표시하지 않는 한 제③항에서 정한 공지기간이 종료됨과 동시에 효력이 발생합니다.
    ⑤ “이용자”는 변경된 약관에 동의하지 않을 경우 “서비스” 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다. 변경된 약관의 공지기간 종료 이후 “이용자”의 계속적인 “서비스” 이용은 이 약관의 변경사항에 동의한 것으로 간주됩니다.
    제4조 약관의 해석
    본 약관에서 언급되지 아니한 사항에 대해서는 “약관의 규제에 관한 법률”, “정보통신망법” 등 관련 법령 및 회사의 지침에 따릅니다.
    제5조 서비스 이용계약의 체결
    ① “서비스” 이용 계약은, “서비스”의 이용이 가능한 기기를 보유하게 된 자가 각각 이 약관에 동의한다는 의사표시와 회원가입에 필요한 정보를 “회사”에게 제공하고, “회사”가 서비스 이용을 승낙함으로써 체결됩니다.
    ② “이용자”는 반드시 본인의 정보를 제공하여야만 “서비스”를 이용할 수 있으며, 타인의 정보를 도용하거나 허위의 정보를 등록하는 등 본인의 진정한 정보를 등록하지 않는 경우 “서비스”와 관련하여 어떠한 주장도 할 수 없으며, 관련 법령에 따라 처벌받을 수 있습니다.
    ③ “서비스”는 만 14세 미만의 "이용자"에게는 이용이 허용되지 않습니다. 
  
    제6조 “서비스” 승낙의 제한
    “회사”는 다음 각 호에 해당하는 “서비스” 이용 신청에 대하여는 이를 승낙하지 아니하거나 해당 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
    ① 다른 사람의 정보를 사용하는 등 가입 신청의 내용을 허위로 기재하거나 필수적으로 기재가 요구되는 사항을 누락한 경우
    ② 영리를 추구할 목적 등 부정한 용도로 “서비스”를 이용하고자 하는 경우
    ③ 법령위반 또는 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우
    ④ 이 약관에 근거하여 “회사”가 이용계약을 해지한 전(前) “회원”이 재가입신청을 하는 경우
    ⑤ 이 약관에 위배되거나 위법 또는 부당한 가입신청임이 확인된 경우
    ⑥ 기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우
    제7조 이용계약의 종료
    ① “회원”이 이용계약을 해지하고자 하는 경우 이용계약을 해지하고 “회원” 탈퇴를 하거나 “회사”가 정한 별도의 방법으로 “회사”에 해지 신청을 하여야 합니다.
    ② “회원”의 해지
    가. “회원”은 “회사”에 해지의사를 통지함으로써 언제든지 이용계약을 해지하고 “회원” 탈퇴를 할 수 있습니다.
    나. 위와 같은 요청을 받은 “회사”는 “회사”가 고지한 방법에 따라 신속하게 “회원” 탈퇴를 처리합니다. 다만, 본인확인 절차가 필요한 경우에는 신분증 또는 “회사”가 인정하는 본인의 신분을 확인할 수 있는 증서의 제출을 요청할 수 있습니다.
    다. 탈퇴한 “회원”은 새롭게 제5조의 절차를 통해 재가입할 수 있으나, 이전 이용계약시 본 약관을 위반하였던 “회원”에 대해서는 재가입이 제한될 수 있습니다.
    라. “회원”이 탈퇴한 이후, “서비스”에 재가입 하더라도 이전의 이용 정보는 복구되지 않습니다.
    ③ “회사”의 해지
    가. “회사”는 다음과 같은 사유가 있는 경우 “회원”에게 해지의 의사를 통지하여 이용계약을 해지할 수 있습니다. 다만, “회사”는 해당 “회원”에게 사전에 해지사유에 대한 의견진술의 기회를 부여할 수 있습니다.
    - “회원”에게 이 약관에서 정하고 있는 이용계약의 승낙 제한 사유가 있음이 확인된 경우
    - “회원”이 “회사”나 다른 “회원” 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 행위를 한 경우
    - “회원”이 회사가 제공하는 “서비스”의 원활한 진행을 방해하는 행위를 하거나 이를 시도한 경우
    - 기타 “회원”이 이 약관에 위배되거나 “서비스”와 관련하여 위법한 행위를 한 경우
    나. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 “회원”이 책임을 부담하여야 하고, “회사”는 일체의 책임을 지지 않습니다.
    제8조 이용자정보의 변경
    ① “이용자”는 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 이메일(ID) 등은 수정이 불가능합니다.
    ② “이용자”는 이용자가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 “회사”에 대하여 그 변경사항을 알려야 합니다.
    ③ 제2항의 변경사항을 “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
    제9조 “이용자”에 대한 통지
    ① “회사”가 “이용자”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
    ② “회사”는 “이용자” 전체에 대한 통지의 경우 7일 이상 “회사”의 “웹사이트” 또는 서비스 내 “공지사항” 화면에 게시함으로써 제①항의 통지에 갈음할 수 있습니다.
    제10조 “회사”의 의무
    ① “회사”는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 “서비스”를 제공하는데 최선을 다하여야 합니다.
    ② “회사”는 “이용자”가 안전하게 “서비스”를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 합니다.
    ③ “회사”는 서비스이용과 관련하여 “이용자”로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 “이용자”에게 처리과정 및 결과를 전달합니다.
    ④ "회사"는 "이용자"의 잘못된 정보 입력으로 생성된 활동 기준("권장 식이량" 등)에 의하여 발생하는 어떠한 손해에 대해서도 책임을 지지 않습니다.
    ⑤ 아래의 사항에 해당될 경우 건강정보 공개 및 상실에 대하여 "회사"는 책임을 지지 않습니다.
    가. 회원의 부주의로 "비밀번호"가 유출되어 건강정보가 공개되는 경우
    나. 회원이 각종 삭제 기능을 사용하여 내용을 삭제하였을 경우
    다. 천재지변이나 그 밖의 "회사"가 통제할 수 없는 상황에 의하여 내용이 공개되거나 상실되었을 경우
    제 11 조 “이용자”의 의무
    ① “이용자”는 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.
    ② “이용자”는 다음 행위를 하여서는 안됩니다.
    가. “회사”, 기타 제3자의 저작권 등 지적재산권에 대한 침해
    나. “회사”, 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    다. 외설 또는 폭력적인 메시지, 화상, 음성 기타 미풍양속에 반하는 정보를 공개 또는 게시하는 행위
    라. 기타 불법적이거나 부당한 행위
    - 회사가 게시한 정보의 변경
    - 타인의 정보도용
    - 서비스 신청, 변경 시 허위정보의 등록
    - “회사”의 사전 동의 없이 영리 목적의 광고성 정보를 전송하기 위하여 이용하는 행위
    - 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 모방 또는 기타 변형하는 행위
    - 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 “회사”의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
    - 본인 아닌 제3자에게 접속권한을 부여하는 행위
    ③ “이용자”는 “회사”에서 공식적으로 인정한 경우를 제외하고는 “서비스”를 이용하여 상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법전송 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 “회사”가 책임을 지지 않으며, 이와 같은 행위로 인하여 “회사”에게 손해가 발생한 경우, “이용자”는 “회사”에 대하여 손해배상 의무를 집니다.
    ④ “이용자”가 이 약관의 규정을 위반함으로 인하여 “회사”에 손해가 발생하게 되는 경우, 이 약관을 위반한 “이용자”는 “회사”에 발생하는 모든 손해를 배상할 책임이 있습니다.
    ⑤ “이용자”가 본 서비스를 이용함에 있어 행한 불법행위나 이 약관의 위배행위로 인하여 “회사”가 당해 이용자 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 “이용자”는 자신의 책임과 비용으로 “회사”를 면책 시켜야 하며, “회사”가 면책되지 못한 경우 당해 “이용자”는 그로 인하여 “회사”에 발생한 모든 손해를 배상하여야 합니다.
    제12조 서비스의 제공 등
    ① “서비스” 이용은 “회사”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. “회사”는 “서비스”를 일정 범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며, 이 경우 그 내용을 인터넷 사이트 등에 공지합니다.
    ② “회사”는 다음 각 호에 해당하는 경우 “서비스”의 전부 또는 일부를 영구히 또는 일시적으로 제한하거나 중단할 수 있습니다.
    가. 컴퓨터 등 정보통신설비의 보수·점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우
    나. 설비의 보수 등 공사로 인해 부득이한 경우
    다. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 “서비스” 이용에 지장이 있는 경우
    라. “이용자”가 “회사”의 영업활동을 방해하는 경우
    마. “서비스” 제공업자와의 계약종료 등 “회사”의 제반 사정으로 해당 “서비스”를 유지할 수 없는 경우
    바. 기타 천재지변, 국가비상사태 등 불가항력의 사유가 있는 경우
    사. “회사”의 사업계획의 변경 또는 경영상의 사유로 더 이상의 “서비스” 제공이 불가능할 경우
    ③ “서비스” 중단의 경우 “회사”는 중단될 “서비스”의 내용 및 제공일자를 제9조에서 정한 게시의 방법으로 “이용자”에게 통지를 갈음할 수 있습니다. 단, “회사”가 “회사”의 고의·과실이 없는 시스템 장애, 천재지변 등 기타 불가피한 사유로 인하여 “서비스”를 중단하게 되어 사전 통지가 불가능한 경우에는 “이용자”에게 미리 통지하지 못할 수도 있습니다.
    ④ “회사”에 고의 또는 중과실이 없는 경우 “회사”는 “서비스”의 중단으로 발생하는 문제에 대해서 일체의 책임을 지지 않습니다.
    제13조 “서비스”의 변경 및 중단
    ① “회사”는 이용 감소로 인한 원활한 “서비스” 제공의 곤란 및 수익성 악화, 기술 진보에 따른 차세대 “서비스”로의 전환 필요성, “서비스” 제공과 관련한 “회사” 정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 “서비스”를 수정, 변경 또는 중단할 수 있습니다.
    ② “회사”는 무료로 제공되는 “서비스”의 일부 또는 전부를 “회사”의 정책 및 운영의 필요상 언제든지 수정, 중단, 변경 할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “이용자”에게 별도의 보상을 하지 않습니다.
    ③ “회사”는 정보통신 시스템 등의 정기점검, 보수, 교체, 고장, 통신두절 또는 운영상의 상당한 이유가 있는 경우에는 “서비스”의 제공을 일시 중단할 수 있습니다. 이 경우 회사는 사전에 이용자에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
    ④ 서비스의 내용, 이용방법, 이용시간에 대하여 변경 또는 서비스 중단이 있는 경우에는 변경 또는 중단될 서비스의 내용 및 사유와 일자 등은 그 변경 또는 중단 전에 “웹사이트” 또는 “서비스” 내 공지화면 등 이용자가 충분히 인지할 수 있는 방법으로 7일 이상(단, 약관의 변경이 있는 경우에는 10일 이상, 서비스 종료 시에는 30일 이상) 게시하는 방법으로 공지합니다.
    ⑤ “회사”는 본 “서비스”의 변경, 중지로 발생하는 손해 등 어떠한 문제에 대해서도 책임 지지 않습니다.
    제14조 정보의 제공
    “회사”는 이용자가 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 이용자에게 제공할 수 있습니다.
    제15조 개인정보보호 의무
    “회사”는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” (이하 “정보통신망법”) 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 “회사”의 개인정보처리방침이 적용됩니다. 다만, “회사”의 공식 사이트 이외의 링크된 사이트에서는 “회사”의 개인정보처리방침이 적용되지 않습니다.
    제16조 “이용자”의 개인정보 관리에 대한 의무
    ① “이용자”의 “아이디”와 “비밀번호”에 관한 관리책임은 “이용자”에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다
    ② “회사”는 “이용자”의 “아이디”가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 “회사” 및 “회사”의 운영자로 오인할 우려가 있는 경우, 해당 “아이디”의 이용을 제한할 수 있습니다.
    ③ “이용자”는 “아이디” 및 “비밀번호”가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야 합니다.
    ④ 제3항의 경우에 해당 “이용자”가 “회사”에 그 사실을 통지하지 않거나, 통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
    제17조 이용제한 등
    ① “회사”는 “이용자”가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 계약해지 등으로 “서비스”의 이용을 단계적으로 제한할 수 있습니다.
    ② “회사”는 전항에도 불구하고, “주민등록법”을 위반한 명의도용 및 결제도용, “저작권법” 및 “컴퓨터프로그램보호법”을 위반한 불법 프로그램의 제공 및 운영방해, “정보통신망법”을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 계약해지를 할 수 있습니다. 본 항에 따른 계약해지 시 서비스 이용을 통해 획득한 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
    ③ “회사”는 이용제한정책 등에서 제한의 조건 및 세부내용을 정하되, 본 조의 이용제한 범위를 벗어나지 않습니다.
    ④ 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 “회사”는 제9조 [“이용자”에 대한 통지]에 정한 방법에 따라 통지합니다.
    제18조 책임제한
    ① “회사”는 천재지변 또는 이에 준하는 불가항력으로 인하여 “서비스”를 제공할 수 없는 경우에는 “서비스” 제공에 관한 책임이 면제됩니다.
    ② “회사”는 “이용자”의 귀책사유로 인한 “서비스” 이용의 장애에 대하여는 책임을 지지 않습니다.
    ③ “회사”는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
    ④“회사” 및 “회사”의 임직원 그리고 대리인은 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 아니합니다.
    가. “이용자” 개인정보의 허위 또는 부정확성에 기인하는 손해
    나. 그 성질과 경위를 불문하고 “서비스”에 대한 접속 및 “서비스”의 이용과정에서 발생하는 개인적인 손해
    다. 서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위로부터 발생하는 손해
    라. 제3자가 “서비스”를 이용하여 불법적으로 전송, 유포하거나 또는 전송/유포 되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해
    마. 전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생되는 손해
    ⑤ “서비스”와 관련하여 “이용자”와 제3자 간에 분쟁이 발생한 경우 “회사”는 그 분쟁에 개입하지 않으며 그 분쟁의 결과에 책임이 없습니다. 이와 관련하여 “회사”가 제3자에게 손해를 배상하거나 기타 비용을 지출한 경우 “회사”는 “이용자”에게 구상권을 행사할 수 있습니다.
    제19조 회사의 연락처
    "회사"의 상호 및 주소 등은 다음과 같습니다.
    - 상호: 엘지전자 주식회사
    - 주소: 서울특별시 영등포구 여의도동 20
    - 대표전화: 1544-7777
    - 사업자등록번호: 107-86-14075
    제20조 해외이용
    “회사”는 “이용자”가 대한민국의 영토 이외의 지역에서 “서비스”를 이용하고자 하는 경우 “서비스”의 품질 또는 사용성을 보장하지 않습니다. 따라서 “이용자”가 대한민국의 영토 이외의 지역에서 “서비스”를 이용하고자 하는 경우 스스로의 판단과 책임에 따라서 이용 여부를 결정하여야 하고, 특히 “서비스”의 이용과정에서 현지 법령을 준수할 책임은 “이용자”에게 있습니다.`,
  term02: `LG전자 제휴사 Admin 서비스 개인정보 수집 이용 동의 
  LG전자 주식회사(이하 "회사")는 이용자가 제휴사Admin 서비스를 이용하는 경우 아래와 같이 개인정보를 수집·이용합니다. 
  1. 수집하는 개인정보 항목 
  - 아이디, 패스워드, 성명, 이메일, 전화번호, 회사명, 사업자등록번호
  - 서비스 이용기록(접속 일시, 세션로그정보 등) 

  2. 개인정보의 수집 및 이용 목적
  회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
  가. 회원 관리
  회사는 본인확인, 이용자 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 의사 확인, 가입 제한, 고지사항 전달 등을 위하여 수집한 개인정보를 이용합니다.
  회사는 이용자에 대해 수집한 개인정보를(이메일, 전화번호) 업무 커뮤니케이션 목적으로만 사용하게 됩니다. 세부적으로 이용자가 속한 회사의 서비스 주문 알림, 정산, 환불 업무 등을 의미하며 sms/카카오 알림톡/전화 등을 통해 커뮤니케이션 할 예정입니다. 이용자의 개인정보는 업무 외 목적 (마케팅, 영업) 등으로는 사용되지 않습니다. 또한 이용자는 이용자가 속한 회사의 승인 하에 권한을 가지게 됩니다. 

  3. 개인정보 처리 및 보유 기간
  회사는 이용자로부터 개인정보를 수집하는 경우 서비스 이용기간 동안 개인정보를 이용∙보관함을 원칙으로 합니다. 다만, 회사는 관계 법령의 규정 및 회사 내부 방침에 의하여 개인정보를 보존할 필요가 있는 경우 일정한 기간 동안 해당 개인 정보를 보관합니다.
  가. 상법 등 관련 법령의 규정에 의하여 일정기간 보유하여야 할 필요가 있을 경우에는 그 기간 동안 보유합니다. 그 예는 아래와 같습니다.
  ① 상업장부와 영업에 관한 중요서류에 포함된 개인정보: 10년(상법)
  ② 전표 또는 이와 유사한 서류에 포함된 개인정보: 5년(상법)
  ③ 이용자의 성명, 전화번호, 주소, 요금납부내역(청구액, 수납액, 수납 일시, 요금납부 방법 등), 기타 거래에 관한 장부: 관련 국세의 법정신고기한이 경과한 날로부터 5년(국세기본법)
  ④ 계약 또는 청약철회 등에 관한 기록: 계약 또는 청약철회 시부터 5년(전자상거래 등에서의 소비자보호에 관한 법률)
  ⑤ 대금결제 및 재화 등의 공급에 관한 기록: 공급 시부터 5년(전자상거래 등에서의 소비자보호에 관한 법률)
  ⑥ 표시광고에 관한 기록: 6개월(전자상거래 등에서의 소비자보호에 관한 법률)
  ⑦ 신용정보의 수집 처리 및 이용 등에 관한 기록: 3년(신용정보의 이용 및 보호에 관한 법률)
  ⑧ 통신사실확인자료 제공 시 이용자 확인에 필요한 성명, 전화번호 등: 12개월(통신비밀보호법)
  ⑨ 전기통신 일시, 전기통신 개시·종료 시간, 발∙착신 통신번호 등 상대방의 가입자 번호, 사용도수, 정보통신망에 접속된 정보통신기기의 위치를 확인할 수 있는 발신기지국의 위치추적자료 등: 12개월(통신비밀보호법)
  ⑩ 컴퓨터 통신 또는 인터넷의 사용자가 전기통신역무를 이용한 사실에 관한 컴퓨터 통신 또는 인터넷의 로그기록자료, 컴퓨터 통신 또는 인터넷 사용자가 정보통신망에 접속하기 위하여 사용하는 정보통신기기의 위치를 확인할 수 있는 접속지의 추적 자료: 3개월(통신비밀보호법)
  나. 개별적으로 이용자의 동의를 받은 경우: 그 동의 받은 기간
  다. 서비스가 해지 등으로 종료되었으나 이용요금(기타 거래대금을 포함)을 납부하지 않은 경우: 그 요금을 완납할 때까지
  라. 회사와 이용자 간에 민원, 소송 등 분쟁이 발생한 경우에 그 보유기간 내에 분쟁이 해결되지 않을 경우: 그 분쟁이 해결될 때까지 
  4. 동의 거부권 및 미동의에 대한 불이익 안내
  고객님께서는 정보주체로서 개인정보 동의 거부권이 있으시며, 미동의 시 서비스 이용에 제약이 있을 수 있습니다.

  위와 같이 개인정보를 수집∙이용하는데 동의하십니까? `,
  term03: `LG전자 주식회사(이하 “회사”)는 이용자의 개인정보를 처리함에 있어 「개인정보 보호법」(이하 “개인정보보호법”), 「정보통신망 이용 촉진 및 정보 보호 등에 관한 법률」(이하 “정보통신망법”) 등 관련 법령상의 규정을 준수합니다.
  
  1. 수집하는 개인정보의 항목 및 수집 방법
  2. 개인정보의 수집 및 이용 목적
  3. 개인정보의 제공
  4. 개인정보의 처리 위탁
  5. 개인정보의 추가적인 이용•제공 판단 기준
  6. 가명정보의 처리
  7. 개인정보 국외이전 
  8. 개인정보의 보유 및 이용 기간
  9. 개인정보의 파기절차 및 방법
  10. 이용자 및 법정대리인의 권리와 그 행사 방법
  11. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
  12. 개인정보의 기술적/관리적 보호 대책
  13. 개인정보관리책임자 및 민원 처리 콜센터
  14. 권익침해 구제방법
  15. 기타 
  16. 고지 의무

  1. 수집하는 개인정보의 항목 및 수집 방법
  가. 수집하는 개인정보의 항목
  - 아이디, 패스워드, 사번, 성명, 이메일, 전화번호, 회사명, 사업자등록번호
  - 서비스 이용기록(접속 일시, 세션로그정보 등)

  나. 개인정보 수집 방법
  - 이용자의 개인정보는 웹페이지를 통한 회원 가입 및 이용과정에서 이용자의 직접 입력, 웹사이트 및 앱 이용에 따른 로그분석 프로그램 등을 통하여 자동으로 기기 사용정보 등 개인정보를 수집합니다.

  2. 개인정보의 수집 및 이용 목적
  가. 회원 관리
  회사는 본인확인, 이용자 식별, 불량 회원의 부정 이용 및 비인가 사용 방지, 가입 의사 확인, 가입 제한, 고지사항 전달 등을 위하여 수집한 개인정보를 이용합니다.
  나. 서비스 제공
  회사는 이용자에 대하여 수집한 개인정보를 이용합니다. 
  회사는 이용자에 대하여 업무 관련 커뮤니케이션을 제공하기 위하여 수집한 개인정보를 이용합니다. 

  3. 개인정보의 제3자 제공
  회사는 이용자의 동의가 있거나 관련 법령에 특별한 규정이 있는 경우 등을 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다.

  4. 개인정보의 추가적인 이용•제공 판단 기준
  회사는 개인정보 보호법 제15조 제3항 및 제17조 제4항에 따라, 이용자의 동의 없이 개인정보를 추가적으로 이용•제공할 수 있습니다. 회사는 다음과 같은 엄격한 기준을 충분히 고려한 후에 이러한 추가적 이용•제공을 수행합니다.
  가. 개인정보를 추가적으로 이용•제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
  1) 본 개인정보 처리방침 2. 개인정보의 수집 및 이용 목적에 기재된 목적
  2) 기타 본 개인정보 처리방침 2. 개인정보의 수집 및 이용 목적에 기재된 목적과 그 성질이나 경향상 관련성 있는 개인정보 처리의 경우
  나. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 처리에 대한 예측가능성이 있는지 여부
  1) 추가적 이용•제공이 회사가 달성하고자 하는 제품 및 서비스 품질 향상이라는 목적 달성과 관련성 있는지 여부
  2) 이용약관을 통하여 이용자에게 제시된 서비스의 내용
  3) 본 개인정보 처리방침 및 동의서를 통하여 이용자에게 제시된 개인정보 수집 항목 및 목적
  4) 제품 및 서비스가 고객에게 제공되는 방식
  5) 기타 유사 업계, 제품 및 서비스의 관행 및 동향
  다. 정보주체의 이익을 부당하게 침해하는지 여부
  회사는 이용자의 이익을 부당하게 침해하지 않는 범위에서 이용자의 개인정보를 추가적으로 이•제공합니다. 회사는 개인정보의 추가적인 이용•제공으로 인하여 이용자의 사생활이 침해되거나, 이용자의 개인정보가 원치 않는 제3자에게 노출됨으로 인한 불편을 야기하거나, 기타 이용자가 예측할 수 없는 불이익이 발생하는 등 이용자의 이익이 부당하게 침해되지 않도록 합니다.
  6. 개인정보의 국외이전 
  회사는 서비스 제공 목적 및 이용자의 편의 증진 등을 위하여 이용자의 정보를 아래와 같이 국외로 전송하거나, 국외에서 관리할 수 있으며, 회사의 개인정보 국외 이전 내역은 아래와 같습니다.

  1) 이전받는 업체 및 연락처
    Amazon Web Services Inc.
    - AWS Korea Privacy
    aws-korea-privacy@amazon.com
  2)이전 국가
  일본, 미국, 아일랜드
  3)이전되는 개인정보 항목  
  서비스 이용 중 수집되는 개인정보 및 로그 정보
  4)이전받는 자의 목적, 보유∙이용기간 및 이전일시 및 방법
  - 목적: LG전자 서비스 제공을 위한 데이터 저장 및 서비스 운영 등
  - 기간: 이용자의 서비스 가입기간 동안 보유
  - 일시 및 방법: 서비스 제공 과정 중 정보통신망을 통해 수시로 이전 
  
  7. 개인정보의 보유 및 이용 기간
  회사는 이용자로부터 개인정보를 수집하는 경우 서비스 이용기간 동안 개인정보를 이용∙보관함을 원칙으로 합니다. 다만, 회사는 관계 법령의 규정 및 회사 내부 방침에 의하여 개인정보를 보존할 필요가 있는 경우 일정한 기간 동안 해당 개인 정보를 보관합니다.
  가. 상법 등 관련 법령의 규정에 의하여 일정기간 보유하여야 할 필요가 있을 경우에는 그 기간 동안 보유합니다. 그 예는 아래와 같습니다.
  ① 상업장부와 영업에 관한 중요서류에 포함된 개인정보: 10년(상법)
  ② 전표 또는 이와 유사한 서류에 포함된 개인정보: 5년(상법)
  ③ 이용자의 성명, 전화번호, 주소, 요금납부내역(청구액, 수납액, 수납 일시, 요금납부 방법 등), 기타 거래에 관한 장부: 관련 국세의 법정신고기한이 경과한 날로부터 5년(국세기본법)
  ④ 계약 또는 청약철회 등에 관한 기록: 계약 또는 청약철회 시부터 5년(전자상거래 등에서의 소비자보호에 관한 법률)
  ⑤ 대금결제 및 재화 등의 공급에 관한 기록: 공급 시부터 5년(전자상거래 등에서의 소비자보호에 관한 법률)
  ⑥ 표시광고에 관한 기록: 6개월(전자상거래 등에서의 소비자보호에 관한 법률)
  ⑦ 신용정보의 수집 처리 및 이용 등에 관한 기록: 3년(신용정보의 이용 및 보호에 관한 법률)
  ⑧ 통신사실확인자료 제공 시 이용자 확인에 필요한 성명, 전화번호 등: 12개월(통신비밀보호법)
  ⑨ 전기통신 일시, 전기통신 개시•종료 시간, 발∙착신 통신번호 등 상대방의 가입자 번호, 사용도수, 정보통신망에 접속된 정보통신기기의 위치를 확인할 수 있는 발신기지국의 위치추적자료 등: 12개월(통신비밀보호법)
  ⑩ 컴퓨터 통신 또는 인터넷의 사용자가 전기통신역무를 이용한 사실에 관한 컴퓨터 통신 또는 인터넷의 로그기록자료, 컴퓨터 통신 또는 인터넷 사용자가 정보통신망에 접속하기 위하여 사용하는 정보통신기기의 위치를 확인할 수 있는 접속지의 추적 자료: 3개월(통신비밀보호법)
  나. 개별적으로 이용자의 동의를 받은 경우: 그 동의 받은 기간
  다. 서비스가 해지 등으로 종료되었으나 이용요금(기타 거래대금을 포함)을 납부하지 않은 경우: 그 요금을 완납할 때까지
  라. 회사와 이용자 간에 민원, 소송 등 분쟁이 발생한 경우에 그 보유기간 내에 분쟁이 해결되지 않을 경우: 그 분쟁이 해결될 때까지
  8. 개인정보의 파기절차 및 방법
  회사는 이용자의 개인정보를 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 다만, 이용자의 동의를 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.

  가. 파기절차
  개인정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다. 

  나. 파기방법
  종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 

  9. 이용자 및 법정대리인의 권리와 그 행사 방법
  이용자 및 법정대리인은 개인정보 처리와 관련하여 이하의 권리를 행사할 수 있습니다. 

  가. 개인정보 열람, 정정, 삭제 요구의 권리
  이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 해당 미성년자 이용자의 개인정보를 열람하거나 정정할 수 있고 삭제를 요구할 수 있습니다. 이용자 및 법정대리인이 개인정보 열람, 정정 및 삭제를 하고자 할 경우에는 직접 열람, 정정 및 삭제하거나, 또는 회사의 개인정보보호책임자 및 담당자에게 서면 또는 전자우편으로 연락하는 경우 지체 없이 조치하도록 하겠습니다. 다만, 이용자는 다른 법령에서 그 개인정보가 수집대상으로 명시되어 있는 경우에는 삭제를 요구할 수 없으며, 개인정보를 정정∙삭제하는 경우 일부 또는 전부의 서비스 이용이 불가능할 수 있습니다.

  나. 개인정보 처리정지 요구의 권리
  이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 해당 미성년자 이용자의 개인정보의 처리정지를 요청할 수 있습니다. 개인정보 처리의 정지를 원하는 경우 회사의 개인정보보호책임자 및 담당자에게 서면 또는 전자우편으로 연락하면 지체 없이 조치하도록 하겠습니다. 다만, 회사는 관련 법령에 따라 이용자의 처리정지 요청에도 불구하고 계속하여 개인정보를 처리할 수 있으며, 이러한 경우에는 지체 없이 그 내용을 이용자 및 법정대리인에게 알려드리도록 하겠습니다. 이에 대하여 이용자 및 법정대리인은 개인정보보호책임자 및 담당자에게 서면 또는 전자우편 등의 방법을 통하여 이의를 제기할 수 있습니다.

  다. 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리
  이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 해당 미성년자 이용자의 개인정보의 수집, 이용, 제공에 대한 동의를 철회할 수 있습니다. 이를 위하여 이용자는 「회원탈퇴」 절차를 진행할 수 있고, 또는 회사의 개인정보보호책임자 및 담당자에게 서면 또는 전자우편으로 연락하여 철회를 신청할 수 있습니다. 이러한 경우 회사는 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다. 다만, 이용자가 사용 중인 스마트폰에서 LG전자 서비스 어플리케이션을 삭제하더라도, 회사에 수집된 개인정보는 삭제되지 않으므로, 개인정보 삭제는 가.항의 권리 행사 방법을 통해 회사에 별도로 요구하시기 바랍니다.

  10. 개인정보의 기술적/관리적 보호 장치
  회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

  가. 기술적 보호조치
  - 회사는 이용자의 개인정보를 암호 알고리즘을 이용하여 보호하며, 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL)를 채택하고 있습니다. 단, 광고 트래커의 경우는 제외됩니다.
  - 회사는 해킹 등 외부 침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 적정 수준의 보안성을 확보하기 위한 가능한 기술적 장치를 갖추어 개인정보 보안에 만전을 기하고 있습니다.
  - 회사는 패스워드 등 본인임을 인증하는 정보에 대해서는 일방향 암호화하여 저장합니다.
  - 회사는 개인정보 침해사고 발생에 대응하기 위해 개인정보처리시스템에 대한 접속기록을 보관하고 백업 조치 등 위조•변조 방지를 위한 조치를 취하고 있습니다.
  - 회사는 개인정보 처리 시스템에서 개인정보의 출력 시(인쇄, 화면표시, 파일생성 등) 용도를 특정하며, 용도에 따라 출력 항목을 최소화합니다.
  - 회사는 개인정보 취급자가 개인 정보를 종이로 인쇄하거나, 디스켓, 콤팩트디스크 등 이동 가능한 저장매체에 복사할 경우 개인정보 관리 책임자의 사전 승인을 받도록 조치합니다. 출력, 복사물로부터 다시 출력 또는 복사하는 경우도 또한 같습니다.

  나. 관리적 보호조치
  - 회사는 이용자의 개인정보에 대한 접근 권한을 최소한의 인원으로 제한하고 있습니다.
  - 회사는 전보 또는 퇴직 등 인사 이동이 발생하여 개인정보취급자가 변경되었을 경우에 지체 없이 개인 정보처리시스템의 접근 권한을 변경 또는 말소합니다. 이 경우 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 최소 5년 간 보관합니다. 
  - 회사는 처리 중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보취급자의 PC를 설정합니다.
  - 회사는 개인정보를 수집•이용 및 파기하는 경우 관련 법령이 권고하는 기준에 따라 수집 목적, 이용기간, 활용 정보 항목, 파기 등에 대한 근거를 남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 있습니다.
  - 회사는 개인정보를 처리하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호의무 등에 관해 정기적인 사내교육 및 외부 위탁교육을 실시하고 있습니다.
  - 회사는 모든 임직원의 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보보호정책에 대한 이행사항 및 직원의 준수여부를 감시하기 위한 내부절차를 마련하고 있습니다.
  - 회사는 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.
  - 회사는 개인정보와 개인정보가 아닌 데이터를 혼합하여 보관하지 않고 별도 분리하여 보관하고 있습니다.

  11. 개인정보관리책임자 및 민원 처리 콜센터
  회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 두고 있습니다. 이용자는 LG전자 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 민원을 개인정보관리 책임자에게 신고할 수 있으며, 회사는 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다.

  - 개인정보 보호 책임자: LG전자 정보보호담당
  - 전화번호: 1544-7777
  - 이메일: 
    1) LG계정 contact.lgaccount@lge.com
    2) ThinQ	thinq@lge.com
    3) 엘지이닷컴 privacy@lge.com


  12. 권익침해 구제방법
  이용자는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
  아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다
  - 개인정보 침해신고센터 : (국번없이)118 (https://privacy.kisa.or.kr)
  소관업무 : 개인정보 침해사실 신고, 상담 신청
  주소 : 전라남도 나주시 진흥길 9 한국인터넷진흥원
  - 개인정보 분쟁조정위원회 : 1833-6972 (www.kopico.go.kr)
  소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
  주소 : 서울특별시 종로구 세종대로 209 정부서울청사 12층
  - 대검찰청 사이버수사과 : (국번없이)1301, cid@spo.go.kr (https://spo.go.kr)
  - 경찰청 사이버수사국 : (국번없이)182 (https://ecrm.cyber.go.kr) 

  13. 기타
  회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공 받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.
  회사가 포함하고 있는 링크를 클릭하여 타 웹사이트의 페이지로 옮겨갈 경우 해당 웹사이트의 개인정보보호 정책은 회사와 무관하므로 새로 방문한 웹사이트의 정책을 검토해 보시기 바랍니다.

  14. 고지 의무
  회사는 관련 법령, 회사 내부정책 또는 보안 기술의 변경에 따라 본 개인정보 처리방침의 추가∙삭제 또는 수정이 있을 경우 아래와 같은 방법으로 사전 공지하도록 하겠습니다.
  - 인터넷 홈페이지 및 모바일 어플리케이션 공지사항 란 또는 별도의 창을 통하여 수정내용 공지
  - 이메일 또는 모바일 어플리케이션 PUSH 메시지를 통하여 수정내용 공지

  본 개인정보 처리방침은 2024년 1월 1일부터 시행합니다 . (**서비스 오픈일에 맞게 수정)
`,
};
