/**
 * @category useHooks
 * @namespace useValidation
 * @param {string} fieldName Input 공통 컴포넌트 사용 시 지정해주는 유효성 검사 타입
 * @param {string} fieldValue Input 공통 컴포넌트 사용 시 지정해주는 value 값.
 * @returns {status, isBoolean}
 * @description Input 공통 컴포넌트의 유효성 검사에 사용하는 status, isBoolean 출력.
 */

const useValidation = (fieldName, fieldValue, checkValue) => {
  /** id 유효성 검사 */
  if (fieldName === 'id') {
    if (fieldValue === '') {
      return {
        status: 'default',
        isBoolean: false,
        message: '아이디를 입력해주세요',
      };
    } else if (fieldValue.length < 5 || fieldValue.length > 12) {
      return {
        status: 'error',
        isBoolean: false,
        // message: PopUpMeg("A0006"),
        message: '글자수를 확인해주세요',
      };
    } else {
      const isReg = /^[a-z0-9]{5,12}$/.test(fieldValue);

      return {
        status: isReg ? 'success' : 'error',
        isBoolean: isReg,
        // message: isReg ? PopUpMeg("A0026") : "형식에 맞게 입력해주세요",
        message: isReg
          ? '사용가능한 아이디입니다.'
          : '형식에 맞게 입력해주세요',
      };
    }
  }

  /** email 유효성 검사 */
  if (fieldName === 'email') {
    if (fieldValue === '') {
      return {
        status: 'default',
        isBoolean: false,
      };
    } else {
      const isReg =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/.test(
          fieldValue
        );

      return {
        status: isReg ? 'success' : 'error',
        isBoolean: isReg,
      };
    }
  }

  /** password 유효성 검사 */
  if (fieldName === 'password') {
    if (fieldValue === '') {
      return {
        status: 'default',
        isBoolean: false,
        message: '글자 수를 확인해주세요.',
      };
    } else if (fieldValue.length < 8 || fieldValue.length > 16) {
      return {
        status: 'error',
        isBoolean: false,
        // message: PopUpMeg("A0006"),
        message: '글자 수를 확인해주세요.',
      };
    } else if (checkValue === fieldValue) {
      return {
        status: 'error',
        isBoolean: false,
        // message: PopUpMeg("A0027"),
        message: '사용 불가능한 패스워드 입니다.(아이디와 동일)',
      };
    } else if (/([0-9a-zA-Z])\1{2,}/.test(fieldValue)) {
      return {
        status: 'error',
        isBoolean: false,
        // message: PopUpMeg("A0027"),
        message: '사용 불가능한 패스워드 입니다.',
      };
    } else {
      return checkKoreanAndSpecialCharacters(fieldValue);
    }
  }

  /** passwordConfirmation 유효성 검사 */
  if (fieldName === 'passwordConfirmation') {
    if (fieldValue === '') {
      return {
        status: 'default',
        isBoolean: false,
      };
    } else if (fieldValue.length < 8 || fieldValue.length > 16) {
      return {
        status: 'error',
        isBoolean: false,
        // message: PopUpMeg("A0006"),
        message: '글자 수를 확인해주세요.',
      };
    } else {
      return checkKoreanAndSpecialCharacters(fieldValue, checkValue);
    }
  }

  /** phone(폰 번호) 유효성 검사 */
  if (fieldName === 'phone') {
    if (fieldValue === '') {
      return { status: 'default', isBoolean: false };
    } else {
      const isReg = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/.test(fieldValue);

      return {
        status: isReg ? 'success' : 'error',
        isBoolean: isReg,
      };
    }
  }
};

function checkKoreanAndSpecialCharacters(fieldValue, password) {
  const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  const specialCharactersRegex = /[\{\}\[\]\/?.,;:|)~`\-_+<>\\=('\"]/g;
  const includeMinimumTwoCharRegex =
    /^(?=.*[a-z])(?=.*[A-Z]|.*[!@#$%^&*()_+]|.*\d)[a-zA-Z\d!@#$%^&*()_+]{8,}$/;

  if (koreanRegex.test(fieldValue)) {
    return {
      status: 'error',
      isBoolean: false,
      message: '한국어 문자가 포함되었습니다.',
    };
  }
  if (specialCharactersRegex.test(fieldValue)) {
    return {
      status: 'error',
      isBoolean: false,
      message: '유효하지 않은 특수문자가 포함되었습니다.',
    };
  }
  // TODO: test
  if (!includeMinimumTwoCharRegex.test(fieldValue)) {
    return {
      status: 'error',
      isBoolean: false,
      message:
        '영대문자, 영소문자, 숫자, 특수문자 중 최소 두 가지 이상을 포함 해주세요.',
    };
  }
  if (password === '') {
    return {
      status: 'error',
      isBoolean: false,
      message: '비밀번호를 입력해주세요.',
    };
  }
  if (password && password === fieldValue) {
    return {
      status: 'success',
      isBoolean: true,
      message: '확인되었습니다.',
    };
  }
  if (password && password !== fieldValue) {
    return {
      status: 'error',
      isBoolean: false,
      message: '비밀번호가 일치하지 않습니다.',
    };
  }

  return {
    status: 'success',
    isBoolean: true,
    message: '확인되었습니다.',
  };
}

export default useValidation;
