import classNames from 'classnames/bind';
import css from './NoticeListSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { Button } from '@components/index';
import { useFormContext } from 'react-hook-form';
import HFInput from '@components/_ReactHookForm/HFInput';

const cn = classNames.bind(css);

const NoticeListSearchArea = ({ selectSearchOptions, onSearchClick }) => {
  const { register, control, handleSubmit } = useFormContext();

  return (
    <div className={css.flex}>
      <div className={cn('innerBox', 'option_detail')}>
        <strong className={css.title}>조회 항목</strong>
        <div className={cn('selectbox', 'type01', 'sizeS')}>
          <HFSelectBox
            control={control}
            name={'selectSearchCondition'}
            options={selectSearchOptions}
          />
        </div>

        <div className={css.inputWrap}>
          <form className={css.form}>
            <HFInput
              register={register}
              name={'keyword'}
              type={'text'}
              placeholder={'검색 조건을 선택해 주세요'}
            />
            <Button
              styleType={'btn01'}
              subType={'type03'}
              className={cn('btn')}
              onClick={handleSubmit(onSearchClick)}
            >
              검색
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NoticeListSearchArea;
