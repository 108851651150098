import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // 로그인 정보
  userData: {
    userId: '',
    password: '',
    authenticationFlag: 'N',
    passwordChangeRequired: true,
    authority: '',
    companyCategory: '',
    companyName: '',
    departmentName: '',
    email: '',
    phoneNo: '',
    isLogined: false,
    lastLoginDatetime: '',
    vendorKey: '',
    companyCode: '',
    companyBizName: '',
    companyRegNo: '',
    companyCeoName: '',
    companyOpenDate: '',
    supportPhoneNo: '',
    bizRegDocFileKey: '',
    bizRegDocFileName: '',
    ecomRegDocFileKey: '',
    ecomRegDocFileName: '',
  },
  myAuthority: {
    approvalDateAccount: '',
    approvalDateEmail: '',
    approvalDatePhone: '',
    approvalStatusAccount: '',
    approvalStatusEmail: '',
    approvalStatusPhone: '',
    approvalUserIdAccount: '',
    approvalUserIdEmail: '',
    approvalUserIdPhone: '',
    bizRegisDocFileKey: '',
    bizRegisDocFileName: '',
    commentApprovalAccount: '',
    commentApprovalEmail: '',
    commentApprovalPhone: '',
    companyCategory: '',
    companyCode: '',
    companyName: '',
    companyRegNo: '',
    delFlag: '',
    departmentName: '',
    ecomRegisDocFileKey: '',
    ecomRegisDocFileName: '',
    email: '',
    emailChangedFlag: '',
    joinDate: '',
    phoneNo: '',
    phoneNoChangedFlag: '',
    userHistKey: '',
    userId: '',
    vendorKey: '',
  },
  accessiblePagelist: {},
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const payload = Object.assign({}, action.payload, {
        isLogined: true,
      });
      state.userData = { ...state.userData, ...payload };
    },
    setMyAuthority: (state, action) => {
      state.myAuthority = action.payload;
    },
    setAuthenticationFlag: (state, action) => {
      state.userData.authenticationFlag = action.payload;
    },
    setAccessiblePageList: (state, action) => {
      state.accessiblePagelist = action.payload;
    },
    setLoginStatusFalse: (state, action) => {
      state.userData.isLogined = action.payload;
    },
    refreshUserData: (state, action) => {
      state.userData = initialState.userData;
    },
  },
});

export const {
  setUserData,
  setMyAuthority,
  setAuthenticationFlag,
  setAccessiblePageList,
  setLoginStatusFalse,
  refreshUserData,
} = user.actions;

export default user.reducer;
