import { useState, memo, useEffect } from 'react';
import css from './NoticeRegister.module.scss';
import classNames from 'classnames/bind';
import {
  Button,
  Input,
  Checkbox,
  VerticalTable,
  VerticalTableContainer,
  VerticalTableLabel,
  Calendar,
  TextArea,
} from '@components/index';
import { useInput } from '@hooks';
import { setNoticeForm } from '@feature/notice/noticeSlice';
import { postUpdateNotice, postDeleteNotice } from 'redux/action/noticeAction';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useSelect } from '@hooks';
import {
  isHoliday,
  isWeekDay,
  calculateOffsetBusinessDate,
  replaceImgUploadUrl,
} from '@utils';
import QuillEditor from '@components/QuillEditor/QuillEditor';
import { convertDateToString2 } from 'utils/utils';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const DISABLED_DATE_TYPE = {
  WEEKEND: 'weekend',
  HOLIDAY: 'holiday',
};

const DISABLED_DATE_TYPE_LIST = ['weekend', 'holiday'];
const OFFSET = 3;

/**
 * @category components
 * @namespace NoticeEdit
 * @description 공지사항 수정
 */
const NoticeEdit = ({ onRequestClose, currentNotice }) => {
  const dispatch = useDispatch();
  const formList = useSelector((state) => state.notice.form);
  const userData = useSelector((state) => state.user.userData);
  const holidaysOfKorea = useSelector(
    ({ holidays: { holidaysOfKorea } }) => holidaysOfKorea
  );
  const combinedStartDateTimeString = `${currentNotice.popupFromDate} ${currentNotice.popupFromTime}`;
  const combinedEndDateTimeString = `${currentNotice.popupToDate} ${currentNotice.popupToTime}`;

  const [startDate, setStartDate] = useState(
    new Date(combinedStartDateTimeString)
  );
  const [endDate, setEndDate] = useState(new Date(combinedEndDateTimeString));
  const [maxEndDate, setMaxEndDate] = useState(
    new Date(combinedEndDateTimeString)
  );
  // noticeFixedFlag "Y" / "N"
  const [noticeFixedFlag, setNoticeFixedFlag] = useState(
    currentNotice.noticeFixedFlag === 'Y' ? true : false
  );

  const [subject, setSubject, onChangeSubject] = useInput(
    currentNotice.subject,
    false,
    setNoticeForm
  );

  const handlePopupSelect = useCallback(() => {
    setPopupFlag(true);
  }, []);

  const [popupContent, setPopupContent, onChangePopupContent] = useInput(
    currentNotice.popupContent,
    false,
    setNoticeForm,
    handlePopupSelect
  );

  //popupFlag "Y" / "N"
  const [popupFlag, setPopupFlag] = useState(
    currentNotice.popupFlag === 'Y' ? true : false
  );

  const handleFixedFlag = useCallback(() => {
    const fixedFlag = !noticeFixedFlag;
    setNoticeFixedFlag(fixedFlag);

    const payLoad = {
      key: 'noticeFixedFlag',
      value: fixedFlag ? 'Y' : 'N',
    };
    dispatch(setNoticeForm(payLoad));
    //eslint-disable-next-line
  }, [noticeFixedFlag]);

  const handleCancel = useCallback(() => {
    onRequestClose();
  }, []);

  const handleSave = useCallback(async () => {
    const cloudfrontToS3 = Object.assign({}, formList, {
      content: replaceImgUploadUrl('cloudfrontToS3', formList.content),
    });
    postUpdateNotice(dispatch, cloudfrontToS3);
    onRequestClose();
    //eslint-disable-next-line
  }, [formList]);

  const handleDelete = useCallback(() => {
    // dispatch(openModalAlert({ message: '삭제되었습니다.' }));
    postDeleteNotice(dispatch, {
      noticeKey: currentNotice.noticeKey,
      userId: userData.userId,
    });
    onRequestClose();
    //eslint-disable-next-line
  }, [dispatch, onRequestClose, currentNotice, userData]);

  const handlePopupFlag = useCallback(() => {
    const flag = !popupFlag;
    setPopupFlag(flag);

    const payLoad = {
      key: 'popupFlag',
      value: flag ? 'Y' : 'N',
    };
    dispatch(setNoticeForm(payLoad));
    //eslint-disable-next-line
  }, [popupFlag]);

  const isBusinessDay = useCallback(
    (date) => {
      if (!DISABLED_DATE_TYPE_LIST.length) return true;

      for (const type of DISABLED_DATE_TYPE_LIST) {
        switch (type) {
          case DISABLED_DATE_TYPE.WEEKEND:
            if (!isWeekDay(date)) return false;
            break;
          case DISABLED_DATE_TYPE.HOLIDAY:
            if (isHoliday({ holidays: holidaysOfKorea, date })) return false;
            break;
          default:
            break;
        }
      }
      return true;
    },
    [holidaysOfKorea]
  );

  // 날짜 선택 이벤트 핸들러
  const handleStartDate = (date) => {
    setStartDate(date);
    handlePopupSelect();

    const AFTER_OFFSET_DATE = calculateOffsetBusinessDate({
      isBusinessDayValidFn: isBusinessDay,
      date,
      offset: OFFSET,
      operator: 'plus',
    });

    setEndDate(AFTER_OFFSET_DATE);
    setMaxEndDate(AFTER_OFFSET_DATE);

    const params = [
      {
        key: 'popupFromDate',
        value: convertDateToString2(date),
      },
      {
        key: 'popupToDate',
        value: convertDateToString2(AFTER_OFFSET_DATE),
      },
    ];

    params.map((item) => dispatch(setNoticeForm(item)));
  };

  const handleEndDate = (date) => {
    const params = {
      key: 'popupToDate',
      value: convertDateToString2(date),
    };
    dispatch(setNoticeForm(params));

    setEndDate(date);
    handlePopupSelect();
  };

  useEffect(() => {
    const defaultSetting = [
      {
        key: 'popupFromDate',
        value: currentNotice.popupFromDate,
      },
      {
        key: 'popupToDate',
        value: currentNotice.popupToDate,
      },
      {
        key: 'popupFromTime',
        value: currentNotice.popupFromTime,
      },
      {
        key: 'popupToTime',
        value: currentNotice.popupToTime,
      },
      {
        key: 'popupReasonCode',
        value: currentNotice.popupReasonCode,
      },
      {
        key: 'popupFlag',
        value: currentNotice.popupFlag,
      },
      {
        key: 'subject',
        value: currentNotice.subject,
      },
      {
        key: 'popupContent',
        value: currentNotice.popupContent,
      },
      {
        key: 'content',
        value: currentNotice.content,
      },
      {
        key: 'noticeKey',
        value: currentNotice.noticeKey,
      },
      {
        key: 'noticeFixedFlag',
        value: currentNotice.noticeFixedFlag,
      },
      {
        key: 'companyCode',
        value: currentNotice.companyCode,
      },
      {
        key: 'userId',
        value: userData.userId,
      },
    ];
    defaultSetting.map((item) => dispatch(setNoticeForm(item)));
  }, [dispatch, currentNotice, userData]);

  return (
    <>
      <strong className={css.popup_title}>공지사항 수정</strong>
      <VerticalTableContainer
        button={[
          <div className={css.buttonContainer}>
            <Button
              key='cancel'
              onClick={handleCancel}
              className={cn('btn01', 'type03')}
            >
              취소
            </Button>
            <Button
              key='delete'
              onClick={handleDelete}
              className={cn('btn01', 'type01')}
            >
              삭제
            </Button>
            <Button
              key='save'
              onClick={handleSave}
              className={cn('btn01', 'type02')}
            >
              저장
            </Button>
          </div>,
        ]}
      >
        <VerticalTable title={'게시 정보'}>
          <div className={css.contentBox}>
            <VerticalTableLabel size='auto'>등록</VerticalTableLabel>

            <div className={css.content}>
              <Input
                type='text'
                size='auto'
                readOnly='true'
                value={currentNotice.createUserCompanyName}
              />
            </div>
          </div>
        </VerticalTable>
        <VerticalTable
          multiTitle={['제목']}
          isMultiRow={true}
          multiItem={[
            <Input
              type='text'
              value={subject}
              name={'subject'}
              onChange={onChangeSubject}
              placeholder={'제목을 입력하세요'}
              hasByte={true}
              maxByte={100}
            />,
            <Checkbox
              id={'fixTop'}
              onClick={() => handleFixedFlag()}
              checked={noticeFixedFlag}
              className={css.checkbox}
              readOnly
            >
              상단고정
            </Checkbox>,
          ]}
        ></VerticalTable>
        <VerticalTable title={['팝업 설정']}>
          <div className={css.popupContainer}>
            <Checkbox
              id='popupFlag'
              onClick={() => {
                handlePopupFlag();
              }}
              checked={popupFlag}
              readOnly
              className={css.checkbox}
            >
              출력
            </Checkbox>
            <div className={css.calendarContainer}>
              {/* FIXME: NoticeRegister 처럼 Calendar로 수정 필요 */}
              <Calendar
                hasTitle={false}
                hasPeriodInput={false}
                startDate={startDate}
                handleStartDate={handleStartDate}
                setStartDate={setStartDate}
                endDate={endDate}
                handleEndDate={handleEndDate}
                setEndDate={setEndDate}
                disabledDateTypeList={DISABLED_DATE_TYPE_LIST}
                minDate={startDate}
                maxDate={null}
                maxEndDate={maxEndDate}
              />
            </div>
          </div>
        </VerticalTable>
        <VerticalTable title={['팝업 내용']}>
          <TextArea
            type='text'
            value={popupContent}
            name={'popupContent'}
            onChange={onChangePopupContent}
            placeholder={'팝업 내용을 입력하세요'}
            hasByte={true}
            maxByte={400}
          />
        </VerticalTable>
        <VerticalTable title={'텍스트 에디터'}>
          <QuillEditor
            action={setNoticeForm}
            prevValue={currentNotice.content}
          />
        </VerticalTable>
        <div className={css.fontLeft}>
          <VerticalTable title={['발행(수정)자']}>
            <div className={css.marginTop}>
              {currentNotice.updateUserId
                ? currentNotice.updateUserId
                : currentNotice.createUserId}
            </div>
          </VerticalTable>
        </div>
        <div className={css.fontLeft}>
          <VerticalTable title={['최근 수정일시']}>
            <div className={css.marginTop}>
              {currentNotice.updateDatetime
                ? currentNotice.updateDatetime
                : currentNotice.createDatetime}
            </div>
          </VerticalTable>
        </div>
        <div className={css.btn_container}></div>
      </VerticalTableContainer>
    </>
  );
};

export default memo(NoticeEdit);
