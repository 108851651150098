import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  deletedList: [],
  autoSendList: [],
  totalCount: '',
  deletedTotalCount: '',
};

const mail = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    setMailList: (state, action) => {
      state.list = action.payload;
    },
    setDeletedList: (state, action) => {
      state.deletedList = action.payload;
    },
    setAutoSendList: (state, action) => {
      state.autoSendList = action.payload;
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setDeletedTotalCount: (state, action) => {
      state.deletedTotalCount = action.payload;
    },
  },
});

export const {
  setMailList,
  setDeletedList,
  setAutoSendList,
  setTotalCount,
  setDeletedTotalCount,
} = mail.actions;

export default mail.reducer;
