import React, { useState, useMemo } from 'react';
import css from './StepProgressTab.module.scss';
import classNames from 'classnames/bind';

const cn = classNames.bind(css);

const PROGRESS = [
  { title: '가입 조회', tabIndex: 0 },
  { title: '약관 동의', tabIndex: 1 },
  { title: '정보 입력', tabIndex: 2 },
];

const StepProgressTab = ({ stepIndex }) => {
  return (
    <ul className={cn('progress')}>
      {PROGRESS.map((progress, idx) => {
        return (
          <li
            key={progress.title}
            className={cn(
              stepIndex === idx && 'on',
              stepIndex !== idx && stepIndex > progress.tabIndex && 'check'
            )}
          >
            {progress.title}
          </li>
        );
      })}
    </ul>
  );
};

export default StepProgressTab;
