import React, { useCallback, useMemo } from 'react';
import css from './AuthorityTable.module.scss';
import { memo } from 'react';
import classNames from 'classnames/bind';
import { useInput } from '@hooks/';
import { updateAuthority } from '@feature/Authority/AuthoritySlice';
import { useSelector, useDispatch } from 'react-redux';

import { postUserAuthority } from 'redux/action/AuthorityAction';
import { AUTH_ACCESS_MAP, AUTH_SUB_ACCESS_MAP } from '@data/listMap';
import { Button } from '@components/';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);
export const AuthorityTable = ({}) => {
  const dispatch = useDispatch();
  const accessControlList = useSelector(
    (state) => state.authority.accessControlList
  );
  const changeAccessControlTarget = useSelector(
    (state) => state.authority.changeAccessControlTarget
  );

  const [CheckboxValue, setCheckboxValue, onChangeCheckbox] = useInput(
    '',
    false,
    updateAuthority
  );

  const handleUpdateAuthClick = useCallback(() => {
    const params = {
      authList: changeAccessControlTarget.authList,
    };
    const transformedParams = {
      authList: JSON.stringify(params.authList),
    };
    postUserAuthority(dispatch, transformedParams);
  }, [changeAccessControlTarget]);

  const CheckboxCell = useCallback(
    (levelKey, category, subCategory, level) => {
      const targetLevelFlag =
        accessControlList[category][subCategory][`${level}Flag`];
      const targetId = `${category},${subCategory},${level}Flag`;
      return (
        <div className={cn('input_check', 'reverse')}>
          <div className={css.checkboxDivider}>
            <input
              type='checkbox'
              name={`${levelKey}`}
              id={targetId}
              checked={targetLevelFlag === 'Y' ? true : false}
              onChange={onChangeCheckbox}
            />
            <label htmlFor={targetId}></label>
          </div>
        </div>
      );
    },
    [accessControlList, onChangeCheckbox]
  );

  const generateTable = useMemo(() => {
    return (
      Object.keys(accessControlList).length > 0 && (
        <table className={css.table}>
          <thead className={css.theader}>
            <tr className={css.tbodyRow} key={`authTable-header`}>
              <td className={css.tbodyList} rowSpan={2}>
                NO
              </td>
              <td className={css.tbodyList} rowSpan={2}>
                depth1
              </td>
              <td className={css.tbodyList} rowSpan={2}>
                depth2
              </td>
              <td className={css.tbodyLast} colSpan={3}>
                접근권한
              </td>
            </tr>
            <tr className={css.tbodyRow} key={`authTable-header-level`}>
              <td className={css.tbodyList}>LEVEL1</td>
              <td className={css.tbodyList}>LEVEL2</td>
              <td className={css.tbodyLast}>LEVEL3</td>
            </tr>
          </thead>
          <tbody className={css.tbody}>
            {AUTH_ACCESS_MAP.map((category, index) => {
              let firstSubCategory = AUTH_SUB_ACCESS_MAP[category][0];
              return (
                <>
                  <tr className={css.tbodyRow} key={`authTable-${category}`}>
                    <td
                      className={css.tbodyList}
                      rowSpan={AUTH_SUB_ACCESS_MAP[category].length}
                    >
                      {index + 1}
                    </td>
                    <td
                      className={css.tbodyTitle}
                      rowSpan={AUTH_SUB_ACCESS_MAP[category].length}
                    >
                      {category}
                    </td>
                    <td className={css.tbodyList}>{firstSubCategory}</td>
                    <td className={css.tbodyList}>
                      {CheckboxCell(
                        accessControlList[category][firstSubCategory].level1Key,
                        category,
                        firstSubCategory,
                        'level1'
                      )}
                    </td>
                    <td className={css.tbodyList}>
                      {CheckboxCell(
                        accessControlList[category][firstSubCategory].level2Key,
                        category,
                        firstSubCategory,
                        'level2'
                      )}
                    </td>
                    <td className={css.tbodyLast}>
                      {CheckboxCell(
                        accessControlList[category][firstSubCategory].level3Key,
                        category,
                        firstSubCategory,
                        'level3'
                      )}
                    </td>
                  </tr>
                  {AUTH_SUB_ACCESS_MAP[category].map(
                    (subCategory, subIndex) => {
                      return (
                        subIndex > 0 && (
                          <tr
                            className={css.tbodyRow}
                            key={`authTable-${subCategory}`}
                          >
                            <td className={css.tbodyList}>{subCategory}</td>
                            <td className={css.tbodyList}>
                              {CheckboxCell(
                                accessControlList[category][subCategory]
                                  .level1Key,
                                category,
                                subCategory,
                                'level1'
                              )}
                            </td>
                            <td className={css.tbodyList}>
                              {CheckboxCell(
                                accessControlList[category][subCategory]
                                  .level2Key,
                                category,
                                subCategory,
                                'level2'
                              )}
                            </td>
                            <td className={css.tbodyLast}>
                              {CheckboxCell(
                                accessControlList[category][subCategory]
                                  .level3Key,
                                category,
                                subCategory,
                                'level3'
                              )}
                            </td>
                          </tr>
                        )
                      );
                    }
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      )
    );
  }, [accessControlList]);

  return (
    <>
      <div>
        <h3>Level별 접근 권한</h3>
      </div>
      <div className={css.buttonContainer}>
        <Button
          onClick={() => handleUpdateAuthClick()}
          styleType={'btn03'}
          subType={'type02'}
        >
          수정
        </Button>
      </div>
      <div className={css.authTableContainer}>{generateTable}</div>
    </>
  );
};

export default memo(AuthorityTable);
