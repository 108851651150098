import React, { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames/bind';
import css from './DeliveryRegSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { useFormContext, useWatch } from 'react-hook-form';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';
import { Button } from '@components/index';
import HFInput from '@components/_ReactHookForm/HFInput';

const cn = classNames.bind(css);

const DeliveryRegSearchArea = forwardRef(
  ({ messageTypeOptions, onSearchClick }, ref) => {
    const { register, control, watch, handleSubmit } = useFormContext();

    useImperativeHandle(ref, () => ({
      getSelectBoxWatch: () => watch('messageTypeOptions', 'default'),
      getRadioAllOrFailWatch: () => watch('radioAllOrFail'),
    }));

    return (
      <div className={cn('searchOptionArea', 'mt20')}>
        <div className={cn('innerBox')}>
          <strong className={cn('title')}>메시지 유형</strong>
          <HFSelectBox
            control={control}
            name={'messageTypeOptions'}
            options={messageTypeOptions}
          />
        </div>
        <div className={cn('innerBox')}>
          <strong className={cn('title')}>상세조건</strong>
          <HFInput
            register={register}
            name={'keyword'}
            type={'text'}
            className={cn('input_text')}
            placeholder='제목'
          />
          <Button
            styleType={'btn01'}
            subType={'type03'}
            className={cn('btn', 'btn01', 'type03', 'ml20')}
            onClick={handleSubmit(onSearchClick)}
          >
            검색
          </Button>
        </div>
      </div>
    );
  }
);

export default DeliveryRegSearchArea;
