import { APPROVAL_TARGET, PERMISSIONS } from '@data/Const';
import { createSlice } from '@reduxjs/toolkit';
import { MakeTwoDigitDate, formatDateTime } from '@utils';

export const VendorMasterInterface = {
  companyCode: '',
  gerpBillToCode: '',
  approvalStatus: '',
  companyOpenDate: '',
  approvalDate: '',
  companyName: '',
  companyRegNo: '',
  ecomRegisDocFileName: '',
  delFlag: '',
  ecomRegisDocFileKey: '',
  vendorHistKey: '',
  bizRegisDocFileKey: '',
  vendorKey: '',
  insertDatetime: '',
  gerpVendorCode: '',
  bizRegisDocFileName: '',
  companyCeoName: '',
  commentRequest: '',
  approvalUserId: '',
  commentApproval: '',
};

export const NewVendorInterface = {
  companyCode: '',
  gerpBillToCode: '',
  approvalStatus: '',
  companyName: '',
  companyRegNo: '',
  ecomRegisDocFileName: '',
  approvalStatusDesc: '',
  ecomRegisDocFileKey: '',
  bizRegisDocFileKey: '',
  vendorKey: '',
  gerpVendorCode: '',
  bizRegisDocFileName: '',
  commentApproval: '',
};

export const VendorAuthorityInterface = {
  commentApprovalAccount: '',
  commentApprovalEmail: '',
  companyName: '',
  companyRegNo: '',
  approvalDateAccount: '',
  approvalStatusPhone: '',
  ecomRegisDocFileName: '',
  delFlag: '',
  bizRegisDocFileKey: '',
  userHistKey: '',
  joinDate: '',
  phoneNoChangedFlag: '',
  commentApprovalPhone: '',
  approvalUserIdAccount: '',
  email: '',
  companyCode: '',
  departmentName: '',
  approvalDatePhone: '',
  approvalStatusAccount: '',
  companyCategory: '',
  approvalUserIdEmail: '',
  phoneNo: '',
  emailChangedFlag: '',
  userId: '',
  approvalUserIdPhone: '',
  ecomRegisDocFileKey: '',
  approvalStatusEmail: '',
  vendorKey: '',
  bizRegisDocFileName: '',
  approvalDateEmail: '',
};

export const InternalAuthorityInterface = {
  approvalDateAccount: '',
  approvalDateEmail: '',
  approvalDatePhone: '',
  approvalStatusAccount: '',
  approvalStatusEmail: '',
  approvalStatusPhone: '',
  approvalUserIdAccount: '',
  approvalUserIdEmail: '',
  approvalUserIdPhone: '',
  bizRegisDocFileKey: '',
  bizRegisDocFileName: '',
  commentApprovalAccount: '',
  commentApprovalEmail: '',
  commentApprovalPhone: '',
  companyCategory: '',
  companyCode: '',
  companyName: '',
  companyRegNo: '',
  delFlag: '',
  departmentName: '',
  ecomRegisDocFileKey: '',
  ecomRegisDocFileName: '',
  email: '',
  emailChangedFlag: '',
  joinDate: '',
  phoneNo: '',
  phoneNoChangedFlag: '',
  userHistKey: '',
  userId: '',
  vendorKey: '',
};

const initialState = {
  tableData: [
    {
      No: 1,
      아이디: 'User555(마스터)',
      사업자명: '런드리고',
      사업자번호: '248-81-24867',
      담당자명: '홍길동',
      휴대전화: '111-1234-5678',
      이메일: '555@abc.com',
      사업자등록증: 'image.png',
      통신판매: 'image.png',
      가입일자: '2023-11-07',
      승인일자: '2023-08-24',
      상태: '승인대기',
      삭제: '삭제',
      비고: '-',
      selected: false,
    },
    {
      No: 2,
      아이디: 'User555(마스터',
      사업자명: '런드리고',
      사업자번호: '248-81-24867',
      담당자명: '김딤김',
      휴대전화: '222-1234-5678',
      이메일: '555@abc.com',
      사업자등록증: 'image.png',
      통신판매: 'image.png',
      가입일자: '2023-11-06',
      승인일자: '2023-08-24',
      상태: '승인대기',
      삭제: '삭제',
      비고: '-',
      selected: false,
    },
    {
      No: 3,
      아이디: 'User555(마스터',
      사업자명: '런드리고',
      사업자번호: '010-81-24867',
      담당자명: '지선민',
      휴대전화: '010-1234-5678',
      이메일: '555@abc.com',
      사업자등록증: 'image.png',
      통신판매: 'image.png',
      가입일자: '2023-11-06',
      승인일자: '2023-08-24',
      상태: '승인대기',
      삭제: '삭제',
      비고: '-',
      selected: false,
    },
  ],
  authorityManage: {
    test01: {
      userId: 'test01',
      authorityLevel: 'LEVEL_3_MASTER',
      productMenu1Write: 'Y',
      productMenu1Read: 'Y',
      productMenu2Write: 'Y',
      productMenu2Read: 'Y',
      deliveryMenu1Write: 'Y',
      deliveryMenu1Read: 'Y',
      deliveryMenu2Write: 'Y',
      deliveryMenu2Read: 'Y',
      couponMenu1Write: 'Y',
      couponMenu1Read: 'Y',
      couponMenu2Write: 'Y',
      couponMenu2Read: 'Y',
      couponMenu3Write: 'Y',
      couponMenu3Read: 'Y',
      refundMenu1Write: 'Y',
      refundMenu1Read: 'Y',
      refundMenu2Write: 'Y',
      refundMenu2Read: 'Y',
      refundMenu3Write: 'Y',
      refundMenu3Read: 'Y',
      refundMenu4Write: 'Y',
      refundMenu4Read: 'Y',
      refundMenu5Write: 'Y',
      refundMenu5Read: 'Y',
      settlementMenu1Write: 'Y',
      settlementMenu1Read: 'Y',
      settlementMenu2Write: 'Y',
      settlementMenu2Read: 'Y',
      settlementMenu3Write: 'Y',
      settlementMenu3Read: 'Y',
    },

    testUser: {
      userId: 'testUser',
      authorityLevel: 'LEVEL_3_MASTER',
      productMenu1Write: 'Y',
      productMenu1Read: 'Y',
      productMenu2Write: 'Y',
      productMenu2Read: 'Y',
      deliveryMenu1Write: 'Y',
      deliveryMenu1Read: 'Y',
      deliveryMenu2Write: 'Y',
      deliveryMenu2Read: 'Y',
      couponMenu1Write: 'Y',
      couponMenu1Read: 'Y',
      couponMenu2Write: 'Y',
      couponMenu2Read: 'Y',
      couponMenu3Write: 'Y',
      couponMenu3Read: 'Y',
      refundMenu1Write: 'Y',
      refundMenu1Read: 'Y',
      refundMenu2Write: 'Y',
      refundMenu2Read: 'Y',
      refundMenu3Write: 'Y',
      refundMenu3Read: 'Y',
      refundMenu4Write: 'Y',
      refundMenu4Read: 'Y',
      refundMenu5Write: 'Y',
      refundMenu5Read: 'Y',
      settlementMenu1Write: 'Y',
      settlementMenu1Read: 'Y',
      settlementMenu2Write: 'Y',
      settlementMenu2Read: 'Y',
      settlementMenu3Write: 'Y',
      settlementMenu3Read: 'Y',
    },
  },
  newVendorList: [],
  vendorAuthorityList: {
    '': VendorAuthorityInterface,
  },
  internalAuthorityList: {
    '': InternalAuthorityInterface,
  },
  accessControlList: {},
  changeAccessControlTarget: {
    authList: [],
  },
  accessOriginSetting: [],
  VendorMasterList: [],
  csmsNewVendorMasterList: [],
};

export const AuthorityData = createSlice({
  name: 'AuthorityData',
  initialState,
  reducers: {
    setAuthorityData: (state, action) => {
      state.tableData = action.payload;
    },
    setVendorAuthority: (state, action) => {
      state.vendorAuthorityList = action.payload;
    },
    setInternalAuthority: (state, action) => {
      state.internalAuthorityList = action.payload;
    },

    updateAuthority: (state, action) => {
      const { id, key, checked } = action.payload;
      const parts = id.split(',');
      const [category, subCategory, levelFlag] = parts.map((part) =>
        part.replace(',', '')
      );
      const cloneArr = [...state.changeAccessControlTarget.authList];

      if (cloneArr.length > 0) {
        const existTargetIndex = cloneArr.findIndex((obj) => key in obj);
        if (existTargetIndex !== -1) {
          cloneArr.splice(existTargetIndex, 1, { [key]: checked });
        } else {
          cloneArr.push({ [key]: checked });
        }
      } else {
        cloneArr.push({ [key]: checked });
      }

      state.accessControlList[category][subCategory][levelFlag] = checked;
      state.changeAccessControlTarget.authList = cloneArr;
    },
    updateVendorAuthorityList: (state, action) => {
      const {
        payload: {
          approvalTarget,
          userId,
          approvalStatus,
          commentApproval,
          approvalUserId,
          changedEmail,
          changedPhoneNo,
        },
      } = action;

      const TODAY = new Date();
      const year = TODAY.getFullYear();
      const month = MakeTwoDigitDate(TODAY.getMonth() + 1);
      const date = MakeTwoDigitDate(TODAY.getDate() + 1);
      const currentDate = `${year}-${month}-${date}`;

      const vendorAuthorityList = { ...state.vendorAuthorityList };

      switch (approvalTarget) {
        case APPROVAL_TARGET.ACCOUNT:
          vendorAuthorityList[userId] = {
            ...vendorAuthorityList[userId],
            approvalStatusAccount: approvalStatus,
            commentApprovalAccount: commentApproval,
            approvalUserIdAccount: approvalUserId,
            approvalDateAccount:
              approvalStatus === PERMISSIONS.APPROVED ? currentDate : '',
            phoneNo: changedPhoneNo,
            email: changedEmail,
          };
          break;
        case APPROVAL_TARGET.EMAIL:
          vendorAuthorityList[userId] = {
            ...vendorAuthorityList[userId],
            approvalStatusEmail: approvalStatus,
            commentApprovalEmail: commentApproval,
            approvalUserIdEmail: approvalUserId,
            approvalDateEmail: currentDate,
            phoneNo: changedPhoneNo,
            email: changedEmail,
          };
          break;
        case APPROVAL_TARGET.PHONE:
          vendorAuthorityList[userId] = {
            ...vendorAuthorityList[userId],
            approvalStatusPhone: approvalStatus,
            commentApprovalPhone: commentApproval,
            approvalUserIdPhone: approvalUserId,
            approvalDatePhone: currentDate,
            phoneNo: changedPhoneNo,
            email: changedEmail,
          };
          break;
        default:
          break;
      }

      state.vendorAuthorityList = vendorAuthorityList;
    },
    updateInternalAuthorityList: (state, action) => {
      const {
        payload: {
          approvalStatus,
          approvalUserId,
          commentApproval,
          userId,
          changedEmail,
          changedPhoneNo,
        },
      } = action;

      const TODAY = new Date();
      const year = TODAY.getFullYear();
      const month = MakeTwoDigitDate(TODAY.getMonth() + 1);
      const date = MakeTwoDigitDate(TODAY.getDate() + 1);
      const currentDate = `${year}-${month}-${date}`;

      const internalAuthorityList = { ...state.internalAuthorityList };
      internalAuthorityList[userId] = {
        ...internalAuthorityList[userId],
        approvalStatusAccount: approvalStatus,
        commentApprovalAccount: commentApproval,
        approvalUserIdAccount: approvalUserId,
        phoneNo: changedPhoneNo,
        email: changedEmail,
        approvalDateAccount:
          approvalStatus === PERMISSIONS.APPROVED ? currentDate : '',
      };

      state.internalAuthorityList = internalAuthorityList;
    },
    deleteVendorAuthorityList: (state, action) => {
      const vendorAuthorityList = { ...state.vendorAuthorityList };
      delete vendorAuthorityList[action.payload];
      state.vendorAuthorityList = vendorAuthorityList;
    },
    deleteInternalAuthorityList: (state, action) => {
      const internalAuthorityList = { ...state.internalAuthorityList };
      delete internalAuthorityList[action.payload];
      state.internalAuthorityList = internalAuthorityList;
    },
    setAccessControlList: (state, action) => {
      let targetObj = {};
      action.payload.forEach((item) => {
        if (!targetObj[item.depth1Desc]) {
          targetObj[item.depth1Desc] = {};
          targetObj[item.depth1Desc][item.depth2Desc] = {};
          targetObj[item.depth1Desc][item.depth2Desc].level1Key =
            item.level1Key;
          targetObj[item.depth1Desc][item.depth2Desc].level1Flag =
            item.level1Flag;
          targetObj[item.depth1Desc][item.depth2Desc].level2Key =
            item.level2Key;
          targetObj[item.depth1Desc][item.depth2Desc].level2Flag =
            item.level2Flag;
          targetObj[item.depth1Desc][item.depth2Desc].level3Key =
            item.level3Key;
          targetObj[item.depth1Desc][item.depth2Desc].level3Flag =
            item.level3Flag;
        } else {
          targetObj[item.depth1Desc][item.depth2Desc] = {};
          targetObj[item.depth1Desc][item.depth2Desc].level1Key =
            item.level1Key;
          targetObj[item.depth1Desc][item.depth2Desc].level1Flag =
            item.level1Flag;
          targetObj[item.depth1Desc][item.depth2Desc].level2Key =
            item.level2Key;
          targetObj[item.depth1Desc][item.depth2Desc].level2Flag =
            item.level2Flag;
          targetObj[item.depth1Desc][item.depth2Desc].level3Key =
            item.level3Key;
          targetObj[item.depth1Desc][item.depth2Desc].level3Flag =
            item.level3Flag;
        }
      });
      state.accessControlList = targetObj;
      state.accessOriginSetting = action.payload;
    },
    resetChangeAccessControlTarget: (state, action) => {
      state.changeAccessControlTarget.authList = action.payload;
    },
    setNewVendorList: (state, action) => {
      state.newVendorList = action.payload;
    },
    setVendorMasterList: (state, action) => {
      state.VendorMasterList = action.payload;
    },
    setCsmsNewVendorMasterList: (state, action) => {
      state.csmsNewVendorMasterList = action.payload;
    },
    updateVendorMasterList: (state, action) => {
      const {
        payload: { shouldAddElements },
      } = action;

      let VendorMasterList;

      if (shouldAddElements) {
        VendorMasterList = [
          ...state.VendorMasterList,
          {
            ...action.payload.params,
            approvalDate: action.payload.params.insertDatetime,
            approvalUserId: action.payload.params.requestUserId,
            approvalStatus: PERMISSIONS.APPROVED,
            commentApproval: '승인합니다.',
          },
        ];
      } else {
        const {
          payload: {
            params: { vendorHistKey, vendorKey, approvalStatus, userId },
          },
        } = action;

        VendorMasterList = state.VendorMasterList.map((vendorMaster) => {
          if (
            vendorMaster?.vendorHistKey === vendorHistKey &&
            vendorMaster?.vendorKey === vendorKey
          ) {
            return {
              ...vendorMaster,
              approvalStatus,
              approvalUserId: userId,
              approvalDate: formatDateTime(new Date()).split(' ')[0],
            };
          }

          return vendorMaster;
        });
      }

      state.VendorMasterList = VendorMasterList;
    },
  },
});

export const {
  setAuthorityData,
  updateAuthority,
  updateVendorAuthorityList,
  updateInternalAuthorityList,
  deleteInternalAuthorityList,
  deleteVendorAuthorityList,
  setInternalAuthority,
  setVendorAuthority,
  setAccessControlList,
  resetChangeAccessControlTarget,
  setNewVendorList,
  setVendorMasterList,
  setCsmsNewVendorMasterList,
  updateVendorMasterList,
} = AuthorityData.actions;

export default AuthorityData.reducer;
