import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  // 약관 동의
  terms: {
    // 약관 동의 버전 별 분리
    agreedV1: {
      agreedTerms: false,
      agreedPrivacy: false,
      agreedlocation: false,
    },
    agreedV2: {},
  },

  // 국세청 API 검증 후 받아오는 데이터
  businessRegister: {
    valid: '',
    companyRegNo: '',
    companyOpenDate: '',
    companyCeoName: '',
    companyName: '',
    companyBizName: '',
  },

  // checkVendorExist API 의 response 값으로 받아오는 데이터
  businessInfo: {
    companyCode: '',
    vendorKey: '',
    companyName: '',
    companyRegNo: '',
    bizRegisDocFileKey: '',
    ecomRegisDocFileKey: '',
    companyOpenDate: '',
    companyCeoName: '',
  },

  // 가입 정보
  signUpInfo: {
    companyNumber: '',
    companyName: '',
    userId: '',
    userPassword: '',
    userDepartment: '',
    userEmail: '',
    userPhoneNumber: '',
    companyCode: '',
    vendorKey: '',
  },

  // 신규 제휴사인 경우
  newVendorInfo: {
    companyCode: '',
    companyName: '',
    vendorKey: '',
  },

  // 등록된 제휴사
  checkVendorExist: '',
  // 입력한 userId
  userId: '',
};

// 사업자 등록 번호
export const postResisterBusiness = createAsyncThunk(
  'signUp/postResisterBusiness',
  async (
    { companyRegNo, companyOpenDate, companyCeoName, companyBizName },
    thunkAPI
  ) => {
    const URL = `https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=${process.env.REACT_APP_BUSSINESS_KEY}`;

    axios
      .post(URL, {
        businesses: [
          {
            b_no: companyRegNo,
            start_dt: companyOpenDate,
            p_nm: companyCeoName,
            b_nm: companyBizName,
          },
        ],
      })
      .then((response) => {
        if (response.data.data[0].valid === '01') {
          thunkAPI.dispatch(
            updateRegisterBusiness({
              valid: response.data.data[0].valid,
              companyRegNo: response.data.data[0].request_param.b_no,
              companyOpenDate: response.data.data[0].request_param.start_dt,
              companyCeoName: response.data.data[0].request_param.p_nm,
              companyName: response.data.data[0].request_param.b_nm,
              companyBizName: response.data.data[0].request_param.b_nm,
            })
          );
        } else
          thunkAPI.dispatch(
            updateRegisterBusiness({
              valid: response.data.data[0].valid,
            })
          );
      })
      .catch((error) => {
        console.log('updateRegisterBusiness onFail ', error);
      });
  }
);

const signUp = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    updateRegisterBusiness: (state, action) => {
      state.businessRegister = action.payload;
    },
    resetBusinessRegister: (state) => {
      state.businessRegister = initialState.businessRegister;
    },
    setBusinessInfo: (state, action) => {
      state.businessInfo = action.payload;
    },
    updateTerms: (state, action) => {
      state.terms.agreedV1 = action.payload;
    },
    updateSignUpInfo: (state, action) => {
      state.signUpInfo = action.payload;
    },
    setNewVendor: (state, action) => {
      state.newVendorInfo = action.payload;
    },
    checkVendorExistYN: (state, action) => {
      state.checkVendorExist = action.payload;
    },
    getUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const {
  updateRegisterBusiness,
  resetBusinessRegister,
  setBusinessInfo,
  updateTerms,
  updateSignUpInfo,
  setNewVendor,
  checkVendorExistYN,
  getUserId,
} = signUp.actions;

export default signUp.reducer;
