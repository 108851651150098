import { memo, useRef, useCallback } from 'react';
import { ko } from 'date-fns/esm/locale';
import { useSelector } from 'react-redux';
import { isWeekDay, isHoliday } from '@utils';
import DatePicker from 'react-datepicker';
import ico_calendar from '@assets/img/ico_calendar.svg';
import classNames from 'classnames/bind';
import css from './CalendarSingle.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import '../Calendar/Calendar.css';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const DISABLED_DATE_TYPE = {
  WEEKEND: 'weekend',
  HOLIDAY: 'holiday',
};

/**
 * @category Components
 * @namespace Calendar
 * @param {Date} startDate - date-picker의 시작 날짜
 * @param {function} handleStartDate - date-picker 시작 날짜의 onChange callback
 * @param {function} setStartDate - PeriodInput에서 시작 날짜를 설정하는 함수
 * @param {array} disabledDateTypeList - 달력에서 선택할 수 없는 날짜들을 지정하기 위한 배열 (값: 'weekend' | 'holiday')
 * @param {date} minDate date-picker의 선택 가능한 최소 날짜
 * @param {date} maxStartDate date-picker의 시작 달력의 선택 가능한 최대 날짜
 * @param {boolean} hasTimeSetter TimeSetter 존재 여부
 * @param {boolean} disabled
 */

const CalendarSingle = ({
  startDate,
  handleStartDate,
  disabledDateTypeList,
  minDate,
  maxDate,
  disabled,
  ...props
}) => {
  const holidaysOfKorea = useSelector(
    ({ holidays: { holidaysOfKorea } }) => holidaysOfKorea
  );

  const startDateRef = useRef(null);

  const isBusinessDay = useCallback(
    (date) => {
      if (!disabledDateTypeList.length) return true;

      for (const type of disabledDateTypeList) {
        switch (type) {
          case DISABLED_DATE_TYPE.WEEKEND:
            if (!isWeekDay(date)) return false;
            break;
          case DISABLED_DATE_TYPE.HOLIDAY:
            if (isHoliday({ holidays: holidaysOfKorea, date })) return false;
            break;
          default:
            break;
        }
      }
      return true;
    },
    [disabledDateTypeList, holidaysOfKorea]
  );

  const startDateFocus = useCallback(() => {
    // DatePicker에 handleFocus 메소드가 내장되어있습니다.
    // 아이콘 클릭 시 DatePicker에 포커스 줘서 달력 UI Open 시킴.
    startDateRef.current.handleFocus();
  }, []);

  return (
    <div className={css.container}>
      <DatePicker
        selected={startDate}
        onChange={handleStartDate}
        dateFormat='yyyy-MM-dd'
        className={cn('datePicker', { disabled })}
        ref={startDateRef}
        minDate={minDate}
        maxDate={maxDate}
        showIcon
        icon={
          <i className={css.calIcon}>
            <img
              onClick={() => {
                startDateFocus();
              }}
              src={ico_calendar}
              alt={'calendar Icon'}
            />
          </i>
        }
        locale={ko}
        showYearDropdown
        scrollableMonthYearDropdown
        filterDate={isBusinessDay}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

CalendarSingle.defaultProps = {
  startDate: new Date(),
  disabledDateTypeList: [],
  minDate: new Date(),
  maxDate: undefined,
  hasTimeSetter: false,
};

export default memo(CalendarSingle);
