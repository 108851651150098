import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '@App';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import store from '@store';
import Modal from 'react-modal';
import '@assets/scss/_base.scss';
import '@assets/scss/_font.scss';
import loading from '@assets/img/loading.svg';
import { ModalAlert } from '@components';

Modal.setAppElement('#root');
//브라우져 콘솔 창에 window.store.getState() 를 호출해보세요.
//window 객체에 store 할당함, runTime 진입 이전에, 즉 아래 코드를 읽기 전에, window는 이미 생성되어있습니다. 감사합니다.
window.store = store;

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <img src={loading} alt='loading...' />
        </div>
      }
      persistor={persistor}
    >
      <BrowserRouter>
        <App />
        <ModalAlert />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
