import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  holidaysSlice,
  signUpSlice,
  commonSlice,
  AuthoritySlice,
  noticeSlice,
  userSlice,
  couponSlice,
  dashBoardSlice,
  deliverySlice,
  refundSlice,
  settlementSlice,
} from 'redux/feature';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import productSlice from '@feature/product/productSlice';
import mailSlice from '@feature/mail/mailSlice';

const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['user'], // 해당 reducer만 저장
};

const reducers = combineReducers({
  holidays: holidaysSlice,
  signUp: signUpSlice,
  common: commonSlice,
  authority: AuthoritySlice,
  notice: noticeSlice,
  user: userSlice,
  product: productSlice,
  coupon: couponSlice,
  mail: mailSlice,
  dashBoard: dashBoardSlice,
  delivery: deliverySlice,
  refund: refundSlice,
  settlement: settlementSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
export default store;
