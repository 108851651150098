import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { startJob } from '@utils';

/**
 * @category useHooks
 * @namespace useInputMultiple
 * @param {string} initialValue Input 공통 컴포넌트 사용 시 지정해주는 초기 value.
 * @param {boolean} handleChecked 회원가입: Input 유효성 검사시 결과값을 반환하게 되는데, input값이 바뀌게 되면 이 결과값을 리셋 하기 위한 값
 * @param {function} action 전역 상태 수정을 위한 action 함수.
 * @returns {state, setState, onChange}
 * @description 여러개의 Input 공통 컴포넌트(ex. 핸드폰 번호) 에서 사용하기 위한 hook
 */

const useInputMultiple = (initialValue, handleChecked, action) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialValue);

  const onChange = useCallback((e) => {
    const { name, value, checked, id } = e.target;

    //input target name = 수정할 상태의 key
    console.log('onChange', e);
    const payLoad = {
      key: name,
      value: value,
      checked: checked ? 'Y' : 'N',
      id: id ?? null,
    };
    console.log('payLoad', payLoad);
    if (action) {
      startJob(`${e.target.name}`, () => dispatch(action(payLoad)), 100);
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleChecked && handleChecked(false);
  }, []);

  return [state, setState, onChange];
};

export default useInputMultiple;
