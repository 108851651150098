import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames/bind';
import TableV8 from '@components/Table/TableV8';
import { useCallback, useEffect, useMemo, useState } from 'react';
import css from './ManagementCompanyInfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSIONS } from '@data/Const';
import {
  Button,
  PopUp,
  PopUpAlert,
  VerticalTable,
  VerticalTableContainer,
} from '@components/index';
import axios from 'axios';
import { backendMode, createQueryString, getByte } from '@utils';
import { getUrl } from 'redux/function/TAxiosConfig';
import { postApproveVendorMaster } from 'redux/action/AuthorityAction';
import { FormProvider, useForm } from 'react-hook-form';
import HFTextarea from '@components/_ReactHookForm/HFTextarea';
import HFInput from '@components/_ReactHookForm/HFInput';
import AuthoritySearchArea from './modules/AuthoritySearchArea';
import {
  hasSearchText,
  isDateInRange,
  matchesCondition,
} from '@components/Search/utils/searchValidationFunctions';
import { openModalAlert } from '@feature/common/commonSlice';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const POPUP_NAME = {
  CLOSED: [],
  UNDER_APPROVAL: 'UNDER_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CONFIRM_APPROVE: 'CONFIRM_APPROVE',
  CONFIRM_REJECT: 'CONFIRM_REJECT',
};
const CONFIRM_TYPE = {
  STATUS_APPROVED: 'STATUS_APPROVED',
  STATUS_REJECTED: 'STATUS_REJECTED',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  WITHDRAWAL: 'WITHDRAWAL',
};

const statusSearchOptions = {
  guide: '',
  list: [
    { id: '', title: '전체' },
    { id: PERMISSIONS.APPROVED, title: '승인' },
    { id: PERMISSIONS.UNDER_APPROVAL, title: '승인대기' },
    { id: PERMISSIONS.REJECTED, title: '거절' },
  ],
};

const keywordSearchOptions = {
  guide: '',
  list: [
    { id: 'requestUserId', title: '아이디' },
    { id: 'companyBizName', title: '상호명' },
    { id: 'companyCeoName', title: '대표자명' },
  ],
};

const changeCompanyInfoInitialValues = {
  companyRegNo: '',
  companyName: '',
  companyCeoName: '',
  companyOpenDate: '',
  bizRegisDocFileName: '',
  ecomRegisDocFileName: '',
  commentRequest: '',
  comment: '',
  approvalStatus: '',
  approvalDate: '',
  vendorHistKey: '',
  vendorKey: '',
  approvalUserId: '',
};

const ManagementCompanyInfo = ({ selectApprovalStatus }) => {
  const dispatch = useDispatch();
  const userData = useSelector(({ user: { userData } }) => userData);
  const vendorMasterList = useSelector(
    ({ authority: { VendorMasterList } }) => VendorMasterList
  );
  const sortedVendorMasterList = useMemo(() => {
    return [...vendorMasterList].sort((prev, cur) => {
      if (!prev.approvalDate && !cur.approvalDate) {
        return new Date(cur.insertDatetime) - new Date(prev.insertDatetime);
      } else if (!prev.approvalDate) {
        return -1;
      } else if (!cur.approvalDate) {
        return 1;
      } else {
        return new Date(cur.approvalDate) - new Date(prev.approvalDate);
      }
    });
  }, [vendorMasterList]);

  const [filtered, setFiltered] = useState(sortedVendorMasterList);
  const TABLE_DATA_LENGTH = useMemo(() => filtered.length, [filtered]);

  const [currentPopupName, setCurrentPopupName] = useState(POPUP_NAME.CLOSED);
  const searchForms = useForm();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: changeCompanyInfoInitialValues,
  });

  const onSearchClick = useCallback(
    (data) => {
      const {
        startDate,
        endDate,
        statusSearchCondition,
        keywordSearchCondition,
        keyword,
      } = data;

      if (getByte(keyword) <= 3)
        return dispatch(openModalAlert({ message: '글자수를 확인 해주세요' }));

      const filteredList = sortedVendorMasterList.filter((master) => {
        const { insertDatetime, approvalStatus } = master;

        return (
          isDateInRange({
            targetDate: new Date(insertDatetime),
            startDate,
            endDate,
          }) &&
          matchesCondition({
            targetCondition: approvalStatus,
            searchCondition: statusSearchCondition,
          }) &&
          hasSearchText({
            targetValue: master[keywordSearchCondition],
            searchText: keyword,
          })
        );
      });

      setFiltered(filteredList);
    },
    [dispatch, sortedVendorMasterList]
  );

  const onFileDownloadClick = useCallback(async (fileKey) => {
    if (fileKey) {
      const serverType = backendMode();
      const baseUri = '/api/1.0/app/attachment/download';
      const url = `${getUrl(baseUri, serverType)}?${createQueryString({
        fileKey,
      })}`;

      try {
        const response = await axios.get(url, {
          responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: response.data.type });
        const fileUrl = window.URL.createObjectURL(blob);
        const fileName = response.headers['content-disposition'].match(
          /attachment; filename="(.*)"/
        )[1];

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = decodeURI(fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const onPushCurrentPopupName = useCallback((popupName) => {
    setCurrentPopupName((prev) => [...prev, popupName]);
  }, []);

  const onPopCurrentPopup = useCallback(() => {
    setCurrentPopupName((prev) => prev.slice(0, -1));
  }, []);

  const onCellButtonClick = useCallback(
    ({ info, popupName }) => {
      switch (popupName) {
        case POPUP_NAME.UNDER_APPROVAL:
          const {
            row: {
              original: {
                companyRegNo,
                companyName,
                companyCeoName,
                companyOpenDate,
                bizRegisDocFileName,
                ecomRegisDocFileName,
                commentRequest,
                vendorHistKey,
                vendorKey,
              },
            },
          } = info;

          reset({
            companyRegNo,
            companyName,
            companyCeoName,
            companyOpenDate,
            bizRegisDocFileName,
            ecomRegisDocFileName,
            commentRequest,
            vendorHistKey,
            vendorKey,
          });
          break;
        case POPUP_NAME.APPROVED:
          const {
            row: {
              original: {
                approvalStatus,
                commentApproval,
                approvalDate,
                approvalUserId,
              },
            },
          } = info;

          reset({
            approvalStatus:
              approvalStatus === PERMISSIONS.APPROVED ? '승인' : '거절',
            commentApproval,
            approvalDate,
            approvalUserId,
          });
          break;
        default:
          break;
      }

      onPushCurrentPopupName(popupName);
    },
    [reset, onPushCurrentPopupName]
  );

  const onPopupClose = useCallback(() => {
    reset(changeCompanyInfoInitialValues);
    setCurrentPopupName(POPUP_NAME.CLOSED);
  }, [reset]);

  const onConfirm = useCallback(
    ({ data, type }) => {
      const { vendorHistKey, vendorKey, comment } = data;

      switch (type) {
        case CONFIRM_TYPE.STATUS_APPROVED:
          const approvedParams = {
            vendorHistKey,
            vendorKey,
            commentApproval: comment,
            approvalStatus: PERMISSIONS.APPROVED,
            userId: userData.userId,
          };

          postApproveVendorMaster({ dispatch, params: approvedParams });
          break;
        case CONFIRM_TYPE.STATUS_REJECTED:
          const rejectedParams = {
            vendorHistKey,
            vendorKey,
            commentApproval: comment,
            approvalStatus: PERMISSIONS.REJECTED,
            userId: userData.userId,
          };

          postApproveVendorMaster({ dispatch, params: rejectedParams });
          break;
        default:
          break;
      }

      onPopupClose();
    },
    [dispatch, userData.userId, onPopupClose]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('no', {
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => TABLE_DATA_LENGTH - index,
      }),
      columnHelper.accessor('requestUserId', {
        header: () => <span>아이디</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyRegNo', {
        header: () => <span>사업자 등록번호</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyBizName', {
        header: () => <span>상호명</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyName', {
        header: () => <span>제휴사명</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyCeoName', {
        header: () => <span>대표자명</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyOpenDate', {
        header: () => <span>개업 일자</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('bizRegisDocFileName', {
        header: () => <span>사업자 등록증</span>,
        cell: (info) => {
          const {
            row: {
              original: { bizRegisDocFileKey },
            },
          } = info;

          return info.getValue() ? (
            <span
              className={cn('download')}
              onClick={() => onFileDownloadClick(bizRegisDocFileKey)}
            >
              {info.getValue()}
            </span>
          ) : (
            '-'
          );
        },
      }),
      columnHelper.accessor('ecomRegisDocFileName', {
        header: () => <span>통신판매 신고증</span>,
        cell: (info) => {
          const {
            row: {
              original: { ecomRegisDocFileKey },
            },
          } = info;

          return info.getValue() ? (
            <span
              className={cn('download')}
              onClick={() => onFileDownloadClick(ecomRegisDocFileKey)}
            >
              {info.getValue()}
            </span>
          ) : (
            '-'
          );
        },
      }),
      columnHelper.accessor('approvalStatus', {
        header: () => <span>상태</span>,
        cell: (info) => {
          switch (info.getValue()) {
            case PERMISSIONS.UNDER_APPROVAL:
              return (
                <Button
                  styleType='btn01'
                  subType='type04'
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.UNDER_APPROVAL,
                    })
                  }
                >
                  승인 대기
                </Button>
              );
            case PERMISSIONS.APPROVED:
              return (
                <Button
                  styleType='btn01'
                  subType='type04'
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.APPROVED,
                    })
                  }
                >
                  승인
                </Button>
              );
            case PERMISSIONS.REJECTED:
              return (
                <Button styleType='btn01' subType='type04' disabled>
                  거절
                </Button>
              );
            default:
              return info.getValue();
          }
        },
      }),
      columnHelper.accessor('commentApproval', {
        header: () => <span>비고</span>,
        cell: (info) => {
          const {
            row: {
              original: { approvalStatus },
            },
          } = info;

          return approvalStatus === PERMISSIONS.REJECTED
            ? info.getValue()
            : '-';
        },
      }),
    ],
    [TABLE_DATA_LENGTH, onFileDownloadClick, onCellButtonClick]
  );

  useEffect(() => {
    setFiltered(sortedVendorMasterList);
  }, [sortedVendorMasterList]);

  return (
    <>
      <form>
        <FormProvider {...searchForms}>
          <AuthoritySearchArea
            statusSearchOptions={statusSearchOptions}
            keywordSearchOptions={keywordSearchOptions}
            onSearchClick={onSearchClick}
            selectApprovalStatus={selectApprovalStatus}
          />
        </FormProvider>
      </form>
      <TableV8 data={filtered} columns={columns} hasCsvDownload={false} />
      {/* 상태 column의 '승인 대기' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.UNDER_APPROVAL)}
        onRequestClose={onPopupClose}
        size={'auto'}
        title={'사업자 정보 변경'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  styleType='btn01'
                  subType='type03'
                  onClick={onPopupClose}
                  fullWidth
                >
                  닫기
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  styleType='btn01'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.CONFIRM_REJECT)
                  }
                  fullWidth
                >
                  거절
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  styleType='btn01'
                  subType='type02'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.CONFIRM_APPROVE)
                  }
                  fullWidth
                >
                  승인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'사업자 등록번호'}>
            <HFInput
              type={'text'}
              name={'companyRegNo'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'제휴사명'}>
            <HFInput
              type={'text'}
              name={'companyName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'대표자명'}>
            <HFInput
              type={'text'}
              name={'companyCeoName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'개업 일자'}>
            <HFInput
              type={'text'}
              name={'companyOpenDate'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'사업자 등록증'}>
            <HFInput
              type={'text'}
              name={'bizRegisDocFileName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'통신판매 신고증'}>
            <HFInput
              type={'text'}
              name={'ecomRegisDocFileName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'사유'}>
            <HFInput
              type={'text'}
              name={'commentRequest'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              placeholder={'내용을 입력하세요'}
              name={'comment'}
              register={register}
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_REJECT)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: CONFIRM_TYPE.STATUS_REJECTED })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        승인 거부 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_APPROVE)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: CONFIRM_TYPE.STATUS_APPROVED })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        최종 반영처리 하시겠습니까?
      </PopUpAlert>
      {/* 상태 column의 '승인' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.APPROVED)}
        onRequestClose={onPopupClose}
        size={'auto'}
        title={'사업자 정보 승인 완료'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  styleType='btn01'
                  subType='type03'
                  onClick={onPopupClose}
                  fullWidth
                >
                  확인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'승인 상태'}>
            <HFInput
              type={'text'}
              name={'approvalStatus'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea name={'commentApproval'} register={register} readOnly />
          </VerticalTable>
          <VerticalTable title={'승인 일자'}>
            <HFInput
              type={'text'}
              name={'approvalDate'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'승인자 ID'}>
            <HFInput
              type={'text'}
              name={'approvalUserId'}
              register={register}
              readOnly
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
    </>
  );
};

export default ManagementCompanyInfo;
