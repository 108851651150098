import React from 'react';
import css from './RegisterForm.module.scss';
import classNames from 'classnames/bind';
import RegisterContainer from '../RegisterContainer/RegisterContainer';

const cn = classNames.bind(css);

/**
 * @category components
 * @namespace RegisterForm
 * @param {string} title RegisterForm 제목.
 * @param {ReactNode[]} item RegisterForm 아이템 리스트.
 * @param {any} children RegisterForm 자식(버튼) 요소.
 * @param {boolean} hasTitleMargin RegisterForm 타이틀 마진 값 존재 여부.
 * @param {boolean} isLoginForm RegisterForm 로그인 화면인지 아닌지 판별.
 * @description Register/Login, Find 페이지에서 사용하는 RegisterForm 컴포넌트.
 */

const RegisterForm = ({
  children,
  title,
  item,
  hasTitleMargin,
  isLoginForm,
}) => {
  return (
    <>
      <RegisterContainer>
        <div className={cn('registerContainer', { isLoginForm })}>
          <h2 className={cn('pageTitle2', { hasTitleMargin })}>{title}</h2>
          <div className={css.register_input}>
            <div className={css.inputWrap}>
              {item &&
                item.map((item, idx) => (
                  <div key={idx} className={css.inputWrap}>
                    <div className={css.input_text}>{item}</div>
                  </div>
                ))}
            </div>
          </div>
          {children}
        </div>
      </RegisterContainer>
      <div className={css.register_footer}>
        <span className={css.copyright}>구독 제휴사 Admin</span>
      </div>
    </>
  );
};

RegisterForm.defaultProps = {
  hasTitleMargin: false,
  isLoginForm: false,
};

export default RegisterForm;
