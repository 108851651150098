import { BreadCrumbs, TabMenu } from '@components/index';
import { ROUTER_PATH } from '@router/routerPath';
import css from './Mail.module.scss';
import classNames from 'classnames/bind';
import { useMemo, useState } from 'react';
import Inbox from './Inbox';
import Sent from './Sent';
import Deleted from './Deleted';
import { useSelector } from 'react-redux';

const cn = classNames.bind(css);

const Mail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const totalCount = useSelector((state) => state.mail.totalCount);

  const tabData = useMemo(
    () => [
      {
        id: 0,
        title: (
          <p>
            받은 메세지(
            <span className={cn('color-primary')}>
              {totalCount?.unreadCnt ?? 0}
            </span>
            )
          </p>
        ),
      },
      { id: 1, title: '자동 발송 조회' },
      { id: 2, title: '삭제한 메세지' },
    ],
    [totalCount]
  );

  return (
    <>
      <h2 className={css.pageTitle}>메시지함</h2>
      <div className={cn('tabMenu', 'tabMenu01', 'type01', 'mt20')}>
        <div className={css.list}>
          <TabMenu
            tabData={tabData}
            type02={true}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab === 0 && <Inbox />}
          {activeTab === 1 && <Sent />}
          {activeTab === 2 && <Deleted />}
        </div>
      </div>
    </>
  );
};

export default Mail;
