import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './CouponPublish.module.scss';
import { Table, Button, TableV8, PopUp } from '@components/index';

import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  postTalkDreamCouponIssueTarget,
  getAwaitTalkDreamCouponIssueTargetListAPI,
  postCancelMappingContractAndCoupon,
  sendCouponStatusToCSMSAPI,
} from 'redux/action/couponAction';
import Loading from 'template/Loading/Loading';
import TableCheckbox from '@components/Table/TableCheckbox';
import { openModalAlert } from '@feature/common/commonSlice';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const CouponPublish = ({
  couponIssueCompletedList,
  couponIssueCompletedListFailCase,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);
  const userId = useSelector((state) => state.user.userData.userId);
  const [selectedSuccessKeys, setSelectedSuccessKeys] = useState(null);
  const [selectedFailKeys, setSelectedFailKeys] = useState([]);
  const [selectedRetryKeys, setSelectedRetryKeys] = useState([]);

  const onChangeSelectedSuccessKeys = useCallback((keys) => {
    const couponNumbers = keys.map((item, idx) => item.couponKey);
    let formattedCancelTargetList = [];
    if (keys?.length > 0) {
      formattedCancelTargetList = JSON.stringify(couponNumbers);
    }
    setSelectedSuccessKeys(formattedCancelTargetList);
  }, []);

  const onChangeSelectedFailKeys = useCallback((keys) => {
    const couponNumbers = keys.map((item, idx) => item.couponKey);
    let formattedCancelTargetList = [];
    if (keys?.length > 0) {
      formattedCancelTargetList = JSON.stringify(couponNumbers);
    }
    setSelectedFailKeys(formattedCancelTargetList);
    setSelectedRetryKeys(formattedCancelTargetList);
  }, []);

  const columnDataV8 = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-header-coupon-unmapping'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-coupon-unmapping' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송계획번호</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>제휴사</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>구독회차</span>,
      }),
      columnHelper.accessor('deliveryReqDate', {
        id: 'deliveryReqDate',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>발행예정일</span>,
      }),
    ],
    []
  );
  const columnDataV8StatusN = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'select-header-coupon-remapping'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'select-header-coupon-remapping' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송계획번호</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>제휴사</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>구독회차</span>,
      }),
      columnHelper.accessor('deliveryReqDate', {
        id: 'deliveryReqDate',
        cell: (info) => <span>{info.getValue() ?? 'null'}</span>,
        header: () => <span>발행예정일</span>,
      }),
    ],
    []
  );

  const addAlramTarget = useCallback(async () => {
    const params = {
      messageTargetList: selectedSuccessKeys,
      userId: userId,
    };
    await dispatch(postTalkDreamCouponIssueTarget(params));
    const tempParams = {
      limit: '100',
      page: '1',
    };
    await dispatch(getAwaitTalkDreamCouponIssueTargetListAPI(tempParams));
  }, [userId, selectedSuccessKeys]);

  const handleCancelMapping = useCallback(
    (selectedItem) => {
      if (selectedItem?.length === 0) {
        dispatch(openModalAlert({ message: '취소할 대상을 체크해주세요.' }));
      } else {
        postCancelMappingContractAndCoupon(dispatch, {
          userId: userId,
          cancelTargetList: selectedItem,
        });
      }
    },
    [userId]
  );
  const handleRetryMapping = useCallback(
    (selectedItem) => {
      const params = {
        sendTargetList: selectedItem,
        userId: userId,
      };
      sendCouponStatusToCSMSAPI(dispatch, params);
    },
    [userId]
  );

  return (
    <>
      <div className={css.stepGuideText}>
        ②쿠폰 발행 결과를 확인 후 알림 발송 대상을 추가해주세요.
      </div>
      {/* FIXME: > 0 */}
      {couponIssueCompletedListFailCase?.length > 0 && (
        <>
          <TableV8ManualPage
            columns={columnDataV8StatusN}
            data={couponIssueCompletedListFailCase}
            hasPageSize={false}
            getRowselected={onChangeSelectedFailKeys}
            hasCsvDownload={false}
            limit={100}
          />
          <div className={cn('btn_wrapper')}>
            <Button
              styleType={'btn01'}
              subType={'type01'}
              onClick={() => {
                handleCancelMapping(selectedFailKeys);
              }}
            >
              선택항목 매핑(발행) 취소
            </Button>
            <Button
              styleType={'btn01'}
              subType={'type01'}
              style={{ marginLeft: '50px' }}
              onClick={() => {
                handleRetryMapping(selectedRetryKeys);
              }}
            >
              난수번호 다시 매핑
            </Button>
          </div>
        </>
      )}
      <div className={cn('table_header')}>
        <div className={cn('box_left')}>
          <div class={cn('total_count')}>
            [쿠폰 발행 완료] 총 <b>{couponIssueCompletedList?.length ?? 0}</b>건
          </div>
        </div>
      </div>
      <TableV8ManualPage
        columns={columnDataV8}
        data={couponIssueCompletedList}
        getRowselected={onChangeSelectedSuccessKeys}
        hasCsvDownload={false}
        hasPageSize={false}
        limit={100}
      />
      <div className={css.stepGuideText}>
        등록 수량보다 발행 대상 쿠폰이 많은 경우에는, 쿠폰 생성 및 등록 메뉴로
        이동하여 추가 쿠폰을 등록하도록 업체에 공지해주세요.
      </div>
      <div className={cn('btn_wrapper')}>
        <Button
          styleType={'btn01'}
          subType={'type01'}
          onClick={() => {
            handleCancelMapping(selectedSuccessKeys);
          }}
        >
          선택항목 매핑(발행) 취소
        </Button>
      </div>
      <div className={cn('flex', 'justify-content-center', 'btn_wrapper')}>
        <Button
          styleType={'btn01'}
          subType={'type01'}
          onClick={() => {
            addAlramTarget();
          }}
        >
          발행 알림 발송 대상 추가
        </Button>
      </div>
      <PopUp isOpenPopUp={loading} closeButton={false}>
        <Loading />
      </PopUp>
    </>
  );
};

export default CouponPublish;
