import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingTaxBillStepFirst.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { Button } from '@components/index';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import TableCheckbox from '@components/Table/TableCheckbox';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSalesSettlementListAPI,
  postUpdateExternalProcessStatusAPI,
} from 'redux/action/settlementAction';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';
import { useFormContext, useWatch } from 'react-hook-form';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const AccountingTaxBillStepFirst = () => {
  const dispatch = useDispatch();
  const salesSettlementListFOC = useSelector(
    (state) => state.settlement.salesSettlementList.foc
  );
  const salesSettlementListNFOC = useSelector(
    (state) => state.settlement.salesSettlementList.nfoc
  );

  const { register, control, watch } = useFormContext();

  console.log('###watch', watch());

  const nfocColumns = useMemo(
    () => [
      {
        id: 'select',
        cell: ({ row }) => {
          let radioValue = [];
          if (row.original.dataType === '원거래') {
            const target = salesSettlementListNFOC[row.original.vendorName];
            const targetIds = [];
            target.forEach((item) => {
              targetIds.push(item.settlementTotalId);
            });
            radioValue = {
              targetKeyPair: targetIds,
              gevsCompleteFlag: target[0].gevsCompleteFlag,
              gerpCompleteFlag: target[0].gerpCompleteFlag,
            };

            return (
              <HFRadioButton
                name={'radio_settlement_nfoc'}
                register={register}
                id={`nfocColumns-radio-button` + row.id}
                value={JSON.stringify(radioValue)}
              />
            );
          }
        },
      },
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정 구분</span>,
      }),
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산 월</span>,
      }),
      columnHelper.accessor('promotionType', {
        id: 'promotionType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('sellCnt', {
        id: 'sellCnt',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출건수</span>,
      }),
      columnHelper.accessor('sellTotal', {
        id: 'sellTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>판매총액</span>,
      }),
      columnHelper.accessor('marginTotal', {
        id: 'marginTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>마진총액</span>,
      }),
      columnHelper.accessor('netTotal', {
        id: 'netTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>공급가액</span>,
      }),
      columnHelper.accessor('gevsCompleteFlag', {
        id: 'gevsCompleteFlag',
        cell: (info) => {
          return (
            <>
              {info.getValue() === 'Y' ? (
                <>
                  <i className={cn('ico_act', 'type03')}></i>
                  <span> {info.getValue()} </span>
                </>
              ) : (
                <>
                  <i className={cn('ico_act', 'type01')}></i>
                  <span> {info.getValue()}</span>
                </>
              )}
            </>
          );
        },
        header: () => <span>GEVS</span>,
      }),
      columnHelper.accessor('gerpCompleteFlag', {
        id: 'gerpCompleteFlag',
        cell: (info) => {
          return (
            <>
              {info.getValue() === 'Y' ? (
                <>
                  <i className={cn('ico_act', 'type03')}></i>
                  <span> {info.getValue()} </span>
                </>
              ) : (
                <>
                  <i className={cn('ico_act', 'type01')}></i>
                  <span> {info.getValue()}</span>
                </>
              )}
            </>
          );
        },
        header: () => <span>GERP</span>,
      }),
      columnHelper.accessor('salesApproveLevel3', {
        id: 'salesApproveLevel3',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사 승인</span>,
      }),
      columnHelper.accessor('salesApproveAdmin', {
        id: 'salesApproveAdmin',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>LGE 승인</span>,
      }),
    ],
    [salesSettlementListNFOC, salesSettlementListFOC]
  );

  const focColumns = useMemo(
    () => [
      {
        id: 'select',
        cell: ({ row }) => {
          let radioValue = [];
          if (
            row.original.dataType === '원거래' &&
            salesSettlementListFOC?.[row.original.vendorName]
          ) {
            const target = salesSettlementListFOC[row.original.vendorName];
            const targetIds = [];
            target.forEach((item) => {
              targetIds.push(item.settlementTotalId);
            });
            radioValue = {
              targetKeyPair: targetIds,
              taxBillRequestFlag: target[0].taxBillRequestFlag,
              taxBillResponseFlag: target[0].taxBillResponseFlag,
              apmsCompleteFlag: target[0].apmsCompleteFlag,
            };
            return (
              <HFRadioButton
                name={'radio_settlement_foc'}
                register={register}
                id={`focColumns-radio-button` + row.id}
                value={JSON.stringify(radioValue)}
              />
            );
          }
        },
      },
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>조정 구분</span>,
      }),
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산 월</span>,
      }),
      columnHelper.accessor('promotionType', {
        id: 'promotionType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('sellCnt', {
        id: 'sellCnt',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출건수</span>,
      }),
      columnHelper.accessor('sellTotal', {
        id: 'sellTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>판매총액</span>,
      }),
      columnHelper.accessor('netTotal', {
        id: 'netTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>공급가액</span>,
      }),

      columnHelper.accessor('taxBillRequestFlag', {
        id: 'taxBillRequestFlag',
        cell: (info) => {
          return (
            <>
              {info.getValue() === 'Y' ? (
                <>
                  <i className={cn('ico_act', 'type03')}></i>
                  <span> {info.getValue()} </span>
                </>
              ) : (
                <>
                  <i className={cn('ico_act', 'type01')}></i>
                  <span> {info.getValue()}</span>
                </>
              )}
            </>
          );
        },
        header: () => <span>세금계산서 요청</span>,
      }),
      columnHelper.accessor('taxBillResponseFlag', {
        id: 'taxBillResponseFlag',
        cell: (info) => {
          return (
            <>
              {info.getValue() === 'Y' ? (
                <>
                  <i className={cn('ico_act', 'type03')}></i>
                  <span> {info.getValue()} </span>
                </>
              ) : (
                <>
                  <i className={cn('ico_act', 'type01')}></i>
                  <span> {info.getValue()}</span>
                </>
              )}
            </>
          );
        },
        header: () => <span>세금계산서 수신</span>,
      }),
      columnHelper.accessor('apmsCompleteFlag', {
        id: 'apmsCompleteFlag',
        cell: (info) => {
          return (
            <>
              {info.getValue() === 'Y' ? (
                <>
                  <i className={cn('ico_act', 'type03')}></i>
                  <span> {info.getValue()} </span>
                </>
              ) : (
                <>
                  <i className={cn('ico_act', 'type01')}></i>
                  <span> {info.getValue()}</span>
                </>
              )}
            </>
          );
        },
        header: () => <span>APMS</span>,
      }),
      columnHelper.accessor('salesApproveLevel3', {
        id: 'salesApproveLevel3',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사 승인</span>,
      }),
      columnHelper.accessor('salesApproveAdmin', {
        id: 'salesApproveAdmin',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>LGE 승인</span>,
      }),
    ],
    []
  );

  const listFOC = useMemo(() => {
    let ret = [];
    if (
      salesSettlementListFOC &&
      Object.keys(salesSettlementListFOC).length > 0
    ) {
      ret = Object.values(salesSettlementListFOC).flat();
    }
    return ret;
  }, [salesSettlementListFOC]);

  const listNFOC = useMemo(() => {
    let ret = [];
    if (
      salesSettlementListNFOC &&
      Object.keys(salesSettlementListNFOC).length > 0
    ) {
      ret = Object.values(salesSettlementListNFOC).flat();
    }
    return ret;
  }, [salesSettlementListNFOC]);

  const radioTargetStatus = useMemo(() => {
    const { radio_settlement_foc, radio_settlement_nfoc } = watch();
    let gevsStatus = 'NULL';
    let gerpStatus = 'NULL';
    let taxBillRequestFlag = 'NULL';
    let taxBillResponseFlag = 'NULL';
    let apmsCompleteFlag = 'NULL';
    if (radio_settlement_nfoc) {
      const nfoc = JSON.parse(radio_settlement_nfoc);
      gevsStatus = nfoc.gevsCompleteFlag;
      gerpStatus = nfoc.gerpCompleteFlag;
    }
    if (radio_settlement_foc) {
      const foc = JSON.parse(radio_settlement_foc);
      taxBillRequestFlag = foc.taxBillRequestFlag;
      taxBillResponseFlag = foc.taxBillResponseFlag;
      apmsCompleteFlag = foc.apmsCompleteFlag;
    }

    return {
      gevsStatus,
      gerpStatus,
      taxBillRequestFlag,
      taxBillResponseFlag,
      apmsCompleteFlag,
    };
  }, [watch(), salesSettlementListFOC, salesSettlementListNFOC]);

  const handleStatusChange = useCallback(
    (type, data) => {
      const { radio_settlement_foc, radio_settlement_nfoc } = watch();
      console.log('###keyPair', watch(), radio_settlement_nfoc);
      let keyPair;
      if (type === 'foc' && radio_settlement_foc) {
        const { targetKeyPair } = JSON.parse(radio_settlement_foc);
        keyPair = { settlementTotalId: targetKeyPair };
      } else if (type === 'nfoc' && radio_settlement_nfoc) {
        const { targetKeyPair } = JSON.parse(radio_settlement_nfoc);
        keyPair = { settlementTotalId: targetKeyPair };
      }
      console.log('###keyPair', Object.assign({}, keyPair, data));
      postUpdateExternalProcessStatusAPI(
        dispatch,
        Object.assign({}, keyPair, data)
      );
    },
    [watch()]
  );

  useEffect(() => {
    getSalesSettlementListAPI(dispatch, { promotionType: 'NFOC' });
    getSalesSettlementListAPI(dispatch, { promotionType: 'FOC' });
  }, []);

  return (
    <>
      {/* 유상 페이지  */}
      <h3 className={cn('areaTitle')}>유상 매출 정산</h3>
      <div className={cn('stepGuideText')}>
        1-1. 31일전에는 업체 승인 취소후 매출 재조정 가능, 31일후에는 조정
        불가합니다.
        <br /> 업체별 유상 거래액 및 매출 채권 금액을 확인해주세요. 만약 맞지
        않으면 매출 조정 페이지로 이동해주세요. (확정 후 변경 된 금액은 다음달
        매출에 반영됩니다)
      </div>

      <div className={cn('table_header')}>
        <div className={cn('box_left')}>
          <strong></strong>
        </div>
      </div>

      <div className={cn('dataTable01')}>
        <TableV8ManualPage
          columns={nfocColumns}
          data={listNFOC}
          csvTitle='내보내기'
          disabledFooter
        />
      </div>

      <div className={cn('stepGuideText')}>
        <div className={cn('flex', 'justify-content-between')}>
          <div>
            1-2. 상기 업체에서 발행한 세금계산서가 수신되었는지
            확인해주세요.(이메일 수신 등 확인)
            <Button
              type='button'
              styleType='btn02'
              subType='type01'
              className={cn('ml10')}
            >
              <i className={cn('ico_download')}></i>GEVS 메뉴얼 다운로드
            </Button>
          </div>
          <div className={cn('btn_container10')}>
            <i
              className={cn(
                'ico_act',
                radioTargetStatus.gevsStatus === 'Y'
                  ? 'type03'
                  : radioTargetStatus.gevsStatus === 'N'
                    ? 'type01'
                    : 'type04'
              )}
            ></i>{' '}
            {radioTargetStatus.gevsStatus === 'Y'
              ? '완료'
              : radioTargetStatus.gevsStatus === 'N'
                ? '미완료'
                : ''}
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              disabled={radioTargetStatus.gevsStatus === 'Y'}
              className={cn('ml20')}
              onClick={() =>
                handleStatusChange('nfoc', {
                  updateType: 'GEVS',
                  changeStatus: 'APPROVAL',
                })
              }
            >
              GEVS 정산 완료
            </Button>
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              disabled={radioTargetStatus.gevsStatus === 'N'}
              className={cn('ml5')}
              onClick={() =>
                handleStatusChange('nfoc', {
                  updateType: 'GEVS',
                  changeStatus: 'CANCEL',
                })
              }
            >
              취소
            </Button>
          </div>
        </div>
      </div>

      <div className={cn('stepGuideText')}>
        <div className={cn('flex', 'justify-content-between')}>
          <div>
            1-3. 업체별 세금계산서를 수신 하셨나요? 확인 후 GERP 시스템을 통해
            정산 완료해주세요.(완료여부 확인)
            <Button
              type='button'
              styleType='btn02'
              subType='type01'
              className={cn('ml10')}
            >
              <i className={cn('ico_download')}></i>GERP AR 메뉴얼 다운로드
            </Button>
          </div>
          <div className={cn('btn_container10')}>
            <i
              className={cn(
                'ico_act',
                radioTargetStatus.gerpStatus === 'Y'
                  ? 'type03'
                  : radioTargetStatus.gerpStatus === 'N'
                    ? 'type01'
                    : 'type04'
              )}
            ></i>{' '}
            {radioTargetStatus.gerpStatus === 'Y'
              ? '완료'
              : radioTargetStatus.gerpStatus === 'N'
                ? '미완료'
                : ''}
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml20')}
              disabled={radioTargetStatus.gerpStatus === 'Y'}
              onClick={() =>
                handleStatusChange('nfoc', {
                  updateType: 'GERP',
                  changeStatus: 'APPROVAL',
                })
              }
            >
              GERP 정산 완료
            </Button>
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml5')}
              disabled={radioTargetStatus.gerpStatus === 'N'}
              onClick={() =>
                handleStatusChange('nfoc', {
                  updateType: 'GERP',
                  changeStatus: 'CANCEL',
                })
              }
            >
              취소
            </Button>
          </div>
        </div>
      </div>

      <div className={cn('stepGuideText')}>
        1-4. 상기 모든 항목의 확인이 모두 끝나셨으면 다음 버튼으로 이동해주세요.
      </div>

      {/* 무상 페이지 */}
      <h3 className={cn('areaTitle')}>무상</h3>
      <div className={cn('stepGuideText')}>
        1-1. 업체별 무상 거래액을 확인해주세요. 만약 맞지 않으면 매출 조정
        페이지로 이동해주세요. (제휴사, LG전자 매출승인버튼 취소 후 조정 가능)
      </div>

      {/* <div className={cn('table_header')}>
        <div className={cn('box_left')}>
          <strong></strong>
        </div>
        <div className={cn('box_right')}>
          <Button type='button' styleType='btn03' subType='type01'>
            매출 조정 페이지로 이동
          </Button>
        </div>
      </div> */}

      <div className={cn('dataTable01')}>
        <TableV8ManualPage
          columns={focColumns}
          data={listFOC}
          csvTitle='내보내기'
          disabledFooter
        />
      </div>

      <div className={cn('stepGuideText')}>
        <div className={cn('flex', 'justify-content-between')}>
          <div>
            1-2. 상기 업체에 세금계산서 발행을 요청해 주세요.(업체에 이메일,
            유선 연락 진행){' '}
          </div>
          <div className={cn('btn_container10')}>
            <i
              className={cn(
                'ico_act',
                radioTargetStatus.taxBillRequestFlag === 'Y'
                  ? 'type03'
                  : radioTargetStatus.taxBillRequestFlag === 'N'
                    ? 'type01'
                    : 'type04'
              )}
            ></i>{' '}
            {radioTargetStatus.taxBillRequestFlag === 'Y'
              ? '완료'
              : radioTargetStatus.taxBillRequestFlag === 'N'
                ? '미완료'
                : ''}
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml20')}
              disabled={radioTargetStatus.taxBillRequestFlag === 'Y'}
              onClick={() =>
                handleStatusChange('foc', {
                  updateType: 'TAX_BILL_REQUEST',
                  changeStatus: 'APPROVAL',
                })
              }
            >
              세금계산서 요청 완료
            </Button>
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml5')}
              disabled={radioTargetStatus.taxBillRequestFlag === 'N'}
              onClick={() =>
                handleStatusChange('foc', {
                  updateType: 'TAX_BILL_REQUEST',
                  changeStatus: 'CANCEL',
                })
              }
            >
              취소
            </Button>
          </div>
        </div>
      </div>

      <div className={cn('stepGuideText')}>
        <div className={cn('flex', 'justify-content-between')}>
          <div>
            1-2. 상기 업체에서 발행한 세금계산서가 수신되었는지
            확인해주세요.(이메일 수신 등 확인)
          </div>
          <div className={cn('btn_container10')}>
            <i
              className={cn(
                'ico_act',
                radioTargetStatus.taxBillResponseFlag === 'Y'
                  ? 'type03'
                  : radioTargetStatus.taxBillResponseFlag === 'N'
                    ? 'type01'
                    : 'type04'
              )}
            ></i>{' '}
            {radioTargetStatus.taxBillResponseFlag === 'Y'
              ? '완료'
              : radioTargetStatus.taxBillResponseFlag === 'N'
                ? '미완료'
                : ''}
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml20')}
              disabled={radioTargetStatus.taxBillResponseFlag === 'Y'}
              onClick={() =>
                handleStatusChange('foc', {
                  updateType: 'TAX_BILL_RESPONSE',
                  changeStatus: 'APPROVAL',
                })
              }
            >
              세금계산서 확인 완료
            </Button>
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml5')}
              disabled={radioTargetStatus.taxBillResponseFlag === 'N'}
              onClick={() =>
                handleStatusChange('foc', {
                  updateType: 'TAX_BILL_RESPONSE',
                  changeStatus: 'CANCEL',
                })
              }
            >
              취소
            </Button>
          </div>
        </div>
      </div>

      <div className={cn('stepGuideText')}>
        <div className={cn('flex', 'justify-content-between')}>
          <div>
            1-3. 업체별 세금계산서를 수신 하셨나요? 확인 후 APMS 시스템을 통해
            정산 완료해주세요.(완료여부 확인)
            <Button
              type='button'
              styleType='btn02'
              subType='type01'
              className={cn('ml10')}
            >
              <i className={cn('ico_download')}></i>APMS 메뉴얼 다운로드
            </Button>
          </div>
          <div className={cn('btn_container10')}>
            <i
              className={cn(
                'ico_act',
                radioTargetStatus.apmsCompleteFlag === 'Y'
                  ? 'type03'
                  : radioTargetStatus.apmsCompleteFlag === 'N'
                    ? 'type01'
                    : 'type04'
              )}
            ></i>{' '}
            {radioTargetStatus.apmsCompleteFlag === 'Y'
              ? '완료'
              : radioTargetStatus.apmsCompleteFlag === 'N'
                ? '미완료'
                : ''}
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml20')}
              disabled={radioTargetStatus.apmsCompleteFlag === 'Y'}
              onClick={() =>
                handleStatusChange('foc', {
                  updateType: 'APMS',
                  changeStatus: 'APPROVAL',
                })
              }
            >
              APMS 정산 완료
            </Button>
            <Button
              type='button'
              styleType='btn03'
              subType='type01'
              className={cn('ml5')}
              disabled={radioTargetStatus.apmsCompleteFlag === 'N'}
              onClick={() =>
                handleStatusChange('foc', {
                  updateType: 'APMS',
                  changeStatus: 'CANCEL',
                })
              }
            >
              취소
            </Button>
          </div>
        </div>
      </div>

      <div className={cn('stepGuideText')}>
        1-4. 상기 모든 항목의 확인이 모두 끝나셨으면 다음 버튼으로 이동해주세요.
      </div>
    </>
  );
};

export default AccountingTaxBillStepFirst;
