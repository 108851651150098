import { TAxios } from 'redux/function/TAxios';
import {
  getUserId,
  setNewVendor,
  setBusinessInfo,
  resetBusinessRegister,
} from '@feature/signUp/signUpSlice';
import { setVendorList } from '@feature/common/commonSlice';
import { ROUTER_PATH } from '@router/routerPath';
import { openModalAlert } from '@feature/common/commonSlice';

/**
 * @category Action
 * @namespace getCheckVendorExist
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * @property {string} params.companyRegNo - 사업자 등록 번호 (예: "6518100883")
 */

const getCheckVendorExist = (
  dispatch,
  params,
  handleClickStepNext,
  selectedVendorInfo
) => {
  const URL = '/api/1.0/app/user/vendor/checkVendorExist';

  const onSuccess = (response) => {
    if (response.data.rs === null) {
      if (
        selectedVendorInfo?.id === 'NEW' ||
        Object.keys(selectedVendorInfo).length === 0
      ) {
        dispatch(openModalAlert({ message: '신규 제휴사입니다.' }));
        dispatch(
          setNewVendor({
            companyCode: selectedVendorInfo?.id,
            companyName: selectedVendorInfo?.title,
            vendorKey: selectedVendorInfo?.vendorKey,
          })
        );
        handleClickStepNext();
      } else
        dispatch(
          openModalAlert({
            message: '선택하신 제휴사명과 나머지 정보가 일치하지 않습니다.',
          })
        );
    } else {
      if (
        (response.data.rs?.companyCode === selectedVendorInfo?.id &&
          response.data.rs?.companyName === selectedVendorInfo?.title) ||
        Object.keys(selectedVendorInfo).length === 0
      ) {
        dispatch(openModalAlert({ message: '약관 페이지로 이동합니다' }));
        dispatch(
          setBusinessInfo({
            vendorKey: response.data.rs.vendorKey,
            companyCode: response.data.rs.companyCode,
            companyName: response.data.rs.companyName,
            companyRegNo: response.data.rs.companyRegNo,
            companyCeoName: response.data.rs.companyCeoName,
            companyOpenDate: response.data.rs.companyOpenDate,
            bizRegisDocFileKey: response.data.rs.bizRegisDocFileKey,
            ecomRegisDocFileKey: response.data.rs.ecomRegisDocFileKey,
          })
        );
        handleClickStepNext();
      } else
        dispatch(
          openModalAlert({
            message: '선택하신 제휴사명과 나머지 정보가 일치하지 않습니다.',
          })
        );
    }
  };
  const onFail = (response) => {
    console.log('getCheckVendorExist onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace postCreateVendorUser
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 * @example
 * const params = {
      userId: "testUser",
      password: "12345678",
      phoneNo: "010-1234-5678",
      email: "test@gmail.com",
      companyName: "(주)티윈",
      companyRegNo: "6518100883",
    };
 * @property {string} params.userId - 사용자 아이디
 * @property {string} params.password - 사용자 비밀번호
 * @property {string} params.phoneNo - 사용자 핸드폰 번호
 * @property {string} params.email - 이메일
 * @property {string} params.companyCode - 상호코드
 * @property {string} params.vendorKey - 상호명
 * @property {string} params.companyName - 상호명
 * @property {string} params.companyRegNo - 사업자 등록 번호
 * @property {string} params.departmentName - 소속부서
 * @property {file} params.registrationDocFiles - 사업자등록증 파일
 * @property {file} params.salesDocFiles - 통신판매업등록증 파일
 */

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    // post method params 데이터 form-data 형식으로 보내기 위한 header 추가
  },
};

const postCreateVendorUser = (dispatch, params, handleNavigate) => {
  const URL = '/api/1.0/app/user/vendor/createVendorUser';

  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      dispatch(openModalAlert({ message: '저장 완료' }));
      dispatch(resetBusinessRegister());
      handleNavigate(ROUTER_PATH.ROOT);
    } else
      dispatch(
        openModalAlert({
          message: '회원가입이 되지 않았습니다. 정보를 확인해주세요.',
        })
      );
  };
  const onFail = (response) => {
    console.log('postCreateVendorUser onFail ', response);
    dispatch(openModalAlert({ message: '회원가입이 되지 않았습니다.' }));
  };

  if (params.registrationDocFiles || params.salesDocFiles) {
    const upLoadHeader = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        // post method params file 보내기 위한 header 추가
      },
    };

    let formData = new FormData();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (key === 'registrationDocFiles' || key === 'salesDocFiles') {
          formData.append(key, params[key][0]);
        } else {
          formData.append(key, params[key]);
        }
      }
    }

    TAxios(
      undefined,
      undefined,
      'post',
      URL,
      formData,
      onSuccess,
      onFail,
      true,
      upLoadHeader
    );
  } else {
    TAxios(
      undefined,
      undefined,
      'post',
      URL,
      params,
      onSuccess,
      onFail,
      true,
      header
    );
  }
};

/**
 * @category Action
 * @namespace getCheckIdAvailable
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @param {function} setId - id input 초기화 해주기 위한 set 함수
 * @example
    const params = {
      userId: id,
    };
 * @property {string} params.userId - 사용자 아이디
 */

const getCheckIdAvailable = (dispatch, params, setId) => {
  const URL = '/api/1.0/app/user/checkIdAvailable';

  const onSuccess = (response) => {
    if (response.data.rs.existFlag === 'Y') {
      dispatch(
        openModalAlert({
          message: '중복되는 ID입니다. 다른 ID를 사용해주세요.',
        })
      );
      setId('');
    } else dispatch(openModalAlert({ message: '사용 가능한 ID입니다.' }));
  };

  const onFail = (response) => {
    console.log('checkIdAvailable onFail ', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, true);
};

/**
 * @category Action
 * @namespace getFindVendorUserId
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
    const params = {
    email: email,
      companyName: companyName,
      companyRegNo: companyRegistrationNumber,
    };
 * @property {string} params.email - 사용자 이메일
 * @property {string} params.companyName - 상호명
 * @property {string} params.companyRegNo - 사업자 등록 번호
 */

const getFindVendorUserId = (
  dispatch,
  params,
  setIsOpenConfirm,
  setIsOpenFail,
  setFailPopUpMsg
) => {
  const URL = '/api/1.0/app/user/vendor/findVendorUserId';

  const onSuccess = (response) => {
    if (response.data.rs === null) {
      setFailPopUpMsg('아이디 찾기 실패');
      setIsOpenFail(true);
    } else if (response.data.rs.result === 'SUCCESSED') {
      dispatch(getUserId(response.data.rs.userId));
      setIsOpenConfirm(true);
    }
  };
  const onFail = (response) => {
    console.log('findVendorUserId onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, true);
};

/**
 * @category Action
 * @namespace getFindInternallUserId
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @property {string} params.email - 사용자 이메일
 */

const getFindInternallUserId = (
  dispatch,
  params,
  setIsOpenConfirm,
  setIsOpenFail,
  setFailPopUpMsg
) => {
  const URL = '/api/1.0/app/user/findInternalUserId';

  const onSuccess = (response) => {
    if (response.data.rs === null) {
      setFailPopUpMsg('아이디 찾기 실패');
      setIsOpenFail(true);
    } else if (response.data.rs.result === 'SUCCESSED') {
      dispatch(getUserId(response.data.rs.userId));
      setIsOpenConfirm(true);
    }
  };
  const onFail = (response) => {
    console.log('getFindInternallUserId onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, true);
};

/**
 * @category Action
 * @namespace postIssueTempPassword
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 * @example
    const params = {
      email: email,
      companyName: companyName,
      companyRegNo: companyRegistrationNumber,
    };
 * @property {string} params.email - 사용자 이메일
 * @property {string} params.companyName - 상호명
 * @property {string} params.companyRegNo - 사업자 등록 번호
 */

const getCheckUserExist = (dispatch, params, setIsOpenModifyPassword) => {
  const URL = '/api/1.0/app/user/vendor/checkUserExist';

  const onSuccess = (response) => {
    if (response.data.rs?.existFlag === 'N') {
      dispatch(openModalAlert({ message: '정보를 확인해주세요' }));
    } else {
      setIsOpenModifyPassword(true);
    }
  };
  const onFail = (response) => {
    console.log('getCheckUserExist onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

const getRetrieveCsmsVendorList = (dispatch, params) => {
  const URL = '/api/1.0/app/common/retrieveCsmsVendorList';

  const onSuccess = (response) => {
    if (response.data.rs.list) {
      dispatch(setVendorList(response.data.rs.list));
    }
  };
  const onFail = (response) => {
    console.log('getRetrieveCsmsVendorList onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

export {
  getCheckVendorExist,
  postCreateVendorUser,
  getCheckIdAvailable,
  getFindVendorUserId,
  getFindInternallUserId,
  getCheckUserExist,
  getRetrieveCsmsVendorList,
};
