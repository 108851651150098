import { createQueryString } from '@utils';
import { setHolidayList } from '@feature/holidays/holidaysSlice';
import axios from 'axios';

const fetchHolidays = async ({ params, onSuccess, onFail }) => {
  const BASE_URL =
    'https://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService';
  // FIXME: 테스트를 위해 개인 계정으로 발행한 키로 추후 수정 필요
  const API_KEY =
    'eZ17cnoKvHmrAu76may4JvwjqwpWBdD2bP%2Fs4mFIZjIphAOMnKRq8yOHaC3DXjYEpWJyic%2FdtW14XLgGJxJ%2B1g%3D%3D';
  const promises = [-1, 0, 1].reduce((acc, yearValue) => {
    const URL =
      `${BASE_URL}/getRestDeInfo?serviceKey=${API_KEY}&` +
      createQueryString({ solYear: params.solYear + yearValue }) +
      '&numOfRows=100';

    acc.push(axios.get(URL));

    return acc;
  }, []);
  Promise.all(promises)
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onFail && onFail(error);
    });
};

/**
 * @category Action
 * @namespace getHolidays
 * @param {function} dispatch - dispatch 함수
 * @param {{ solYear: number }} params - get parameters
 */

const getHolidays = (dispatch, params) => {
  const onSuccess = (response) => {
    const holidaysOfKorea = response.reduce((acc, holidays) => {
      const {
        data: {
          response: {
            body: { items: { item } = {} },
          },
        },
      } = holidays;

      if (item) {
        const newItem = item.reduce((acc, { locdate }) => {
          const date = locdate.toString();

          const [YYYY, MMDD] = [date.slice(0, 4), date.slice(4)];

          if (!acc[YYYY]) acc[YYYY] = [];
          acc[YYYY].push(MMDD);

          return acc;
        }, {});

        return Object.assign(acc, newItem);
      }

      return acc;
    }, {});
    dispatch(setHolidayList(holidaysOfKorea));
  };
  const onFail = (error) => {
    console.error('getHolidays onFail ', error);
  };

  fetchHolidays({ params, onSuccess, onFail });
};

export { getHolidays };
