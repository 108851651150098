import { memo } from 'react';
import css from './TextButton.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace TextButton
 * @param {any} children TextButton 의 자식 요소.
 * @param {string} styleType Button 의 타입 (btn_txt01, btn_txt02, btn_txt03, btn_txt04).

 * @description 공통적으로 사용하는 TextButton 컴포넌트.
 */
const TextButton = ({ children, styleType, ...rest }) => {
  return (
    <div className={cn('textButton', styleType)} {...rest}>
      {children}
    </div>
  );
};

TextButton.defaultProps = {
  /** TextButton 타입 지정 (btn_txt01, btn_txt02, btn_txt03, btn_txt04) */
  styleType: 'btn_txt01',
};

export default memo(TextButton);
