import React, { useEffect, useId } from 'react';
import classNames from 'classnames/bind';
import css from './RefundRetrieveSearchArea.module.scss';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import { useFormContext } from 'react-hook-form';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import HFInput from '@components/_ReactHookForm/HFInput';
import { Button } from '@components/index';
import { MILLISECONDS_PER_DAY } from '@data/Const';

const cn = classNames.bind(css);
const DATE_NOW = Date.now();

const RADIO_INPUT_VALUES = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  ONE_MONTH: 'ONE_MONTH',
};

const RefundRetrieveSearchArea = ({ categorySearchOptions, onSearchClick }) => {
  const { register, control, watch, handleSubmit, setValue } = useFormContext();
  const radioDateWatch = watch('radioDate');
  const radioInputId = useId();

  useEffect(() => {
    if (radioDateWatch !== undefined) {
      switch (radioDateWatch) {
        case RADIO_INPUT_VALUES.TODAY:
          setValue('fromDate', new Date(DATE_NOW));
          break;
        case RADIO_INPUT_VALUES.WEEK:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 7));
          break;
        case RADIO_INPUT_VALUES.ONE_MONTH:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30));
          break;
      }
    }
    setValue('toDate', new Date(DATE_NOW));
  }, [radioDateWatch, setValue]);

  return (
    <div>
      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={cn('flex')}>
          <div className={cn('innerBox')}>
            <strong className={cn('title')}>기간</strong>
            <HFCalendar
              name={'fromDate'}
              defaultValue={new Date('2023-08-01')}
              control={control}
            />
            <span className={cn('dash')}>-</span>
            <HFCalendar name={'toDate'} control={control} />
          </div>
          <div className={cn('input_radio', 'ml50')}>
            <HFInput
              name={'radioDate'}
              register={register}
              type={'radio'}
              id={`${radioInputId}-today`}
              value={RADIO_INPUT_VALUES.TODAY}
            />
            <label htmlFor={`${radioInputId}-today`}>오늘</label>
          </div>
          <div className={cn('input_radio')}>
            <HFInput
              name={'radioDate'}
              register={register}
              type={'radio'}
              id={`${radioInputId}-week`}
              value={RADIO_INPUT_VALUES.WEEK}
            />
            <label htmlFor={`${radioInputId}-week`}>일주일</label>
          </div>
          <div className={cn('input_radio')}>
            <HFInput
              name={'radioDate'}
              register={register}
              type={'radio'}
              id={`${radioInputId}-oneMonth`}
              value={RADIO_INPUT_VALUES.ONE_MONTH}
            />
            <label htmlFor={`${radioInputId}-oneMonth`}>1개월</label>
          </div>
        </div>
        <div className={cn('flex')}>
          <div className={cn('innerBox')}>
            <strong className={cn('title')}>구분</strong>
            <div className={cn('selectbox')}>
              <HFSelectBox
                control={control}
                name={'categorySearchOptions'}
                options={categorySearchOptions}
              />
            </div>
          </div>

          <div className={cn('ml20')}>
            <Button
              styleType={'btn01'}
              subType={'type03'}
              className={cn('btn')}
              onClick={handleSubmit(onSearchClick)}
            >
              검색
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundRetrieveSearchArea;
