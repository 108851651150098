import classNames from 'classnames/bind';
import css from './AuthoritySearchArea.module.scss';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import { DISABLED_DATE_TYPE, MILLISECONDS_PER_DAY } from '@data/Const';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import HFInput from '@components/_ReactHookForm/HFInput';
import { Button } from '@components/index';
import { useFormContext } from 'react-hook-form';

const cn = classNames.bind(css);
const DATE_NOW = Date.now();

const AuthoritySearchArea = ({
  statusSearchOptions,
  keywordSearchOptions,
  onSearchClick,
  selectApprovalStatus,
}) => {
  const { register, control, handleSubmit } = useFormContext();

  return (
    <div className={cn('searchOptionArea', 'row')}>
      <div className={cn('flex')}>
        <div className={cn('innerBox', 'date')}>
          <strong className={cn('title')}>조회 기간</strong>
          <HFCalendar
            name={'startDate'}
            control={control}
            disabledDateTypeList={[
              DISABLED_DATE_TYPE.HOLIDAY,
              DISABLED_DATE_TYPE.WEEKEND,
            ]}
            defaultValue={new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30)}
          />
          <span className={cn('dash')}>-</span>
          <HFCalendar
            name={'endDate'}
            control={control}
            disabledDateTypeList={[
              DISABLED_DATE_TYPE.HOLIDAY,
              DISABLED_DATE_TYPE.WEEKEND,
            ]}
          />
        </div>
        <div className={cn('innerBox', 'status')}>
          <strong className={cn('title')}>승인 상태</strong>
          <HFSelectBox
            control={control}
            name={'statusSearchCondition'}
            options={statusSearchOptions}
            selectApprovalStatus={selectApprovalStatus}
          />
        </div>
      </div>
      <div className={cn('flex')}>
        <div className={cn('innerBox', 'option_detail')}>
          <strong className={cn('title')}>상세 조건</strong>
          <HFSelectBox
            control={control}
            name={'keywordSearchCondition'}
            options={keywordSearchOptions}
          />
          <div className={cn('inputWrap')}>
            <HFInput
              register={register}
              name={'keyword'}
              type={'text'}
              placeholder={'검색 조건을 선택해 주세요'}
            />
            <Button
              styleType={'btn01'}
              subType={'type03'}
              className={cn('btn')}
              onClick={handleSubmit(onSearchClick)}
            >
              검색
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthoritySearchArea;
