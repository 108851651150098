import React, { useCallback, useEffect, useState } from 'react';
import css from './RefundAlarmTabFirst.module.scss';
import { useMemo } from 'react';
import classNames from 'classnames/bind';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Calendar, PopUp } from '@components/index';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAlert } from '@feature/common/commonSlice';
import { FormProvider, useForm } from 'react-hook-form';
import SendingResultSearchArea from '../module/SendingResultSearchArea';
import RefundAlarmDetailPopup from '../module/RefundAlarmDetailPopup';
import { getRefundTalkedreamResultList } from 'redux/action/refundAction';
import { convertDateToString2 } from 'utils/utils';
import { getRefundTalkdreamResultDashboardAPI } from 'redux/action/refundAction';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const receiverSearchOptions = {
  guide: '',
  list: [
    { id: 'ALL', title: '전체' },
    { id: 'CUSTOMER', title: '고객' },
    { id: 'VENDOR', title: '제휴사' },
    { id: 'LGE', title: 'LGE' },
  ],
};

const alarmTypeSearchOptions = {
  guide: '',
  list: [
    { id: 'CAT01', title: '기간내 미처리 환불지연' },
    { id: 'CAT02', title: '고객요청 환불지연' },
  ],
};

const sendingResultMockData = [
  {
    messageTargetDesc: '고객1',
    vendorName: '업체A',
    categoryDesc: '알림1',
    content: '알림톡 내용1',
    successedCount: 10,
    waitingCount: 5,
    failedCount: 2,
  },
  {
    messageTargetDesc: '고객2',
    vendorName: '업체B',
    categoryDesc: '알림2',
    content: '알림톡 내용2',
    successedCount: 8,
    waitingCount: 3,
    failedCount: 1,
  },
];

const RefundAlarmTabFirst = () => {
  const dispatch = useDispatch();
  const searchForms = useForm();
  const [isOpenSendingDetailPopUp, setIsOpenSendingDetailPopUp] =
    useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [tableOriginalData, setTableOriginalData] = useState({
    category: '',
    requestDatetime: '',
    vendorKey: '',
  });
  const vendorList = useSelector((state) => state.common.vendorList);
  const refundTalkedreamResultList = useSelector(
    (state) => state.refund.refundTalkedreamResultList
  );
  const refundTalkdreamResultDashboard = useSelector(
    (state) => state.refund.refundTalkdreamResultDashboard
  );

  const resultCounts = useMemo(
    () => ({
      // 전체 - 발송건수
      totalVendorResultCount:
        //기간내 미처리 환불지연 - 알림톡 성공/실패/대기중 + LMS성공/실패/대기중
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1SuccessedCnt +
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1FailedCnt +
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1WaitingCnt,

      totalCustomerResultCount:
        //고객요청 환불지연 - 알림톡 성공/실패/대기중 + LMS성공/실패/대기중
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1SuccessedCnt +
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1FailedCnt +
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1WaitingCnt,

      // 전체 - 성공건수
      successVendorResultCount:
        //기간내 미처리 환불지연 - 알림톡 성공 + LMS 성공
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1SuccessedCnt,
      successCustomerResultCount:
        //고객요청 환불지연 - 알림톡 성공 + LMS 성공
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1SuccessedCnt,

      // 전체 - 실패건수
      failedVendorResultCount:
        //기간내 미처리 환불지연 - 알림톡 실패 + LMS 실패
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1FailedCnt,
      //고객요청 환불지연 - 알림톡 실패 + LMS 실패
      failedCustomerResultCount:
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1FailedCnt,
    }),
    [refundTalkdreamResultDashboard]
  );

  const alarmDashboardList = [
    {
      alarmType: '기간내 미처리 환불지연',
      //전체
      totalSendCount: resultCounts.totalVendorResultCount,
      totalSuccess: resultCounts.successVendorResultCount,
      totalFailure: resultCounts.failedVendorResultCount,
      totalPending:
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1WaitingCnt,
      //알림톡: 알림톡은 현재 없음 추가 가능성이 있어서 0으로 고정
      alarmTalkSuccess: 0,
      alarmTalkFailure: 0,
      //LMS
      lmsSuccess:
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1SuccessedCnt,
      lmsFailure:
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1FailedCnt,
      lmsPending:
        refundTalkdreamResultDashboard.lmsRefundVendorDelayed1WaitingCnt,
    },
    {
      alarmType: '고객요청 환불지연',
      //전체
      totalSendCount: resultCounts.totalCustomerResultCount,
      totalSuccess: resultCounts.successCustomerResultCount,
      totalFailure: resultCounts.failedCustomerResultCount,
      totalPending:
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1WaitingCnt,
      //알림톡: 알림톡은 현재 없음 추가 가능성이 있어서 0으로 고정
      alarmTalkSuccess: 0,
      alarmTalkFailure: 0,
      //LMS
      lmsSuccess:
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1SuccessedCnt,
      lmsFailure:
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1FailedCnt,
      lmsPending:
        refundTalkdreamResultDashboard.lmsRefundCustomerDelayedD1WaitingCnt,
    },
  ];

  // FIXME: 테이블 리스트에 성공 된 건수의 합계 표시하는게 맞는지 확인 후 적용
  const totalSuccessedLength = useMemo(() => {
    return (
      refundTalkedreamResultList &&
      refundTalkedreamResultList
        .map((item) => item.totalCount - item.failedCount)
        .reduce((acc, cur) => {
          return acc + cur;
        }, 0)
    );
  }, [refundTalkedreamResultList]);

  // 화면 진입시 기본값 세팅값
  useEffect(() => {
    getRefundTalkedreamResultList(dispatch, {
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
      messageTarget: '',
      vendorKey: '',
      category: '',
      limit: '10',
      page: '1',
    });
    getRefundTalkdreamResultDashboardAPI(dispatch, {
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
    });
  }, []);

  // 검색 버튼 or 대시보드 클릭시 변경 되는 값 세팅
  const listParams = useCallback(
    (messageTarget, vendor, category) => {
      const result = {
        fromDate: convertDateToString2(startDate),
        toDate: convertDateToString2(endDate),
        messageTarget: messageTarget === 'ALL' ? '' : messageTarget,
        vendorKey: vendor,
        category: category,
        limit: '10',
        page: '1',
      };
      return result;
    },
    [startDate, endDate]
  );

  // 날짜 선택 이벤트 핸들러
  const handleStartDate = (date) => {
    if (date <= endDate) {
      setStartDate(date);
    } else {
      dispatch(
        openModalAlert({
          message: '시작 날짜는 종료 날짜보다 이전이어야 합니다.',
        })
      );
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      setEndDate(date);
    } else {
      dispatch(
        openModalAlert({
          message: '종료 날짜는 시작 날짜보다 이후이어야 합니다.',
        })
      );
    }
  };

  const vendorSearchOptions = useMemo(() => {
    let options = {
      guide: '제휴사명을 선택해주세요.',
      list: [],
    };
    if (vendorList.length > 0) {
      options.list = [
        ...options.list,
        ...vendorList
          .filter((item) => item.vendorKey !== 'VENDOR.00001')
          .map((item) => {
            return {
              id: item.vendorKey,
              title: item.companyName,
            };
          }),
      ];
    }
    return options;
  }, [vendorList]);

  const onSearchClick = useCallback(
    (data) => {
      const {
        receiverSearchOptions,
        vendorSearchOptions,
        alarmTypeSearchOptions,
      } = data;
      getRefundTalkedreamResultList(
        dispatch,
        listParams(
          receiverSearchOptions,
          vendorSearchOptions,
          alarmTypeSearchOptions
        )
      );
      getRefundTalkdreamResultDashboardAPI(dispatch, {
        fromDate: convertDateToString2(startDate),
        toDate: convertDateToString2(endDate),
      });
    },
    [listParams]
  );

  const sendingResultcolumns = useMemo(
    () => [
      columnHelper.accessor('messageTargetDesc', {
        id: 'messageTargetDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('categoryDesc', {
        id: 'categoryDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림 구분</span>,
      }),
      columnHelper.accessor('content', {
        id: 'content',
        cell: (info) => <div className={cn('xmp')}>{info.getValue()}</div>,
        header: () => <span>알림톡 내용</span>,
      }),
      {
        id: 'result',
        header: () => {
          return '최종결과';
        },
        cell: ({ row }) => {
          const { requestDatetime, category, vendorKey } = row?.original || {};
          return (
            <div className={cn('progressNum')}>
              <button
                onClick={() => {
                  setTableOriginalData((prevState) => ({
                    ...prevState,
                    requestDatetime: requestDatetime,
                    category: category,
                    vendorKey: vendorKey,
                  }));
                  setIsOpenSendingDetailPopUp(!isOpenSendingDetailPopUp);
                }}
              >
                <span className={cn('color-default')}>
                  총 {row.original.totalCount}건 /
                </span>
                {row.original.waitingCount > 0 && (
                  <span className={cn('color-pink')}>
                    {row.original.waitingCount}건 대기 /{' '}
                  </span>
                )}
                <span className={cn('color-pink')}>
                  {row.original.failedCount}건 실패
                </span>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  console.log('###???');

  return (
    <>
      {/* 텝메뉴 컨텐츠 */}
      <div className={cn('cont_area')}>
        {/* 텝메뉴 컨텐츠 1 */}
        <div id='tab1' className={cn('cont')}>
          {/* 검색 옵션 */}
          <div className={cn('searchOptionArea', 'mt20')}>
            <div className={cn('innerBox')}>
              <div className={cn('select_date')}>
                <Calendar
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  handleEndDate={handleEndDate}
                  setEndDate={setEndDate}
                />
              </div>
            </div>
          </div>
          {/* //검색 옵션 */}
          <div className={cn('dataTable01', 'type04')}>
            <table>
              <thead>
                <tr>
                  <th rowSpan='2'>알림 구분</th>
                  <th colSpan='4' className={cn('bg_type02')}>
                    전체
                  </th>
                  <th colSpan='2' className={cn('bg_type03')}>
                    알림톡
                  </th>
                  <th colSpan='3' className={cn('bg_type04')}>
                    LMS
                  </th>
                </tr>
                <tr>
                  <th className={cn('bg_type01')}>발송건수</th>
                  <th className={cn('bg_type01')}>성공</th>
                  <th className={cn('bg_type01')}>실패</th>
                  <th className={cn('bg_type01')}>대기중</th>
                  <th className={cn('bg_type01')}>성공</th>
                  <th className={cn('bg_type01')}>실패</th>
                  <th className={cn('bg_type01')}>성공</th>
                  <th className={cn('bg_type01')}>실패</th>
                  <th className={cn('bg_type01')}>대기중</th>
                </tr>
              </thead>
              <tbody>
                {alarmDashboardList.map((item, _idx) => (
                  <tr key={_idx}>
                    <td>{item.alarmType}</td>
                    <td>{item.totalSendCount}</td>
                    <td>{item.totalSuccess}</td>
                    <td className={cn('failed')}>{item.totalFailure}</td>
                    <td className={cn('failed')}>{item.totalPending}</td>
                    <td>{item.alarmTalkSuccess}</td>
                    <td className={cn('failed')}>{item.alarmTalkFailure}</td>
                    <td>{item.lmsSuccess}</td>
                    <td className={cn('failed')}>{item.lmsFailure}</td>
                    <td className={cn('failed')}>{item.lmsPending}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={cn('searchOptionArea', 'mt20')}>
            <FormProvider {...searchForms}>
              <SendingResultSearchArea
                vendorSearchOptions={vendorSearchOptions}
                receiverSearchOptions={receiverSearchOptions}
                alarmTypeSearchOptions={alarmTypeSearchOptions}
                onSearchClick={onSearchClick}
              />
            </FormProvider>
            <div className={cn('note', 'mt0', 'ml20')}>
              총{' '}
              <strong>
                <span className={cn('color-primary')}>
                  {totalSuccessedLength}
                </span>
              </strong>
              건 성공
            </div>
          </div>

          <TableV8ManualPage
            columns={sendingResultcolumns}
            data={refundTalkedreamResultList}
            hasCsvDownload={false}
          />
        </div>
      </div>

      <PopUp
        isOpenPopUp={isOpenSendingDetailPopUp}
        onRequestClose={() => setIsOpenSendingDetailPopUp(false)}
        closeButton
        isWideSize
        button={[
          <div className={cn('btn_container4')}>
            <Button
              key='alertConfirm'
              subType={'type03'}
              className={cn('btn')}
              fullWidth
            >
              닫기
            </Button>
          </div>,
        ]}
      >
        <RefundAlarmDetailPopup resultDetailListParam={tableOriginalData} />
      </PopUp>
    </>
  );
};

export default RefundAlarmTabFirst;
