import classNames from 'classnames/bind';
import css from './StatusDashBoard.module.scss';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateToString2 } from '@utils';
import { useSearchParams } from 'react-router-dom';

const cn = classNames.bind(css);
const today = new Date();

const StatusDashBoard = ({ data, onClickSearchParams }) => {
  const dispatch = useDispatch();

  const userVendorKey = useSelector((state) => state.user.userData.vendorKey);
  const myAuth = useSelector((state) => state.user.userData.authority);

  const [searchParams, setSearchParams] = useSearchParams();
  const vendorKey = searchParams.get('vendorKey');

  const {
    deliveryDelayedCnt,
    deliveryNewOrderCnt,
    deliveryCompletedCnt,
    deliveryPackageUnderPreparingCnt,
    deliveryPackagePreparedCnt,
    deliveryInTransitCnt,
  } = data;

  return (
    <div className={cn('statusDashBoard')}>
      <div className={cn('status', 'flow', 'shipping')}>
        <dl>
          <dt>신규주문</dt>
          <dd>
            <div
              className={cn('value')}
              onClick={() =>
                onClickSearchParams({
                  fromDate: '',
                  todate: convertDateToString2(new Date()),
                  venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
                  deliveryStatus: 'D001',
                  detailCondition: '',
                })
              }
            >
              <span className={cn('num')}>{deliveryNewOrderCnt}</span>건
            </div>
          </dd>
        </dl>
        <dl>
          <dt>
            상품 준비중
            <div className={cn('note')}>재고확인</div>
          </dt>
          <dd>
            <div
              className={cn('value')}
              onClick={() =>
                onClickSearchParams({
                  fromDate: '',
                  todate: convertDateToString2(new Date()),
                  venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
                  deliveryStatus: 'D002',
                  detailCondition: '',
                })
              }
            >
              <span className={cn('num')}>
                {deliveryPackageUnderPreparingCnt}
              </span>
              건
            </div>
            <ul className={cn('detail')}>
              <li>
                신규 <span className={cn('color_primary')}>{0}</span>
              </li>
              <li>
                재배송 <span className={cn('color_pink')}>{0}</span>
              </li>
            </ul>
          </dd>
        </dl>
        <dl>
          <dt>
            상품 준비 완료
            <div className={cn('note')}>배송 대기</div>
          </dt>
          <dd>
            <div
              className={cn('value')}
              onClick={() =>
                onClickSearchParams({
                  fromDate: '',
                  todate: convertDateToString2(new Date()),
                  venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
                  deliveryStatus: 'D003',
                  detailCondition: '',
                })
              }
            >
              <span className={cn('num')}>{deliveryPackagePreparedCnt}</span>건
            </div>
          </dd>
        </dl>
        <dl>
          <dt>
            배송중
            <div className={cn('note')}>운송장번호 입력</div>
          </dt>
          <dd>
            <div
              className={cn('value')}
              onClick={() =>
                onClickSearchParams({
                  fromDate: '',
                  todate: convertDateToString2(new Date()),
                  venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
                  deliveryStatus: 'D004',
                  detailCondition: '',
                })
              }
            >
              <span className={cn('num')}>{deliveryInTransitCnt}</span>건
            </div>
          </dd>
        </dl>
        <dl>
          <dt>
            배송 완료
            <div className={cn('note')}>배송 완료 확정</div>
          </dt>
          <dd>
            <div
              className={cn('value')}
              onClick={() =>
                onClickSearchParams({
                  fromDate: '',
                  todate: convertDateToString2(new Date()),
                  venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
                  deliveryStatus: 'D005',
                  detailCondition: '',
                })
              }
            >
              <span className={cn('num')}>{deliveryCompletedCnt}</span>건
            </div>
          </dd>
        </dl>
      </div>
      <div className={cn('delay')}>
        <dl>
          <dt>배송 지연</dt>
          <dd>
            <div
              className={cn('value')}
              onClick={() =>
                onClickSearchParams({
                  fromDate: '',
                  todate: convertDateToString2(new Date()),
                  venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
                  deliveryStatus: 'D006',
                  detailCondition: '',
                })
              }
            >
              <span className={cn('num')}>{deliveryDelayedCnt}</span>건
            </div>
          </dd>
        </dl>
        <p className={cn('note', 'mt4')}>{`배송 중 D+${5} 이상`}</p>
      </div>
    </div>
  );
};

export default StatusDashBoard;
