import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contents: {},
  customerRefundDashboard: {
    refundOngoingCount: 0,
    refundCompletedDeliveryCount: 0,
    refundCompletedCouponCount: 0,
  },

  vendorRefundStatusList: {
    totalCount: { additionalInfo: 0 },
    list: [],
  },
  vendorRefundDashboard: {
    unusedCouponCount: 0,
    statusChangeCompletedCouponCount: 0,
  },
  refundCompletedList: {},
  refundTalkedreamResultList: [],
  refundTalkedreamResultDetailList: {},
  refundTalkdreamResultDashboard: {
    lmsRefundCustomerDelayedD1SuccessedCnt: 0,
    lmsRefundCustomerDelayedD1FailedCnt: 0,
    lmsRefundCustomerDelayedD1WaitingCnt: 0,
    lmsRefundVendorDelayed1SuccessedCnt: 0,
    lmsRefundVendorDelayed1WaitingCnt: 0,
    lmsRefundVendorDelayed1FailedCnt: 0,
  },
};

const refund = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    getCustomerRefund: (state, action) => {
      state.contents = action.payload;
    },
    getCustomerRefundDashBoard: (state, action) => {
      state.customerRefundDashboard = action.payload;
    },
    getVendorRefundStatusList: (state, action) => {
      state.vendorRefundStatusList = action.payload;
    },
    getVendorRefundStatusDashboard: (state, action) => {
      state.vendorRefundDashboard = action.payload;
    },
    getRefundCompleted: (state, action) => {
      state.refundCompletedList = action.payload;
    },
    getRefundTalkedreamResult: (state, action) => {
      state.refundTalkedreamResultList = action.payload;
    },
    getRefundTalkdreamResultDetail: (state, action) => {
      state.refundTalkedreamResultDetailList = action.payload;
    },
    getRefundTalkdreamResultDashboard: (state, action) => {
      state.refundTalkdreamResultDashboard = action.payload;
    },
  },
});

export const {
  getCustomerRefund,
  getCustomerRefundDashBoard,
  getVendorRefundStatusList,
  getVendorRefundStatusDashboard,
  getRefundCompleted,
  getRefundTalkedreamResult,
  getRefundTalkdreamResultDetail,
  getRefundTalkdreamResultDashboard,
} = refund.actions;

export default refund.reducer;
