import React from 'react';
import classNames from 'classnames/bind';
import css from './AccountingManageStepFlow.module.scss';
import { useSelector } from 'react-redux';

const cn = classNames.bind(css);

const AccountingManageStepFlow = () => {
  const settlementTargetStatusInfo = useSelector(
    (state) => state.settlement.settlementTargetStatusInfo
  );

  const DATE_NOW = new Date();

  return (
    <>
      <h2 className={cn('pageTitle')}>업체별 매출 조정 및 확정</h2>
      <div className={cn('statusDashBoard')}>
        <div className={cn('sales_count')}>
          <dl className={cn('case')}>
            <dt>
              {`${DATE_NOW.getMonth() + 1}월 매출 대상 건수`}
              <span className={cn('date')}>기준 : 전월 26일~ 당월 25일</span>
            </dt>
            <dd>
              <span className={cn('num')}>
                {settlementTargetStatusInfo.settlementTargetInfo.totalCnt}
              </span>{' '}
              건
            </dd>
          </dl>
          <div className={cn('detail_count_wrap')}>
            <dl className={cn('detail_count')}>
              <dt>유상</dt>
              <dd>
                <div className={cn('value')}>
                  <span className={cn('num')}>
                    {settlementTargetStatusInfo.settlementTargetInfo.nfocCnt}
                  </span>{' '}
                  건
                </div>
                <div className={cn('amount')}>
                  <span className={cn('num')}>
                    {
                      settlementTargetStatusInfo.settlementTargetInfo
                        .nfocSellTotal
                    }
                  </span>
                  원
                </div>
              </dd>
            </dl>
            <dl className={cn('detail_count')}>
              <dt>무상</dt>
              <dd>
                <div className={cn('value')}>
                  <span className={cn('num')}>
                    {settlementTargetStatusInfo.settlementTargetInfo.focCnt}
                  </span>{' '}
                  건
                </div>
                <div className={cn('amount')}>
                  <span className={cn('num')}>
                    {
                      settlementTargetStatusInfo.settlementTargetInfo
                        .focSellTotal
                    }
                  </span>
                  원
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className={cn('sales_count')}>
          <dl className={cn('case')}>
            <dt>
              {`${DATE_NOW.getMonth() + 1}월 매출 확정 건수`}
              <span className={cn('date')}>기준 : 전월 26일~ 당월 25일</span>
            </dt>
            <dd>
              <span className={cn('num')}>
                {settlementTargetStatusInfo?.settlementApprovalInfo?.totalCnt ??
                  0}
              </span>{' '}
              건
            </dd>
          </dl>
          <div className={cn('detail_count_wrap')}>
            <dl className={cn('detail_count')}>
              <dt>유상</dt>
              <dd>
                <div className={cn('amount')}>
                  {settlementTargetStatusInfo?.settlementApprovalInfo
                    ?.nfocCnt ?? 0}{' '}
                  건
                </div>
              </dd>
            </dl>
            <dl className={cn('detail_count')}>
              <dt>무상</dt>
              <dd>
                <div className={cn('amount')}>
                  {settlementTargetStatusInfo?.settlementApprovalInfo?.focCnt ??
                    0}{' '}
                  건
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>

      <div className={cn('stepsFlow', 'type03', 'mt32')}>
        <div className={cn('steps', 'on')}>업체별 매출 조정 및 확정</div>
        <div className={cn('steps')}>업체별 거래액 송부 및 세금계산서</div>
      </div>
    </>
  );
};

export default AccountingManageStepFlow;
