import React, { useMemo, useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingList.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { FormProvider, useForm } from 'react-hook-form';
import AccountingListSearchArea from './module/AccountingListSearchArea';
import { useDispatch, useSelector } from 'react-redux';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { getSettlementHistoryAPI } from 'redux/action/settlementAction';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const DATE_NOW = new Date();
const AccountingList = () => {
  const vendorList = useSelector((state) => state.common.vendorList);
  const searchForms = useForm();
  const dispatch = useDispatch();
  const level = useSelector((state) => state.user.userData.authority);
  const companyName = useSelector((state) => state.user.userData.companyName);
  const isLevel3 = level === 'LEVEL_3';
  const settlementHistoryList = useSelector(
    (state) => state.settlement.settlementHistory
  );

  const generateDateList = useCallback(() => {
    let currentDate = new Date(DATE_NOW);
    const dateList = [];

    // 현재로부터 1년 전까지의 날짜를 생성
    for (let i = 0; i < 12; i++) {
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');

      dateList.push({
        id: `${year}-${month}`,
        title: `${year}년 ${month}월`,
      });

      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    // 배열을 역순으로 정렬 (최신 월이 먼저 오도록)
    dateList.reverse();

    return dateList;
  }, []);

  const periodSearchOptions = useMemo(() => {
    return {
      guide: '',
      list: generateDateList(),
    };
  }, [generateDateList]);

  useEffect(() => {
    getSettlementHistoryAPI(dispatch, {
      // Todo: vendorName 변경해야됨 전체('')인지 지정(lge)인지
      vendorName: isLevel3 ? companyName : '',
      settlementMonth: periodSearchOptions.list[0].id,
    });
  }, []);

  const selectVendorList = useMemo(() => {
    return vendorList.map((vendor) => ({
      id: vendor.companyName,
      title: vendor.companyName,
      vendorKey: vendor.vendorKey,
    }));
  }, [vendorList]);

  const vendorSearchOptions = {
    guide: '',
    list: selectVendorList,
  };

  //Todo: 임시 onSearchClick mockData제거 api와 연동해야함
  const onSearchClick = useCallback((data) => {
    const { vendorSearchOptions, periodSearchOptions } = data;
    getSettlementHistoryAPI(dispatch, {
      vendorName: isLevel3 ? companyName : vendorSearchOptions,
      settlementMonth: periodSearchOptions,
    });
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('settlementMonth', {
        id: 'settlementMonth',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산월</span>,
      }),
      columnHelper.accessor('promotionTypeDesc', {
        id: 'promotionTypeDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('dataType', {
        id: 'dataType',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>구분</span>,
      }),
      columnHelper.accessor('sellCnt', {
        id: 'sellCnt',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>매출건수</span>,
      }),
      columnHelper.accessor('sellTotal', {
        id: 'sellTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>판매총액</span>,
      }),

      columnHelper.accessor('marginTotal', {
        id: 'marginTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>마진총액</span>,
      }),
      columnHelper.accessor('netTotal', {
        id: 'netTotal',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>공급가액</span>,
      }),
    ],
    [settlementHistoryList]
  );

  return (
    <>
      <h2 className={cn('pageTitle')}>업체별 정산내역 조회</h2>
      <FormProvider {...searchForms}>
        <AccountingListSearchArea
          vendorSearchOptions={vendorSearchOptions}
          periodSearchOptions={periodSearchOptions}
          isLevel3={isLevel3}
          onSearchClick={onSearchClick}
        />
      </FormProvider>
      <TableV8ManualPage
        columns={columns}
        data={settlementHistoryList}
        csvTitle='내보내기'
      />
    </>
  );
};

export default AccountingList;
