import React, { useCallback, useMemo } from 'react';
import css from './ContractPopup.module.scss';
import {
  contractKeyNames,
  historyKeyNames,
  subscriptionKeyNames,
} from './Constants';
import Loading from 'template/Loading/Loading';
import { useSelector } from 'react-redux';
import { PopUp } from '@components';

const DUMMY = {
  historyInfo: [
    {
      orderSeq: '1',
      deliveryCompleteDate: '',
      trackerDeliveryCompanyDesc: null,
      serviceTimes: '3',
      changeApprovalComment: null,
      deliveryKey: 'DELIVERY.1707805473000000002',
      deliveryStatusDesc: '배송중',
      deliveryCategoryDesc: '재배송',
      subscribeYearMonth: '2023-10',
      insertDatetime: '2024-02-29 09:18:31',
      trackingNo: null,
      deliveryPlanNo: '104522127',
      contractLineNo: '18532259',
      promotionTypeDesc: '무상',
      changeTargetStatusDesc: '배송완료',
      changeRequestComment: '',
      deliveryStatus: 'D004',
    },
    {
      orderSeq: '1',
      deliveryCompleteDate: '',
      trackerDeliveryCompanyDesc: null,
      serviceTimes: '3',
      changeApprovalComment: null,
      deliveryKey: 'DELIVERY.1707805473000000002',
      deliveryStatusDesc: '배송중',
      deliveryCategoryDesc: '신규',
      subscribeYearMonth: '2023-10',
      insertDatetime: '2024-02-29 09:18:38',
      trackingNo: null,
      deliveryPlanNo: '104522127',
      contractLineNo: '18532259',
      promotionTypeDesc: '무상',
      changeTargetStatusDesc: '배송완료',
      changeRequestComment: '',
      deliveryStatus: 'D004',
    },
  ],

  contractInfo: {
    customerAddress: '서울 중구 후암로 98 (남대문로5가)',
    orderType: 'LG생활건강 세탁세제 정기배송(3개월 무료)',
    contractStartDate: '2023-09-07',
    receiverName: '현지선',
    customerTelNo: '01000004900',
    serviceTimes: '1',
    contractEndDate: '2043-09-06',
    receiverMobileNo: '01035977415',
    receiverTelNo: '01035977415',
    customerName: '김0현',
    customerTelNo: '계약자 전화번호',
    customerMobileNo: '01000004900',
    receiverAddress: '서울 중구 후암로 98 (남대문로5가)',
    merchandiseId: 'ADA0001',
    customerEmail: 'cecil1126@naver.com',
    contractLineNo: '20229476',
    promotionTypeDesc: '무상',
    merchandiseDesc: '세탁기 세탁세제',
  },
  subscriptionInfo: {
    paymentCmplDate: '2023-12-19',
    trackerDeliveryStatusDesc: null,
    deliveryCompleteDate: null,
    paymentCmplFlag: 'Y',
    trackerDeliveryCompanyDesc: null,
    trackingNo: null,
    deliveryDays: null,
    deliveryStatusDesc: '신규주문',
    changeTargetStatusDesc: null,
    changeApprovalStatus: null,
    // deliveryStatus: 'D001',
    deliveryDelayedFlag: '',
  },
};

const DeliveryInfoDetail = ({ infos }) => {
  const loading = useSelector((state) => state.common.loading);

  console.log('jh 실제 데이터', infos);

  const infoArr = useMemo(() => {
    const convertArr = (data, keyNames) => {
      const result = [];

      const keys = Object.keys(keyNames);
      keys?.map((key, index) => {
        const item = {
          [keyNames[key]]: data?.[key],
        };

        result.push(item);
      });

      const combineTwoArr = (data) => {
        const pushArr = [];
        for (let i = 0; i < data.length; i += 2) {
          pushArr.push([data[i], data[i + 1]]);
        }
        return pushArr;
      };

      const comdinedResult = combineTwoArr(result);

      return comdinedResult;
    };

    const contract = convertArr(infos?.contractInfo, contractKeyNames);
    const subscription = convertArr(
      infos?.subscriptionInfo,
      subscriptionKeyNames
    );

    let targetHistoryKeys = [
      'subscribeYearMonth',
      'serviceTimes',
      'deliveryStatusDesc',
      'trackerDeliveryCompanyDesc',
      'trackingNo',
      'deliveryCategoryDesc',
      'changeTargetStatusDesc',
      'changeRequestComment',
      'changeApprovalComment',
      'deliveryCompleteDate',
      'promotionTypeDesc',
    ];
    const history = [];

    infos?.historyInfo &&
      infos?.historyInfo.map((historyArr) => {
        return targetHistoryKeys.map((key) => history.push(historyArr[key]));
      });

    // const history = [];
    // infos?.historyInfo?.map((historyArr) => {
    //   const historyItemArr = [];
    //   Object.values(historyArr)?.map((item) => {
    //     historyItemArr.push(item);
    //   });
    //   history.push(historyItemArr);
    // });

    return { contract, subscription, history };
  }, [infos, contractKeyNames, subscriptionKeyNames]);

  console.log('jh infoArr', infoArr);

  const renderComponent = useCallback((itemArr) => {
    return itemArr.map((items, index) => (
      <tr key={`item_${index}`}>
        {items.map((item, index) => {
          let key = '';
          let value = '';

          if (item === undefined) {
            key = '-';
            value = '-';
          } else {
            key = Object.keys(item)[0];
            value = Object.values(item)[0];
          }
          // const [key] = Object.keys(item);
          // const [value] = Object.values(item);

          return (
            <React.Fragment key={index}>
              <th>{key}</th>
              <td>{value}</td>
            </React.Fragment>
          );
        })}
      </tr>
    ));
  }, []);

  return (
    <>
      <h3 className={css.mainTitle}>{'계약 정보'}</h3>
      <div className={css.tableLayer}>
        <table>
          <colgroup>
            <col className={css.colHead} />
            <col className={css.colBody} />
            <col className={css.colHead} />
            <col className={css.colBody} />
          </colgroup>
          <tbody>{renderComponent(infoArr.contract)}</tbody>
        </table>
      </div>
      <h3 className={css.mainTitle}>{'당월 구독 정보'}</h3>
      <div className={css.tableLayer}>
        <table>
          <colgroup>
            <col className={css.colHead} />
            <col className={css.colBody} />
            <col className={css.colHead} />
            <col className={css.colBody} />
          </colgroup>
          <tbody>{renderComponent(infoArr.subscription)}</tbody>
        </table>
      </div>

      {infoArr?.history && infoArr?.history.length > 0 && (
        <>
          <h3 className={css.mainTitle}>{'History'}</h3>
          <div className={css.tableLayerColumn}>
            <table>
              <thead>
                <tr>
                  {Object.values(historyKeyNames).map((item, index) => (
                    <th key={`historyKeyName_${index}`}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {infoArr?.history.map((historyArr, idx) => (
                  <tr key={`historyData_${idx}`}>
                    {historyArr?.map((item, itemIdx) => (
                      <td key={`historyData_${idx}_item_${itemIdx}`}>{item}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <PopUp isOpenPopUp={loading} closeButton={false}>
        <Loading />
      </PopUp>
    </>
  );
};

export default DeliveryInfoDetail;
