import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import css from './RefundAlarmTabSecond.module.scss';
import classNames from 'classnames/bind';
import { FormProvider, useForm } from 'react-hook-form';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import DeliveryRegSearchArea from '../module/DeliveryRegSearchArea';
import { PopUpAlert } from '@components/index';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const messageTypeOptions = {
  guide: '',
  list: [
    { id: '전체', title: '전체' },
    { id: '알림톡', title: '알림톡' },
    { id: 'LMS', title: 'LMS' },
  ],
};

const tableMockData = [
  {
    No: 1,
    code: '/Code/',
    메시지유형: '메시지 유형',
    제목: '제목(관리자용)',
    문구내용: '첫번쨰 문구 내용입니다. 문구 내용입니다.문구 내용입니다.',
    상태: '상태',
  },
  {
    No: 2,
    code: '/Code/',
    메시지유형: '메시지 유형',
    제목: '제목(관리자용)',
    문구내용: '문구 내용입니다. 문구 내용입니다.문구 내용입니다.',
    상태: '상태',
  },
];

const RefundAlarmTabSecond = () => {
  const searchForms = useForm();
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [content, setContent] = useState(null);

  const alarmDetailSearchRef = useRef({
    getSelectBoxWatch: () => {},
    getRadioAllOrFailWatch: () => {},
  });

  const onClickContent = useCallback((content) => {
    setContent(content);
    setIsOpenPopUp(!isOpenPopUp);
  }, []);

  // Todo : 데이터 filter연결 (onSearchClick구현)
  // const onSearchClick = useCallback(
  //   (data) => {
  //     const {
  //       statusSearchCondition,
  //       keywordSearchCondition,
  //       keyword,
  //     } = data;

  //     const filteredList = sortedVendorUserList.filter((vendor) => {
  //       const { joinDate, approvalStatusAccount } = vendor;

  //       return (
  //         matchesCondition({
  //           targetCondition: approvalStatusAccount,
  //           searchCondition: statusSearchCondition,
  //         }) &&
  //         hasSearchText({
  //           targetValue: vendor[keywordSearchCondition],
  //           searchText: keyword,
  //         })
  //       );
  //     });

  //     setFiltered(filteredList);
  //   },
  //   [dispatch]
  // );

  const columns = useMemo(
    () => [
      columnHelper.accessor('No', {
        id: 'No',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>No</span>,
        size: 1,
      }),
      columnHelper.accessor('code', {
        id: 'code',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>code</span>,
        size: 1,
      }),
      columnHelper.accessor('메시지유형', {
        id: '메시지유형',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>메시지유형</span>,
        size: 1,
      }),
      columnHelper.accessor('제목', {
        id: '제목',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제목(관리자용)</span>,
      }),
      columnHelper.accessor('문구내용', {
        id: '문구내용',
        cell: (info) => (
          <span
            className={cn('pointer')}
            onClick={() => onClickContent(info.getValue())}
          >
            {info.getValue()}
          </span>
        ),
        header: () => <span>문구내용</span>,
      }),
      columnHelper.accessor('상태', {
        id: '상태',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상태</span>,
        size: 1,
      }),
    ],
    []
  );

  return (
    <>
      <div className={cn('statusDashBoard')}>
        <h3 className={cn('areaTitle', 'flex-10auto', 'mg0')}>
          배송 알림 등록 템플릿
        </h3>
        <div className={cn('status')}>
          <dl className={cn('flex', 'justify-content-between')}>
            <dt>카카오 알림 톡 등록 건수</dt>
            <dd>
              <div className={cn('value')}>
                <span className={cn('num')}>2</span>건
              </div>
            </dd>
          </dl>
          <dl className={cn('flex', 'justify-content-between')}>
            <dt>LMS 등록 건수</dt>
            <dd>
              <div className={cn('value')}>
                <span className={cn('num')}>2</span>건
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <FormProvider {...searchForms}>
        <DeliveryRegSearchArea
          messageTypeOptions={messageTypeOptions}
          ref={alarmDetailSearchRef}
        />
      </FormProvider>
      <TableV8ManualPage
        columns={columns}
        data={tableMockData}
        hasCsvDownload={false}
      />
      <PopUpAlert
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
        closeButton
      >
        <strong className={cn('popup_title')}>문구 내용</strong>
        <div className={cn('popup_body')}>
          <div className={cn('text_box1')}>
            <div className={cn('xmp')}>{content}</div>
          </div>
        </div>
      </PopUpAlert>
    </>
  );
};

export default RefundAlarmTabSecond;
