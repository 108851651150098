import { memo, useCallback, useEffect, useMemo } from 'react';
import css from './SelectUser.module.scss';
import classNames from 'classnames/bind';
import { Button, SelectBox } from '@components/index';
import ico_message from '@assets/img/ico_message.svg';
import { BASE_URL, USER_SUB_PATH, ROUTER_PATH } from '@router/routerPath';
import { useNavigate } from 'react-router-dom';
import { postLogout } from 'redux/action/loginAction';
import { useDispatch, useSelector } from 'react-redux';
import { getMessageCount } from 'redux/action/mailAction';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace SelectUser
 * @description DashboardLayout에서 사용하는 SelectUser 컴포넌트.
 */

const SelectUser = ({ userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalCount = useSelector((state) => state.mail.totalCount);

  const countParams = useMemo(() => {
    const result = {
      userId: userId,
      delFlag: 'N',
    };
    return result;
  }, [userId]);

  const handleNavigate = useCallback((path, state) => {
    navigate(path, state);
  }, []);

  useEffect(() => {
    getMessageCount(dispatch, countParams);
  }, [countParams]);

  const DropMenu = [
    {
      id: '내 정보',
      title: '내 정보',
      fn: () => handleNavigate(BASE_URL + USER_SUB_PATH.MYINFO),
    },
    {
      id: '로그아웃',
      title: '로그아웃',
      fn: () => doLogout(),
    },
  ];

  const doLogout = () => {
    postLogout(handleNavigate);
    handleNavigate(ROUTER_PATH.ROOT);
  };

  const onGoToInbox = () => {
    navigate(ROUTER_PATH.MAIL);
  };

  return (
    <div className={css.select_user}>
      <button className={css.messageBox} onClick={onGoToInbox}>
        <span className={css.num}>{totalCount?.unreadCnt ?? 0}+</span>
      </button>
      {/* TODO:  정산관리에서 세금계산서  api 연동후 반영 예정
      <div className={css.adjustment_notice}>
        <span>정산 마감 NN일 전</span>
      </div> */}
      <SelectBox
        optionList={DropMenu}
        type={'type02'}
        currentLabel={userId}
      ></SelectBox>
    </div>
  );
};

SelectUser.defaultProps = {};

export default memo(SelectUser);
