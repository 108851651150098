import React from 'react';
import classNames from 'classnames/bind';
import css from './RefundCustomerSearchArea.module.scss';
import { useFormContext } from 'react-hook-form';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import { Button } from '@components/index';

const cn = classNames.bind(css);

const RefundCustomerSearchArea = ({ onSearchClick }) => {
  const { control, handleSubmit } = useFormContext();
  return (
    <div>
      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={cn('flex')}>
          <div className={cn('innerBox')}>
            <strong className={cn('title')}>기간</strong>
            <HFCalendar
              name={'fromDate'}
              defaultValue={new Date('2023-08-01')}
              control={control}
            />
            <span className={cn('dash')}>-</span>
            <HFCalendar name={'toDate'} control={control} />
          </div>
          <div className={cn('innerBox')}>
            <div>
              <Button
                styleType={'btn01'}
                subType={'type03'}
                className={cn('btn')}
                onClick={handleSubmit(onSearchClick)}
              >
                검색
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundCustomerSearchArea;
