import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BASE_URL, DELIVERY_SUB_PATH } from '@router/routerPath';
import css from './DeliveryOrder.module.scss';
import classNames from 'classnames/bind';
import DeliverySearchArea from './modules/DeliverySearchArea';
import { FormProvider, useForm } from 'react-hook-form';

import img_flow_product_check from '@assets/img/img_flow_shipping.svg';
import {
  Button,
  PopUp,
  VerticalTableContainer,
  VerticalTable,
} from '@components';
import { useSelector, useDispatch } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import TableCheckbox from '@components/Table/TableCheckbox';
import StatusDashBoard from './modules/StatusDashBoard';
import PortalPopup, { SIZES } from 'template/PortalPopup/PortalPopup';
import ContractPopup from './ContractPopup';
import { dummyCallData } from './Constants';

import {
  getRetrieveDeliveryStatusDashboard,
  getRetrieveDeliveryStatusListAPI,
  getDeliveryInfoDetailsAPI,
  postUpdateDeliveryStatus,
  postUpdateDeliveryStatusReverse,
  postUpdateDeliveryTrackingInfo,
  postUpdateDeliveryTrackingStatus,
  postUpdateDeliveryStatusResend,
  postUpdateDeliveryStatusDispose,
  postDoApprovalDeliveryStatusChangeRequest,
  getRetrieveDeliveryCompanyList,
} from 'redux/action/deliveryAction';
import { convertDateToString2, isAllElementSame } from '@utils';
import { openModalAlert } from '@feature/common/commonSlice';
import HFInput from '@components/_ReactHookForm/HFInput';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import DeliveryInfoDetail from './DeliveryInfoDetail';
import Loading from 'template/Loading/Loading';

const cn = classNames.bind(css);
const today = new Date();
const lastMonth = new Date(today);

const dummyData = [
  {
    deliveryDelayedDays: '',
    changeTargetStatus: null,
    csmsPromotionType: 'FOC',
    trackerDeliveryStatus: null,
    changeApprovalDatetime: null,
    serviceTimes: '1',
    changeApprovalComment: null,
    deliveryKey: null,
    deliveryStatusDesc: '신규주문',
    changeRequestDatetime: null,
    csmsPromotionTypeDesc: '무상',
    paymentCmplDate: '2023-12-19',
    receiverPostCode: '04637',
    changeApprovalUserId: null,
    promotionTypeDesc: '무상',
    merchandiseDesc: '세탁기 세탁세제',
    csmsSendStatusResultFlag: '',
    deliveryDelayedFlag: '',
    receiverAddress1: '서울 중구 후암로 98 (남대문로5가)',
    csmsD003Datetime: null,
    statusChangeAvailableFlag: 'Y',
    receiverAddress2: '5층',
    promotionType: 'FOC',
    csmsD002Datetime: null,
    csmsD004Datetime: null,
    trackerDeliveryCompanyDesc: null,
    receiverName: '현지선',
    trackerDeliveryCompany: null,
    csmsD005Datetime: null,
    changeApprovalStatus: null,
    receiverMobileNo: '01035977415',
    vendorName: 'LG생활건강',
    csmsD006Datetime: null,
    trackerDeliveryStatusDesc: null,
    merchandiseId: 'ADA0001',
    vendorKey: 'VENDOR.00002',
    changeRequestUserId: null,
    trackingNo: null,
    deliveryPlanNo: '100032940',
    contractLineNo: '20229476',
    promotionService: '무상 1회차',
    vendorContactNo: '0000-0000',
    changeRequestComment: null,
    deliveryStatus: 'D001',
  },
  {
    deliveryDelayedDays: '',
    changeTargetStatus: null,
    trackerDeliveryStatus: null,
    changeApprovalDatetime: null,
    serviceTimes: '2',
    changeApprovalComment: null,
    deliveryKey: null,
    deliveryStatusDesc: '신규주문',
    changeRequestDatetime: null,
    paymentCmplDate: '2023-09-01',
    receiverPostCode: null,
    changeApprovalUserId: null,
    promotionTypeDesc: '무상',
    merchandiseDesc: '건조기 건조시트',
    csmsSendStatusResultFlag: '',
    deliveryDelayedFlag: '',
    receiverAddress1: null,
    csmsD003Datetime: null,
    statusChangeAvailableFlag: 'Y',
    receiverAddress2: null,
    promotionType: 'FOC',
    csmsD002Datetime: null,
    csmsD004Datetime: null,
    trackerDeliveryCompanyDesc: null,
    receiverName: null,
    trackerDeliveryCompany: null,
    csmsD005Datetime: null,
    changeApprovalStatus: null,
    receiverMobileNo: null,
    vendorName: 'LG생활건강',
    csmsD006Datetime: null,
    trackerDeliveryStatusDesc: null,
    merchandiseId: 'ADA0003',
    vendorKey: 'VENDOR.00002',
    changeRequestUserId: null,
    trackingNo: 'dummy0000',
    deliveryPlanNo: '104851317',
    contractLineNo: '18662181',
    changeRequestComment: null,
    deliveryStatus: 'D002',
  },
  {
    deliveryDelayedDays: '',
    changeTargetStatus: null,
    trackerDeliveryStatus: null,
    changeApprovalDatetime: null,
    serviceTimes: '2',
    changeApprovalComment: null,
    deliveryKey: 'DELIVERY.1707349861000000000',
    deliveryStatusDesc: '신규주문',
    changeRequestDatetime: null,
    paymentCmplDate: '2023-09-01',
    receiverPostCode: null,
    changeApprovalUserId: null,
    promotionTypeDesc: '무상',
    merchandiseDesc: '건조기 건조시트',
    csmsSendStatusResultFlag: '',
    deliveryDelayedFlag: '',
    receiverAddress1: null,
    csmsD003Datetime: null,
    statusChangeAvailableFlag: 'Y',
    receiverAddress2: null,
    promotionType: 'FOC',
    csmsD002Datetime: null,
    csmsD004Datetime: null,
    trackerDeliveryCompanyDesc: null,
    receiverName: null,
    trackerDeliveryCompany: null,
    csmsD005Datetime: null,
    changeApprovalStatus: null,
    receiverMobileNo: null,
    vendorName: 'LG생활건강',
    csmsD006Datetime: null,
    trackerDeliveryStatusDesc: null,
    merchandiseId: 'ADA0003',
    vendorKey: 'VENDOR.00002',
    changeRequestUserId: null,
    trackingNo: null,
    deliveryPlanNo: '104851317',
    contractLineNo: '18662182',
    changeRequestComment: null,
    deliveryStatus: 'D003',
  },
  {
    deliveryDelayedDays: '',
    changeTargetStatus: null,
    trackerDeliveryStatus: null,
    changeApprovalDatetime: null,
    serviceTimes: '2',
    changeApprovalComment: null,
    deliveryKey: null,
    deliveryStatusDesc: '신규주문',
    changeRequestDatetime: null,
    paymentCmplDate: '2023-09-01',
    receiverPostCode: null,
    changeApprovalUserId: null,
    promotionTypeDesc: '무상',
    merchandiseDesc: '건조기 건조시트',
    csmsSendStatusResultFlag: '',
    deliveryDelayedFlag: '',
    receiverAddress1: null,
    csmsD003Datetime: null,
    statusChangeAvailableFlag: 'Y',
    receiverAddress2: null,
    promotionType: 'FOC',
    csmsD002Datetime: null,
    csmsD004Datetime: null,
    trackerDeliveryCompanyDesc: null,
    receiverName: null,
    trackerDeliveryCompany: null,
    csmsD005Datetime: null,
    changeApprovalStatus: null,
    receiverMobileNo: null,
    vendorName: 'LG생활건강',
    csmsD006Datetime: null,
    trackerDeliveryStatusDesc: null,
    merchandiseId: 'ADA0003',
    vendorKey: 'VENDOR.00002',
    changeRequestUserId: null,
    trackingNo: null,
    deliveryPlanNo: '104851317',
    contractLineNo: '18662180',
    changeRequestComment: null,
    deliveryStatus: 'D004',
  },
  {
    deliveryDelayedDays: '',
    changeTargetStatus: null,
    trackerDeliveryStatus: null,
    changeApprovalDatetime: null,
    serviceTimes: '2',
    changeApprovalComment: null,
    deliveryKey: null,
    deliveryStatusDesc: '신규주문',
    changeRequestDatetime: null,
    paymentCmplDate: '2023-09-01',
    receiverPostCode: null,
    changeApprovalUserId: null,
    promotionTypeDesc: '무상',
    merchandiseDesc: '건조기 건조시트',
    csmsSendStatusResultFlag: '',
    deliveryDelayedFlag: '',
    receiverAddress1: null,
    csmsD003Datetime: null,
    statusChangeAvailableFlag: 'Y',
    receiverAddress2: null,
    promotionType: 'FOC',
    csmsD002Datetime: null,
    csmsD004Datetime: null,
    trackerDeliveryCompanyDesc: null,
    receiverName: null,
    trackerDeliveryCompany: null,
    csmsD005Datetime: null,
    changeApprovalStatus: null,
    receiverMobileNo: null,
    vendorName: 'LG생활건강',
    csmsD006Datetime: null,
    trackerDeliveryStatusDesc: null,
    merchandiseId: 'ADA0003',
    vendorKey: 'VENDOR.00002',
    changeRequestUserId: null,
    trackingNo: null,
    deliveryPlanNo: '104851317',
    contractLineNo: '18662180',
    changeRequestComment: null,
    deliveryStatus: 'D005',
  },
  {
    deliveryDelayedDays: '',
    changeTargetStatus: null,
    trackerDeliveryStatus: null,
    changeApprovalDatetime: null,
    serviceTimes: '2',
    changeApprovalComment: null,
    deliveryKey: null,
    deliveryStatusDesc: '신규주문',
    changeRequestDatetime: null,
    paymentCmplDate: '2023-09-01',
    receiverPostCode: null,
    changeApprovalUserId: null,
    promotionTypeDesc: '무상',
    merchandiseDesc: '건조기 건조시트',
    csmsSendStatusResultFlag: '',
    deliveryDelayedFlag: '',
    receiverAddress1: null,
    csmsD003Datetime: null,
    statusChangeAvailableFlag: 'Y',
    receiverAddress2: null,
    promotionType: 'FOC',
    csmsD002Datetime: null,
    csmsD004Datetime: null,
    trackerDeliveryCompanyDesc: null,
    receiverName: null,
    trackerDeliveryCompany: null,
    csmsD005Datetime: null,
    changeApprovalStatus: null,
    receiverMobileNo: null,
    vendorName: 'LG생활건강',
    csmsD006Datetime: null,
    trackerDeliveryStatusDesc: null,
    merchandiseId: 'ADA0003',
    vendorKey: 'VENDOR.00002',
    changeRequestUserId: null,
    trackingNo: null,
    deliveryPlanNo: '104851317',
    contractLineNo: '18662180',
    changeRequestComment: null,
    deliveryStatus: 'D006',
  },
];

const deliveryStatusOptions = {
  guide: '',
  list: [
    { id: '', title: '전체' },
    { id: 'D001', title: '신규주문' },
    { id: 'D002', title: '상품 준비중' },
    { id: 'D003', title: '상품 준비 완료' },
    { id: 'D004', title: '배송중' },
    { id: 'D005', title: '배송 완료 확정' },
    { id: 'D006', title: '배송 지연' },
  ],
};

const deliveryCategoryOptions = {
  guide: '',
  list: [
    { id: '', title: '전체' },
    { id: 'CAT01', title: '무상주문' },
    { id: 'CAT02', title: '유상주문' },
    { id: 'CAT03', title: '신규계약' },
  ],
};

const DeliveryOrder = () => {
  const userId = useSelector((state) => state.user.userData.userId);
  const userVendorKey = useSelector((state) => state.user.userData.vendorKey);
  const myAuth = useSelector((state) => state.user.userData.authority);
  const loading = useSelector((state) => state.common.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deliveryStatusList = useSelector(
    (state) => state.delivery?.deliveryStatusList?.list
  );

  const deliveryStatusListTotalCount = useSelector(
    (state) => state.delivery?.deliveryStatusList?.additionalInfo?.totalCount
  );

  const deliveryStatusDashboard = useSelector(
    (state) => state.delivery.deliveryStatusDashboard
  );
  const deliveryCompanyList = useSelector(
    (state) => state.delivery.deliveryCompanyList
  );

  useEffect(() => {
    getRetrieveDeliveryCompanyList(dispatch);
  }, [dispatch]);

  const deliveryInfoDetails = useSelector(
    (state) => state.delivery.deliveryInfoDetails
  );

  const deliveryCompanyListOptions = useMemo(() => {
    let options = {
      guide: '택배사명을 선택해주세요.',
      list: [],
    };

    if (deliveryCompanyList.length > 0) {
      options.list = [
        ...deliveryCompanyList.map((item) => ({
          id: item.companyCode,
          title: item.companyDesc,
        })),
      ];
    }

    return options;
  }, [deliveryCompanyList]);

  const [contractId, setContractId] = useState(null);

  const searchForms = useForm();

  const {
    register: registerTrackingNo,
    handleSubmit: handleSubmitTrackingNo,
    control,
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  const {
    register: registerVendor,
    reset: resetVendor,
    handleSubmit: handleSubmitVendor,
  } = useForm({
    defaultValues: {
      fromDeliveryStatus: '',
      toDeliveryStatus: '',
      fromDeliveryStatusCode: '',
      toDeliveryStatusCode: '',
      changeReason: '버튼 선택 오류로 인한 변경 요청',
      changeRequestComment: '',
    },
    mode: 'onChange',
  });

  const {
    register: registerLg,
    reset: resetLg,
    handleSubmit: handleSubmitLg,
  } = useForm({
    defaultValues: {
      fromDeliveryStatus: '',
      toDeliveryStatus: '',
      fromDeliveryStatusCode: '',
      toDeliveryStatusCode: '',
      changeReason: '버튼 선택 오류로 인한 변경 요청',
      changeRequestComment: '',
    },
    mode: 'onChange',
  });

  const deliveryStatusSelectedRef = useRef({
    getStatusSelectWatch: () => {},
    getFromDateWatch: () => {},
    getToDateWatch: () => {},
  });

  const deliveryStatusSelectedValue =
    deliveryStatusSelectedRef.current.getStatusSelectWatch();
  const fromDateValue = deliveryStatusSelectedRef.current.getFromDateWatch();
  const toDateValue = deliveryStatusSelectedRef.current.getToDateWatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const contractNumRef = useRef([]);
  const deliveryStatusRef = useRef([]);
  const deliveryStatusInfoRef = useRef({});

  const [selectedDeliveryTrackingNo, setSelectedDeliveryTrackingNo] =
    useState(0);
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState(0);

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const deliveryStatus = searchParams.get('deliveryStatus');
  const detailCondition = searchParams.get('detailCondition');
  const vendorKey = searchParams.get('vendorKey');
  const category = searchParams.get('category');
  const fromDate = searchParams.get('fromDate');
  const toDate = searchParams.get('toDate');

  const handleNavigate = useCallback((path) => {
    window.location.href = `${BASE_URL}/${path}`;
  }, []);

  const [isOpenPopup, setOpenPopup] = useState(false);
  const [isOpenSecondPopup, setOpenSecondPopup] = useState(false);
  const [isOpenChangeRequestComment, setIsOpenChangeRequestComment] =
    useState(false);
  const [isOpenChangeRequestCommentTitle, setIsOpenChangeRequestCommentTitle] =
    useState('');

  const [isOpenApprovalStatusChange, setIsOpenApprovalStatusChange] =
    useState(false);
  const [statusChangePopupInfo, setStatusChangePopupInfo] = useState({
    text: '',
    status: '',
  });

  const onClickOpenPopup = async (contractLineNo, deliveryPlanNo) => {
    setOpenPopup(true);
    setContractId(contractLineNo);

    const params = {
      contractLineNo: contractLineNo,
      deliveryPlanNo: deliveryPlanNo,
    };

    await dispatch(getDeliveryInfoDetailsAPI(params));
  };

  const columnHelper = createColumnHelper();

  // TODO: 실제 데이터 연동한 column
  const columnsData = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'delivery-table-status-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'delivery-table-status-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('no', {
        id: 'no',
        cell: ({ row: { index } }) => {
          return <>{deliveryStatusList && deliveryStatusList.length - index}</>;
        },
        header: () => <span>No</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => {
          const deliveryPlanNo = info?.row?.original?.deliveryPlanNo || '';
          return (
            <button
              className={css.color_primary}
              onClick={() => onClickOpenPopup(info.getValue(), deliveryPlanNo)}
            >
              {info.getValue()}
            </button>
          );
        },
        header: () => <span>계약 라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송 예정번호 </span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품 ID</span>,
      }),
      columnHelper.accessor('paymentCmplDate', {
        id: 'paymentCmplDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>결제일</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            구독
            <br />
            회차
          </span>
        ),
      }),
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품</span>,
      }),
      columnHelper.accessor('promotionTypeDesc', {
        id: 'promotionTypeDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('deliveryStatus', {
        id: 'deliveryStatus',
        header: () => <span>배송 상태</span>,
        cell: (info) => {
          switch (info.getValue()) {
            case 'D001':
              return <span>신규주문</span>;
            case 'D002':
              return <span>상품 준비중</span>;
            case 'D003':
              return <span>상품 준비완료</span>;
            case 'D004':
              return <span>배송중</span>;
            case 'D005':
              return <span>배송완료 확정</span>;
            case 'D006':
              return <span>배송폐기</span>;
            default:
              return <span>{info.getValue()}</span>;
          }
        },
      }),
      columnHelper.accessor('receiverName', {
        id: 'receiverName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>고객명</span>,
      }),

      columnHelper.accessor('receiverAddress1', {
        id: 'receiverAddress1',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>주소</span>,
      }),

      columnHelper.accessor('receiverMobileNo', {
        id: 'receiverMobileNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>전화번호</span>,
      }),
      columnHelper.accessor('trackerDeliveryStatusDesc', {
        id: 'trackerDeliveryStatusDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송 중 현황</span>,
      }),
      columnHelper.accessor('trackerDeliveryCompany', {
        id: 'trackerDeliveryCompany',
        cell: (info) => (
          <>
            {myAuth === 'LEVEL_3' ? (
              <HFSelectBox
                control={control}
                name={`deliveryCompanyListSelectBox-${info.row.id}`}
                options={deliveryCompanyListOptions}
                type={'type04'}
                disabled={
                  !info?.row?.getIsSelected() ||
                  info?.row?.original?.deliveryStatus !== 'D003'
                }
                hasScroll
                required
              />
            ) : (
              <>{info?.row?.original?.trackerDeliveryCompanyDesc}</>
            )}
          </>
        ),
        header: () => <span>택배사</span>,
      }),
      columnHelper.accessor('trackingNo', {
        id: 'trackingNo',
        cell: (info) => {
          return (
            <>
              {info.getValue() ? (
                <span>{info.getValue()}</span>
              ) : (
                <div className={cn('flex', 'align-center-gap8')}>
                  <HFInput
                    className={'type02'}
                    type={'text'}
                    name={`trackingNo-${info.row.id}`}
                    register={registerTrackingNo}
                    placeholder={'운송장번호 입력'}
                    disabled={
                      !info?.row?.getIsSelected() ||
                      info?.row?.original?.deliveryStatus !== 'D003'
                    }
                    required
                  />

                  <Button
                    styleType={'btn03'}
                    subType={'type01'}
                    onClick={handleSubmitTrackingNo(
                      handleUpdateDeliveryTrackingInfo,
                      onFailTrackingInfo
                    )}
                    disabled={
                      !info?.row?.getIsSelected() ||
                      info?.row?.original?.deliveryStatus !== 'D003'
                    }
                  >
                    수정
                  </Button>
                </div>
              )}
            </>
          );
        },
        header: () => <span>송장번호</span>,
      }),
      columnHelper.accessor('deliveryDelayedFlag', {
        id: 'deliveryDelayedFlag',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            배송
            <br />
            지연
          </span>
        ),
      }),
      columnHelper.accessor('deliveryDelayedDays', {
        id: 'deliveryDelayedDays',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            배송
            <br />
            일수
          </span>
        ),
      }),
      columnHelper.accessor('changeTargetStatus', {
        id: 'changeTargetStatus',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송 상태 변경 요청</span>,
      }),
      columnHelper.accessor('changeApprovalStatus', {
        id: 'changeApprovalStatus',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송 관리 요청 상태</span>,
      }),
    ],
    [deliveryStatusList, deliveryCompanyListOptions]
  );

  useEffect(() => {
    const portalPopupDiv = document.createElement('div');
    portalPopupDiv.id = 'portal-popup';
    document.body.appendChild(portalPopupDiv);

    const portalSecondPopupDiv = document.createElement('div');
    portalSecondPopupDiv.id = 'portal-popup-1';
    document.body.appendChild(portalSecondPopupDiv);

    return () => {
      document.body.removeChild(portalPopupDiv);
      document.body.removeChild(portalSecondPopupDiv);
    };
  }, []);

  // 첫 진입 시 배송 현황 / 대시보드
  useEffect(() => {
    const dashboardParams = {
      fromDate: '2023-07-01',
      toDate: convertDateToString2(new Date()),
      venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
      deliveryStatus: deliveryStatus ?? '',
      detailCondition: detailCondition ?? '',
      category: category ?? '',
    };
    getRetrieveDeliveryStatusDashboard(dispatch, dashboardParams);
  }, []);

  // TODO: 첫 진입 시 테이블 데이터 호출
  useEffect(() => {
    const fetchDeliveryStatusList = async () => {
      const params = {
        deliveryStatus: deliveryStatus ?? '',
        fromDate: convertDateToString2(new Date()),
        toDate: convertDateToString2(new Date()),
        venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
        detailCondition: detailCondition ?? '',
        category: category ?? '',
        limit: limit ?? '100',
        page: page ?? '1',
      };
      // TODO: API 실제 호출
      await dispatch(getRetrieveDeliveryStatusListAPI(params));
    };
    fetchDeliveryStatusList();
  }, []);

  // 배송 현황 / 대시보드 건 수 클릭 이벤트
  const handleSearchParams = useCallback(
    async (param) => {
      for (let key in param) {
        searchParams.set(key, param[key]);
      }

      const tempParams = {
        fromDate: fromDateValue && convertDateToString2(fromDateValue),
        toDate: toDateValue && convertDateToString2(toDateValue),
        venderKey: param.venderKey ?? '',
        deliveryStatus: param.deliveryStatus ?? '',
        detailCondition: param.detailCondition ?? '',
        category: param.category ?? '',
        limit: limit ?? '100',
        page: page ?? '1',
      };

      setSearchParams(searchParams);

      // TODO: API 실제 호출
      await dispatch(getRetrieveDeliveryStatusListAPI(tempParams));
    },
    [fromDateValue, toDateValue, limit, page]
  );

  // TODO: 검색 버튼 클릭 시, 대시보드 & 리스트 API 동시 호출?
  const onSearchClick = useCallback(
    async (data) => {
      const {
        deliveryStatusCondition,
        deliveryCategoryCondition,
        keyword,
        toDate,
        fromDate,
      } = data;

      const keyArray = [];
      for (let key of searchParams.keys()) {
        keyArray.push(key);
      }
      keyArray.forEach((key) => {
        searchParams.delete(key);
      });

      setSearchParams(searchParams);

      // const params = {
      //   fromDate: convertDateToString2(fromDate),
      //   toDate: convertDateToString2(toDate),
      //   venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
      //   deliveryStatus: deliveryStatusCondition,
      //   category: deliveryCategoryCondition,
      //   detailCondition: keyword,
      // };
      // getRetrieveDeliveryStatusDashboard(dispatch, params);

      const tableParams = {
        deliveryStatus: deliveryStatusCondition,
        fromDate: convertDateToString2(fromDate),
        toDate: convertDateToString2(toDate),
        venderKey: myAuth === 'LEVEL_3' ? userVendorKey : vendorKey,
        detailCondition: keyword,
        category: deliveryCategoryCondition,
        limit: limit ?? '100',
        page: page ?? '1',
      };
      // TODO: API 실제 호출
      await dispatch(getRetrieveDeliveryStatusListAPI(tableParams));
    },
    [vendorKey, limit, page]
  );

  // 주문배송처리 / 6. 배송상태 변경 건 API 호출 위한 상태값 변경 (LG 내부망)
  const handleApprovalDeliveryStatusChangeRequest = useCallback(
    (selectedBtnStatusCode) => {
      setIsOpenChangeRequestComment(!isOpenChangeRequestComment);

      let toDeliveryStatusCode = '';
      let selectedBtnStatus = '';
      let toDeliveryStatus = '';

      switch (selectedBtnStatusCode) {
        case 'D004':
          toDeliveryStatusCode = 'D005';
          selectedBtnStatus = '배송중';
          toDeliveryStatus = '배송 완료 확정';
          setIsOpenChangeRequestCommentTitle('배송 완료 확정');
          break;
        case 'D005':
          toDeliveryStatusCode = 'D002';
          selectedBtnStatus = '배송중';
          toDeliveryStatus = '상품 준비중';
          setIsOpenChangeRequestCommentTitle('상품 준비중');
          break;
        case 'D006':
          toDeliveryStatusCode = 'D006';
          selectedBtnStatus = '배송폐기';
          toDeliveryStatus = '환불 요청';
          setIsOpenChangeRequestCommentTitle('환불 요청');
          break;
        default:
          toDeliveryStatusCode = '';
          selectedBtnStatus = '';
          toDeliveryStatus = '';
          setIsOpenChangeRequestCommentTitle('');
      }

      resetLg({
        fromDeliveryStatus: selectedBtnStatus,
        toDeliveryStatus: toDeliveryStatus,
        toDeliveryStatusCode: toDeliveryStatusCode,
        changeReason: '버튼 선택 오류로 인한 변경 요청',
        changeRequestComment: '',
      });
    },
    [resetLg, isOpenChangeRequestComment]
  );

  // 주문배송처리 / 6. 배송상태 변경 건 승인/반려 팝업 열기 (LG 내부망)
  const handleOpenApprovalStatusChangePopUp = useCallback(
    (type) => {
      switch (type) {
        case 'reject':
          setStatusChangePopupInfo((prevInfo) => ({
            ...prevInfo,
            text: '반려',
            status: 'REJECTED',
          }));
          setIsOpenApprovalStatusChange(true);
          break;
        case 'approval':
          setStatusChangePopupInfo((prevInfo) => ({
            ...prevInfo,
            text: '승인',
            status: 'APPROVED',
          }));
          setIsOpenApprovalStatusChange(true);
          break;
        default:
          break;
      }
    },
    [setStatusChangePopupInfo, setIsOpenApprovalStatusChange]
  );

  // 주문배송처리 / 6. 배송상태 변경 건 최종 API 호출 (LG 내부망)
  const onApprovalUpdateStatusChange = useCallback(
    ({ data, status }) => {
      const { toDeliveryStatusCode, changeRequestComment } = data;
      if (status === 'APPROVED') {
        switch (toDeliveryStatusCode) {
          case 'D002':
            const resendParams = {
              deliveryPlanNo: contractNumRef.current[0],
              changeRequestCommnet: changeRequestComment,
              userId: userId,
            };
            postUpdateDeliveryStatusResend(dispatch, resendParams);
            setIsOpenApprovalStatusChange(false);
          case 'D006':
            const disposeParams = {
              deliveryPlanNo: contractNumRef.current[0],
              changeRequestCommnet: changeRequestComment,
              userId: userId,
            };
            postUpdateDeliveryStatusDispose(dispatch, disposeParams);
            setIsOpenApprovalStatusChange(false);
        }
      } else if (status === 'REJECTED') {
        setIsOpenApprovalStatusChange(false);
        dispatch(openModalAlert({ message: '요청 처리 되지 않았습니다.' }));
      }

      if (toDeliveryStatusCode === 'D005') {
        const changeRequestParams = {
          deliveryPlanNo: contractNumRef.current[0],
          changeApprovalStatus: status,
          changeApprovalCommnet: changeRequestComment,
          userId: userId,
        };

        postDoApprovalDeliveryStatusChangeRequest(
          dispatch,
          changeRequestParams
        );

        setIsOpenApprovalStatusChange(false);
      }
    },
    [
      contractNumRef,
      userId,
      postUpdateDeliveryStatusResend,
      postUpdateDeliveryStatusDispose,
      postDoApprovalDeliveryStatusChangeRequest,
    ]
  );

  // 주문배송처리 / 6. 배송상태 변경 건 최종 API 호출 handleSubmit 예외 처리 로직 (LG 내부망)
  const onFailUpdateStatusChange = useCallback((data) => {
    const { changeRequestComment } = data;
    if (changeRequestComment.type === 'required') {
      setIsOpenApprovalStatusChange(false);
      dispatch(openModalAlert({ message: '코멘트를 입력 해주세요.' }));
    }
  }, []);

  // 주문배송처리 / status 변경 역방향 API 호출 위한 팝업 여는 함수
  const handleOpenChangeRequest = useCallback(
    (selectedBtnStatusCode) => {
      setIsOpenChangeRequestComment(!isOpenChangeRequestComment);

      let toDeliveryStatusCode = '';
      let selectedBtnStatus = '';
      let toDeliveryStatus = '';

      switch (selectedBtnStatusCode) {
        case 'D002':
          toDeliveryStatusCode = 'D001';
          selectedBtnStatus = '상품 준비중';
          toDeliveryStatus = '신규주문';
          setIsOpenChangeRequestCommentTitle('신규주문');
          break;
        case 'D003':
          toDeliveryStatusCode = 'D002';
          selectedBtnStatus = '상품 준비완료';
          toDeliveryStatus = '상품 준비중';
          setIsOpenChangeRequestCommentTitle('상품 준비중');
          break;
        case 'D004':
          toDeliveryStatusCode = 'D003';
          selectedBtnStatus = '배송중';
          toDeliveryStatus = '상품 준비완료';
          setIsOpenChangeRequestCommentTitle('상품 준비완료');
          break;
        default:
          break;
      }

      resetVendor({
        fromDeliveryStatus: selectedBtnStatus,
        toDeliveryStatus: toDeliveryStatus,
        fromDeliveryStatusCode: selectedBtnStatusCode,
        toDeliveryStatusCode: toDeliveryStatusCode,
        changeReason: '버튼 선택 오류로 인한 변경 요청',
        changeRequestComment: '',
      });
    },
    [resetVendor, isOpenChangeRequestComment]
  );

  // 주문배송처리 / status 변경 역방향
  const onChangeDeliveryStatusReverseCallback = useCallback(
    (data) => {
      const {
        changeRequestComment,
        fromDeliveryStatusCode,
        toDeliveryStatusCode,
      } = data;

      const deliveryReverseParams = {
        updateTargetList: JSON.stringify(contractNumRef.current),
        fromDeliveryStatus: fromDeliveryStatusCode,
        toDeliveryStatus: toDeliveryStatusCode,
        changeRequestComment: changeRequestComment,
        userId: userId,
      };

      postUpdateDeliveryStatusReverse(
        dispatch,
        deliveryReverseParams,
        setIsOpenChangeRequestComment
      );
    },
    [contractNumRef, userId]
  );

  // 주문배송처리 / status 변경 역방향 handleSubmit 예외 처리 로직
  const onFailDeliveryStatusReverseCallback = useCallback((data) => {
    const { changeRequestComment } = data;
    if (changeRequestComment.type === 'required') {
      dispatch(openModalAlert({ message: '코멘트를 입력 해주세요.' }));
    }
  }, []);

  // 주문배송처리 / status 변경 정방향
  const onChangeDeliveryStatusCallback = useCallback(
    (selectedBtnStatusCode) => {
      let toDeliveryStatus = '';

      switch (selectedBtnStatusCode) {
        case 'D001':
          dispatch(
            openModalAlert({
              content: (
                <>
                  배송 상태가{' '}
                  <span className={cn('color_primary')}>상품 준비완료</span>로
                  변경됩니다.
                </>
              ),
            })
          );
          toDeliveryStatus = 'D002';
          break;
        case 'D002':
          dispatch(
            openModalAlert({
              content: (
                <>
                  배송 상태가{' '}
                  <span className={cn('color_primary')}>배송중</span>로
                  변경됩니다.
                </>
              ),
            })
          );
          dispatch(
            openModalAlert({
              content: (
                <>
                  배송 상태가{' '}
                  <span className={cn('color_primary')}>배송중</span>으로
                  변경됩니다.
                </>
              ),
            })
          );
          toDeliveryStatus = 'D003';
          break;
        case 'D003':
          dispatch(
            openModalAlert({
              message: '운송장 번호를 입력 해주세요.',
            })
          );
          toDeliveryStatus = 'D004';
          break;
        case 'D004':
          dispatch(
            openModalAlert({
              message: '관리자가 승인 해주세요.',
            })
          );
          toDeliveryStatus = 'D005';
          break;
        case 'D005':
          dispatch(
            openModalAlert({
              content: (
                <>
                  배송 상태가{' '}
                  <span className={cn('color_primary')}>상품 준비 중</span>으로
                  변경됩니다.
                </>
              ),
            })
          );
          toDeliveryStatus = 'D002';
          break;
        default:
      }

      const deliveryParams = {
        updateTargetList: JSON.stringify(contractNumRef.current),
        fromDeliveryStatus: selectedBtnStatusCode,
        toDeliveryStatus: toDeliveryStatus,
        changeRequestComment: '',
        userId: userId,
      };

      postUpdateDeliveryStatus(dispatch, deliveryParams);
    },
    [userId]
  );

  // 주문배송 처리 / status 최종적으로 변경
  const handleChangeDeliveryStatus = useCallback(
    (selectedBtnStatusCode) => {
      const selectedDeliveryStatusEndNum = Number(
        deliveryStatusRef.current[0].split('')[3]
      );

      const selectedBtnStatusCodeEndNum = Number(
        selectedBtnStatusCode.split('')[3]
      );

      if (selectedDeliveryStatusEndNum <= selectedBtnStatusCodeEndNum) {
        onChangeDeliveryStatusCallback(selectedBtnStatusCode);
      } else handleOpenChangeRequest(selectedBtnStatusCode);
    },
    [deliveryStatusRef]
  );

  // 주문배송처리 / 3. 운송장정보 업데이트
  const handleUpdateDeliveryTrackingInfo = useCallback(
    (data) => {
      let trackingNoValueArray = [];
      let trackerDeliveryCompanyValueArray = [];

      for (const key in data) {
        if (key.includes('trackingNo')) {
          trackingNoValueArray.push(data[key]);
        } else if (key.includes('deliveryCompanyListSelectBox')) {
          trackerDeliveryCompanyValueArray.push(data[key]);
        }
      }

      const trackingNo = trackingNoValueArray?.filter((item) => item);
      const trackerDeliveryCompany = trackerDeliveryCompanyValueArray?.filter(
        (item) => item
      );

      const paramArray = deliveryStatusInfoRef?.current.map((item) => {
        return {
          ...item,
          trackerDeliveryCompany: trackerDeliveryCompany[0],
          trackingNo: trackingNo[0],
        };
      });

      if (trackingNo.length > 1) {
        dispatch(
          openModalAlert({ message: '운송장 번호는 한건 씩 수정 해주세요.' })
        );
      } else {
        const params = {
          updateTargetList: JSON.stringify(paramArray),
        };
        postUpdateDeliveryTrackingInfo(dispatch, params);
        dispatch(
          openModalAlert({ message: '송장번호 API 호출됨(예외 처리 나중에)' })
        );
      }
    },
    [deliveryStatusInfoRef, postUpdateDeliveryTrackingInfo]
  );

  // 주문배송처리 / 3. 운송장정보 업데이트 handleSubmit 예외 처리 로직
  const onFailTrackingInfo = useCallback((data) => {
    for (const key in data) {
      if (key.includes('trackingNo')) {
        data[key].type === 'required' &&
          dispatch(openModalAlert({ message: '운송장 번호를 입력 해주세요.' }));
      } else if (key.includes('deliveryCompanyListSelectBox')) {
        data[key].type === 'required' &&
          dispatch(openModalAlert({ message: '택배사를 선택해주세요.' }));
      }
    }
  }, []);

  // 주문배송처리 / 4. 배송현황 업데이트(스마트택배 API)
  const handleUpdateDeliveryTrackingStatus = useCallback(() => {
    if (selectedDeliveryTrackingNo > 0) {
      const params = {
        updateTargetList: JSON.stringify(contractNumRef.current),
      };
      postUpdateDeliveryTrackingStatus(dispatch, params);
      dispatch(
        openModalAlert({
          message:
            '배송현황 업데이트(스마트택배 API 호출 성공, 예외처리 나중에.)',
        })
      );
    } else dispatch(openModalAlert({ message: '입력값을 확인 해주세요' }));
  }, [selectedDeliveryTrackingNo]);

  const selectedRowCallback = useCallback((rows) => {
    let contractNumArr = [];
    let deliveryStatusArr = [];
    let deliveryStatusInfoObj = {};
    rows.forEach((row) => {
      return contractNumArr.push(row.deliveryPlanNo);
    });
    rows.forEach((row) => {
      return deliveryStatusArr.push(row.deliveryStatus);
    });
    contractNumRef.current = contractNumArr;
    deliveryStatusRef.current = deliveryStatusArr;

    deliveryStatusInfoRef.current = rows.map((row) => {
      deliveryStatusInfoObj = {
        deliveryKey: row.deliveryKey,
        trackerDeliveryCompany: row.trackerDeliveryCompany,
        trackingNo: row.trackingNo,
      };
      return deliveryStatusInfoObj;
    });

    setSelectedDeliveryStatus(deliveryStatusRef.current?.length);
    setSelectedDeliveryTrackingNo(
      deliveryStatusInfoRef.current?.filter((item) => item.trackingNo).length
    );
  }, []);

  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '100');
    setSearchParams(searchParams);
  }, []);

  const selectedDeliveryStatusEndNum = Number(
    deliveryStatusRef?.current[0]?.split('')[3]
  );

  const isAllElementSameCondition =
    isAllElementSame(deliveryStatusRef.current) &&
    deliveryStatusRef?.current?.length > 0;

  const vendorButtonList = useMemo(() => {
    const buttonListArr = [
      {
        buttonType: 'type01',
        deliveryStatus: 'D002',
        statusNum: 2,
        name: '재고확인',
      },
      {
        buttonType: 'type02',
        deliveryStatus: 'D003',
        statusNum: 3,
        name: '배송대기',
      },
      {
        buttonType: 'type03',
        deliveryStatus: 'D004',
        statusNum: 4,
        name: '운송장 번호 입력',
      },
      {
        buttonType: 'type04',
        deliveryStatus: 'D005',
        statusNum: 5,
        name: '배송 완료 승인 요청',
      },
      {
        buttonType: null,
        deliveryStatus: 'D006',
        statusNum: 6,
        name: '재 배송',
      },
    ];
    return buttonListArr;
  }, []);

  const buttonList = useMemo(() => {
    const buttonListArr = [
      {
        buttonType: 'type04',
        deliveryStatus: 'D004',
        statusNum: 4,
        name: '배송 완료 승인',
        fn: handleApprovalDeliveryStatusChangeRequest,
        notDisabled: false,
      },
      {
        buttonType: null,
        deliveryStatus: 'D005',
        statusNum: 4,
        name: '재 배송',
        fn: handleApprovalDeliveryStatusChangeRequest,
        notDisabled: false,
      },
      {
        buttonType: null,
        deliveryStatus: null,
        name: '배송 현황 조회',
        notDisabled: true,
      },
      {
        buttonType: null,
        deliveryStatus: 'D006',
        statusNum: 6,
        name: '배송폐기',
        fn: handleApprovalDeliveryStatusChangeRequest,
        notDisabled: false,
      },
    ];
    return buttonListArr;
  }, [handleApprovalDeliveryStatusChangeRequest]);

  return (
    <>
      <h2 className={cn('pageTitle')}>배송 현황</h2>
      <StatusDashBoard
        data={deliveryStatusDashboard}
        onClickSearchParams={handleSearchParams}
      />
      <FormProvider {...searchForms}>
        <DeliverySearchArea
          ref={deliveryStatusSelectedRef}
          deliveryStatusOptions={deliveryStatusOptions}
          deliveryCategoryOptions={deliveryCategoryOptions}
          onSearchClick={onSearchClick}
        />
      </FormProvider>
      <div className={cn('flowChart', 'mt15', 'mb30')}>
        <h3 className={cn('title')}>배송 처리 Flow</h3>
        <div className={cn('img')}>
          <img src={img_flow_product_check} alt='배송 처리 Flow 이미지' />
        </div>
      </div>
      {/* FIXME: 상세 팝업 테스트 버튼 */}
      {/* <Button onClick={() => onClickOpenPopup('18532259', '104522130')}>
        팝업 테스트
      </Button> */}
      {/* TODO: 테이블  */}
      <TableV8ManualPage
        data={deliveryStatusList}
        // data={dummyData}
        columns={columnsData}
        getRowselected={selectedRowCallback}
        handlePageCallback={handlePageCallback}
        page={page}
        hasPageSize={false}
        limit={limit ?? undefined}
        totalLength={deliveryStatusListTotalCount}
        hasCsvDownload={false}
        rightControls={
          myAuth === 'LEVEL_3' ? (
            <div className={cn('flex', 'align-center-gap10')}>
              {vendorButtonList.map((button) => {
                const elementsToCheck = [
                  selectedDeliveryStatusEndNum - 1,
                  selectedDeliveryStatusEndNum,
                  selectedDeliveryStatusEndNum + 1,
                ];

                return (
                  <Button
                    key={button.deliveryStatus}
                    styleType={'btn03'}
                    subType={'type01'}
                    deliveryStatus={button.buttonType}
                    onClick={() =>
                      handleChangeDeliveryStatus(button.deliveryStatus)
                    }
                    disabled={
                      !isAllElementSameCondition ||
                      !elementsToCheck.includes(button.statusNum)
                    }
                  >
                    {button.name}
                  </Button>
                );
              })}
              <Button
                styleType={'btn03'}
                subType={'type01'}
                onClick={handleUpdateDeliveryTrackingStatus}
              >
                배송 현황 조회
              </Button>
            </div>
          ) : (
            <div className={cn('flex', 'align-center-gap10')}>
              {buttonList.map((button) => {
                const elementsToCheck = [selectedDeliveryStatusEndNum];

                return (
                  <Button
                    key={button.deliveryStatus}
                    styleType={'btn03'}
                    subType={'type01'}
                    deliveryStatus={button.buttonType}
                    onClick={
                      button.deliveryStatus
                        ? () => button.fn(button.deliveryStatus)
                        : handleUpdateDeliveryTrackingStatus
                    }
                    disabled={
                      button.notDisabled
                        ? !button.notDisabled
                        : !isAllElementSameCondition ||
                          !elementsToCheck.includes(button.statusNum)
                    }
                  >
                    {button.name}
                  </Button>
                );
              })}
            </div>
          )
        }
      />
      {isOpenPopup && (
        <PortalPopup
          size={SIZES['1440x770']}
          setClose={setOpenPopup}
          disabled={isOpenSecondPopup}
          buttonContents={[
            {
              label: '리스트로 이동',
              onClick: () => handleNavigate(DELIVERY_SUB_PATH.RESULT),
            },
            { label: '확인', isDefault: true },
          ]}
          // children={<ContractPopup infos={infos} />}
          children={<DeliveryInfoDetail infos={deliveryInfoDetails} />}
        />
      )}
      {/* {isOpenSecondPopup && (
        <PortalPopup
          portalIndex={1}
          size={SIZES['660x330']}
          setClose={setOpenSecondPopup}
          buttonContents={[
            {
              label: '알람',
              onClick: () => window.alert('알람 버튼을 클릭했다!'),
            },
            { label: '확인', isDefault: true },
          ]}
          children={
            <div>
              {'두번째 팝업 창입니다.'}
              <br />
              {
                '내부 컨텐츠가 스크롤박스를 넘지 않으면 스크롤 바가 생성되지 않습니다.'
              }
            </div>
          }
        />
      )} */}

      {myAuth === 'LEVEL_3' ? (
        <PopUp
          title={`${isOpenChangeRequestCommentTitle} 승인 요청`}
          isOpenPopUp={isOpenChangeRequestComment}
          onRequestClose={() => setIsOpenChangeRequestComment(false)}
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='cancelComment'
                  subType={'type03'}
                  onClick={() => {
                    setIsOpenChangeRequestComment(false);
                  }}
                  fullWidth
                >
                  취소
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='confirmComment'
                  subType={'type02'}
                  onClick={handleSubmitVendor(
                    onChangeDeliveryStatusReverseCallback,
                    onFailDeliveryStatusReverseCallback
                  )}
                  fullWidth
                >
                  승인 요청
                </Button>
              </div>
            </div>,
          ]}
        >
          <div className={cn('popupWrap')}>
            <VerticalTableContainer>
              <VerticalTable title={'배송 상태'}>
                <HFInput
                  type={'text'}
                  name={'fromDeliveryStatus'}
                  register={registerVendor}
                  readOnly
                />
              </VerticalTable>
              <VerticalTable title={'배송 상태 변경'}>
                <HFInput
                  type={'text'}
                  name={'toDeliveryStatus'}
                  register={registerVendor}
                  readOnly
                />
              </VerticalTable>
              <VerticalTable title={'변경 사유'}>
                <HFInput
                  type={'text'}
                  name={'changeReason'}
                  register={registerVendor}
                />
              </VerticalTable>
              <VerticalTable title={'코멘트'} required>
                <HFInput
                  type={'text'}
                  name={'changeRequestComment'}
                  register={registerVendor}
                  required
                />
              </VerticalTable>
            </VerticalTableContainer>
          </div>
        </PopUp>
      ) : (
        <PopUp
          title={`${isOpenChangeRequestCommentTitle} 승인 요청`}
          isOpenPopUp={isOpenChangeRequestComment}
          onRequestClose={() => setIsOpenChangeRequestComment(false)}
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='rejectStatus'
                  subType={'type03'}
                  onClick={() => {
                    handleOpenApprovalStatusChangePopUp('reject');
                  }}
                  fullWidth
                >
                  반려
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='approvalStatus'
                  subType={'type02'}
                  onClick={() => {
                    handleOpenApprovalStatusChangePopUp('approval');
                  }}
                  fullWidth
                >
                  승인
                </Button>
              </div>
            </div>,
          ]}
        >
          <div className={cn('popupWrap')}>
            <VerticalTableContainer>
              <VerticalTable title={'배송 상태'}>
                <HFInput
                  type={'text'}
                  name={'fromDeliveryStatus'}
                  register={registerLg}
                  readOnly
                />
              </VerticalTable>
              <VerticalTable title={'배송 상태 변경'}>
                <HFInput
                  type={'text'}
                  name={'toDeliveryStatus'}
                  register={registerLg}
                  readOnly
                />
              </VerticalTable>
              <VerticalTable title={'변경 사유'}>
                <HFInput
                  type={'text'}
                  name={'changeReason'}
                  register={registerLg}
                />
              </VerticalTable>
              <VerticalTable title={'코멘트'} required>
                <HFInput
                  type={'text'}
                  name={'changeRequestComment'}
                  register={registerLg}
                  required
                />
              </VerticalTable>
            </VerticalTableContainer>
          </div>
        </PopUp>
      )}

      <PopUp
        isOpenPopUp={isOpenApprovalStatusChange}
        onRequestClose={() => setIsOpenApprovalStatusChange(false)}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='approvalOrReject'
                subType={'type02'}
                onClick={handleSubmitLg(
                  (data) =>
                    onApprovalUpdateStatusChange({
                      data,
                      status: statusChangePopupInfo.status,
                    }),
                  onFailUpdateStatusChange
                )}
                fullWidth
              >
                {statusChangePopupInfo.text}
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={css.alert}>
          <div className={css.alert_body}>
            {statusChangePopupInfo.status === 'REJECTED' ? (
              <>요청 처리를 반려 하시겠습니까?</>
            ) : (
              <>
                요청 처리를 반영 하시겠습니까?
                <br />
                <span className={cn('color_primary')}>
                  {isOpenChangeRequestCommentTitle}
                </span>
                상태로 변경 됩니다.
              </>
            )}
          </div>
        </div>
      </PopUp>
      <PopUp isOpenPopUp={loading} closeButton={false}>
        <Loading />
      </PopUp>
    </>
  );
};

export default DeliveryOrder;
