import classNames from 'classnames/bind';
import React from 'react';
import css from './UnusedSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { Button } from '@components/index';
import { useFormContext } from 'react-hook-form';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';

const cn = classNames.bind(css);

const UnusedSearchArea = ({ statusSearchOptions, onSearchClick }) => {
  const { register, control, handleSubmit } = useFormContext();

  return (
    <div className={cn('searchOptionArea', 'row', 'mt20')}>
      <div className={cn('flex')}>
        <div className={cn('innerBox')}>
          <strong className={cn('title')}>폐기 상태</strong>
          <div className={cn('innerBox')}>
            <div className={cn('selectbox', 'type01')}>
              <HFSelectBox
                control={control}
                name={'statusSearchOptions'}
                options={statusSearchOptions}
              />
            </div>
          </div>
          <div className={cn('innerBox')}>
            <HFCalendar
              name={'startDate'}
              defaultValue={new Date('2023-08-01')}
              control={control}
            />
            <span className={cn('dash')}>-</span>
            <HFCalendar name={'endDate'} control={control} />
          </div>
          <div className={cn('innerBox')}>
            <Button
              styleType={'btn01'}
              subType={'type03'}
              className={cn('btn', 'ml40')}
              onClick={handleSubmit(onSearchClick)}
            >
              검색
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnusedSearchArea;
