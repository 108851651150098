import { TAxios } from 'redux/function/TAxios';
import { setAuthenticationFlag } from '@feature/user/userSlice';
import { openModalAlert } from '@feature/common/commonSlice';
import { ROUTER_PATH } from '@router/routerPath';
import { formatDateTime } from '@utils';
import {
  getAwaitVendorMasterList,
  postApproveVendorMaster,
} from './AuthorityAction';
import { PERMISSIONS, SEARCH_TYPE } from '@data/Const';

/**
 * @category Action
 * @namespace getCheckUserAuthenticationByPassword
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @property {string} params.userId - 사용자 Id
 * @property {string} params.password - 사용자 비밀번호
 */

// 사용자 본인인증(비밀번호) Api 함수
const getCheckUserAuthenticationByPassword = (
  dispatch,
  params,
  setAuthPassword,
  setIsOpenModify,
  setIsOpenModifyPassword
) => {
  const URL = '/api/1.0/app/user/checkUserAuthenticationByPassword';

  const onSuccess = (response) => {
    if (response.data.rs.authenticationFlag === 'Y') {
      setIsOpenModify && setIsOpenModify(false);
      setIsOpenModifyPassword && setIsOpenModifyPassword(true);
    } else {
      dispatch(openModalAlert({ message: '잘못된 Password 입니다.' }));
      setAuthPassword && setAuthPassword('');
    }
    dispatch(setAuthenticationFlag(response.data.rs.authenticationFlag));
  };
  const onFail = (response) => {
    console.log('getCheckUserAuthenticationByPassword onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, true);
};

/**
 * @category Action
 * @namespace getCheckUserAuthenticationByEmail
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @property {string} params.userId - 사용자 Id
 * @property {string} params.email - 사용자 이메일
 */

// 사용자(내부망) 비밀번호 재설정 전 검증 Api
const getCheckUserAuthenticationByEmail = (
  dispatch,
  params,
  setIsOpenModifyPassword
) => {
  const URL = '/api/1.0/app/user/checkUserAuthenticationByEmail';

  const onSuccess = (response) => {
    if (response.data.rs.authenticationFlag !== 'Y') {
      dispatch(openModalAlert({ message: '정보를 확인해주세요.' }));
    } else setIsOpenModifyPassword(true);
  };
  const onFail = (response) => {
    console.log('getCheckUserAuthenticationByEmail onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, true);
};

/**
 * @category Action
 * @namespace PasswordVerificationAndChange
 * @param {function} dispatch - dispatch 함수
 * @param {object} checkPasswordParams - get parameters
 * @example
 * const checkPasswordParams = {
 *  userId: "testUser",
 *  password: "12345678",
 * };
 * @property {string} checkPasswordParams.userId - 사용자 Id
 * @property {string} checkPasswordParams.password - 기존 사용자 비밀번호
 *
 * @param {object} changePasswordParams - get parameters
 * @example
 * const changePasswordParams = {
 *   userId: "testUser",
 *   password: "12345678",
 * };
 * @property {string} changePasswordParams.userId - 사용자 Id
 * @property {string} changePasswordParams.password - 변경 할 사용자 비밀번호
 *
 * @param {function} handlePopUp - 팝업을 닫는 함수
 * @param {function} resetPasswordFields - 비밀번호 필드를 초기화하는 함수 (비밀번호 필드 예시: setCurrentPassword, setNewPassword, setConfirmPassword)
 */

// 사용자 본인인증(비밀번호)후 비밀번호를 변경해주는 Api
const getPasswordVerificationAndChange = (
  dispatch,
  checkPasswordParams,
  changePasswordParams,
  handlePopUp,
  resetPasswordFields
) => {
  const URL = '/api/1.0/app/user/checkUserAuthenticationByPassword';

  const onSuccess = (response) => {
    if (response.data.rs.authenticationFlag === 'Y') {
      postChangeUserPassword(dispatch, changePasswordParams);
      handlePopUp();
      resetPasswordFields();
    } else {
      dispatch(
        openModalAlert({ message: '기존 비밀번호와 일치하지 않습니다.' })
      );
    }
  };
  const onFail = (response) => {
    console.log('getPasswordVerificationAndChange onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    checkPasswordParams,
    onSuccess,
    onFail,
    true
  );
};

/**
 * @category Action
 * @namespace postCreateVendorUser
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post parameters
 * @example
 * const params = {
  "userId": "lgeuser",
  "password": "12345678"
  }
 * @property {string} params.userId - 사용자 아이디 
 * @property {string} params.password - 변경할 아이디
 */

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const postChangeUserPassword = (
  dispatch,
  params,
  setFailPopUpMsg,
  setIsOpenFail
) => {
  const URL = '/api/1.0/app/user/changeUserPassword';

  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      // 내 정보 페이지에서 비밀번호 변경시 플래그 추가
      dispatch(openModalAlert({ message: '저장이 완료되었습니다.' }));
    } else {
      setIsOpenFail && setIsOpenFail(true);
      setFailPopUpMsg && setFailPopUpMsg('비밀번호 재설정 실패');
    }
  };
  const onFail = (response) => {
    console.log('postChangeUserPassword onFail ', response, params);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

const postChangeUserInfo = (dispatch, params) => {
  const URL = '/api/1.0/app/user/changeUserInfo';

  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      dispatch(
        openModalAlert({
          modalType: 'routerModal',
          message: '저장이 완료되었습니다.',
          path: ROUTER_PATH.HOME,
        })
      );
    }
  };
  const onFail = (response) => {
    console.log('postChangeUserInfo onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

const postWithdrawVendorUser = (dispatch, params, setIsConfirmWithdrawal) => {
  const URL = '/api/1.0/app/user/withdrawUser';

  const onSuccess = (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      setIsConfirmWithdrawal(true);
    }
  };
  const onFail = (response) => {
    console.log('postWithdrawVendorUser onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

// 휴대전화번호 중복여부 확인
const getCheckPhoneNumberExist = (dispatch, params, setPhoneNoCheck) => {
  const URL = '/api/1.0/app/user/checkPhoneNumberExist';

  const onSuccess = (response) => {
    if (response.data.rs.existFlag === 'Y') {
      dispatch(openModalAlert({ message: '이미 사용중인 전화번호입니다.' }));
      setPhoneNoCheck(false);
    } else {
      dispatch(openModalAlert({ message: '사용 가능합니다.' }));
      setPhoneNoCheck(true);
    }
  };
  const onFail = (response) => {
    console.log('checkPhoneNumberExist onFail ', response);
    dispatch(openModalAlert({ message: '이미 사용중인 전화번호입니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

// 메일 중복여부 확인
const getCheckEmailExist = (dispatch, params, setEmailCheck) => {
  const URL = '/api/1.0/app/user/checkEmailExist';

  const onSuccess = (response) => {
    if (response.data.rs.existFlag === 'Y') {
      dispatch(openModalAlert({ message: '이미 사용중인 이메일입니다.' }));
      setEmailCheck(false);
    } else {
      dispatch(openModalAlert({ message: '사용 가능합니다.' }));
      setEmailCheck(true);
    }
  };
  const onFail = (response) => {
    console.log('getCheckEmailExist onFail ', response);
    // TODO: 중복되는 값이면 에러 발생하는 것이 맞는지 확인 필요
    dispatch(openModalAlert({ message: '이미 사용중인 이메일입니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

// 사용자 비밀번호 변경 건너뛰기
const postSkipPasswordUpdate = (dispatch, params) => {
  const URL = '/api/1.0/app/user/skipUserPasswordUpdate';
  const onSuccess = (response) => {
    console.log('postSkipPasswordUpdate', response.data);
  };
  const onFail = (response) => {
    console.log('postSkipPasswordUpdate onFail ', response);
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    true,
    header
  );
};

// 내 정보 / 사용자 일반 정보 변경
const postUpdateVendorGeneralInfo = ({
  dispatch,
  params,
  setIsOpenRequestChange,
  authority,
}) => {
  const URL = '/api/1.0/app/user/vendor/updateVendorGeneralInfo';

  const upLoadHeader = {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data', // post method params file 보내기 위한 header 추가
    },
  };

  const formData = new FormData();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      if (key === 'registrationDocFiles' || key === 'salesDocFiles') {
        formData.append(key, params[key][0]);
      } else {
        formData.append(key, params[key]);
      }
    }
  }
  const onSuccess = async (response) => {
    if (response.data.rs.result === 'SUCCESSED') {
      if (authority && authority !== 'LEVEL_3') {
        const getMasterListParams = {
          fromDate: '20231001',
          toDate: formatDateTime(new Date()).split(' ')[0],
          approvalStatus: PERMISSIONS.UNDER_APPROVAL,
          searchType: SEARCH_TYPE.COMPANY_NAME,
          detailCondition: params?.companyName,
          limit: '100',
          page: '1',
        };

        const { payload } = await dispatch(
          getAwaitVendorMasterList(getMasterListParams)
        );

        try {
          if (payload && payload[0]) {
            const approveVendorMasterParams = {
              vendorHistKey: payload[0]?.vendorHistKey,
              vendorKey: payload[0]?.vendorKey,
              approvalStatus: PERMISSIONS.APPROVED,
              commentApproval: '승인합니다.',
              userId: params?.userId,
            };

            dispatch(
              postApproveVendorMaster({
                dispatch,
                params: approveVendorMasterParams,
                mutationParams: payload[0],
              })
            );
            dispatch(openModalAlert({ message: '승인완료 되었습니다.' }));
          }
        } catch (error) {
          new Error(error);
        }
      } else {
        dispatch(openModalAlert({ message: '승인대기 중입니다.' }));
        setIsOpenRequestChange && setIsOpenRequestChange(false);
      }
    } else {
      dispatch(openModalAlert({ message: '변경 요청에 실패했습니다.' }));
    }
  };
  const onFail = (response) => {
    console.log('postUpdateVendorGeneralInfo onFail ', response, params);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    formData,
    onSuccess,
    onFail,
    true,
    upLoadHeader
  );
};

export {
  getCheckUserAuthenticationByPassword,
  getCheckUserAuthenticationByEmail,
  postChangeUserPassword,
  getPasswordVerificationAndChange,
  postChangeUserInfo,
  postWithdrawVendorUser,
  getCheckPhoneNumberExist,
  getCheckEmailExist,
  postSkipPasswordUpdate,
  postUpdateVendorGeneralInfo,
};
