import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import css from './RefundUnused.module.scss';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { REFUND_SUB_PATH } from '@router/routerPath';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Button,
  Calendar,
  Input,
  PopUp,
  PopUpAlert,
  SelectBox,
  VerticalTable,
} from '@components/index';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAlert } from '@feature/common/commonSlice';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import UnusedSearchArea from './modules/UnusedSearchArea';
import TableCheckbox from '@components/Table/TableCheckbox';
import {
  isDateInRange,
  matchesCondition,
} from '@components/Search/utils/searchValidationFunctions';
import flow_level_admin from '@assets/img/img_flow_refund_lv12.svg';
import flow_level_vendor from '@assets/img/img_flow_refund_lv34.svg';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { useSelect } from '@hooks/index';
import { useEffect } from 'react';
import axios from 'axios';
import {
  getVendorRefundStatusListAPI,
  getVendorRefundStatusDashboardAPI,
  postVendorCouponStatusAPI,
} from 'redux/action/refundAction';
import { convertDateToString2 } from '@utils';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const statusSearchOptions = {
  guide: '전체',
  list: [
    { id: 'CHANGE_TARGET', title: '상태변경진행중' },
    { id: 'CHANGE_COMPLETED', title: '상태변경완료' },
  ],
};

const COUPON_REFUND_STATUS_OPTION = {
  guide: '변경할 상태를 선택해주세요.',
  list: [
    { id: 'C004', title: '쿠폰사용확정(C004)' },
    { id: 'C006', title: '쿠폰 폐기(C006)' },
  ],
};

const discardReasonList = [
  { id: 'first_catagory', title: '기간만료' },
  { id: 'second_catagory', title: '무언가' },
  { id: 'thired_catagory', title: '이유' },
  { id: 'fourth_catagory', title: '등등' },
  { id: 'fifth_catagory', title: '이유' },
];

const RefundUnused = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  //
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedVendorKey = searchParams.get('vendorkey');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const searchType = searchParams.get('searchType');
  const detailCondition = searchParams.get('detailCondition');
  const paramFromDate = searchParams.get('fromDate');
  const paramToDate = searchParams.get('toDate');
  const couponStatusList = searchParams.get('couponStatusList');

  //
  const searchForms = useForm();
  const { control, watch } = searchForms;

  const updateList = useRef(null);
  const userData = useSelector((state) => state.user.userData);
  const vendorKey = useSelector((state) => state.user.userData.vendorKey);
  const vendorRefundStatusList = useSelector(
    (state) => state.refund.vendorRefundStatusList.list
  );
  const vendorRefundDashboard = useSelector(
    (state) => state.refund.vendorRefundDashboard
  );
  const [isDisableSubmitButton, setIsDisableSubmitButton] = useState(true);
  const [isOpenDiscardPopUp, setIsOpenDiscardPopUp] = useState(false);
  const [isOpenDiscardDecisionPopUp, setIsOpenDiscarDecisiondPopUp] =
    useState(false);
  const level = useSelector((state) => state.user.userData.authority);
  const [decisionText, setDecisionText] = useState(null);
  const [decision, setDecision] = useState('confirm');

  const [currentSelectValue, setCurrenrSelectValue, currentSelectLabel] =
    useSelect({
      optionList: discardReasonList,
      initialIndex: 0,
      initialLabel: '기간만료',
      // titleKey: 'companyName',
    });

  const isLgeUser = () => {
    return level === 'LEVEL_1' || level === 'LEVEL_2';
  };

  const onSearchClick = useCallback(
    (data) => {
      //TODO:API 호출 검색버튼
      const { startDate, endDate, statusSearchOptions } = data;

      const params = {
        vendorKey: selectedVendorKey,
        fromDate: convertDateToString2(startDate),
        toDate: convertDateToString2(endDate),
        changeStatus: statusSearchOptions,
        limit: '100',
        page: '1',
      };
      getVendorRefundStatusListAPI(dispatch, params);
    },
    [vendorRefundStatusList]
  );

  // const discardRequest = useCallback(() => {
  //   setIsOpenDiscardPopUp(!isOpenDiscardPopUp);
  // }, []);

  const onClickDecision = useCallback((decision) => {
    setIsOpenDiscarDecisiondPopUp(true);
    if (decision === 'confirm') {
      setDecision('confirm');
      setDecisionText(
        '요청 처리를 반영 하시겠습니까? 폐기 진행 중 상태로 변경됩니다.'
      );
    } else {
      setDecision('cancle');
      setDecisionText('요청 처리를 반려 하시겠습니까?');
    }
    setIsOpenDiscardPopUp(false);
  }, []);

  const decisionConfirm = useCallback(() => {
    setIsOpenDiscarDecisiondPopUp(false);
    if (decision === 'confirm') {
      //Todo: do someting (api작업)
      alert('comfirm');
    } else {
      //Todo: do someting (api작업)
      alert('cancle');
    }
    setIsOpenDiscardPopUp(false);
  }, [decision]);

  const discardConfirm = useCallback(() => {
    let fromCoupon = { status: undefined };
    let toCoupon = { status: undefined };
    if (updateList.current.length > 0) {
      const filteredUpdateList = updateList.current.filter(
        (item) => item.changeStatus === 'CHANGE_TARGET'
      );
      const updateTargetList = filteredUpdateList.map((item) => {
        fromCoupon['status'] = item.currentCouponStatus;
        toCoupon['status'] = item.toCouponStatus;
        return item.deliveryPlanNo;
      });
      console.log('updateTargetList', updateTargetList);
      if (!fromCoupon.status) {
        dispatch(
          openModalAlert({
            message: '기존 쿠폰상태가 동일한 대상만 변경 가능합니다.',
          })
        );
      } else if (!toCoupon.status) {
        dispatch(
          openModalAlert({ message: '변경할 쿠폰상태를 확인해주세요.' })
        );
      } else {
        const params = {
          updateTargetList: JSON.stringify(updateTargetList),
          fromCouponStatus: fromCoupon.status,
          toCouponStatus: toCoupon.status,
          userId: userData.userId,
        };
        postVendorCouponStatusAPI(dispatch, params);
        dispatch(
          openModalAlert({
            message: '쿠폰 변경 상태가 상태변경완료로 변경됩니다.',
          })
        );
      }
    }
  }, [userData]);

  const onChangeSelectedKeys = useCallback((keys) => {
    console.log('###watch', watch());
    const valuesAll = watch();
    console.log('###keys', keys);
    const willChangeStatus = {};

    keys.forEach((key) => {
      const couponKeyOnlyNumber = key.couponKey.split('.')[1];
      if (
        valuesAll?.[couponKeyOnlyNumber] &&
        !willChangeStatus[valuesAll?.[couponKeyOnlyNumber]]
      ) {
        willChangeStatus[valuesAll?.[couponKeyOnlyNumber]] = true;
      }
    });

    if (Object.keys(willChangeStatus).length === 1) {
      setIsDisableSubmitButton(false);
      const targetList = keys.map((item) => {
        const copyItem = { ...item };
        copyItem.toCouponStatus = Object.keys(willChangeStatus)[0];
        return copyItem;
      });
      updateList.current = targetList;
    } else {
      setIsDisableSubmitButton(true);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-refund-vendor-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-refund-vendor-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발송예정번호</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>회차</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('validToDate', {
        id: 'validToDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>만료일</span>,
      }),
      columnHelper.accessor('disposalExceedDays', {
        id: 'disposalExceedDays',
        cell: (info) => <span>{`+${info.getValue()}`}</span>,
        header: () => <span>폐기 경과일</span>,
      }),
      columnHelper.accessor('changeStatusDesc', {
        id: 'changeStatusDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰 변경 상태</span>,
      }),
      columnHelper.accessor('changeRequestDate', {
        id: 'changeRequestDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>폐기 요청일</span>,
      }),
      columnHelper.accessor('currentCouponStatus', {
        id: 'currentCouponStatus',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰 상태</span>,
      }),
      columnHelper.accessor('toCouponStatus', {
        id: 'toCouponStatus',
        cell: (info) => {
          return (
            <span>
              {vendorRefundStatusList.length > 0 && (
                <HFSelectBox
                  control={control}
                  disabled={
                    !info.row.getIsSelected() ||
                    info.row.original.changeStatus === 'CHANGE_COMPLETED'
                  }
                  name={
                    info.row?.original?.couponKey?.split('.')?.[1] ??
                    'toCouponStatus' + info.row.id
                  }
                  options={COUPON_REFUND_STATUS_OPTION}
                  type={'type04'}
                />
              )}
            </span>
          );
        },
        header: () => <span>변경할 쿠폰 상태</span>,
      }),
    ],
    [vendorRefundStatusList]
  );

  const dashboardClick = useCallback((type) => {
    if (type === 'unusedCouponCount') {
      const data = {
        startDate: new Date('2023-08-01'),
        endDate: new Date(),
        statusSearchOptions: 'CHANGE_TARGET',
      };
      onSearchClick(data);
    } else {
      const data = {
        startDate: new Date('2023-08-01'),
        endDate: new Date(),
        statusSearchOptions: 'CHANGE_COMPLETED',
      };
      onSearchClick(data);
    }
  }, []);

  useEffect(() => {
    const params = {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' && selectedVendorKey
          ? selectedVendorKey
          : process.env.REACT_APP_MODE === 'LG'
            ? ''
            : vendorKey,
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
      limit: limit ?? '100',
      page: page ?? '1',
    };
    getVendorRefundStatusDashboardAPI(dispatch, params);
  }, [vendorKey, selectedVendorKey, limit, page]);

  useEffect(() => {
    //List초기 렌더링
    const params = {
      vendorKey: selectedVendorKey ? selectedVendorKey : '',
      fromDate: '2023-08-01',
      toDate: convertDateToString2(new Date()),
      changeStatus: '',
      limit: '10',
      page: '1',
    };
    getVendorRefundStatusListAPI(dispatch, params);
  }, []);

  return (
    <>
      <h2 className={cn('pageTitle')}>환불 현황</h2>
      <div className={cn('statusDashBoard')}>
        <div className={cn('status', 'flow', 'refund')}>
          <dl>
            <dt>장기 미사용 쿠폰 건수</dt>
            <dd>
              <div className={cn('value', 'color-pink')}>
                <span
                  className={cn('num')}
                  style={{ cursor: 'pointer' }}
                  onClick={() => dashboardClick('unusedCouponCount')}
                >
                  {vendorRefundDashboard?.unusedCouponCount ?? 0}
                </span>
                건
              </div>
            </dd>
          </dl>
          <dl>
            <dt>쿠폰 폐기 완료</dt>
            <dd>
              <div className={cn('value')}>
                <span
                  className={cn('num')}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    dashboardClick('statusChangeCompletedCouponCount')
                  }
                >
                  {vendorRefundDashboard?.statusChangeCompletedCouponCount ?? 0}
                </span>
                건
              </div>
            </dd>
          </dl>
        </div>
      </div>

      {process.env.REACT_APP_MODE === 'LG' ? (
        <div className={cn('flowChart', 'mt32')}>
          <h3 className={cn('title')}>
            장기 미사용 쿠폰 환불 관리 Flow(Level1~2 관리자)
          </h3>
          <div className={cn('img')}>
            <img src={flow_level_admin} alt='' />
          </div>
        </div>
      ) : (
        <div className={cn('flowChart', 'mt32')}>
          <h3 className={cn('title')}>
            장기 미사용 쿠폰 환불 관리 Flow(Level3,4 제휴사)
          </h3>
          <div className={cn('img')}>
            <img src={flow_level_vendor} alt='' />
          </div>
        </div>
      )}

      {/* -- 검색 옵션 -- */}
      {/* <div className={cn('searchOptionArea', 'mt20')}> */}
      <FormProvider {...searchForms}>
        <UnusedSearchArea
          statusSearchOptions={statusSearchOptions}
          onSearchClick={onSearchClick}
        />
      </FormProvider>
      {/* </div> */}

      <TableV8ManualPage
        columns={columns}
        data={vendorRefundStatusList}
        csvTitle='내보내기'
        // page={page}
        // limit={limit}
        // totalLength={couponOverallStatusTotalCount}
        getRowselected={onChangeSelectedKeys}
        rightControls={
          <Button
            onClick={discardConfirm}
            disabled={isDisableSubmitButton}
            className={cn('btn', 'btn03', 'type01')}
          >
            {/* <i className={cn('ico_act', 'type01')}></i>쿠폰 폐기 확정 */}
            쿠폰 상태 변경 저장
          </Button>
        }
      />

      <PopUp
        isOpenPopUp={isOpenDiscardPopUp}
        onRequestClose={() => setIsOpenDiscardPopUp(false)}
        closeButton
        button={[
          <div className={cn('btn_container4')}>
            <Button
              key='alertConfirm'
              subType={'type03'}
              onClick={() => onClickDecision('cancel')}
              className={cn('btn')}
              fullWidth
            >
              취소
            </Button>
            <Button
              key='alertConfirm'
              subType={'type02'}
              onClick={() => onClickDecision('confirm')}
              className={cn('btn')}
              fullWidth
            >
              승인 요청
            </Button>
          </div>,
        ]}
      >
        <div className={cn('popup')}>
          <strong className={cn('popup_title')}>폐기 요청</strong>
          <div className={cn('popup_body')}>
            <div className={cn('inputWrap')}>
              <VerticalTable title={'폐기 상태'}>
                <Input
                  type='text'
                  size='auto'
                  readOnly
                  placeholder='폐기 미 요청'
                  // value={currentNotice.createUserCompanyName}
                />
              </VerticalTable>
            </div>
            <VerticalTable title={'상태 변경'}>
              <Input
                type='text'
                size='auto'
                readOnly
                placeholder='폐기 진행 중'
                // value={currentNotice.createUserCompanyName}
              />
            </VerticalTable>
            <VerticalTable title={'폐기 사유'}>
              <SelectBox
                optionList={discardReasonList}
                currentIndex={currentSelectValue}
                currentLabel={currentSelectLabel}
                handleSelectIndex={setCurrenrSelectValue}
                fullWidth
              />
            </VerticalTable>
            <VerticalTable title={'상세 사유'}>
              <Input
                type='text'
                size='auto'
                placeholder='*상세 사유 입력(폐기 처리 부탁 합니다.)'
                // value={currentNotice.createUserCompanyName}
              />
            </VerticalTable>
            <VerticalTable title={'코멘트'}>
              <Input
                type='text'
                size='auto'
                readOnly={isLgeUser}
                // placeholder="폐기 미 요청"
                // value={currentNotice.createUserCompanyName}
              />
            </VerticalTable>
          </div>
        </div>
      </PopUp>

      <PopUpAlert
        isOpenPopUp={isOpenDiscardDecisionPopUp}
        onRequestClose={() => setIsOpenDiscarDecisiondPopUp(false)}
        closeButton
        button={[
          <div className={cn('btn_container')}>
            <Button
              key='alertConfirm'
              subType={'type02'}
              onClick={() => decisionConfirm('cancel')}
              className={cn('btn', 'btn01')}
              fullWidth
            >
              확인
            </Button>
          </div>,
        ]}
      >
        <div className={cn('alert_body')}>{decisionText && decisionText}</div>
      </PopUpAlert>
    </>
  );
};

export default RefundUnused;
