import axios from 'axios';
import { getUrl, AUTHORIZATION } from './TAxiosConfig';
import { createQueryString, backendMode } from '@utils';
import store from '@store/index';
import { setSessionExpiredPopup } from '@feature/common/commonSlice';

/**
 * type: 'get', 'put', 'post', 'delete'
 */

export const TAxios = async (
  dispatch,
  getState,
  type,
  baseUrl,
  params = {},
  onSuccess,
  onFail,
  noTokenRefresh = false,
  header
) => {
  //도메인 주소로 backend 형상 판단
  const serverType = backendMode(); //개발용

  if (typeof window === 'object') {
    const urlparams = createQueryString(params);
    let url = '';
    if (Array.isArray(baseUrl)) {
      url = getUrl(baseUrl[0], serverType);
      for (let i = 1; i < baseUrl.length; i++) {
        url += '/' + baseUrl[i];
      }
    } else {
      url = getUrl(baseUrl, serverType);
    }
    // eslint-disable-next-line
    switch (type) {
      case 'get':
        url += urlparams ? `?${urlparams}` : '';
        break;
    }
    if (serverType !== 'prd') {
      console.log('TAxios ', type + ': ' + url, params);
    }

    let axiosIns = null;
    // eslint-disable-next-line
    switch (type) {
      case 'get':
        axiosIns = axios[type](url);
        break;
      case 'put':
      case 'post':
        axiosIns = axios[type](url, params, header);
        break;
      case 'delete': {
        const auth = Object.assign({}, AUTHORIZATION, { data: params });
        axiosIns = axios[type](url, auth);
        break;
      }
    }
    if (axiosIns) {
      axiosIns
        .then((res) => {
          if (serverType !== 'localhost') {
            const sessionexpired = res.headers[`sessionexpired`];
            if (sessionexpired === 'true') {
              return store.dispatch(setSessionExpiredPopup(true));
            }
          }

          if (serverType !== 'prd') {
            console.log('TAxios response', url, res);
          }
          if (onSuccess) {
            onSuccess(res);
          }
        })
        .catch((error) => {
          console.error(
            'TAxios ',
            url,
            'resultCode: ',
            error
            // error.response.data.resultCode
          );

          if (onFail) {
            onFail(console.log('fail'));
          }
        });
    } else {
      console.warn('TAxios invalid case type ', type);
    }
  }
};
