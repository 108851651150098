import { useState, memo, useEffect, useRef, useCallback } from 'react';
import css from './SignUp.module.scss';
import classNames from 'classnames/bind';
import {
  Button,
  Input,
  VerticalTable,
  VerticalTableContainer,
} from '@components';
import { useInput, useValidation, useInputMultiple } from '@hooks';
import { handlePreventRegex } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateSignUpInfo } from '@feature/signUp/signUpSlice';
import { useNavigate } from 'react-router-dom';
import {
  postCreateVendorUser,
  getCheckIdAvailable,
} from 'redux/action/registerAction';
import {
  getCheckPhoneNumberExist,
  getCheckEmailExist,
} from 'redux/action/userAction';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

// A0006 글자수를 확인 해주세요
// A0025 사용 불가능한 ID입니다.
// A0026 사용 가능한 ID입니다.
// A0027 사용 불가능한 패스워드 입니다.
// A0028 사용 가능한 패스워드 입니다.

/**
 * @category components
 * @namespace SignUP
 * @description 회원 가입 페이지
 */

const SignUp = ({ handleClickStepPrev }) => {
  const dispatch = useDispatch();

  const businessRegisterData = useSelector(
    (state) => state.signUp.businessRegister
  );
  const businessInfoData = useSelector((state) => state.signUp.businessInfo);
  const newVendorInfo = useSelector((state) => state.signUp.newVendorInfo);

  // 국세청 API 검증 후 받아오는 데이터
  const companyNumber = businessRegisterData.companyRegNo;
  const companyBizName = businessRegisterData.companyBizName;
  const companyCeoName = businessRegisterData.companyCeoName;
  const companyOpenDate = businessRegisterData.companyOpenDate;

  // checkVendorExist API 의 response 값으로 받아오는 데이터
  const companyName = businessInfoData.companyName;
  const companyVendorKey = businessInfoData.vendorKey;
  const companyCode = businessInfoData.companyCode;
  const bizRegisDocFileKey = businessInfoData.bizRegisDocFileKey;
  const ecomRegisDocFileKey = businessInfoData.ecomRegisDocFileKey;

  const newCompanyVendorKey = newVendorInfo.vendorKey;
  const newCompanyCode = newVendorInfo.companyCode;
  const newCompanyName = newVendorInfo.companyName;

  // 중복확인 버튼이 눌렸는지 여부를 바꿔주는 함수
  const handleIdChecked = (checked) => {
    setIdCheck(checked);
  };
  // 유효성 확인 버튼이 눌렸는지 여부를 바꿔주는 함수
  const handlePasswordChecked = (checked) => {
    setPassWordCheck(checked);
  };

  //Input의 value를 바꿔주기 위한 useInput hook
  const [id, setId, onChangeId] = useInput('', handleIdChecked);
  const [password, setPassword, onChangePassword] = useInput(
    '',
    handlePasswordChecked
  );
  const [
    passwordConfirmation,
    setPasswordConfirmation,
    onChangePasswordConfirmation,
  ] = useInput('');

  const [phoneNumber, setPhoneNumber, onChangePhoneNumber] = useInputMultiple({
    phoneNum1: '',
    phoneNum2: '',
    phoneNum3: '',
  });

  const { phoneNum1, phoneNum2, phoneNum3 } = phoneNumber;

  const [email, setEmail, onChangeEmail] = useInput('');
  const [emailConfirmation, setEmailConfirmation, onChangeEmailConfirmation] =
    useInput('');

  // 유효성 체크를 위한 hook
  const idValidate = useValidation('id', id);
  const emailValidate = useValidation('email', email);
  const passwordValidate = useValidation('password', password, id);
  const passwordConfirmationValidate = useValidation(
    'passwordConfirmation',
    passwordConfirmation,
    password
  );
  const phoneValidate = useValidation(
    'phone',
    `${phoneNum1}-${phoneNum2}-${phoneNum3}`
  );

  //id 중복 체크,비밀번호 유효성 검사 체크,이메일 인증 요청되었는지 여부
  const [idCheck, setIdCheck] = useState(false);
  const [passwordCheck, setPassWordCheck] = useState(false);
  const [passwordConfirmationCheck, setPassWordConfirmationCheck] =
    useState(false);

  // 중복 확인 boolean 값
  const [phoneNoCheck, setPhoneNoCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(false);

  // required 메세지를 위한 변수
  const [requiredIdMessage, setRequiredIdMessage] = useState('');
  const [requiredPasswordMessage, setRequiredPasswordMessage] = useState('');
  const [
    requiredPasswordConfirmationMessage,
    setRequiredPasswordConfirmationMessage,
  ] = useState('');
  const [requiredNameMessage, setRequiredNameMessage] = useState('');

  // 이미지 파일 이름을 위한 변수
  const [registrationFileName, setRegistrationFileName] = useState('');
  const [mailOrderFileNamef, setMailOrderFileNamef] = useState('');

  // 이미지 등록 버튼과 input을 연결하기 위한 Ref
  const registrationImageInputRef = useRef();
  const mailOrderImageInputRef = useRef();

  // 이미지 파일
  const [mailOrderImage, setMailOrderImage] = useState();
  const [registrationImage, setRegistrationImage] = useState();

  const navigate = useNavigate();

  const handleNavigate = useCallback((path) => {
    navigate(path);
    // eslint-disable-next-line
  }, []);

  // 사용자(제휴사) 신규가입 Api
  const createUser = () => {
    const params = {
      userId: id,
      password: password,
      phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
      email: email,
      vendorKey: newCompanyCode ? newCompanyVendorKey : companyVendorKey,
      companyCode: newCompanyCode ? newCompanyCode : companyCode,
      companyName: newCompanyCode ? newCompanyName : companyName,
      companyBizName: newCompanyCode ? newCompanyName : companyBizName,
      companyRegNo: companyNumber,
      companyCeoName: companyCeoName,
      companyOpenDate: companyOpenDate,
      registrationDocFiles: registrationImage,
      salesDocFiles: mailOrderImage,
      supportPhoneNo: '1544-7777',
    };

    postCreateVendorUser(dispatch, params, handleNavigate);
  };

  // 사용자 ID 사용 가능 여부 확인 Api
  const handleCheckDuplicate = (check) => {
    const params = {
      userId: id,
    };

    if (check.isBoolean) {
      getCheckIdAvailable(dispatch, params, setId);
      setIdCheck(true);
    } else {
      return dispatch(openModalAlert({ message: check.message }));
    }
  };

  // 비밀번호 유효성 체크
  const handleValidation = (check) => {
    setPassWordCheck(true);
    return dispatch(openModalAlert({ message: check.message }));
  };

  //Button 클릭시 handle Functions
  const handleSave = () => {
    switch (true) {
      case !idCheck:
        dispatch(openModalAlert({ message: '아이디 중복 확인이 필요합니다.' }));
        break;
      case !passwordCheck:
        dispatch(
          openModalAlert({ message: '비밀번호 유효성 확인이 필요합니다.' })
        );
        break;
      case !passwordConfirmationCheck:
        dispatch(openModalAlert({ message: '비밀번호가 일치하지 않습니다.' }));
        break;
      case !emailCheck:
        dispatch(openModalAlert({ message: '올바른 이메일을 입력해주세요' }));
        break;
      case !phoneNum1 || !phoneNum2 || !phoneNum3:
        dispatch(openModalAlert({ message: '올바른 전화번호를 입력해주세요' }));
        break;
      case phoneNum1 !== '010':
        dispatch(openModalAlert({ message: '올바른 전화번호를 입력해주세요' }));
        break;
      case password === `${phoneNum1}${phoneNum2}${phoneNum3}`:
        dispatch(
          openModalAlert({
            message: '비밀번호와 전화번호가 일치 합니다. 정보를 확인해주세요.',
          })
        );
        break;
      default: {
        if (!bizRegisDocFileKey || !ecomRegisDocFileKey) {
          if (!registrationImage || !mailOrderImage) {
            dispatch(
              openModalAlert({
                message:
                  '사업자 등록증과 통신판매업신고증 이미지를 등록해주세요.',
              })
            );
          } else createUser();
        } else createUser();
      }
    }
    dispatch(
      updateSignUpInfo({
        companyNumber: companyNumber,
        companyName: companyName,
        userId: id,
        userPassword: password,
        userEmail: email,
        userPhoneNumber: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
      })
    );
  };

  // 메일 중복 여부 확인 Api
  const handleCheckEmailExist = () => {
    const param = {
      email: email,
    };

    if (email) {
      getCheckEmailExist(dispatch, param, setEmailCheck);
    } else {
      dispatch(openModalAlert({ message: '올바른 이메일을 입력해 주세요.' }));
    }
  };

  // 핸드폰 번호 중복 여부 확인 Api
  const handleCheckPhoneNumberExist = () => {
    const param = {
      phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
    };
    if (phoneNum1 && phoneNum2 && phoneNum3 && phoneCheck) {
      getCheckPhoneNumberExist(dispatch, param, setPhoneNoCheck);
    } else {
      dispatch(openModalAlert({ message: '올바른 전화번호를 입력해 주세요.' }));
    }
  };

  useEffect(() => {
    setPassWordConfirmationCheck(passwordConfirmationValidate.isBoolean);
  }, [passwordConfirmationValidate.isBoolean]);

  useEffect(() => {
    setEmailCheck(emailValidate.isBoolean);
  }, [emailValidate.isBoolean]);

  useEffect(() => {
    setPhoneCheck(phoneValidate.isBoolean);
  }, [phoneValidate.isBoolean]);

  const handleCancel = (e) => {
    e.preventDefault();
    handleClickStepPrev();
  };

  const blurEvent = (condition1, condition2, message, setFunc) => {
    if (condition1 === '' || condition2 === '') {
      setFunc(message);
    } else {
      setFunc('');
    }
  };

  const onClickImageUpload = (ref) => {
    ref.current?.click();
  };

  const handleFileUpload = (e, func) => {
    if (e.target.files?.length > 0) {
      if (e.target.name === 'mailOrderImage') {
        setMailOrderImage(e.target.files);
        func(e.target.files[0].name);
      } else {
        setRegistrationImage(e.target.files);
        func(e.target.files[0].name);
      }
    }
  };

  return (
    <>
      <div className={css.register_input}>
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='cancel'
                  onClick={handleCancel}
                  subType='type03'
                  fullWidth
                >
                  취소
                </Button>
              </div>
              <div className={css.btn}>
                <Button key='save' onClick={handleSave} fullWidth>
                  저장
                </Button>
              </div>
            </div>,
          ]}
          isCenter
        >
          {(bizRegisDocFileKey || ecomRegisDocFileKey) && (
            <>
              <h3 className={cn('sectionTitle')}>사업자 정보</h3>
              <div className={cn('mt16')}></div>
            </>
          )}
          <VerticalTable title={'사업자 등록 번호'}>
            <Input
              type='text'
              value={companyNumber}
              name={companyNumber}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'상호명'}>
            <Input
              type='text'
              name={newCompanyCode ? newCompanyName : companyBizName}
              id={newCompanyCode ? newCompanyName : companyBizName}
              value={newCompanyCode ? newCompanyName : companyBizName}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'제휴사명'}>
            <Input
              type='text'
              name={companyName}
              id={companyName}
              value={companyName}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'대표자명'}>
            <Input
              type='text'
              name={companyCeoName}
              id={companyCeoName}
              value={companyCeoName}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'개업일자'}>
            <Input
              type='text'
              name={companyOpenDate}
              id={companyOpenDate}
              value={companyOpenDate}
              readOnly
            />
          </VerticalTable>
          {(bizRegisDocFileKey || ecomRegisDocFileKey) && (
            <>
              <h3 className={cn('sectionTitle')}>운영자 개인정보</h3>
              <div className={cn('mt16')}></div>
            </>
          )}
          <VerticalTable
            title={'아이디'}
            required
            button={
              <Button
                onClick={() => handleCheckDuplicate(idValidate)}
                styleType={'btn04'}
                subType={'type01'}
              >
                중복 확인
              </Button>
            }
          >
            <Input
              type='text'
              value={id.replace(/[^a-z0-9]/g, '')}
              name={id}
              onChange={onChangeId}
              minLength={5}
              maxLength={10}
              required={true}
              placeholder={'영어 소문자와 숫자만 입력 가능합니다.(5~12자)'}
              onBlur={() =>
                blurEvent(
                  id,
                  'default',
                  '아이디를 입력해 주세요',
                  setRequiredIdMessage
                )
              }
              confirmedMessage={idCheck && idValidate.isBoolean}
              errormessage={requiredIdMessage}
            />
          </VerticalTable>
          <VerticalTable
            title={'비밀번호'}
            required
            button={
              <Button
                onClick={() => handleValidation(passwordValidate)}
                type='button'
                styleType={'btn04'}
                subType={'type01'}
              >
                유효성 확인
              </Button>
            }
          >
            <Input
              type='password'
              value={password}
              name={password}
              onChange={onChangePassword}
              minLength={8}
              maxLength={16}
              required={true}
              placeholder={
                '영어 대소문자, 숫자, 특수기호를 조합해 주세요.(10자 이상)'
              }
              onBlur={() =>
                blurEvent(
                  password,
                  'default',
                  '비밀번호를 입력해 주세요',
                  setRequiredPasswordMessage
                )
              }
              confirmedMessage={passwordCheck && passwordValidate.isBoolean}
              errormessage={requiredPasswordMessage}
              onKeyDown={(e) => handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)}
            />
          </VerticalTable>
          <VerticalTable title={'비밀번호 확인'} required>
            <Input
              type='password'
              value={passwordConfirmation}
              name={passwordConfirmation}
              onChange={onChangePasswordConfirmation}
              minLength={8}
              maxLength={16}
              required={true}
              onBlur={() =>
                blurEvent(
                  passwordConfirmation,
                  'default',
                  '비밀번호 확인을 입력해 주세요',
                  setRequiredPasswordConfirmationMessage
                )
              }
              errormessage={requiredPasswordConfirmationMessage}
              onKeyDown={(e) => handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)}
            />
          </VerticalTable>
          <VerticalTable
            title={'회사 전화번호'}
            isPhoneType
            button={
              <Button
                onClick={() => handleCheckPhoneNumberExist()}
                styleType={'btn04'}
              >
                중복 확인
              </Button>
            }
            required
          >
            <div className={cn('flex', 'align-center-gap8')}>
              <Input
                type='text'
                value={phoneNum1.replace(/[^0-9]/g, '')}
                name={'phoneNum1'}
                onChange={onChangePhoneNumber}
                minLength={3}
                maxLength={3}
                required={true}
                pattern='^[0-9]+$'
              />
              -
              <Input
                type='text'
                value={phoneNum2.replace(/[^0-9]/g, '')}
                name={'phoneNum2'}
                onChange={onChangePhoneNumber}
                minLength={4}
                maxLength={4}
                required={true}
                pattern='^[0-9]+$'
              />
              -
              <Input
                type='text'
                value={phoneNum3.replace(/[^0-9]/g, '')}
                name={'phoneNum3'}
                onChange={onChangePhoneNumber}
                minLength={4}
                maxLength={4}
                required={true}
                pattern='^[0-9]+$'
              />
            </div>
            {/* <div className={cn('note', 'color-error')}>
              휴대전화 오입력시 가입 승인이 거절 됩니다.
            </div> */}
          </VerticalTable>
          <VerticalTable
            title={'회사 이메일'}
            button={
              <Button
                onClick={() => handleCheckEmailExist()}
                styleType={'btn04'}
              >
                중복 확인
              </Button>
            }
            required
          >
            <Input
              type='email'
              value={email}
              name={email}
              onChange={onChangeEmail}
              required
            />
          </VerticalTable>
          {(!bizRegisDocFileKey || !ecomRegisDocFileKey) && (
            <>
              <VerticalTable
                title={'사업자 등록증'}
                button={
                  <Button
                    onClick={() => {
                      onClickImageUpload(registrationImageInputRef);
                    }}
                    styleType={'btn04'}
                  >
                    이미지 등록
                  </Button>
                }
                required
              >
                <Input
                  type='text'
                  name=''
                  id='registrationFileName'
                  placeholder={
                    registrationFileName
                      ? registrationFileName
                      : '용량 2MB 이내 / jpg, png, bmp 이미지 (예시 image.jpg)'
                  }
                  readOnly={true}
                />
                <input
                  ref={registrationImageInputRef}
                  name={'registrationImage'}
                  onChange={(e) => handleFileUpload(e, setRegistrationFileName)}
                  type='file'
                  className={css.fileInput}
                />
              </VerticalTable>
              <VerticalTable
                title={'통신판매업신고증'}
                button={
                  <Button
                    onClick={() => {
                      onClickImageUpload(mailOrderImageInputRef);
                    }}
                    styleType={'btn04'}
                  >
                    이미지 등록
                  </Button>
                }
                required
              >
                <Input
                  type='text'
                  name=''
                  id='mailOrderImageInputRef'
                  placeholder={
                    mailOrderFileNamef
                      ? mailOrderFileNamef
                      : '용량 2MB 이내 / jpg, png, bmp 이미지 (예시 image.jpg)'
                  }
                  readOnly={true}
                />
                <input
                  onChange={(e) => handleFileUpload(e, setMailOrderFileNamef)}
                  ref={mailOrderImageInputRef}
                  name={'mailOrderImage'}
                  type='file'
                  className={css.fileInput}
                />
              </VerticalTable>
            </>
          )}
        </VerticalTableContainer>
      </div>
    </>
  );
};

export default memo(SignUp);
