import React, { useState, useEffect, useCallback, useMemo } from 'react';
import css from './CouponRegisterSecond.module.scss';
import classNames from 'classnames/bind';
import { Button, PopUp } from '@components/index';
import { postCreateCoupon } from 'redux/action/couponAction';
import { useDispatch, useSelector } from 'react-redux';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const CouponRegisterSecond = ({
  randomNumber,
  selectMerchandiseId,
  currentCouponItem,
  randomNumberCallback,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [filteredRandomNumberArray, setFilteredRandomNumberArray] = useState(
    []
  );
  const [duplicatedCount, setDuplicatedCount] = useState(0);

  const deleteRandomNumber = useCallback(
    (index) => {
      randomNumber.splice(index, 1);
      randomNumberCallback([...randomNumber]);
    },
    [randomNumber]
  );

  const randomMemo = useMemo(() => {
    const resultArr = randomNumber.map((item, index) => {
      return (
        <li>
          {item}
          <button
            className={css.delete}
            onClick={() => deleteRandomNumber(index)}
          >
            삭제
          </button>
        </li>
      );
    });
    return resultArr;
  }, [randomNumber, deleteRandomNumber]);

  const removeDuplicatesAndCount = useCallback((arr) => {
    //중복 필터링 로직
    const map = {};
    const countMap = {};

    // 중복 요소를 추적하고 각 요소의 출현 횟수 기록
    arr.forEach((element, index) => {
      if (!(element in map)) {
        map[element] = index; // 각 요소의 첫 번째 등장 인덱스를 기록
        countMap[element] = 1; // 요소의 출현 횟수를 1로 초기화
      } else {
        countMap[element]++; // 중복 요소의 출현 횟수를 증가
      }
    });

    // 중복 요소 중 가장 낮은 인덱스의 값을 제외하고 나머지 중복 요소를 제거
    const result = arr.filter((element, index) => {
      return map[element] === index;
    });

    return { filteredArray: result, countMap };
  }, []);

  const removeDuplicatesCallback = useCallback(() => {
    //useEffect 에서 중복제거된 상태를 반영
    randomNumberCallback(filteredRandomNumberArray);
  }, [filteredRandomNumberArray]);

  const submitRandomNumberCallback = useCallback(() => {
    //submit 로직 TODO: 팝업내용 수정필요
    const [target] = currentCouponItem;
    if (target?.merchandiseKey) {
      const params = {
        merchandiseKey: target.merchandiseKey,
        merchandiseId: target.merchandiseId,
        modelSuffix: target.modelSuffix,
        vendorKey: userData.vendorKey,
        createTargetList: JSON.stringify(randomNumber),
        userId: userData.userId,
      };
      if (randomNumber.length > 0) {
        postCreateCoupon(dispatch, params);
      } else {
        alert('쿠폰을 추가해주세요.');
      }
      randomNumberCallback([]);
    } else {
      setIsOpenPopUp(true); //어떤 상황에 성공 실패 팝업이 뜨는지 판단해서 이동
    }
  }, [currentCouponItem, randomNumber, userData]);

  useEffect(() => {
    //중복제거된 리스트와 카운트 반환
    const { filteredArray, countMap } = removeDuplicatesAndCount(randomNumber);
    const countValues = Object.values(countMap);
    const duplicatedCount = countValues.reduce((acc, curr) => {
      if (curr > 1) {
        return acc + curr;
      }
      return acc;
    }, 0);
    setFilteredRandomNumberArray(filteredArray);
    setDuplicatedCount(duplicatedCount);
  }, [randomNumber]);

  useEffect(() => {
    randomNumberCallback([]);
    //상품 라디오버튼 변경시 리셋
  }, [selectMerchandiseId]);

  return (
    <>
      <div className={css.stepGuideText}>
        ③ 마지막 단계예요! 쿠폰 리스트의 오류나 중복 여부를 확인해주세요.
        <div className={css.btn_container6}>
          <button
            className={cn('btn', 'btn03', 'type03')}
            onClick={() => removeDuplicatesCallback()}
          >
            중복 제거
          </button>
        </div>
      </div>

      <div className={css.totalCount}>
        총 <b>{randomNumber.length}</b>건 (중복 {duplicatedCount}건)
      </div>
      <div className={css.addCouponList}>
        {Array.isArray(randomNumber) && randomNumber.length > 0 && (
          <ul>{randomMemo}</ul>
        )}
      </div>

      <div className={css.stepsNav}>
        <Button
          onClick={submitRandomNumberCallback}
          className={cn('btn', 'btn01', 'type01', 'marginL10')}
        >
          완료
        </Button>
      </div>
      <PopUp
        size='mideum'
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
        closeButton={false}
        button={[
          <Button
            className={cn('btn', 'btn01', 'type02', 'btn_large')}
            key='firstButton'
            onClick={() => {
              setIsOpenPopUp(false);
            }}
          >
            확인
          </Button>,
        ]}
      >
        <div className={css.alert_body}>등록이 필요한 쿠폰이 있습니다.</div>
      </PopUp>
    </>
  );
};

export default CouponRegisterSecond;
