import { memo } from 'react';
import css from './Input.module.scss';
import classNames from 'classnames/bind';
import { getByte, getLimitedByteText } from '@utils';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Input
 * @param {string} id Input 의 고유 id.
 * @param {string} value Input value.
 * @param {boolean} isLabel Input 의 해당 label text 존재 여부.
 * @param {string} label Input 의 해당 label text.
 * @param {string} placeholder Input placeholder.
 * @param {boolean} confirmedMessage 확인 메세지 출력 여부.
 * @param {string} errormessage 에러 메시지 출력.
 * @param {boolean} hasByte Byte 제한 존재 여부.
 * @param {number} maxByte Input 최대 제한 Byte 수.
 * @param {boolean} required Input 필수입력 여부.
 * @param {boolean} disabled Input 비활성화 여부.
 * @param {boolean} readOnly Input 읽기전용 여부
 * @description 공통적으로 사용하는 Input 컴포넌트.
 */

const Input = ({
  id,
  value,
  isLabel,
  label,
  placeholder,
  confirmedMessage,
  errormessage,
  hasByte,
  maxByte,
  required,
  disabled,
  readOnly,
  fullWidth,
  className,
  ...rest
}) => {
  return (
    <div className={cn('container', { fullWidth }, className)}>
      {isLabel && <label htmlFor={id}>{label}</label>}
      <input
        id={id}
        className={cn('input', {
          required,
          disabled,
          readOnly: readOnly,
          bytePadding: hasByte,
        })}
        value={hasByte ? getLimitedByteText(value, maxByte) : value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        {...rest}
      />
      {hasByte && (
        <div className={css.byteCount}>
          <span>{getByte(value)}</span>/<span>{maxByte.toLocaleString()}</span>{' '}
          Bytes
        </div>
      )}
      {confirmedMessage && <div className={css.confirmed}>확인되었습니다.</div>}
      {errormessage && <div className={css.error}>{errormessage}</div>}
    </div>
  );
};

Input.defaultProps = {
  /** Input value 값 */
  value: '',
  /** Input의 label 존재 유무 */
  isLabel: false,
  /** Input byteCount 존재 유무 */
  hasByte: false,
  /** Input 필수 입력 토글 */
  required: false,
  /** Input 비활성화 활성화 토글 */
  disabled: false,
  confirmedMessage: false,
  errormessage: '',
  readOnly: false,
};

export default memo(Input);
