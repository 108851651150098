import React, { useState, memo, useRef } from 'react';
import css from './SlidingCalendar.module.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // CSS 파일을 임포트합니다.
import { ko } from 'date-fns/esm/locale';
import ico_calendar from '@assets/img/ico_calendar.svg';
import classNames from 'classnames/bind';
import * as Utils from 'utils/utils';

const cn = classNames.bind(css);

const SlidingCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarVisible, setCalendarVisible] = useState(false); // 달력 표시 여부 상태 추가
  const [slideDirection, setSlideDirection] = useState('slide-default');
  const dateRef = useRef();

  // 이전 날짜로 이동
  const handlePrevClick = () => {
    setSlideDirection('slide-left');
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  // 다음 날짜로 이동
  const handleNextClick = () => {
    setSlideDirection('slide-right');
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  // 달력 표시 여부를 토글하는 함수
  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  // 달력에서 날짜를 선택한 후의 콜백 함수
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    toggleCalendar(); // 달력을 닫습니다.
  };

  // 선택된 날짜를 원하는 형식으로 표시
  const formattedDate = Utils.timeToISO8601DateStr(selectedDate);

  // 슬라이딩 애니메이션 종료 후 슬라이딩 방향 상태 초기화
  const handleTransitionEnd = () => {
    setSlideDirection('slide-default');
  };

  return (
    <div className={css.container}>
      <button className={css.button} onClick={handlePrevClick}>
        이전
      </button>
      <strong
        className={`${css.container} ${css[slideDirection]}`}
        onTransitionEnd={handleTransitionEnd}
      >
        {formattedDate}
      </strong>
      <button className={cn('button', 'next')} onClick={handleNextClick}>
        다음
      </button>
      <button
        onClick={toggleCalendar}
        type='button'
        className={css.btn_calendar}
      >
        달력
      </button>
      {calendarVisible && (
        <DatePicker
          selected={selectedDate}
          onChange={handleDateSelect}
          dateFormat='yyyy-MM-dd'
          locale={ko}
          maxDate={new Date()}
          showYearDropdown
          scrollableMonthYearDropdown
          inline
        />
      )}
    </div>
  );
};

export default memo(SlidingCalendar);
