import classNames from 'classnames/bind';
import React from 'react';
import css from './ProductListModifySearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { Button } from '@components/index';
import HFInput from '@components/_ReactHookForm/HFInput';
import { useFormContext } from 'react-hook-form';

const cn = classNames.bind(css);

const ProductListModifySearchArea = ({
  selectSearchOptions,
  onSearchClick,
}) => {
  const { register, control, handleSubmit } = useFormContext();

  return (
    <div className={css.searchContainer}>
      <div className={cn('selectbox', 'type01', 'sizeS')}>
        <HFSelectBox
          control={control}
          name={'selectSearchCondition'}
          options={selectSearchOptions}
        />
      </div>

      <div className={css.inputWrap}>
        <form className={css.form}>
          <HFInput
            register={register}
            name={'keyword'}
            type={'text'}
            placeholder={'model.suffix, 정산 구분, 상품명'}
          />
          <Button
            styleType={'btn01'}
            subType={'type03'}
            className={cn('btn')}
            onClick={handleSubmit(onSearchClick)}
          >
            검색
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ProductListModifySearchArea;
