import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './AccountingTaxBillStepSecond.module.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { Button } from '@components/index';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import TableCheckbox from '@components/Table/TableCheckbox';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const AccountingTaxBillStepSecond = () => {
  return (
    <>
      <div className={cn('stepGuideText')}>
        2-1. 업체별 월 정산을 마감 확정 하시겠습니까?(확정하시면 level 1의
        취소가 있어야 재확정이 가능 합니다.)
      </div>

      <div className={cn('table_header')}>
        <div className={cn('box_left')}>
          <strong></strong>
        </div>
        <div className={cn('box_right')}>
          {/* Todo : 마감정산은 5일 지나면 자동 확정되도록 구현해야함 */}
          <Button type='button' styleType='btn03' subType='type01'>
            마감 정산 확정
          </Button>
          <Button type='button' styleType='btn03' subType='type04'>
            취소
          </Button>
        </div>
      </div>
    </>
  );
};

export default AccountingTaxBillStepSecond;
