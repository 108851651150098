import React, { useState, useCallback } from 'react';
import css from './Login.module.scss';
import classNames from 'classnames/bind';
import {
  PopUp,
  PopUpAlert,
  Button,
  TextButton,
  Input,
} from '@components/index';
import { useInput, useValidation } from '@hooks';
import RegisterForm from '../module/RegisterForm/RegisterForm';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, USER_SUB_PATH } from '@router/routerPath';
import { postLogin, postCreateInternalUser } from 'redux/action/loginAction';
import InternalUserSignUp from './InternalUserSignUp/InternalUserSignUp';
import {
  handlePreventRegex,
  backendMode,
  getGuideUrlByServerType,
  writeSessionStorage,
} from '@utils';
import { useEffect } from 'react';
import { refreshUserData, setLoginStatusFalse } from '@feature/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

// A0019 아이디를 입력해 주세요
// A0020 패스워드를 입력해 주세요
// A0021 아이디 / 패스워드가 일치 하지 않습니다.
// A0022 관리자 승인 후 사용 가능 합니다.
/**
 * @category components
 * @namespace Login
 * @description 로그인 페이지
 */

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpenSignUp, setIsOpenSignUp] = useState(false);
  const [isOpenFail, setIsOpenFail] = useState(false);

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, []);

  //Input의 value를 바꿔주기 위한 useInput hook
  const [id, setId, onChangeId] = useInput('');
  const [password, setPassword, onChangePassword] = useInput('');

  const idValidate = useValidation('id', id);
  const passwordValidate = useValidation('password', password, id);

  const isIdValid = /^[a-z0-9]*$/.test(id);

  const doLogin = () => {
    if (!id) {
      dispatch(openModalAlert({ message: '아이디를 입력해 주세요.' }));
    } else if (!password) {
      dispatch(openModalAlert({ message: '패스워드를 입력해 주세요.' }));
    } else if (!idValidate.isBoolean) {
      dispatch(openModalAlert({ message: '올바른 아이디를 입력해 주세요.' }));
    } else if (!passwordValidate.isBoolean) {
      dispatch(openModalAlert({ message: '올바른 비밀번호를 입력해 주세요.' }));
    } else {
      const params = {
        userId: id,
        password: password,
      };
      postLogin(dispatch, handleNavigate, params, setIsOpenFail);
    }
  };

  const handleCancel = () => {
    setIsOpenSignUp(false);
  };
  useEffect(() => {
    dispatch(setLoginStatusFalse(false));
    dispatch(refreshUserData());
  }, []);

  useEffect(() => {
    writeSessionStorage('sessionexpired', false);
  }, []);

  return (
    <>
      {process.env.REACT_APP_MODE === 'VENDOR' ? (
        <RegisterForm
          isLoginForm
          title={'로그인'}
          item={[
            <Input
              type='text'
              value={id.replace(/[^a-z0-9]/g, '')}
              name={id}
              onChange={onChangeId}
              placeholder='아이디를 입력해 주세요.'
              minLength={5}
              maxLength={12}
              required
            />,
            <Input
              type='password'
              value={password}
              name={password}
              onChange={onChangePassword}
              placeholder='비밀번호를 입력해 주세요.'
              minLength={8}
              maxLength={16}
              pattern='^[a-zA-Z0-9!@#$%^&*]*$'
              required
              onKeyDown={(e) => handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)}
            />,
          ]}
        >
          <div className={css.btn_container}>
            <Button
              onClick={doLogin}
              styleType={'btn01'}
              subType={'type01'}
              fullWidth
            >
              로그인
            </Button>
          </div>
          <div className={cn('register_link', 'single')}>
            <TextButton
              styleType={'btn_txt04'}
              onClick={() => handleNavigate(BASE_URL + USER_SUB_PATH.FIND)}
            >
              아이디/비밀번호 재설정
            </TextButton>
          </div>
          <div className={css.btn_container}>
            <Button
              onClick={() => handleNavigate(BASE_URL + USER_SUB_PATH.REGISTER)}
              styleType={'btn01'}
              subType={'type05'}
              fullWidth
            >
              회원가입
            </Button>
          </div>
          {/* <div className={cn('register_link', 'single')}>
            <TextButton
              styleType={'btn_txt04'}
              onClick={() => handleOpenGuide()}
            >
              회원 가입 가이드 다운로드
            </TextButton>
          </div> */}
        </RegisterForm>
      ) : (
        <>
          <RegisterForm
            isLoginForm
            title={'로그인'}
            item={[
              <>
                <Input
                  type='text'
                  value={id.replace(/[^a-z0-9]/g, '')}
                  name={id}
                  onChange={onChangeId}
                  placeholder='아이디를 입력해 주세요.'
                  minLength={5}
                  maxLength={12}
                  pattern='^[a-z0-9]*$'
                  title='영문 소문자와 숫자만 입력할 수 있습니다.'
                  required
                />
                {!isIdValid && (
                  <span className={cn('isIdValid')}>
                    영문 소문자와 숫자만 입력할 수 있습니다.
                  </span>
                )}
              </>,
              <Input
                type='password'
                value={password}
                name={password}
                onChange={onChangePassword}
                placeholder='비밀번호를 입력해 주세요.'
                minLength={8}
                maxLength={16}
                pattern='^[a-zA-Z0-9!@#$%^&*]*$'
                onKeyDown={(e) =>
                  handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)
                }
                required
              />,
            ]}
          >
            <div className={css.btn_container}>
              <Button
                onClick={doLogin}
                styleType={'btn01'}
                subType={'type01'}
                fullWidth
              >
                로그인
              </Button>
            </div>
            <div className={cn('register_link', 'single')}>
              <TextButton
                styleType={'btn_txt04'}
                onClick={() => handleNavigate(BASE_URL + USER_SUB_PATH.FIND)}
              >
                아이디/비밀번호 재설정
              </TextButton>
            </div>
            <div className={css.btn_container}>
              <Button
                onClick={() => setIsOpenSignUp(true)}
                styleType={'btn01'}
                subType={'type05'}
                fullWidth
              >
                접근 신청
              </Button>
            </div>

            <div className={cn('mt32', 'txtC', 'font-small')}>
              접근 승인 후 사이트 이용이 가능합니다.
              <br />
              문의 : lg-partnerlink@lge.com
            </div>
          </RegisterForm>
          <PopUp
            isOpenPopUp={isOpenSignUp}
            onRequestClose={() => setIsOpenSignUp(false)}
            closeButton={true}
            title={'계정 신청'}
          >
            <InternalUserSignUp handleCancel={handleCancel} />
          </PopUp>
        </>
      )}
      <PopUpAlert
        isOpenPopUp={isOpenFail}
        // TODO: blockingFlag 추가 시 title 조건식으로 수정
        // AuthorityAction에서 팝업 오픈 여부 추가
        title={'승인이 거절 되었습니다.'}
        button={[
          <Button
            onClick={() => setIsOpenFail(false)}
            subType={'type02'}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        <div className={css.mt16}>
          관리자에게 문의해주세요
          <br />
          lg-partnerlink@lge.com
          <br />
          02-3777-6979
        </div>
      </PopUpAlert>
    </>
  );
};

export default Login;
