import { Fragment, memo } from 'react';
import css from './VerticalTable.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace VerticalTable
 * @param {string} title VerticalTable 의 제목.
 * @param {boolean} isTitle VerticalTable 타이틀 존재 여부.
 * @param {boolean} isMultiRow VerticalTable 1행에 2가지 항목 여부.
 * @param {Array} multiTitle VerticalTable 1행에 2가지 제목.
 * @param {ReactNode[]} multiItem VerticalTable 1행에 2가지 아이템.
 * @param {boolean} hasSlash VerticalTable Slash 표시.
 * @param {boolean} isAuthNumber VerticalTable 인증 번호 입력 행 여부 (DXC className inputWrap type02).
 * @param {boolean} isPhoneType VerticalTable 휴대전화 Input type
 * @param {any} button VerticalTable 행의 button.
 * @param {any} children VerticalTable 의 자식 요소.
 * @param {string} hasTitleMinWidth VerticalTable 의 title이 최소 width를 가지는지 여부
 * @description 공통적으로 사용하는 VerticalTable 컴포넌트.
 */

const VerticalTable = ({
  isTitle,
  title,
  isMultiRow,
  multiTitle,
  multiItem,
  hasSlash,
  isAuthNumber,
  isPhoneType,
  required,
  button,
  children,
  hasTitleMinWidth,
  ...rest
}) => {
  return (
    <div {...rest}>
      <table className={css.input_table}>
        <colgroup>
          <col width='28%' />
        </colgroup>
        <tbody>
          <tr>
            {isMultiRow ? (
              <th>
                {multiTitle.map((tit, idx) => (
                  <Fragment key={idx}>
                    <span className={cn({ required })}>{tit}</span>
                    {multiTitle.length - 1 !== idx && <>{' / '}</>}
                  </Fragment>
                ))}
              </th>
            ) : (
              <>
                {isTitle && (
                  <th className={cn({ required, hasTitleMinWidth })}>
                    {title}
                  </th>
                )}
              </>
            )}
            <td>
              <div
                className={cn('inputWrap', {
                  hasSlash,
                  isAuthNumber,
                  isPhoneType,
                })}
              >
                {isMultiRow ? (
                  <>
                    {multiItem.map((item, idx) => (
                      <div key={idx} className={cn('inputText')}>
                        {item}
                      </div>
                    ))}
                    {button && <div>{button}</div>}
                  </>
                ) : (
                  <>
                    <div className={cn('inputText')}>{children}</div>
                    {button && <div>{button}</div>}
                  </>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

VerticalTable.defaultProps = {
  isMultiRow: false,
  multiTitle: [],
  multiItem: [],
  hasSlash: false,
  isAuthNumber: false,
  isPhoneType: false,
  required: false,
  isTitle: true,
  hasTitleMinWidth: false,
};

export default memo(VerticalTable);
