import { memo } from 'react';
import css from './TableContainer.module.scss';
import classNames from 'classnames/bind';
import { Fragment } from 'react';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace TableContainer
 * @param {ReactNode[]} button TableContainer 의 버튼 리스트.
 * @param {boolean} isCenter TableContainer 의 버튼 위치가 가운데인지 여부.
 * @param {any} children TableContainer 의 자식 요소.
 * @description VerticalTable에서 사용하는 TableContainer 컴포넌트.
 */

const TableContainer = ({ button, isCenter, children, rest }) => {
  return (
    <>
      <div className={cn('tableContainer')} {...rest}>
        {children}
      </div>
      <div className={cn('buttonBox', { isCenter })}>
        {button &&
          button.map((item, idx) => <Fragment key={idx}>{item}</Fragment>)}
      </div>
    </>
  );
};

TableContainer.defaultProps = {
  /** TableContainer 의 버튼 리스트 */
  button: [],
  /** TableContainer 의 버튼 위치가 가운데인지 여부 */
  isCenter: false,
};

export default memo(TableContainer);
