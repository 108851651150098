import React, { memo } from 'react';
import ReactDom from 'react-dom';

const Portal = ({ children }) => {
  const el = document.getElementById('root');

  return ReactDom.createPortal(children, el);
};

export default memo(Portal);
