import React, { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames/bind';
import css from './RefundAlarmDetailSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { useFormContext, useWatch } from 'react-hook-form';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';

const cn = classNames.bind(css);

const RefundAlarmDetailSearchArea = forwardRef(
  ({ messageTypeOptions }, ref) => {
    const { register, control, watch } = useFormContext();

    const radioSelectWatch = useWatch({
      control,
      name: 'radioAllOrFail',
      defaultValue: 'all',
    });

    useImperativeHandle(ref, () => ({
      getSelectBoxWatch: () => watch('messageTypeOptions', 'default'),
      getRadioAllOrFailWatch: () => watch('radioAllOrFail'),
    }));

    return (
      <>
        <div className={cn('inputCheckWrap')}>
          <HFRadioButton
            name={'radioAllOrFail'}
            register={register}
            id={`all`}
            value={`all`}
            defaultValue={radioSelectWatch}
          >
            전체
          </HFRadioButton>
          <HFRadioButton
            name={'radioAllOrFail'}
            register={register}
            id={`fail`}
            value={`fail`}
          >
            실패
          </HFRadioButton>
        </div>

        <div className={cn('selectbox', 'type01', 'ml20')}>
          <HFSelectBox
            control={control}
            name={'messageTypeOptions'}
            options={messageTypeOptions}
            disabled={radioSelectWatch === 'all'}
          />
        </div>
      </>
    );
  }
);

export default RefundAlarmDetailSearchArea;
