import React, { useState, useEffect } from 'react';
import css from './Terms.module.scss';
import classNames from 'classnames/bind';
import { Checkbox, Button } from '@components';
import { updateTerms } from '@feature/signUp/signUpSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TERMS_LIST } from '@data/Terms';

const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Terms
 * @param {Function} handleClickStepNext 회원가입 Terms 이전 단계로 이동하는 콜백함수
 * @param {Function} handleClickStepPrev 회원가입 Terms 다음 단계로 이동하는 콜백함수
 * @param {boolean} isPopUpMode 메인화면 Terms 팝업 노출 여부.
 * @param {Function} setIsOpenTerms 메인화면 Terms 팝업 노출 여부.
 * @description 약관동의(회원가입 / 메인화면) 컴포넌트.
 */

const AGREE_MOCK_DATA = {
  agreedTerms: false,
  agreedPrivacy: false,
  agreedPolicy: false,
};

const Terms = ({
  handleClickStepNext,
  handleClickStepPrev,
  isPopUpMode,
  setIsOpenTerms,
}) => {
  const dispatch = useDispatch();
  const [agreed, setAgreed] = useState(AGREE_MOCK_DATA);
  const [allAgreed, setAllAgreed] = useState(false);

  const handleSave = () => {
    dispatch(
      updateTerms({
        agreedTerms: agreed.agreedTerms,
        agreedPrivacy: agreed.agreedPrivacy,
        agreedPolicy: agreed.agreedPolicy,
      })
    );
    isPopUpMode ? setIsOpenTerms(false) : handleClickStepNext();
  };

  const handleCancel = () => {
    isPopUpMode ? setIsOpenTerms(false) : handleClickStepPrev();
  };

  // 약관 단일 동의
  const handleAgreedChange = (e) => {
    const { name, checked } = e.target;
    // agreed 객체에 checked(boolean) 넣어 준다.
    setAgreed((prev) => ({ ...prev, [name]: checked }));
    // every : agreed의 모든 value 값이 true이면 true 리턴.
    const allChecked = Object.values({ ...agreed, [name]: checked }).every(
      (value) => value === true
    );
    // true가 된 allChecked 값을 allAgree state에 할당한다.
    setAllAgreed(allChecked);
  };

  // 약관 전체 동의
  const handleAllAgreedChange = (e) => {
    const { checked } = e.target;
    setAgreed((prev) =>
      Object.keys(prev).reduce(
        // newAgree: 누적되는 값, 최종적으로 출력되는 값.(accumulator)
        // agreeKey: 배열의 현재 요소.(current)
        (newAgree, agreeKey) => ({
          ...newAgree,
          [agreeKey]: checked,
        }),
        {} // acc를 object로 선언 (초기 값)
      )
    );
    setAllAgreed(checked);
  };

  return (
    <>
      <div className={cn('pageSummary')}>
        이용약관, 개인정보 수집 및 이용, 위치정보 이용약관(선택)에 모두 동의
        합니다.
        <Checkbox
          id='agree_check_all'
          name='agree_check_all'
          checked={allAgreed}
          onChange={handleAllAgreedChange}
          noLabel
        ></Checkbox>
      </div>

      <div className={cn('termsWrap')}>
        <div className={cn('title')}>
          <strong>이용 약관 동의 (필수)</strong>
          <Checkbox
            id='agree_check_terms'
            name='agreedTerms'
            checked={agreed.agreedTerms}
            onChange={handleAgreedChange}
            noLabel
          />
        </div>
        <div className={cn('termsContents')}>
          {TERMS_LIST.term01}
        </div>
      </div>

      <div className={cn('termsWrap')}>
        <div className={cn('title')}>
          <strong>개인 정보 수집 및 이용 약관 동의 (필수)</strong>
          <Checkbox
            id='agree_check_privacy'
            name='agreedPrivacy'
            checked={agreed.agreedPrivacy}
            onChange={handleAgreedChange}
            noLabel
          />
        </div>
        <div className={cn('termsContents')}>
          {TERMS_LIST.term02}
        </div>
      </div>

      <div className={cn('termsWrap')}>
        <div className={cn('title')}>
          <strong>개인정보 처리방침 (필수)</strong>
          <Checkbox
            id='agree_check_policy'
            name='agreedPolicy'
            checked={agreed.agreedPolicy}
            onChange={handleAgreedChange}
            noLabel
          />
        </div>
        <div className={cn('termsContents')}>
          {TERMS_LIST.term03}
        </div>
      </div>

      <div className={cn('btn_container4')}>
        {!isPopUpMode && (
          <div className={css.btn}>
            <Button
              key='cancel'
              subType='type03'
              onClick={handleCancel}
              fullWidth
            >
              취소
            </Button>
          </div>
        )}
        <div className={css.btn}>
          <Button
            key='save'
            onClick={handleSave}
            disabled={!(agreed.agreedTerms && agreed.agreedPrivacy && agreed.agreedPolicy)}
            fullWidth
          >
            저장
          </Button>
        </div>
      </div>
    </>
  );
};

export default Terms;
