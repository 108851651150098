import { memo } from 'react';
import css from './ToggleButton.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Checkbox
 * @param {string} id ToggleButton 의 고유 id.
 * @param {boolean} checked ToggleButton 체크 여부. (고유 속성)
 * @param {string} children ToggleButton 의 자식 요소.
 * @description 공통적으로 사용하는 ToggleButton 컴포넌트.
 */

const ToggleButton = ({ id, children, ...rest }) => {
  return (
    <div className={cn('toggleBox')}>
      <input id={id} type='checkbox' {...rest} />
      <label htmlFor={id} className={css.label}>
        <span>{children}</span>
      </label>
    </div>
  );
};

ToggleButton.defaultProps = {
  /** ToggleButton 체크 여부 */
  checked: false,
};

export default memo(ToggleButton);
