import { createSlice } from '@reduxjs/toolkit';
import { convertDateToString3 } from '@utils';

const initialState = {
  settlementDetails: [],
  settlementDashboard: {},
  settlementApprovalStatus: {
    adminApprovalStatus: 'N',
    level3ApprovalStatus: 'N',
  },
  settlementTargetStatusInfo: {
    settlementTargetInfo: {
      totalCnt: 0,
      focCnt: 0,
      focSellTotal: 0,
      nfocCnt: 0,
      nfocSellTotal: 0,
    },
    settlementApprovalInfo: {
      totalCnt: 0,
      focCnt: 0,
      nfocCnt: 0,
    },
  },
  salesSettlementList: {
    foc: {},
    nfoc: {},
  },
  entireAdjustmentList: [],
  externalProcessDashboard: {
    taxBillRequestCompleteCnt: 0,
    gevsNotCompleteCnt: 0,
    gevsCompleteCnt: 0,
    taxBillResponseCompleteCnt: 0,
    gerpNotCompleteCnt: 0,
    apmsCompleteCompleteCnt: 0,
    apmsCompleteNotCompleteCnt: 0,
    gerpCompleteCnt: 0,
    taxBillResponseNotCompleteCnt: 0,
    taxBillRequestNotCompleteCnt: 0,
  },
  settlementTotal: {
    SettlementNFOCTotal: [
      {
        salesApproveLevel3: 'N',
        sellTotal: 0,
        dataType: '원거래',
        promotionTypeDesc: '유상',
        settlementMonth: convertDateToString3(new Date()),
        marginTotal: 0,
        netTotal: 0,
        sellCnt: 0,
        salesApproveAdmin: 'N',
      },
      {
        salesApproveLevel3: 'N',
        sellTotal: 0,
        dataType: '매출 조정',
        promotionTypeDesc: '유상',
        settlementMonth: convertDateToString3(new Date()),
        marginTotal: 0,
        netTotal: 0,
        sellCnt: 0,
        salesApproveAdmin: 'N',
      },
    ],
    SettlementFOCTotal: [
      {
        salesApproveLevel3: 'N',
        dataType: '원거래',
        promotionTypeDesc: '무상',
        settlementMonth: convertDateToString3(new Date()),
        netTotal: 0,
        sellCnt: 0,
        salesApproveAdmin: 'N',
      },
      {
        salesApproveLevel3: 'N',
        dataType: '매출 조정',
        promotionTypeDesc: '무상',
        settlementMonth: convertDateToString3(new Date()),
        netTotal: 0,
        sellCnt: 0,
        salesApproveAdmin: 'N',
      },
    ],
  },
  adjustmentResult: {
    deliveryAddTotal: 0,
    entireExcludeTotal: 0,
    couponExcludeTotal: 0,
    entireAddTotal: 0,
    couponAddCnt: 0,
    couponExcludeCnt: 0,
    deliveryExcludeCnt: 0,
    deliveryExcludeTotal: 0,
    deliveryAddCnt: 0,
    entireCnt: 0,
    couponAddTotal: 0,
    adjustmentTotal: 0,
  },
  targetMerchandise: [],
  settlementHistory: {
    sellTotal: 0,
    dataType: '원거래',
    promotionTypeDesc: '무상',
    settlementMonth: '2024-02',
    netTotal: 0,
    marginTotal: 0,
    vendorName: '대리주부',
    sellCnt: 0,
  },
};

const settlement = createSlice({
  name: 'settlement',
  initialState,
  reducers: {
    getSettlementDetails: (state, action) => {
      state.settlementDetails = action.payload;
    },
    getSettlementDashboard: (state, action) => {
      console.log('###action.payload', action.payload);
      state.settlementDashboard = action.payload;
    },
    getSettlementTargetStatusInfo: (state, action) => {
      state.settlementTargetStatusInfo = action.payload;
    },
    getEntireAdjustmentList: (state, action) => {
      state.entireAdjustmentList = action.payload;
    },
    getAdjustmentResult: (state, action) => {
      state.adjustmentResult = action.payload;
    },
    getSettlementTotal: (state, action) => {
      state.settlementTotal = action.payload;
    },
    getSettlementApprovalStatus: (state, action) => {
      state.settlementApprovalStatus = action.payload;
    },
    getExternalProcessDashboard: (state, action) => {
      state.externalProcessDashboard = action.payload;
    },
    getSalesSettlementListFOC: (state, action) => {
      state.salesSettlementList.foc = action.payload;
    },
    getSalesSettlementListNFOC: (state, action) => {
      state.salesSettlementList.nfoc = action.payload;
    },
    setSettlementTargetMerchandise: (state, action) => {
      if (action.payload === 'reset') {
        state.targetMerchandise = [];
      } else {
        state.targetMerchandise.push(action.payload);
        state.targetMerchandise = [...state.targetMerchandise];
      }
    },
    getSettlementHistory: (state, action) => {
      state.settlementHistory = action.payload;
    },
  },
});

export const {
  getSettlementDetails,
  getSettlementDashboard,
  getSettlementTargetStatusInfo,
  getEntireAdjustmentList,
  getAdjustmentResult,
  getSettlementTotal,
  getSettlementApprovalStatus,
  getExternalProcessDashboard,
  getSalesSettlementListFOC,
  getSalesSettlementListNFOC,
  setSettlementTargetMerchandise,
  getSettlementHistory,
} = settlement.actions;

export default settlement.reducer;
