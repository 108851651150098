import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import css from './Find.module.scss';
import {
  Button,
  Input,
  PopUp,
  PopUpAlert,
  VerticalTable,
  VerticalTableContainer,
} from '@components/index';
import { useInput, useInputMultiple, useValidation } from '@hooks';
import RegisterForm from '../module/RegisterForm/RegisterForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFindVendorUserId,
  getFindInternallUserId,
  getCheckUserExist,
} from 'redux/action/registerAction';

import {
  postChangeUserPassword,
  getCheckUserAuthenticationByEmail,
} from 'redux/action/userAction';
import * as Utils from '@utils';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

// A0033 정보를 확인해주세요
// A0096 정보가 일치합니다

/**
 * @category components
 * @namespace Find
 * @description 아이디/비밀번호 찾기 페이지
 */

const Find = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.signUp.userId);
  const [passwordCheck, setPassWordCheck] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenFail, setIsOpenFail] = useState(false);
  const [failPopUpMsg, setFailPopUpMsg] = useState('');

  const [requiredPasswordMessage, setRequiredPasswordMessage] = useState('');

  const [phoneNumber, setPhoneNumber, onChangePhoneNumber] = useInputMultiple({
    phoneNum1: '010',
    phoneNum2: '',
    phoneNum3: '',
  });

  const { phoneNum1, phoneNum2, phoneNum3 } = phoneNumber;

  // 아이디 / 비밀번호 찾기
  const [
    companyRegistrationNumber,
    setCompanyRegistrationNumber,
    onChangeCompanyRegistrationNumber,
  ] = useInput('');
  const [companyName, setcompanyName, onChangeCompanyName] = useInput('');
  const [email, setEmail, onChangeEmail] = useInput('');

  // 비밀 번호 재설정 관련
  const [
    companyRegistrationNumberPwd,
    setCompanyRegistrationNumberPwd,
    onChangeCompanyRegistrationNumberPwd,
  ] = useInput('');
  const [userIdPwd, setUserIdPwd, onChangeUserIdPwd] = useInput('');
  const [emailPwd, setEmailPwd, onChangeEmailPwd] = useInput('');
  const [companyNamePwd, setCompanyNamePwd, onChangeCompanyNamePwd] =
    useInput('');

  // 유효성 확인 버튼이 눌렸는지 여부를 바꿔주는 함수
  const handlePasswordChecked = (checked) => {
    setPassWordCheck(checked);
  };
  const [newPassword, setNewPassword, onChangeNewPassword] = useInput(
    '',
    handlePasswordChecked
  );
  const [
    confirmedNewPassword,
    setConfirmedNewPassword,
    onChangeConfirmedNewPassword,
  ] = useInput('');

  const [phoneNumberPwd, setPhoneNumberPwd, onChangePhoneNumberPwd] =
    useInputMultiple({
      phoneNumPwd1: '010',
      phoneNumPwd2: '',
      phoneNumPwd3: '',
    });
  const { phoneNumPwd1, phoneNumPwd2, phoneNumPwd3 } = phoneNumberPwd;

  const [isOpenModifyPassword, setIsOpenModifyPassword] = useState(false);

  const passwordValidate = useValidation('password', newPassword);

  // 비밀번호 유효성 체크
  const handleValidation = (check) => {
    setPassWordCheck(true);
    return dispatch(openModalAlert({ message: check.message }));
  };

  const blurEvent = (condition1, condition2, message, setFunc) => {
    if (condition1 === '' || condition2 === '') {
      setFunc(message);
    } else {
      setFunc('');
    }
  };

  // 아이디 찾기 Api 함수
  const handleFindUserId = () => {
    const params = {
      phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
      companyBizName: companyName,
      companyRegNo: companyRegistrationNumber,
    };

    if (companyRegistrationNumber && companyName) {
      getFindVendorUserId(
        dispatch,
        params,
        setIsOpenConfirm,
        setIsOpenFail,
        setFailPopUpMsg
      );
    } else dispatch(openModalAlert({ message: '정보를 확인해주세요' }));
  };

  // 아이디 찾기(내부망) Api 함수
  const handleFindInternalUserId = () => {
    const params = {
      email: email,
    };

    if (email) {
      getFindInternallUserId(
        dispatch,
        params,
        setIsOpenConfirm,
        setIsOpenFail,
        setFailPopUpMsg
      );
    } else dispatch(openModalAlert({ message: '정보를 확인해주세요' }));
  };

  const maskingUserId = Utils.replaceUserId(userId, '*');

  const handleCancelSaveNewPassword = () => {
    setIsOpenModifyPassword(false);
    setNewPassword('');
    setConfirmedNewPassword('');
  };

  // 비밀번호 변경 전 검증 Api 함수
  const handleOpenPwdReset = () => {
    const params = {
      userId: userIdPwd,
      phoneNo: `${phoneNumPwd1}-${phoneNumPwd2}-${phoneNumPwd3}`,
      companyBizName: companyNamePwd,
      companyRegNo: companyRegistrationNumberPwd,
    };

    if (
      !companyRegistrationNumberPwd ||
      !companyNamePwd ||
      !phoneNumPwd1 ||
      !phoneNumPwd2 ||
      !phoneNumPwd3 ||
      !userIdPwd
    ) {
      dispatch(openModalAlert({ message: '정보를 확인해주세요' }));
    } else getCheckUserExist(dispatch, params, setIsOpenModifyPassword);
  };

  // 비밀번호 변경 전 검증(내부망) Api 함수
  const handleOpenPwdResetInternall = () => {
    const params = {
      userId: userIdPwd,
      email: emailPwd,
    };

    if (!userIdPwd || !emailPwd) {
      dispatch(openModalAlert({ message: '정보를 확인해주세요' }));
    } else
      getCheckUserAuthenticationByEmail(
        dispatch,
        params,
        setIsOpenModifyPassword
      );
  };

  // 비밀번호 변경 Api 함수
  const handleSaveNewPassword = () => {
    const params = {
      userId: userId,
      password: confirmedNewPassword,
    };

    if (!newPassword || !confirmedNewPassword) {
      dispatch(openModalAlert({ message: '정보를 확인해주세요(입력)' }));
    } else if (confirmedNewPassword === userId) {
      dispatch(
        openModalAlert({
          message: '사용 불가능한 패스워드 입니다.(아이디와 동일)',
        })
      );
    } else if (newPassword.length < 8 || newPassword.length > 16) {
      dispatch(
        openModalAlert({ message: '글자수를 확인 해주세요. 8 ~ 16자리' })
      );
    } else if (newPassword !== confirmedNewPassword) {
      dispatch(openModalAlert({ message: '비밀번호가 일치 하지 않습니다.' }));
    } else {
      postChangeUserPassword(dispatch, params, setFailPopUpMsg, setIsOpenFail);
      setIsOpenModifyPassword(false);
      setConfirmedNewPassword('');
      setNewPassword('');
    }
  };

  return (
    <>
      <RegisterForm
        title={'아이디 찾기'}
        hasTitleMargin
        item={
          process.env.REACT_APP_MODE === 'VENDOR'
            ? [
                <Input
                  type='text'
                  value={companyRegistrationNumber.replace(/[^0-9]/g, '')}
                  name={companyRegistrationNumber}
                  onChange={onChangeCompanyRegistrationNumber}
                  placeholder='사업자 등록번호 10자리를 입력해 주세요. (-제외)'
                  maxLength={10}
                />,
                <Input
                  type='text'
                  value={companyName}
                  name={companyName}
                  onChange={onChangeCompanyName}
                  placeholder='상호명을 입력해주세요.'
                />,
                <>
                  <div className={cn('flex', 'align-center-gap8')}>
                    <Input
                      type='text'
                      value={phoneNum1.replace(/[^0-9]/g, '')}
                      name={'phoneNum1'}
                      onChange={onChangePhoneNumber}
                      minLength={3}
                      maxLength={3}
                      required={true}
                      pattern='^[0-9]+$'
                      readOnly
                    />
                    -
                    <Input
                      type='text'
                      value={phoneNum2.replace(/[^0-9]/g, '')}
                      name={'phoneNum2'}
                      onChange={onChangePhoneNumber}
                      minLength={4}
                      maxLength={4}
                      required={true}
                      pattern='^[0-9]+$'
                    />
                    -
                    <Input
                      type='text'
                      value={phoneNum3.replace(/[^0-9]/g, '')}
                      name={'phoneNum3'}
                      onChange={onChangePhoneNumber}
                      minLength={4}
                      maxLength={4}
                      required={true}
                      pattern='^[0-9]+$'
                    />
                  </div>
                  <div className={cn('note')}>
                    등록된 휴대폰 번호를 입력해주세요.
                  </div>
                </>,
              ]
            : [
                <Input
                  type='text'
                  value={email}
                  name={email}
                  onChange={onChangeEmail}
                  placeholder='가입시 등록한 LGE 이메일을 입력 해주세요.'
                />,
              ]
        }
      >
        <div className={css.btn_container}>
          <div className={css.btn}>
            <Button
              onClick={
                process.env.REACT_APP_MODE === 'VENDOR'
                  ? handleFindUserId
                  : handleFindInternalUserId
              }
              fullWidth
            >
              조회
            </Button>
          </div>
        </div>
        <h2 className={cn('pageTitle2', 'mb60', 'mt80')}>비밀번호 재설정</h2>
        <VerticalTableContainer
          button={[
            <div className={css.btn_container}>
              <div className={css.btn}>
                <Button
                  key='setting'
                  onClick={
                    process.env.REACT_APP_MODE === 'VENDOR'
                      ? handleOpenPwdReset
                      : handleOpenPwdResetInternall
                  }
                  fullWidth
                >
                  변경
                </Button>
              </div>
            </div>,
          ]}
          isCenter
        >
          {process.env.REACT_APP_MODE === 'VENDOR' ? (
            <>
              <VerticalTable isTitle={false}>
                <Input
                  type='text'
                  value={companyRegistrationNumberPwd.replace(/[^0-9]/g, '')}
                  name={companyRegistrationNumberPwd}
                  onChange={onChangeCompanyRegistrationNumberPwd}
                  placeholder='사업자 등록번호 10자리를 입력해 주세요. (-제외)'
                  maxLength={10}
                />
              </VerticalTable>
              <VerticalTable isTitle={false}>
                <Input
                  type='text'
                  value={companyNamePwd}
                  name={companyNamePwd}
                  onChange={onChangeCompanyNamePwd}
                  minLength={11}
                  maxLength={12}
                  required={true}
                  placeholder={'상호명을 입력해주세요.'}
                />
              </VerticalTable>
              <VerticalTable isTitle={false}>
                <Input
                  type='text'
                  value={userIdPwd}
                  name={userIdPwd}
                  onChange={onChangeUserIdPwd}
                  minLength={11}
                  maxLength={12}
                  required={true}
                  placeholder={'아이디를 입력해주세요.'}
                />
              </VerticalTable>
              <VerticalTable isTitle={false}>
                <div className={cn('flex', 'align-center-gap8')}>
                  <Input
                    type='text'
                    value={phoneNumPwd1.replace(/[^0-9]/g, '')}
                    name={'phoneNumPwd1'}
                    onChange={onChangePhoneNumberPwd}
                    minLength={3}
                    maxLength={3}
                    required={true}
                    pattern='^[0-9]+$'
                    readOnly
                  />
                  -
                  <Input
                    type='text'
                    value={phoneNumPwd2.replace(/[^0-9]/g, '')}
                    name={'phoneNumPwd2'}
                    onChange={onChangePhoneNumberPwd}
                    minLength={4}
                    maxLength={4}
                    required={true}
                    pattern='^[0-9]+$'
                  />
                  -
                  <Input
                    type='text'
                    value={phoneNumPwd3.replace(/[^0-9]/g, '')}
                    name={'phoneNumPwd3'}
                    onChange={onChangePhoneNumberPwd}
                    minLength={4}
                    maxLength={4}
                    required={true}
                    pattern='^[0-9]+$'
                  />
                </div>
                <div className={cn('note')}>
                  등록된 전화번호를 입력해주세요.
                </div>
              </VerticalTable>
            </>
          ) : (
            <>
              <VerticalTable isTitle={false}>
                <Input
                  type='text'
                  value={emailPwd}
                  name={emailPwd}
                  onChange={onChangeEmailPwd}
                  placeholder='가입시 등록한 LGE 이메일을 입력 해주세요.'
                />
              </VerticalTable>
              <VerticalTable isTitle={false}>
                <Input
                  type='text'
                  value={userIdPwd.replace(/[^a-z0-9]/g, '')}
                  name={userIdPwd}
                  onChange={onChangeUserIdPwd}
                  minLength={11}
                  maxLength={12}
                  required={true}
                  placeholder={'아이디를 입력해주세요.'}
                />
              </VerticalTable>
            </>
          )}
        </VerticalTableContainer>
      </RegisterForm>
      <PopUp
        title='비밀번호 재설정'
        isOpenPopUp={isOpenModifyPassword}
        onRequestClose={() => setIsOpenModifyPassword(false)}
        closeButton
      >
        <div>
          <div>변경 할 비밀번호를 입력해주세요.</div>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <div className={css.btn}>
                  <Button
                    key='cancel'
                    onClick={handleCancelSaveNewPassword}
                    subType='type03'
                    fullWidth
                  >
                    취소
                  </Button>
                </div>
                <div className={css.btn}>
                  <Button key='save' onClick={handleSaveNewPassword} fullWidth>
                    저장
                  </Button>
                </div>
              </div>,
            ]}
            isCenter
          >
            <VerticalTable
              isTitle={false}
              button={
                <Button
                  onClick={() => handleValidation(passwordValidate)}
                  type='button'
                  styleType={'btn04'}
                >
                  유효성 확인
                </Button>
              }
            >
              <Input
                type='password'
                value={newPassword}
                name={newPassword}
                onChange={onChangeNewPassword}
                minLength={8}
                maxLength={16}
                required={true}
                placeholder={'신규 비밀번호를 입력해 주세요'}
                onBlur={() =>
                  blurEvent(
                    newPassword,
                    'default',
                    '비밀번호를 입력해 주세요',
                    setRequiredPasswordMessage
                  )
                }
                confirmedMessage={passwordCheck && passwordValidate.isBoolean}
                errormessage={requiredPasswordMessage}
                onKeyDown={(e) =>
                  Utils.handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)
                }
              />
            </VerticalTable>
            <VerticalTable isTitle={false}>
              <Input
                type='password'
                value={confirmedNewPassword}
                name={confirmedNewPassword}
                onChange={onChangeConfirmedNewPassword}
                minLength={8}
                maxLength={16}
                placeholder={'비밀번호를 다시 입력해 주세요'}
                onKeyDown={(e) =>
                  Utils.handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)
                }
                disabled={!newPassword}
              />
            </VerticalTable>
          </VerticalTableContainer>
        </div>
      </PopUp>
      <PopUpAlert
        title={'아이디 찾기 완료'}
        isOpenPopUp={isOpenConfirm}
        onRequestClose={() => setIsOpenConfirm(false)}
        closeButton={false}
        button={[
          <Button
            key='confirmBtn'
            onClick={() => {
              setIsOpenConfirm(false);
            }}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        <div className={cn('mt32')}>
          구독 제휴사 Admin 아이디 찾기 결과입니다.
        </div>
        <div className={cn('text_box1', 'mt20')}>
          고객님의 아이디는{' '}
          <strong className={cn('color-primary')}>{maskingUserId}</strong>{' '}
          입니다.
        </div>
        <div className={cn('font-small', 'mt20')}>
          아이디는 앞자리 중 일부분만 표시되며,
          <br />
          별표(*)는 아이디 자릿수와 무관하게 표시됩니다.
          <br />
        </div>
        <div className={cn('color-grey', 'mt20')}>
          문의 사항은 아래 담당자에게 연락하시기 바랍니다.
        </div>
        <div className={cn('font-small', 'mt20')}>
          구독 제휴사 Admin
          <br />
          lg-partnerlink@lge.com
          <br />
          02-3777-6979, 02-3777-6980
        </div>
      </PopUpAlert>
      <PopUpAlert
        title={failPopUpMsg}
        isOpenPopUp={isOpenFail}
        onRequestClose={() => setIsOpenFail(false)}
        closeButton={false}
        button={[
          <Button
            key='confirmBtn'
            subType={'type03'}
            onClick={() => {
              setIsOpenFail(false);
            }}
            fullWidth
          >
            닫기
          </Button>,
        ]}
      >
        <div className={cn('text_box1', 'mt20')}>
          입력 하신 정보와 일치하는 아이디가 없습니다.
        </div>
        <div className={cn('font-small', 'mt20')}>
          ※ 가입 정보 확인이 필요하시면 아래 CS센터로 문의주세요
          <br />
          구독 제휴사 Admin
          <br />
          lg-partnerlink@lge.com
          <br />
          02-3777-6979, 02-3777-6980
        </div>
      </PopUpAlert>
    </>
  );
};

export default Find;
