import React, { useEffect } from 'react';
import css from './Register.module.scss';
import classNames from 'classnames/bind';
import RegisterStep from './RegisterStep/RegisterStep';
import RegisterContainer from './module/RegisterContainer/RegisterContainer';

const cn = classNames.bind(css);

const Register = () => {
  return (
    <RegisterContainer>
      <RegisterStep />
    </RegisterContainer>
  );
};

export default Register;
