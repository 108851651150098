import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import css from './RefundAlarmDetailPopup.module.scss';
import classNames from 'classnames/bind';
import { Button, Input, PopUp } from '@components/index';
import { FormProvider, useForm } from 'react-hook-form';
import RefundAlarmDetailSearchArea from './RefundAlarmDetailSearchArea';
import TableCheckbox from '@components/Table/TableCheckbox';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { createColumnHelper } from '@tanstack/react-table';
import { useInputMultiple } from '@hooks/index';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getRefundTalkdreamResultDetailList } from 'redux/action/refundAction';

const cn = classNames.bind(css);

const messageTypeOptions = {
  guide: '',
  list: [
    { id: '전체', title: '전체' },
    { id: '알림톡', title: '알림톡' },
    { id: 'LMS', title: 'LMS' },
  ],
};

const sendingDetailMockData = [
  {
    알림요청일시: '2023-10-01 23:00',
    업체명: '런드리고',
    수신자명: '홍길동',
    계약번호: '123456',
    서비스명: '런드리고 세탁 패키지',
    쿠폰명: '런드리고 세탁서비스 이용권',
    휴대폰번호: '010-1234-1234',
    알림톡결과: '성공',
    LMS결과: '',
  },
  {
    알림요청일시: '2023-10-02 10:30',
    업체명: 'ABC Company',
    수신자명: '아이유',
    계약번호: '789012',
    서비스명: 'ABC 서비스',
    쿠폰명: '추가 혜택 쿠폰',
    휴대폰번호: '010-5678-5678',
    알림톡결과: '실패',
    LMS결과: '발송 중',
  },
];

const RefundAlarmDetailPopup = ({ resultDetailListParam }) => {
  const dispatch = useDispatch();
  const searchForms = useForm();
  const columnHelper = createColumnHelper();
  const [alarmResultDetail, setAlarmResultDetail] = useState([]);
  const [isOpenChangeNumberPopUp, setIsOpenChangeNumberPopUp] = useState(false);
  const refundTalkdreamResultDetail = useSelector(
    (state) => state.refund.refundTalkedreamResultDetailList
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  //Todo: mock Data 변경
  const phoneNo = '010-1111-1111';
  const userPhoneNumber = phoneNo.split('-');

  //변경전 전화번호
  const [
    currentPhoneNumber,
    setcurrentPhoneNumber,
    onChangecurrentPhoneNumber,
  ] = useInputMultiple({
    currentPhoneNum1: userPhoneNumber[0],
    currentPhoneNum2: userPhoneNumber[1],
    currentPhoneNum3: userPhoneNumber[2],
  });
  const { currentPhoneNum1, currentPhoneNum2, currentPhoneNum3 } =
    currentPhoneNumber;

  const [phoneNumber, setPhoneNumber, onChangePhoneNumber] = useInputMultiple({
    phoneNumCheck1: '010',
    phoneNumCheck2: '',
    phoneNumCheck3: '',
  });

  const { phoneNum1, phoneNum2, phoneNum3 } = phoneNumber;

  //Todo: 추후 결과선택 전체 실패에 따라 filter필요
  const [sendingDetailFiltered, setSendingDetailFiltered] = useState(
    sendingDetailMockData
  );

  const alarmDetailSearchRef = useRef({
    getSelectBoxWatch: () => {},
    getRadioAllOrFailWatch: () => {},
  });

  const selectBoxWatchValue = alarmDetailSearchRef.current.getSelectBoxWatch();
  const radioAllOrFailValue =
    alarmDetailSearchRef.current.getRadioAllOrFailWatch();

  useEffect(() => {
    const fetchResultDetailListData = async () => {
      //FIXME: totalCount 가 없어서 pagenation안됨
      const params = {
        requestDatetime: resultDetailListParam?.requestDatetime,
        vendorKey: resultDetailListParam?.vendorKey,
        category: resultDetailListParam?.category,
        sendMethod:
          selectBoxWatchValue === 'default' ? '' : selectBoxWatchValue,
        sendResult: radioAllOrFailValue === 'all' ? '' : 'FAILED',
        limit: '100',
        page: page ?? '1',
      };

      await dispatch(getRefundTalkdreamResultDetailList(params));
    };

    if (radioAllOrFailValue && selectBoxWatchValue) {
      fetchResultDetailListData();
    }
  }, [
    resultDetailListParam,
    page,
    limit,
    selectBoxWatchValue,
    radioAllOrFailValue,
  ]);

  const selectedRowCallback = useCallback((rows) => {
    let messageKeyArr = [];
    rows.forEach((row) => {
      return messageKeyArr.push(row);
    });
    setAlarmResultDetail(messageKeyArr);
  }, []);

  const onClickPhoneNumber = useCallback(() => {
    setIsOpenChangeNumberPopUp(!isOpenChangeNumberPopUp);
  }, []);

  const sendingDetailcolumns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-auth-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('requestDatetime', {
        id: 'requestDatetime',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림 요청일시</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('receiverName', {
        id: 'receiverName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신자 명</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약번호</span>,
      }),
      columnHelper.accessor('service', {
        id: 'service',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>서비스명</span>,
      }),
      columnHelper.accessor('쿠폰명', {
        id: '쿠폰명',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰명</span>,
      }),
      columnHelper.accessor('receiverMobileNo', {
        id: 'receiverMobileNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>휴대폰번호</span>,
      }),
      columnHelper.accessor('kakaoResult', {
        id: 'kakaoResult',
        cell: (info) => {
          switch (info.getValue()) {
            case '성공':
              return <span>성공</span>;
            case '실패':
              return <span className={cn('color-primary')}>실패</span>;
            case '':
              return <span>-</span>;
            default:
              return info.getValue();
          }
        },
        header: () => <span>알림톡 결과</span>,
      }),
      columnHelper.accessor('lmsResult', {
        id: 'lmsResult',
        cell: (info) => {
          switch (info.getValue()) {
            case '성공':
              return <span>성공</span>;
            case '실패':
              return <span className={cn('color-primary')}>실패</span>;
            case '':
              return <span>-</span>;
            default:
              return info.getValue();
          }
        },
        header: () => <span>LMS 결과</span>,
      }),
    ],
    []
  );

  return (
    <div className={cn('popup')}>
      <strong className={cn('popup_title')}>알림 발송 결과 상세</strong>
      <div className={cn('popup_body')}>
        <div className={cn('searchOptionArea')}>
          <strong className={cn('title')}>결과 선택</strong>
          <FormProvider {...searchForms}>
            <RefundAlarmDetailSearchArea
              messageTypeOptions={messageTypeOptions}
              ref={alarmDetailSearchRef}
            />
          </FormProvider>
        </div>
        <div className={cn('btn_container6', 'mt32')}>
          <Button
            disabled={
              !alarmResultDetail.length > 0 || alarmResultDetail.length == 1
            }
            className={cn('btn', 'btn01', 'type04')}
          >
            대량 재발송
          </Button>
          <Button
            disabled={alarmResultDetail.length !== 1}
            className={cn('btn', 'btn01', 'type04')}
          >
            단건 수정 발송
          </Button>
        </div>
        <TableV8ManualPage
          columns={sendingDetailcolumns}
          data={sendingDetailMockData}
          hasCsvDownload={false}
          getRowselected={selectedRowCallback}
          // page={page}
          // limit={limit}
          // totalLength={couponOverallStatusTotalCount}
        />
      </div>
      <PopUp
        isOpenPopUp={isOpenChangeNumberPopUp}
        onRequestClose={() => setIsOpenChangeNumberPopUp(false)}
        closeButton
        button={[
          <div className={cn('btn_container4')}>
            <Button
              key='alertConfirm'
              subType={'type03'}
              className={cn('btn', 'btn01')}
              onClick={() => setIsOpenChangeNumberPopUp(false)}
              fullWidth
            >
              취소
            </Button>
            <Button
              key='alertConfirm'
              subType={'type02'}
              className={cn('btn', 'btn01')}
              fullWidth
            >
              저장
            </Button>
          </div>,
        ]}
      >
        <strong className={cn('popup_title')}>알림 정보 변경</strong>
        <div className={cn('note')}>
          알림 대상이 고객일 경우 개인 정보는 변경되지 않고 해당 알림 재발송 시
          1회 적용됩니다.
        </div>
        <div className={cn('popup_body')}>
          <div className={cn('in_popup_input_table')}>
            <table className={cn('input_table')}>
              <colgroup>
                <col className={cn('col_width')} />
              </colgroup>
              <tbody>
                <tr>
                  <th>수신자 명</th>
                  <td>
                    <div className={cn('inputWrap')}>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          name=''
                          id=''
                          value='홍길동'
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>계약번호</th>
                  <td>
                    <div className={cn('inputWrap')}>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          name=''
                          id=''
                          value='123456'
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>현재 휴대폰 번호</th>
                  <td>
                    <div className={cn('inputWrap', 'type_phone')}>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          value={currentPhoneNum1?.replace(/[^0-9]/g, '')}
                          name={'currentPhoneNum1'}
                          onChange={onChangecurrentPhoneNumber}
                          readOnly
                          minLength={3}
                          maxLength={3}
                          required={true}
                        />
                      </div>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          value={currentPhoneNum2?.replace(/[^0-9]/g, '')}
                          name={'currentPhoneNum2'}
                          onChange={onChangecurrentPhoneNumber}
                          readOnly
                          className={cn('input_text')}
                          minLength={4}
                          maxLength={4}
                          required={true}
                        />
                      </div>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          value={currentPhoneNum3?.replace(/[^0-9]/g, '')}
                          name={'currentPhoneNum3'}
                          onChange={onChangecurrentPhoneNumber}
                          readOnly
                          className={cn('input_text')}
                          minLength={4}
                          maxLength={4}
                          required={true}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>변경 휴대폰 번호</th>
                  <td>
                    <div className={cn('inputWrap', 'type_phone')}>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          value={phoneNum1?.replace(/[^0-9]/g, '')}
                          name={'phoneNum1'}
                          onChange={onChangePhoneNumber}
                          minLength={3}
                          maxLength={3}
                          required={true}
                        />
                      </div>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          value={phoneNum2?.replace(/[^0-9]/g, '')}
                          name={'phoneNum2'}
                          onChange={onChangePhoneNumber}
                          className={cn('input_text')}
                          minLength={4}
                          maxLength={4}
                          required={true}
                        />
                      </div>
                      <div className={cn('input_text')}>
                        <Input
                          type='text'
                          value={phoneNum3?.replace(/[^0-9]/g, '')}
                          name={'phoneNum3'}
                          onChange={onChangePhoneNumber}
                          className={cn('input_text')}
                          minLength={4}
                          maxLength={4}
                          required={true}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </PopUp>
    </div>
  );
};

export default RefundAlarmDetailPopup;
