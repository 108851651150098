import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import css from './CouponPublish.module.scss';
import { Table, Button, TableV8, PopUpAlert, PopUp } from '@components/index';

import { createColumnHelper } from '@tanstack/react-table';
import {
  getTalkDreamCouponIssueTargetListAPI,
  postTalkDreamCouponIssueMessage,
  postCancelTalkDreamCouponIssueTarget,
} from 'redux/action/couponAction';
import { useDispatch, useSelector } from 'react-redux';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import TableCheckbox from '@components/Table/TableCheckbox';
import { openModalAlert } from '@feature/common/commonSlice';

const columnHelper = createColumnHelper();

const cn = classNames.bind(css);

const TABLE_MOCK_DATA = [
  {
    some1: 'GC0001',
    some2: '',
    some3: '',
    some4: '',
    some5: '',
    some6: '',
    some7: 'Y',
  },
  {
    some1: 'GC0001',
    some2: '',
    some3: '',
    some4: '',
    some5: '',
    some6: '',
    some7: 'Y',
  },
];

const CouponPublishTalkDream = ({ handleClickStepNext }) => {
  const dispatch = useDispatch();
  const talkDreamCouponIssueTargetList = useSelector(
    (state) => state.coupon.talkDreamCouponIssueTargetList
  );
  const [isOpenMappingPopup, setIsOpenMappingPopup] = useState(false);
  const [isOpenMessagePopup, setIsOpenMessagePopup] = useState(false);
  const [currentMessageContents, setCurrentMessageContents] = useState();
  const userId = useSelector((state) => state.user.userData.userId);

  const handleMessagePopup = useCallback((contents) => {
    setIsOpenMessagePopup(true);
    setCurrentMessageContents(contents);
  }, []);

  const columnDataV8 = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'coupon-talkdream-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'coupon-talkdream-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사</span>,
      }),
      columnHelper.accessor('templateName', {
        id: 'templateName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림톡 제목</span>,
      }),
      columnHelper.accessor('template', {
        id: 'template',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>메세지 코드</span>,
      }),
      columnHelper.accessor('content', {
        id: 'content',
        cell: (info) => (
          <span
            className={css.ellipsis}
            onClick={() => handleMessagePopup(info.getValue())}
          >
            {info.getValue()}
          </span>
        ),
        header: () => <span>메세지 내용</span>,
      }),
    ],
    []
  );

  const callTalkDreamAPI = useCallback(() => {
    postTalkDreamCouponIssueMessage(dispatch);
  }, []);

  useEffect(() => {
    const tempParams = {
      limit: '100',
      page: '1',
    };
    getTalkDreamCouponIssueTargetListAPI(dispatch, tempParams);
  }, []);

  const [selectedTalkdreamKeys, setSelectedTalkdreamKeys] = useState([]);

  const onChangeSelectedTalkdreamKeys = useCallback((keys) => {
    const messageKeys = keys.map((item, idx) => item.messageKey);
    let formattedCancelTargetList = [];
    if (keys?.length > 0) {
      formattedCancelTargetList = JSON.stringify(messageKeys);
    }
    setSelectedTalkdreamKeys(formattedCancelTargetList);
  }, []);

  const handleCancelTalkDream = useCallback(
    (selectedItem) => {
      if (selectedItem?.length === 0) {
        dispatch(openModalAlert({ message: '취소할 대상을 체크해주세요.' }));
      } else {
        postCancelTalkDreamCouponIssueTarget(dispatch, {
          cancelTargetList: selectedItem,
          userId: userId,
        });
      }
    },
    [userId]
  );

  return (
    <>
      <div className={css.stepGuideText}>
        ③ 고객에게 발행된 쿠폰 번호를 알림톡으로 발송해주세요.
      </div>
      <TableV8ManualPage
        columns={columnDataV8}
        data={talkDreamCouponIssueTargetList}
        hasCsvDownload={false}
        hasPagination={false}
        hasPageSize={false}
        limit={100}
        getRowselected={onChangeSelectedTalkdreamKeys}
      />
      <div className={cn('flex', 'justify-content-center')}>
        <Button
          styleType={'btn01'}
          subType={'type01'}
          onClick={() => {
            handleCancelTalkDream(selectedTalkdreamKeys);
          }}
        >
          선택항목 발송리스트 제거
        </Button>
        <Button
          styleType={'btn01'}
          subType={'type01'}
          style={{ marginLeft: '50px' }}
          onClick={() => {
            setIsOpenMappingPopup(true);
          }}
        >
          알림톡 발송하기
        </Button>
      </div>
      <PopUpAlert
        title={'알림톡 발송 확인'}
        isOpenPopUp={isOpenMappingPopup}
        onRequestClose={() => setIsOpenMappingPopup(false)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={() => setIsOpenMappingPopup(false)}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='stay'
                subType={'type02'}
                onClick={() => {
                  setIsOpenMappingPopup(false);
                  callTalkDreamAPI();
                }}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        {`알림톡은 즉시 발송 됩니다.
        계속 하시겠습니까?`}
      </PopUpAlert>
      <PopUp
        title={'알림톡 확인'}
        isOpenPopUp={isOpenMessagePopup}
        onRequestClose={() => setIsOpenMessagePopup(false)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='stay'
                subType={'type02'}
                onClick={() => {
                  setIsOpenMessagePopup(false);
                }}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={css.messagePreview}>{currentMessageContents}</div>
      </PopUp>
    </>
  );
};

export default CouponPublishTalkDream;
