import React, { memo } from 'react';
import css from './Tabmenu.module.scss';
import classNames from 'classnames/bind';
import { useState, useRef } from 'react';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category Components
 * @namespace TabMenu
 * @param {string[]} tabData tab의 Title, Content 값
 * @param {boolean} hasPrevButton prevButton이 있는지 여부
 * @param {boolean} hasNextButton NextButton이 있는지 여부
 * @param {number} activeTab 현재 선택된 탭의 index
 * @param {function} setActiveTab activeTab setter
 * @param {string[]} tabMenu css class를 지정하기 위한 요소: tabMenu01, tabMenu02 존재
 * @param {boolean} type02 css class를 지정하기 위한 요소: type02를 적용해야 하는지 여부
 * @param {boolean} type02_1 css class를 지정하기 위한 요소: type02_1를 적용해야 하는지 여부
 * @param {boolean} isTabMarinBottom 탭 메뉴의 밑부분(콘텐츠 영역) 마진 존재 여부
 */

const TabMenu = ({
  tabData,
  hasPrevButton,
  hasNextButton,
  activeTab,
  setActiveTab,
  tabMenu,
  type02,
  type02_1,
  isTabMarinBottom,
}) => {
  const [transformValue, setTransformValue] = useState(0);
  const targetRef = useRef();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const moveScrollTo = (direction) => {
    const currentValue = transformValue;
    const tabDataLength = tabData.length;

    const step = targetRef.current?.clientWidth;

    const minAllowedValue = -step * (tabDataLength - 1);
    const maxAllowedValue = step * (tabDataLength - 1);

    if (direction === 'prev' && currentValue > minAllowedValue) {
      setTransformValue(currentValue - step);
    } else if (direction === 'next' && currentValue < maxAllowedValue) {
      setTransformValue(currentValue + step);
    }
  };

  return (
    <>
      <div className={cn(isTabMarinBottom && 'mb20')}>
        <div
          className={cn(tabMenu, type02 && 'type02', type02_1 && 'type02-1')}
        >
          <div
            className={css.list}
            style={{ overflow: transformValue !== 0 ? 'hidden' : 'visible' }}
          >
            {hasPrevButton && (
              <button
                type='button'
                className={cn('nav', 'prev')}
                onClick={() => moveScrollTo('prev')}
              >
                이전
              </button>
            )}
            <ul style={{ transform: `translate(${transformValue}px)` }}>
              {tabData.map((tab, index) => (
                <li
                  key={tab.id}
                  className={activeTab === tab.id ? css.on : ''}
                  ref={targetRef}
                >
                  <div
                    className={css.btn}
                    onClick={() => handleTabClick(tab.id)}
                  >
                    <span>{tab.title}</span>
                  </div>
                </li>
              ))}
            </ul>
            {hasNextButton && (
              <button
                type='button'
                className={cn('nav', 'next')}
                onClick={() => moveScrollTo('next')}
              >
                다음
              </button>
            )}
          </div>

          <div className={cn(css.cont_area, 'mt20')}>
            {tabData.map((tab, index) => {
              if (tab?.content && activeTab === tab.id) {
                return <div key={tab.id}>{tab.content}</div>;
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

TabMenu.defaultProps = {
  tabData: [
    { id: 'tab1', title: 'tabTitle1', content: '' },
    { id: 'tab2', title: 'tabTitle2', content: '' },
    { id: 'tab3', title: 'tabTitle3', content: '' },
  ],
  tabMenu: 'tabMenu01',
  hasPrevButton: false,
  hasNextButton: false,
  type02: false,
  type02_1: false,
  isTabMarinBottom: true,
};

export default memo(TabMenu);
