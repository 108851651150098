import { useState, memo, useEffect } from 'react';
import css from './InternalUserSignUp.module.scss';
import classNames from 'classnames/bind';
import {
  Button,
  Input,
  VerticalTable,
  VerticalTableContainer,
} from '@components/index';
import { useInput, useInputMultiple, useValidation } from '@hooks';
import { handlePreventRegex } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { getCheckIdAvailable } from 'redux/action/registerAction';
import { postCreateInternalUser } from 'redux/action/loginAction';
import { getCheckEmailExist } from 'redux/action/userAction';
import { openModalAlert } from '@feature/common/commonSlice';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

// A0006 글자수를 확인 해주세요
// A0025 사용 불가능한 ID입니다.
// A0026 사용 가능한 ID입니다.
// A0027 사용 불가능한 패스워드 입니다.
// A0028 사용 가능한 패스워드 입니다.

/**
 * @category components
 * @namespace InternalUserSignUp
 * @description 내부망 회원 가입 페이지
 */

const InternalUserSignUp = ({ handleCancel }) => {
  const dispatch = useDispatch();

  // 중복확인 버튼이 눌렸는지 여부를 바꿔주는 함수
  const handleIdChecked = (checked) => {
    setIdCheck(checked);
  };
  // 유효성 확인 버튼이 눌렸는지 여부를 바꿔주는 함수
  const handlePasswordChecked = (checked) => {
    setPassWordCheck(checked);
  };

  const [phoneNumber, setPhoneNumber, onChangePhoneNumber] = useInputMultiple({
    phoneNum1: '',
    phoneNum2: '',
    phoneNum3: '',
  });

  // 휴대폰 Input 3개 test

  const { phoneNum1, phoneNum2, phoneNum3 } = phoneNumber;

  //Input의 value를 바꿔주기 위한 useInput hook
  const [id, setId, onChangeId] = useInput('', handleIdChecked);
  const [password, setPassword, onChangePassword] = useInput(
    '',
    handlePasswordChecked
  );
  const [
    passwordConfirmation,
    setPasswordConfirmation,
    onChangePasswordConfirmation,
  ] = useInput('');

  const [email, setEmail, onChangeEmail] = useInput('');

  //id 중복 체크,비밀번호 유효성 검사 체크,이메일 인증 요청되었는지 여부
  const [idCheck, setIdCheck] = useState(false);
  const [passwordCheck, setPassWordCheck] = useState(false);
  const [passwordConfirmationCheck, setPassWordConfirmationCheck] =
    useState(false);
  const [emailCheck, setEmailCheck] = useState(false);

  // 유효성 체크를 위한 hook
  const idValidate = useValidation('id', id);
  const emailValidate = useValidation('email', email);
  const passwordValidate = useValidation('password', password, id);
  const passwordConfirmationValidate = useValidation(
    'passwordConfirmation',
    passwordConfirmation,
    password
  );

  // required 메세지를 위한 변수
  const [requiredIdMessage, setRequiredIdMessage] = useState('');
  const [requiredPasswordMessage, setRequiredPasswordMessage] = useState('');
  const [
    requiredPasswordConfirmationMessage,
    setRequiredPasswordConfirmationMessage,
  ] = useState('');

  // 사용자(LGE) 신규 생성 Api
  const createInternalUser = () => {
    const params = {
      userId: id,
      password: password,
      phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
      email: email,
    };
    postCreateInternalUser(dispatch, params, handleCancel);
  };

  // 사용자 ID 사용 가능 여부 확인 Api
  const handleCheckDuplicate = (check) => {
    const params = {
      userId: id,
    };

    if (check.isBoolean) {
      getCheckIdAvailable(dispatch, params, setId);
      setIdCheck(true);
    } else {
      return dispatch(openModalAlert({ message: check.message }));
    }
  };

  // 비밀번호 유효성 체크
  const handleValidation = (check) => {
    setPassWordCheck(true);
    return dispatch(openModalAlert({ message: check.message }));
  };

  const handleSave = () => {
    switch (true) {
      case !idCheck:
        dispatch(openModalAlert({ message: '아이디 중복 확인이 필요합니다.' }));
        break;
      case !passwordCheck:
        dispatch(
          dispatch(
            openModalAlert({ message: '비밀번호 유효성 확인이 필요합니다.' })
          )
        );
        break;
      case !passwordConfirmationCheck:
        dispatch(openModalAlert({ message: '비밀번호가 일치하지 않습니다.' }));
        break;
      case !emailCheck:
        dispatch(openModalAlert({ message: '이메일 인증이 필요합니다.' }));
        break;
      case !phoneNum1 || !phoneNum2 || !phoneNum3:
        dispatch(openModalAlert({ message: '올바른 전화번호를 입력해주세요' }));
        break;
      case phoneNum1 !== '010':
        dispatch(openModalAlert({ message: '올바른 전화번호를 입력해주세요' }));
        break;
      case password === `${phoneNum1}${phoneNum2}${phoneNum3}`:
        dispatch(
          openModalAlert({
            message: '비밀번호와 전화번호가 일치 합니다. 정보를 확인해주세요.',
          })
        );
        break;
      default:
        createInternalUser();
    }
  };

  useEffect(() => {
    setPassWordConfirmationCheck(passwordConfirmationValidate.isBoolean);
  }, [passwordConfirmationValidate.isBoolean]);

  useEffect(() => {
    setEmailCheck(emailValidate.isBoolean);
  }, [emailValidate.isBoolean]);

  const blurEvent = (condition1, condition2, message, setFunc) => {
    if (condition1 === '' || condition2 === '') {
      setFunc(message);
    } else {
      setFunc('');
    }
  };

  // 메일 중복 여부 확인 Api
  const handleCheckEmailExist = () => {
    const param = {
      email: email,
    };

    if (email) {
      getCheckEmailExist(dispatch, param, setEmailCheck);
    }
  };

  return (
    <>
      <div className={css.register_input}>
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='cancel'
                  onClick={handleCancel}
                  subType='type03'
                  fullWidth
                >
                  취소
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='save'
                  subType='type02'
                  onClick={handleSave}
                  fullWidth
                >
                  신청
                </Button>
              </div>
            </div>,
          ]}
          isCenter
        >
          <VerticalTable
            title={'아이디'}
            button={
              <Button
                onClick={() => handleCheckDuplicate(idValidate)}
                styleType={'btn04'}
              >
                중복 확인
              </Button>
            }
            required
          >
            <Input
              type='text'
              value={id.replace(/[^a-z0-9]/g, '')}
              name={id}
              onChange={onChangeId}
              minLength={5}
              maxLength={10}
              required={true}
              pattern='^[a-z0-9]*$'
              onBlur={() =>
                blurEvent(
                  id,
                  'default',
                  '아이디를 입력해 주세요',
                  setRequiredIdMessage
                )
              }
              confirmedMessage={idCheck && idValidate.isBoolean}
              errormessage={requiredIdMessage}
              placeholder={'영어 소문자와 숫자만 입력 가능합니다.(5~12자)'}
            />
          </VerticalTable>
          <VerticalTable
            title={'비밀번호'}
            required
            button={
              <Button
                onClick={() => handleValidation(passwordValidate)}
                type='button'
                styleType={'btn04'}
                subType={'type01'}
              >
                유효성 확인
              </Button>
            }
          >
            <Input
              type='password'
              value={password}
              name={password}
              onChange={onChangePassword}
              minLength={8}
              maxLength={16}
              required={true}
              pattern='^[a-zA-Z0-9!@#$%^&*]*$'
              placeholder={
                '영어 대소문자, 숫자, 특수기호를 조합해 주세요.(10자 이상)'
              }
              onBlur={() =>
                blurEvent(
                  password,
                  'default',
                  '비밀번호를 입력해 주세요',
                  setRequiredPasswordMessage
                )
              }
              confirmedMessage={passwordCheck && passwordValidate.isBoolean}
              errormessage={requiredPasswordMessage}
              onKeyDown={(e) => handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)}
            />
          </VerticalTable>
          <VerticalTable title={'비밀번호 확인'} required>
            <Input
              type='password'
              value={passwordConfirmation}
              name={passwordConfirmation}
              onChange={onChangePasswordConfirmation}
              minLength={8}
              maxLength={16}
              required={true}
              pattern='^[a-zA-Z0-9!@#$%^&*]*$'
              onBlur={() =>
                blurEvent(
                  passwordConfirmation,
                  'default',
                  '비밀번호 확인을 입력해 주세요',
                  setRequiredPasswordConfirmationMessage
                )
              }
              errormessage={requiredPasswordConfirmationMessage}
              onKeyDown={(e) => handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)}
            />
          </VerticalTable>
          <VerticalTable title={'회사 전화'} required>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              <Input
                type='text'
                value={phoneNum1.replace(/[^0-9]/g, '')}
                name={'phoneNum1'}
                onChange={onChangePhoneNumber}
                minLength={3}
                maxLength={3}
                required={true}
                pattern='^[0-9]+$'
              />
              -
              <Input
                type='text'
                value={phoneNum2.replace(/[^0-9]/g, '')}
                name={'phoneNum2'}
                onChange={onChangePhoneNumber}
                minLength={4}
                maxLength={4}
                required={true}
                pattern='^[0-9]+$'
              />
              -
              <Input
                type='text'
                value={phoneNum3.replace(/[^0-9]/g, '')}
                name={'phoneNum3'}
                onChange={onChangePhoneNumber}
                minLength={4}
                maxLength={4}
                required={true}
                pattern='^[0-9]+$'
              />
            </div>
            {/* <div className={cn('note', 'color-error')}>
              휴대전화 오입력시 가입 승인이 거절 됩니다.
            </div> */}
          </VerticalTable>
          <VerticalTable
            title={'회사 이메일'}
            button={[
              <Button
                key={'checkDuplicateEmail'}
                onClick={handleCheckEmailExist}
                styleType={'btn04'}
              >
                중복 확인
              </Button>,
            ]}
            required
          >
            <Input
              type='email'
              value={email}
              name={email}
              onChange={onChangeEmail}
            />
          </VerticalTable>
          {/* <div className={cn('mt24', 'font-small')}>
            신청 승인 완료 후 기입하신 LGE 메일로 발송됩니다.
            <br />
            문의 : AAAA@lge.com
          </div> */}
        </VerticalTableContainer>
      </div>
    </>
  );
};

export default memo(InternalUserSignUp);
