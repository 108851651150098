import React, { memo, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import css from './ModalAlert.module.scss';
import classNames from 'classnames/bind';
import { Portal, Button } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalAlert } from '@feature/common/commonSlice';
import { useNavigate } from 'react-router-dom';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category Components
 * @namespace ModalAlert
 * @description 전역으로 사용하는 알림 메세지 모달.
 */

const ModalAlert = () => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '100%',
      padding: '60px 0',
      overflowX: 'hidden',
      overflowY: 'auto',
      zIndex: 100,
    },
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = useCallback((path) => {
    navigate(path);
    // eslint-disable-next-line
  }, []);

  const { modalType, isOpen, message, content, path } = useSelector(
    (state) => state.common.isAlertModal
  );

  const handlecloseModalAlert = () => {
    switch (modalType) {
      case 'basicModal':
        dispatch(closeModalAlert());
        break;
      case 'routerModal':
        dispatch(closeModalAlert());
        handleNavigate(path);
        break;
      default:
        dispatch(closeModalAlert());
    }
  };

  return (
    <>
      {isOpen && (
        <Portal>
          <Modal
            className={cn('alert')}
            isOpen={isOpen}
            style={customStyles}
            onRequestClose={handlecloseModalAlert}
          >
            {content ? (
              <div className={css.alert_body}>{content}</div>
            ) : (
              <div className={css.alert_body}>{message}</div>
            )}
            <div className={css.buttonContianer}>
              <Button
                key='alertConfirm'
                subType={'type02'}
                onClick={handlecloseModalAlert}
                fullWidth
              >
                확인
              </Button>
            </div>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default memo(ModalAlert);
