import React, { useState } from 'react';
import classNames from 'classnames/bind';
import css from './RefundAlarm.module.scss';
import { TabMenu } from '@components/index';
import RefundAlarmTabFirst from './RefundAlarmTab/RefundAlarmTabFirst';
import RefundAlarmTabSecond from './RefundAlarmTab/RefundAlarmTabSecond';

const cn = classNames.bind(css);

const RefundAlarm = () => {
  return (
    <>
      <h2 className={cn('pageTitle')}>환불알림</h2>

      <div className={cn('tabMenu', 'tabMenu01', 'type01', 'mt20')}>
        <RefundAlarmTabFirst />
      </div>
    </>
  );
};

export default RefundAlarm;
