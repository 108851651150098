import React, { useCallback, useState } from 'react';
import { Checkbox, PopUp } from '@components/index';
import css from './HomePopUp.module.scss';
import { dateToStr, readLocalStorage, writeLocalStorage } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setNoticeDetailPopup } from '@feature/common/commonSlice';
import NoticeDetail from 'template/Notice/NoticeDetail';

const HomePopUp = ({ targetPopup }) => {
  const dispatch = useDispatch();
  const [dontShowToday, setDontShowToday] = useState(false);
  const isNoticeDetailPopup = useSelector(
    (state) => state.common.popup.isNoticeDetailPopup
  );

  const handleDetailPopUpClick = useCallback(() => {
    dispatch(setNoticeDetailPopup(!isNoticeDetailPopup));
  }, [dispatch, isNoticeDetailPopup]);

  const extractTextFromHTML = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText;
  };

  const handleDontShowToday = () => {
    setDontShowToday((prev) => !prev);
    const toDay = new Date();
    const todayStr = dateToStr(toDay);
    const popupStorage = readLocalStorage('popupStorage');
    const notPopupTodayItem = Object.assign(
      {},
      popupStorage[todayStr][targetPopup.noticeKey],
      { notPopupToday: !dontShowToday }
    );

    delete popupStorage[todayStr][targetPopup.noticeKey];
    popupStorage[todayStr][targetPopup.noticeKey] = {};
    popupStorage[todayStr][targetPopup.noticeKey] = notPopupTodayItem;
    writeLocalStorage('popupStorage', popupStorage);
  };

  return (
    <div className={css.popupContainer} onClick={handleDetailPopUpClick}>
      <PopUp
        isOpenPopUp={isNoticeDetailPopup}
        onRequestClose={handleDetailPopUpClick}
        flexStart={true}
        isWideSize={true}
      >
        <NoticeDetail currentNotice={targetPopup} isHomePopUp={true} />
      </PopUp>

      {targetPopup && Object.keys(targetPopup).length > 0 && (
        <div>
          <div className={css.popUpContainer}>
            <h3>{targetPopup.subject}</h3>
            <p>{extractTextFromHTML(targetPopup.popupContent)}</p>
          </div>
          <div className={css.checkbox}>
            <Checkbox
              id={'dontShowToday'}
              onClick={() => handleDontShowToday()}
              defaultChecked={dontShowToday}
            >
              오늘 하루 보지 않기
            </Checkbox>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePopUp;
