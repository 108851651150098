import { createSlice } from '@reduxjs/toolkit';
import { replaceImgUploadUrl } from '@utils/';

const initialState = {
  // 공지사항
  contents: {},
  popupContents: {},
  form: {
    companyCode: '',
    subject: '',
    noticeFixedFlag: '',
    popupFlag: '',
    popupReasonCode: '',
    popupFromDate: '',
    popupFromTime: '',
    popupToDate: '',
    popupToTime: '',
    popupContent: '',
    content: '',
    userId: '',
    imageUrl: '',
    noticeKey: '',
    vendorKey: 'VENDOR.LGE',
  },
  // 댓글
  conmmentsList: {},
  comment: {
    noticeKey: '',
    content: '',
    secretFlag: '',
    userId: '',
  },
  currentNotice: {},
};

const notice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    getNotice: (state, action) => {
      state.contents = action.payload;
    },
    getPopUpNotice: (state, action) => {
      let payload = action.payload;
      let target = {};
      if (!target[payload.noticeKey]) {
        target[payload.noticeKey] = payload;
      }
      state.popupContents = target[payload.noticeKey];
    },
    updateNotice: (state, action) => {
      const payload = action.payload;
      const target = Object.assign({}, payload, {
        content: replaceImgUploadUrl('S3ToCloudfront', payload.content),
      });
      state.contents[action.payload.noticeKey] = Object.assign(
        {},
        state.contents[action.payload.noticeKey],
        target
      );
    },
    deleteNotice: (state, action) => {
      state.contents[action.payload].delFlag = 'Y';
    },
    setConmments: (state, action) => {
      const targetObj = action.payload?.commentlist?.[0]?.noticeKey;
      if (targetObj) {
        if (
          !state.conmmentsList?.[targetObj] &&
          state.contents[targetObj].commentCount > 0
        ) {
          // first call comment
          state.conmmentsList[targetObj] = {};
          state.conmmentsList[targetObj] = action.payload.commentlist;
        } else if (
          state.conmmentsList?.[targetObj] &&
          state.contents[targetObj].commentCount > 0
        ) {
          // recall comment
          state.conmmentsList[targetObj] = action.payload.commentlist;
        } else if (state.contents?.[targetObj].commentCount === 0) {
          //first post comment
          state.conmmentsList[targetObj] = action.payload.commentlist;
          state.contents[targetObj].commentCount++;
        }
      } else {
        const noticeKey = action.payload.detailInfo.noticeKey;
        state.conmmentsList[noticeKey] = action.payload.commentlist;
      }
    },
    updateConmments: (state, action) => {
      const targetObj = action.payload?.commentlist?.[0]?.noticeKey;
      state.conmmentsList[targetObj] = action.payload.commentlist;
      state.contents[targetObj].commentCount++;
    },
    deleteComments: (state, action) => {
      const targetObj = action.payload.detailInfo.noticeKey;
      if (state.contents[targetObj].commentCount > 0) {
        state.contents[targetObj].commentCount--;
      }
      state.conmmentsList[targetObj] = action.payload.commentlist;
    },
    setNoticeForm: (state, action) => {
      const key = action.payload.key;
      state.form[key] = action.payload.value;
    },
    setNoticeComment: (state, action) => {
      const key = action.payload.key;
      state.comment[key] = action.payload.value;
    },
    resetForm: (state) => {
      state.form = initialState.form;
    },
    setCurrentNotice: (state, action) => {
      state.currentNotice = action.payload;
    },
    setNoticeReadCount: (state, action) => {
      state.contents[action.payload].readCount++;
    },
  },
});

export const {
  getNotice,
  getPopUpNotice,
  updateNotice,
  deleteNotice,
  setConmments,
  updateConmments,
  deleteComments,
  setNoticeForm,
  setNoticeComment,
  resetForm,
  setCurrentNotice,
  setNoticeReadCount,
} = notice.actions;

export default notice.reducer;
