import React from 'react';
import { useTable, usePagination } from 'react-table';
import css from './Table.module.scss';
import { memo } from 'react';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

export const Table = ({
  columns,
  data,
  registerButton,
  hasPagination,
  hasPageSize,
  largeWidth,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {
      pageIndex,
      pageSize,
      pageSizeOptions = [10, 20, 30, 40, 50, 100, 200],
    },
    setPageSize,
    gotoPage,
    pageCount,
  } = useTable({ columns, data, autoResetPage: false }, usePagination);

  // 페이지 번호 그룹 크기
  const pageSizeGroup = 5;
  const currentPageGroup = Math.floor(pageIndex / pageSizeGroup);

  // 페이지 버튼을 동적으로 생성
  const pageButtons = Array.from(
    { length: Math.min(pageSizeGroup, pageCount) },
    (_, i) => {
      const pageNumber = currentPageGroup * pageSizeGroup + i;
      return (
        <li key={i} className={pageNumber === pageIndex ? css.on : ''}>
          <button
            type='button'
            onClick={() => gotoPage(pageNumber)}
            disabled={pageIndex === pageNumber}
          >
            {pageNumber + 1}
          </button>
        </li>
      );
    }
  );

  return (
    <div className={css.tableContainer}>
      <div className={css.table_header}>
        {hasPageSize && (
          <div className={css.box_left}>
            <div className={css.total_count}>
              <span>{data.length} </span>
              Items
            </div>
            <span>
              <select
                className={cn('selectbox', 'type05')}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {pageSizeOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </span>
          </div>
        )}
        {registerButton && (
          <div className={css.box_right}>
            <button type='button' className={cn('btn', 'btn03', 'type02')}>
              등록
            </button>
          </div>
        )}
      </div>

      <table {...getTableProps()} className={css.table}>
        <thead>
          {headerGroups.map((header) => (
            <tr {...header.getHeaderGroupProps()} className={css.header}>
              {header.headers.map((col) => (
                <th
                  {...col.getHeaderProps()}
                  className={cn('headerList', largeWidth && 'largeWidth')}
                >
                  {col.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={css.tbody}>
          {data.length > 0 ? (
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className={css.tbodyRow}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={cn('tbodyList', largeWidth && 'largeWidth')}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <div>검색 결과가 없습니다.</div>
          )}
        </tbody>
      </table>

      {hasPagination && (
        <div className={css.pagination}>
          <button
            type='button'
            className={cn('nav', 'first')}
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
          >
            처음
          </button>
          <button
            type='button'
            className={cn('nav', 'prev')}
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
          >
            이전
          </button>
          <ol className={css.order}>{pageButtons}</ol>
          <button
            type='button'
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex === pageCount - 1}
            className={cn('nav', 'next')}
          >
            다음
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex === pageCount - 1}
            className={cn('nav', 'last')}
          >
            마지막
          </button>
          <span className={css.pageIndexInfo}>
            {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
          </span>
        </div>
      )}
    </div>
  );
};

Table.defaultProps = {
  hasPagination: true,
  hasPageSize: true,
};

export default memo(Table);
