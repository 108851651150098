import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import TableV8 from '@components/Table/TableV8';
import css from './VendorAccountTable.module.scss';
import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import {
  Button,
  PopUp,
  PopUpAlert,
  VerticalTable,
  VerticalTableContainer,
} from '@components/';
import {
  postApproveVendorUserAccount,
  postDeleteVendorUserAccount,
} from 'redux/action/AuthorityAction';
import { VendorAuthorityInterface } from '@feature/Authority/AuthoritySlice';
import { PERMISSIONS, APPROVAL_TARGET } from '@data/Const';
import { postUpdateVendorGeneralInfo } from 'redux/action/userAction';
import AuthoritySearchArea from './modules/AuthoritySearchArea';
import { FormProvider, useForm } from 'react-hook-form';
import {
  hasSearchText,
  isDateInRange,
  matchesCondition,
} from '@components/Search/utils/searchValidationFunctions';
import HFInput from '@components/_ReactHookForm/HFInput';
import HFTextarea from '@components/_ReactHookForm/HFTextarea';
import HFCheckbox from '@components/_ReactHookForm/HFCheckbox';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { RadioButton } from '@components/index';
import { openModalAlert } from '@feature/common/commonSlice';
import { getByte } from '@utils';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const statusSearchOptions = {
  guide: '',
  list: [
    { id: '', title: '전체' },
    { id: PERMISSIONS.APPROVED, title: '승인' },
    { id: PERMISSIONS.UNDER_APPROVAL, title: '승인대기' },
    { id: PERMISSIONS.REJECTED, title: '거절' },
  ],
};

const keywordSearchOptions = {
  guide: '',
  list: [
    { id: 'userId', title: '아이디' },
    { id: 'companyName', title: '제휴사명' },
  ],
};

const POPUP_NAME = {
  CLOSED: [],
  MANAGEMENT_COMPANY_CODE: 'MANAGEMENT_COMPANY_CODE',
  UPDATE_COMPANY_CODE: 'UPDATE_COMPANY_CODE',
  UNDER_APPROVAL: 'UNDER_APPROVAL',
  CONFIRM_APPROVE: 'CONFIRM_APPROVE',
  CONFIRM_REJECT: 'CONFIRM_REJECT',
  CONFIRM_PHONE: 'CONFIRM_PHONE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  WITHDRAWAL: 'WITHDRAWAL',
  FIRST_WITHDRAWAL_CONFIRM: 'FIRST_WITHDRAWAL_CONFIRM',
  SECOND_WITHDRAWAL_CONFIRM: 'SECOND_WITHDRAWAL_CONFIRM',
  FINAL_WITHDRAWAL_CONFIRM: 'FINAL_WITHDRAWAL_CONFIRM',
};

const SUBMIT_TYPE = {
  STATUS_APPROVED: 'STATUS_APPROVED',
  STATUS_REJECTED: 'STATUS_REJECTED',
  UPDATE_COMPANY_CODE: 'UPDATE_COMPANY_CODE',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  WITHDRAWAL: 'WITHDRAWAL',
};

const TargetuserInfoInterface = {
  ...VendorAuthorityInterface,
  checkPhoneFlag: false,
  checkEmailFlag: false,
};

const VendorAccountTable = ({ selectApprovalStatus }) => {
  const dispatch = useDispatch();
  const userData = useSelector(({ user: { userData } }) => userData);
  const vendorAuthorityList = useSelector(
    ({ authority: { vendorAuthorityList } }) => vendorAuthorityList
  );
  const sortedVendorUserList = useMemo(() => {
    const list = [];

    for (const key in vendorAuthorityList) {
      list.push(vendorAuthorityList[key]);
    }

    list.sort((a, b) => b.joinDate - a.joinDate);

    return list;
  }, [vendorAuthorityList]);
  const [filtered, setFiltered] = useState(sortedVendorUserList);
  const TABLE_DATA_LENGTH = useMemo(() => filtered.length, [filtered]);

  const [currentPopupName, setCurrentPopupName] = useState(POPUP_NAME.CLOSED);
  const searchForms = useForm();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: TargetuserInfoInterface,
  });

  const newVendorList = useSelector(
    ({ authority: { newVendorList } }) => newVendorList
  );
  const vendorMasterList = useSelector(
    ({ authority: { VendorMasterList } }) => VendorMasterList
  );
  const vendorCodeList = useMemo(() => {
    const uniqueVendorMasterList = vendorMasterList.reduce((acc, cur) => {
      const { approvalStatus } = cur;
      if (approvalStatus !== PERMISSIONS.APPROVED) return acc;

      const { companyCode, approvalDate } = cur;
      if (!acc?.companyCode) {
        acc[companyCode] = cur;
      } else {
        if (
          new Date(approvalDate) - new Date(acc[companyCode].approvalDate) >=
          0
        ) {
          acc[companyCode] = cur;
        }
      }

      return acc;
    }, {});

    return [...newVendorList, ...Object.values(uniqueVendorMasterList)];
  }, [newVendorList, vendorMasterList]);
  const csmsNewVendorMasterList = useSelector(
    ({ authority: { csmsNewVendorMasterList } }) => csmsNewVendorMasterList
  );
  const companyCodeOptions = useMemo(
    () => ({
      guide: '업체코드를 선택해주세요',
      list: csmsNewVendorMasterList.map((vendor) => ({
        id: vendor?.companyCode,
        title: vendor?.companyName,
      })),
    }),
    [csmsNewVendorMasterList]
  );
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(-1);
  const {
    register: registerForManageCompanyCode,
    handleSubmit: handleSubmitForManageCompanyCode,
    reset: resetForManageCompanyCode,
    watch: watchForManageCompanyCode,
    setValue: setValueForManageCompanyCode,
    getValues: getValuesForManageCompanyCode,
    control: controlForManageCompanyCode,
  } = useForm();
  const registrationDocFilesRef = useRef(null);
  const salesDocFilesRef = useRef(null);
  const [registrationDocFiles, salesDocFiles] = watchForManageCompanyCode([
    'registrationDocFiles',
    'salesDocFiles',
  ]);

  const onPushCurrentPopupName = useCallback((popupName) => {
    setCurrentPopupName((prev) => [...prev, popupName]);
  }, []);

  const onPopCurrentPopup = useCallback(() => {
    setCurrentPopupName((prev) => prev.slice(0, -1));
  }, []);

  const onPopupClose = useCallback(() => {
    reset(TargetuserInfoInterface);
    setCurrentPopupName(POPUP_NAME.CLOSED);
  }, [reset]);

  const onSearchClick = useCallback(
    (data) => {
      const {
        startDate,
        endDate,
        statusSearchCondition,
        keywordSearchCondition,
        keyword,
      } = data;

      if (getByte(keyword) <= 3)
        return dispatch(openModalAlert({ message: '글자수를 확인 해주세요' }));

      const filteredList = sortedVendorUserList.filter((vendor) => {
        const { joinDate, approvalStatusAccount } = vendor;

        return (
          isDateInRange({
            targetDate: new Date(joinDate),
            startDate,
            endDate,
          }) &&
          matchesCondition({
            targetCondition: approvalStatusAccount,
            searchCondition: statusSearchCondition,
          }) &&
          hasSearchText({
            targetValue: vendor[keywordSearchCondition],
            searchText: keyword,
          })
        );
      });

      setFiltered(filteredList);
    },
    [dispatch, sortedVendorUserList]
  );

  const onImageUploadClick = (ref) => {
    ref.current?.click();
  };

  const onCompanyInfoChangeConfirm = useCallback(
    (data) => {
      const {
        vendorKey,
        companyCode,
        companyRegNo,
        companyName,
        companyBizName,
        companyCeoName,
        companyOpenDate,
        supportPhoneNo,
        gerpVendorCode,
        gerpBillToCode,
        commentRequest,
        userId,
        registrationDocFiles,
        salesDocFiles,
      } = data;

      const updateCompanyCodeParams = {
        vendorKey,
        companyCode:
          typeof companyCode === 'string' ? companyCode : companyCode?.id,
        companyRegNo,
        companyName,
        companyBizName,
        companyCeoName,
        companyOpenDate,
        supportPhoneNo,
        gerpVendorCode,
        gerpBillToCode,
        commentRequest,
        userId,
        registrationDocFiles,
        salesDocFiles,
      };

      postUpdateVendorGeneralInfo({
        dispatch,
        params: updateCompanyCodeParams,
        authority: userData?.authority,
      });
      onPopupClose();
    },
    [dispatch, userData, onPopupClose]
  );

  const onCompanyInfoChangeClick = useCallback(() => {
    const selectedCompany = vendorCodeList[selectedCompanyIndex];

    selectedCompany &&
      resetForManageCompanyCode({
        companyCode:
          selectedCompany.companyCode !== 'TEMP'
            ? selectedCompany.companyCode
            : null,
        gerpBillToCode: selectedCompany.gerpBillToCode,
        approvalStatus: selectedCompany.approvalStatus,
        companyOpenDate: selectedCompany.companyOpenDate,
        companyName: selectedCompany.companyName,
        companyRegNo: selectedCompany.companyRegNo,
        vendorKey: selectedCompany.vendorKey,
        companyBizName: selectedCompany.companyBizName,
        gerpVendorCode: selectedCompany.gerpVendorCode,
        companyCeoName: selectedCompany.companyCeoName,
        supportPhoneNo: selectedCompany.supportPhoneNo,
        userId: userData.userId,
      });

    onPushCurrentPopupName(POPUP_NAME.UPDATE_COMPANY_CODE);
  }, [
    vendorCodeList,
    selectedCompanyIndex,
    resetForManageCompanyCode,
    userData.userId,
    onPushCurrentPopupName,
  ]);

  const onCellButtonClick = useCallback(
    ({ info, popupName }) => {
      const {
        row: {
          original: {
            userId,
            email,
            companyName,
            phoneNo,
            approvalDateAccount,
            approvalStatusAccount,
            approvalUserIdAccount,
            commentApprovalAccount,
            userHistKey,
          },
        },
      } = info;

      const phoneNoArr = phoneNo?.split('-');

      switch (popupName) {
        case POPUP_NAME.PHONE:
          reset({
            userId,
            email,
            phoneNo0: phoneNoArr[0],
            phoneNo1: phoneNoArr[1],
            phoneNo2: phoneNoArr[2],
            userHistKey,
          });
          break;
        case POPUP_NAME.EMAIL:
          reset({
            phoneNo,
            userId,
            email,
            userHistKey,
          });

          break;
        case POPUP_NAME.UNDER_APPROVAL:
        case POPUP_NAME.REJECTED:
        case POPUP_NAME.WITHDRAWAL:
          reset({
            userId,
            email,
            companyName,
            phoneNo0: phoneNoArr[0],
            phoneNo1: phoneNoArr[1],
            phoneNo2: phoneNoArr[2],
            userHistKey,
          });
          break;
        case POPUP_NAME.APPROVED:
          reset({
            approvalStatusAccount:
              approvalStatusAccount === popupName ? '승인' : '',
            commentApprovalAccount,
            approvalDateAccount,
            approvalUserIdAccount,
          });
          break;
        default:
          break;
      }

      onPushCurrentPopupName(popupName);
    },
    [reset, onPushCurrentPopupName]
  );

  const onConfirm = useCallback(
    ({ data, type }) => {
      const {
        userId,
        phoneNo,
        phoneNo0,
        phoneNo1,
        phoneNo2,
        checkPhoneFlag,
        email,
        checkEmailFlag,
        commentApproval,
        commentDelete,
        userHistKey,
      } = data;

      switch (type) {
        case SUBMIT_TYPE.PHONE:
          const updatePhoneParams = {
            approvalUserId: userData.userId,
            approvalTarget: APPROVAL_TARGET.PHONE,
            approvalStatus: PERMISSIONS.APPROVED,
            userId,
            changedPhoneNo: `${phoneNo0}-${phoneNo1}-${phoneNo2}`,
            checkPhoneFlag: 'Y',
            changedEmail: email,
            checkEmailFlag: 'Y',
            commentApproval: '',
            userHistKey,
          };

          postApproveVendorUserAccount(dispatch, updatePhoneParams);
          onPopupClose();
          break;
        case SUBMIT_TYPE.EMAIL:
          const updateEmailParams = {
            approvalUserId: userData.userId,
            approvalTarget: APPROVAL_TARGET.EMAIL,
            approvalStatus: PERMISSIONS.APPROVED,
            userId,
            changedPhoneNo: phoneNo,
            checkPhoneFlag: 'Y',
            changedEmail: email,
            checkEmailFlag: 'Y',
            commentApproval: '',
            userHistKey,
          };

          postApproveVendorUserAccount(dispatch, updateEmailParams);
          onPopupClose();
          break;
        case SUBMIT_TYPE.STATUS_APPROVED:
          const approvedParams = {
            approvalUserId: userData.userId,
            approvalTarget: APPROVAL_TARGET.ACCOUNT,
            approvalStatus: PERMISSIONS.APPROVED,
            userId,
            changedPhoneNo: `${phoneNo0}-${phoneNo1}-${phoneNo2}`,
            checkPhoneFlag: checkPhoneFlag ? 'Y' : 'N',
            changedEmail: email,
            checkEmailFlag: checkEmailFlag ? 'Y' : 'N',
            commentApproval,
            userHistKey: userHistKey ?? '',
          };

          postApproveVendorUserAccount(dispatch, approvedParams);
          onPopupClose();
          break;
        case SUBMIT_TYPE.STATUS_REJECTED:
          const rejectedParams = {
            approvalUserId: userData.userId,
            approvalTarget: APPROVAL_TARGET.ACCOUNT,
            approvalStatus: PERMISSIONS.REJECTED,
            userId,
            changedPhoneNo: `${phoneNo0}-${phoneNo1}-${phoneNo2}`,
            checkPhoneFlag: checkPhoneFlag ? 'Y' : 'N',
            changedEmail: email,
            checkEmailFlag: checkEmailFlag ? 'Y' : 'N',
            commentApproval,
            userHistKey: userHistKey ?? '',
          };

          postApproveVendorUserAccount(dispatch, rejectedParams);
          onPopupClose();
          break;
        case SUBMIT_TYPE.WITHDRAWAL:
          const withdrawalParams = {
            userId,
            deleteUserId: userData.userId,
            commentDelete,
          };

          postDeleteVendorUserAccount(dispatch, withdrawalParams);
          onPushCurrentPopupName(POPUP_NAME.FINAL_WITHDRAWAL_CONFIRM);
          break;
        default:
          break;
      }
    },
    [userData.userId, dispatch, onPopupClose, onPushCurrentPopupName]
  );

  const userListColumns = useMemo(
    () => [
      columnHelper.accessor('no', {
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => TABLE_DATA_LENGTH - index,
      }),
      columnHelper.accessor('userId', {
        header: () => <span>아이디</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyName', {
        header: () => <span>제휴사명</span>,
        cell: (info) => <i>{info.getValue()}</i>,
      }),
      columnHelper.accessor('companyRegNo', {
        header: () => <span>사업자번호</span>,
        cell: (info) => info.getValue(),
      }),

      columnHelper.accessor('phoneNo', {
        header: () => <span>전화번호</span>,
        cell: (info) => {
          const {
            row: {
              original: { approvalStatusPhone },
            },
          } = info;

          return approvalStatusPhone === PERMISSIONS.UNDER_APPROVAL ? (
            <button
              className={cn('btn', 'btn01', 'type04')}
              onClick={() =>
                onCellButtonClick({ info, popupName: POPUP_NAME.PHONE })
              }
            >
              변경대기
            </button>
          ) : (
            info.getValue()
          );
        },
      }),
      columnHelper.accessor('email', {
        header: () => <span>이메일</span>,
        cell: (info) => {
          const {
            row: {
              original: { approvalStatusEmail },
            },
          } = info;

          return approvalStatusEmail === PERMISSIONS.UNDER_APPROVAL ? (
            <button
              className={cn('btn', 'btn01', 'type04')}
              onClick={() =>
                onCellButtonClick({ info, popupName: POPUP_NAME.EMAIL })
              }
            >
              변경대기
            </button>
          ) : (
            info.getValue()
          );
        },
      }),
      columnHelper.accessor('joinDate', {
        header: () => <span>가입일자</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('approvalDateAccount', {
        header: () => <span>승인일자</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('approvalStatusAccount', {
        header: () => <span>상태</span>,
        cell: (info) => {
          return (
            <>
              {info.renderValue() === PERMISSIONS.APPROVED ? (
                <button
                  className={cn('btn', 'btn01', 'type04')}
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.APPROVED,
                    })
                  }
                >
                  승인
                </button>
              ) : info.renderValue() === PERMISSIONS.REJECTED ? (
                <button
                  className={cn('btn', 'btn01', 'type04')}
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.REJECTED,
                    })
                  }
                >
                  거절
                </button>
              ) : (
                <button
                  className={cn('btn', 'btn01', 'type04')}
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.UNDER_APPROVAL,
                    })
                  }
                >
                  승인대기
                </button>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor('delete', {
        header: () => <span>탈퇴</span>,
        cell: (info) => {
          return (
            <button
              className={cn('btn', 'btn01', 'type04')}
              onClick={() =>
                onCellButtonClick({
                  info,
                  popupName: POPUP_NAME.WITHDRAWAL,
                })
              }
            >
              탈퇴
            </button>
          );
        },
      }),
      columnHelper.accessor('commentApprovalAccount', {
        header: () => <span>비고</span>,
        cell: (info) => {
          const {
            row: {
              original: { approvalStatusAccount },
            },
          } = info;

          return approvalStatusAccount === PERMISSIONS.REJECTED
            ? info.renderValue()
            : '-';
        },
      }),
    ],
    [TABLE_DATA_LENGTH, onCellButtonClick]
  );

  const companyListColumns = useMemo(
    () => [
      columnHelper.accessor('radio-button-column', {
        header: () => null,
        cell: (info) => {
          const {
            row: { index, id },
          } = info;

          return (
            <RadioButton
              id={id}
              value={index}
              checked={index === selectedCompanyIndex}
              onClick={() => setSelectedCompanyIndex(index)}
            />
          );
        },
        size: 50,
      }),
      columnHelper.accessor('companyCode', {
        header: () => <span>업체코드</span>,
        cell: (info) => (info.getValue() === 'TEMP' ? '-' : info.getValue()),
      }),
      columnHelper.accessor('companyName', {
        header: () => <span>제휴사명</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('companyRegNo', {
        header: () => <span>사업자번호</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('note', {
        header: () => <span>비고</span>,
        cell: (info) => info.getValue() ?? '-',
      }),
    ],
    [selectedCompanyIndex]
  );

  useEffect(() => {
    setFiltered(sortedVendorUserList);
  }, [sortedVendorUserList]);

  useEffect(() => {
    const bizRegisDocFileName =
      registrationDocFiles && registrationDocFiles[0]?.name;
    const ecomRegisDocFileName = salesDocFiles && salesDocFiles[0]?.name;

    if (bizRegisDocFileName)
      setValueForManageCompanyCode('bizRegisDocFileName', bizRegisDocFileName);

    if (ecomRegisDocFileName)
      setValueForManageCompanyCode(
        'ecomRegisDocFileName',
        ecomRegisDocFileName
      );
  }, [registrationDocFiles, salesDocFiles, setValueForManageCompanyCode]);

  return (
    <>
      <form>
        <FormProvider {...searchForms}>
          <AuthoritySearchArea
            statusSearchOptions={statusSearchOptions}
            keywordSearchOptions={keywordSearchOptions}
            onSearchClick={onSearchClick}
            selectApprovalStatus={selectApprovalStatus}
          />
        </FormProvider>
      </form>
      <TableV8
        data={filtered}
        columns={userListColumns}
        hasCsvDownload={false}
        rightControls={
          <Button
            styleType='btn03'
            subType='type01'
            onClick={() =>
              onPushCurrentPopupName(POPUP_NAME.MANAGEMENT_COMPANY_CODE)
            }
          >
            기업 코드 관리
          </Button>
        }
      />
      {/* 기업 코드 관리 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.MANAGEMENT_COMPANY_CODE
        )}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
        title={'기업 코드 관리 상세'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  onClick={onPopupClose}
                  styleType='btn01'
                  subType='type01'
                  fullWidth
                >
                  닫기
                </Button>
              </div>
            </div>,
          ]}
        >
          <TableV8
            data={vendorCodeList}
            columns={companyListColumns}
            hasCsvDownload={false}
            hasPageSize={false}
            rightControls={[
              <Button
                styleType='btn01'
                subType='type04'
                onClick={() => {
                  selectedCompanyIndex !== -1 && onCompanyInfoChangeClick();
                }}
              >
                정보 변경
              </Button>,
            ]}
          />
        </VerticalTableContainer>
      </PopUp>
      {/* 기업 코드 관리 > 정보 변경 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.UPDATE_COMPANY_CODE)}
        onRequestClose={onPopCurrentPopup}
        closeButton={true}
        size={'auto'}
        title={'정보 변경'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button onClick={onPopCurrentPopup} subType='type03' fullWidth>
                  닫기
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  subType='type02'
                  onClick={handleSubmitForManageCompanyCode(
                    onCompanyInfoChangeConfirm
                  )}
                  fullWidth
                >
                  승인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'업체코드'}>
            {getValuesForManageCompanyCode('approvalStatus') ===
            PERMISSIONS.UNDER_APPROVAL ? (
              <HFSelectBox
                options={companyCodeOptions}
                name={'companyCode'}
                control={controlForManageCompanyCode}
                required
              />
            ) : (
              <HFInput
                type={'text'}
                name={'companyCode'}
                register={registerForManageCompanyCode}
                readOnly
              />
            )}
          </VerticalTable>
          <VerticalTable title={'제휴사 명'}>
            <HFInput
              type={'text'}
              name={'companyName'}
              register={registerForManageCompanyCode}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'사업자 번호'}>
            <HFInput
              type={'text'}
              name={'companyRegNo'}
              register={registerForManageCompanyCode}
              required
            />
          </VerticalTable>
          <VerticalTable
            title={'사업자 등록증'}
            button={
              <Button
                onClick={() => {
                  onImageUploadClick(registrationDocFilesRef);
                }}
                styleType={'btn04'}
              >
                이미지 등록
              </Button>
            }
          >
            <HFInput
              type={'text'}
              name={'bizRegisDocFileName'}
              placeholder={
                '용량 2MB 이내 / jpg, png, bmp 이미지 (예시 image.jpg)'
              }
              register={registerForManageCompanyCode}
              readOnly
            />
            <HFInput
              type={'file'}
              name={'registrationDocFiles'}
              className={cn('fileInput')}
              register={registerForManageCompanyCode}
              ref={registrationDocFilesRef}
              accept={'image/*'}
            />
          </VerticalTable>
          <VerticalTable
            title={'통신판매 신고증'}
            button={
              <Button
                onClick={() => {
                  onImageUploadClick(salesDocFilesRef);
                }}
                styleType={'btn04'}
              >
                이미지 등록
              </Button>
            }
          >
            <HFInput
              type={'text'}
              name={'ecomRegisDocFileName'}
              placeholder={
                '용량 2MB 이내 / jpg, png, bmp 이미지 (예시 image.jpg)'
              }
              register={registerForManageCompanyCode}
              readOnly
            />
            <HFInput
              type={'file'}
              name={'salesDocFiles'}
              className={cn('fileInput')}
              register={registerForManageCompanyCode}
              ref={salesDocFilesRef}
              accept={'image/*'}
            />
          </VerticalTable>
          <VerticalTable title={'비고'}>
            <HFTextarea
              name={'commentRequest'}
              maxLength={10}
              placeholder={'내용을 입력하세요'}
              register={registerForManageCompanyCode}
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      {/* 휴대전화 column의 '승인 대기' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.PHONE)}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
      >
        <div className={css.alert_body}>
          <h2>휴대전화 번호 변경 승인</h2>
        </div>
        <p className={cn('mt32')}>
          승인 요청시 이전 번호로는 LMS 수신이 중지 됩니다.
        </p>
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <Button onClick={onPopupClose}>취소</Button>
              <Button
                onClick={() => onPushCurrentPopupName(POPUP_NAME.CONFIRM_PHONE)}
              >
                승인
              </Button>
            </div>,
          ]}
        >
          <VerticalTable title={'휴대전화'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <HFInput
                  key={index}
                  type={'text'}
                  name={`phoneNo${index}`}
                  register={register}
                  readOnly
                />
              ))}
            </div>
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_PHONE)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: SUBMIT_TYPE.PHONE })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        최종 반영처리 하시겠습니까?
      </PopUpAlert>
      {/* 이메일 column의 '승인 대기' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.EMAIL)}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
      >
        <div className={css.alert_body}>
          <h2>이메일 변경 승인</h2>
        </div>
        <p className={cn('mt32')}>
          변경된 이메일 주소 확인 완료 후 승인 해주세요.
        </p>
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <Button onClick={onPopupClose}>취소</Button>
              <Button
                onClick={() => onPushCurrentPopupName(POPUP_NAME.CONFIRM_EMAIL)}
              >
                승인
              </Button>
            </div>,
          ]}
        >
          <VerticalTable title={'이메일'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              <HFInput
                type={'text'}
                name={'email'}
                register={register}
                readOnly
              />
            </div>
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_EMAIL)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: SUBMIT_TYPE.EMAIL })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        최종 반영처리 하시겠습니까?
      </PopUpAlert>
      {/* 상태 column의 '승인 대기' 버튼 클릭 시 Popup */}
      <PopUp
        title={'계정 승인'}
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.UNDER_APPROVAL)}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='popupClose'
                  onClick={onPopupClose}
                  subType='type03'
                  fullWidth
                >
                  취소
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='reject'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.CONFIRM_REJECT)
                  }
                  fullWidth
                >
                  거절
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='approval'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.CONFIRM_APPROVE)
                  }
                  fullWidth
                >
                  승인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'상호명'}>
            <HFInput
              type={'text'}
              name={'companyName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'아이디'}>
            <HFInput
              type={'text'}
              name={'userId'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'휴대전화'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <HFInput
                  key={index}
                  type={'text'}
                  name={`phoneNo${index}`}
                  register={register}
                  readOnly
                />
              ))}
            </div>
            <HFCheckbox
              id={'checkPhoneFlag'}
              name={'checkPhoneFlag'}
              className={cn('mt10')}
              register={register}
              required
            >
              확인 완료
            </HFCheckbox>
          </VerticalTable>
          <VerticalTable title={'이메일'}>
            <HFInput
              type={'text'}
              name={'email'}
              register={register}
              readOnly
            />
            <HFCheckbox
              id={'checkEmailFlag'}
              name={'checkEmailFlag'}
              className={cn('mt10')}
              register={register}
              required
            >
              확인 완료
            </HFCheckbox>
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              name={'commentApproval'}
              maxLength={10}
              placeholder={'내용을 입력하세요'}
              register={register}
            />
          </VerticalTable>
          <div className={cn('mt20')}>
            <strong>[승인] 전 확인해주세요!</strong>
          </div>
          <ul className={cn('infoList')}>
            <li>1. 업체 증빙 서류 2종 업로드</li>
            <li>2. 휴대폰 번호 및 이메일 본인 확인</li>
          </ul>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_REJECT)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: SUBMIT_TYPE.STATUS_REJECTED })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        승인 거부 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_APPROVE)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: SUBMIT_TYPE.STATUS_APPROVED })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        최종 반영처리 하시겠습니까?
      </PopUpAlert>
      {/* 상태 column의 '승인' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.APPROVED)}
        onRequestClose={onPopupClose}
        size={'auto'}
        title={'계정 승인 완료'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='isOpenApprovedPopUp'
                  onClick={onPopupClose}
                  subType='type02'
                  fullWidth
                >
                  확인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'승인 상태'}>
            <HFInput
              type={'text'}
              name={'approvalStatusAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              name={'commentApprovalAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'승인 일자'}>
            <HFInput
              type={'text'}
              name={'approvalDateAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'승인자 ID'}>
            <HFInput
              type={'text'}
              name={'approvalUserIdAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      {/* 상태 column의 '거절' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.REJECTED)}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
      >
        <div className={css.alert_body}>
          <h2>계정 승인 거절</h2>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <Button
                  key='popupClose'
                  onClick={onPopupClose}
                  subType='type03'
                >
                  취소
                </Button>
                <Button
                  key='approval'
                  onClick={handleSubmit((data) =>
                    onConfirm({ data, type: SUBMIT_TYPE.STATUS_APPROVED })
                  )}
                >
                  승인
                </Button>
              </div>,
            ]}
          >
            <VerticalTable title={'상호명'}>
              <HFInput
                type={'text'}
                name={'companyName'}
                register={register}
                readOnly
              />
            </VerticalTable>
            <VerticalTable title={'아이디'}>
              <HFInput
                type={'text'}
                name={'userId'}
                register={register}
                readOnly
              />
            </VerticalTable>
            <VerticalTable title={'휴대전화'}>
              <div
                className={cn(
                  'flex',
                  'align-center-gap8',
                  'phoneNumberContainer'
                )}
              >
                {new Array(3).fill(0).map((_, index) => (
                  <HFInput
                    key={index}
                    type={'text'}
                    name={`phoneNo${index}`}
                    register={register}
                  />
                ))}
              </div>
              <HFCheckbox
                id={'checkPhoneFlag'}
                name={'checkPhoneFlag'}
                className={cn('mt10')}
                register={register}
              >
                확인 완료
              </HFCheckbox>
            </VerticalTable>
            <VerticalTable title={'이메일'}>
              <HFInput type={'text'} name={'email'} register={register} />
              <HFCheckbox
                id={'checkEmailFlag'}
                name={'checkEmailFlag'}
                className={cn('mt10')}
                register={register}
              >
                확인 완료
              </HFCheckbox>
            </VerticalTable>
            <VerticalTable title={'코멘트'}>
              <HFTextarea
                name={'commentApproval'}
                maxLength={10}
                placeholder={'내용을 입력하세요'}
                register={register}
              />
            </VerticalTable>
            <div className={cn('mt20')}>
              <strong>[승인] 전 확인해주세요!</strong>
            </div>
            <ul className={cn('infoList')}>
              <li>1. 업체 증빙 서류 2종 업로드</li>
              <li>2. 휴대폰 번호 및 이메일 본인 확인</li>
            </ul>
          </VerticalTableContainer>
        </div>
      </PopUp>
      {/* 탈퇴 column의 '탈퇴' 버튼 클릭 시 Popup 및 탈퇴 관련 다중 경고 PopupAlert */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.WITHDRAWAL)}
        onRequestClose={onPopupClose}
        size={'auto'}
        title={'계정 탈퇴'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='cancelDeletePopUp'
                  onClick={onPopupClose}
                  subType='type03'
                  fullWidth
                >
                  확인
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='cancelDeleteVendorUser'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.FIRST_WITHDRAWAL_CONFIRM)
                  }
                  subType='type02'
                  fullWidth
                >
                  탈퇴
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'상호명'}>
            <HFInput
              type={'text'}
              name={'companyName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'아이디'}>
            <HFInput
              type={'text'}
              name={'userId'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'휴대전화'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <HFInput
                  key={index}
                  type={'text'}
                  name={`phoneNo${index}`}
                  register={register}
                  readOnly
                />
              ))}
            </div>
          </VerticalTable>
          <VerticalTable title={'이메일'}>
            <HFInput
              type={'text'}
              name={'email'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              name={'commentDelete'}
              maxLength={20}
              placeholder={'내용을 입력하세요'}
              register={register}
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.FIRST_WITHDRAWAL_CONFIRM
        )}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={() =>
                  onPushCurrentPopupName(POPUP_NAME.SECOND_WITHDRAWAL_CONFIRM)
                }
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.SECOND_WITHDRAWAL_CONFIRM
        )}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal_Alert'
                onClick={handleSubmit((data) =>
                  onConfirm({ data, type: SUBMIT_TYPE.WITHDRAWAL })
                )}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        <p>탈퇴 처리되면 복구 처리하지 못합니다.</p>
        <p>정말로 탈퇴 요청 하시겠습니까?</p>
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.FINAL_WITHDRAWAL_CONFIRM
        )}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button key='comfirm_withdrawal' onClick={onPopupClose} fullWidth>
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 처리 되었습니다.
      </PopUpAlert>
    </>
  );
};

export default VendorAccountTable;
