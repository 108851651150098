import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import css from './TableCheckbox.module.scss';

const cn = classNames.bind(css);

const TableCheckbox = ({
  indeterminate,
  disabled,
  className = '',
  ...rest
}) => {
  const ref = React.useRef(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <div className={cn('tableCheckbox')}>
      <input
        id={rest.id}
        type='checkbox'
        disabled={disabled}
        ref={ref}
        {...rest}
      />
      <label htmlFor={rest.id} className={css.label}></label>
    </div>
  );
};

export default TableCheckbox;
