import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  couponList: [],
  couponIssueTargetList: {
    additionalInfo: {
      totalCount: 10,
    },
    list: [],
  },
  invalidCouponNoList: [],
  couponIssueCompletedList: [],
  couponIssueCompletedListFailCase: [],
  willCompleteTargetCouponList: {
    additionalInfo: { totalCount: 10 },
    list: [],
  },
  couponUseApprovalStatus: { additionalInfo: { totalCount: 10 }, list: [] },
  couponOverallStatusList: {
    additionalInfo: {
      totalCount: 10,
    },
    list: [],
  },
  couponOverallStatusListById: { additionalInfo: { totalCount: 10 }, list: [] },
  couponRegisterTargetList: [],
  couponIssueStatusDashboard: {
    couponUseApprovedVendorCnt: 0,
    couponIssueFailedCnt: 0,
    couponIssuedCnt: 0,
    couponUseUnderApprovalVendorCnt: 0,
  },
  couponApprovalStatusDashboard: {
    couponUseApprovedVendorCnt: 0,
    couponUseUnderApprovalVendorName: null,
    couponUseRejectedVendorName: null,
    couponUseApprovedVendorName: '',
    couponUseUnderApprovalVendorCnt: 0,
    couponUseRejectedVendorCnt: 0,
  },
  couponOverallStatusDashboard: {
    couponUsedCnt: 0,
    couponUseApprovedCnt: 0,
    couponIssuedCnt: 0,
  },
  talkDreamCouponIssueTargetList: [],
  retrieveCouponTalkdreamResultList: [],
  retrieveCouponTalkdreamResultDetailList: [],
  retrieveCouponTalkdreamResultDashboard: [
    {
      kakaoCouponIssueSuccessedCnt: 0, // 알림톡 발행 완료 성공
      kakaoCouponIssueFailedCnt: 0, // 알림톡 발행 완료 실패
      kakaoCouponExpriedNotice30SuccessedCnt: 0, // 알림톡 폐기 알림 성공(한 달전)
      kakaoCouponExpriedNotice30FailedCnt: 0, // 알림톡 폐기 알림 실패(한 달전)
      kakaoCouponExpriedNotice15SuccessedCnt: 0, // 알림톡 폐기 완료 성공(15일전)
      kakaoCouponExpriedNotice15FailedCnt: 0, // 알림톡 폐기 완료 실패(15일전)
      kakaoCouponExpriedNotice1SuccessedCnt: 0, // 알림톡 폐기 알림 성공(1일전)
      kakaoCouponExpriedNotice1FailedCnt: 0, // 알림톡 폐기 완료 실패(1일전)
      lmsCouponIssueSuccessedCnt: 0, // LMS 발행 완료 성공
      lmsCouponIssueFailedCnt: 0, // LMS 발행 완료 실패
      lmsCouponExpriedNotice30SuccessedCnt: 0, // LMS 폐기 알림 성공(한 달전)
      lmsCouponExpriedNotice30FailedCnt: 0, // LMS 폐기 알림 실패(한 달전)
      lmsCouponExpriedNotice15SuccessedCnt: 0, // LMS 폐기 알림 성공(15일전)
      lmsCouponExpriedNotice15FailedCnt: 0, // LMS 폐기 알림 실패(15일전)
      lmsCouponExpriedNotice1SuccessedCnt: 0, // LMS 폐기 알림 성공(1일전)
      lmsCouponExpriedNotice1FailedCnt: 0, // LMS 폐기 알림 실패(1일전)
      lmsCouponNoCreateRequestSuccessedCnt: 0, // LMS 난수 번호 추가 성공
      lmsCouponNoCreateRequestFailedCnt: 0, // LMS 난수 번호 추가 실패

      lmsCouponIssueWaitingCnt: 0, // LMS 발행 완료 대기
      lmsCouponExpriedNotice1WaitingCnt: 0, // LMS 폐기 알림 대기(1일전)
      lmsCouponExpriedNotice15WaitingCnt: 0, // LMS 폐기 알림 대기(15일전)
      lmsCouponExpriedNotice30WaitingCnt: 0, // LMS 폐기 알림 대기(30일전)
      lmsCouponNoCreateRequestWaitingCnt: 0, // LMS 난수 번호 추가 대기
    },
  ],
  couponUseCompletedStatusDashboard: {
    couponUseCompleteRequestedCnt: 0,
    couponUseCompleteApprovedCnt: 0,
    couponUseCompleteRejectedCnt: 0,
    couponCreatedCnt: 0,
  },
  merchandiseList: [],
};
const coupon = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    getCouponList: (state, action) => {
      state.couponList = action.payload;
    },
    getCouponIssueTarget: (state, action) => {
      state.couponIssueTargetList = action.payload;
    },
    getCouponIssueCompleted: (state, action) => {
      state.couponIssueCompletedList = action.payload;
    },
    getCouponIssueCompletedFailCase: (state, action) => {
      state.couponIssueCompletedListFailCase = action.payload;
    },
    getWillCompleteTargetCouponList: (state, action) => {
      state.willCompleteTargetCouponList = action.payload;
    },
    getCouponUseApprovalStatus: (state, action) => {
      state.couponUseApprovalStatus = action.payload;
    },
    getOverallStatusList: (state, action) => {
      state.couponOverallStatusList = action.payload;
    },
    getOverallStatusListById: (state, action) => {
      state.couponOverallStatusListById = action.payload;
    },
    getCouponRegisterTargetList: (state, action) => {
      state.couponRegisterTargetList = action.payload;
    },
    getCouponIssueStatusDashboard: (state, action) => {
      state.couponIssueStatusDashboard = action.payload;
    },
    getCouponApprovalStatusDashboard: (state, action) => {
      state.couponApprovalStatusDashboard = action.payload;
    },
    getCouponOverallStatusDashboard: (state, action) => {
      state.couponOverallStatusDashboard = action.payload;
    },
    getTalkDreamCouponIssueTargetList: (state, action) => {
      state.talkDreamCouponIssueTargetList = action.payload;
    },
    getRetrieveCouponTalkdreamResultDashboard: (state, action) => {
      state.retrieveCouponTalkdreamResultDashboard = action.payload;
    },
    getRetrieveCouponTalkdreamResultList: (state, action) => {
      state.retrieveCouponTalkdreamResultList = action.payload;
    },
    getRetrieveCouponTalkdreamResultDetailList: (state, action) => {
      state.retrieveCouponTalkdreamResultDetailList = action.payload;
    },
    getCouponUseCompletedStatusDashboard: (state, action) => {
      state.couponUseCompletedStatusDashboard = action.payload;
    },
    getMerchandiseList: (state, action) => {
      state.merchandiseList = action.payload;
    },
    setInvalidCouponNoList: (state, action) => {
      // 쿠폰생성 시 실패한 난수번호 리스트,
      state.invalidCouponNoList = action.payload;
    },
  },
});

export const {
  getCouponList,
  getCouponIssueTarget,
  setInvalidCouponNoList,
  getCouponIssueCompleted,
  getWillCompleteTargetCouponList,
  getCouponUseApprovalStatus,
  getOverallStatusList,
  getOverallStatusListById,
  getCouponRegisterTargetList,
  getCouponIssueStatusDashboard,
  getCouponApprovalStatusDashboard,
  getCouponOverallStatusDashboard,
  getTalkDreamCouponIssueTargetList,
  getRetrieveCouponTalkdreamResultDashboard,
  getRetrieveCouponTalkdreamResultList,
  getRetrieveCouponTalkdreamResultDetailList,
  getCouponUseCompletedStatusDashboard,
  getMerchandiseList,
  getCouponIssueCompletedFailCase,
} = coupon.actions;
export default coupon.reducer;
