import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

/**
 * @category useHooks
 * @namespace useSelect
 * @param {{ id: string, title: string }[]} optionList
 * @param {string} initialIndex SelectBox 공통 컴포넌트 사용 시 지정해주는 초기 index.
 * @param {string} initialLabel SelectBox 공통 컴포넌트 사용 시 지정해주는 초기 label.
 * @param {function} action 전역 상태 수정을 위한 action 함수.
 * @param {string} targetKey 전역 상태에 지정해줄 key 값.
 * @param {function} onClickfunc SelectBox를 클릭했을때 부가적으로 수행해야되는 function이 있을 경우 사용
 * @returns {currentIndex, handleOnChangeSelectIndex}
 * @description SelectBox 공통 컴포넌트에서 사용하는 value, onClickItem 출력.
 */

const useSelect = ({
  optionList,
  initialIndex,
  initialLabel,
  action,
  targetKey,
  onClickfunc,
  titleKey = 'title',
}) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [newLabel, setNewLabel] = useState(initialLabel);

  const handleOnChangeSelectIndex = (event) => {
    const {
      target: { innerText },
    } = event;

    const selectedIndex = optionList.findIndex(
      (option) => option[titleKey] === innerText
    );

    setCurrentIndex(selectedIndex !== -1 ? selectedIndex : 0);
    setNewLabel(
      selectedIndex !== -1 ? optionList[selectedIndex][titleKey] : newLabel
    );

    onClickfunc && onClickfunc();

    if (action) {
      const payLoad = {
        key: targetKey,
        value: innerText,
      };
      dispatch(action(payLoad));
    }
  };
  const handleManualSetIndex = (target) => {
    //target = {id: , title: };
    setCurrentIndex(target.id);
    setNewLabel(target.title);
  };

  return [
    currentIndex,
    handleOnChangeSelectIndex,
    newLabel,
    handleManualSetIndex,
  ];
};

export default useSelect;
