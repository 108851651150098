import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import TableV8 from '@components/Table/TableV8';
import css from './LGAccountTable.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  PopUp,
  PopUpAlert,
  VerticalTable,
  VerticalTableContainer,
} from '@components/index';
import {
  postApproveLGUserAccount,
  postDeleteLGUserAccount,
} from 'redux/action/AuthorityAction';
import { InternalAuthorityInterface } from '@feature/Authority/AuthoritySlice';
import { APPROVAL_TARGET, PERMISSIONS } from '@data/Const';
import { FormProvider, useForm } from 'react-hook-form';
import AuthoritySearchArea from './modules/AuthoritySearchArea';
import {
  hasSearchText,
  isDateInRange,
  matchesCondition,
} from '@components/Search/utils/searchValidationFunctions';
import HFInput from '@components/_ReactHookForm/HFInput';
import HFCheckbox from '@components/_ReactHookForm/HFCheckbox';
import HFTextarea from '@components/_ReactHookForm/HFTextarea';
import { getByte } from '@utils';
import { openModalAlert } from '@feature/common/commonSlice';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const statusSearchOptions = {
  guide: '',
  list: [
    { id: '', title: '전체' },
    { id: PERMISSIONS.APPROVED, title: '승인' },
    { id: PERMISSIONS.UNDER_APPROVAL, title: '승인대기' },
    { id: PERMISSIONS.REJECTED, title: '거절' },
  ],
};

const keywordSearchOptions = {
  guide: '',
  list: [{ id: 'userId', title: '아이디' }],
};

const POPUP_NAME = {
  CLOSED: [],
  UNDER_APPROVAL: 'UNDER_APPROVAL',
  APPROVED: 'APPROVED',
  CONFIRM_APPROVE: 'CONFIRM_APPROVE',
  CONFIRM_REJECT: 'CONFIRM_REJECT',
  REJECTED: 'REJECTED',
  WITHDRAWAL: 'WITHDRAWAL',
  FIRST_WITHDRAWAL_CONFIRM: 'FIRST_WITHDRAWAL_CONFIRM',
  SECOND_WITHDRAWAL_CONFIRM: 'SECOND_WITHDRAWAL_CONFIRM',
  FINAL_WITHDRAWAL_CONFIRM: 'FINAL_WITHDRAWAL_CONFIRM',
};

const SUBMIT_TYPE = {
  STATUS_APPROVED: 'STATUS_APPROVED',
  STATUS_REJECTED: 'STATUS_REJECTED',
  WITHDRAWAL: 'WITHDRAWAL',
};

const TargetUserInfoInterface = {
  ...InternalAuthorityInterface,
  checkPhoneFlag: false,
  checkEmailFlag: false,
};

const LGAccountTable = ({ selectApprovalStatus }) => {
  const dispatch = useDispatch();
  const userData = useSelector(({ user: { userData } }) => userData);
  const internalAuthorityList = useSelector(
    ({ authority: { internalAuthorityList } }) => internalAuthorityList
  );
  const sortedLGUserList = useMemo(() => {
    const list = [];

    for (const key in internalAuthorityList) {
      list.push(internalAuthorityList[key]);
    }

    list.sort((a, b) => b.joinDate - a.joinDate);

    return list;
  }, [internalAuthorityList]);
  const [filtered, setFiltered] = useState(sortedLGUserList);
  const TABLE_DATA_LENGTH = useMemo(() => filtered.length, [filtered]);
  const searchForms = useForm();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: TargetUserInfoInterface,
  });

  const [currentPopupName, setCurrentPopupName] = useState(POPUP_NAME.CLOSED);

  const onSearchClick = useCallback(
    (data) => {
      const {
        startDate,
        endDate,
        statusSearchCondition,
        keywordSearchCondition,
        keyword,
      } = data;

      if (getByte(keyword) <= 3)
        return dispatch(openModalAlert({ message: '글자수를 확인 해주세요' }));

      const filteredList = sortedLGUserList.filter((LGUser) => {
        const { joinDate, approvalStatusAccount } = LGUser;

        return (
          isDateInRange({
            targetDate: new Date(joinDate),
            startDate,
            endDate,
          }) &&
          matchesCondition({
            targetCondition: approvalStatusAccount,
            searchCondition: statusSearchCondition,
          }) &&
          hasSearchText({
            targetValue: LGUser[keywordSearchCondition],
            searchText: keyword,
          })
        );
      });

      setFiltered(filteredList);
    },
    [dispatch, sortedLGUserList]
  );

  const onPushCurrentPopupName = useCallback((popupName) => {
    setCurrentPopupName((prev) => [...prev, popupName]);
  }, []);

  const onPopCurrentPopup = useCallback(() => {
    setCurrentPopupName((prev) => prev.slice(0, -1));
  }, []);

  const onPopupClose = useCallback(() => {
    reset(TargetUserInfoInterface);
    setCurrentPopupName(POPUP_NAME.CLOSED);
  }, [reset]);

  const onCellButtonClick = useCallback(
    ({ info, popupName }) => {
      const {
        row: {
          original: {
            approvalDateAccount,
            approvalStatusAccount,
            approvalUserIdAccount,
            commentApprovalAccount,
            companyName,
            email,
            phoneNo,
            userId,
            userHistKey,
          },
        },
      } = info;

      const phoneNoArr = phoneNo?.split('-');

      switch (popupName) {
        case POPUP_NAME.UNDER_APPROVAL:
        case POPUP_NAME.REJECTED:
        case POPUP_NAME.WITHDRAWAL:
          reset({
            userId,
            email,
            companyName,
            phoneNo0: phoneNoArr[0],
            phoneNo1: phoneNoArr[1],
            phoneNo2: phoneNoArr[2],
            userHistKey,
          });
          break;
        case POPUP_NAME.APPROVED:
          reset({
            approvalStatusAccount:
              approvalStatusAccount === popupName ? '승인' : '',
            commentApprovalAccount,
            approvalDateAccount,
            approvalUserIdAccount,
          });
          break;
        default:
          break;
      }

      onPushCurrentPopupName(popupName);
    },
    [onPushCurrentPopupName, reset]
  );

  const onAccountSubmit = useCallback(
    ({ data, type }) => {
      const {
        userId,
        phoneNo0,
        phoneNo1,
        phoneNo2,
        checkPhoneFlag,
        email,
        checkEmailFlag,
        commentApproval,
        commentDelete,
        userHistKey,
      } = data;

      switch (type) {
        case SUBMIT_TYPE.STATUS_APPROVED:
          const approvedParams = {
            approvalUserId: userData.userId,
            approvalTarget: APPROVAL_TARGET.ACCOUNT,
            approvalStatus: PERMISSIONS.APPROVED,
            userId,
            changedPhoneNo: `${phoneNo0}-${phoneNo1}-${phoneNo2}`,
            checkPhoneFlag: checkPhoneFlag ? 'Y' : 'N',
            changedEmail: email,
            checkEmailFlag: checkEmailFlag ? 'Y' : 'N',
            commentApproval,
            userHistKey,
          };

          postApproveLGUserAccount(dispatch, approvedParams);
          onPopupClose();
          break;
        case SUBMIT_TYPE.STATUS_REJECTED:
          const rejectedParams = {
            approvalUserId: userData.userId,
            approvalTarget: APPROVAL_TARGET.ACCOUNT,
            approvalStatus: PERMISSIONS.REJECTED,
            userId,
            changedPhoneNo: `${phoneNo0}-${phoneNo1}-${phoneNo2}`,
            checkPhoneFlag: checkPhoneFlag ? 'Y' : 'N',
            changedEmail: email,
            checkEmailFlag: checkEmailFlag ? 'Y' : 'N',
            commentApproval,
            userHistKey,
          };

          postApproveLGUserAccount(dispatch, rejectedParams);
          onPopupClose();
          break;
        case SUBMIT_TYPE.WITHDRAWAL:
          const withdrawalParams = {
            userId,
            deleteUserId: userData.userId,
            commentDelete,
          };

          postDeleteLGUserAccount(dispatch, withdrawalParams);
          onPushCurrentPopupName(POPUP_NAME.FINAL_WITHDRAWAL_CONFIRM);
          break;
        default:
          break;
      }
    },
    [userData.userId, dispatch, onPopupClose, onPushCurrentPopupName]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('no', {
        header: () => <span>No</span>,
        cell: ({ row: { index } }) => TABLE_DATA_LENGTH - index,
        size: 1, // FIXME: react table 에서 size를 설정할 수 있는 방법 좀 더 알아보기
      }),
      columnHelper.accessor('userId', {
        header: () => <span>아이디</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('phoneNo', {
        header: () => <span>회사 전화번호</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('email', {
        header: () => <span>이메일</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('joinDate', {
        header: () => <span>가입일자</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('approvalDateAccount', {
        header: () => <span>승인일자</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('approvalStatusAccount', {
        header: () => <span>상태</span>,
        cell: (info) => {
          return (
            <>
              {info.renderValue() === PERMISSIONS.APPROVED ? (
                <button
                  className={cn('btn', 'btn01', 'type04')}
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.APPROVED,
                    })
                  }
                >
                  승인
                </button>
              ) : info.renderValue() === PERMISSIONS.REJECTED ? (
                <button
                  className={cn('btn', 'btn01', 'type04')}
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.REJECTED,
                    })
                  }
                >
                  거절
                </button>
              ) : (
                <button
                  className={cn('btn', 'btn01', 'type04')}
                  onClick={() =>
                    onCellButtonClick({
                      info,
                      popupName: POPUP_NAME.UNDER_APPROVAL,
                    })
                  }
                >
                  승인대기
                </button>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor('delete', {
        header: () => <span>탈퇴</span>,
        cell: (info) => {
          return (
            <button
              className={cn('btn', 'btn01', 'type04')}
              onClick={() =>
                onCellButtonClick({
                  info,
                  popupName: POPUP_NAME.WITHDRAWAL,
                })
              }
            >
              탈퇴
            </button>
          );
        },
      }),
      columnHelper.accessor('commentApprovalAccount', {
        header: () => <span>비고</span>,
        cell: (info) => {
          const {
            row: {
              original: { approvalStatusAccount },
            },
          } = info;

          return approvalStatusAccount === PERMISSIONS.REJECTED
            ? info.renderValue()
            : '-';
        },
      }),
    ],
    [TABLE_DATA_LENGTH, onCellButtonClick]
  );

  useEffect(() => {
    setFiltered(sortedLGUserList);
  }, [sortedLGUserList]);

  return (
    <>
      <form>
        <FormProvider {...searchForms}>
          <AuthoritySearchArea
            statusSearchOptions={statusSearchOptions}
            keywordSearchOptions={keywordSearchOptions}
            onSearchClick={onSearchClick}
            selectApprovalStatus={selectApprovalStatus}
          />
        </FormProvider>
      </form>
      <TableV8 data={filtered} columns={columns} hasCsvDownload={false} />
      {/* 상태 column의 '승인 대기' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.UNDER_APPROVAL)}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
        title={'계정 승인'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='popupClose'
                  onClick={onPopupClose}
                  subType='type03'
                  fullWidth
                >
                  취소
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='reject'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.CONFIRM_REJECT)
                  }
                  fullWidth
                >
                  거절
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='approval'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.CONFIRM_APPROVE)
                  }
                  subType='type02'
                  fullWidth
                >
                  승인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'상호명'}>
            <HFInput
              type={'text'}
              name={'companyName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'아이디'}>
            <HFInput
              type={'text'}
              name={'userId'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'휴대전화'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <HFInput
                  key={index}
                  type={'text'}
                  name={`phoneNo${index}`}
                  register={register}
                  readOnly
                />
              ))}
            </div>
            <HFCheckbox
              id={'checkPhoneFlag'}
              name={'checkPhoneFlag'}
              className={cn('mt10')}
              register={register}
              required
            >
              확인 완료
            </HFCheckbox>
          </VerticalTable>
          <VerticalTable title={'이메일'}>
            <HFInput
              type={'text'}
              name={'email'}
              register={register}
              readOnly
            />
            <HFCheckbox
              id={'checkEmailFlag'}
              name={'checkEmailFlag'}
              className={cn('mt10')}
              register={register}
              required
            >
              확인 완료
            </HFCheckbox>
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              name={'commentApproval'}
              placeholder={'내용을 입력하세요'}
              maxLength={10}
              register={register}
            />
          </VerticalTable>
          <div className={cn('mt20')}>
            <strong>[승인] 전 확인해주세요!</strong>
          </div>
          <ul className={cn('infoList')}>
            <li>1. 업체 증빙 서류 2종 업로드</li>
            <li>2. 휴대폰 번호 및 이메일 본인 확인</li>
          </ul>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_REJECT)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onAccountSubmit({ data, type: SUBMIT_TYPE.STATUS_REJECTED })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        승인 거부 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.CONFIRM_APPROVE)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                아니오
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={handleSubmit((data) =>
                  onAccountSubmit({ data, type: SUBMIT_TYPE.STATUS_APPROVED })
                )}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        최종 반영처리 하시겠습니까?
      </PopUpAlert>
      {/* 상태 column의 '승인' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.APPROVED)}
        onRequestClose={onPopupClose}
        size={'auto'}
        title={'계정 승인 완료'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='isOpenApprovedPopUp'
                  onClick={onPopupClose}
                  subType='type02'
                  fullWidth
                >
                  확인
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'승인 상태'}>
            <HFInput
              type={'text'}
              name={'approvalStatusAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              name={'commentApprovalAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'승인 일자'}>
            <HFInput
              type={'text'}
              name={'approvalDateAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'승인자 ID'}>
            <HFInput
              type={'text'}
              name={'approvalUserIdAccount'}
              register={register}
              readOnly
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      {/* 상태 column의 '거절' 버튼 클릭 시 Popup */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.REJECTED)}
        onRequestClose={onPopupClose}
        closeButton={true}
        size={'auto'}
      >
        <div className={css.alert_body}>
          <h2>계정 승인 거절</h2>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <Button
                  key='popupClose'
                  onClick={onPopupClose}
                  subType='type03'
                >
                  취소
                </Button>
                <Button
                  key='approval'
                  onClick={handleSubmit((data) =>
                    onAccountSubmit({ data, type: SUBMIT_TYPE.STATUS_APPROVED })
                  )}
                >
                  승인
                </Button>
              </div>,
            ]}
          >
            <VerticalTable title={'상호명'}>
              <HFInput
                type={'text'}
                name={'companyName'}
                register={register}
                readOnly
              />
            </VerticalTable>
            <VerticalTable title={'아이디'}>
              <HFInput
                type={'text'}
                name={'userId'}
                register={register}
                readOnly
              />
            </VerticalTable>
            <VerticalTable title={'휴대전화'}>
              <div
                className={cn(
                  'flex',
                  'align-center-gap8',
                  'phoneNumberContainer'
                )}
              >
                {new Array(3).fill(0).map((_, index) => (
                  <HFInput
                    key={index}
                    type={'text'}
                    name={`phoneNo${index}`}
                    register={register}
                  />
                ))}
              </div>
              <HFCheckbox
                id={'checkPhoneFlag'}
                name={'checkPhoneFlag'}
                className={cn('mt10')}
                register={register}
                required
              >
                확인 완료
              </HFCheckbox>
            </VerticalTable>
            <VerticalTable title={'이메일'}>
              <HFInput type={'text'} name={'email'} register={register} />
              <HFCheckbox
                id={'checkEmailFlag'}
                name={'checkEmailFlag'}
                className={cn('mt10')}
                register={register}
                required
              >
                확인 완료
              </HFCheckbox>
            </VerticalTable>
            <VerticalTable title={'코멘트'}>
              <HFTextarea
                name={'commentApproval'}
                maxLength={10}
                placeholder={'내용을 입력하세요'}
                register={register}
              />
            </VerticalTable>
            <div className={cn('mt20')}>
              <strong>[승인] 전 확인해주세요!</strong>
            </div>
            <ul className={cn('infoList')}>
              <li>1. 업체 증빙 서류 2종 업로드</li>
              <li>2. 휴대폰 번호 및 이메일 본인 확인</li>
            </ul>
          </VerticalTableContainer>
        </div>
      </PopUp>
      {/* 탈퇴 column의 '탈퇴' 버튼 클릭 시 Popup 및 탈퇴 관련 다중 경고 PopupAlert */}
      <PopUp
        isOpenPopUp={currentPopupName.includes(POPUP_NAME.WITHDRAWAL)}
        onRequestClose={onPopupClose}
        size={'auto'}
        title={'계정 탈퇴'}
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key='cancelDeletePopUp'
                  onClick={onPopupClose}
                  subType='type03'
                  fullWidth
                >
                  확인
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key='cancelDeleteVendorUser'
                  onClick={() =>
                    onPushCurrentPopupName(POPUP_NAME.FIRST_WITHDRAWAL_CONFIRM)
                  }
                  subType='type02'
                  fullWidth
                >
                  탈퇴
                </Button>
              </div>
            </div>,
          ]}
        >
          <VerticalTable title={'상호명'}>
            <HFInput
              type={'text'}
              name={'companyName'}
              register={register}
              readOnly
            />
          </VerticalTable>

          <VerticalTable title={'아이디'}>
            <HFInput
              type={'text'}
              name={'userId'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'휴대전화'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <HFInput
                  key={index}
                  type={'text'}
                  name={`phoneNo${index}`}
                  register={register}
                  readOnly
                />
              ))}
            </div>
          </VerticalTable>
          <VerticalTable title={'이메일'}>
            <HFInput
              type={'text'}
              name={'email'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'코멘트'}>
            <HFTextarea
              name={'commentDelete'}
              maxLength={20}
              placeholder={'내용을 입력하세요'}
              register={register}
            />
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.FIRST_WITHDRAWAL_CONFIRM
        )}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={() =>
                  onPushCurrentPopupName(POPUP_NAME.SECOND_WITHDRAWAL_CONFIRM)
                }
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.SECOND_WITHDRAWAL_CONFIRM
        )}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={onPopCurrentPopup}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal_Alert'
                onClick={handleSubmit((data) =>
                  onAccountSubmit({ data, type: SUBMIT_TYPE.WITHDRAWAL })
                )}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        <p>탈퇴 처리되면 복구 처리하지 못합니다.</p>
        <p>정말로 탈퇴 요청 하시겠습니까?</p>
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={currentPopupName.includes(
          POPUP_NAME.FINAL_WITHDRAWAL_CONFIRM
        )}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button key='comfirm_withdrawal' onClick={onPopupClose} fullWidth>
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 처리 되었습니다.
      </PopUpAlert>
    </>
  );
};

export default LGAccountTable;
