import React, { useMemo, useState } from 'react';
import CouponRegisterFirst from './CouponRegisterFirst/CouponRegisterFirst';
import CouponRegisterSecond from './CouponRegisterSecond/CouponRegisterSecond';
import classNames from 'classnames/bind';
import css from './CouponRegisterStep.module.scss';
import { BreadCrumbs } from '@components/index';
import { useEffect } from 'react';
import {
  getCouponListFromMerchandise,
  getCouponIssueTargetList,
  getCouponRegisterTargetListAPI,
} from 'redux/action/couponAction';
import {
  MakeTwoDigitDate,
  createQueryString,
  convertDateToString2,
} from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCouponRegisterTargetDashboard } from 'redux/action/dashBoardAction';
import flow_coupon_register from '@assets/img/img_flow_coupon_register.png';
import {
  ROUTER_PATH,
  BASE_URL,
  PRODUCT_SUB_PATH,
  DELIVERY_SUB_PATH,
  COUPON_SUB_PATH,
  REFUND_SUB_PATH,
  ACCOUNTING_SUB_PATH,
} from '@router/routerPath';
import {
  MERCHANDISE_SEARCH_CATEGORY,
  MERCHANDISE_SEARCH_TYPE,
} from '@data/Const';

const cn = classNames.bind(css);

const CouponRegisterStep = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchparams, setSearchParams] = useSearchParams();
  const vendorKeyForLGUser = searchparams.get('vendorKey');
  const userData = useSelector((state) => state.user.userData);
  // const couponList = useSelector((state) => state.coupon.couponList);
  const couponRegisterTargetDashboard = useSelector(
    (state) => state.dashBoard.couponRegisterTargetDashboard
  );
  const couponIssueTargetList = useSelector(
    (state) => state.coupon.couponIssueTargetList.list
  );
  //FIXME: list count 추가해야함
  const couponRegisterTargetList = useSelector(
    (state) => state.coupon.couponRegisterTargetList
  );

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, []);

  const handleNavigateOnlyLG = useCallback((path, state) => {
    navigate(path, { state: state });
  }, []);

  const registerRequiredList = useMemo(() => {
    return couponRegisterTargetList?.filter(
      (item) => item.salesStatusDesc === '사용'
    );
  }, [couponRegisterTargetList]);

  const registerRequiredCount = useMemo(() => {
    let count = 0;
    couponRegisterTargetList.forEach((target) => {
      if (
        target.salesStatusDesc === '사용' &&
        target.issueRequiredCouponCnt > 0
      ) {
        count = count + target.issueRequiredCouponCnt;
      }
    });
    return count;
  }, [couponRegisterTargetList]);

  // useEffect(() => {
  //   const TODAY = new Date();
  //   const year = TODAY.getFullYear();
  //   const month = MakeTwoDigitDate(TODAY.getMonth() + 1);
  //   const date = MakeTwoDigitDate(TODAY.getDate() + 1);
  //   const toDate = `${year}${month}${date}`;

  //   if (process.env.REACT_APP_MODE === 'LG') {
  //     let targetVendorKey;
  //     if (vendorKeyForLGUser) {
  //       targetVendorKey = vendorKeyForLGUser;
  //     }
  //     const tempIssueTargetParams = {
  //       limit: '1000',
  //       page: '1',
  //       vendorKey: targetVendorKey,
  //     };
  //     getCouponIssueTargetList(dispatch, tempIssueTargetParams);
  //   }

  //   const vendorCouponListParams = {
  //     authority: userData.authority,
  //     vendorKey: userData.vendorKey,
  //     searchCategory: 'CR',
  //     searchPeriodType: 'MANUAL',
  //     preDefinedType: '',
  //     fromDate: '20230801',
  //     toDate,
  //     searchType: '',
  //     detailCondition: '',
  //   };
  //   const lgCouponListParams = {
  //     authority: vendorKeyForLGUser ? 'LEVEL_3' : userData.authority,
  //     vendorKey: vendorKeyForLGUser ? vendorKeyForLGUser : userData.vendorKey,
  //     searchCategory: 'CR',
  //     searchPeriodType: 'MANUAL',
  //     preDefinedType: '',
  //     fromDate: '20230801',
  //     toDate,
  //     searchType: '',
  //     detailCondition: '',
  //   };
  //   getCouponListFromMerchandise(
  //     dispatch,
  //     process.env.REACT_APP_MODE === 'LG'
  //       ? lgCouponListParams
  //       : vendorCouponListParams
  //   );

  // }, [userData]);

  useEffect(() => {
    const tempParams = {
      authority: vendorKeyForLGUser ? 'LEVEL_3' : userData.authority,
      vendorKey: vendorKeyForLGUser
        ? vendorKeyForLGUser
        : process.env.REACT_APP_MODE === 'LG'
          ? ''
          : userData.vendorKey,
      limit: '100',
      page: '1',
    };
    getCouponRegisterTargetListAPI(dispatch, tempParams);
    const dashBoardtParams = {
      vendorKey:
        process.env.REACT_APP_MODE === 'LG' && vendorKeyForLGUser
          ? vendorKeyForLGUser
          : process.env.REACT_APP_MODE === 'LG'
            ? ''
            : userData.vendorKey,
    };
    getCouponRegisterTargetDashboard(dispatch, dashBoardtParams);
  }, [userData, vendorKeyForLGUser]);
  const vendorList = useSelector((state) => state.common.vendorList);
  const targetVendorMemo = useMemo(() => {
    let ret;
    if (process.env.REACT_APP_MODE === 'LG') {
      if (vendorKeyForLGUser) {
        let targetVendorCompanyName;
        vendorList.forEach((item) => {
          if (item.vendorKey === vendorKeyForLGUser) {
            targetVendorCompanyName = item.companyName;
          }
        });
        ret = createQueryString({
          approvalStatus: '',
          searchType: 'VNAME',
          detailCondition: targetVendorCompanyName,
          searchCategory: 'CR',
          category: 'COUPON',
          page: '1',
          limit: '100',
        });
      } else {
        ret = createQueryString({
          approvalStatus: '',
          searchType: '',
          detailCondition: '',
          searchCategory: 'CR',
          authority: userData.authority,
          category: 'COUPON',
          vendorKey: userData.vendorKey,
          page: '1',
          limit: '100',
        });
      }
    } else {
      ret = createQueryString({
        approvalStatus: '',
        searchType: '',
        detailCondition: '',
        searchCategory: 'CR',
        authority: userData.authority,
        category: 'COUPON',
        vendorKey: userData.vendorKey,
        page: '1',
        limit: '100',
      });
    }

    return ret;
  }, [vendorKeyForLGUser, userData, vendorList]);
  return (
    <>
      <h2 className={css.pageTitle}>
        쿠폰 생성 등록
        {/* {process.env.REACT_APP_MODE === 'LG' ? (
          <span className={css.additionalInfo}>
            {`생성 필요한 쿠폰이 `}
            <span
              className={css.pointer_cursor}
              onClick={() => handleNavigate('/' + COUPON_SUB_PATH.LIST)}
            >
              {registerRequiredCount}
            </span>
            {` 확인되었습니다.`}
          </span>
        ) : null} */}
      </h2>

      <div className={css.statusDashBoard}>
        <div className={css.status}>
          <dl>
            <dt>시스템 등록 쿠폰 수</dt>
            <dd>
              <div className={css.value}>
                <span
                  className={cn(
                    css.num,
                    process.env.REACT_APP_MODE === 'LG'
                      ? css.pointer_cursor
                      : null
                  )}
                  onClick={() =>
                    process.env.REACT_APP_MODE === 'LG'
                      ? handleNavigateOnlyLG('/' + COUPON_SUB_PATH.LIST, {
                          sysRegRamdomNumber: 'sysRegRamdomNumber',
                        })
                      : undefined
                  }
                >
                  {couponRegisterTargetDashboard?.unusedCouponCnt ?? 0}
                </span>
                건
              </div>
              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
            <div className={cn('desc')}>
              (접속한 제휴사의 상품에 등록된 난수 번호 합계)
            </div>
          </dl>
          <dl>
            <dt>쿠폰 상품 등록 수</dt>
            <dd>
              <div className={css.value}>
                <span
                  className={cn(css.num, css.pointer_cursor)}
                  onClick={() =>
                    handleNavigate(
                      '/' + PRODUCT_SUB_PATH.LIST + '?' + `${targetVendorMemo}`
                    )
                  }
                >
                  {couponRegisterTargetDashboard.merchandiseNfocCnt +
                    couponRegisterTargetDashboard.merchandiseFocCnt}
                </span>
                건
              </div>
              <ul className={css.detail}>
                <li>
                  {`유상 `}
                  <span className={cn('color-pink')}>
                    {couponRegisterTargetDashboard.merchandiseNfocCnt}
                  </span>
                  건
                </li>
                <li>
                  {`무상 `}
                  <span>{couponRegisterTargetDashboard.merchandiseFocCnt}</span>
                  건
                </li>
              </ul>
              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
            <div className={cn('desc')}>
              (접속한 제휴사의 전체 (쿠폰)상품 아이디 합계)
            </div>
          </dl>
          <dl>
            <dt>전월 일별 쿠폰 발행 수</dt>
            <dd>
              <div className={css.value}>
                <span className={css.num}>
                  {couponRegisterTargetDashboard.dailyAvgCouponCnt}
                </span>
                건
                <button type='button' className={cn('co_list_btn')}>
                  업체 목록
                  <div className={cn('co_list')}>
                    직전 30일 평균 쿠폰 발행 수
                  </div>
                </button>
              </div>
              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
            <div className={cn('desc')}>(전월 일 평균 쿠폰 발행 수)</div>
          </dl>
          {/* <dl>
            <dt>일별 추천 쿠폰 등록 수</dt>
            <dd>
              <div className={css.value}>
                <span className={css.num}>
                  {couponRegisterTargetDashboard.issueRequiredCouponCnt}
                </span>
                건
                <button type='button' className={cn('co_list_btn')}>
                  업체 목록
                  <div className={cn('co_list')}>
                    직전 30일 평균 쿠폰 발행 수 x 1.5 - 시스템 등록 쿠폰 수
                  </div>
                </button>
              </div>
              <p className={css.standard}>* 접속 월 기준</p>
            </dd>
          </dl> */}
        </div>
      </div>
      <div className={cn('flowChart')}>
        <h3 className={cn('title')}>쿠폰 발행 Flow</h3>
        <div className={cn('img')}>
          <img src={flow_coupon_register} alt='상품 조회 Flow 이미지' />
        </div>
      </div>
      <div className={cn('stepsFlow', 'mt32')}>
        <div className={cn('steps', 'on')}>
          쿠폰 생성 등록<span className={css.badge}>진행중</span>
        </div>
        <div className={css.steps}>쿠폰 발행</div>
        <div className={css.steps}>쿠폰 사용 등록</div>
        <div className={css.steps}>사용 확정 요청</div>
      </div>
      <CouponRegisterFirst registerRequiredList={registerRequiredList} />
    </>
  );
};

export default CouponRegisterStep;
