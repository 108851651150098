import { memo } from 'react';
import css from './TextArea.module.scss';
import classNames from 'classnames/bind';
import { getByte, getLimitedByteText } from '@utils';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace TextArea
 * @param {string} id TextArea 의 고유 id.
 * @param {string} value TextArea value.
 * @param {boolean} isLabel TextArea 의 해당 label text 존재 여부.
 * @param {string} label TextArea 의 해당 label text.
 * @param {string} placeholder TextArea placeholder.
 * @param {boolean} hasByte Byte 제한 존재 여부.
 * @param {number} maxByte TextArea 최대 제한 Byte 수.
 * @param {boolean} required TextArea 필수입력 여부.
 * @param {boolean} disabled TextArea 비활성화 여부.
 * @param {string} row TextArea 의 row (높이 조절 기능)
 * @description 공통적으로 사용하는 TextArea 컴포넌트.
 */

const TextArea = ({
  id,
  value,
  isLabel,
  label,
  placeholder,
  hasByte,
  maxByte,
  required,
  disabled,
  row,
  ...rest
}) => {
  // return (
  //   <div className={css.container}>
  //     {isLabel && <label htmlFor={id}>{label}</label>}
  //     <textarea
  //       id={id}
  //       className={cn('textArea', { required, disabled, bytePadding: hasByte })}
  //       value={hasByte ? getLimitedByteText(value, maxByte) : value}
  //       placeholder={placeholder}
  //       required={required}
  //       disabled={disabled}
  //       rows={row}
  //       {...rest}
  //     />
  //     {hasByte && (
  //       <div className={css.byteCount}>
  //         <span>{getByte(value)}</span>/<span>{maxByte}</span> Bytes
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <>
      <div className={css.container}>
        {isLabel && <label htmlFor={id}>{label}</label>}
        <textarea
          id={id}
          className={cn('textArea', {
            required,
            disabled,
          })}
          value={hasByte ? getLimitedByteText(value, maxByte) : value}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          rows={row}
          {...rest}
        />
      </div>
      {hasByte && (
        <div className={css.byteCount}>
          <span>{getByte(value)}</span>/<span>{maxByte.toLocaleString()}</span>{' '}
          Bytes
        </div>
      )}
    </>
  );
};

TextArea.defaultProps = {
  /** TextArea의 value 값 */
  value: '',
  /** TextArea의 label 존재 유무 */
  isLabel: false,
  /** TextArea의 byteCount 존재 유무 */
  hasByte: false,
  /** TextArea의 필수 입력 토글 */
  required: false,
  /** TextArea 비활성화 활성화 토글 */
  disabled: false,
  /** TextArea 높이를 위한 row */
  row: 2,
};

export default memo(TextArea);
