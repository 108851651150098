import classNames from 'classnames/bind';
import css from './ProductListSearch.module.scss';
import { useEffect, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import HFInput from '@components/_ReactHookForm/HFInput';
import { DISABLED_DATE_TYPE, MILLISECONDS_PER_DAY } from '@data/Const';
import { Button } from '@components/index';

const cn = classNames.bind(css);
const DATE_NOW = Date.now();

const RADIO_INPUT_VALUES = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  ONE_MONTH: 'ONE_MONTH',
  THREE_MONTH: 'THREE_MONTH',
};

const ProductListSearch = ({
  onSearchClick,
  criteriaSearchOptions,
  detailedSearchOptions,
}) => {
  const { control, register, handleSubmit, watch, setValue } = useFormContext();
  const radioDateWatch = watch('radioDate');
  const radioInputId = useId();

  useEffect(() => {
    if (radioDateWatch !== undefined) {
      switch (radioDateWatch) {
        case RADIO_INPUT_VALUES.ONE_MONTH:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30));
          break;
        case RADIO_INPUT_VALUES.TODAY:
          setValue('fromDate', new Date(DATE_NOW));
          break;
        case RADIO_INPUT_VALUES.WEEK:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 7));
          break;
        case RADIO_INPUT_VALUES.THREE_MONTH:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 90));
          break;
        default:
          break;
      }
      setValue('toDate', new Date(DATE_NOW));
    }
  }, [radioDateWatch, setValue]);

  return (
    <div className={cn('searchOptionArea', 'row', 'mt20')}>
      <div className={cn('flex')}>
        <div className={cn('innerBox')}>
          <strong className={cn('title')}>검색 기준일</strong>
          <div className={cn('innerBox')}>
            <div className={cn('selectbox', 'type01', 'sizeS', 'ml20')}>
              <HFSelectBox
                name={'searchCategory'}
                control={control}
                options={criteriaSearchOptions}
              />
            </div>
          </div>
        </div>
        <div className={cn('innerBox', 'date')}>
          <HFCalendar
            name={'fromDate'}
            control={control}
            disabledDateTypeList={[
              DISABLED_DATE_TYPE.HOLIDAY,
              DISABLED_DATE_TYPE.WEEKEND,
            ]}
          />
          <span className={cn('dash')}>-</span>
          <HFCalendar
            name={'toDate'}
            control={control}
            disabledDateTypeList={[
              DISABLED_DATE_TYPE.HOLIDAY,
              DISABLED_DATE_TYPE.WEEKEND,
            ]}
          />
        </div>
        <div className={cn('input_radio', 'ml50')}>
          <HFInput
            name={'radioDate'}
            register={register}
            type={'radio'}
            id={`${radioInputId}-today`}
            value={RADIO_INPUT_VALUES.TODAY}
          />
          <label htmlFor={`${radioInputId}-today`}>오늘</label>
        </div>
        <div className={cn('input_radio')}>
          <HFInput
            name={'radioDate'}
            register={register}
            type={'radio'}
            id={`${radioInputId}-week`}
            value={RADIO_INPUT_VALUES.WEEK}
          />
          <label htmlFor={`${radioInputId}-week`}>일주일</label>
        </div>
        <div className={cn('input_radio')}>
          <HFInput
            name={'radioDate'}
            register={register}
            type={'radio'}
            id={`${radioInputId}-oneMonth`}
            value={RADIO_INPUT_VALUES.ONE_MONTH}
          />
          <label htmlFor={`${radioInputId}-oneMonth`}>1개월</label>
        </div>
        <div className={cn('input_radio')}>
          <HFInput
            name={'radioDate'}
            register={register}
            type={'radio'}
            id={`${radioInputId}-threeMonth`}
            value={RADIO_INPUT_VALUES.THREE_MONTH}
            className={cn('flex')}
          />
          <label htmlFor={`${radioInputId}-threeMonth`}>3개월</label>
        </div>
      </div>
      <div className={cn('flex')}>
        <div className={cn('innerBox', 'status')}>
          <strong className={cn('title')}>상세 검색</strong>
          <div className={cn('selectbox', 'type01', 'sizeS')}>
            <HFSelectBox
              name={'searchType'}
              control={control}
              options={detailedSearchOptions}
            />
          </div>
          <div className={cn('inputWrap')}>
            <HFInput
              register={register}
              name={'detailCondition'}
              type={'text'}
              placeholder={'정산, 제휴사명, 상품명'}
            />
            <Button
              styleType={'btn01'}
              subType={'type03'}
              className={cn('btn')}
              onClick={handleSubmit(onSearchClick)}
            >
              검색
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListSearch;
