import React, { useState, memo, useRef } from 'react';
import css from './SlidingButton.module.scss';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import classNames from 'classnames/bind';
import * as Utils from 'utils/utils';
import { SlidingCalendar } from '..';

const cn = classNames.bind(css);

const SlidingButton = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('slide-default');
  const dateRef = useRef();

  // 이전 데이터로 이동
  const handlePrevClick = () => {
    const newIndex = (currentIndex - 1 + data.length) % data.length;
    setSlideDirection('slide-left');
    setCurrentIndex(newIndex);
  };

  // 다음 데이터로 이동
  const handleNextClick = () => {
    const newIndex = (currentIndex + 1) % data.length;
    setSlideDirection('slide-right');
    setCurrentIndex(newIndex);
  };

  // 슬라이딩 애니메이션 종료 후 슬라이딩 방향 상태 초기화
  const handleTransitionEnd = () => {
    setSlideDirection('slide-default');
  };

  return (
    <div className={css.container}>
      <button className={css.button} onClick={handlePrevClick}>
        ◀
      </button>
      <span
        className={cn(`${css.container} ${css[slideDirection]}`, 'code')}
        onTransitionEnd={handleTransitionEnd}
      >
        {data[currentIndex].key}
      </span>
      <button className={cn('button', 'next')} onClick={handleNextClick}>
        ▶
      </button>
    </div>
  );
};

SlidingButton.defaultProps = {
  data: [
    {
      id: 0,
      key: 'GCA0005',
    },
    {
      id: 1,
      key: 'GCA0006',
    },
    {
      id: 2,
      key: 'GCA0075',
    },
  ],
};
export default memo(SlidingButton);
