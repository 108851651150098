import React, { useCallback, useContext, useEffect } from 'react';
import { Input, Button } from '@components';
import { useInput } from '@hooks';
import css from './Search.module.scss';
import { memo } from 'react';
import classNames from 'classnames/bind';
import { convertDateToString2 } from 'utils/utils';
import { searchContext } from './SearchContainer';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category Components
 * @namespace Search
 * @param {string[]} data 검색 전 전체 데이터
 * @param {string[]} searchKeys 검색에 사용될 데이터 키 값들의 배열
 * @param {string} targetDateKey 기간 검색에 사용될 날짜 데이터 키 값
 * @function updateFilteredData 업데이트 된 필터 데이터를 설정하는 함수
 * @param {boolean} fullWidth 넓이 100% 여부.
 * @param {date} startDate 시작일
 * @param {date} endDate 종료일
 * @param {string} className Search component container에 전달되는 클래스 명
 * @function selectCondition selectBox에 선택된 값으로 필터링 해주는 커스텀 함수
 * @param {Object} selectList - 검색에 사용될 추가적인 조건(selectBox key와 값)을 담은 객체.
 */

const MultiSelectSearch = ({
  data,
  searchKeys,
  targetDateKey,
  placeholder,
  updateFilteredData,
  fullWidth,
  startDate,
  endDate,
  className,
  searchConditionIndex,
  onSearchClick,
  selectList,
}) => {
  const [searchText, setSearchText, onChangeSearchText] = useInput('');
  const context = useContext(searchContext);

  const _onSearchClick = useCallback(
    (e) => {
      e.preventDefault();

      if (onSearchClick) {
        onSearchClick(searchText);
        setSearchText('');
        return;
      }

      const filteredData = data.filter((item) => {
        const targetDate = targetDateKey && new Date(item[targetDateKey]);

        const isDateInRange =
          (!startDate ||
            convertDateToString2(targetDate) >=
              convertDateToString2(startDate)) &&
          (!endDate ||
            convertDateToString2(targetDate) <= convertDateToString2(endDate));

        const matchesSelectList = () => {
          for (const [key, value] of Object.entries(selectList)) {
            if (item[key] !== value) {
              return false;
            }
          }
          return true;
        };

        if (!searchText) return isDateInRange && matchesSelectList();

        const hasSearchText = () => {
          if (!searchKeys.length) {
            return false;
          } else {
            let score = 0;

            for (const key of searchKeys) {
              const value = item[key] ?? '';
              score += Number(
                value.toUpperCase().includes(searchText.toUpperCase())
              );
            }

            return Boolean(score);
          }
        };
        return isDateInRange && hasSearchText() && matchesSelectList();
      });

      updateFilteredData(filteredData);
      setSearchText('');
    },
    [
      onSearchClick,
      data,
      searchKeys,
      targetDateKey,
      searchText,
      setSearchText,
      updateFilteredData,
      startDate,
      endDate,
      selectList,
    ]
  );

  useEffect(() => {
    if (context) {
      const { setSearchQuery } = context;

      setSearchQuery &&
        setSearchQuery((prev) => {
          prev[searchConditionIndex - 1].value = searchText;
          return prev;
        });
    }
  }, [context, searchText, searchConditionIndex]);

  return (
    <div className={cn('container', fullWidth && 'fullWidth', className)}>
      <form className={css.form}>
        <Input
          type='text'
          value={searchText}
          name={searchText}
          onChange={onChangeSearchText}
          isLabel
          placeholder={placeholder}
          fullWidth={fullWidth}
        />
        <div className={css.buttonContainer}>
          <Button subType={'type03'} onClick={_onSearchClick} fullWidth>
            검색
          </Button>
        </div>
      </form>
    </div>
  );
};

MultiSelectSearch.defaultProps = {
  data: [],
  searchKeys: [],
  placeholder: '검색어를 입력해주세요.',
  fullWidth: true,
  buttonClass: null,
};

export default memo(MultiSelectSearch);
