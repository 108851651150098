import { TAxios } from 'redux/function/TAxios';
import { setVendorList } from '@feature/common/commonSlice';

/**
 * @category Action
 * @namespace getCheckUserAuth
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 */

// 회원가입 제휴사명 리스트 [SelectBox]
const getRetrieveVendorList = (dispatch, params) => {
  const URL = '/api/1.0/app/common/retrieveVendorList';

  const onSuccess = (response) => {
    if (response.data?.rs?.list) {
      dispatch(setVendorList(response.data.rs.list));
    }
  };
  const onFail = (response) => {
    console.log('getCheckUserAuth onFail ', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, true);
};

export { getRetrieveVendorList };
