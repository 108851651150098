import classNames from 'classnames/bind';
import css from './ProductListDashBoard.module.scss';
import { getRetrieveMerchandiseDashboard } from 'redux/action/productAction';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PRODUCT_SUB_PATH } from '@router/routerPath';
import { dateToStr, createQueryString, addHyphenToDate } from '@utils';
import {
  MERCHANDISE_SEARCH_CATEGORY,
  MERCHANDISE_SEARCH_TYPE,
  MILLISECONDS_PER_DAY,
  SERVICE_START_DATE,
} from '@data/Const';
import { convertDateToString2 } from '@utils';

const cn = classNames.bind(css);
const DATE_NOW = Date.now();

const NAVIGATE_TYPE = Object.freeze({
  TOTAL: 'TOTAL',
  USED: 'USED',
  SAVED: 'SAVED',
  UNDER_APPROVAL: 'UNDER_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
});

const ProductListDashBoard = ({ handleSearchButtonTrigger }) => {
  const dispatch = useDispatch();
  const { authority, vendorKey } = useSelector((state) => state.user.userData);
  const {
    activatedCnt,
    activatedCouponCnt,
    activatedDeliveryCnt,
    approvedCnt,
    rejectedCnt,
    savedCnt,
    totalCnt,
    underApprovalCnt,
  } = useSelector((state) => state.product.dashboardData);

  useEffect(() => {
    const params = {
      authority: authority,
      vendorKey: vendorKey,
    };

    getRetrieveMerchandiseDashboard(dispatch, params);
  }, [dispatch, authority, vendorKey]);

  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = useCallback(
    (path, type) => {
      const today = convertDateToString2(new Date());
      if (location.pathname !== '/product/list') {
        let state;
        switch (type) {
          case NAVIGATE_TYPE.TOTAL:
            state = {
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: '',
            };
            break;

          case NAVIGATE_TYPE.USED:
            state = {
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: 'USED',
              approvalStatus: 'APPROVED',
            };
            break;

          case NAVIGATE_TYPE.SAVED:
            state = {
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'SAVED',
            };
            break;

          case NAVIGATE_TYPE.UNDER_APPROVAL:
            state = {
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'UNDER_APPROVAL',
            };
            break;

          case NAVIGATE_TYPE.APPROVED:
            state = {
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'APPROVED',
            };
            break;

          case NAVIGATE_TYPE.REJECTED:
            state = {
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'REJECTED',
            };
            break;
          default:
        }
        navigate(path, { state: state });
      } else {
        switch (type) {
          case NAVIGATE_TYPE.TOTAL:
            handleSearchButtonTrigger({
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: '',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: '',
            });
            break;

          case NAVIGATE_TYPE.USED:
            handleSearchButtonTrigger({
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: 'USED',
              approvalStatus: '',
            });
            break;

          case NAVIGATE_TYPE.SAVED:
            handleSearchButtonTrigger({
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'SAVED',
            });
            break;

          case NAVIGATE_TYPE.UNDER_APPROVAL:
            handleSearchButtonTrigger({
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'UNDER_APPROVAL',
            });
            break;

          case NAVIGATE_TYPE.APPROVED:
            handleSearchButtonTrigger({
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'APPROVED',
            });
            break;

          case NAVIGATE_TYPE.REJECTED:
            handleSearchButtonTrigger({
              page: '1',
              limit: '100',
              vendorKey: vendorKey,
              searchCategory: 'CR',
              searchPeriodType: 'MANUAL',
              fromDate: '2023-08-01',
              toDate: today,
              searchType: 'FOCFLAG',
              detailCondition: '',
              authority: authority,
              salesStatus: '',
              approvalStatus: 'REJECTED',
            });
            break;

          default:
        }
      }
    },
    [location, handleSearchButtonTrigger, authority, vendorKey]
  );

  return (
    <div className={cn('statusDashBoard')}>
      <div className={cn('count', 'total')}>
        <div className={cn('title')}>전체 상품 등록 수(미 사용 포함)</div>
        <span className={cn('value')}>
          <span
            className={css.pointer_cursor}
            onClick={() =>
              handleNavigate('/' + PRODUCT_SUB_PATH.LIST, NAVIGATE_TYPE.TOTAL)
            }
          >
            {totalCnt}
          </span>
          건
        </span>
        <div className={cn('desc')}>(최근 30일 생성된 상품 아이디 총 건수)</div>
      </div>
      <div className={cn('count', 'sale')}>
        <div className={cn('title')}>사용 중인 상품 수</div>
        <span className={cn('value')}>
          <span
            className={css.pointer_cursor}
            onClick={() =>
              handleNavigate('/' + PRODUCT_SUB_PATH.LIST, NAVIGATE_TYPE.USED)
            }
          >
            {activatedCnt}
          </span>
          건
        </span>
        <ul className={cn('type')}>
          <li>
            쿠폰{' '}
            <span className={cn('color_primary')}>{activatedCouponCnt}</span>
          </li>
          <li>
            배송{' '}
            <span className={cn('color_pink')}>{activatedDeliveryCnt}</span>
          </li>
        </ul>
        <div className={cn('desc')}>
          (최근 30일 사용 중인 상품 아이디 총 건수)
        </div>
      </div>
      <div className={cn('status', 'step')}>
        <dl>
          <dt>상품 정보 작성</dt>
          <dd>
            <div className={cn('value')}>
              <span
                className={cn('num')}
                onClick={() =>
                  handleNavigate(
                    '/' + PRODUCT_SUB_PATH.LIST,
                    NAVIGATE_TYPE.SAVED
                  )
                }
              >
                {savedCnt}
              </span>
              건
            </div>
            <div className={cn('desc')}>
              (최근 30일 상품등록이 '저장완료' 상태)
            </div>
          </dd>
        </dl>
        <dl>
          <dt>상품 검토</dt>
          <dd>
            <div className={cn('value')}>
              <span
                className={cn('num')}
                onClick={() =>
                  handleNavigate(
                    '/' + PRODUCT_SUB_PATH.LIST,
                    NAVIGATE_TYPE.UNDER_APPROVAL
                  )
                }
              >
                {underApprovalCnt}
              </span>
              건
            </div>
            <div className={cn('desc')}>
              (최근 30일 상품등록이 '승인대기' 상태 )
            </div>
          </dd>
        </dl>
        <dl>
          <dt>상품 등록</dt>
          <dd>
            <div className={cn('value')}>
              <span
                className={cn('num')}
                onClick={() =>
                  handleNavigate(
                    '/' + PRODUCT_SUB_PATH.LIST,
                    NAVIGATE_TYPE.APPROVED
                  )
                }
              >
                {approvedCnt}
              </span>
              건
            </div>
            <div className={cn('desc')}>
              (최근 30일 상품등록이 '승인완료' 상태 )
            </div>
          </dd>
        </dl>
      </div>
      <div className={cn('reject')}>
        <dl>
          <dt>반려</dt>
          <dd>
            <div className={cn('value')}>
              <span
                className={cn('num')}
                onClick={() =>
                  handleNavigate(
                    '/' + PRODUCT_SUB_PATH.LIST,
                    NAVIGATE_TYPE.REJECTED
                  )
                }
              >
                {rejectedCnt}
              </span>
              건
            </div>
          </dd>
        </dl>
        <div className={cn('desc')}>(최근 30일 상품등록이 '반려' 상태 )</div>
      </div>
    </div>
  );
};

export default ProductListDashBoard;
