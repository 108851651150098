import { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames/bind';
import css from './AlarmListSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { useFormContext, useWatch } from 'react-hook-form';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';

const cn = classNames.bind(css);

const AlarmListSearchArea = forwardRef(({ resultListOptions }, ref) => {
  const { register, control, watch } = useFormContext();

  const radioSelectWatch = useWatch({
    control,
    name: 'radioAllOrFail',
    defaultValue: 'all',
  });

  useImperativeHandle(ref, () => ({
    getSelectBoxWatch: () => watch('resultListCondition', 'default'),
    getRadioAllOrFailWatch: () => watch('radioAllOrFail'),
  }));

  return (
    <div className={cn('searchOptionArea', 'row', 'mt20')}>
      <div className={cn('flex')}>
        <strong className={cn('title')}>결과 선택</strong>
        <div className={css.inputCheckWrap}>
          <HFRadioButton
            name={'radioAllOrFail'}
            register={register}
            id={`all`}
            value={`all`}
            defaultValue={radioSelectWatch}
          >
            전체
          </HFRadioButton>
          <HFRadioButton
            name={'radioAllOrFail'}
            register={register}
            id={`fail`}
            value={`fail`}
          >
            실패
          </HFRadioButton>
        </div>
        <div className={cn('selectbox', 'type01', 'sizeS', 'ml20')}>
          <HFSelectBox
            control={control}
            name={'resultListCondition'}
            options={resultListOptions}
            disabled={radioSelectWatch === 'all'}
            selectApprovalStatus={false}
          />
        </div>
      </div>
    </div>
  );
});

export default AlarmListSearchArea;
