import { TAxios } from 'redux/function/TAxios';
import {
  setVendorAuthority,
  setInternalAuthority,
  setAccessControlList,
  resetChangeAccessControlTarget,
  updateVendorAuthorityList,
  deleteVendorAuthorityList,
  updateInternalAuthorityList,
  deleteInternalAuthorityList,
  setNewVendorList,
  setVendorMasterList,
  updateVendorMasterList,
  setCsmsNewVendorMasterList,
} from '@feature/Authority/AuthoritySlice';
import { setMyAuthority } from '@feature/user/userSlice';
import { ROUTER_PATH } from '@router/routerPath';
import { PERMISSIONS } from '@data/Const';
import { openModalAlert } from '@feature/common/commonSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { backendMode, createQueryString } from '@utils';
import { getUrl } from 'redux/function/TAxiosConfig';
import axios from 'axios';

const postUserAuthority = (dispatch, params) => {
  const URL = `/api/1.0/app/auth/updateAccessControl`;
  const header = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const onSuccess = () => {
    dispatch(resetChangeAccessControlTarget([]));
    dispatch(openModalAlert({ message: '수정되었습니다.' }));
  };
  const onFail = () => {
    dispatch(openModalAlert({ message: '수정에 실패하였습니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};
const postApproveVendorUserAccount = (dispatch, params) => {
  const URL = `/api/1.0/app/auth/approveUserAccount`;
  const header = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const onSuccess = () => {
    dispatch(updateVendorAuthorityList(params));
    dispatch(openModalAlert({ message: '완료 하였습니다.' }));
  };
  const onFail = () => {
    dispatch(openModalAlert({ message: '실패 하였습니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

const postApproveLGUserAccount = (dispatch, params) => {
  const URL = `/api/1.0/app/auth/approveUserAccount`;
  const header = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const onSuccess = () => {
    dispatch(updateInternalAuthorityList(params));
    dispatch(openModalAlert({ message: '완료되었습니다.' }));
  };
  const onFail = () => {
    dispatch(openModalAlert({ message: '실패하였습니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

const postDeleteVendorUserAccount = (dispatch, params) => {
  const URL = `/api/1.0/app/auth/deleteUserAccount`;
  const header = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const onSuccess = () => {
    dispatch(deleteVendorAuthorityList(params.userId));
    dispatch(openModalAlert({ message: '완료되었습니다.' }));
  };
  const onFail = () => {
    dispatch(openModalAlert({ message: '실패하였습니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

const postDeleteLGUserAccount = (dispatch, params) => {
  const URL = `/api/1.0/app/auth/deleteUserAccount`;
  const header = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const onSuccess = () => {
    dispatch(deleteInternalAuthorityList(params.userId));
    dispatch(openModalAlert({ message: '완료되었습니다.' }));
  };
  const onFail = () => {
    dispatch(openModalAlert({ message: '실패하였습니다.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

const getVendorAuthorityList = (
  dispatch,
  params,
  myAuth = false,
  handleNavigate,
  setIsOpenFail
) => {
  const URL = `/api/1.0/app/auth/retrieveVendorUserList`;
  const onSuccess = (response) => {
    const {
      data: {
        rs: { list },
      },
    } = response;

    const [target] = list;

    if (myAuth) {
      if (target.approvalStatusAccount) {
        const { joinDate } = target;
        target.joinDate = joinDate.split(' ')[0];

        switch (target.approvalStatusAccount) {
          case PERMISSIONS.UNDER_APPROVAL:
            dispatch(
              openModalAlert({ message: '관리자 승인 후 사용 가능 합니다.' })
            );
            break;
          case PERMISSIONS.REJECTED:
            setIsOpenFail(true);
            break;
          case PERMISSIONS.APPROVED:
            dispatch(setMyAuthority(target));
            handleNavigate(ROUTER_PATH.HOME);
            break;
          default:
            dispatch(
              openModalAlert({ message: '관리자 승인 후 사용 가능 합니다.' })
            );
        }
      }
    } else {
      const vendorAuthorityList = list.reduce((acc, cur) => {
        if (cur?.delFlag === 'Y') return acc;

        const accountInfo = { ...cur };
        accountInfo.joinDate = accountInfo.joinDate.split(' ')[0];
        acc[accountInfo.userId] = accountInfo;

        return acc;
      }, {});

      dispatch(setVendorAuthority(vendorAuthorityList));
    }
  };
  const onFail = () => {
    if (myAuth) {
      dispatch(openModalAlert({ message: '실패하였습니다.' }));
    }
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};
const getInternalAuthorityList = (
  dispatch,
  params,
  myAuth = false,
  handleNavigate,
  setIsOpenFail
) => {
  const URL = `/api/1.0/app/auth/retrieveInternalUserList`;
  const onSuccess = (response) => {
    const {
      data: {
        rs: { list },
      },
    } = response;

    if (myAuth) {
      const [target] = list.filter(
        (user) => user.userId === params.detailCondition
      );
      if (target.approvalStatusAccount) {
        const { joinDate } = target;

        target.joinDate = joinDate.split(' ')[0];

        switch (target.approvalStatusAccount) {
          case PERMISSIONS.UNDER_APPROVAL:
            dispatch(
              openModalAlert({ message: '관리자 승인 후 사용 가능 합니다.' })
            );
            break;
          case PERMISSIONS.REJECTED:
            setIsOpenFail(true);
            break;
          case PERMISSIONS.APPROVED:
            dispatch(setMyAuthority(target));
            handleNavigate(ROUTER_PATH.HOME);
            break;
          default:
            dispatch(
              openModalAlert({ message: '관리자 승인 후 사용 가능 합니다.' })
            );
        }
      }
    } else {
      const internalAuthorityList = list.reduce((acc, cur) => {
        if (cur?.delFlag === 'Y') return acc;

        const accountInfo = { ...cur };
        accountInfo.joinDate = accountInfo.joinDate.split(' ')[0];
        acc[accountInfo.userId] = accountInfo;

        return acc;
      }, {});

      dispatch(setInternalAuthority(internalAuthorityList));
    }
  };
  const onFail = () => {
    if (myAuth) {
      dispatch(openModalAlert({ message: '실패하였습니다.' }));
    }
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

const getAccessControlList = (dispatch) => {
  const URL = `/api/1.0/app/auth/retrieveAccessControlList`;
  const onSuccess = (response) => {
    dispatch(setAccessControlList(response?.data?.rs?.list));
  };
  const onFail = (response) => {
    console.log('onFail', response?.data?.rs?.list);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getVendorMasterList = (dispatch, params) => {
  const URL = '/api/1.0/app/auth/retrieveVendorMasterList';

  const onSuccess = (response) => {
    const {
      data: {
        rs: { list },
      },
    } = response;

    dispatch(setVendorMasterList(list));
  };
  const onFail = (response) => {
    console.log('onFail', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

const getAwaitVendorMasterList = createAsyncThunk(
  'authority/getAwaitVendorMasterList',
  async (params, thunkAPI) => {
    const serverType = backendMode();
    const path = '/api/1.0/app/auth/retrieveVendorMasterList';
    const URL = getUrl(path, serverType) + '?' + createQueryString(params);
    const header = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return axios.get(URL, header).then((value) => value.data.rs.list);
  }
);

const getNewVendorList = (dispatch) => {
  const URL = '/api/1.0/app/auth/retrieveNewVendorList';

  const onSuccess = (response) => {
    const {
      data: {
        rs: { list },
      },
    } = response;

    dispatch(setNewVendorList(list));
  };
  const onFail = (response) => {
    console.log('onFail', response?.data?.rs?.list);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const getCsmsNewVendorMasterList = (dispatch) => {
  const URL = '/api/1.0/app/auth/retrieveCsmsNewVendorMasterList';

  const onSuccess = (response) => {
    const {
      data: {
        rs: { list },
      },
    } = response;

    dispatch(setCsmsNewVendorMasterList(list));
  };
  const onFail = (response) => {
    console.log('onFail', response?.data?.rs?.list);
  };
  TAxios(undefined, undefined, 'get', URL, {}, onSuccess, onFail, false);
};

const postApproveVendorMaster = ({ dispatch, params, mutationParams }) => {
  const URL = '/api/1.0/app/auth/approveVendorMaster';
  const header = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const onSuccess = () => {
    if (mutationParams) {
      dispatch(
        updateVendorMasterList({
          shouldAddElements: true,
          params: mutationParams,
        })
      );
    } else {
      dispatch(
        updateVendorMasterList({ shouldAddElements: false, params: params })
      );
    }
  };
  const onFail = (response) => {
    console.log('onFail', response?.data?.rs?.list);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

export {
  postUserAuthority,
  postApproveVendorUserAccount,
  postApproveLGUserAccount,
  postDeleteLGUserAccount,
  postDeleteVendorUserAccount,
  getVendorAuthorityList,
  getInternalAuthorityList,
  getAccessControlList,
  getVendorMasterList,
  getAwaitVendorMasterList,
  getNewVendorList,
  getCsmsNewVendorMasterList,
  postApproveVendorMaster,
};
