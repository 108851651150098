import { useState, useCallback, useMemo, useRef } from 'react';
import css from './MyInfo.module.scss';
import classNames from 'classnames/bind';
import {
  Button,
  Input,
  VerticalTable,
  VerticalTableContainer,
  PopUp,
  PopUpAlert,
  CalendarSingle,
  TextArea,
  TextButton,
  ModalAlert,
} from '@components/index';
import { useInput, useValidation, useInputMultiple } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTER_PATH } from '@router/routerPath';
import { Link, useNavigate } from 'react-router-dom';
import {
  postChangeUserInfo,
  postWithdrawVendorUser,
  getCheckPhoneNumberExist,
  getCheckEmailExist,
  getPasswordVerificationAndChange,
  postUpdateVendorGeneralInfo,
} from 'redux/action/userAction';
import { handlePreventRegex, backendMode, createQueryString } from '@utils';
import { postLogoutBySessionExpired } from 'redux/action/loginAction';
import { openModalAlert } from '@feature/common/commonSlice';
import axios from 'axios';
import { getUrl } from 'redux/function/TAxiosConfig';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace MyInfo
 * @description 회원 정보 수정 페이지.
 */

const MyInfo = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.user.userData);
  const authorityInfo = useSelector((state) => state.user.myAuthority);
  const isOpenSessionExpired = useSelector(
    (state) => state.common.isSessionExpiredPopup
  );

  // 전역 유저 데이터
  const companyNumber = userInfo.companyRegNo;
  const companyName = userInfo.companyName;
  const companyBizName = userInfo.companyBizName;
  const companyCeoName = userInfo.companyCeoName;
  const companyCode = userInfo.companyCode;
  const companyOpenDate = userInfo.companyOpenDate;
  const vendorKey = userInfo.vendorKey;
  const gerpBillToCode = userInfo.gerpBillToCode;
  const gerpVendorCode = userInfo.gerpVendorCode;
  const userId = userInfo.userId;
  const userPhoneNumber = userInfo.phoneNo.split('-');
  const userEmail = userInfo.email;
  const userAuth = userInfo.authority;
  const bizRegDocFileKey = userInfo.bizRegDocFileKey;
  const bizRegDocFileName = userInfo.bizRegDocFileName;

  const [startDate, setStartDate] = useState(
    companyOpenDate ? new Date(companyOpenDate) : new Date()
  );
  // const [openDate, setOpenDate] = useState(new Date());

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const [
    requestCompanyNumber,
    setRequerCompanyNumber,
    onChangeRequestCompanyNumber,
  ] = useInput(companyNumber);
  const [
    requestCompanyName,
    setRequestCompanyName,
    onChangeRequestCompanyName,
  ] = useInput(companyName);
  const [
    requestCompanyBizName,
    setRequestCompanyBizName,
    onChangeRequestCompanyBizName,
  ] = useInput(companyBizName);
  const [
    requestCompanyCeoName,
    setRequestCompanyCeoName,
    onChangeRequestCompanyCeoName,
  ] = useInput(companyCeoName);

  const [comment, setComment, onChangeComment] = useInput('');

  // 유효성 확인 버튼이 눌렸는지 여부를 바꿔주는 함수
  const handlePasswordChecked = (checked) => {
    setPassWordCheck(checked);
  };

  //회원 정보 화면 관련 state
  const [id, setId, onChangeId] = useInput('');
  const [phoneNumber, setPhoneNumber, onChangePhoneNumber] = useInputMultiple({
    phoneNum1: userPhoneNumber[0],
    phoneNum2: userPhoneNumber[1],
    phoneNum3: userPhoneNumber[2],
  });

  const { phoneNum1, phoneNum2, phoneNum3 } = phoneNumber;

  const [phoneNumberCheck, setPhoneNumberCheck, onChangePhoneNumberCheck] =
    useInputMultiple({
      phoneNumCheck1: '010',
      phoneNumCheck2: '',
      phoneNumCheck3: '',
    });

  const { phoneNumCheck1, phoneNumCheck2, phoneNumCheck3 } = phoneNumberCheck;

  const [email, setEmail, onChangeEmail] = useInput(userEmail);
  const [emailCheckValue, setEmailCheckValue, onChangeEmailCheckValue] =
    useInput('');

  // 비밀번호 변경 관련 state
  const [oldPassword, setOldPassword, onChangeOldPassword] = useInput('');
  const [newPassword, setNewPassword, onChangeNewPassword] = useInput(
    '',
    handlePasswordChecked
  );
  const [
    confirmedNewPassword,
    setConfirmedNewPassword,
    onChangeConfirmedNewPassword,
  ] = useInput('');

  // 최종 password
  const [finalPassword, setFinalPassword] = useState('');

  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [isOpenEmailExistCheck, setIsOpenEmailExistCheck] = useState(false);
  const [isOpenPhoneExistCheck, setIsOpenPhoneExistCheck] = useState(false);

  const [isOpenModifyPassword, setIsOpenModifyPassword] = useState(false);
  const [isOpenRequestChange, setIsOpenRequestChange] = useState(false);

  // 중복 확인 boolean 값
  const [passwordCheck, setPassWordCheck] = useState(false);
  const [phoneNoCheck, setPhoneNoCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);

  // 탈퇴하기 관련 상태 (Level 3 ~4)
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenWithdrawalAlert, setIsOpenWithdrawalAlert] = useState(false);
  const [isConfirmWithdrawal, setIsConfirmWithdrawal] = useState(false);

  const [requiredPasswordMessage, setRequiredPasswordMessage] = useState('');

  // password 변경 되었는지 판별하는 flag
  const [passwordChangedFlag, setPasswordChangedFlag] = useState('N');

  // 이미지 파일 이름을 위한 변수
  const [registrationFileName, setRegistrationFileName] = useState('');
  const [mailOrderFileNamef, setMailOrderFileNamef] = useState('');

  // 이미지 등록 버튼과 input을 연결하기 위한 Ref
  const registrationImageInputRef = useRef();
  const mailOrderImageInputRef = useRef();

  // 이미지 파일
  const [mailOrderImage, setMailOrderImage] = useState();
  const [registrationImage, setRegistrationImage] = useState();

  // 유효성 체크
  const emailValidate = useValidation('email', emailCheckValue);
  const passwordValidate = useValidation('password', newPassword, id);
  const phoneValidate = useValidation(
    'phone',
    `${phoneNum1}-${phoneNum2}-${phoneNum3}`
  );

  const phoneNoCheckValidate = useValidation(
    'phone',
    `${phoneNumCheck1}-${phoneNumCheck2}-${phoneNumCheck3}`
  );

  const handleModifyPassword = () => {
    setIsOpenModifyPassword(true);
  };

  // 비밀번호 유효성 체크
  const handleValidation = (check) => {
    setPassWordCheck(true);
    return dispatch(openModalAlert({ message: check.message }));
  };

  const blurEvent = (condition1, condition2, message, setFunc) => {
    if (condition1 === '' || condition2 === '') {
      setFunc(message);
    } else {
      setFunc('');
    }
  };

  const handleNavigate = useCallback((path) => {
    navigate(path);
    // eslint-disable-next-line
  }, []);

  const handleSave = () => {
    const params = {
      userId: userId,
      password: finalPassword,
      passwordChangedFlag: passwordChangedFlag,
      phoneNo: `${phoneNum1}-${phoneNum2}-${phoneNum3}`,
      email: email,
    };
    if (phoneValidate.status !== 'success') {
      dispatch(
        openModalAlert({ message: '글자수를 확인 해주세요.(핸드폰 번호)' })
      );
    } else if (!phoneNum1 || !phoneNum2 || !phoneNum3 || !email) {
      dispatch(openModalAlert({ message: '정보를 확인해주세요.' }));
    } else {
      postChangeUserInfo(dispatch, params);
    }
  };

  const resetPasswordFields = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmedNewPassword('');
  };

  const handlePopUp = () => {
    setIsOpenModifyPassword(false);
  };

  const handleSaveNewPassword = () => {
    const changePasswordParams = {
      userId: userId,
      password: newPassword,
    };

    if (!oldPassword || !newPassword || !confirmedNewPassword) {
      dispatch(openModalAlert({ message: '정보를 확인해주세요' }));
    } else if (!passwordCheck) {
      dispatch(openModalAlert({ message: '비밀번호 유효성 확인을 해주세요' }));
    } else if (newPassword !== confirmedNewPassword) {
      dispatch(
        openModalAlert({
          message: '신규 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
        })
      );
    } else if (confirmedNewPassword === userId) {
      dispatch(
        openModalAlert({
          message:
            '사용 불가능한 패스워드 입니다.(아이디와 동일 하지 않게 입력해주세요.)',
        })
      );
    } else if (
      confirmedNewPassword.length < 8 ||
      confirmedNewPassword.length > 16
    ) {
      dispatch(
        openModalAlert({ message: '글자 수를 확인 해주세요. 8 ~ 16자리' })
      );
    } else if (
      confirmedNewPassword === `${phoneNum1}${phoneNum2}${phoneNum3}`
    ) {
      dispatch(
        openModalAlert({
          message:
            '비밀번호와 휴대전화 번호가 일치 합니다. 정보를 확인해주세요.',
        })
      );
    } else {
      const checkUserParams = {
        userId: userId,
        password: oldPassword,
      };

      getPasswordVerificationAndChange(
        dispatch,
        checkUserParams,
        changePasswordParams,

        handlePopUp,
        resetPasswordFields
      );

      setFinalPassword(confirmedNewPassword);
      setPasswordChangedFlag('Y');
      dispatch(openModalAlert({ message: '저장이 완료되었습니다.' }));
    }
  };

  // 핸드폰 번호 중복 여부 확인 Api
  const handleCheckPhoneNumberExist = () => {
    const param = {
      phoneNo: `${phoneNumCheck1}-${phoneNumCheck2}-${phoneNumCheck3}`,
    };
    if (!phoneNoCheckValidate.isBoolean) {
      dispatch(
        openModalAlert({ message: '올바른 휴대폰 번호를 입력해주세요.' })
      );
    } else getCheckPhoneNumberExist(dispatch, param, setPhoneNoCheck);
  };

  // 핸드폰 번호 변경 관련 함수
  const handleResetPhoneNumber = (type) => {
    if (type === 'save') {
      if (!phoneNumCheck1 || !phoneNumCheck2 || !phoneNumCheck3) {
        dispatch(
          openModalAlert({ message: '올바른 휴대폰 번호를 입력해주세요.' })
        );
      } else {
        setPhoneNumber({
          phoneNum1: phoneNumCheck1,
          phoneNum2: phoneNumCheck2,
          phoneNum3: phoneNumCheck3,
        });
        setIsOpenPhoneExistCheck(false);
      }
    }
    setPhoneNumberCheck({
      phoneNumCheck1: '010',
      phoneNumCheck2: '',
      phoneNumCheck3: '',
    });
    setPhoneNoCheck(false);
  };

  // 메일 중복 여부 확인 Api
  const handleCheckEmailExist = () => {
    const param = {
      email: emailCheckValue,
    };
    if (!emailValidate.isBoolean) {
      dispatch(openModalAlert({ message: '올바른 이메일을 입력해주세요.' }));
    } else getCheckEmailExist(dispatch, param, setEmailCheck);
  };

  // 메일 변경 관련 함수
  const handleResetEmail = (type) => {
    if (type === 'save') {
      setEmail(emailCheckValue);
    }
    setIsOpenEmailExistCheck(false);
    setEmailCheckValue('');
    setEmailCheck(false);
  };

  const handleCancel = () => {
    setIsOpenCancel(true);
  };

  const handleWithdrawalPopUp = () => {
    setIsOpenWithdrawal(true);
  };

  const handleWithdrawal = () => {
    const params = {
      companyRegNo: companyNumber,
      userId: userId,
    };

    postWithdrawVendorUser(dispatch, params, setIsConfirmWithdrawal);
  };

  const handleRequsetChangeInfo = () => {
    const params = {
      vendorKey: vendorKey,
      companyCode: companyCode,
      companyRegNo: requestCompanyNumber,
      companyName: requestCompanyName,
      companyBizName: requestCompanyBizName,
      companyCeoName: requestCompanyCeoName,
      companyOpenDate: companyOpenDate,
      supportPhoneNo: '1544-7777',
      gerpBillToCode: gerpBillToCode,
      gerpVendorCode: gerpVendorCode,
      commentRequest: comment,
      userId: userId,
      registrationDocFiles: registrationImage,
      salesDocFiles: mailOrderImage,
    };
    if (!mailOrderImage || !registrationImage) {
      dispatch(
        openModalAlert({
          message: '사업자 등록증과 통신판매업신고증 이미지를 등록해주세요.',
        })
      );
    } else
      postUpdateVendorGeneralInfo({ dispatch, params, setIsOpenRequestChange });
  };

  const onClickImageUpload = (ref) => {
    ref.current?.click();
  };

  const handleFileUpload = (e, func) => {
    if (e.target.files?.length > 0) {
      if (e.target.name === 'mailOrderImage') {
        setMailOrderImage(e.target.files);
        func(e.target.files[0].name);
      } else {
        setRegistrationImage(e.target.files);
        func(e.target.files[0].name);
      }
    }
  };

  // 유저의 LEVEL 을 판별해서 화면 다르게 렌더링 해주는 로직
  const userInModifyPage = useMemo(() => {
    let pageTitle = '';
    let isVendor = false;
    if (userAuth === 'LEVEL_1' || userAuth === 'LEVEL_2') {
      pageTitle = '회원 정보';
    } else if (userAuth === 'LEVEL_3') {
      pageTitle = '내정보';
      isVendor = true;
    }
    return { pageTitle, isVendor };
  }, [userAuth]);

  const onFileDownloadClick = useCallback(async (fileKey) => {
    if (fileKey) {
      const serverType = backendMode();
      const baseUri = '/api/1.0/app/attachment/download';
      const url = `${getUrl(baseUri, serverType)}?${createQueryString({
        fileKey,
      })}`;

      try {
        const response = await axios.get(url, {
          responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: response.data.type });
        const fileUrl = window.URL.createObjectURL(blob);
        const fileName = response.headers['content-disposition'].match(
          /attachment; filename="(.*)"/
        )[1];

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = decodeURI(fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <>
      <div className={cn('registerContainer', 'type02')}>
        <div className={cn('register_header')}>
          <Link to={ROUTER_PATH.HOME}>
            <span className={cn('copyright')}>구독 제휴사 Admin</span>
          </Link>
        </div>
        <div className={cn('register_contents')}>
          <h2 className={cn('pageTitle')}>{userInModifyPage.pageTitle}</h2>
          {userInModifyPage.isVendor && (
            <>
              <h3 className={cn('sectionTitle')}>사업자 정보</h3>
              <div className={cn('register_input3')}>
                <VerticalTableContainer>
                  <VerticalTable title={'사업자 등록 번호'}>
                    <Input
                      type='text'
                      value={companyNumber}
                      name={companyNumber}
                      readOnly
                    />
                  </VerticalTable>
                  <VerticalTable title={'제휴사명'}>
                    <Input
                      type='text'
                      name={companyName}
                      id={companyName}
                      value={companyName}
                      readOnly
                    />
                  </VerticalTable>
                  <VerticalTable title={'상호명'}>
                    <Input
                      type='text'
                      name={companyBizName}
                      id={companyBizName}
                      value={companyBizName}
                      readOnly
                    />
                  </VerticalTable>
                  <VerticalTable title={'대표자명'}>
                    <Input
                      type='text'
                      name={companyCeoName}
                      id={companyCeoName}
                      value={companyCeoName}
                      readOnly
                    />
                  </VerticalTable>
                  <VerticalTable title={'개업일자'}>
                    <CalendarSingle
                      startDate={startDate}
                      handleStartDate={handleStartDate}
                      setStartDate={setStartDate}
                      minDate={null}
                      disabled={true}
                    />
                  </VerticalTable>
                  <VerticalTable title={'사업자 등록증'}>
                    <TextButton
                      styleType={'btn_txt04'}
                      onClick={() =>
                        onFileDownloadClick(authorityInfo.bizRegisDocFileKey)
                      }
                    >
                      {authorityInfo.bizRegisDocFileName}
                    </TextButton>
                  </VerticalTable>
                  <VerticalTable title={'통신판매신고증'}>
                    <TextButton
                      styleType={'btn_txt04'}
                      onClick={() =>
                        onFileDownloadClick(authorityInfo.ecomRegisDocFileKey)
                      }
                    >
                      {authorityInfo.ecomRegisDocFileName}
                    </TextButton>
                  </VerticalTable>
                </VerticalTableContainer>
              </div>
            </>
          )}
          {userInModifyPage.isVendor && (
            <h3 className={cn('sectionTitle')}>
              운영자 개인정보
              <span className={css.ml20}>
                <Button
                  key='requestChange'
                  subType={'type04'}
                  onClick={() => setIsOpenRequestChange(true)}
                  disabled={false}
                >
                  변경 요청
                </Button>
              </span>
            </h3>
          )}
          <div
            className={cn(
              userInModifyPage.isVendor ? 'register_input3' : 'register_input2'
            )}
          >
            <VerticalTableContainer
              button={[
                <div className={css.btn_container4}>
                  {userInModifyPage.isVendor && (
                    <div className={css.btn}>
                      <Button
                        key='withdrawal'
                        onClick={handleWithdrawalPopUp}
                        subType='type03'
                        fullWidth
                      >
                        탈퇴하기
                      </Button>
                    </div>
                  )}
                  <div className={css.btn}>
                    <Button
                      key='cancel'
                      onClick={handleCancel}
                      subType='type03'
                      fullWidth
                    >
                      취소
                    </Button>
                  </div>
                  <div className={css.btn}>
                    <Button
                      key='save'
                      onClick={handleSave}
                      fullWidth
                      disabled={false}
                    >
                      저장
                    </Button>
                  </div>
                </div>,
              ]}
              isCenter
            >
              {!userInModifyPage.isVendor && (
                <VerticalTable title={'Admin'}>LGE 구독 Admin</VerticalTable>
              )}
              <VerticalTable title={'아이디'} required>
                <Input
                  type='text'
                  value={userId}
                  name={userId}
                  onChange={onChangeId}
                  minLength={5}
                  maxLength={10}
                  readOnly
                />
              </VerticalTable>
              <VerticalTable title={'비밀번호'} required>
                <Button
                  onClick={() => handleModifyPassword()}
                  styleType={'btn04'}
                >
                  변경하기
                </Button>
              </VerticalTable>
              <VerticalTable
                title={'회사 전화번호'}
                isPhoneType
                button={[
                  <Button
                    key={'checkDuplicatePhone'}
                    onClick={() => setIsOpenPhoneExistCheck(true)}
                    styleType={'btn04'}
                  >
                    변경하기
                  </Button>,
                ]}
                required
              >
                <div className={cn('flex', 'align-center-gap8')}>
                  <Input
                    type='text'
                    value={phoneNum1?.replace(/[^0-9]/g, '')}
                    name={'phoneNum1'}
                    onChange={onChangePhoneNumber}
                    minLength={3}
                    maxLength={3}
                    required={true}
                    readOnly
                  />
                  -
                  <Input
                    type='text'
                    value={phoneNum2?.replace(/[^0-9]/g, '')}
                    name={'phoneNum2'}
                    onChange={onChangePhoneNumber}
                    minLength={4}
                    maxLength={4}
                    required={true}
                    readOnly={true}
                  />
                  -
                  <Input
                    type='text'
                    value={phoneNum3?.replace(/[^0-9]/g, '')}
                    name={'phoneNum3'}
                    onChange={onChangePhoneNumber}
                    minLength={4}
                    maxLength={4}
                    required={true}
                    readOnly={true}
                  />
                </div>
              </VerticalTable>
              <VerticalTable
                title={'회사 이메일'}
                button={[
                  <Button
                    key={'checkDuplicateEmail'}
                    onClick={() => setIsOpenEmailExistCheck(true)}
                    styleType={'btn04'}
                  >
                    변경하기
                  </Button>,
                ]}
                required
              >
                <Input
                  type='email'
                  value={email}
                  name={'userEmail'}
                  onChange={onChangeEmail}
                  readOnly={true}
                />
              </VerticalTable>
            </VerticalTableContainer>
          </div>
        </div>
      </div>
      <PopUpAlert
        isOpenPopUp={isOpenWithdrawal}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={() => setIsOpenWithdrawal(false)}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={() => {
                  setIsOpenWithdrawal(false);
                  setIsOpenWithdrawalAlert(true);
                }}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={isOpenWithdrawalAlert}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={() => setIsOpenWithdrawalAlert(false)}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal_Alert'
                onClick={() => {
                  handleWithdrawal();
                  setIsOpenWithdrawalAlert(false);
                  setIsConfirmWithdrawal(true);
                }}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 처리되면 복구 처리하지 못합니다. <br />
        정말로 탈퇴 요청 하시겠습니까?
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={isConfirmWithdrawal}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='comfirm_withdrawal'
                onClick={() => setIsConfirmWithdrawal(false)}
                fullWidth
              >
                예
              </Button>
            </div>
          </div>,
        ]}
      >
        탈퇴 처리 되었습니다.
      </PopUpAlert>
      <PopUpAlert
        isOpenPopUp={isOpenCancel}
        onRequestClose={() => setIsOpenCancel(false)}
        closeButton={false}
        button={[
          <div className={css.btn_container4}>
            <div className={css.btn}>
              <Button
                key='cancel'
                subType='type03'
                onClick={() => setIsOpenCancel(false)}
                fullWidth
              >
                취소
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                key='stay'
                subType={'type02'}
                onClick={() => handleNavigate(ROUTER_PATH.HOME)}
                fullWidth
              >
                확인
              </Button>
            </div>
          </div>,
        ]}
      >
        {'이전 페이지로 돌아가시겠습니까?'}
      </PopUpAlert>
      <PopUp
        title='비밀번호 재설정'
        isOpenPopUp={isOpenModifyPassword}
        onRequestClose={() => setIsOpenModifyPassword(false)}
        closeButton
      >
        <div>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <div className={css.btn}>
                  <Button
                    key='cancel'
                    onClick={handlePopUp}
                    subType='type03'
                    fullWidth
                  >
                    취소
                  </Button>
                </div>
                <div className={css.btn}>
                  <Button key='save' onClick={handleSaveNewPassword} fullWidth>
                    저장
                  </Button>
                </div>
              </div>,
            ]}
            isCenter
          >
            <VerticalTable isTitle={false}>
              <Input
                type='password'
                value={oldPassword}
                name={oldPassword}
                onChange={onChangeOldPassword}
                minLength={8}
                maxLength={16}
                placeholder={'기존 비밀번호를 입력해 주세요'}
                onKeyDown={(e) =>
                  handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)
                }
              />
            </VerticalTable>
            <VerticalTable
              isTitle={false}
              button={
                <Button
                  onClick={() => handleValidation(passwordValidate)}
                  type='button'
                  styleType={'btn04'}
                >
                  유효성 확인
                </Button>
              }
            >
              <Input
                type='password'
                value={newPassword}
                name={newPassword}
                onChange={onChangeNewPassword}
                minLength={8}
                maxLength={16}
                placeholder={'신규 비밀번호를 입력해 주세요'}
                onBlur={() =>
                  blurEvent(
                    newPassword,
                    'default',
                    '비밀번호를 입력해 주세요',
                    setRequiredPasswordMessage
                  )
                }
                confirmedMessage={passwordCheck && passwordValidate.isBoolean}
                errormessage={requiredPasswordMessage}
                onKeyDown={(e) =>
                  handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)
                }
              />
            </VerticalTable>
            <VerticalTable isTitle={false}>
              <Input
                type='password'
                value={confirmedNewPassword}
                name={confirmedNewPassword}
                onChange={onChangeConfirmedNewPassword}
                minLength={8}
                maxLength={16}
                placeholder={'새 비밀번호를 다시 입력해 주세요'}
                onKeyDown={(e) =>
                  handlePreventRegex(e, /^[a-zA-Z0-9!@#$%^&*]*$/)
                }
                disabled={!newPassword}
              />
            </VerticalTable>
          </VerticalTableContainer>
        </div>
      </PopUp>
      <PopUp
        title='이메일 변경'
        isOpenPopUp={isOpenEmailExistCheck}
        onRequestClose={() => setIsOpenEmailExistCheck(false)}
        closeButton
      >
        <div className={css.in_popup_input_table}>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <div className={css.btn}>
                  <Button
                    key='emailResetCancel'
                    onClick={() => handleResetEmail('cancel')}
                    fullWidth
                    subType={'type03'}
                  >
                    취소
                  </Button>
                </div>
                <div className={css.btn}>
                  <Button
                    key='emailResetSave'
                    onClick={() => handleResetEmail('save')}
                    fullWidth
                    disabled={!emailCheck}
                  >
                    저장
                  </Button>
                </div>
              </div>,
            ]}
            isCenter
          >
            <VerticalTable
              title={'이메일'}
              button={
                <Button
                  onClick={() => handleCheckEmailExist()}
                  styleType={'btn04'}
                >
                  중복 확인
                </Button>
              }
            >
              <Input
                type='email'
                value={emailCheckValue}
                name={'emailCheckValue'}
                onChange={onChangeEmailCheckValue}
                placeholder={'이메일을 입력해 주세요.'}
              />
            </VerticalTable>
          </VerticalTableContainer>
        </div>
      </PopUp>
      <PopUp
        title='휴대전화 번호 변경'
        isOpenPopUp={isOpenPhoneExistCheck}
        onRequestClose={() => setIsOpenPhoneExistCheck(false)}
        closeButton
      >
        <div className={css.in_popup_input_table}>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <div className={css.btn}>
                  <Button
                    key='phoneResetCancel'
                    onClick={() => handleResetPhoneNumber('cancel')}
                    fullWidth
                    subType={'type03'}
                  >
                    취소
                  </Button>
                </div>
                <div className={css.btn}>
                  <Button
                    key='phoneResetSave'
                    onClick={() => handleResetPhoneNumber('save')}
                    fullWidth
                    disabled={!phoneNoCheck}
                  >
                    저장
                  </Button>
                </div>
              </div>,
            ]}
            isCenter
          >
            <VerticalTable
              title={'휴대폰'}
              isPhoneType
              button={
                <Button
                  onClick={handleCheckPhoneNumberExist}
                  styleType={'btn04'}
                >
                  중복 확인
                </Button>
              }
            >
              <div className={cn('flex', 'align-center-gap8')}>
                <Input
                  type='text'
                  value={phoneNumCheck1?.replace(/[^0-9]/g, '')}
                  name={'phoneNumCheck1'}
                  onChange={onChangePhoneNumberCheck}
                  minLength={3}
                  maxLength={3}
                  required={true}
                  readOnly
                />
                -
                <Input
                  type='text'
                  value={phoneNumCheck2?.replace(/[^0-9]/g, '')}
                  name={'phoneNumCheck2'}
                  onChange={onChangePhoneNumberCheck}
                  minLength={4}
                  maxLength={4}
                  required={true}
                />
                -
                <Input
                  type='text'
                  value={phoneNumCheck3?.replace(/[^0-9]/g, '')}
                  name={'phoneNumCheck3'}
                  onChange={onChangePhoneNumberCheck}
                  minLength={4}
                  maxLength={4}
                  required={true}
                />
              </div>
            </VerticalTable>
          </VerticalTableContainer>
        </div>
      </PopUp>
      <PopUp
        title='사업자 정보 변경'
        isOpenPopUp={isOpenRequestChange}
        onRequestClose={() => setIsOpenRequestChange(false)}
        closeButton
      >
        <div className={css.in_popup_input_table}>
          <VerticalTableContainer
            button={[
              <div className={css.btn_container4}>
                <div className={css.btn}>
                  <Button
                    key='phoneResetCancel'
                    onClick={() => setIsOpenRequestChange(false)}
                    subType={'type03'}
                    fullWidth
                  >
                    취소
                  </Button>
                </div>
                <div className={css.btn}>
                  <Button
                    key='phoneResetSave'
                    onClick={() => handleRequsetChangeInfo()}
                    subType={'type02'}
                    fullWidth
                  >
                    저장
                  </Button>
                </div>
              </div>,
            ]}
            isCenter
          >
            <VerticalTable title={'사업자 등록 번호'}>
              <Input
                type='text'
                id={requestCompanyNumber}
                value={requestCompanyNumber.replace(/[^0-9]/g, '')}
                name={requestCompanyNumber}
                maxLength={10}
                onChange={onChangeRequestCompanyNumber}
                placeholder={'사업자 등록 번호를 입력해 주세요. (-제외)'}
              />
            </VerticalTable>
            <VerticalTable title={'제휴사명'}>
              <Input
                type='text'
                name={requestCompanyName}
                id={requestCompanyName}
                value={requestCompanyName}
                onChange={onChangeRequestCompanyBizName}
                readOnly
              />
            </VerticalTable>
            <VerticalTable title={'상호명'}>
              <Input
                type='text'
                name={requestCompanyBizName}
                id={requestCompanyBizName}
                value={requestCompanyBizName}
                onChange={onChangeRequestCompanyBizName}
              />
            </VerticalTable>
            <VerticalTable title={'대표자명'}>
              <Input
                type='text'
                name={requestCompanyCeoName}
                id={requestCompanyCeoName}
                value={requestCompanyCeoName}
                onChange={onChangeRequestCompanyCeoName}
              />
            </VerticalTable>
            <VerticalTable title={'개업일자'}>
              <CalendarSingle
                startDate={startDate}
                handleStartDate={handleStartDate}
                setStartDate={setStartDate}
                minDate={null}
              />
            </VerticalTable>
            <VerticalTable
              title={'사업자 등록증'}
              button={
                <Button
                  onClick={() => {
                    onClickImageUpload(registrationImageInputRef);
                  }}
                  styleType={'btn04'}
                >
                  이미지 등록
                </Button>
              }
              required
            >
              <Input
                type='text'
                name=''
                id='registrationFileName'
                placeholder={
                  registrationFileName
                    ? registrationFileName
                    : '용량 2MB 이내 / jpg, png, bmp 이미지 (예시 image.jpg)'
                }
                readOnly
              />
              <input
                ref={registrationImageInputRef}
                name={'registrationImage'}
                onChange={(e) => handleFileUpload(e, setRegistrationFileName)}
                type='file'
                className={css.fileInput}
              />
            </VerticalTable>
            <VerticalTable
              title={'통신판매신고증'}
              button={
                <Button
                  onClick={() => {
                    onClickImageUpload(mailOrderImageInputRef);
                  }}
                  styleType={'btn04'}
                >
                  이미지 등록
                </Button>
              }
              required
            >
              <Input
                type='text'
                name=''
                id='mailOrderImageInputRef'
                placeholder={
                  mailOrderFileNamef
                    ? mailOrderFileNamef
                    : '용량 2MB 이내 / jpg, png, bmp 이미지 (예시 image.jpg)'
                }
                readOnly
              />
              <input
                onChange={(e) => handleFileUpload(e, setMailOrderFileNamef)}
                ref={mailOrderImageInputRef}
                name={'mailOrderImage'}
                type='file'
                className={css.fileInput}
              />
            </VerticalTable>
            <VerticalTable title={'사유'}>
              <TextArea
                type='text'
                value={comment}
                name={'comment'}
                onChange={onChangeComment}
                hasByte={true}
                maxByte={2000}
                row={5}
              />
            </VerticalTable>
          </VerticalTableContainer>
        </div>
      </PopUp>
      <PopUpAlert
        isOpenPopUp={isOpenSessionExpired}
        button={[
          <Button
            key='alertConfirm'
            subType={'type02'}
            onClick={() => postLogoutBySessionExpired()}
            fullWidth
          >
            확인
          </Button>,
        ]}
      >
        오랜 시간 동안 활동 없어 세션이 만료되었습니다. <br />
        다시 로그인하십시오.
      </PopUpAlert>
    </>
  );
};
export default MyInfo;
