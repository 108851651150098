import { memo } from 'react';
import css from './Label.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Label
 * @param {string} size Label 의 크기 (auto, large).
 * @param {boolean} required Label 필수 dot 표시 여부.
 * @param {any} children Label 의 자식 요소.
 * @description VerticalTable에서 사용하는 Label 컴포넌트.
 */

const Label = ({ size, required, children }) => {
  return (
    <div className={cn('label', size)}>
      {required && <span className={css.required}>*</span>}
      {children}
    </div>
  );
};

Label.defaultProps = {
  /** Label 크기 지정 (auto, large) */
  size: 'large',
  /** Label 필수 dot 표시 여부 */
  required: false,
};

export default memo(Label);
