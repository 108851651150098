import classNames from 'classnames/bind';
import css from './DeliveryResult.module.scss';

const cn = classNames.bind(css);

export const periodArr = ['오늘', '3일', '7일'];

export const targetSearchOptions = {
  guide: '',
  list: [
    { id: 'ALL', title: '전체' },
    { id: 'CUSTOMER', title: '고객' },
    { id: 'LGE', title: 'LGE' },
  ],
};

export const vendorSearchOptions = {
  guide: '',
  list: [
    { id: 'VENDOR.00002', title: '런드리고' },
    { id: 'VENDOR.00004', title: '대리주부' },
    { id: 'VENDOR.00004', title: '다락' },
  ],
};

export const alarmSearchOptions = {
  guide: '',
  list: [
    { id: 'CAT01', title: '배송안내' },
    { id: 'CAT02', title: '배송요청' },
    { id: 'CAT03', title: '상품 준비완료 요청' },
    { id: 'CAT04', title: '송장번호 입력 지연' },
    { id: 'CAT05', title: '배송지연(제휴사)' },
  ],
};

export const ALARM_KEYS = {
  DELIVERY_INFO: '배송안내', // 배송안내
  DELIVERY_DELAY: '배송지연', // 배송지연
  CONTRACT_CANCEL: '결제안내계약취소', // 계약취소
  PRODUCT_PREPARATION_REQUEST: '상품 준비 완료 요청', // 상품준비요청
};

export const mockData = [
  {
    receiver: '고객',
    vendorName: '런드리고',
    제목: 'LG전자 제휴서비스 배송 안내',
    '알림톡 내용': ALARM_KEYS.DELIVERY_INFO,
    messageTarget: ALARM_KEYS.DELIVERY_INFO,
    messageType: '알림톡',
    '알림톡 발송 결과': '발송 중',
    'LMS 발송 결과': '-',
    '최종 결과': 'qsge_-_122-asdg-1234',
  },
  {
    receiver: '고객',
    vendorName: '런드리고',
    제목: 'LG전자 제휴서비스 배송 지연 안내',
    '알림톡 내용': ALARM_KEYS.DELIVERY_DELAY,
    messageTarget: ALARM_KEYS.DELIVERY_DELAY,
    messageType: '알림톡',
    '알림톡 발송 결과': '발송 완료',
    'LMS 발송 결과': '발송 중',
    '최종 결과': 'qsge_-_122-asdg-1234',
  },
  {
    receiver: '고객',
    vendorName: '런드리고',
    제목: '계약 취소 알림',
    '알림톡 내용': ALARM_KEYS.CONTRACT_CANCEL,
    messageTarget: ALARM_KEYS.CONTRACT_CANCEL,
    messageType: 'LMS/메세지',
    '알림톡 발송 결과': '발송완료',
    'LMS 발송 결과': '발송완료',
    '최종 결과': 'qsge_-_122-asdg-1234',
  },
  {
    receiver: '제휴사',
    vendorName: '대리주부',
    제목: '계약 취소 알림',
    '알림톡 내용': ALARM_KEYS.PRODUCT_PREPARATION_REQUEST,
    messageTarget: ALARM_KEYS.PRODUCT_PREPARATION_REQUEST,
    messageType: 'LMS/메세지',
    '알림톡 발송 결과': '발송완료',
    'LMS 발송 결과': '발송완료',
    '최종 결과': 'qsge_-_122-asdg-1234',
  },
];

export const alarmMessage = (type) => {
  switch (type) {
    case ALARM_KEYS.DELIVERY_INFO:
      return (
        <div>
          [LG전자 제휴서비스 배송 안내]
          <br />
          안녕하세요 <span className={cn('color-primary')}>{`#{항목1}`}</span>
          고객님, LG전자입니다.
          <br />
          <span className={cn('color-primary')}>{`#{항목5}`}</span> 제휴서비스
          배송이 시작 될 예정입니다.
        </div>
      );
    case ALARM_KEYS.DELIVERY_DELAY:
      return (
        <div>
          [LG전자 제휴서비스 배송 지연 안내]
          <br />
          안녕하세요 <span className={cn('color-primary')}>{`#{항목1}`}</span>
          고객님, LG전자입니다.
          <br />
          주문하신 상품의 발송이 지연되고 있어 최대한 빠르게 배송될 수 있도록
          조치 중입니다.
          <br />
          다시 한번 이용에 불편을 드려 죄송합니다.
        </div>
      );
    case ALARM_KEYS.CONTRACT_CANCEL:
      return (
        <div>
          [계약 취소 알림]
          <br />
          최종 미납으로 인한 계약 취소{' '}
          <span className={cn('color-primary')}>{`#{항목17}`}</span>
          건이 있습니다.
          <br />
          확인해 주세요.
        </div>
      );
    case ALARM_KEYS.PRODUCT_PREPARATION_REQUEST:
      return (
        <div>
          [신규 주문/배송 요청 알림]
          <br />
          <span className={cn('color-primary')}>{`#{항목14}`}</span>
          건의 신규주문이 접수 되었습니다. 배송관리에서 상품 준비를
          시작해주세요.
        </div>
      );
  }
};
