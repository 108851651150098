import { TAxios } from 'redux/function/TAxios';
import {
  getSettlementDetails,
  getSettlementDashboard,
  getSettlementTargetStatusInfo,
  getEntireAdjustmentList,
  getAdjustmentResult,
  getSettlementTotal,
  getSettlementApprovalStatus,
  getExternalProcessDashboard,
  getSalesSettlementListFOC,
  getSalesSettlementListNFOC,
  getSettlementHistory,
} from '@feature/settlement/settlementSlice';
import { openModalAlert } from '@feature/common/commonSlice';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

/**
 * @category Action
 * @namespace getSettlementDetailsAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
    }
 * @property {Array} params.vendorKey - 제휴사 고유키, '' 전달 시 전체조회
 */

const getSettlementDetailsAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveSettlementDetails`;
  const onSuccess = (response) => {
    console.log('retrieveSettlementDetails response', response);
    dispatch(getSettlementDetails(response.data.rs));
  };
  const onFail = (response) => {
    console.log('retrieveSettlementDetails onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getSettlementDashboardAPI
 * @description 정산 내역 카운트 && 금액
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
    }
 * @property {Array} params.vendorKey - 제휴사 고유키, '' 전달 시 전체조회
 */

const getSettlementDashboardAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveSettlementDashboard`;
  console.log('retrieveSettlementDashboard', params);
  const onSuccess = (response) => {
    console.log('retrieveSettlementDashboard response', response);
    console.log('retrieveSettlementDashboard params', params);
    dispatch(getSettlementDashboard(response.data.rs));
  };
  const onFail = (response) => {
    console.log('retrieveSettlementDashboard onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false
    // header
  );
};
/**
 * @category Action
 * @namespace getSettlementTargetStatusInfoAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
    }
 * @property {Array} params.vendorKey - 제휴사 고유키
 */

const getSettlementTargetStatusInfoAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveSettlementTargetStatusInfo`;
  const onSuccess = (response) => {
    console.log('retrieveSettlementDetails response', response);
    dispatch(getSettlementTargetStatusInfo(response.data.rs));
  };
  const onFail = (response) => {
    console.log('retrieveSettlementDetails onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getEntireAdjustmentListAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
      settlementMonth: "2023-10"
    }

 * @property {Array} params.vendorKey - 제휴사 고유키
 */

const getAdjustmentResultAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveAdjustmentResult`;
  const onSuccess = (response) => {
    console.log('retrieveAdjustmentResult response', response);
    dispatch(getAdjustmentResult(response.data.rs.AdjustmentResult));
  };
  const onFail = (response) => {
    console.log('retrieveAdjustmentResult onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getSettlementTotalAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
    }

 * @property {Array} params.vendorKey - 제휴사 고유키
 */

const getSettlementTotalAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveSettlementTotal`;
  const onSuccess = (response) => {
    console.log('retrieveSettlementTotal response', response);
    dispatch(getSettlementTotal(response.data.rs));
  };
  const onFail = (response) => {
    console.log('retrieveSettlementTotal onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};
/**
 * @category Action
 * @namespace getSettlementApprovalStatusAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
      settlementMonth: "2023-10"
    }

 * @property {Array} params.vendorKey - 제휴사 고유키
 */

const getSettlementApprovalStatusAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveSettlementApprovalStatus`;
  const onSuccess = (response) => {
    console.log('retrieveSettlementApprovalStatus response', response);
    dispatch(getSettlementApprovalStatus(response.data.rs.ApprovalStatus));
  };
  const onFail = (response) => {
    console.log('retrieveSettlementApprovalStatus onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getExternalProcessDashboardAPI
 */

const getExternalProcessDashboardAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveExternalProcessDashboard`;
  const onSuccess = (response) => {
    console.log('retrieveExternalProcessDashboard response', response);
    dispatch(
      getExternalProcessDashboard(
        response.data.rs.retrieveExternalProcessDashboard
      )
    );
  };
  const onFail = (response) => {
    console.log('retrieveExternalProcessDashboard onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getSalesSettlementListAPI
 */

const getSalesSettlementListAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveSalesSettlementList`;
  const onSuccess = (response) => {
    console.log('retrieveSalesSettlementList response', response);
    if (params.promotionType === 'FOC') {
      dispatch(getSalesSettlementListFOC(response.data.rs));
    } else {
      dispatch(getSalesSettlementListNFOC(response.data.rs));
    }
  };
  const onFail = (response) => {
    console.log('retrieveSalesSettlementList onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getEntireAdjustmentListAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
      settlementMonth: "2023-10"
    }

 * @property {Array} params.vendorKey - 제휴사 고유키
 */

const getEntireAdjustmentListAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveEntireAdjustmentList`;
  const onSuccess = (response) => {
    console.log('retrieveEntireAdjustmentList response', response);
    dispatch(getEntireAdjustmentList(response.data.rs ?? []));
  };
  const onFail = (response) => {
    console.log('retrieveEntireAdjustmentList onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postInsertSettlementTarget
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 * {
    "resultList": [
        {
            "result": {
                "statusUpdateResultforCSMS": "SUCCESSED",
                "statusUpdateResult": "FAILED"
            },
            "couponKey": "COUPON.1707791042000000003"
        },
        {
            "result": {
                "statusUpdateResultforCSMS": "SUCCESSED",
                "statusUpdateResult": "SUCCESSED"
            },
            "couponKey": "COUPON.1703651053000000002"
        }
  ]
}
}
 * @property {Array} params.resultList - 객체 리스트
 */

const postInsertSettlementTarget = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/insertSettlementTarget`;
  const onSuccess = (response) => {
    // dispatch(getCustomerRefund(response.data.rs.list));
    console.log('insertSettlementTarget response', response);
  };
  const onFail = (response) => {
    console.log('insertSettlementTarget onFail', response);
  };

  TAxios(undefined, undefined, 'post', URL, params, onSuccess, onFail, false);
};

const postInsertAdjustmentAPI = (
  dispatch,
  params,
  entireAdjustmentLisParams
) => {
  const URL = `/api/1.0/app/settlement/insertAdjustment`;
  const onSuccess = (response) => {
    // dispatch(getCustomerRefund(response.data.rs.list));
    console.log('insertAdjustment response', response);
    getEntireAdjustmentListAPI(dispatch, entireAdjustmentLisParams);
    getAdjustmentResultAPI(dispatch, {
      vendorKey: entireAdjustmentLisParams.vendorKey,
    });
  };
  const onFail = (response) => {
    console.log('insertAdjustment onFail', response);
  };

  TAxios(undefined, undefined, 'post', URL, params, onSuccess, onFail, false);
};

const postDeleteAdjustmentAPI = (
  dispatch,
  params,
  entireAdjustmentLisParams
) => {
  const URL = `/api/1.0/app/settlement/deleteAdjustment`;
  const onSuccess = (response) => {
    console.log('deleteAdjustment response', response);
    getEntireAdjustmentListAPI(dispatch, entireAdjustmentLisParams);
    getAdjustmentResultAPI(dispatch, {
      vendorKey: entireAdjustmentLisParams.vendorKey,
    });
  };
  const onFail = (response) => {
    console.log('deleteAdjustment onFail', response);
  };

  TAxios(undefined, undefined, 'post', URL, params, onSuccess, onFail, false);
};
const postUpdateSettlementApprovalStatusAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/updateSettlementApprovalStatus`;
  const onSuccess = (response) => {
    console.log('updateSettlementApprovalStatus response', response);
    getSettlementTotalAPI(dispatch, { vendorKey: params.vendorKey });
    getSettlementApprovalStatusAPI(dispatch, { vendorKey: params.vendorKey });
  };
  const onFail = (response) => {
    console.log('updateSettlementApprovalStatus onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};
const postUpdateExternalProcessStatusAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/updateExternalProcessStatus`;
  const onSuccess = (response) => {
    console.log('updateSettlementApprovalStatus response', response);
    getSalesSettlementListAPI(dispatch, { promotionType: 'NFOC' });
    getSalesSettlementListAPI(dispatch, { promotionType: 'FOC' });
    getExternalProcessDashboardAPI(dispatch, {});
  };
  const onFail = (response) => {
    console.log('updateSettlementApprovalStatus onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false
    // header
  );
};
const postUpdateSettlementCloseStatusAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/updateSettlementCloseStatus`;
  const onSuccess = (response) => {
    console.log('updateSettlementCloseStatus response', response);
    dispatch(openModalAlert({ message: '정산 확정 되었습니다.' }));
  };
  const onFail = (response) => {
    console.log('updateSettlementCloseStatus onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false
    // header
  );
};

/**
 * @category Action
 * @namespace getSettlementHistoryAPI
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - post body parameters
 * @example
 *  {
      vendorKey: "VENDOR.00001"
      settlementMonth: "2023-10"
    }

 * @property {string} params.vendorKey - 제휴사 고유키
   @property {string} params.settlementMonth - 날짜(년-월)
 */

const getSettlementHistoryAPI = (dispatch, params) => {
  const URL = `/api/1.0/app/settlement/retrieveMonthlySettlementHistory`;
  const onSuccess = (response) => {
    console.log('retrieveMonthlySettlementHistory response', response);
    dispatch(getSettlementHistory(response.data.rs.settlementHistory ?? []));
  };
  const onFail = (response) => {
    console.log('retrieveMonthlySettlementHistory onFail', response);
  };

  TAxios(
    undefined,
    undefined,
    'get',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

export {
  getSettlementDetailsAPI,
  getSettlementDashboardAPI,
  getSettlementTargetStatusInfoAPI,
  getEntireAdjustmentListAPI,
  postInsertSettlementTarget,
  getAdjustmentResultAPI,
  getSettlementTotalAPI,
  getSettlementApprovalStatusAPI,
  getExternalProcessDashboardAPI,
  getSalesSettlementListAPI,
  postInsertAdjustmentAPI,
  postDeleteAdjustmentAPI,
  postUpdateSettlementApprovalStatusAPI,
  postUpdateExternalProcessStatusAPI,
  postUpdateSettlementCloseStatusAPI,
  getSettlementHistoryAPI,
};
