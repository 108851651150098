import { createSlice } from '@reduxjs/toolkit';
import {
  postTalkDreamCouponIssueTarget,
  getAwaitTalkDreamCouponIssueTargetListAPI,
  getRetrieveCouponTalkdreamResultListAPI,
  getRetrieveCouponTalkdreamResultDetailListAPI,
} from 'redux/action/couponAction';

import {
  getRetrieveDeliveryStatusListAPI,
  getDeliveryInfoDetailsAPI,
} from 'redux/action/deliveryAction';

import { wait } from '@utils';
const INITIAL_MODAL_ALERT = {
  modalType: 'basicModal',
  isOpen: false,
  message: '',
  path: '',
  content: null,
};

const initialState = {
  // 약관 동의
  terms: {
    // 약관 동의 버전 별 분리
    agreedV1: {
      agreedTerms: false,
      agreedPrivacy: false,
      agreedlocation: false,
    },
    agreedV2: {},
  },
  // 사업자 등록 번호 인증 데이터
  businessRegister: [
    {
      companyNumber: '',
      companyDate: '',
      companyPerson: '',
      companyName: '',
    },
  ],
  // 가입 정보
  signUp: {
    businessNumber: '',
    businessName: '',
    userId: '',
    userPassword: '',
    userEmail: '',
    userPhoneNumber: '',
    // 사업자 등록증 (image)
    // 통신판매업신고증 (image)
  },
  vendorList: [],
  popup: {
    isNoticeListPopup: false,
    isNoticeDetailPopup: false,
    isNoticeRegisterPopup: false,
    isNoticeEditPopup: false,
  },
  isSessionExpiredPopup: false,
  isAlertModal: INITIAL_MODAL_ALERT,
  loading: false,
};

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setVendorList: (state, action) => {
      state.vendorList = action.payload;
    },
    setNoticeListPopup: (state, action) => {
      state.popup.isNoticeListPopup = action.payload;
    },
    setNoticeDetailPopup: (state, action) => {
      state.popup.isNoticeDetailPopup = action.payload;
    },
    setNoticeRegisterPopup: (state, action) => {
      state.popup.isNoticeRegisterPopup = action.payload;
    },
    setNoticeEditPopup: (state, action) => {
      state.popup.isNoticeEditPopup = action.payload;
    },
    setSessionExpiredPopup: (state, action) => {
      state.isSessionExpiredPopup = action.payload;
    },
    openModalAlert: (state, action) => {
      const { payload } = action;
      state.isAlertModal = { ...state.isAlertModal, ...payload, isOpen: true };
    },
    closeModalAlert: (state) => {
      state.isAlertModal = INITIAL_MODAL_ALERT;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postTalkDreamCouponIssueTarget.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      postTalkDreamCouponIssueTarget.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      postTalkDreamCouponIssueTarget.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getAwaitTalkDreamCouponIssueTargetListAPI.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getAwaitTalkDreamCouponIssueTargetListAPI.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getAwaitTalkDreamCouponIssueTargetListAPI.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getRetrieveCouponTalkdreamResultDetailListAPI.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getRetrieveCouponTalkdreamResultDetailListAPI.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getRetrieveCouponTalkdreamResultDetailListAPI.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getRetrieveCouponTalkdreamResultListAPI.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getRetrieveCouponTalkdreamResultListAPI.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getRetrieveCouponTalkdreamResultListAPI.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getRetrieveDeliveryStatusListAPI.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getRetrieveDeliveryStatusListAPI.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      getRetrieveDeliveryStatusListAPI.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(getDeliveryInfoDetailsAPI.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDeliveryInfoDetailsAPI.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getDeliveryInfoDetailsAPI.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setVendorList,
  setNoticeListPopup,
  setNoticeDetailPopup,
  setNoticeRegisterPopup,
  setNoticeEditPopup,
  setSessionExpiredPopup,
  openModalAlert,
  closeModalAlert,
} = common.actions;
export default common.reducer;
