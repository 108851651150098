import React, { useCallback, useEffect, useMemo } from 'react';
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import css from './LGDashBoardDetailSlide.module.scss';
import classNames from 'classnames/bind';
import {
  ROUTER_PATH,
  PRODUCT_SUB_PATH,
  COUPON_SUB_PATH,
  DELIVERY_SUB_PATH,
  REFUND_SUB_PATH,
} from '@router/routerPath';
import { useNavigate } from 'react-router-dom';
import {
  getDashboardCouponInfo,
  getDashboardMerchandiseInfo,
  getDashboardDeliveryInfo,
  getDashboardRefundInfo,
} from 'redux/action/dashBoardAction';
import { useDispatch, useSelector } from 'react-redux';
import { createQueryString, dateToStr } from '@utils';
import {
  MERCHANDISE_SEARCH_CATEGORY,
  MERCHANDISE_SEARCH_TYPE,
  MILLISECONDS_PER_DAY,
  SERVICE_START_DATE,
} from '@data/Const';
import { dashboardRefundInfo } from '@feature/dashBoard/dashBoardSlice';

const cn = classNames.bind(css);

const PAGE_LIMIT = `page=1&limit=100`;

const LGDashBoardDetailSlide = ({ activeDetailSlideTab }) => {
  const dispatch = useDispatch();
  const dashboardCouponInfo = useSelector(
    (state) => state.dashBoard.dashboardCouponInfo
  );

  const dashboardMerchandiseInfo = useSelector(
    (state) => state.dashBoard.dashboardMerchandiseInfo
  );

  const dashboardDeliveryInfo = useSelector(
    (state) => state.dashBoard.dashboardDeliveryInfo
  );
  const dashboarRefundInfo = useSelector(
    (state) => state.dashBoard.dashboardRefundInfo
  );

  // 쿠폰관리
  const totalUnhandledCouponInfo = useMemo(() => {
    return dashboardCouponInfo?.totalUnhandledInfo;
  }, [dashboardCouponInfo]);

  const totalCouponCreationRequiredInfo = useMemo(() => {
    return dashboardCouponInfo?.couponCreationRequiredInfo;
  }, [dashboardCouponInfo]);

  const totalCouponUseCompleteRequestedInfo = useMemo(() => {
    return dashboardCouponInfo?.couponUseCompleteRequestedInfo;
  }, [dashboardCouponInfo]);

  const totalCouponTalkdreamSentInfo = useMemo(() => {
    return dashboardCouponInfo?.couponTalkdreamSentInfo;
  }, [dashboardCouponInfo]);

  // 배송관리
  const totalUnhandledDeliveryInfo = useMemo(() => {
    return dashboardDeliveryInfo?.totalUnhandledInfo;
  }, [dashboardDeliveryInfo]);

  const totalDeliveryStatusChangeRequestedInfo = useMemo(() => {
    return dashboardDeliveryInfo?.deliveryStatusChangeRequestedInfo;
  }, [dashboardDeliveryInfo]);

  const totalDeliveryUnderDeliveredInfo = useMemo(() => {
    return dashboardDeliveryInfo?.deliveryUnderDeliveredInfo;
  }, [dashboardDeliveryInfo]);

  const totalDeliveryUnprocessedInfo = useMemo(() => {
    return dashboardDeliveryInfo?.deliveryUnprocessedInfo;
  }, [dashboardDeliveryInfo]);

  // 상품관리
  const merchandiseOnUseInfo = useMemo(() => {
    return dashboardMerchandiseInfo?.merchandiseOnUseInfo;
  }, [dashboardMerchandiseInfo]);

  const merchandiseUnderApprovalInfo = useMemo(() => {
    return dashboardMerchandiseInfo?.merchandiseUnderApprovalInfo;
  }, [dashboardMerchandiseInfo]);

  const totalMerchandiseUnderApprovalCount =
    merchandiseUnderApprovalInfo?.reduce((accumulator, item) => {
      // parseInt를 사용하여 문자열을 숫자로 변환
      const cntAsNumber = parseInt(item.cnt, 10);
      return accumulator + cntAsNumber;
    }, 0);

  const totalMerchandiseOnUseCount = merchandiseOnUseInfo?.reduce(
    (accumulator, item) => {
      const cntAsNumber = parseInt(item.cnt, 10);
      return accumulator + cntAsNumber;
    },
    0
  );

  // 환불관리
  const refundCustomerCouponInfo = useMemo(() => {
    return dashboarRefundInfo?.refundCustomerCouponInfo;
  }, [dashboarRefundInfo]);

  const refundCustomerDeliveryInfo = useMemo(() => {
    return dashboarRefundInfo?.refundCustomerDeliveryInfo;
  }, [dashboarRefundInfo]);

  const refundUnusedCouponInfo = useMemo(() => {
    return dashboarRefundInfo?.refundUnusedCouponInfo;
  }, [dashboarRefundInfo]);

  const refundTotalUnhandledInfo = useMemo(() => {
    return dashboarRefundInfo?.totalUnhandledInfo;
  }, [dashboarRefundInfo]);

  const mapWithoutFirst = (info, mapFn) =>
    info?.filter((item, idx) => idx !== 0).map((item, idx) => mapFn(item, idx));

  const mapToResultArray = (info, mapFn) =>
    info?.map((item, idx) => mapFn(item, idx));

  const resultArrays = useMemo(() => {
    // 쿠폰 관리
    const totalUnhandledResultArray = mapWithoutFirst(
      totalUnhandledCouponInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        badge: `미접속 D + ${item.dateDiff}`,
      })
    );

    const couponCreationRequiredArray = mapWithoutFirst(
      totalCouponCreationRequiredInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${COUPON_SUB_PATH.REGISTER}?${PAGE_LIMIT}&vendorKey=${item.vendorKey}`,
      })
    );

    const couponUseCompleteRequestedArray = mapWithoutFirst(
      totalCouponUseCompleteRequestedInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${COUPON_SUB_PATH.RESULT}?${PAGE_LIMIT}&vendorKey=${item.vendorKey}`,
      })
    );

    const couponTalkdreamSentArray = mapWithoutFirst(
      totalCouponTalkdreamSentInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.sendRequiredCnt,
        requiredCnt: `발행 ${item.sendRequestedTotalCnt}`,
        sendSuccessedCnt: `완료 ${item.sendSuccessedCnt}`,
        path: `/${COUPON_SUB_PATH.ALARM}?${PAGE_LIMIT}&vendorKey=${item.vendorKey}&messageTarget=CUSTOMER`,
      })
    );

    // 배송 관리
    const totalUnhandledDeliveryResultArray = mapWithoutFirst(
      totalUnhandledDeliveryInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        badge: `미접속 D + ${item.dateDiff}`,
      })
    );

    const totalDeliveryUnprocessedInfoArray = mapWithoutFirst(
      totalDeliveryUnprocessedInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${item.vendorKey}&deliveryStatus=D003`,
      })
    );

    const totalDeliveryUnderDeliveredInfoArray = mapWithoutFirst(
      totalDeliveryUnderDeliveredInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${item.vendorKey}&deliveryStatus=D004`,
      })
    );

    const totalDeliveryStatusChangeRequestedArray = mapWithoutFirst(
      totalDeliveryStatusChangeRequestedInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${DELIVERY_SUB_PATH.ORDER}?${PAGE_LIMIT}&vendorKey=${item.vendorKey}`,
      })
    );

    // 상품 관리

    const DATE_NOW = Date.now();

    const criteriaSearchOptions = {
      guide: '',
      list: [
        { id: MERCHANDISE_SEARCH_CATEGORY.CR, title: '상품 등록일' },
        { id: MERCHANDISE_SEARCH_CATEGORY.SS, title: '판매 시작일' },
        { id: MERCHANDISE_SEARCH_CATEGORY.SE, title: '판매 종료일' },
        { id: MERCHANDISE_SEARCH_CATEGORY.AC, title: '승인 완료일' },
      ],
    };

    const detailedSearchOptions = {
      guide: '',
      list: [
        { id: MERCHANDISE_SEARCH_TYPE.FOCFLAG, title: '정산' },
        { id: MERCHANDISE_SEARCH_TYPE.VNAME, title: '제휴사명' },
        { id: MERCHANDISE_SEARCH_TYPE.MNAME, title: '상품명' },
      ],
    };

    const BASE_PARAMS = Object.freeze({
      vendorKey: null,
      searchCategory: criteriaSearchOptions.list[0].id,
      searchPeriodType: 'MANUAL',
      preDefinedType: '',
      fromDate: new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30),
      toDate: new Date(DATE_NOW),
      searchType: detailedSearchOptions.list[0].id,
      detailCondition: '',
      salesStatus: '',
      approvalStatus: '',
    });

    const merchandiseOnUseArray = mapToResultArray(
      merchandiseOnUseInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${PRODUCT_SUB_PATH.LIST}?${createQueryString({
          salesStatus: 'USED',
          searchCategory: MERCHANDISE_SEARCH_CATEGORY.CR,
          fromDate: BASE_PARAMS.fromDate,
          toDate: BASE_PARAMS.toDate,
          searchType: MERCHANDISE_SEARCH_TYPE.VNAME,
          detailCondition: item.companyName,
          page: '1',
          limit: '100',
        })}`,
      })
    );

    const merchandiseUnderApprovaArray = mapToResultArray(
      merchandiseUnderApprovalInfo,
      (item, idx) => ({
        id: idx,
        itemTitle: item.companyName,
        num: item.cnt,
        path: `/${PRODUCT_SUB_PATH.LIST}?${createQueryString({
          approvalStatus: 'UNDER_APPROVAL',
          searchCategory: MERCHANDISE_SEARCH_CATEGORY.CR,
          fromDate: BASE_PARAMS.fromDate,
          toDate: BASE_PARAMS.toDate,
          searchType: MERCHANDISE_SEARCH_TYPE.VNAME,
          detailCondition: item.companyName,
          page: '1',
          limit: '100',
        })}`,
      })
    );

    const refundInfoArray = (target) =>
      mapWithoutFirst(target, (item, idx) => {
        let path;
        switch (target) {
          case refundTotalUnhandledInfo:
            path = null;
            break;
          case refundCustomerCouponInfo:
            path = `/${REFUND_SUB_PATH.CUSTOMER}?${createQueryString({
              fromDate: BASE_PARAMS.fromDate,
              toDate: BASE_PARAMS.toDate,
              vendorkey: item.vendorKey,
              category: 'COUPON_REFUND_APPROVED',
              page: '1',
              limit: '100',
            })}`;
            break;
          case refundCustomerDeliveryInfo:
            path = `/${REFUND_SUB_PATH.CUSTOMER}?${createQueryString({
              fromDate: BASE_PARAMS.fromDate,
              toDate: BASE_PARAMS.toDate,
              vendorkey: item.vendorKey,
              category: 'DELIVERY_REFUND_APPROVED',
              page: '1',
              limit: '100',
            })}`;
            break;
          case refundUnusedCouponInfo:
            path = `/${REFUND_SUB_PATH.UNUSED}?${createQueryString({
              fromDate: BASE_PARAMS.fromDate,
              toDate: BASE_PARAMS.toDate,
              vendorkey: item.vendorKey,
              page: '1',
              limit: '100',
            })}`;
            break;
          default:
            path = null;
            break;
        }

        return {
          id: idx,
          itemTitle: item.companyName,
          num: item.cnt,
          path: path,
          couponCnt:
            item.couponCnt === '' || item.couponCnt === undefined
              ? ''
              : `쿠폰 ${item.couponCnt}`,
          deliveryCnt:
            item.deliveryCnt === '' || item.deliveryCnt === undefined
              ? ''
              : `배송 ${item.deliveryCnt}`,
        };
      });

    return {
      totalUnhandledResultArray,
      couponCreationRequiredArray,
      couponUseCompleteRequestedArray,
      couponTalkdreamSentArray,
      merchandiseOnUseArray,
      merchandiseUnderApprovaArray,
      totalUnhandledDeliveryResultArray,
      totalDeliveryUnprocessedInfoArray,
      totalDeliveryUnderDeliveredInfoArray,
      totalDeliveryStatusChangeRequestedArray,
      refundInfoArray,
    };
  }, [
    totalCouponCreationRequiredInfo,
    totalCouponTalkdreamSentInfo,
    totalCouponUseCompleteRequestedInfo,
    totalUnhandledCouponInfo,
    merchandiseUnderApprovalInfo,
    merchandiseOnUseInfo,
    totalUnhandledDeliveryInfo,
    totalDeliveryStatusChangeRequestedInfo,
    totalDeliveryUnderDeliveredInfo,
    totalDeliveryUnprocessedInfo,
  ]);

  const TEST_SWIPER = useMemo(() => {
    const result = [
      //상품 관리
      [
        // {
        //   title: '사용 중',
        //   subTitle: '제휴사',
        //   total: totalMerchandiseOnUseCount,
        //   slideItem: resultArrays.merchandiseOnUseArray,
        // },
        {
          title: '상품 등록\n승인 요청',
          subTitle: '제휴사별 등록한\n 상품 중 LGE 승인\n 요청 건 수',
          total: totalMerchandiseUnderApprovalCount,
          slideItem: resultArrays.merchandiseUnderApprovaArray,
        },
      ],
      // 쿠폰 관리
      [
        {
          title: '전체 미처리',
          subTitle: '전체 미처리 건수 출력          ',
          total: totalUnhandledCouponInfo?.[0]?.cnt,
          slideItem: resultArrays.totalUnhandledResultArray,
        },
        {
          title: '쿠폰 생성 필요',
          subTitle: '제휴사별 쿠폰 생성 \n필요 건수 출력',
          total: totalCouponCreationRequiredInfo?.[0]?.cnt,
          slideItem: resultArrays.couponCreationRequiredArray,
        },
        {
          title: '쿠폰 사용 확정 요청',
          subTitle: '제휴사별 생성한 쿠폰 중 사용완료 요청 건수',
          total: totalCouponUseCompleteRequestedInfo?.[0]?.cnt,
          slideItem: resultArrays.couponUseCompleteRequestedArray,
        },
        {
          title: '쿠폰 알림톡 발송 건',
          subTitle: '제휴사별 생성한 쿠폰 중 알림이 필요한 건수',
          total: totalCouponTalkdreamSentInfo?.[0]?.sendRequiredCnt,
          slideItem: resultArrays.couponTalkdreamSentArray,
        },
      ],
      // 배송 관리
      [
        {
          title: '전체 미처리',
          subTitle: null,
          total: totalUnhandledDeliveryInfo?.[0].cnt,
          slideItem: resultArrays.totalUnhandledDeliveryResultArray,
        },
        {
          title: '배송처리 대기',
          subTitle: '제휴사',
          total: totalDeliveryUnprocessedInfo?.[0].cnt,
          slideItem: resultArrays.totalDeliveryUnprocessedInfoArray,
        },
        {
          title: '배송 중',
          subTitle: '제휴사',
          total: totalDeliveryUnderDeliveredInfo?.[0].cnt,
          slideItem: resultArrays.totalDeliveryUnderDeliveredInfoArray,
        },
        {
          title: '배송 상태 변경 요청',
          subTitle: 'MY',
          total: totalDeliveryStatusChangeRequestedInfo?.[0].cnt,
          slideItem: resultArrays.totalDeliveryStatusChangeRequestedArray,
          // slideItem: [
          //   {
          //     id: 0,
          //     itemTitle: 'MY',
          //     num: 33,
          //     d_day: 'D+1 ↑',
          //     d_day_count: '2',
          //   },
          // ],
        },
      ],
      // 정산 관리 Todo: 추가해야됨
      [
        {
          title: '추가 추가',
          subTitle: null,
          total: 108,
        },
      ],
      // 환불 관리
      [
        {
          title: '전체 미처리',
          subTitle: null,
          total: refundTotalUnhandledInfo?.[0]?.cnt,
          slideItem: resultArrays.refundInfoArray(refundTotalUnhandledInfo),
        },
        {
          title: '고객 요청 환불(쿠폰)',
          subTitle: '환불 요청 건',
          total: refundCustomerCouponInfo?.[0]?.cnt,
          slideItem: resultArrays.refundInfoArray(refundCustomerCouponInfo),
        },
        {
          title: '고객 요청 환불(배송)',
          subTitle: '환불 요청 건',
          total: refundCustomerDeliveryInfo?.[0]?.cnt,
          slideItem: resultArrays.refundInfoArray(refundCustomerDeliveryInfo),
        },
        {
          title: '기간내 미사용 쿠폰 환불',
          subTitle: '',
          total: refundUnusedCouponInfo?.[0]?.cnt,
          slideItem: resultArrays.refundInfoArray(refundUnusedCouponInfo),
        },
      ],
    ];
    return result;
  }, [dashboardCouponInfo, dashboardMerchandiseInfo, dashboardRefundInfo]);

  const navigate = useNavigate();

  const returnActiveTabTitle = useCallback(() => {
    switch (activeDetailSlideTab) {
      case 0:
        return '상품관리';
      case 1:
        return '쿠폰관리';
      case 2:
        return '배송관리';
      case 3:
        return '정산관리';
      case 4:
        return '환불관리';
      default:
        return '';
    }
  }, [activeDetailSlideTab]);

  useEffect(() => {
    getDashboardCouponInfo(dispatch);
    getDashboardMerchandiseInfo(dispatch);
    getDashboardDeliveryInfo(dispatch);
    getDashboardRefundInfo(dispatch);
  }, []);

  const handleNavigate = useCallback((path, state) => {
    if (state) {
      navigate(path, { state: state });
    } else if (path) {
      navigate(path);
    }
  }, []);

  return (
    <>
      <h3 className={css.areaTitle}>{returnActiveTabTitle()}</h3>
      <div className={css.shippingManage}>
        {TEST_SWIPER[activeDetailSlideTab].map((rowItem, rowIndex) => {
          return (
            <div key={rowIndex} className={css.row}>
              <div className={css.titleArea}>
                <dl>
                  <dt>{rowItem.title}</dt>
                  {rowItem.subTitle && (
                    <span className={css.type_color_red}>
                      {rowItem.subTitle}
                    </span>
                  )}
                  <dd>
                    <span className={css.num}>{rowItem.total}</span>건
                  </dd>
                </dl>
              </div>
              <Swiper
                className={cn('swiper-container')}
                slidesPerView={'3'}
                spaceBetween={20}
                // onSwiper={(swiper) => console.log('swiper', swiper)}
              >
                {rowItem.slideItem &&
                  rowItem?.slideItem?.map((slide, index) => {
                    return (
                      <SwiperSlide
                        key={slide.itemTitle + index}
                        className={cn('swiper-slide')}
                      >
                        <div className={css.link}>
                          <dl>
                            <dt>{slide.itemTitle}</dt>
                            <dd className={css.value}>
                              <span
                                className={
                                  slide.path ? css.num : css['disabled_num']
                                }
                                onClick={
                                  slide.path
                                    ? () =>
                                        handleNavigate(slide.path, slide.state)
                                    : null
                                }
                              >
                                {slide.num}
                              </span>
                              건
                            </dd>
                            {slide.badge && (
                              <dd className={css.notConnected}>
                                {slide.badge}
                              </dd>
                            )}
                            {slide.d_day && (
                              <dd className={css.d_day}>
                                {slide.d_day}
                                <span>{slide.d_day_count}</span>
                              </dd>
                            )}
                          </dl>

                          <div className={css.alarmTalkCnt}>
                            {slide.requiredCnt && <dd>{slide.requiredCnt}</dd>}
                            {slide.sendSuccessedCnt && (
                              <dd>{slide.sendSuccessedCnt}</dd>
                            )}

                            {slide.couponCnt && <dd>{slide.couponCnt}</dd>}
                            {slide.deliveryCnt && <dd>{slide.deliveryCnt}</dd>}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LGDashBoardDetailSlide;
