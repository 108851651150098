import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTER_PATH } from '@router/routerPath';
import {
  BreadCrumbs,
  Calendar,
  PopUp,
  TableV8,
  TextArea,
  APISearchInput,
  SelectBox,
} from '@components/index';
import css from './ProductReg.module.scss';
import classNames from 'classnames/bind';
import { RadioButton, VerticalTable, Input, Button } from '@components/index';
import { useRadio, useInput, useSelect } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModelSuffixList,
  postSaveProductTempNewAndSaved,
  postRequestProductApproval,
} from 'redux/action/productAction';
import { createColumnHelper } from '@tanstack/react-table';
import { convertDateToString2, formatDateTime } from 'utils/utils';
import img_flow_product_check from '@assets/img/img_flow_product_check.png';
import ProductListDashBoard from '../modules/ProductListDashBoard';
import {
  resetModelSuffixSearchCondition,
  setModelSuffixSearchCondition,
} from '@feature/product/productSlice';
import { openModalAlert } from '@feature/common/commonSlice';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { useForm } from 'react-hook-form';

const cn = classNames.bind(css);

const columnHelper = createColumnHelper();

//Radio Box
const RADIO_FREE_OR_PAID = [
  { id: 0, title: '무상' },
  { id: 1, title: '유상' },
];

//Select Box
const SELECT_SEARCH_TARGET = [
  { id: 0, title: 'model.suffix', searchKey: 'MODEL' },
  { id: 1, title: '정산 구분', searchKey: 'FOC' },
  { id: 2, title: '상품명', searchKey: 'DESC' },
];

const ProductReg = ({ hasWritePermission }) => {
  const userId = useSelector((state) => state.user.userData.userId);
  const modelSuffixList = useSelector((state) => state.product.modelSuffixList);
  const modelSuffixCondition = useSelector(
    (state) => state.product.modelSuffixSearchCondition
  );
  const dispatch = useDispatch();

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      couponExpirationCondition: '',
    },
  });

  const initialFormData = {
    userId: userId,
    modelSuffix: '',
    vendorKey: '',
    vendorDesc: '',
    focFlag: '',
    focTypeDesc: '',
    category: '',
    categoryDesc: '',
    merchandiseId: '',
    merchandiseDesc: '',
    validDateFrom: convertDateToString2(new Date()),
    validDateTo: convertDateToString2(new Date()),
    //판매가
    sellPrice: '',
    //공급가
    netPrice: '',
    //마진가
    profitMargin: '',
    description: '',
  };

  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectFreeOrPaidRadioValue, setFreeOrPaidRadioValue] =
    useRadio('무상');

  const [sellPrice, setSellPrice, onChangeSellPrice] = useInput('', false);
  const [netPrice, setNetPrice, onChangeNetPrice] = useInput('', false);
  const [profitMargin, setProfitMargin] = useState();
  const [description, setDescription, onChangeDescription] = useInput('');
  const [merchandiseKey, setMerchandiseKey] = useState();

  const [isSelectedModleSuffix, setIsSelectedModleSuffix] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [isTempSaved, setIsTempSaved] = useState(false);
  const [isApproval, setIsApproval] = useState(false);

  const [selectedInquiryValue, onSelectInquiryValue, currentLabel] = useSelect({
    optionList: SELECT_SEARCH_TARGET,
    initialIndex: 0,
    initialLabel: SELECT_SEARCH_TARGET[0].title,
  });

  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };
  const onClickModelSuffix = (suffixInfo) => {
    setIsSelectedModleSuffix(true);
    setIsTempSaved(false);
    setIsApproval(false);

    const newFormData = { ...formData };

    Object.keys(suffixInfo).forEach((key) => {
      if (key === 'validDateFrom' || key === 'validDateTo') {
        return;
      } else if (key === 'modelSuffixDesc') {
        newFormData['merchandiseDesc'] = suffixInfo['modelSuffixDesc'];
      } else if (key === 'focType') {
        newFormData['focFlag'] = suffixInfo['focType'];
      } else {
        newFormData[key] = suffixInfo[key];
      }
    });
    setFormData(newFormData);
    setFreeOrPaidRadioValue(newFormData.focTypeDesc);

    setIsOpenPopUp(false);
  };

  useEffect(() => {
    const params = {
      searchType: 'FOC / MODEL / DESC',
      detailCondition: '',
    };
    getModelSuffixList(dispatch, params);
  }, []);

  useEffect(() => {
    const payLoad = {
      key: 'searchType',
      value: SELECT_SEARCH_TARGET[selectedInquiryValue].searchKey,
    };
    dispatch(setModelSuffixSearchCondition(payLoad));
  }, [selectedInquiryValue]);

  const makeProductTable = () => {
    getModelSuffixList(dispatch, modelSuffixCondition);
    dispatch(resetModelSuffixSearchCondition());
  };

  useEffect(() => {
    setProfitMargin(sellPrice - netPrice);
    setFormData({
      ...formData,
      sellPrice: sellPrice,
      netPrice: netPrice,
      profitMargin: sellPrice - netPrice,
      description: description,
    });
  }, [sellPrice, netPrice, description]);

  // 날짜 선택 이벤트 핸들러
  const handleStartDate = (date) => {
    if (date <= endDate) {
      setStartDate(date);
      setFormData({
        ...formData,
        validDateFrom: convertDateToString2(date),
      });
    } else {
      alert('시작 날짜는 종료 날짜보다 이전이어야 합니다.');
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      setEndDate(date);
      setFormData({
        ...formData,
        validDateTo: convertDateToString2(date),
      });
    } else {
      alert('종료 날짜는 시작 날짜보다 이후이어야 합니다.');
    }
  };

  const handleModelSuffix = () => {
    const params = {
      searchType: 'FOC / MODEL / DESC',
      detailCondition: '',
    };
    getModelSuffixList(dispatch, params);
    handlePopUpClick();
  };

  const onClickCancel = () => {
    setIsSelectedModleSuffix(false);
    setFormData(initialFormData);
    setSellPrice('');
    setNetPrice('');
    setProfitMargin('');
    setDescription('');
  };

  const onClickTempSave = useCallback(
    (data) => {
      const { couponExpirationCondition } = data;

      if (
        !isSelectedModleSuffix ||
        !description ||
        sellPrice === '' ||
        netPrice === ''
      ) {
        dispatch(openModalAlert({ message: '필수값을 입력하세요.' }));
      } else {
        const param = {
          modelSuffix: formData.modelSuffix,
          focFlag: formData.focFlag,
          category: formData.category,
          merchandiseDesc: formData.merchandiseDesc,
          priceAppliedDate: convertDateToString2(new Date()),
          validPeriod: couponExpirationCondition.toString(),
          vendorKey: formData.vendorKey,
          description: formData.description,
          sellPrice: formData.sellPrice,
          netPrice: formData.netPrice,
          profitMargin: formData.profitMargin,
          userId: formData.userId,
        };

        postSaveProductTempNewAndSaved(
          dispatch,
          param,
          setIsTempSaved,
          setMerchandiseKey,
          setIsApproval
        );
      }
    },
    [formData, isSelectedModleSuffix, description, sellPrice, netPrice]
  );

  const onFailTempSave = useCallback((data) => {
    const { couponExpirationCondition } = data;
    if (couponExpirationCondition.type === 'required') {
      dispatch(openModalAlert({ message: '필수값을 입력하세요.' }));
    }
  }, []);

  const onClickRequestApproval = () => {
    postRequestProductApproval(
      dispatch,
      {
        merchandiseKey: merchandiseKey,
        userId: userId,
      },
      setIsApproval
    );
  };

  const couponExpirationOptions = useMemo(() => {
    const options = {
      guide: '유효기간(월)을 선택해주세요.',
      list: Array.from({ length: 60 }, (_, index) => ({
        id: index + 1,
        title: `${index + 1}개월`,
      })),
    };

    return options;
  }, []);

  const columnData = useMemo(() => {
    return [
      columnHelper.accessor('modelSuffix', {
        id: 'modelSuffix',
        cell: (info) => {
          return (
            <>
              <button
                onClick={() => {
                  onClickModelSuffix(info.row.original);
                }}
              >
                {info.getValue()}
              </button>
            </>
          );
        },
        header: () => <span>model.suffix</span>,
      }),

      columnHelper.accessor('modelSuffixDesc', {
        id: 'modelSuffixDesc',
        cell: (info) => {
          return (
            <>
              <button
                onClick={() => {
                  onClickModelSuffix(info.row.original);
                }}
              >
                {info.getValue()}
              </button>
            </>
          );
        },
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('focTypeDesc', {
        id: 'focTypeDesc',
        cell: (info) => info.getValue(),
        header: () => <span>정산 구분</span>,
      }),
      columnHelper.accessor('categoryDesc', {
        id: 'categoryDesc',
        cell: (info) => {
          return <span>{info.getValue()}</span>;
        },
        header: () => <span>판매 기간</span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => info.getValue(),
        header: () => <span>사용중 상품 ID</span>,
      }),
    ];
  }, [modelSuffixList]);

  console.log('jh formData', formData);

  return (
    <>
      <h2 className={css.pageTitle}>상품 등록</h2>
      <ProductListDashBoard />
      <div className={cn('flowChart', 'mt15')}>
        <h3 className={cn('title')}>상품 조회 Flow</h3>
        <div className={cn('img')}>
          <img src={img_flow_product_check} alt='상품 조회 Flow 이미지' />
        </div>
      </div>

      <h3 className={css.sectionTitle02}>상품 정보 입력</h3>

      <div className={cn('input_table_wrap', 'first')}>
        <h4 className={css.areaTitle}>CSMS 정보</h4>
        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable
            className={cn('color-red')}
            hasTitleMinWidth
            title={['CSMS 매칭']}
          >
            <div className={cn('inputWrap', 'type03')}>
              <button
                onClick={handleModelSuffix}
                type='button'
                className={cn('btn', 'btn01', 'type02')}
              >
                model.suffix 정보 조회
              </button>
              <div className={css.input_text}>
                <Input type='text' value={formData.modelSuffix} disabled />
              </div>
            </div>
          </VerticalTable>
        </div>
        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['제휴사명']}>
            <Input type='text' value={formData.vendorDesc} disabled />
          </VerticalTable>
        </div>

        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['정산']}>
            <div className={cn('selectbox', 'type01', 'sizeM')}>
              <div className={css.inputCheckWrap}>
                {RADIO_FREE_OR_PAID &&
                  RADIO_FREE_OR_PAID?.map((item) => (
                    <RadioButton
                      key={item.id}
                      id={item.id}
                      value={selectFreeOrPaidRadioValue}
                      checked={selectFreeOrPaidRadioValue === item.title}
                      onClick={() => {
                        return false;
                      }}
                      disabled={item.disabled}
                    >
                      <>{item.title}</>
                    </RadioButton>
                  ))}
              </div>
            </div>
          </VerticalTable>
        </div>

        <div className={css.input_table}>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['구분']}>
              <div className={cn('selectbox', 'type01', 'sizeM')}>
                <Input
                  type='text'
                  fullWidth
                  value={formData.categoryDesc}
                  disabled
                />
              </div>
            </VerticalTable>
          </div>
          <div className={cn('inputWrap')}>
            <VerticalTable hasTitleMinWidth title={['상품명']}>
              <div className={cn('width578')}>
                <Input
                  type='text'
                  fullWidth
                  value={formData.merchandiseDesc}
                  disabled
                />
              </div>
            </VerticalTable>
          </div>
        </div>
      </div>

      <div className={css.input_table_wrap}>
        <h4 className={css.areaTitle}>상품 정보</h4>
        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['상품아이디']}>
            <Input type='text' name={'memo'} placeholder='자동 발번' disabled />
          </VerticalTable>
        </div>

        {/* TODO: 삭제 예정 */}
        {/* <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['판매 기간']}>
            <div className={cn('selectbox', 'type01', 'sizeM')}>
              <div className={css.inputCheckWrap}>
                <Calendar
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  hasTitle={false}
                  hasPeriodInput={false}
                  minDate={new Date()}
                  maxDate={null}
                />
              </div>
            </div>
          </VerticalTable>
        </div> */}
        {formData.categoryDesc === '쿠폰' && (
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['쿠폰 유효기간']} required>
              <div className={cn('selectbox', 'type01', 'sizeM')}>
                <HFSelectBox
                  control={control}
                  name={'couponExpirationCondition'}
                  options={couponExpirationOptions}
                  hasScroll
                  required={formData.categoryDesc === '쿠폰'}
                />
              </div>
            </VerticalTable>
          </div>
        )}
        <div className={cn('verticalTable_container', 'ml5')}>
          <VerticalTable hasTitleMinWidth title={['판매가']} required>
            <Input
              type='number'
              value={sellPrice}
              onChange={onChangeSellPrice}
            />
          </VerticalTable>
          <VerticalTable title={['공급가']} className={css.marginL30} required>
            <Input type='number' value={netPrice} onChange={onChangeNetPrice} />
          </VerticalTable>
          <VerticalTable title={['마진가']} className={css.marginL30}>
            <Input
              type='number'
              disabled
              placeholder='자동 계산'
              value={profitMargin}
            />
          </VerticalTable>
        </div>
      </div>

      <div className={cn('input_table_wrap', 'last')}>
        <div className={css.input_table}>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['비고']} required>
              <div className={css.textAreaWidth}>
                <TextArea
                  hasByte
                  maxByte={200}
                  value={description}
                  name={'description'}
                  onChange={onChangeDescription}
                  placeholder={'배송/쿠폰혜택 사용 가능 사이트명 표기'}
                  row={4}
                />
              </div>
            </VerticalTable>
          </div>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['발행(수정)자']}>
              <span>{userId}</span>
            </VerticalTable>
          </div>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['최근 수정일시']}>
              <span>{formatDateTime(new Date())}</span>
            </VerticalTable>
          </div>
        </div>
      </div>
      <div className={cn('btn_container6', 'mt32')}>
        <div className={css.btn}>
          <Button subType={'type03'} fullWidth onClick={onClickCancel}>
            취소
          </Button>
        </div>
        <div className={css.btn}>
          <Button
            subType={'type02'}
            fullWidth
            onClick={handleSubmit(onClickTempSave, onFailTempSave)}
            disabled={isTempSaved}
          >
            임시 저장
          </Button>
        </div>
        <div className={css.btn}>
          <Button
            subType={'type02'}
            fullWidth
            disabled={!isApproval}
            onClick={onClickRequestApproval}
          >
            승인 요청
          </Button>
        </div>
      </div>

      <PopUp
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
        isWideSize
      >
        <strong className={css.popup_title}>CSMS model.suffix 선택</strong>
        <TableV8
          data={modelSuffixList}
          columns={columnData}
          hasCsvDownload={false}
          rightControls={
            <div className={css.searchContainer}>
              <div className={cn('selectbox', 'type01', 'sizeS')}>
                <SelectBox
                  optionList={SELECT_SEARCH_TARGET}
                  handleSelectIndex={onSelectInquiryValue}
                  currentLabel={currentLabel}
                  fullWidth={true}
                />
              </div>
              <APISearchInput
                placeholder='model.suffix, 정산 구분, 상품명'
                action={setModelSuffixSearchCondition}
                onClickFunc={makeProductTable}
              />
            </div>
          }
        />
      </PopUp>
    </>
  );
};

export default ProductReg;
