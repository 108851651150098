import { forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames/bind';
import css from './DeliveryAlarmSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { Button } from '@components/index';
import { useFormContext } from 'react-hook-form';

const cn = classNames.bind(css);

const DeliveryAlarmSearchArea = forwardRef(
  (
    {
      targetSearchOptions,
      vendorSearchOptions,
      alarmSearchOptions,
      onSearchClick,
      totalLength,
    },
    ref
  ) => {
    const { control, handleSubmit, watch } = useFormContext();
    const targetWatch = watch('targetSearchCondition');

    useImperativeHandle(ref, () => ({
      getCategorySelectWatch: () => watch('alarmSearchCondition'),
    }));

    return (
      <div className={cn('searchOptionArea', 'row', 'mt20')}>
        <div className={cn('flex')}>
          <div className={cn('selectbox', 'sizeS')}>
            <HFSelectBox
              control={control}
              name={'targetSearchCondition'}
              options={targetSearchOptions}
            />
          </div>
          <div className={cn('selectbox', 'sizeS')}>
            <HFSelectBox
              control={control}
              name={'vendorSearchCondition'}
              options={vendorSearchOptions}
              disabled={targetWatch !== 'CUSTOMER'}
            />
          </div>
          <div className={css.guideText}>{'알림 구분'}</div>
          <div className={cn('selectbox', 'sizeS')}>
            <HFSelectBox
              control={control}
              name={'alarmSearchCondition'}
              options={alarmSearchOptions}
            />
          </div>
          <Button
            styleType={'btn01'}
            subType={'type03'}
            className={classNames(cn('btn'), css.searchButton)}
            onClick={handleSubmit(onSearchClick)}
          >
            {'검색'}
          </Button>
          <div className={classNames(cn('note', 'mt0', 'ml20'), css.result)}>
            {'총 '}
            <strong>
              <span className={cn('color-primary')}>{totalLength}</span>
            </strong>
            {' 건 성공'}
          </div>
        </div>
      </div>
    );
  }
);

export default DeliveryAlarmSearchArea;
