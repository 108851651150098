import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchandiseDashboard: {
    totalCnt: 0,
    activatedCnt: 0,
    activatedDeliveryCnt: 0,
    activatedCouponCnt: 0,
    underApprovalCnt: 0,
    rejectedCnt: 0,
    savedCnt: 0,
    approvedCnt: 0,
  },
  dashboardMainPanel: {
    merchandiseActivatedCnt: 0,
    settlementManagementCnt: 0,
    vendorAccountApprovalRequestedCnt: 0, //제휴사
    vendorMasterApprovalRequestedCnt: 0, //사업자
    refundManagementCnt: 0,
    deliveryManagementCnt: 0,
    internalAccountApprovalRequestedCnt: 0, //lg
    accountApprovalRequestedCnt: 0,
    couponManagementCnt: 0,
  },
  dashboardCouponInfo: {},
  dashboardVendorCouponInfo: {
    couponUseCompleteRequestedCnt: 0,
    couponNfocCnt: 0,
    couponCreationRequiredCnt: 0,
    csmsNewContractCnt: 0,
    couponFocCnt: 0,
    couponTalkdreamSendRequiredCnt: 0,
  },
  dashboardMerchandiseInfo: {},
  dashboardRefundInfo: {},
  dashboardVendorMerchandiseInfo: {
    activatedCnt: 0,
    underApprovalCnt: 0,
  },
  dashboardDeliveryInfo: {},
  dashboardVendorDeliveryInfo: {
    newOrderCnt: 0,
    statusD004Cnt: 0,
    nfocOrderCnt: 0,
    statusD001Cnt: 0,
    statusD003Cnt: 0,
    statusD002Cnt: 0,
    focOrderCnt: 0,
  },
  couponRegisterTargetDashboard: {
    merchandiseFocCnt: 0,
    merchandiseNfocCnt: 0,
    issueRequiredCouponCnt: 0,
    dailyAvgCouponCnt: 0,
    entireCouponCnt: 0,
    unusedCouponCnt: 0,
  },
  dashboardVendorRefundInfo: {
    unusedCouponRefundCnt: 0,
    deliveryCustomerRefundCnt: 0,
    couponCustomerRefundCnt: 0,
  },
  selectedVendorKey: 'VENDOR.00002',
  selectedCompanyName: 'LG생활건강',
};
const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    merchandiseDashboard: (state, action) => {
      state.merchandiseDashboard = action.payload;
    },
    dashboardMainPanel: (state, action) => {
      state.dashboardMainPanel = action.payload;
    },
    dashboardCouponInfo: (state, action) => {
      state.dashboardCouponInfo = action.payload;
    },
    dashboardVendorCouponInfo: (state, action) => {
      state.dashboardVendorCouponInfo = action.payload;
    },
    dashboardMerchandiseInfo: (state, action) => {
      state.dashboardMerchandiseInfo = action.payload;
    },
    dashboardVendorMerchandiseInfo: (state, action) => {
      state.dashboardVendorMerchandiseInfo = action.payload;
    },
    dashboardDeliveryInfo: (state, action) => {
      state.dashboardDeliveryInfo = action.payload;
    },
    dashboardVendorDeliveryInfo: (state, action) => {
      state.dashboardVendorDeliveryInfo = action.payload;
    },
    dashboardRefundInfo: (state, action) => {
      state.dashboardRefundInfo = action.payload;
    },
    dashboardVendorRefundInfo: (state, action) => {
      state.dashboardVendorRefundInfo = action.payload;
    },
    couponRegisterTargetDashboard: (state, action) => {
      state.couponRegisterTargetDashboard = action.payload;
    },
    setSelectedCompanyName: (state, action) => {
      state.selectedCompanyName = action.payload;
    },
    setSelectedVendorKey: (state, action) => {
      state.selectedVendorKey = action.payload;
    },
    resetSelectedCompanyName: (state) => {
      state.selectedCompanyName = initialState.selectedCompanyName;
    },
    resetSelectedVendorKey: (state) => {
      state.selectedVendorKey = initialState.selectedVendorKey;
    },
  },
});

export const {
  merchandiseDashboard,
  dashboardMainPanel,
  dashboardCouponInfo,
  dashboardVendorCouponInfo,
  dashboardMerchandiseInfo,
  dashboardVendorMerchandiseInfo,
  dashboardDeliveryInfo,
  dashboardVendorDeliveryInfo,
  dashboardVendorRefundInfo,
  dashboardRefundInfo,
  couponRegisterTargetDashboard,
  setSelectedCompanyName,
  setSelectedVendorKey,
  resetSelectedCompanyName,
  resetSelectedVendorKey,
} = dashboard.actions;
export default dashboard.reducer;
