import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { isWeekDay, isHoliday } from '@utils';
import { DISABLED_DATE_TYPE } from '@data/Const';
import classNames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import css from './HFCalendar.module.scss';
import ico_calendar from '@assets/img/ico_calendar.svg';

const cn = classNames.bind(css);

const DATE_NOW = Date.now();

/**
 * @category components
 * @namespace HFCalendar
 * @param {string} name handleSubmit에서 사용되는 고유 key 값
 * @param {bool} required 값 선택 필수 유무
 * @param {bool} disabled disabled
 * @param {Date} defaultValue 초기 선택 날짜로 기본 값은 '오늘'
 * @param {string} dateFormat YYYY-MM-DD,
 * @param {array} disabledDateTypeList DISABLED_DATE_TYPE[] 배열로 선택하지 못하는 날짜 타입을 지정
 * @description React-hook-form 사용 시 사용되는 HFCalendar
 */
const HFCalendar = ({
  name,
  required,
  disabled,
  control,
  defaultValue,
  dateFormat,
  disabledDateTypeList,
}) => {
  const ref = useRef(null);
  const holidaysOfKorea = useSelector(
    ({ holidays: { holidaysOfKorea } }) => holidaysOfKorea
  );
  const { field } = useController({
    name,
    defaultValue: defaultValue ?? new Date(),
    control,
    rules: {
      required,
      disabled,
    },
  });

  const isSelectableDate = useCallback(
    (date) => {
      if (!disabledDateTypeList?.length) return true;

      for (const type of disabledDateTypeList) {
        switch (type) {
          case DISABLED_DATE_TYPE.WEEKEND:
            if (!isWeekDay(date)) return false;
            break;
          case DISABLED_DATE_TYPE.HOLIDAY:
            if (isHoliday({ holidays: holidaysOfKorea, date })) return false;
            break;
          default:
            break;
        }
      }
      return true;
    },
    [disabledDateTypeList, holidaysOfKorea]
  );

  const onIconClick = useCallback(() => {
    ref.current.handleFocus();
  }, []);

  const onChangeDate = useCallback(
    (date) => {
      field.onChange(date);
    },
    [field]
  );

  return (
    <DatePicker
      className={cn('datePicker')}
      locale={ko}
      selected={field.value}
      onChange={onChangeDate}
      dateFormat={dateFormat}
      showIcon
      icon={
        <i className={cn('calIcon')}>
          <img onClick={onIconClick} src={ico_calendar} alt={'calendar Icon'} />
        </i>
      }
      showYearDropdown
      scrollableMonthYearDropdown
      filterDate={isSelectableDate}
      maxDate={new Date(DATE_NOW)}
      ref={ref}
    />
  );
};

HFCalendar.defaultProps = {
  defaultValue: new Date(DATE_NOW),
  dateFormat: 'yyyy-MM-dd',
};

export default HFCalendar;
