import { TAxios } from 'redux/function/TAxios';
import {
  setMailList,
  setDeletedList,
  setAutoSendList,
  setTotalCount,
  setDeletedTotalCount,
} from '@feature/mail/mailSlice';
import { openModalAlert } from '@feature/common/commonSlice';

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

/**
 * @category Action
 * @namespace getRetrieveMessageList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "userId": "admin",
  "fromDate": "20231201",
  "toDate": "20231231",
  "searchType": "CATEGORY / GROUP / SENDER / CONTENT",
  "detailCondition": "런드리고",
  "limit": "100",
  "page": "1"
}
 * @property {string} params.userId - userId
 * @property {string} params.fromDate - 시작날짜
 * @property {string} params.toDate - 종료날짜
 * @property {string} params.searchType - 검색 조건
 * @property {string} params.detailCondition - 검색 text
 * @property {string} params.limit - 
 * @property {string} params.page - 
 */

const getRetrieveMessageList = (dispatch, params, listType) => {
  const URL = `/api/1.0/app/message/retrieveMessageList`;

  const onSuccess = (response) => {
    if (listType === 'deleted') {
      dispatch(setDeletedList(response.data.rs.list));
    } else if (listType === 'autoSend') {
      dispatch(setAutoSendList(response.data.rs.list));
    } else {
      dispatch(setMailList(response.data.rs.list));
    }
  };
  const onFail = (response) => {
    console.log('get Retrieve Message onFail', response);
  };

  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

/**
 * @category Action
 * @namespace getRetrieveMessageList
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "deleteTargetList": "['MESSAGE.1701935609000000002', 'MESSAGE.1701935609000000001', 'MESSAGE.1701935609000000000']",
  "userId": "testUser"
}
 * @property {string[]} params.deleteTargetList - 삭제 대상 MessageKey
 * @property {string} params.userId - userId
 */

const postDeleteMessage = (dispatch, params, listParams) => {
  const URL = `/api/1.0/app/message/deleteMessage`;

  const onSuccess = () => {
    dispatch(openModalAlert({ message: '완료되었습니다.' }));
    getRetrieveMessageList(dispatch, listParams);
  };
  const onFail = (response) => {
    dispatch(openModalAlert({ message: '실패하였습니다 재시도 해주세요.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postUpdateReadFlag
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "updateTargetList": "['MESSAGE.1701935609000000002', 'MESSAGE.1701935609000000001', 'MESSAGE.1701935609000000000']",
  "userId": "testUser"
}
 * @property {string[]} params.updateTargetList - 업데이트 대상 MessageKey
 * @property {string} params.userId - userId
 */

const postUpdateReadFlag = (dispatch, params, listParams, hasPopup) => {
  const URL = `/api/1.0/app/message/updateReadFlag`;

  const onSuccess = () => {
    hasPopup && dispatch(openModalAlert({ message: '완료되었습니다.' }));
    getRetrieveMessageList(dispatch, listParams);
  };
  const onFail = (response) => {
    hasPopup &&
      dispatch(openModalAlert({ message: '실패하였습니다 재시도 해주세요.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace postRecoverMessage
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "recoverTargetList": "['MESSAGE.1701935609000000002', 'MESSAGE.1701935609000000001', 'MESSAGE.1701935609000000000']",
  "userId": "testUser"
}
 * @property {string[]} params.recoverTargetList - 복구 대상 MessageKey
 * @property {string} params.userId - userId
 */

const postRecoverMessage = (dispatch, params, listParams, listType) => {
  const URL = `/api/1.0/app/message/recoverMessage`;

  const onSuccess = () => {
    dispatch(openModalAlert({ message: '완료되었습니다.' }));
    getRetrieveMessageList(dispatch, listParams, listType);
  };
  const onFail = (response) => {
    dispatch(openModalAlert({ message: '실패하였습니다 재시도 해주세요.' }));
  };
  TAxios(
    undefined,
    undefined,
    'post',
    URL,
    params,
    onSuccess,
    onFail,
    false,
    header
  );
};

/**
 * @category Action
 * @namespace getMessageCount
 * @param {function} dispatch - dispatch 함수
 * @param {object} params - get parameters
 * @example
 * const params = {
  "userId": "testUser",
  "delFlag": "Y / N"
}
 * @property {string[]} params.userId - userId
 * @property {string} params.delFlag - 삭제 여부 flag
 */

const getMessageCount = (dispatch, params, messageType) => {
  const URL = `/api/1.0/app/message/retrieveMessageCountInfo`;

  const onSuccess = (response) => {
    if (messageType === 'deleted') {
      dispatch(setDeletedTotalCount(response.data.rs.messageCountInfo));
    } else {
      dispatch(setTotalCount(response.data.rs.messageCountInfo));
    }
  };
  const onFail = (response) => {
    console.log('post message count onFail', response);
  };
  TAxios(undefined, undefined, 'get', URL, params, onSuccess, onFail, false);
};

export {
  getRetrieveMessageList,
  postDeleteMessage,
  postUpdateReadFlag,
  postRecoverMessage,
  getMessageCount,
};
