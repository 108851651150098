import classNames from 'classnames/bind';
import css from './CouponListSearchArea.module.scss';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import HFInput from '@components/_ReactHookForm/HFInput';
import { Button } from '@components/index';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { convertDateToString2 } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import HFRadioButton from '@components/_ReactHookForm/HFRadioButton';
import HFCalendar from '@components/_ReactHookForm/HFCalendar';
import { DISABLED_DATE_TYPE, MILLISECONDS_PER_DAY } from '@data/Const';
import { getRetrieveMerchandiseList } from 'redux/action/couponAction';

const cn = classNames.bind(css);
const hasSubSelectBoxArray = [
  'MERCHANDISE_ID',
  'FOC_FLAG',
  'COUPON_STATUS',
  'VENDOR_NAME',
];

const RADIO_INPUT_VALUES = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
  ONE_MONTH: 'ONE_MONTH',
};

const DATE_NOW = Date.now();

const CouponListSearchArea = ({ keywordSearchOptions, onSearchClick }) => {
  const dispatch = useDispatch();

  const { register, control, handleSubmit, watch, setValue } = useFormContext();
  const watchKeywordSearchCondition = watch('keywordSearchCondition');

  const radioDateWatch = useWatch({
    control,
    name: 'radioDate',
    defaultValue: RADIO_INPUT_VALUES.ONE_MONTH,
  });

  useEffect(() => {
    getRetrieveMerchandiseList(dispatch);
  }, []);

  const retrieveMerchandiseList = useSelector(
    (state) => state.coupon.merchandiseList
  );

  const merchandiseOption = useMemo(() => {
    let optionList = [];
    retrieveMerchandiseList.map((item) => {
      return optionList.push({ id: item.code, title: item.value });
    });
    return optionList;
  }, [retrieveMerchandiseList]);

  const vendorList = useSelector((state) => state.common.vendorList);

  const vendorListOptions = useMemo(() => {
    let targetList = [];

    vendorList.map((vendor) => {
      return targetList.push({
        id: vendor.vendorKey,
        title: vendor.companyName,
      });
    });
    return targetList;
  }, [vendorList]);

  const hasSubKeyword = useMemo(() => {
    return hasSubSelectBoxArray.includes(watchKeywordSearchCondition);
  }, [watchKeywordSearchCondition]);

  const subKeywordSearchOptions = useMemo(() => {
    let targetList = [];
    switch (watchKeywordSearchCondition) {
      case 'MERCHANDISE_ID':
        targetList = [...merchandiseOption];
        break;
      case 'FOC_FLAG':
        targetList = [
          { id: 'NFOC', title: '유상' },
          { id: 'FOC', title: '무상' },
        ];
        break;
      case 'COUPON_STATUS':
        targetList = [
          { id: 'C002', title: '쿠폰발행' },
          { id: 'C003', title: '쿠폰사용등록' },
          { id: 'C004', title: '쿠폰사용완료확정' },
          { id: 'C005', title: '쿠폰재발행' },
          { id: 'C006', title: '쿠폰폐기' },
        ];
        break;
      case 'VENDOR_NAME':
        targetList = [...vendorListOptions];
        break;
      default:
        break;
    }
    const subKeyword = {
      guide: '선택',
      list: targetList,
    };
    return subKeyword;
  }, [watchKeywordSearchCondition, vendorListOptions, merchandiseOption]);

  const today = convertDateToString2(new Date());

  useEffect(() => {
    if (radioDateWatch !== undefined) {
      switch (radioDateWatch) {
        case RADIO_INPUT_VALUES.TODAY:
          setValue('fromDate', new Date(DATE_NOW));
          break;
        case RADIO_INPUT_VALUES.WEEK:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 7));
          break;
        case RADIO_INPUT_VALUES.ONE_MONTH:
          setValue('fromDate', new Date(DATE_NOW - MILLISECONDS_PER_DAY * 30));
          break;
        default:
          break;
      }
      setValue('toDate', new Date(DATE_NOW));
    }
  }, [radioDateWatch, setValue]);

  return (
    <div className={cn('searchOptionArea', 'row', 'mt20')}>
      <div className={cn('flex')}>
        <div className={cn('innerBox')}>
          <strong className={cn('title')}>조회 기간</strong>
          <div className={cn('dateBox', 'mb10')}>
            <HFCalendar
              name={'fromDate'}
              control={control}
              disabledDateTypeList={[
                DISABLED_DATE_TYPE.HOLIDAY,
                DISABLED_DATE_TYPE.WEEKEND,
              ]}
            />
            <span className={cn('dash')}>-</span>
            <HFCalendar
              name={'toDate'}
              control={control}
              disabledDateTypeList={[
                DISABLED_DATE_TYPE.HOLIDAY,
                DISABLED_DATE_TYPE.WEEKEND,
              ]}
            />
            <div className={css.radioBox}>
              <HFRadioButton
                name={'radioDate'}
                register={register}
                id={`alarm-today`}
                value={RADIO_INPUT_VALUES.TODAY}
              >
                오늘
              </HFRadioButton>
              <HFRadioButton
                name={'radioDate'}
                register={register}
                id={`alarm-week`}
                value={RADIO_INPUT_VALUES.WEEK}
              >
                일주일
              </HFRadioButton>
              <HFRadioButton
                name={'radioDate'}
                register={register}
                id={`alarm-oneMonth`}
                value={RADIO_INPUT_VALUES.ONE_MONTH}
                defaultValue={radioDateWatch}
              >
                1개월
              </HFRadioButton>
            </div>
          </div>
        </div>
      </div>
      <div className={cn('innerBox')}>
        <strong className={cn('title')}>조회 항목</strong>
        <div className={cn('selectbox', 'type01', 'sizeS')}>
          <HFSelectBox
            control={control}
            name={'keywordSearchCondition'}
            options={keywordSearchOptions}
          />
        </div>
        {hasSubKeyword && (
          <>
            <strong className={cn('title')} style={{ marginLeft: '15px' }}>
              추가 항목
            </strong>
            <div className={cn('selectbox', 'type01', 'sizeS')}>
              <HFSelectBox
                control={control}
                name={'subKeywordSearchOptions'}
                options={subKeywordSearchOptions}
              />
            </div>
          </>
        )}
        <div className={cn('inputWrap')} style={{ justifyContent: 'end' }}>
          {!hasSubKeyword && (
            <>
              {watchKeywordSearchCondition !== 'ALL' && (
                <>
                  <strong className={cn('title')}>상세 조건</strong>
                  <HFInput
                    register={register}
                    name={'keyword'}
                    type={'text'}
                    placeholder={
                      watchKeywordSearchCondition === 'ISSUE_DATE'
                        ? today
                        : '검색 조건을 선택해 주세요'
                    }
                  />
                </>
              )}
            </>
          )}

          <Button
            styleType={'btn01'}
            subType={'type03'}
            className={cn('btn')}
            onClick={handleSubmit(onSearchClick)}
          >
            검색
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CouponListSearchArea;
