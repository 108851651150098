import { memo } from 'react';
import css from './RadioButton.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace RadioButton
 * @param {string} id RadioButton 의 고유 id.
 * @param {boolean} checked RadioButton 체크 여부. (고유 속성)
 * @param {string} children RadioButton 의 자식 요소.
 * @description 공통적으로 사용하는 RadioButton 컴포넌트.
 */

const RadioButton = ({ id, children, ...rest }) => {
  return (
    <div className={cn('radioButton')}>
      <input id={id} type='radio' readOnly {...rest} />
      <label htmlFor={id} className={css.label}>
        <span>{children}</span>
      </label>
    </div>
  );
};

RadioButton.defaultProps = {
  /** RadioButton 체크 여부 */
  checked: false,
};

export default memo(RadioButton);
