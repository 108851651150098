// export const BASE_URL = '/lge.com_admin/build/';
export const BASE_URL = '';

export const ROUTER_PATH = {
  ROOT: `${BASE_URL}/`,
  TEST: `${BASE_URL}/index.html`,

  //protected
  HOME: `${BASE_URL}/home`,
  AUTHORITY: `${BASE_URL}/authority`,
  NOTICE: `${BASE_URL}/notice`,
  MAIL: `${BASE_URL}/mail`,

  // PRODUCT: `${BASE_URL}/product`,
  // DELIVERY: `${BASE_URL}/delivery`,
  // COUPON: `${BASE_URL}/coupon`,
  // RECEIPT: `${BASE_URL}/receipt`,
  // REFUND: `${BASE_URL}/refund`,
  // CALCULATE: `${BASE_URL}/calculate`,
  //protected

  //unprotected
  // REGISTER: `${BASE_URL}register`,
  // MYPAGE: `${BASE_URL}register/modify`,
  //unprotected

  //test
  COMPONENT: `${BASE_URL}/component`,
  TEMPLATE: `${BASE_URL}/template`,
  //test
};

// SUB_PATH
// entry point 는 수정예정
// restful 한 방식으로 수정할 예정 구체적인 구현은 더 생각해 볼 것.

//CASE 1) 단순 인덱싱, restful X 임시 구현

export const PRODUCT_SUB_PATH = {
  REGISTER: 'product/register',
  LIST: 'product/list',
};

export const DELIVERY_SUB_PATH = {
  ORDER: 'delivery/order',
  RESULT: 'delivery/result',
};

export const COUPON_SUB_PATH = {
  REGISTER: 'coupon/register',
  MANAGEMENT: 'coupon/management',
  LIST: 'coupon/list',
  ALARM: 'coupon/alarm',
  RESULT: 'coupon/result',
};

export const REFUND_SUB_PATH = {
  UNUSED: 'refund/unused',
  VENDOR: 'refund/vendor',
  CUSTOMER: 'refund/customer',
  RETRIEVE: 'refund/retrieve',
  ALARM: 'refund/alarm',
};

export const ACCOUNTING_SUB_PATH = {
  MANAGEMENT: 'accounting/management',
  TAXBILL: 'accounting/taxbill',
  APPROVAL: 'accounting/approval',
  LIST: 'accounting/list',
};

export const USER_SUB_PATH = {
  REGISTER: 'user/register',
  FIND: 'user/find',
  MODIFY: 'user/modify',
  MYINFO: 'user/myinfo',
};
