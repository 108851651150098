import React, { Fragment, memo } from 'react';
import Modal from 'react-modal';
import css from './PopUp.module.scss';
import classNames from 'classnames/bind';
import { useEffect } from 'react';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category Components
 * @namespace PopUP
 * @param {string} title PopUp의 title
 * @param {string | any} description PopUp의 description
 * @param {boolean} closeButton PopUp의 우측 상단 X버튼 존재 여부
 * @param {boolean} isOpenPopUp PopUp open/close 여부
 * @param {boolean} isWideSize PopUp 넓은 사이즈 여부
 * @callback onRequestCloseCallback PopUp 닫힐때 실행되는 함수
 * @param {ReactNode[]} button PopUp 의 버튼 리스트
 * @param {boolean} flexStart PopUp의 길이가 화면 크기보다 길어지면 flexStart를 적용해줘야 짤림 없이 동작함
 */

const PopUp = ({
  closeButton,
  isOpenPopUp,
  onRequestClose,
  title,
  description,
  children,
  button,
  flexStart,
  isWideSize,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.75)',
      display: 'flex',
      alignItems: flexStart ? 'flex-Start' : 'center',
      justifyContent: 'center',
      maxHeight: '100%',
      padding: '20px 0',
      zIndex: 100,
    },
    content: {
      maxHeight: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  };

  useEffect(() => {
    if (isOpenPopUp) {
      const root = document.getElementById('root');
      root.style.overflow = 'hidden';
    } else {
      const root = document.getElementById('root');
      root.style.overflow = 'auto';
    }
  }, [isOpenPopUp]);

  return (
    <div className={css.container}>
      {isOpenPopUp && (
        <div className={css.dimmed} onClick={onRequestClose}></div>
      )}
      <div className={css.layerPopup}>
        <Modal
          className={cn('popup', isWideSize ? 'wide' : null)}
          isOpen={isOpenPopUp}
          onRequestClose={onRequestClose}
          style={customStyles}
        >
          <div className={css.popup_title}>
            <strong>{title}</strong>
            {closeButton && (
              <div onClick={onRequestClose} className={css.popup_close} />
            )}
          </div>
          {description && <div className={css.note}>{description}</div>}
          <div className={css.popup_body}>{children}</div>
          <div className={css.buttonContianer}>
            {button &&
              button.map((item, idx) => <Fragment key={idx}>{item}</Fragment>)}
          </div>
        </Modal>
      </div>
    </div>
  );
};

PopUp.defaultProps = {
  closeButton: true,
  flexStart: false,
  isWideSize: false,
};

export default memo(PopUp);
