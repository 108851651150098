import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Fragment,
} from 'react';
import css from './AlarmRequestList.module.scss';
import classNames from 'classnames/bind';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Button,
  PopUp,
  VerticalTable,
  VerticalTableContainer,
} from '@components';
import TableCheckbox from '@components/Table/TableCheckbox';
import AlarmListSearchArea from './modules/AlarmListSearchArea';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { useSearchParams } from 'react-router-dom';
import Loading from 'template/Loading/Loading';
import { openModalAlert } from '@feature/common/commonSlice';
import HFInput from '@components/_ReactHookForm/HFInput';
import { handlePreventRegex, formatPhoneNumber } from '@utils';
import {
  getRetrieveDeliveryTalkdreamResultListAPI,
  postResendSingleDeliveryMessage,
  postResendDeliveryMessage,
} from 'redux/action/deliveryAction';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const resultListOptions = {
  guide: '',
  list: [
    { id: 'default', title: '전체' },
    { id: 'KAKAO', title: '알림톡' },
    { id: 'LMS', title: 'LMS' },
  ],
};

const tableData = [
  {
    messageKey: 'MESSAGE.1705999184000000000',
    sendResult: 'SUCCESSED',
    receiverName: '김하나',
    receiverMobileNo: '01063311080',
    vendorName: '대리주부',
    categoryDesc: '쿠폰발행완료',
    requestDatetime: '2024-01-23',
    sendMethod: 'KAKAO',
    lmsResult: '',
    vendorKey: 'VENDOR.00004',
    deliveryPlanNo: '105893988',
    service: 'ACE.00002',
    sendDatetime: '2024-01-23 17:53:34',
    contractLineNo: '18989340',
    category: 'COUPON_ISSUE',
    kakaoResult: '실패',
  },
  {
    messageKey: 'MESSAGE.1705999184000000000',
    sendResult: 'SUCCESSED',
    receiverName: '김둘',
    receiverMobileNo: '01011112222',
    vendorName: '다락',
    categoryDesc: '쿠폰발행완료',
    requestDatetime: '2024-01-23',
    sendMethod: 'KAKAO',
    lmsResult: '성공',
    vendorKey: 'VENDOR.00005',
    deliveryPlanNo: '105893988',
    service: 'ACE.00002',
    sendDatetime: '2024-01-23 17:53:34',
    contractLineNo: '18989340',
    category: 'COUPON_ISSUE',
    kakaoResult: '',
  },
];

const AlarmModifiedInterface = {
  receiverName: '',
  contractNum: '',
  phoneNo0: '010',
  phoneNo1: '',
  phoneNo2: '',
  modifiedPhoneNo0: '',
  modifiedPhoneNo1: '',
  modifiedPhoneNo2: '',
};

const AlarmRequestList = ({ resultDetailListParam }) => {
  const searchForms = useForm();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: AlarmModifiedInterface,
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.loading);

  const couponResultDeatilList = useSelector(
    (state) => state.coupon.retrieveCouponTalkdreamResultDetailList
  );

  const userId = useSelector((state) => state.user.userData.userId);

  const [selectedCouponkeysCount, setSelectedCouponkeysCount] = useState();
  const [isOpenChangeInfo, setIsOpenChangeInfo] = useState(false);

  const alarmSearchRef = useRef({
    getSelectBoxWatch: () => {},
    getRadioAllOrFailWatch: () => {},
  });

  const selectBoxWatchValue = alarmSearchRef.current.getSelectBoxWatch();
  const radioAllOrFailValue = alarmSearchRef.current.getRadioAllOrFailWatch();
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-alarm-detail-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'LG-alarm-detail-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },
      columnHelper.accessor('requestDatetime', {
        id: 'requestDatetime',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>알림 요청일시</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>업체명</span>,
      }),
      columnHelper.accessor('receiverName', {
        id: 'receiverName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>수신자 명</span>,
      }),

      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약번호</span>,
      }),
      columnHelper.accessor('service', {
        id: 'service',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>서비스명</span>,
      }),
      columnHelper.accessor('receiverMobileNo', {
        id: 'receiverMobileNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>휴대폰번호</span>,
      }),
      columnHelper.accessor('kakaoResult', {
        id: 'kakaoResult',
        cell: (info) => {
          switch (info.getValue()) {
            case '성공':
              return <span>성공</span>;
            case '실패':
              return <span className={cn('color-primary')}>실패</span>;
            case '':
              return <span>-</span>;
            default:
              return info.getValue();
          }
        },
        header: () => <span>알림톡 결과</span>,
      }),
      columnHelper.accessor('lmsResult', {
        id: 'lmsResult',
        cell: (info) => {
          switch (info.getValue()) {
            case '성공':
              return <span>성공</span>;
            case '실패':
              return <span className={cn('color-primary')}>실패</span>;
            case '':
              return <span>-</span>;
            default:
              return info.getValue();
          }
        },
        header: () => <span>LMS 결과</span>,
      }),
    ],
    []
  );

  // TODO: SearchParams 테스트
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const messageKeysRef = useRef([]);
  const receiverInfoRef = useRef({});

  const formattedPhoneNo = formatPhoneNumber(
    receiverInfoRef?.current[0]?.receiverMobileNo
  );

  const phoneNumArr = formattedPhoneNo?.split('-');

  const handleOpenChangeInfo = useCallback(() => {
    setIsOpenChangeInfo(!isOpenChangeInfo);
    reset({
      receiverName: receiverInfoRef?.current[0]?.receiverName,
      contractNum: receiverInfoRef?.current[0]?.contractLineNo,
      phoneNo0: phoneNumArr[0],
      phoneNo1: phoneNumArr[1],
      phoneNo2: phoneNumArr[2],
      modifiedPhoneNo0: '',
      modifiedPhoneNo1: '',
      modifiedPhoneNo2: '',
    });
  }, [isOpenChangeInfo, phoneNumArr, reset]);

  const onAlarmInfoModifiedSubmit = useCallback(
    (data) => {
      const { modifiedPhoneNo0, modifiedPhoneNo1, modifiedPhoneNo2 } = data;

      const params = {
        resendTarget: messageKeysRef.current[0] ?? '',
        userId: userId,
        mobileNo: `${modifiedPhoneNo0}-${modifiedPhoneNo1}-${modifiedPhoneNo2}`,
      };
      if (modifiedPhoneNo0 !== '010') {
        dispatch(
          openModalAlert({ message: '올바른 전화번호를 입력해 주세요.' })
        );
      } else postResendSingleDeliveryMessage(dispatch, params);
    },
    [userId]
  );

  const onFail = useCallback((data) => {
    const { modifiedPhoneNo0, modifiedPhoneNo1, modifiedPhoneNo2 } = data;
    if (
      modifiedPhoneNo0?.type === 'required' ||
      modifiedPhoneNo1?.type === 'required' ||
      modifiedPhoneNo2?.type === 'required'
    ) {
      dispatch(openModalAlert({ message: '올바른 전화번호를 입력해 주세요.' }));
    }
  }, []);

  useEffect(() => {
    const fetchResultDetailListData = async () => {
      //FIXME: totalCount 가 없어서 pagenation안됨
      const params = {
        requestDatetime: resultDetailListParam?.requestDatetime,
        category: resultDetailListParam?.category,
        vendorKey: resultDetailListParam?.vendorKey,
        sendMethod:
          selectBoxWatchValue === 'default' ? '' : selectBoxWatchValue,
        sendResult: radioAllOrFailValue === 'all' ? '' : 'FAILED',
        limit: '100',
        page: page ?? '1',
      };

      await dispatch(getRetrieveDeliveryTalkdreamResultListAPI(params));
    };

    if (radioAllOrFailValue && selectBoxWatchValue) {
      fetchResultDetailListData();
    }
  }, [
    resultDetailListParam,
    page,
    limit,
    selectBoxWatchValue,
    radioAllOrFailValue,
    dispatch,
  ]);

  useEffect(() => {
    if (selectBoxWatchValue !== undefined) {
      searchParams.set('page', page ?? '1');
      searchParams.set('limit', limit ?? '100');
      setSearchParams(searchParams);
    }
  }, [selectBoxWatchValue, searchParams, setSearchParams, page, limit]);

  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '100');
    setSearchParams(searchParams);
  }, []);

  const selectedRowCallback = useCallback((rows) => {
    let messageKeyArr = [];
    let receiverInfoObj = {};
    rows.forEach((row) => {
      return messageKeyArr.push(row.messageKey);
    });
    messageKeysRef.current = messageKeyArr;
    receiverInfoRef.current = rows.map((row) => {
      receiverInfoObj = {
        receiverName: row.receiverName,
        receiverMobileNo: row.receiverMobileNo,
        contractLineNo: row.contractLineNo,
      };
      return receiverInfoObj;
    });
    setSelectedCouponkeysCount(messageKeysRef.current?.length);
  }, []);

  const resendCouponMessageMultiple = useCallback(
    (selectedmessagekeys) => {
      const params = {
        resendTargetList: JSON.stringify(selectedmessagekeys),
        userId: userId,
      };
      postResendDeliveryMessage(dispatch, params);
    },
    [userId]
  );

  return (
    <>
      <FormProvider {...searchForms}>
        <AlarmListSearchArea
          resultListOptions={resultListOptions}
          ref={alarmSearchRef}
        />
      </FormProvider>
      <TableV8ManualPage
        columns={columns}
        // FIXME: 테스트 위해 mockData 연동
        data={couponResultDeatilList}
        // data={tableData}
        getRowselected={selectedRowCallback}
        handlePageCallback={handlePageCallback}
        hasPageSize={false}
        limit={100}
        rightControls={
          <div className={css.btn_container6}>
            <div className={css.btn}>
              <Button
                subType={'type04'}
                disabled={!messageKeysRef.current?.length > 0}
                onClick={() =>
                  resendCouponMessageMultiple(messageKeysRef.current)
                }
                fullWidth
              >
                대량 재발송
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                subType={'type04'}
                onClick={handleOpenChangeInfo}
                disabled={messageKeysRef.current?.length !== 1}
                fullWidth
              >
                단건 수정 발송
              </Button>
            </div>
          </div>
        }
      />
      <PopUp
        title={'알림 정보 변경'}
        description={
          '알림 대상이 고객일 경우 개인 정보는 변경되지 않고 해당 알림 재발송 시 1회 적용됩니다.'
        }
        isOpenPopUp={isOpenChangeInfo}
        onRequestClose={handleOpenChangeInfo}
        closeButton
      >
        <VerticalTableContainer
          button={[
            <div className={css.btn_container4}>
              <div className={css.btn}>
                <Button
                  key={'cancelInfo'}
                  subType={'type03'}
                  onClick={handleOpenChangeInfo}
                  fullWidth
                >
                  닫기
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  key={'saveInfo'}
                  subType={'type02'}
                  onClick={handleSubmit(onAlarmInfoModifiedSubmit, onFail)}
                  fullWidth
                >
                  저장
                </Button>
              </div>
            </div>,
          ]}
          isCenter
        >
          <VerticalTable title={'수신자 명'}>
            <HFInput
              type={'text'}
              name={'receiverName'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'계약번호'}>
            <HFInput
              type={'text'}
              name={'contractNum'}
              register={register}
              readOnly
            />
          </VerticalTable>
          <VerticalTable title={'현재 휴대폰 번호'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <Fragment key={index}>
                  <HFInput
                    type={'text'}
                    name={`phoneNo${index}`}
                    register={register}
                    readOnly
                  />
                  {index < 2 && <span>-</span>}
                </Fragment>
              ))}
            </div>
          </VerticalTable>
          <VerticalTable title={'변경 휴대폰 번호'}>
            <div
              className={cn(
                'flex',
                'align-center-gap8',
                'phoneNumberContainer'
              )}
            >
              {new Array(3).fill(0).map((_, index) => (
                <Fragment key={index}>
                  <HFInput
                    type={'text'}
                    name={`modifiedPhoneNo${index}`}
                    register={register}
                    maxLength={index === 0 ? 3 : 4}
                    onKeyPress={(e) => handlePreventRegex(e, /^[0-9]*$/)}
                    required
                    pattern='^[0-9]*$'
                  />
                  {index < 2 && <span>-</span>}
                </Fragment>
              ))}
            </div>
          </VerticalTable>
        </VerticalTableContainer>
      </PopUp>
      <PopUp isOpenPopUp={loading} closeButton={false}>
        <Loading />
      </PopUp>
    </>
  );
};

export default AlarmRequestList;
