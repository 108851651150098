import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@components/index';
import css from './Notice.module.scss';
import classNames from 'classnames/bind';
import {
  getNoticeList,
  postIncreaseNoticeReadCount,
} from 'redux/action/noticeAction';
import {
  setNoticeListPopup,
  setNoticeDetailPopup,
  setNoticeRegisterPopup,
  setNoticeEditPopup,
} from '@feature/common/commonSlice';
import TableV8 from '@components/Table/TableV8';
import { createColumnHelper } from '@tanstack/react-table';
import { setCurrentNotice } from '@feature/notice/noticeSlice';
const columnHelper = createColumnHelper();

const cn = classNames.bind(css);

const Notice = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notice.contents);
  const myAuthority = useSelector((state) => state.user.userData.authority);
  const userId = useSelector((state) => state.user.userData.userId);

  const tableData = useMemo(() => {
    let resultArr = [];
    if (Object.keys(data).length > 0) {
      const filteredDelflag = Object.values(data).filter(
        (data) => data.delFlag === 'N'
      );
      const dataArr = Object.values(filteredDelflag);

      let fixedElements = [];

      for (let i = dataArr.length - 1; i >= 0; i--) {
        if (dataArr[i].noticeFixedFlag === 'Y') {
          fixedElements = fixedElements.concat(dataArr.splice(i, 1)); // 조건에 맞는 요소를 배열에서 제거하고 fixedElements에 추가
        }
      }
      const topFixedElements = fixedElements
        .sort((a, b) => {
          const dateA = new Date(a.createDatetime);
          const dateB = new Date(b.createDatetime);
          return dateB - dateA;
        })
        .splice(0, 3);

      const notTopFixed = [...dataArr, ...fixedElements];

      const sortedRestArr = notTopFixed.sort((a, b) => {
        const dateA = new Date(a.createDatetime);
        const dateB = new Date(b.createDatetime);
        return dateB - dateA;
      });

      const mergedArr = [...topFixedElements, ...sortedRestArr];
      resultArr = mergedArr.map((item, index) => {
        return Object.assign({}, item, {
          no: mergedArr.length - index,
          isTopFixed: topFixedElements.includes(item),
        });
      });
    }
    return resultArr;
  }, [data]);

  const isNoticeListPopup = useSelector(
    (state) => state.common.popup.isNoticeListPopup
  );
  const isNoticeRegisterPopup = useSelector(
    (state) => state.common.popup.isNoticeRegisterPopup
  );
  const isNoticeDetailPopup = useSelector(
    (state) => state.common.popup.isNoticeDetailPopup
  );
  const isNoticeEditPopup = useSelector(
    (state) => state.common.popup.isNoticeEditPopup
  );

  useEffect(() => {
    const params = {
      fromDate: '20231101',
      toDate: new Date(),
      searchType: 'SUBJECT',
      detailCondition: '',
    };
    getNoticeList(dispatch, params);
  }, []);

  const handleListPopUpClick = useCallback(() => {
    dispatch(setNoticeListPopup(!isNoticeListPopup));
    //eslint-disable-next-line
  }, [isNoticeListPopup]);

  const handleRegisterPopUpClick = useCallback(() => {
    dispatch(setNoticeRegisterPopup(!isNoticeRegisterPopup));
    //eslint-disable-next-line
  }, [isNoticeRegisterPopup]);

  const handleDetailPopUpClick = useCallback(() => {
    dispatch(setNoticeDetailPopup(!isNoticeDetailPopup));
    //eslint-disable-next-line
  }, [isNoticeDetailPopup]);

  const handleCurrentNotice = useCallback((target) => {
    if (target.createUserId !== userId) {
      postIncreaseNoticeReadCount(dispatch, {
        noticeKey: target.noticeKey,
        userId: userId,
      });
    }

    dispatch(setCurrentNotice(target));
  }, []);

  const handleEditPopUpClick = useCallback(() => {
    dispatch(setNoticeEditPopup(!isNoticeEditPopup));
    //eslint-disable-next-line
  }, [isNoticeEditPopup]);

  const columnData = useMemo(() => {
    return [
      // columnHelper.accessor('no', {
      //   id: 'no',
      //   cell: (info) => info.getValue(),
      //   header: () => <span>No</span>,
      // }),
      columnHelper.accessor('subject', {
        id: 'subject',
        cell: (info) => {
          return (
            <>
              {info.row.original.isTopFixed ? (
                <strong>{info.getValue()}</strong>
              ) : (
                info.getValue()
              )}
            </>
          );
        },
        header: () => <span>제목</span>,
      }),
      columnHelper.accessor('content', {
        id: 'content',
        cell: (info) => (
          <>
            <button
              onClick={() => {
                handleDetailPopUpClick();
                handleCurrentNotice(info.row.original);
              }}
              className={cn('btn_txt04', 'content')}
              dangerouslySetInnerHTML={{
                __html:
                  info
                    .getValue()
                    .replace(/<img[^>]*>(.*?)<\/img>/g, '')
                    .match(/<p>(.*?)<\/p>/)?.[0] ??
                  info.getValue().replace(/<img[^>]*>(.*?)<\/img>/g, ''),
              }}
            ></button>
            {info.row.original.commentCount > 0 && (
              <span className={css.ico_reply}>
                {info.row.original.commentCount}
              </span>
            )}
          </>
        ),
        header: () => <span>내용</span>,
        size: 300,
      }),
      columnHelper.accessor('createDatetime', {
        id: 'createDatetime',
        cell: (info) => info.getValue().split(' ')[0],
        header: () => <span>게시일</span>,
      }),
      // columnHelper.accessor('readCount', {
      //   id: 'readCount',
      //   cell: (info) => info.getValue(),
      //   header: () => <span>조회</span>,
      // }),
    ];
    //참조 값이 없지만 의존성 배열에 data 넣어야 정상동작함
  }, [handleDetailPopUpClick, handleCurrentNotice, data]);

  return (
    <div className={css.container}>
      <h3 className={css.areaTitle}>공지사항</h3>
      <TableV8
        data={tableData}
        columns={columnData}
        hasCsvDownload={false}
        rightControls={
          <div className={css.box_right}>
            <Button onClick={() => handleListPopUpClick()} styleType={'btn03'}>
              목록
            </Button>
            {(myAuthority === 'LEVEL_1' || myAuthority === 'LEVEL_2') && (
              <Button
                onClick={() => handleRegisterPopUpClick()}
                styleType={'btn03'}
                subType={'type02'}
              >
                작성하기
              </Button>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Notice;
