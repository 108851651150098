import { memo, useCallback } from 'react';
import css from './Logo.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATH } from '@router/routerPath';
import logo from '@assets/img/logo.svg';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace Logo
 * @description DashboardLayout에서 사용하는 Logo 컴포넌트.
 */

const Logo = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback((path, state) => {
    navigate(path, state);
  }, []);

  return (
    <h1
      className={css.logo}
      onClick={() => {
        handleNavigate(ROUTER_PATH.HOME);
      }}
    >
      <img className={css.logoImg} src={logo} alt='LG' />
      제휴사 Admin
    </h1>
  );
};

Logo.defaultProps = {};

export default memo(Logo);
