import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import css from './CouponRegisterFirst.module.scss';
import classNames from 'classnames/bind';
import {
  RadioButton,
  Button,
  PopUp,
  SelectBox,
  TableV8,
} from '@components/index';
import { useRadio, useSelect } from '@hooks';
import CouponRegisterSecond from '../CouponRegisterSecond/CouponRegisterSecond';
import { useDispatch, useSelector } from 'react-redux';
import { getCouponOverallStatusListById } from 'redux/action/couponAction';
import { createColumnHelper } from '@tanstack/react-table';
import { getRandomNumTemplate, backendMode } from '@utils';
import { useSearchParams } from 'react-router-dom';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';
import { FormProvider, useForm } from 'react-hook-form';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

const columnHelper = createColumnHelper();

//TODO: couponStatus 별로 API요청 및 page/limit 로직 적용해야함

const SELECT_DATA = {
  guide: '전체',
  list: [
    { id: 'total', title: '전체' },
    { id: 'C002', title: '쿠폰발행' },
    { id: 'C003', title: '쿠폰사용등록' },
    { id: 'C004', title: '쿠폰사용완료확정' },
    { id: 'C005', title: '쿠폰재발행' },
    { id: 'C006', title: '쿠폰폐기' },
  ],
};

const CouponRegisterFirst = ({ registerRequiredList }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, setValue } = useForm();
  const test = watch('keywordSearchCondition');
  const couponOverallStatusListById = useSelector(
    (state) => state.coupon.couponOverallStatusListById.list
  );
  const couponOverallStatusListByIdTotalLength = useSelector(
    (state) =>
      state.coupon.couponOverallStatusListById.additionalInfo.totalCount
  );
  const userDataVendorKey = useSelector(
    (state) => state.user.userData.vendorKey
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorKeySearchParams = searchParams.get('vendorKey');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const [selectMerchandiseId, handleClickMerchandiseId] = useRadio(null);
  // const [selectedStatusIndex, setSelectedStatusIndex, selectedStatusLabel] =
  //   useSelect({
  //     optionList: SELECT_DATA,
  //     initialIndex: 0,
  //     initialLabel: SELECT_DATA[0].title,
  //   });
  const [currentCouponItem, setCurrentCouponItem] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [randomNumber, setRandomNumber] = useState([]);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [currentPopupItem, setCurrentPopupItem] = useState();
  const [uploadedContentCSV, setUploadedContentCSV] = useState([]);
  const randomNumberRef = useRef();

  const handlePostRadio = useCallback(
    (title) => {
      if (registerRequiredList.length > 0) {
        handleClickMerchandiseId(title);
        setCurrentCouponItem(
          registerRequiredList.filter((item) => item.merchandiseId === title)
        );
      }
    },
    [registerRequiredList]
  );

  const handleFileChange = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = function (event) {
        let result = [];
        let contents = event.target.result;

        contents = contents.replace(/\r/g, ',');
        let elements = contents.split(',');
        for (let i = 0; i < elements.length; i++) {
          elements[i] = elements[i].replace(/\n/g, '');
          elements[i] = elements[i].replace(/\\n/g, '');
        }
        if (Array.isArray(elements) && elements.length > 0) {
          result = elements.filter((el) => el !== '' && el !== ' ');
        }
        setUploadedContentCSV(result);
      };

      reader.readAsText(file);
      setSelectedFile(fileName);
    }
  };

  // const handleCancelClick = () => {
  //   // 취소 버튼을 누를 때 선택한 파일을 제거합니다.
  //   setSelectedFile(null);
  // };

  const addCouponRandomNumber = useCallback(() => {
    if (randomNumberRef.current && randomNumberRef.current.value) {
      const addedNumList = randomNumber.concat(randomNumberRef.current.value);
      setRandomNumber(addedNumList);
    }
  }, [randomNumber]);

  const openPopup = useCallback(
    (coupon) => {
      const tempParams = {
        searchType: 'MERCHANDISE_ID',
        detailCondition: coupon.merchandiseId,
        vendorKey:
          process.env.REACT_APP_MODE === 'LG' && vendorKeySearchParams
            ? vendorKeySearchParams
            : process.env.REACT_APP_MODE === 'LG'
              ? ''
              : userDataVendorKey,
        limit: '100',
        page: '1',
      };
      getCouponOverallStatusListById(dispatch, tempParams);
      setCurrentPopupItem(coupon);
      setIsOpenPopUp(true);
    },
    [userDataVendorKey, test]
  );

  const closePopup = useCallback(() => {
    setIsOpenPopUp(false);
  }, []);

  const columnData = useMemo(
    () => [
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('createDatetime', {
        id: 'createDatetime',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>생성일</span>,
      }),
      columnHelper.accessor('issueDate', {
        id: 'issueDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발행일</span>,
      }),
      columnHelper.accessor('couponStatusDesc', {
        id: 'couponStatusDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상태</span>,
      }),
      columnHelper.accessor('couponStatus', {
        id: 'couponStatus',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>쿠폰 상태 코드</span>,
      }),
    ],
    []
  );

  useEffect(() => {
    if (randomNumberRef?.current?.value) {
      randomNumberRef.current.value = '';
    }
    setRandomNumber([]);
  }, [selectMerchandiseId]);

  const pushCSVContents = useCallback(() => {
    //CSV에서 추출한 배열을 기존 배열과 합산
    const addedCSVData = [...randomNumber, ...uploadedContentCSV];
    setRandomNumber(addedCSVData);
  }, [uploadedContentCSV, randomNumber]);

  const randomNumberCallback = useCallback((value) => {
    // 자식요소에서 randomNumber 수정할 수 있도록 콜백함수 내림
    setRandomNumber(value);
  }, []);

  const randomNumberPopupItem = useMemo(() => {
    // 쿠폰발행 쿠폰사용완료확정
    let pubCount = 0;
    let cplCount = 0;
    couponOverallStatusListById.forEach((el) => {
      if (el.couponStatus === 'C002') {
        pubCount++;
      } else if (el.couponStatus === 'C004') {
        cplCount++;
      }
    });
    return { pubCount, cplCount };
  }, [couponOverallStatusListById]);

  const handleOpenGuide = () => {
    const serverType = backendMode();
    const url = getRandomNumTemplate(serverType);

    window.open(url, '_blank');
  };

  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '10');
    setSearchParams(searchParams);
  }, []);

  return (
    <>
      <div className={css.stepGuideText}>
        ① 쿠폰 생성이 필요한 상품을 알려주세요. 등록 필요 Y (등록 필요한 쿠폰 수
        이상으로 쿠폰이 등록되어야 합니다)
      </div>
      <div className={css.dataTable01}>
        <div className={css.totalCount}>
          검색 결과 <b>{registerRequiredList.length}</b>건
        </div>
        <table>
          <thead>
            <tr>
              <th rowSpan='2'>구분</th>
              <th rowSpan='2'>정산</th>
              <th rowSpan='2'>상품 ID</th>
              <th rowSpan='2'>상품</th>
              <th rowSpan='2'>상태</th>
              <th rowSpan='2'>등록 필요</th>
              <th colSpan='2' className={css.bdl}>
                난수 번호
              </th>
            </tr>
            <tr>
              <th className={css.bdl}>시스템 등록된 쿠폰 수</th>
              <th className={css.bdl}>발행 필요한 쿠폰 수</th>
            </tr>
          </thead>
          <tbody>
            {registerRequiredList.length > 0 &&
              registerRequiredList.map((item) => {
                return (
                  <tr key={item.merchandiseId}>
                    <td>
                      <div className={cn('input_radio', 'type02')}>
                        {item.salesStatusDesc === '사용' && (
                          <RadioButton
                            key={item.merchandiseId}
                            id={item.merchandiseId}
                            value={selectMerchandiseId}
                            checked={selectMerchandiseId === item.merchandiseId}
                            onChange={() => handlePostRadio(item.merchandiseId)}
                          />
                        )}
                      </div>
                    </td>
                    <td>{item.focFlagDesc}</td>
                    <td
                      className={css.underLine}
                      onClick={() => openPopup(item)}
                    >
                      {item.merchandiseId}
                    </td>
                    <td>{item.merchandiseDesc}</td>
                    <td>{item.salesStatusDesc}</td>
                    <td>
                      {item.salesStatusDesc === '사용' &&
                      item.issueRequiredCouponCnt > 0
                        ? 'Y'
                        : 'N'}
                    </td>
                    <td className={css.bdl}>{item.unusedCouponCnt}</td>
                    <td className={css.bdl}>{item.issueRequiredCouponCnt}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {process.env.REACT_APP_MODE !== 'LG' && (
        <>
          <div className={css.stepGuideText}>
            ② 등록 할 쿠폰을 추가 해주세요.
          </div>
          <div className={cn('addCoupon', 'mt16')}>
            <div className={cn('box', 'input')}>
              <div className={css.flex}>
                <span className={css.title}>개별 입력</span>

                <div className={css.input_text}>
                  <input ref={randomNumberRef} type='text' name='' id='' />
                </div>
                <div className={css.btn_add}>
                  <Button
                    subType={'type03'}
                    onClick={() => addCouponRandomNumber()}
                    fullWidth
                  >
                    추가
                  </Button>
                </div>
              </div>
              <p className={css.note}>한 건씩 등록 가능합니다.</p>
            </div>
            <div className={cn('box', 'file')}>
              <span className={css.title}>첨부 파일</span>
              <div className={css.attatchedFile}>
                <label
                  htmlFor='file-input'
                  className={cn('btn', 'btn02', 'type01', 'pointer')}
                >
                  파일 선택
                </label>
                <input
                  id='file-input'
                  type='file'
                  className={cn('btn', 'btn02', 'type01')}
                  onChange={handleFileChange}
                />
                <div className={css.fileName}>{selectedFile}</div>
              </div>
              <button
                type='button'
                className={cn('btn', 'btn01', 'type03', 'btn_add')}
                onClick={() => pushCSVContents()}
              >
                추가
              </button>
            </div>
          </div>
          <div className={css.download_form}>
            <Button
              styleType={'btn02'}
              subType={'type02'}
              onClick={() => handleOpenGuide()}
            >
              양식 다운로드
            </Button>
            <div className={css.info}>
              ⓘ 대량 입력은 엑셀(CSV) 파일을 난수 번호를 입력 후 등록해주세요.
            </div>
          </div>
          <div className={css.stepsNav}></div>
          <CouponRegisterSecond
            randomNumber={randomNumber}
            selectMerchandiseId={selectMerchandiseId}
            currentCouponItem={currentCouponItem}
            randomNumberCallback={randomNumberCallback}
          />
        </>
      )}

      <PopUp
        isWideSize
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
      >
        <div className={cn('layerPopup')}>
          <div className={cn('popup')}>
            <strong className={cn('popup_title')}>
              {`[${currentPopupItem?.merchandiseId} ${currentPopupItem?.merchandiseDesc}] 쿠폰 난수번호 리스트`}
            </strong>
            <div className={cn('table_header')}>
              <div className={cn('box_left')}>
                {/* <FormProvider>
                  <HFSelectBox
                    control={control}
                    name={'keywordSearchCondition'}
                    options={SELECT_DATA}
                  />
                </FormProvider> */}
              </div>
              <div className={cn('box_right')}>
                <div>
                  총 <b>{couponOverallStatusListById?.length ?? 0}</b>건
                  <span className={cn('font-small')}>
                    (발행 {randomNumberPopupItem.pubCount}건, 사용완료확정{' '}
                    {randomNumberPopupItem.cplCount}건)
                  </span>
                </div>
              </div>
            </div>
            <div className={cn('dataTable01')}>
              <TableV8ManualPage
                columns={columnData}
                data={couponOverallStatusListById}
                page={page}
                limit={limit ?? undefined}
                hasPageSize={false}
                hasCsvDownload={false}
                handlePageCallback={handlePageCallback}
                totalLength={couponOverallStatusListByIdTotalLength}
              />
            </div>
            <div className={cn('btn_container4')}>
              <Button
                styleType={'btn01'}
                subType={'type01'}
                onClick={() => closePopup()}
              >
                닫기
              </Button>
            </div>
          </div>
        </div>
      </PopUp>
    </>
  );
};

export default CouponRegisterFirst;
