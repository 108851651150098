import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import css from '../CouponUsed.module.scss';
import {
  PopUp,
  Table,
  CsvDownload,
  CsvUpload,
  RadioButton,
  Input,
  TableV8,
  PopUpAlert,
  Button,
} from '@components/index';
import { useRadio, useInput } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getCouponUseApprovalStatusList,
  getCouponUseCompletedStatusDashboardAPI,
  getMerchandiseCouponList,
  postCouponUseComplete,
} from 'redux/action/couponAction';
import { createColumnHelper } from '@tanstack/react-table';
import { getRandomNumTemplate, backendMode } from '@utils';

const cn = classNames.bind(css);
const columnHelper = createColumnHelper();

const FAIL_MOCK_DATA = [
  {
    some1: '2021-11-11',
    some2: 'cssg11232',
    some3: '2022-11-01',
    some4: '무상',
    some5: 'Ac0001',
    some6: '런드리고 포인트 쿠폰',
    some7: '-',
    some8: '난수번호 소진',
  },
];
const CouponUsedVendor = ({}) => {
  const dispatch = useDispatch();
  const randomNumberRef = useRef(null);
  const userData = useSelector((state) => state.user.userData);
  const willCompleteTargetCouponList = useSelector(
    (state) => state.coupon.willCompleteTargetCouponList.list
  ); //TODO: mockdata 수정 필요

  const couponUseApprovalStatus = useSelector(
    (state) => state.coupon.couponUseApprovalStatus.list
  );
  const couponUseCompletedStatusDashboard = useSelector(
    (state) => state.coupon.couponUseCompletedStatusDashboard
  );
  const [currentCouponItem, setCurrentCouponItem] = useState([]);
  const [selectMerchandiseId, handleClickMerchandiseId] = useRadio(null);
  // const [firstNumber, setFirstNumber, onChangeFirstNumber] = useInput('');
  // const [secondNumber, setSecondNumber, onChangeSecondNumber] = useInput('');
  // const [thirdNumber, setThirdNumber, onChangeThirdNumber] = useInput('');
  // const [lastNumber, setLastNumber, onChangeLastNumber] = useInput('');
  const [couponList, setCouponList] = useState([]);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [isOpenConfirmPopUp, setIsOpenConfirmPopUp] = useState(false);

  //
  const [randomNumber, setRandomNumber] = useState([]);
  const [duplicatedCount, setDuplicatedCount] = useState(0);
  const [filteredRandomNumberArray, setFilteredRandomNumberArray] = useState(
    []
  );
  const [uploadedContentCSV, setUploadedContentCSV] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handlePostRadio = useCallback(
    (title) => {
      handleClickMerchandiseId(title);
      if (title) {
        setCurrentCouponItem(
          couponList.filter((item) => item.merchandiseId === title)
        );
      }
    },
    [couponList]
  );
  const columnData = useMemo(() => {
    return [
      {
        accessor: 'some1',
        Header: '상품 아이디',
      },
      {
        accessor: 'some2',
        Header: '난수번호',
      },
      {
        accessor: 'some3',
        Header: '계약라인번호(CCMS)',
      },
      {
        accessor: 'some4',
        Header: '발행 완료 유무(N/Y)',
      },
      {
        accessor: 'some5',
        Header: '발행 일자',
      },
      {
        accessor: 'some6',
        Header: '계약일',
      },
      {
        accessor: 'some7',
        Header: '완납여부',
      },
    ];
  }, []);

  const columnDataReqHist = useMemo(
    () => [
      columnHelper.accessor('no', {
        id: 'no',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>No</span>,
      }),
      columnHelper.accessor('vendorName', {
        id: 'vendorName',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사</span>,
      }),
      columnHelper.accessor('couponUseRequestDate', {
        id: 'couponUseRequestDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>요청일</span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품 아이디</span>,
      }),
      columnHelper.accessor('merchandiseDesc', {
        id: 'merchandiseDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('couponNo', {
        id: 'couponNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>난수번호</span>,
      }),
      columnHelper.accessor('validDateFrom', {
        id: 'validDateFrom',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            쿠폰시작유효기간
            <br />
            {`(CNP_START_DATE)`}
          </span>
        ),
      }),
      columnHelper.accessor('validDateTo', {
        id: 'validDateTo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => (
          <span>
            쿠폰종료유효기간
            <br />
            {`(CNP_END_DATE)`}
          </span>
        ),
      }),
    ],
    []
  );

  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };

  // const isAllInputComplete = useCallback(() => {
  //   return (
  //     firstNumber.length >= 4 &&
  //     secondNumber.length >= 4 &&
  //     thirdNumber.length >= 4 &&
  //     lastNumber.length >= 4
  //   );
  // }, [firstNumber, secondNumber, thirdNumber, lastNumber]);

  const handleAddCoupon = useCallback(() => {
    // if (isAllInputComplete()) {
    // const newCoupon = `${firstNumber}-${secondNumber}-${thirdNumber}-${lastNumber}`;
    setRandomNumber((prevList) => [...prevList, randomNumberRef.current.value]);
    // 쿠폰 추가 후 입력 값 초기화
    // setFirstNumber('');
    // setSecondNumber('');
    // setThirdNumber('');
    // setLastNumber('');
    // }
  }, []);

  const handleUploadData = useCallback(
    (newData) => {
      setRandomNumber((prev) => [...prev, ...newData[0]]);
    },
    [randomNumber]
  );
  const deleteRandomNumber = useCallback(
    (index) => {
      randomNumber.splice(index, 1);
      setRandomNumber([...randomNumber]);
    },
    [randomNumber]
  );

  useEffect(() => {
    if (willCompleteTargetCouponList.length > 0) {
      setCouponList(willCompleteTargetCouponList);
    }
  }, [willCompleteTargetCouponList]);

  useEffect(() => {
    if (userData.vendorKey) {
      const tempParams = {
        vendorKey: userData.vendorKey,
        limit: '100',
        page: '1',
      };
      getMerchandiseCouponList(dispatch, tempParams);
    }
  }, [userData]);

  const couponTableRow = useMemo(() => {
    let result = [];
    if (Array.isArray(couponList) && couponList.length > 0) {
      result = couponList.map((item) => {
        return (
          <tr key={item.merchandiseId}>
            <td>
              <div className={cn('input_radio', 'type02')}>
                {item.salesStatusDesc === '사용' && (
                  <RadioButton
                    key={item.merchandiseId}
                    id={item.merchandiseId}
                    value={selectMerchandiseId}
                    checked={selectMerchandiseId === item.merchandiseId}
                    onChange={() => handlePostRadio(item.merchandiseId)}
                  />
                )}
              </div>
            </td>
            <td>{item.focFlagDesc}</td>
            <td>{item.merchandiseId}</td>
            <td>{item.merchandiseDesc}</td>
            <td>{item.salesStatusDesc}</td>
            <td>{item.validDateFrom}</td>
            <td>{item.validDateTo}</td>
            <td>{item.sellPrice}</td>
          </tr>
        );
      });
    }

    return result;
  }, [couponList, selectMerchandiseId]);

  const couponDelBtn = useMemo(() => {
    let result = null;
    //TODO: 선택된 상품아이디에 할당된 난수 번호 중 사용 완료처리할 리스트가 나와야함
    result = randomNumber.map((coupon, index) => (
      <div className={css.item} key={index}>
        {coupon}
        <button
          type='button'
          className={css.delete}
          onClick={() => deleteRandomNumber(index)}
        >
          삭제
        </button>
      </div>
    ));

    return result;
  }, [randomNumber]);

  const handleSubmit = useCallback(() => {
    const params = {
      merchandiseId: selectMerchandiseId,
      updateTargetList: JSON.stringify(randomNumber),
      userId: userData.userId,
    };
    postCouponUseComplete(dispatch, params);
    setRandomNumber([]);
  }, [selectMerchandiseId, userData, randomNumber]);

  const removeDuplicatesAndCount = useCallback((arr) => {
    //중복 필터링 로직
    const map = {};
    const countMap = {};

    // 중복 요소를 추적하고 각 요소의 출현 횟수 기록
    arr.forEach((element, index) => {
      if (!(element in map)) {
        map[element] = index; // 각 요소의 첫 번째 등장 인덱스를 기록
        countMap[element] = 1; // 요소의 출현 횟수를 1로 초기화
      } else {
        countMap[element]++; // 중복 요소의 출현 횟수를 증가
      }
    });

    // 중복 요소 중 가장 낮은 인덱스의 값을 제외하고 나머지 중복 요소를 제거
    const result = arr.filter((element, index) => {
      return map[element] === index;
    });

    return { filteredArray: result, countMap };
  }, []);

  const removeDuplicatedItem = useCallback(() => {
    //useEffect 에서 중복제거된 상태를 반영
    setRandomNumber(filteredRandomNumberArray);
  }, [filteredRandomNumberArray]);

  const vendorReqHistItems = useMemo(() => {
    const target = couponUseApprovalStatus;
    let filteredItems = [];
    if (target) {
      filteredItems = target.map((filteredItem, index) => {
        return Object.assign({}, filteredItem, {
          no: target.length - index,
        });
      });
    }
    return filteredItems;
  }, [couponUseApprovalStatus]);

  const confirmCallback = useCallback(() => {
    handleSubmit();
    setIsOpenConfirmPopUp(true);
  }, [handleSubmit]);

  useEffect(() => {
    //중복제거된 리스트와 카운트 반환
    const { filteredArray, countMap } = removeDuplicatesAndCount(randomNumber);
    const countValues = Object.values(countMap);
    const duplicatedCount = countValues.reduce((acc, curr) => {
      if (curr > 1) {
        return acc + curr;
      }
      return acc;
    }, 0);
    setFilteredRandomNumberArray(filteredArray);
    setDuplicatedCount(duplicatedCount);
  }, [randomNumber]);

  useEffect(() => {
    const tempParams = {
      vendorKey: userData.vendorKey,
      authority: userData.authority,
      limit: '100',
      page: '1',
    };
    getCouponUseApprovalStatusList(dispatch, tempParams);

    const params = {
      vendorKey: userData.vendorKey,
    };
    getCouponUseCompletedStatusDashboardAPI(dispatch, params);
  }, [userData, dispatch]);
  const handleOpenGuide = () => {
    const serverType = backendMode();
    const url = getRandomNumTemplate(serverType);

    window.open(url, '_blank');
  };

  const handleFileChange = useCallback(
    (event) => {
      const fileInput = event.target;
      const file = fileInput.files[0];

      if (file) {
        const fileName = file.name;
        const reader = new FileReader();

        reader.onload = function (event) {
          let result = [];
          let contents = event.target.result;
          contents = contents.replace(/\r/g, ',');
          let elements = contents.split(',');
          for (let i = 0; i < elements.length; i++) {
            elements[i] = elements[i].replace(/\n/g, '');
            elements[i] = elements[i].replace(/\\n/g, '');
          }
          if (Array.isArray(elements) && elements.length > 0) {
            result = elements.filter((el) => el !== '' && el !== ' ');
          }
          setUploadedContentCSV(result);
          const addedCSVData = [...randomNumber, ...result];
          setRandomNumber(addedCSVData);
        };

        reader.readAsText(file);
        setSelectedFile(fileName);
      }
    },
    [randomNumber]
  );

  return (
    <>
      <h2 className={css.pageTitle}>사용 완료 요청</h2>
      <div className={css.status}>
        <dl>
          <dt>당월 사용 쿠폰 등록 수</dt>
          <dd>
            <div className={css.value}>
              <span className={css.num}>
                {couponUseCompletedStatusDashboard.couponCreatedCnt}
              </span>
              건
            </div>
            <p className={css.standard}>* 접속 월 기준</p>
          </dd>
        </dl>
        <dl>
          <dt>사용 완료 확정 요청</dt>
          <dd>
            <div className={css.value}>
              {/* <span className={css.act}></span> */}
              <span className={css.num}>
                {
                  couponUseCompletedStatusDashboard.couponUseCompleteRequestedCnt
                }
              </span>
              건
            </div>
            <ul className={css.detail}>
              {/* <button onClick={handlePopUpClick}>
                실패 내역 조회<i className={css.ico_arr}></i>
              </button> */}

              <PopUp
                size='mideum'
                isOpenPopUp={isOpenPopUp}
                onRequestClose={() => setIsOpenPopUp(false)}
              >
                <div>
                  총 <b>1-1건</b>의 쿠폰 발행이 실패 하였습니다.
                </div>
                <Table
                  columns={columnData}
                  data={FAIL_MOCK_DATA}
                  hasPagination={false}
                  hasPageSize={false}
                />
                <div className={css.excel}>
                  <CsvDownload
                    data={FAIL_MOCK_DATA}
                    title='내보내기'
                  ></CsvDownload>
                </div>
              </PopUp>
            </ul>
            <p className={css.standard}>* 접속 월 기준</p>
          </dd>
        </dl>
        <dl>
          <dt>당월 사용 완료 쿠폰</dt>
          <dd>
            <ul className={css.valueWrap}>
              <li className={css.value}>
                요청{' '}
                <span className={css.num}>
                  {
                    couponUseCompletedStatusDashboard.couponUseCompleteRequestedCnt
                  }
                </span>
                건
              </li>
              <li className={cn('value', 'confirm')}>
                확정{' '}
                <span className={css.num}>
                  {
                    couponUseCompletedStatusDashboard.couponUseCompleteApprovedCnt
                  }
                </span>
                건
              </li>
            </ul>
            <p className={css.standard}>* 접속 월 기준</p>
          </dd>
        </dl>
      </div>
      <div className={cn('stepsFlow', 'mt32')}>
        <div className={cn('steps', 'on')}>
          쿠폰 생성 등록<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 발행<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 사용 등록<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 사용 확정 요청<span className={css.badge}>진행중</span>
        </div>
      </div>
      <div className={css.stepGuideText}>
        ① 사용완료 처리할 상품 아이디를 선택해주세요.
      </div>
      <div className={css.dataTable01}>
        <table>
          <thead>
            <tr>
              <th>구분</th>
              <th>정산</th>
              <th>상품 ID</th>
              <th>상품</th>
              <th>판매 상태</th>
              <th>판매 시작일</th>
              <th>판매 종료일</th>
              <th>판매 가격</th>
            </tr>
          </thead>
          <tbody>{couponTableRow}</tbody>
        </table>
      </div>
      <div className={cn('infoList', 'mt20')}>
        <div className={css.stepGuideText}>
          ②
          {Array.isArray(currentCouponItem) && currentCouponItem.length > 0 && (
            <>
              <strong>
                {currentCouponItem[0].merchandiseId}
                {` `}
                {currentCouponItem[0].merchandiseDesc}
              </strong>
              에서 사용완료 처리할 난수번호를 입력해주세요.
              <br />
            </>
          )}
          ※주의사항: 사용된 제휴사 업체별 앱 웹 사이트에서 사용 등록된 쿠폰을
          시스템에 등록해주세요.
          <br />
          등록 된 쿠폰은 정산 기초 자료로 활용되오며 별도 검증 작업을 진행할
          예정입니다. 확인 된 쿠폰 번호만 입력해주세요. (오 등록 시 업체 정산
          오류 발생)
        </div>
      </div>
      <div className={css.addCoupon}>
        <div className={css.box_random}>
          <div className={css.flex}>
            <div className={css.input_text}>
              <input ref={randomNumberRef} type='text' name='' id='' />
            </div>
            <button
              type='button'
              className={cn('btn', 'btn01', 'type03', 'btn_add')}
              onClick={() => handleAddCoupon()}
            >
              추가
            </button>
          </div>
        </div>
        <div className={cn('box', 'list')}>
          <div className={css.btn_container7}>
            {couponList.length > 0 && (
              <>
                <button
                  type='button'
                  className={cn('btn', 'btn02', 'type01')}
                  onClick={() => removeDuplicatedItem()}
                >
                  중복제거
                </button>
                {/* <CsvDownload
                  type='button'
                  title='내보내기'
                  className={cn('btn', 'btn02', 'type01')}
                  data={couponList.map((coupon) => [coupon])}
                /> */}
              </>
            )}
          </div>
          <div className={css.totalCount}>
            총 <b>{randomNumber.length}</b>건 (중복 {duplicatedCount}건)
          </div>
          <div className={css.addCouponList}>{couponDelBtn}</div>
          <div className={cn('btn_container7', 'mt12')}>
            <button
              type='button'
              className={cn('btn', 'btn02', 'type02')}
              onClick={() => handleOpenGuide()}
            >
              <i className={css.ico_excel}></i>양식 다운로드
            </button>
            <div className={css.attatchedFile}>
              <label
                htmlFor='file-input'
                className={cn('btn', 'btn02', 'type01', 'pointer')}
              >
                일괄 업로드
              </label>
              <input
                id='file-input'
                type='file'
                className={cn('btn', 'btn02', 'type01')}
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e)}
              />
              <div className={css.fileName}>
                {selectedFile}
                {/* {selectedFile && (
                <button className={css.delete} onClick={handleCancelClick}>
                  삭제
                </button>
              )} */}
              </div>
            </div>
            {/* <CsvUpload
              title='일괄업로드'
              type='button'
              handleUploadData={handleUploadData}
              className={cn('btn', 'btn02', 'type01', 'dowonload')}
            /> */}
          </div>
        </div>
      </div>
      <div className={cn(css.stepGuideText, css.spaceBtw)}>
        ③ 등록한 쿠폰의 중복 제거 및 저장 완료 후 완료 버튼을 선택해주세요.
        <span>
          <button
            type='button'
            className={cn('btn', 'btn01', 'type03')}
            onClick={() => confirmCallback()}
          >
            <div>사용완료 요청</div>
          </button>
        </span>
      </div>
      <div className={css.stepGuideText}>
        ④ 사용완료 요청한 쿠폰을 확인해주세요(조회 기간: 최대 3개월).
      </div>
      {vendorReqHistItems.length > 0 ? (
        <TableV8
          columns={columnDataReqHist}
          data={vendorReqHistItems}
          hasCsvDownload={false}
        />
      ) : (
        <div>3개월 이내 사용완료 요청한 쿠폰이 없습니다.</div>
      )}
      <PopUpAlert
        title={'사용완료 요청'}
        onRequestClose={() => setIsOpenConfirmPopUp(false)}
        isOpenPopUp={isOpenConfirmPopUp}
        closeButton={false}
        button={[
          <Button
            key='firstButton'
            subType={'type02'}
            onClick={() => {
              setIsOpenConfirmPopUp(false);
              const tempParams = {
                vendorKey: userData.vendorKey,
                authority: userData.authority,
                limit: '100',
                page: '1',
              };
              getCouponUseApprovalStatusList(dispatch, tempParams);
              const params = {
                vendorKey: userData.vendorKey,
              };
              getCouponUseCompletedStatusDashboardAPI(dispatch, params);
            }}
          >
            확인
          </Button>,
        ]}
      >{`완료되었습니다.`}</PopUpAlert>
    </>
  );
};

export default CouponUsedVendor;
