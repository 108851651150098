import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTER_PATH } from '@router/routerPath';
import { useSelector } from 'react-redux';

/**
 * @category components
 * @namespace ProtectedRoute
 * @param {string} children ProtectedRoute 의 하위 컴포넌트.
 * @description 로그인 되지 않은 상태에서 페이지 진입 방지하기 위한 컴포넌트.
 */

const ProtectedRoute = ({ children }) => {
  const isLogined = useSelector((state) => state.user.userData.isLogined);

  return (
    <>
      {isLogined ? (
        <Fragment>{children}</Fragment>
      ) : (
        <Navigate to={ROUTER_PATH.ROOT}></Navigate>
      )}
    </>
  );
};

export default ProtectedRoute;
