import React from 'react';
import { CSVLink } from 'react-csv';
import { memo } from 'react';
import css from './CsvDownload.module.scss';
import classNames from 'classnames/bind';

/** 여러개의 className을 사용하기 위한 속성. */
const cn = classNames.bind(css);

/**
 * @category components
 * @namespace CsvDownload
 * @param {Array} data Table 모든 rows
 * @param {Array} selectedRows 선택된(체크박스) rows
 * @param {string} filterKey filter하기 위한 키값(default: id)
 * @param {string} title button안에 들어갈 title
 */

const CsvDownload = ({ data, selectedRows, filterKey, title }) => {
  return (
    <CSVLink
      data={selectedRows?.length === 0 ? data : selectedRows}
      filename={'excel.csv'}
    >
      <div className={css.mb20}>
        <div type='button' className={cn('btn02', 'type02')}>
          <i className={css.ico_excel}></i>
          <span className={css.title}> {title}</span>
        </div>
      </div>
    </CSVLink>
  );
};

CsvDownload.defaultProps = {
  title: '검색 결과 다운로드',
  data: null,
  selectedRows: [],
  filterKey: 'id',
};

export default memo(CsvDownload);
