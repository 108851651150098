import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ROUTER_PATH } from '@router/routerPath';
import {
  BreadCrumbs,
  Calendar,
  PopUp,
  TableV8,
  TextArea,
  Search,
  SelectBox,
} from '@components/index';
import css from './ProductListModifyPopup.module.scss';
import classNames from 'classnames/bind';
import { RadioButton, VerticalTable, Input, Button } from '@components/index';
import { useRadio, useInput, useSelect } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModelSuffixList,
  postSaveProductTempNewAndSaved,
  postSaveProductTemp,
  postRequestProductApproval,
  postDoApprovalOrRejected,
} from 'redux/action/productAction';
import { createColumnHelper } from '@tanstack/react-table';
import { convertDateToString2 } from 'utils/utils';
import ProductListModifySearchArea from './ProductListModifySearchArea';
import { hasSearchText } from '@components/Search/utils/searchValidationFunctions';
import { FormProvider, useForm } from 'react-hook-form';
import { openModalAlert } from '@feature/common/commonSlice';
import HFSelectBox from '@components/_ReactHookForm/HFSelectBox';

const cn = classNames.bind(css);

const columnHelper = createColumnHelper();

//Radio Box
const RADIO_FREE_OR_PAID = [
  { id: 0, title: '무상' },
  { id: 1, title: '유상' },
];

//Select Box
const selectSearchOptions = {
  guide: '',
  list: [
    { id: 'modelSuffix', title: 'model.suffix' },
    { id: 'focTypeDesc', title: '정산 구분' },
    { id: 'modelSuffixDesc', title: '상품명' },
  ],
};

const ProductListModifyPopup = ({ prevProductInfo, handleModifyPopup }) => {
  const userId = useSelector((state) => state.user.userData.userId);
  const level = useSelector((state) => state.user.userData.authority);
  const modelSuffixList = useSelector((state) => state.product.modelSuffixList);
  const dispatch = useDispatch();
  const searchForms = useForm();

  const initialFormData = {
    merchandiseKey: prevProductInfo?.merchandiseKey,
    userId: prevProductInfo?.recentModifiedUserId,
    modelSuffix: prevProductInfo?.modelSuffix,
    vendorKey: prevProductInfo?.vendorKey,
    vendorDesc: prevProductInfo?.vendorName,
    focFlag: prevProductInfo?.focFlag,
    focTypeDesc: prevProductInfo?.focTypeDesc,
    category: prevProductInfo?.category,
    categoryDesc: prevProductInfo?.categoryDesc,
    merchandiseId: prevProductInfo?.merchandiseId,
    merchandiseDesc: prevProductInfo?.merchandiseDesc,
    // validDateFrom: prevProductInfo?.validDateFrom,
    // validDateTo: prevProductInfo?.validDateTo,
    //판매가
    sellPrice: prevProductInfo?.sellPrice,
    //공급가
    netPrice: prevProductInfo?.netPrice,
    //마진가
    profitMargin: prevProductInfo?.profitMargin,
    description: prevProductInfo?.description,
    approvalStatus: '',
    approvalComment: '',
    validPeriod: prevProductInfo?.validPeriod,
  };

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      couponExpirationCondition: '',
    },
  });

  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  // const [startDate, setStartDate] = useState(
  //   new Date(initialFormData.validDateFrom)
  // );
  // const [endDate, setEndDate] = useState(new Date(initialFormData.validDateTo));
  const [selectFreeOrPaidRadioValue, setFreeOrPaidRadioValue] =
    useRadio('무상');

  const [sellPrice, setSellPrice, onChangeSellPrice] = useInput(
    prevProductInfo?.sellPrice,
    false
  );
  const [netPrice, setNetPrice, onChangeNetPrice] = useInput(
    prevProductInfo?.netPrice,
    false
  );
  const [profitMargin, setProfitMargin] = useState();
  const [description, setDescription, onChangeDescription] = useInput(
    prevProductInfo?.description
  );

  const [validPeriod, setValidPeriod, onChangeValidPeriod] = useInput(
    prevProductInfo?.validPeriod
  );

  const [approvalComment, setApprovalComment, onChangeApprovalComment] =
    useInput('');
  const [decisionCompleted, setDecisionCompleted] = useState(false);
  const [approvalCompleted, setApprovalCompleted] = useState(true);
  const [saveCompleted, setSaveCompleted] = useState(false);

  const [formData, setFormData] = useState(initialFormData);
  const [filtered, setFiltered] = useState(modelSuffixList);

  const onSearchClick = useCallback(
    (data) => {
      const { selectSearchCondition, keyword } = data;

      const filteredList = modelSuffixList.filter((table) => {
        return hasSearchText({
          targetValue: table[selectSearchCondition?.id],
          searchText: keyword,
        });
      });
      setFiltered(filteredList);
    },
    [modelSuffixList]
  );

  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };

  const onClickModelSuffix = (suffixInfo) => {
    const newFormData = { ...formData };

    Object.keys(suffixInfo).forEach((key) => {
      if (
        key === 'validDateFrom' ||
        key === 'validDateTo' ||
        key === 'vendorDesc' ||
        key === 'vendorKey' ||
        key === 'approvalComment' ||
        key === 'approvalStatus'
      ) {
        return;
      } else if (key === 'modelSuffixDesc') {
        newFormData['merchandiseDesc'] = suffixInfo['modelSuffixDesc'];
      } else if (key === 'focType') {
        newFormData['focFlag'] = suffixInfo['focType'];
      } else {
        newFormData[key] = suffixInfo[key];
      }
    });
    setFormData(newFormData);
    setFreeOrPaidRadioValue(newFormData.focTypeDesc);

    setIsOpenPopUp(false);
  };

  useEffect(() => {
    const params = {
      searchType: 'FOC / MODEL / DESC',
      detailCondition: '',
    };
    getModelSuffixList(dispatch, params);
  }, []);

  useEffect(() => {
    setProfitMargin(sellPrice - netPrice);
    setFormData({
      ...formData,
      sellPrice: sellPrice,
      netPrice: netPrice,
      profitMargin: sellPrice - netPrice,
      description: description,
    });
  }, [sellPrice, netPrice, description]);

  useEffect(() => {
    setFormData({
      ...formData,
      approvalComment: approvalComment,
    });
  }, [approvalComment]);

  // 날짜 선택 이벤트 핸들러
  // const handleStartDate = (date) => {
  //   if (date <= endDate) {
  //     setStartDate(date);
  //     setFormData({
  //       ...formData,
  //       validDateFrom: convertDateToString2(date),
  //     });
  //   } else {
  //     alert('시작 날짜는 종료 날짜보다 이전이어야 합니다.');
  //   }
  // };

  // const handleEndDate = (date) => {
  //   if (date >= startDate) {
  //     setEndDate(date);
  //     setFormData({
  //       ...formData,
  //       validDateTo: convertDateToString2(date),
  //     });
  //   } else {
  //     alert('종료 날짜는 시작 날짜보다 이후이어야 합니다.');
  //   }
  // };

  const handleModelSuffix = () => {
    const params = {
      searchType: 'FOC / MODEL / DESC',
      detailCondition: '',
    };
    getModelSuffixList(dispatch, params);
    setFiltered(modelSuffixList);
    handlePopUpClick();
  };

  const onClickCancel = () => {
    setFormData(initialFormData);
    setSellPrice('');
    setNetPrice('');
    setProfitMargin('');
    setDescription('');
  };

  console.log('jh formData', formData);

  const onClickTempSave = useCallback((data) => {
    const { couponExpirationCondition } = data;

    const param = {
      modelSuffix: formData.modelSuffix,
      focFlag: formData.focFlag,
      category: formData.category,
      merchandiseDesc: formData.merchandiseDesc,
      priceAppliedDate: convertDateToString2(new Date()),
      validPeriod: formData.validPeriod,
      vendorKey: formData.vendorKey,
      description: formData.description,
      sellPrice: formData.sellPrice,
      netPrice: formData.netPrice,
      profitMargin: formData.profitMargin,
      userId: formData.userId,
    };

    if (!description || sellPrice === '' || netPrice === '') {
      dispatch(openModalAlert({ message: '필수값을 입력하세요.' }));
    } else {
      if (prevProductInfo?.approvalStatus === 'SAVED') {
        postSaveProductTempNewAndSaved(dispatch, param, setSaveCompleted);
      } else {
        postSaveProductTemp(dispatch, param, setSaveCompleted);
      }
    }
  }, []);

  // FIXME: HFSelectBox 삭제 시 제거
  const onFailTempSave = useCallback((data) => {
    const { couponExpirationCondition } = data;
    if (couponExpirationCondition.type === 'required') {
      dispatch(openModalAlert({ message: '필수값을 입력하세요.' }));
    }
  }, []);

  const onClickRequestApproval = () => {
    postRequestProductApproval(
      dispatch,
      {
        merchandiseKey: prevProductInfo?.merchandiseKey,
        userId: userId,
      },
      setApprovalCompleted
    );
    handleModifyPopup();
  };

  // FIXME: param data 확인용
  // console.log('jh formData', formData);

  const onClickDecision = useCallback(
    ({ data, decision }) => {
      const { couponExpirationCondition } = data;

      const param = {
        merchandiseKey: formData.merchandiseKey,
        approvalStatus: decision,
        approvalComment: formData.approvalComment,
        priceAppliedDate: convertDateToString2(new Date()),
        validPeriod: formData.validPeriod,
        description: formData.description,
        sellPrice: formData.sellPrice,
        netPrice: formData.netPrice,
        profitMargin: formData.profitMargin,
        userId: formData.userId,
        category: formData.category,
      };

      const updatedFormData = {
        ...formData,
        approvalStatus: decision,
      };

      if (decision === 'REJECTED') {
        if (!formData.approvalComment) {
          dispatch(openModalAlert({ message: '필수값을 입력하세요.' }));
        } else {
          postDoApprovalOrRejected(
            dispatch,
            param,
            decision,
            setDecisionCompleted
          );
          handleModifyPopup();
        }
      } else {
        postDoApprovalOrRejected(
          dispatch,
          param,
          decision,
          setDecisionCompleted
        );
        handleModifyPopup();
      }
    },
    [formData]
  );

  const columnData = useMemo(() => {
    return [
      columnHelper.accessor('focTypeDesc', {
        id: 'focTypeDesc',
        cell: (info) => info.getValue(),
        header: () => <span>정산 구분</span>,
      }),
      columnHelper.accessor('modelSuffixDesc', {
        id: 'modelSuffixDesc',
        cell: (info) => {
          return (
            <>
              <button
                onClick={() => {
                  onClickModelSuffix(info.row.original);
                }}
              >
                {info.getValue()}
              </button>
            </>
          );
        },
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('modelSuffix', {
        id: 'modelSuffix',
        cell: (info) => {
          return (
            <>
              <button
                onClick={() => {
                  onClickModelSuffix(info.row.original);
                }}
              >
                {info.getValue()}
              </button>
            </>
          );
        },
        header: () => <span>model.suffix</span>,
      }),
      columnHelper.accessor('merchandiseId', {
        id: 'merchandiseId',
        cell: (info) => info.getValue(),
        header: () => <span>등록 아이디</span>,
      }),
      // columnHelper.accessor('combinedSalesPeriod', {
      //   id: 'validDateFrom',
      //   cell: (info) => {
      //     const validDateFrom = info.row.original.validDateFrom;
      //     const validDateTo = info.row.original.validDateTo;
      //     return <span>{`${validDateFrom} ~ ${validDateTo}`}</span>;
      //   },
      //   header: () => <span>판매 기간</span>,
      // }),
    ];
  }, [modelSuffixList]);

  const couponExpirationOptions = useMemo(() => {
    const options = {
      guide: '유효기간(월)을 선택해주세요.',
      list: Array.from({ length: 60 }, (_, index) => ({
        id: index + 1,
        title: `${index + 1}개월`,
      })),
    };

    return options;
  }, []);

  // 저장완료(SAVED) => 수정 가능
  // 반려(REJECTED),승인대기(UNDER_APPROVAL), 승인완료(APPROVED) => 수정 불가능
  const isSaved = () => {
    return prevProductInfo?.approvalStatus === 'SAVED';
  };

  // LEVEL1,LEVEL2인지 여부 => LGE는 임시저장,승인요청 가능 *예외 :vendorkey 0001 일경우 LGE도 승인/반려 가능
  const isLgeUser = () => {
    return level === 'LEVEL_1' || level === 'LEVEL_2';
  };

  const isLgeAdmin = () => {
    return level === 'LEVEL_1';
  };

  // 승인대기/승인/반려인경우 수정하지 못하도록 함
  const isNoEdit = () => {
    return (
      prevProductInfo?.approvalStatus === 'UNDER_APPROVAL' ||
      prevProductInfo?.approvalStatus === 'APPROVED' ||
      prevProductInfo?.approvalStatus === 'REJECTED'
    );
  };

  const isVendorUnderApproval = () => {
    return !isLgeUser() && prevProductInfo?.approvalStatus === 'UNDER_APPROVAL';
  };

  const isLgeProductUnderApproval = () => {
    return isLgeAdmin() && prevProductInfo?.approvalStatus === 'UNDER_APPROVAL';
  };

  return (
    <>
      <h2 className={css.pageTitle}>등록상품 수정</h2>

      <div className={cn('input_table_wrap', 'first')}>
        <h4 className={css.areaTitle}>CSMS 정보</h4>
        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable
            className={cn('color-red')}
            hasTitleMinWidth
            title={['CSMS 매칭']}
          >
            <div className={cn('inputWrap', 'type03')}>
              <button
                onClick={handleModelSuffix}
                type='button'
                className={cn('btn', 'btn01', 'type02')}
              >
                model.suffix 정보 조회
              </button>
              <div className={css.input_text}>
                <Input type='text' value={formData.modelSuffix} disabled />
              </div>
            </div>
          </VerticalTable>
        </div>
        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['제휴사명']}>
            <Input type='text' value={formData.vendorDesc} disabled />
          </VerticalTable>
        </div>

        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['정산']}>
            <div className={cn('selectbox', 'type01', 'sizeM')}>
              <div className={css.inputCheckWrap}>
                {RADIO_FREE_OR_PAID &&
                  RADIO_FREE_OR_PAID?.map((item) => (
                    <RadioButton
                      key={item.id}
                      id={item.id}
                      value={selectFreeOrPaidRadioValue}
                      checked={selectFreeOrPaidRadioValue === item.title}
                      onClick={() => {
                        return false;
                      }}
                      disabled={item.disabled}
                    >
                      <>{item.title}</>
                    </RadioButton>
                  ))}
              </div>
            </div>
          </VerticalTable>
        </div>

        <div className={css.input_table}>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['구분']}>
              <div className={cn('selectbox', 'type01', 'sizeM')}>
                <Input
                  type='text'
                  fullWidth
                  value={formData.categoryDesc}
                  disabled
                />
              </div>
            </VerticalTable>
          </div>
          <div className={cn('inputWrap')}>
            <VerticalTable hasTitleMinWidth title={['상품명']}>
              <div className={cn('width578')}>
                <Input
                  type='text'
                  fullWidth
                  value={formData.merchandiseDesc}
                  disabled
                />
              </div>
            </VerticalTable>
          </div>
        </div>
      </div>

      <div className={css.input_table_wrap}>
        <h4 className={css.areaTitle}>상품 정보</h4>
        <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['상품아이디']}>
            <Input type='text' name={'memo'} placeholder='자동 발번' disabled />
          </VerticalTable>
        </div>
        {formData.categoryDesc === '쿠폰' && (
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['쿠폰 유효기간']}>
              <div className={cn('selectbox', 'type01', 'sizeM')}>
                <Input
                  type='text'
                  fullWidth
                  value={formData.validPeriod}
                  disabled
                />
              </div>
            </VerticalTable>
          </div>
          // <div className={cn('inputWrap', 'type03')}>
          //   <VerticalTable hasTitleMinWidth title={['쿠폰 유효기간']} required>
          //     <div className={cn('selectbox', 'type01', 'sizeM')}>
          //       <HFSelectBox
          //         control={control}
          //         name={'couponExpirationCondition'}
          //         options={couponExpirationOptions}
          //         hasScroll
          //         required={formData.categoryDesc === '쿠폰'}
          //       />
          //     </div>
          //   </VerticalTable>
          // </div>
        )}
        {/* <div className={cn('inputWrap', 'type03')}>
          <VerticalTable hasTitleMinWidth title={['판매 기간']}>
            <div className={cn('selectbox', 'type01', 'sizeM')}>
              <div className={css.inputCheckWrap}>
                <Calendar
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  hasTitle={false}
                  hasPeriodInput={false}
                  minDate={new Date()}
                  maxDate={null}
                  disabled={isNoEdit()}
                />
              </div>
            </div>
          </VerticalTable>
        </div> */}

        <div className={cn('verticalTable_container', 'ml5')}>
          <VerticalTable hasTitleMinWidth title={['판매가']} required>
            <Input
              type='number'
              value={sellPrice}
              onChange={onChangeSellPrice}
              disabled={isNoEdit()}
            />
          </VerticalTable>
          <VerticalTable title={['공급가']} className={css.marginL30} required>
            <Input
              type='number'
              value={netPrice}
              onChange={onChangeNetPrice}
              disabled={isNoEdit()}
            />
          </VerticalTable>
          <VerticalTable title={['마진가']} className={css.marginL30}>
            <Input
              type='number'
              disabled
              placeholder='자동 계산'
              value={profitMargin}
            />
          </VerticalTable>
        </div>
      </div>

      <div className={cn('input_table_wrap', 'last')}>
        <div className={css.input_table}>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['비고']} required>
              <div className={css.textAreaWidth}>
                <TextArea
                  hasByte
                  maxByte={200}
                  value={description}
                  name={'description'}
                  onChange={onChangeDescription}
                  placeholder={'배송/쿠폰혜택 사용 가능 사이트명 표기'}
                  row={4}
                  disabled={isNoEdit()}
                />
              </div>
            </VerticalTable>
          </div>

          {(isVendorUnderApproval() || isLgeProductUnderApproval()) && (
            <div className={cn('inputWrap', 'type03')}>
              <VerticalTable hasTitleMinWidth title={['반려 사유']} required>
                <div className={css.textAreaWidth}>
                  <TextArea
                    hasByte
                    maxByte={200}
                    value={approvalComment}
                    name={'approvalComment'}
                    onChange={onChangeApprovalComment}
                    placeholder={'반려 사유를 입력해주세요'}
                    row={4}
                  />
                </div>
              </VerticalTable>
            </div>
          )}
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['발행(수정)자']}>
              <span>{formData.userId}</span>
            </VerticalTable>
          </div>
          <div className={cn('inputWrap', 'type03')}>
            <VerticalTable hasTitleMinWidth title={['최근 수정일시']}>
              <span>{prevProductInfo?.recentModifiedDate}</span>
            </VerticalTable>
          </div>
        </div>
      </div>

      <div className={cn('btn_container6', 'mt32')}>
        {isLgeUser() && isSaved() && (
          <>
            <div className={css.btn}>
              <Button subType={'type03'} fullWidth onClick={onClickCancel}>
                취소
              </Button>
            </div>
            <>
              <div className={css.btn}>
                <Button
                  subType={'type02'}
                  fullWidth
                  onClick={handleSubmit(onClickTempSave, onFailTempSave)}
                  disabled={saveCompleted || !approvalCompleted}
                >
                  임시 저장
                </Button>
              </div>
              <div className={css.btn}>
                <Button
                  subType={'type02'}
                  fullWidth
                  onClick={onClickRequestApproval}
                  disabled={!approvalCompleted}
                >
                  승인 요청
                </Button>
              </div>
            </>
          </>
        )}
        {(isVendorUnderApproval() || isLgeProductUnderApproval()) && (
          <>
            <div className={css.btn}>
              <Button
                subType={'type02'}
                fullWidth
                onClick={handleSubmit((data) =>
                  onClickDecision({ data, decision: 'REJECTED' })
                )}
                disabled={decisionCompleted}
              >
                반려
              </Button>
            </div>
            <div className={css.btn}>
              <Button
                subType={'type02'}
                fullWidth
                onClick={handleSubmit((data) =>
                  onClickDecision({ data, decision: 'APPROVED' })
                )}
                disabled={decisionCompleted}
              >
                승인
              </Button>
            </div>
          </>
        )}
      </div>

      <PopUp
        isOpenPopUp={isOpenPopUp}
        onRequestClose={() => setIsOpenPopUp(false)}
        isWideSize
      >
        <strong className={css.popup_title}>CSMS model.suffix 선택</strong>
        <TableV8
          data={filtered}
          columns={columnData}
          hasCsvDownload={false}
          rightControls={
            <FormProvider {...searchForms}>
              <ProductListModifySearchArea
                selectSearchOptions={selectSearchOptions}
                onSearchClick={onSearchClick}
              />
            </FormProvider>
          }
        />
      </PopUp>
    </>
  );
};

export default ProductListModifyPopup;
