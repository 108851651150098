import React from 'react';
import emailjs from 'emailjs-com';
import { createBrowserHistory } from 'history';

// import { JSEncrypt } from "jsencrypt";
// forceRefresh 라우터 이동 후 url만 바뀌고 새로 고침 되지 않는 문제 해결 위한 옵션
export const history = createBrowserHistory();

const fillZero = (str, length) => {
  return ('00000000' + str).slice(-length);
};

/** 줄바꿈 함수 (문자열 사이에 \n 추가해서 사용) */
export const convertNewlinesToBr = (text) => {
  if (text) {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== text.length - 1 && <br />} {/* 마지막 줄 다음 <br> 추가 x */}
      </React.Fragment>
    ));
  } else {
    return null;
  }
};

// n자리 난수 번호 생성
export const generateRandomNum = (n) => {
  let str = '';
  for (let i = 0; i < n; i++) {
    str += Math.floor(Math.random() * 10);
  }
  return str;
};

// 이메일 전송 기능(인증 번호)
export const sendEmailAuthentication = (
  from_name,
  user_email, // 받는 사람 이메일
  to_name, // 받는 사람 이름
  certification_number, // 인증 번호
  message // 메세지
) => {
  const emailParams = {
    from_name: from_name,
    user_email: user_email,
    to_name: to_name,
    certification_number: certification_number,
    message: message,
  };

  emailjs
    .send(
      'service_reqfsem', // email.js 회원 가입시 제공되는 고유한 key 1
      'template_78xxqjf', // 고유한 key 2
      emailParams, // 홈페이지에서 설정한 template이 받는 변수들을 넣어주기 위한 params
      'Nm2utn2J4vFHzhLgf' //고유한 key 3
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
};

export const transSecToText = (sec) => {
  if (isNaN(sec)) {
    sec = 0;
  }
  const hh = Math.floor(sec / 3600);
  const mm = Math.floor(Math.round(sec - hh * 3600) / 60);
  const ss = Math.round(sec) % 60;
  const strHH = String(hh).length === 1 ? '0' + String(hh) : String(hh);
  const strMM = String(mm).length === 1 ? '0' + String(mm) : String(mm);
  const strSS = String(ss).length === 1 ? '0' + String(ss) : String(ss);
  return hh > 0 ? strHH + ':' + strMM + ':' + strSS : strMM + ':' + strSS;
};

export const transTimeToText = (hour, minutes) => {
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  return hour + ':' + minutes;
};

//new Date형식 => "14:28:10"
export const transTimeToTextWithSec = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

// 날짜를 "YYYY-MM-DD HH:mm:ss" 형식으로 변환하는 함수
export const formatDateTime = (date) => {
  if (!(date instanceof Date)) {
    console.error('Invalid date object');
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

//"0:00:10" --> 10
export const stringTimetoSec = (str) => {
  let sec = 0;
  if (typeof str === 'string') {
    const sp = str.split(':');
    if (sp.length === 3) {
      sec = Number(sp[0]) * 3600 + Number(sp[1]) * 60 + Number(sp[2]);
    } else if (sp.length === 2) {
      sec = Number(sp[0]) * 60 + Number(sp[1]);
    } else if (sp.length === 1) {
      sec = Number(sp[1]);
    } else {
      sec = Number(str);
    }
  }
  return sec;
};

const strTime = (s) => {
  return String(s).length === 1 ? '0' + String(s) : String(s);
};
export const timeToISO8601DateStr = (date) => {
  if (!date) {
    return '';
  }
  return (
    date.getFullYear() +
    '-' +
    strTime(date.getMonth() + 1) +
    '-' +
    strTime(date.getDate())
  );
};
// "2021-10-06T13:44:00"
export const timeToISO8601Str = (date) => {
  return (
    date.getFullYear() +
    '-' +
    strTime(date.getMonth() + 1) +
    '-' +
    strTime(date.getDate()) +
    'T' +
    strTime(date.getHours()) +
    ':' +
    strTime(date.getMinutes()) +
    ':' +
    strTime(date.getSeconds())
  );
};
// "20211006T134400"
export const timeToFilePathStr = (date) => {
  return (
    date.getFullYear() +
    strTime(date.getMonth() + 1) +
    strTime(date.getDate()) +
    'T' +
    strTime(date.getHours()) +
    strTime(date.getMinutes()) +
    strTime(date.getSeconds())
  );
};
// "20231212"
export const dateToStr = (date) => {
  if (!date) return;

  return (
    date.getFullYear() + strTime(date.getMonth() + 1) + strTime(date.getDate())
  );
};
export const readLocalStorage = (key, defaultValue) => {
  const value = typeof window === 'object' && window.localStorage.getItem(key);
  if (!value && defaultValue !== undefined) {
    return defaultValue;
  }
  return value === 'undefined' ? null : JSON.parse(value);
};

export const writeLocalStorage = (key, value) => {
  if (typeof window === 'object') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeLocalStorage = (key) => {
  if (typeof window === 'object') {
    window.localStorage.removeItem(key);
  }
};

export const readSessionStorage = (key, defaultValue) => {
  const value =
    typeof window === 'object' && window.sessionStorage.getItem(key);
  if (!value && defaultValue !== undefined) {
    return defaultValue;
  }
  return value === 'undefined' ? null : JSON.parse(value);
};

export const writeSessionStorage = (key, value) => {
  if (typeof window === 'object') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};
export const convertNormalStr = (str) => {
  if (!str) return str;
  let ret = str.replace(/(\\n)/gm, '\n');
  ret = ret.replace(/(\\t)/gm, '\t');
  ret = ret.replace(/(\\")/gm, '"');
  return ret;
};

export const windowClose = () => {
  if (typeof window === 'object' && window.PalmSystem) {
    window.close();
  } else if (typeof window === 'object') {
    window.location.reload();
  }
};

export const jsonConcat = (o1, o2) => {
  for (let key in o2) {
    o1[key] = o2[key];
  }
  return o1;
};
export const scaleW = (value) => {
  if (typeof window === 'object') {
    return value * (window.innerWidth / 1920);
  }
  return value;
};
export const scaleH = (value) => {
  if (typeof window === 'object') {
    return value * (window.innerHeight / 1080);
  }
  return value;
};

export const convertLocalTimetoUTC = (date) => {
  if (date instanceof Date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }
  return null;
};
export const convertUTCtoLocalTime = (date) => {
  if (date instanceof Date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }
  return null;
};

// return "2022-04-29" or "2022-04"
export const convertDateToString = (fullyear, month, date) => {
  let str = fullyear + '-' + fillZero(month, 2);
  if (date) {
    str += '-' + fillZero(date, 2);
  }
  return str;
};
// return "2022-04-29"
export const convertDateToString2 = (date) => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1을 해주고 두 자리로 만듭니다.
  const day = String(date.getDate()).padStart(2, '0'); // 일을 두 자리로 만듭니다.

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
// return "2022-04
export const convertDateToString3 = (date) => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1을 해주고 두 자리로 만듭니다.

  const formattedDate = `${year}-${month}`;
  return formattedDate;
};

// return "22년 08월"
export const convertToKoreanDate = (date = new Date()) => {
  const year = date.getFullYear() % 100;
  const month = date.getMonth() + 1;

  const formattedYear = String(year).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');

  return `${formattedYear}년 ${formattedMonth}월`;
};

export const getTodayStr = () => {
  const date = new Date();
  return (
    date.getFullYear() +
    '/' +
    strTime(date.getMonth() + 1) +
    '/' +
    strTime(date.getDate())
  );
};

export const cloneObject = (obj) => {
  const clone = {};
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      clone[key] = [...obj[key]];
    } else if (typeof obj[key] == 'object' && obj[key] != null) {
      clone[key] = cloneObject(obj[key]);
    } else {
      clone[key] = obj[key];
    }
  }
  return clone;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

//Daniel
export const makeShortUsername = (str) => {
  let ret = str;
  if (str && str.length > 10) {
    ret = str.slice(0, 10) + '...';
  }
  return ret;
};

/**
 * await Utils.wait(10000);
 * @param {*} time
 * @returns
 */
export const wait = (time) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const isEmailAddress = (str) => {
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  return exptext.test(str);
};

export const makeRandomValue = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;

export const maskingStr = (type, str) => {
  switch (type) {
    case 'name':
      if (!str || str.length === 1) {
        return str;
      }
      var first = str.charAt(0);
      for (var i = 0; i < str.length - 1; i++) {
        first = first + '*';
      }
      return first;
    case 'phone':
      return str.replace(/([0-9]{4})$/gi, '****');
    default:
      return str;
  }
};

export const convertImgToBase64URL = (url, callback, size, obj) => {
  var maxLength = 1024;
  var img = new Image();
  img.crossOrigin = 'Anonymous';

  img.onload = function () {
    var width = this.width;
    var height = this.height;

    var canvas = document.createElement('CANVAS'),
      ctx = canvas.getContext('2d'),
      dataURL;

    if (this.width > this.height) {
      if (this.width > maxLength) {
        width = maxLength;
        height = (width * this.height) / this.width;
      }
    } else {
      if (this.height > maxLength) {
        height = maxLength;
        width = (height * this.width) / this.height;
      }
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    dataURL = canvas.toDataURL();
    callback(dataURL, obj);
  };
  img.src =
    obj && obj.option ? url + '?' + new Date().getUTCMilliseconds() : url;
};

//fitTV에서 노략질해온 Util 함수
export const createQueryString = (object) => {
  const parts = [];
  for (const key of Object.getOwnPropertyNames(object)) {
    if (
      object[key] !== null &&
      object[key] !== undefined &&
      object[key] !== ''
    ) {
      parts.push(`${key}=${encodeURIComponent(object[key])}`);
    }
  }
  return parts.join('&');
};

export const createStringfyParams = (object) => {
  const parts = {};
  for (const key of Object.getOwnPropertyNames(object)) {
    if (
      object[key] !== null &&
      object[key] !== undefined &&
      object[key] !== ''
    ) {
      parts[key] = object[key].toString();
    }
  }
  return parts;
};

/** UTF-8 방식 byte 구하는 함수 */
const LINE_FEED = 10; // '\n'

const getByteLength = (decimal) => {
  return decimal >> 7 || LINE_FEED === decimal ? 2 : 1;
};

export const getByte = (str) => {
  return str
    .split('')
    .map((s) => s.charCodeAt(0))
    .reduce(
      (prev, unicodeDecimalValue) => prev + getByteLength(unicodeDecimalValue),
      0
    );
};

/** 지정해준 byte number 만큼만 출력해주는 함수 */
export const getLimitedByteText = (inputText, maxByte) => {
  const characters = inputText.split('');
  let validText = '';
  let totalByte = 0;

  for (let i = 0; i < characters.length; i += 1) {
    const character = characters[i];
    const decimal = character.charCodeAt(0);
    const byte = getByteLength(decimal); // 글자 한 개가 몇 바이트 길이인지 구해주기

    // 현재까지의 바이트 길이와 더해 최대 바이트 길이를 넘지 않으면
    if (totalByte + byte < maxByte) {
      totalByte += byte; // 바이트 길이 값을 더해 현재까지의 총 바이트 길이 값을 구함
      validText += character; // 글자를 더해 현재까지의 총 문자열 값을 구함
    } else {
      // 최대 바이트 길이를 넘으면
      break; // for 루프 종료
    }
  }

  return validText;
};

const JOB_HASH = {};

export const stopJob = (jobId) => {
  if (JOB_HASH[jobId]) {
    console.log('Stop job', jobId, JOB_HASH[jobId]);
    clearTimeout(JOB_HASH[jobId]);
    delete JOB_HASH[jobId];
  }
};

export const startJob = (jobId, func, delay = 100) => {
  stopJob(jobId);
  const jobKey = setTimeout(func, delay);
  JOB_HASH[jobId] = jobKey;
  console.log('Started job', jobId, JOB_HASH[jobId]);
};

// 아이디 찾기 userId 마스킹 표시 함수
export const replaceUserId = (str, repeatStr) => {
  if (str && str.length > 0) {
    // 대체할 부분 추출
    const substringToReplace = str.substr(-3, 2);
    // '*'로 대체
    const replacedString = str.replace(substringToReplace, repeatStr.repeat(2));
    return replacedString;
  }
};

/** 현재 환경 체크 */
export const isProduction = process.env.NODE_ENV === 'production';

// input 창에 지정된 정규표현식 외 입력되지 않게 막는 함수
// <Input onKeyDown={(e) => handlePreventRegex(e, /^[가-힣a-zA-Z]+$/)} />
export const handlePreventRegex = (e, regex) => {
  const keyPressed = e.key;

  if (
    !regex.test(keyPressed) &&
    !['Backspace', 'c', 'v'].includes(keyPressed)
  ) {
    e.preventDefault();
  }
};

export const MakeTwoDigitDate = (date) => {
  const dateString = date.toString();

  return dateString.length === 1 ? `0${dateString}` : dateString;
};

export const isWeekDay = (date) => {
  if (!date) return true;

  const day = date.getDay();
  return day !== 0 && day !== 6;
};

// src/redux/feature/holidays/holidaysSlice.js에서 holidays를 사용해야 함 (e.g. holidaysOfKorea)
export const isHoliday = ({ holidays, date }) => {
  if (!holidays || !Object.keys(holidays).length || !date) return false;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (!holidays[year] || holidays[year].length === 0) return false;

  const MMDD = `${MakeTwoDigitDate(month)}${MakeTwoDigitDate(day)}`;

  return holidays[year].includes(MMDD);
};

export const calculateNewDate = (currentDate, multiplier) => {
  const result = currentDate.getTime() + multiplier;
  return new Date(result);
};

/**
 * @param {function} isBusinessDayValidFn - 영업일인지 아닌지를 확인해주는 함수
 * @param {Date} date - 계산의 대상이 되는 날짜
 * @param {number} offset - 빼거나 더해야 될 N일
 * @param {string} operator - 대상 날짜로부터 앞/뒤 계산 방향 결정 (값: 'plus' | 'minus')
 * @param {Date[]} businessDayList - N일 뒤 영업일을 계산시 사용하는 재귀 로직을 위한 배열로 호출 시에는 사용하지 않음
 */
export const calculateOffsetBusinessDate = ({
  isBusinessDayValidFn,
  date,
  offset,
  operator,
}) => {
  if (
    typeof isBusinessDayValidFn !== 'function' ||
    offset === undefined ||
    operator === undefined ||
    date === undefined
  )
    return;

  const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
  const dayMultiplier = (operator === 'plus' ? 1 : -1) * MILLISECONDS_PER_DAY;

  let currentOffset = offset;
  let currentDate = new Date(date);

  while (currentOffset > 0) {
    currentDate = calculateNewDate(currentDate, dayMultiplier);
    if (isBusinessDayValidFn(currentDate)) currentOffset--;
  }

  return currentDate;
};

export const backendMode = () => {
  const hostName = window.location.hostname;
  let serverType = 'prd';
  if (hostName === 'localhost') {
    serverType = 'localhost';
  } else {
    const part = hostName.split('-');
    if (part.length > 1) {
      serverType = part[0];
    }
  }
  return serverType;
};

// 회원가입 가이드 다운로드 경로 생성 함수 (PRD/STG/DEV 서버)
export const getRandomNumTemplate = (serverType) => {
  let url = '';

  switch (serverType) {
    case 'localhost':
      url =
        'https://cf-qa2-subscriptionadmin.lge.com/template/randomNum_Template.csv';
      break;
    case 'qa':
      url =
        'https://cf-qa-subscriptionadmin.lge.com/template/randomNum_Template.csv';
      break;
    case 'qa2':
      url =
        'https://cf-qa2-subscriptionadmin.lge.com/template/randomNum_Template.csv';
      break;
    default:
      url =
        'https://cf-subscriptionadmin.lge.com/template/randomNum_Template.csv';
  }
  return url;
};

export const replaceImgUploadUrl = (type, contents) => {
  const serverType = backendMode();

  if (type === 'cloudfrontToS3') {
    let domain = '';
    switch (serverType) {
      case 'localhost':
        domain = 'https://s3-an2-hasubadmin-dev-affil.s3.amazonaws.com';
        break;
      case 'qa':
        domain = 'https://s3-an2-hasubadmin-stg-affil.s3.amazonaws.com';
        break;
      case 'qa2':
        domain = 'https://s3-an2-hasubadmin-dev-affil.s3.amazonaws.com';
        break;
      default:
        domain = 'https://s3-an2-hasubadmin-prd-affil.s3.amazonaws.com';
    }

    contents = contents.replace(/https:\/\/[^\/]+/g, domain);
    return contents;
  } else {
    let domain = '';
    switch (serverType) {
      case 'localhost':
        domain = 'https://cf-qa2-subscriptionadmin.lge.com';
        break;
      case 'qa':
        domain = 'https://cf-qa-subscriptionadmin.lge.com';
        break;
      case 'qa2':
        domain = 'https://cf-qa2-subscriptionadmin.lge.com';
        break;
      default:
        domain = 'https://cf-subscriptionadmin.lge.com';
    }

    contents = contents.replace(/https:\/\/[^\/]+/g, domain);
    return contents;
  }
};

// arr를 csv 형태로 변환후 clipboard로 만드는 함수/ Ctrl+V 누르면 출력됨
// 빈 메모장을 만들어서, 문자열 붙여넣기 후, 다른 이름으로 저장
// 다른 이름으로 저장할 때, 확장자 "모든파일"로 셋팅하고, 인코딩 ANSI로 셋팅하면 정상출력됨
export const cliboardCSV = (arr) => {
  let csv = '';
  let csvHeader = '';
  arr.forEach((el, index) => {
    if (index === 0) {
      for (let key in el) {
        csvHeader = csvHeader + key + ',';
        csv = csv + el[key] + ',';
      }
      csvHeader = csvHeader + '\n';
      csv = csvHeader + csv + '\n';
    } else {
      for (let key in el) {
        csv = csv + el[key] + ',';
      }
      csv = csv + '\n';
    }
  });
  navigator.clipboard
    .writeText(csv)
    .then(() => console.log(`copy`, csv))
    .catch((e) => console.log(e));
};

// YYYYMMDD to YYYY-MM-DD
export const addHyphenToDate = (YYYYMMDD) =>
  YYYYMMDD && YYYYMMDD.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');

// 01012345678 to 010-1234-5678
export const formatPhoneNumber = (data) => {
  if (data) {
    const formattedNum = data.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    return formattedNum;
  }
};

// 배열의 모든 요소가 같으면 true, 아니면 false 반환
export const isAllElementSame = (arr) => {
  // 배열이 비어있거나 하나의 요소만 있는 경우 항상 true 반환
  if (arr.length <= 1) {
    return true;
  }

  const referenceElement = arr[0];
  return arr.every((el) => el === referenceElement);
};
