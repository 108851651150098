import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { memo } from 'react';
import css from './CsvUpload.module.scss';

const CsvUpload = ({ title, className, handleUploadData }) => {
  const [file, setFile] = useState(null);

  const readExcelFile = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      // 원하는 시트를 선택 (첫 번째 시트 선택 예제)
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // 시트를 JSON으로 변환
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      handleUploadData(jsonData);
    };

    reader.readAsBinaryString(file);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    if (file) {
      readExcelFile(file);
    }
  }, [file]);

  return (
    <div className={css.fileBox}>
      <label htmlFor='ex_file' className={className}>
        {title}
      </label>
      <input id='ex_file' type='file' onChange={handleFileChange} />
    </div>
  );
};

CsvUpload.defaultProps = {
  title: '업로드',
};

export default memo(CsvUpload);
