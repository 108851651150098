import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import css from './CouponManage.module.scss';
import {
  PopUp,
  Table,
  TableV8,
  CsvDownload,
  Button,
  PopUpAlert,
} from '@components/index';
import { createColumnHelper } from '@tanstack/react-table';
import CouponPublish from './CouponPublish';
import flow_coupon_publish from '@assets/img/img_flow_coupon_publish.png';
import CouponPublishTalkDream from './CouponPublishTalkDream';
import { useDispatch, useSelector } from 'react-redux';
import TableCheckbox from '@components/Table/TableCheckbox';
import {
  getCouponIssueCompletedList,
  getCouponIssueStatusDashboardAPI,
  getCouponIssueTargetList,
  postMappingContractAndCoupon,
} from 'redux/action/couponAction';
import TableV8ManualPage from '@components/Table/TableV8ManualPage';
import { COUPON_SUB_PATH } from '@router/routerPath';
import { createQueryString, convertDateToString2 } from '@utils';
const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const FAIL_MOCK_DATA = [
  {
    some1: '2021-11-11',
    some2: 'cssg11232',
    some3: '2022-11-01',
    some4: '무상',
    some5: 'Ac0001',
    some6: '런드리고 포인트 쿠폰',
    some7: '-',
    some8: '난수번호 소진',
  },
];

const CouponManage = () => {
  const dispatch = useDispatch();
  const couponIssueStatusDashboard = useSelector(
    (state) => state.coupon.couponIssueStatusDashboard
  );
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const couponIssueTargetList = useSelector(
    (state) => state.coupon.couponIssueTargetList.list
  );
  const couponIssueTargetTotalCount = useSelector(
    (state) => state.coupon.couponIssueTargetList.additionalInfo.totalCount
  );
  const couponIssueCompletedList = useSelector(
    (state) => state.coupon.couponIssueCompletedList
  );
  const couponIssueCompletedListFailCase = useSelector(
    (state) => state.coupon.couponIssueCompletedListFailCase
  );
  const couponRegisterTargetList = useSelector(
    (state) => state.coupon.couponRegisterTargetList
  );

  // Todo: 권한이 LG 일경우 step3로(사용 확정 승인)까지 사용
  // 권한이 LG가 아닐경우 step2까지 사용하는 로직 추가필요
  const [isOpenConfirmPopUp, setIsOpenConfirmPopUp] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [stepIndex, setStepIndex] = useState(0);

  const registerRequiredCount = useMemo(() => {
    let count = 0;
    couponRegisterTargetList.forEach((target) => {
      if (
        target.salesStatusDesc === '사용' &&
        target.issueRequiredCouponCnt > 0
      ) {
        count = count + target.issueRequiredCouponCnt;
      }
    });
    return count;
  }, [couponRegisterTargetList]);

  const handlePopUpClick = () => {
    setIsOpenPopUp(!isOpenPopUp);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const mappingCallback = useCallback(() => {
    const params = {
      mappingTargetList: JSON.stringify(selectedRow),
    };
    postMappingContractAndCoupon(dispatch, params);
    setIsOpenConfirmPopUp(true);
  }, [selectedRow]);

  const columnData = useMemo(() => {
    return [
      {
        accessor: (row, index) => index + 1,
        Header: 'No',
      },
      {
        accessor: 'some1',
        Header: '계약일',
      },
      {
        accessor: 'some2',
        Header: '계약번호',
      },
      {
        accessor: 'some3',
        Header: '결제일',
      },
      {
        accessor: 'some4',
        Header: '정산',
      },
      {
        accessor: 'some5',
        Header: '상품아이디',
      },
      {
        accessor: 'some6',
        Header: '상품',
      },
      {
        accessor: 'some7',
        Header: '난수번호',
      },
      {
        accessor: 'some8',
        Header: '비고',
      },
    ];
  }, []);

  const columnDataV8 = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'vendor-management-table-select-header'}
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          return (
            <TableCheckbox
              className={css.headerInput}
              id={'vendor-management-table-select' + row.id}
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      },

      columnHelper.accessor('modelSuffixDesc', {
        id: 'modelSuffixDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>상품명</span>,
      }),
      columnHelper.accessor('contractLineNo', {
        id: 'contractLineNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>계약라인번호</span>,
      }),
      columnHelper.accessor('deliveryPlanNo', {
        id: 'deliveryPlanNo',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>배송계획번호</span>,
      }),
      columnHelper.accessor('vendorDesc', {
        id: 'vendorDesc',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>제휴사</span>,
      }),
      columnHelper.accessor('promotionType', {
        id: 'promotionType',
        cell: (info) => (
          <span>{info.getValue() === 'NFOC' ? '유상' : '무상'}</span>
        ),
        header: () => <span>정산</span>,
      }),
      columnHelper.accessor('serviceTimes', {
        id: 'serviceTimes',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>구독회차</span>,
      }),
      columnHelper.accessor('deliveryReqDate', {
        id: 'deliveryReqDate',
        cell: (info) => <span>{info.getValue()}</span>,
        header: () => <span>발행예정일</span>,
      }),
    ],
    []
  );

  ///////// 이하 searchParams 연동
  const [searchParams, setSearchParams] = useSearchParams(); // set searchParams
  const page = searchParams.get('page'); // get searchParams page
  const limit = searchParams.get('limit'); // get searchParams limit

  const vendorKeyForLGUser = searchParams.get('vendorKey');

  // handlePagecallback Table이 여러개인 화면에 경우 handlePagecallback 함수를 여러개 만들어서 키( ex) page1 / limit1)를 다르게 셋팅해야 의도한대로 동작함
  const handlePageCallback = useCallback((pagination) => {
    searchParams.set('page', pagination.pageIndex + 1 ?? '1');
    searchParams.set('limit', pagination.pageSize ?? '100');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    // page와 limit의 상태값을 handlePagecallback이 변경합니다. TableV8ManualPage 컴포넌트의 pagination과 연동되어있습니다.
    const tempIssueTargetParams = {
      limit: '100',
      page: page ?? '1',
    };
    getCouponIssueTargetList(dispatch, tempIssueTargetParams);
    const completedListSuccessParams = {
      limit: '100',
      page: page ?? '1',
      statusCsmsSendFlag: 'Y',
    };
    const completedListFailParams = {
      limit: '100',
      page: page ?? '1',
      statusCsmsSendFlag: 'N',
    };
    getCouponIssueCompletedList(dispatch, completedListSuccessParams);
    getCouponIssueCompletedList(dispatch, completedListFailParams);
    getCouponIssueStatusDashboardAPI(dispatch);
  }, [page, limit]);

  const handleNavigate = useCallback((path) => {
    navigate(path);
  }, []);

  const getRowselectedCallback = useCallback((arr) => {
    const mappingTargetList = arr.map((item) => {
      return item.deliveryPlanNo;
    });
    setSelectedRow(mappingTargetList);
  }, []);

  return (
    <>
      <h2 className={css.pageTitle}>
        쿠폰 발행
        {/* <span className={css.additionalInfo}>
          생성 필요한 쿠폰이 <span>{registerRequiredCount}건</span>{' '}
          확인되었습니다.
        </span> */}
      </h2>

      <div className={css.statusDashBoard}>
        <div className={css.status}>
          <dl>
            <dt>발행 완료</dt>
            <dd>
              <div className={css.value}>
                <span
                  className={cn(css.num, css.pointer_cursor)}
                  onClick={
                    process.env.REACT_APP_MODE === 'LG'
                      ? () =>
                          handleNavigate(
                            '/' +
                              COUPON_SUB_PATH.LIST +
                              '?' +
                              createQueryString({
                                page: '1',
                                limit: '100',
                                vendorkey: vendorKeyForLGUser,
                                searchType: 'COUPON_STATUS',
                                detailCondition: 'C002',
                                fromDate: '2023-01-01',
                                toDate: convertDateToString2(new Date()),
                              })
                          )
                      : undefined
                  }
                >
                  {couponIssueStatusDashboard.couponIssuedCnt}
                </span>
                건
              </div>
              <p className={css.standard}>*최근 30일 기준</p>
            </dd>
            <div className={cn('desc')}>(최근 30일 기준 발행된 쿠폰 수)</div>
          </dl>
          <dl>
            <dt>발행 미완료</dt>
            <dd>
              <div className={css.value}>
                {/* <span className={css.act}></span> */}
                <span className={css.num}>
                  {couponIssueStatusDashboard.couponIssueFailedCnt}
                </span>
                건
              </div>
              <ul className={css.detail}>
                {/* <button onClick={handlePopUpClick}>
                  실패 내역 조회<i className={css.ico_arr}></i>
                </button> */}
                <PopUp
                  size='mideum'
                  isOpenPopUp={isOpenPopUp}
                  onRequestClose={() => setIsOpenPopUp(false)}
                >
                  <div>
                    총{' '}
                    <b>{couponIssueStatusDashboard.couponIssueFailedCnt}건</b>의
                    쿠폰 발행이 실패 하였습니다.
                  </div>
                  <Table
                    columns={columnData}
                    data={FAIL_MOCK_DATA}
                    hasPagination={false}
                    hasPageSize={false}
                  />
                  <div className={css.excel}>
                    <CsvDownload
                      data={FAIL_MOCK_DATA}
                      title='내보내기'
                    ></CsvDownload>
                  </div>
                </PopUp>
              </ul>
              <p className={css.standard}>*최근 30일 기준</p>
            </dd>
            <div className={cn('desc')}>
              (결제 일자가 최근 30일 이내인 주문 건 중 발행완료가 되지 않은 수)
            </div>
          </dl>
          {/* <dl>
            <dt>사용 완료</dt>
            <dd>
              <ul className={css.valueWrap}>
                <li className={css.value}>
                  요청{' '}
                  <span
                    className={cn(css.num, css.pointer_cursor)}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () =>
                            handleNavigate(
                              '/' +
                                COUPON_SUB_PATH.LIST +
                                '?' +
                                createQueryString({
                                  page: '1',
                                  limit: '10',
                                  searchType: 'COUPON_STATUS',
                                  detailCondition: '쿠폰사용등록',
                                  fromDate: '2023-01-01',
                                  toDate: new Date(),
                                })
                            )
                        : undefined
                    }
                  >
                    {couponIssueStatusDashboard.couponUseUnderApprovalVendorCnt}
                  </span>
                  건
                </li>
                <li className={cn('value', 'confirm')}>
                  확정{' '}
                  <span
                    className={cn(css.num, css.pointer_cursor)}
                    onClick={
                      process.env.REACT_APP_MODE === 'LG'
                        ? () =>
                            handleNavigate(
                              '/' +
                                COUPON_SUB_PATH.LIST +
                                '?' +
                                createQueryString({
                                  page: '1',
                                  limit: '10',
                                  searchType: 'COUPON_STATUS',
                                  detailCondition: '쿠폰사용완료확정',
                                  fromDate: '2023-01-01',
                                  toDate: new Date(),
                                })
                            )
                        : undefined
                    }
                  >
                    {couponIssueStatusDashboard.couponUseApprovedVendorCnt}
                  </span>
                  건
                </li>
              </ul>
              <p className={css.standard}>*최근 30일 기준</p>
            </dd>
          </dl> */}
        </div>
      </div>
      <div>
        <div className={cn('flowChart')}>
          <h3 className={cn('title')}>쿠폰 발행 Flow</h3>
          <div className={cn('img')}>
            <img src={flow_coupon_publish} alt='상품 조회 Flow 이미지' />
          </div>
        </div>
      </div>
      <div className={cn('stepsFlow', 'mt32')}>
        <div className={cn('steps', 'on')}>
          쿠폰 생성 등록<span className={css.badge}>완료</span>
        </div>
        <div className={cn('steps', 'on')}>
          쿠폰 발행<span className={css.badge}>진행중</span>
        </div>
        <div className={css.steps}>쿠폰 사용 등록</div>
        <div className={css.steps}>사용 확정 요청</div>
      </div>
      {/* <div className={css.stepGuideText}>
        금월 쿠폰 리스트 발행 건수는{' '}
        {couponIssueStatusDashboard.couponIssuedCnt}건, 빌행이 미완료된 건수는{' '}
        {couponIssueStatusDashboard.couponIssueFailedCnt}건입니다. (발행 건수
        대상 리스트의 숫자, 미완료된 건수: 발행 건수 - 시스템 잔여 등록 건수)
      </div> */}
      <div className={css.stepGuideText} style={{ marginTop: '0' }}>
        ① 발행 대상 리스트를 확인해주세요 (10일 수금, 11일~12일 작성) / CSMS에서
        수금 완료된 쿠폰 중 "발행대상"이 Y이고 "발행 예정일"이 도래한 쿠폰이
        표시됩니다.
      </div>
      <div className={cn('table_header')}>
        <div className={cn('box_left')}>
          {couponIssueTargetTotalCount > 0 ? (
            <div class={cn('total_count')}>
              [CSMS 조회 정보] 총 <b>{couponIssueTargetTotalCount}</b>건
            </div>
          ) : (
            <div class={cn('total_count')}>발행가능한 쿠폰이 없습니다.</div>
          )}
        </div>
      </div>
      {couponIssueTargetTotalCount > 0 && (
        <TableV8ManualPage
          columns={columnDataV8}
          data={couponIssueTargetList}
          hasExportBtn={false}
          hasCsvDownload={false}
          hasPageSize={false}
          hasPagination={false}
          page={page}
          limit={100}
          handlePageCallback={handlePageCallback}
          totalLength={couponIssueTargetTotalCount}
          getRowselected={getRowselectedCallback}
        />
      )}
      <div className={cn('flex', 'justify-content-center')}>
        {couponIssueTargetTotalCount > 0 && (
          <Button
            styleType={'btn01'}
            subType={'type01'}
            onClick={() => {
              mappingCallback();
            }}
          >
            난수번호와 매핑(쿠폰 발행)
          </Button>
        )}
      </div>
      <CouponPublish
        couponIssueCompletedList={couponIssueCompletedList}
        couponIssueCompletedListFailCase={couponIssueCompletedListFailCase}
      />
      <CouponPublishTalkDream />
      <PopUpAlert
        title={'난수번호와 매핑(쿠폰 발행)'}
        onRequestClose={() => setIsOpenConfirmPopUp(false)}
        isOpenPopUp={isOpenConfirmPopUp}
        closeButton={false}
        button={[
          <Button
            key='firstButton'
            subType={'type02'}
            onClick={() => {
              setIsOpenConfirmPopUp(false);
            }}
          >
            확인
          </Button>,
        ]}
      >{`완료되었습니다.`}</PopUpAlert>
    </>
  );
};

export default CouponManage;
