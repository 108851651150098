import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, SelectBox, TableV8 } from '@components/index';
import { useSelect } from '@hooks/index';
import TableCheckbox from '@components/Table/TableCheckbox';
import SingleSelectSearch from '@components/Search/SingleSelectSearch';
import classNames from 'classnames/bind';
import css from './Deleted.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { dateToStr } from 'utils/utils';
import {
  getRetrieveMessageList,
  postRecoverMessage,
  getMessageCount,
} from 'redux/action/mailAction';
import resetIcon from '@assets/img/reset.png';

const columnHelper = createColumnHelper();
const cn = classNames.bind(css);

const searchTypeList = [
  { id: 'categoryDesc', title: '구분' },
  { id: 'sendUserId', title: '아이디' },
];

const Deleted = () => {
  const dispatch = useDispatch();
  const tableV8Ref = useRef();
  const userId = useSelector((state) => state.user.userData.userId);
  const mailList = useSelector((state) => state.mail.deletedList);
  const totalCount = useSelector((state) => state.mail.deletedTotalCount);
  const [pageElements, setPageElements] = useState({
    pageSize: '1000000',
    pageCount: '1',
  });

  //todo: pramas 수정
  const listParams = useMemo(() => {
    const result = {
      userId: userId,
      fromDate: '20231201',
      toDate: dateToStr(new Date()),
      delFlag: 'Y',
      searchType: '',
      detailCondition: '',
      limit: '100',
      page: pageElements.pageCount,
    };
    return result;
  }, [userId]);

  const countParams = useMemo(() => {
    const result = {
      userId: userId,
      delFlag: 'Y',
    };
    return result;
  }, [userId]);

  useEffect(() => {
    getRetrieveMessageList(dispatch, listParams, 'deleted');
  }, [listParams]);

  useEffect(() => {
    resetMessageCount(dispatch, countParams);
  }, [countParams]);

  const [filteredData, setFilteredData] = useState(mailList);

  const [selectedKeys, setSelectedKeys] = useState(null);
  const [selectedKeyArray, setSelectedKeyArray] = useState([]);
  const onChangeSelectedKeys = useCallback((keys) => {
    setSelectedKeys(keys);
  }, []);

  useEffect(() => {
    setFilteredData(mailList);
  }, [mailList]);

  useEffect(() => {
    if (selectedKeys) {
      const keysAsString = selectedKeys
        .map((item) => `'${item.messageKey}'`)
        .join(', ');
      setSelectedKeyArray(`[${keysAsString}]`);
    }
  }, [selectedKeys]);

  const [
    currentSelectedSearchType,
    setCurrentSelectedSearchType,
    currentSearchTypeLabel,
  ] = useSelect({
    optionList: searchTypeList,
    initialIndex: 0,
    initialLabel: searchTypeList[0].title,
  });

  const searchKey = useMemo(
    () => searchTypeList[currentSelectedSearchType]?.id,
    [currentSelectedSearchType]
  );

  const onClickRestore = useCallback(() => {
    const params = {
      userId: userId,
      recoverTargetList: selectedKeyArray,
    };
    handleSelected();

    postRecoverMessage(dispatch, params, listParams, 'deleted');
  }, [userId, selectedKeyArray, listParams]);

  const resetMessageCount = useCallback(() => {
    getMessageCount(dispatch, countParams, 'deleted');
  }, [countParams]);

  const resetMessage = useCallback(() => {
    resetMessageCount();
    getRetrieveMessageList(dispatch, listParams);
  }, [countParams]);

  const onChangePageElements = useCallback((element) => {
    setPageElements(element);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('select', {
        header: ({ table }) => (
          <TableCheckbox
            className={css.headerInput}
            id={'message-select-headers'}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <TableCheckbox
            className={css.headerInput}
            id={'message-selects' + row.id}
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      }),
      columnHelper.accessor('manageCategoryDesc', {
        header: () => <span>구분</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('groupName', {
        header: () => <span>그룹(상호명)</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('sendUserId', {
        header: () => <span>보낸 사람</span>,
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor('content', {
        header: () => <span>내용</span>,
        cell: (info) => (
          <span
            className={css.cursor}
            // onClick={() => onClickMessageDetail(info.row.original)}
          >
            {info.getValue()}
          </span>
        ),
        size: 700,
      }),
      columnHelper.accessor('receivedDatetime', {
        header: () => <span>받은 날짜</span>,
        cell: (info) => <span>{info.getValue()}</span>,
        size: 200,
      }),
    ],
    []
  );
  useEffect(() => {
    setFilteredData(mailList);
  }, [mailList]);

  const handleSelected = () => {
    if (tableV8Ref.current) {
      tableV8Ref.current.clearSelectedRows();
    }
  };

  return (
    <>
      <div className={cn('searchOptionArea')}>
        <div className={cn('flex')}>
          <div className={cn('sizeS')}>
            <SelectBox
              optionList={searchTypeList}
              currentIndex={currentSelectedSearchType}
              currentLabel={currentSearchTypeLabel}
              handleSelectIndex={setCurrentSelectedSearchType}
              fullWidth={true}
            />
          </div>
          <SingleSelectSearch
            targetData={mailList}
            searchKey={searchKey}
            setFilteredData={setFilteredData}
            className={cn('ml20', 'fullWidth')}
          />
        </div>
      </div>
      <TableV8
        ref={tableV8Ref}
        data={filteredData}
        columns={columns}
        hasCsvDownload={false}
        getRowselected={onChangeSelectedKeys}
        getPageElements={onChangePageElements}
        rightControls={[
          <div className={cn('tableButtonContainer')}>
            <Button
              onClick={onClickRestore}
              styleType={'btn03'}
              subType={'type04'}
            >
              복구
            </Button>
          </div>,
        ]}
        //  TODO: pagination 반영후 반영 예정
        // leftControls={[
        //   <div className={css.leftControls}>
        //     <span>
        //       {`${totalCount?.totalCnt ?? 0}/ `}
        //       <span className={css.fontBlue}>{`${
        //         totalCount?.unreadCnt ?? 0
        //       } `}</span>
        //       미확인
        //     </span>
        //     <img
        //       onClick={resetMessage}
        //       src={resetIcon}
        //       alt=''
        //       className={css.resetIcon}
        //     ></img>
        //   </div>,
        // ]}
      />
    </>
  );
};

export default Deleted;
