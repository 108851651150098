const CONTRACT_KEYS = {
  // 계약 정보
  CONTRACTOR_NAME: 'CONTRACTOR_NAME',
  CONTRACTOR_NUMBER: 'CONTRACTOR_NUMBER',
  ORDER_TYPE: 'ORDER_TYPE',
  PAYMENT_DATE: 'PAYMENT_DATE',
  PRODUCT: 'PRODUCT',
  PRODUCT_ID: 'PRODUCT_ID',
  SETTLEMENT: 'SETTLEMENT',
  FREE_OF_CHARGE: 'FREE_OF_CHARGE',
  SERVICE_OPTION: 'SERVICE_OPTION',
  PACKAGING_STATUS: 'PACKAGING_STATUS',
  DELIVERY_CYCLE: 'DELIVERY_CYCLE',
  SUBSCRIPTION_CYCLE: 'SUBSCRIPTION_CYCLE',
  CONSTRACT_START_DATE: 'CONSTRACT_START_DATE',
  CONSTRACT_END_DATE: 'CONSTRACT_END_DATE',
  SUBSCRIPTION_START_DATE: 'SUBSCRIPTION_START_DATE',
  SUBSCRIPTION_END_DATE: 'SUBSCRIPTION_END_DATE',
  ACTUAL_USER_TEL_NUMBER: 'ACTUAL_USER_TEL_NUMBER',
  ACTUAL_USER_PHONE_NUMBER: 'ACTUAL_USER_PHONE_NUMBER',
  ACTUAL_USER_ADDRESS: 'ACTUAL_USER_ADDRESS',
  CONTRACTOR_TEL_NUMBER: 'CONTRACTOR_TEL_NUMBER',
  CONTRACTOR_PHONE_NUMBER: 'CONTRACTOR_PHONE_NUMBER',
  CONTRACTOR_ADDRESS: 'CONTRACTOR_ADDRESS',
  CONTRACTOR_EMAIL: 'CONTRACTOR_EMAIL',
  MONTHS_OF_USE: 'MONTHS_OF_USE',
  USAGE_DAYS: 'USAGE_DAYS',
  REFUND_BANK_CODE: 'REFUND_BANK_CODE',
  REFUND_ACCOUNT: 'REFUND_ACCOUNT',

  // 당월 구독 정보
  CURRENT_MONTH_SETTLEMENT: 'CURRENT_MONTH_SETTLEMENT',
  CURRENT_MONTH_SETTLEMENT_STATUS: 'CURRENT_MONTH_SETTLEMENT_STATUS',
  DELIVERY_STATUS: 'DELIVERY_STATUS',
  CHANGE_DELIVERY_STATUS: 'CHANGE_DELIVERY_STATUS',
  DELIVERY_IN_PROGRESS: 'DELIVERY_IN_PROGRESS',
  DELIVERY_MANAGEMENT_APPROVAL_STATUS: 'DELIVERY_MANAGEMENT_APPROVAL_STATUS',
  COURIER_COMPANY: 'COURIER_COMPANY',
  TRACKING_NUMBER: 'TRACKING_NUMBER',
  DELIVERY_DELAY: 'DELIVERY_DELAY',
  DELIVERY_DAYS: 'DELIVERY_DAYS',
  CONFIRMED_DELIVERY_COMPLETION_DATE: 'CONFIRMED_DELIVERY_COMPLETION_DATE',

  // History
  MONTHLY_SUBSCRIPTION: 'MONTHLY_SUBSCRIPTION',
  SHIPPING_CATEGORY: 'SHIPPING_CATEGORY',
  REASON_FOR_CHANGE: 'REASON_FOR_CHANGE',
  COMMENT: 'COMMENT',
  REFUND_REQUEST: 'REFUND_REQUEST',
};

export const contractKeyNames = {
  contractEndDate: '계약 종료일',
  contractLineNo: '계약자 번호',
  contractStartDate: '계약 시작일',
  customerAddress: '계약자 주소',
  customerEmail: '계약자 이메일',
  customerMobileNo: '계약자 휴대폰 번호',
  customerName: '계약자 명',
  customerTelNo: '계약자 전화번호',
  merchandiseDesc: '상품',
  merchandiseId: '상품 ID',
  orderType: '주문 타입',
  promotionTypeDesc: '정산',
  receiverName: '실사용자 이름',
  receiverTelNo: '실사용자 전화번호',
  receiverMobileNo: '실사용자 휴대폰 번호',
  receiverAddress: '실 사용자 주소',
  serviceTimes: '구독회차',
};

export const subscriptionKeyNames = {
  paymentCmplDate: '당월 정산',
  paymentCmplFlag: '당월 정산 여부(결제 상태)',
  deliveryStatusDesc: '배송 상태',
  changeApprovalStatus: '배송 상태 변경',
  changeTargetStatusDesc: '배송 중 현황',
  trackerDeliveryStatusDesc: '배송관리 승인 상태',
  trackerDeliveryCompanyDesc: '택배 사',
  trackingNo: '송장 번호',
  deliveryDelayedFlag: '배송 지연',
  deliveryDays: '배송일 수',
  deliveryCompleteDate: '배송 완료 확정일',
  // deliveryStatus: '배송 코드', // 임시
};

export const historyKeyNames = {
  subscribeYearMonth: '구독 월',
  serviceTimes: '구독 회차',
  deliveryStatusDesc: '배송 구분',
  // deliveryStatus: '배송 상태',
  trackerDeliveryCompanyDesc: '택배사',
  trackingNo: '운송장 번호',
  deliveryCategoryDesc: '배송 상태',
  changeTargetStatusDesc: '배송 상태 변경',
  changeRequestComment: '변경 사유',
  changeApprovalComment: 'Comment',
  // contractLineNo: '18532259',
  deliveryCompleteDate: '배송 완료 확정일',
  // deliveryKey: 'DELIVERY.1707805473000000002',
  promotionTypeDesc: '정산',
  // deliveryPlanNo: '104522127',
  // insertDatetime: '2024-02-29 09:18:31',
  // orderSeq: '1',
  // contractLineNo: '게약 라인번호',
  // deliveryPlanNo: '발송 예정번호',
  // delFlag: '환불 요청?',
};

const dummyContractData = {
  [CONTRACT_KEYS.CONTRACTOR_NAME]: '이엘지',
  [CONTRACT_KEYS.CONTRACTOR_NUMBER]: 'Csg112345',
  [CONTRACT_KEYS.ORDER_TYPE]:
    '케어십(보유)[제휴1개월](기본요금)(정기배송)(3개월무료)23년6월부터',
  [CONTRACT_KEYS.PAYMENT_DATE]: '매월 10일',
  [CONTRACT_KEYS.PRODUCT]: '우유창고',
  [CONTRACT_KEYS.PRODUCT_ID]: 'abcdef',
  [CONTRACT_KEYS.SETTLEMENT]: '유상/무상',
  [CONTRACT_KEYS.FREE_OF_CHARGE]: '3개월',
  [CONTRACT_KEYS.SERVICE_OPTION]: '',
  [CONTRACT_KEYS.PACKAGING_STATUS]: 'Y',
  [CONTRACT_KEYS.DELIVERY_CYCLE]: '1개월 1회',
  [CONTRACT_KEYS.SUBSCRIPTION_CYCLE]: '1',
  [CONTRACT_KEYS.CONSTRACT_START_DATE]: '2023-05-10',
  [CONTRACT_KEYS.CONSTRACT_END_DATE]: '2028-05-09',
  [CONTRACT_KEYS.SUBSCRIPTION_START_DATE]: '2023-05-10',
  [CONTRACT_KEYS.SUBSCRIPTION_END_DATE]: '2028-05-09',
  [CONTRACT_KEYS.ACTUAL_USER_TEL_NUMBER]: '02-xxxx-xxxx',
  [CONTRACT_KEYS.ACTUAL_USER_PHONE_NUMBER]: '010-5678-1234',
  [CONTRACT_KEYS.ACTUAL_USER_ADDRESS]: '서울특별시 영등포구 여의대로 128',
  [CONTRACT_KEYS.CONTRACTOR_TEL_NUMBER]: '010-1234-5678',
  [CONTRACT_KEYS.CONTRACTOR_PHONE_NUMBER]: '010-1234-5678',
  [CONTRACT_KEYS.CONTRACTOR_ADDRESS]: '서울특별시 영등포구 여의대로 128',
  [CONTRACT_KEYS.CONTRACTOR_EMAIL]: 'LG@lge.com',
  [CONTRACT_KEYS.MONTHS_OF_USE]: '5',
  [CONTRACT_KEYS.USAGE_DAYS]: '35',
  [CONTRACT_KEYS.REFUND_BANK_CODE]: '우리은행',
  [CONTRACT_KEYS.REFUND_ACCOUNT]: '123456********',
};

const dummySubscriptionData = {
  [CONTRACT_KEYS.CURRENT_MONTH_SETTLEMENT]: '무상',
  [CONTRACT_KEYS.CURRENT_MONTH_SETTLEMENT_STATUS]: 'Y',
  [CONTRACT_KEYS.DELIVERY_STATUS]: '배송 중',
  [CONTRACT_KEYS.CHANGE_DELIVERY_STATUS]: '재 배송',
  [CONTRACT_KEYS.DELIVERY_IN_PROGRESS]: '배달 출발',
  [CONTRACT_KEYS.DELIVERY_MANAGEMENT_APPROVAL_STATUS]: '승인',
  [CONTRACT_KEYS.COURIER_COMPANY]: 'CJ 대한통운',
  [CONTRACT_KEYS.TRACKING_NUMBER]: '123456',
  [CONTRACT_KEYS.DELIVERY_DELAY]: 'Y',
  [CONTRACT_KEYS.DELIVERY_DAYS]: '5',
  [CONTRACT_KEYS.CONFIRMED_DELIVERY_COMPLETION_DATE]: '',
};

const dummyHistoryData = [
  {
    [CONTRACT_KEYS.MONTHLY_SUBSCRIPTION]: '1월',
    [CONTRACT_KEYS.SUBSCRIPTION_CYCLE]: '1',
    [CONTRACT_KEYS.SHIPPING_CATEGORY]: '신규',
    [CONTRACT_KEYS.COURIER_COMPANY]: '대한통운',
    [CONTRACT_KEYS.TRACKING_NUMBER]: '123456123456',
    [CONTRACT_KEYS.CHANGE_DELIVERY_STATUS]: '상품 준비 중',
    [CONTRACT_KEYS.REASON_FOR_CHANGE]: '',
    [CONTRACT_KEYS.COMMENT]: '',
    [CONTRACT_KEYS.CONFIRMED_DELIVERY_COMPLETION_DATE]: '2023-06-10',
    [CONTRACT_KEYS.SETTLEMENT]: '무상',
    [CONTRACT_KEYS.REFUND_REQUEST]: 'N',
  },
  {
    [CONTRACT_KEYS.MONTHLY_SUBSCRIPTION]: '2월',
    [CONTRACT_KEYS.SUBSCRIPTION_CYCLE]: '2',
    [CONTRACT_KEYS.SHIPPING_CATEGORY]: '신규',
    [CONTRACT_KEYS.COURIER_COMPANY]: '대한통운',
    [CONTRACT_KEYS.TRACKING_NUMBER]: '123456123456',
    [CONTRACT_KEYS.CHANGE_DELIVERY_STATUS]: '',
    [CONTRACT_KEYS.REASON_FOR_CHANGE]: '',
    [CONTRACT_KEYS.COMMENT]: '',
    [CONTRACT_KEYS.CONFIRMED_DELIVERY_COMPLETION_DATE]: '2023-06-10',
    [CONTRACT_KEYS.SETTLEMENT]: '무상',
    [CONTRACT_KEYS.REFUND_REQUEST]: 'N',
  },
  {
    [CONTRACT_KEYS.MONTHLY_SUBSCRIPTION]: '3월',
    [CONTRACT_KEYS.SUBSCRIPTION_CYCLE]: '3',
    [CONTRACT_KEYS.SHIPPING_CATEGORY]: '신규',
    [CONTRACT_KEYS.COURIER_COMPANY]: '대한통운',
    [CONTRACT_KEYS.TRACKING_NUMBER]: '123456123456',
    [CONTRACT_KEYS.CHANGE_DELIVERY_STATUS]: '',
    [CONTRACT_KEYS.REASON_FOR_CHANGE]: '',
    [CONTRACT_KEYS.COMMENT]: '',
    [CONTRACT_KEYS.CONFIRMED_DELIVERY_COMPLETION_DATE]: '',
    [CONTRACT_KEYS.SETTLEMENT]: '무상',
    [CONTRACT_KEYS.REFUND_REQUEST]: 'N',
  },
  {
    [CONTRACT_KEYS.MONTHLY_SUBSCRIPTION]: '4월',
    [CONTRACT_KEYS.SUBSCRIPTION_CYCLE]: '4',
    [CONTRACT_KEYS.SHIPPING_CATEGORY]: '재배송',
    [CONTRACT_KEYS.COURIER_COMPANY]: '대한통운',
    [CONTRACT_KEYS.TRACKING_NUMBER]: '123456123456',
    [CONTRACT_KEYS.CHANGE_DELIVERY_STATUS]: '',
    [CONTRACT_KEYS.REASON_FOR_CHANGE]: '',
    [CONTRACT_KEYS.COMMENT]: '',
    [CONTRACT_KEYS.CONFIRMED_DELIVERY_COMPLETION_DATE]: '',
    [CONTRACT_KEYS.SETTLEMENT]: '무상',
    [CONTRACT_KEYS.REFUND_REQUEST]: 'N',
  },
];

export const dummyCallData = {
  contract: dummyContractData,
  subscription: dummySubscriptionData,
  history: dummyHistoryData,
};
